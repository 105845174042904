<div class="flex flex-col min-h-screen">
  <section class="bg-yellow-100 pb-4">
    <div class="container mx-auto px-4 lg:px-16 max-w-screen-2xl">
      <div class="w-full">
        <section class="py-8 lg:py-12 px-5 bg-yellow-100">
          <div class="container mx-auto max-w-screen-2xl">
            <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 p-4">
              <div
                class="lg:col-span-3 text-lg lg:text-xl leading-none font-semibold"
              >
                A fun and creative way
                <div class="text-yellow-500 font-semibold">to dive into</div>
                <div class="text-yellow-500 font-semibold">
                  our investment ideas!
                </div>
              </div>
              <div class="lg:col-span-2">
                <p class="text-smd mt-4 lg:mt-0">
                  Enjoy our research in a dynamic AI-generated podcast that
                  summarizes our latest updates and provides context.
                </p>
                <p class="text-smd mt-5 lg:mt-2">
                  Beware, however, that our AI can occasionally get overly
                  creative, so we cannot guarantee the accuracy of the content
                  provided.
                </p>
              </div>
            </div>
          </div>
          <div class="container mx-auto pt-6 max-w-screen-2xl">
            <hr class="my-b" />
          </div>
        </section>
        <section class="px-5 bg-yellow-100 pb-4" id="#eventgrid">
          <div class="content-wrapper limit-pagewidth">
            <app-podcast-highlight
              [podcast]="latestPodcast"
            ></app-podcast-highlight>
          </div>
        </section>
      </div>
    </div>
  </section>
  <section class="bg-blue-100 pt-4" id="archive">
    <div class="container mx-auto px-4 lg:px-16 max-w-screen-2xl">
      <div class="w-full">
        <section class="pt-5 px-5 bg-blue-100">
          <div class="grid grid-cols-1 lg:grid-cols-5 grid-rows-1 gap-4 p-4">
            <div class="lg:col-span-2">
              <p class="text-md">
                Missed our recent podcasts? Here you can find our entire back
                catalogue and catch up on our latest investment ideas.
              </p>
            </div>
            <div class="col-span-1"></div>
            <div
              class="lg:col-span-2 text-lg lg:text-xl leading-none font-semibold text-right"
            ></div>
          </div>
        </section>

        <section class="px-5 bg-blue-100 pb-4">
          <div class="content-wrapper limit-pagewidth">
            <div class="grid grid-cols-1 lg:grid-cols-4 gap-2 p-4">
              <div class="col-span-1 lg:col-span-2">
                @if (podcastCompanies()) {
                  <input
                    class="w-full"
                    type="text"
                    placeholder="FIND PODCASTS"
                    (input)="onSearchInput($event)"
                    [cdkMenuTriggerFor]="podcastCompanyList"
                    class="px-3 py-2 text-base border border-gray-300 cursor-text w-full"
                    #inputElement
                    (cdkMenuOpened)="focusInput()"
                  />
                }
              </div>
              <div class="col-span-1 lg:col-span-2">
                <app-pagination
                  (pageChanged)="fetchMorePodcasts($event)"
                  [currentPage]="currentPage"
                  [itemsPerPage]="itemsPerPage"
                  [totalItems]="totalItems"
                  class="mx-auto lg:float-right"
                ></app-pagination>
              </div>
            </div>
          </div>
        </section>
        <section class="px-5 bg-blue-100 pb-4">
          <div class="content-wrapper limit-pagewidth">
            <app-podcast-list [podcastList]="podcastList"></app-podcast-list>
          </div>
        </section>
        <section class="px-5 bg-blue-100 pb-4">
          <div class="content-wrapper limit-pagewidth">
            <div class="grid grid-cols-1 gap-2 p-4">
              <div class="col-span-1">
                <app-pagination
                  (pageChanged)="fetchMorePodcasts($event)"
                  [currentPage]="currentPage"
                  [itemsPerPage]="itemsPerPage"
                  [totalItems]="totalItems"
                ></app-pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>

  <div class="my-5"></div>

  <hr />
  <!-- subscibe banner-->
</div>

<app-more-content-cta></app-more-content-cta>

<ng-template #podcastCompanyList>
  <div
    cdkMenu
    class="flex flex-col bg-white border border-gray-300 rounded-md py-2 max-h-[50vh] h-full overflow-auto"
  >
    @for (company of searchResults(); track company) {
      <a
        class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
        cdkMenuItem
        (click)="onSearchStringEntered(company)"
      >
        {{ company }}
      </a>
    }
  </div>
</ng-template>
