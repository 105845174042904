<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-6">
        <app-admin-wiki-workflows></app-admin-wiki-workflows>
      </div>
    </div>
  </main>
</div>
