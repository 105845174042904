import { JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, computed, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CompanyInvestmentcaseFormComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-form/company-investmentcase-form.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { InvestmentCaseService } from "~features/company/investment-case.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_EDITORCONTENT_INVESTMENTCASE } from "~shared/initial-values";

@Component({
  selector: "app-company-investmentcase-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    CompanyInvestmentcaseFormComponent,
    JsonPipe,
    AdminSidePanelComponent,
  ],
  templateUrl: "./company-investmentcase-editor.component.html",
  styleUrl: "./company-investmentcase-editor.component.scss",
})
export class CompanyInvestmentcaseEditorComponent {
  route = inject(ActivatedRoute);
  http = inject(HttpClient);

  companyTickerService = inject(CompanyTickerService);
  investmentCaseService = inject(InvestmentCaseService);

  companyTicker = this.companyTickerService.getCompanyTicker();

  investmentCaseContent = computed(() => {
    const investmentCaseValue =
      this.investmentCaseService.getInvestmentCase()();

    return investmentCaseValue !== null
      ? investmentCaseValue
      : INITIAL_EDITORCONTENT_INVESTMENTCASE;
  });

  constructor() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTickerService.setCompanyTicker(ticker);
  }

  onFormSubmit(newData) {
    // console.log(newData);
    this.investmentCaseService.updateInvestmentCase(newData);
  }
}
