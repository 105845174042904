import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IRSSEntry } from "~global-interfaces/IRSSEntry";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { AdminRssEditorFormComponent } from "~features/rss/admin/admin-rss-editor-form/admin-rss-editor-form.component";
import { RssEntryService } from "~features/rss/rss-entry.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-rss-creator",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminRssEditorFormComponent,
    AdminSidePanelComponent,
    CompanySearchComponent,
  ],
  templateUrl: "./admin-rss-creator.component.html",
  styleUrl: "./admin-rss-creator.component.scss",
})
export class AdminRssCreatorComponent {
  rssEntryService = inject(RssEntryService);
  rssEntryEN: WritableSignal<IRSSEntry | null> = signal<IRSSEntry | null>(null);
  rssEntryDE: WritableSignal<IRSSEntry | null> = signal<IRSSEntry | null>(null);

  rssEntryDEMessage: WritableSignal<boolean> = signal<boolean>(false);
  rssEntryENMessage: WritableSignal<boolean> = signal<boolean>(false);
  companyName: WritableSignal<string | null> = signal(null);

  constructor(private route: ActivatedRoute) {}

  onCompanySelected(company: any) {
    this.companyName.set(company.name);
  }

  onFormSubmit(newData: IRSSEntry, lang) {
    //  console.log(newData);
    if (lang === "en") {
      this.rssEntryService
        .createRssEntry(newData)
        .subscribe((updatedRssEntry: IRSSEntry) => {
          this.rssEntryENMessage.set(true);
          this.rssEntryEN.set(updatedRssEntry);
        });
    }
    if (lang === "de") {
      this.rssEntryService
        .createRssEntry(newData)
        .subscribe((updatedRssEntry: IRSSEntry) => {
          this.rssEntryDEMessage.set(true);
          this.rssEntryDE.set(updatedRssEntry);
        });
    }
  }
}
