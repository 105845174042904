/**
 *
 * All the profile keys and possible values are hardcoded.
 * That is not so good, but we want to keep it simple for now and those keys are not likely to change.
 *
 *
 */

export interface AlsterUser {
  _id?: string;
  userId: string;
  userName: string;
  customerType: string; // investor, presse, emittent, etc.
  userRole: string; // admin, customer, etc.
  password: string;
  addinIds: string[];
  rights: UserRights;
  editing: any;
  token: string;
}

export interface UserRights {
  companies: CompanyRights[];
  adminPanel: boolean;
  reportPanel: boolean;
}

export interface CompanyRights {
  companyId: string;
  write: boolean;
  upload: boolean;
  download: boolean;
}

// this model is for better readability, it does not really needs to be implemented
export interface CustomerUser extends AlsterUser {}

export interface NewUser {
  email: string;
  password: string;
  userRole: string;
}

export interface NewContact extends InvestorProfile {
  VORNAME: string;
  NACHNAME: string;
  customerType: string;
  CITY: string;
  COMPANY_NAME: string;
}

export interface InvestorProfile {
  PROFIL_AUM: string;
  PROFIL_GROESSE: string;
  PROFIL_HALTEDAUER: string;
  PROFIL_INVESTITIONSSTIL: string;
  PROFIL_INVESTORENTYP?: string;
  PROFIL_REGION: string;
  PROFIL_TICKET_SIZE: string;
  PROFIL_FREITEXT: string;
}

export class InvestorProfileClass implements Partial<InvestorProfile> {
  constructor(
    public PROFIL_AUM = "",
    public PROFIL_GROESSE = "",
    public PROFIL_HALTEDAUER = "",
    public PROFIL_INVESTITIONSSTIL = "",
    public PROFIL_INVESTORENTYP = "",
    public PROFIL_REGION = "",
    public PROFIL_TICKET_SIZE = ""
  ) {
    //public PROFIL_FREITEXT = ""
  }
}
