import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IEvent } from "~global-interfaces/IEvent";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { DateTimePickerComponent } from "~shared/components/ui/date-time-picker/date-time-picker.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-event-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DateTimePickerComponent,
    NgClass,
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./admin-event-editor-form.component.html",
  styleUrls: ["./admin-event-editor-form.component.scss"],
})
export class AdminEventEditorFormComponent implements OnInit {
  @Input() event!: Signal<IEvent | null>;
  @Output() formSubmit = new EventEmitter<IEvent>();

  eventForm: FormGroup;
  adminRequestService = inject(AdminRequestService);
  constructor(private formBuilder: FormBuilder) {
    this.adminRequestService.emptyLastRequest();
    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  get speakers(): FormArray {
    return this.eventForm.get("speakers") as FormArray;
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.eventForm = this.formBuilder.group({
      title: ["", [Validators.required, Validators.minLength(3)]],
      date: ["", Validators.required],
      language: ["de", [Validators.required, Validators.pattern(/^[a-z]{2}$/)]],
      is_public: [false],

      showFeedback: [false],
      companyName: ["", [Validators.required, Validators.minLength(2)]],
      companyTicker: ["", []],
      videoURL: ["", Validators.pattern(/^https?:\/\/.+/)],
      sibListId: ["", [Validators.required, Validators.pattern(/^\d+$/)]],
      presentationURL: ["", Validators.pattern(/^https?:\/\/.+/)],
      feedbackURL: ["", Validators.pattern(/^https?:\/\/.+/)],
      description: ["", Validators.required],
      companyLogoURL: [""], // Validators.pattern(/^https?:\/\/.+/)
      meetingLink: ["", Validators.pattern(/^https?:\/\/.+/)],
      speakers: this.formBuilder.array([]),
    });
  }

  patchFormValues() {
    if (this.event()) {
      this.eventForm.patchValue({
        title: this.event().title,
        date: this.event().date,
        language: this.event().language ? this.event().language : "de",

        is_public: this.event().is_public,
        showFeedback: this.event().showFeedback,
        companyName: this.event().companyName,
        companyTicker: this.event().companyTicker,
        videoURL: this.event().videoURL,
        sibListId: this.event().sibListId,
        presentationURL: this.event().presentationURL,
        feedbackURL: this.event().feedbackURL,
        description: this.event().description,
        companyLogoURL: this.event().companyLogoURL,
        meetingLink: this.event().meetingLink,
      });
      this.patchSpeakers();
    }
  }

  patchSpeakers() {
    const speakersFormArray = this.eventForm.get("speakers") as FormArray;
    speakersFormArray.clear();
    this.event().speakers?.forEach((speaker) => {
      speakersFormArray.push(
        this.formBuilder.group({
          name: [speaker.name, [Validators.required, Validators.minLength(2)]],
          position: [
            speaker.position,
            [Validators.required, Validators.minLength(2)],
          ],
          imageURL: [
            speaker.imageURL,
            [Validators.required, Validators.pattern(/^https?:\/\/.+/)],
          ],
        })
      );
    });
  }

  addSpeaker() {
    this.speakers.push(
      this.formBuilder.group({
        name: ["", [Validators.required, Validators.minLength(2)]],
        position: ["", [Validators.required, Validators.minLength(2)]],
        imageURL: [
          "",
          [Validators.required, Validators.pattern(/^https?:\/\/.+/)],
        ],
      })
    );
  }

  removeSpeaker(index: number) {
    this.speakers.removeAt(index);
  }

  saveForm() {
    if (this.eventForm.valid) {
      const updatedEvent: IEvent = {
        ...this.event(),
        ...this.eventForm.value,
      };
      this.formSubmit.emit(updatedEvent);
    } else {
      this.markFormGroupTouched(this.eventForm);
    }
  }

  onDateTimeChange(dateTime: string) {
    this.eventForm.patchValue({ date: dateTime });
  }

  getErrorMessage(controlName: string): string {
    const control = this.eventForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
    }
    return "";
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
