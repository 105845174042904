<div class="w-[42rem] text-white text-normal text-md e2e-privacy-cookie">
  <div class="mxodal-header">
    <h4 class="mb-8">Cookie permission</h4>
  </div>
  <div class="mxodal-body">
    <p style="font-size: 1.5rem">
      <i
        >mwb research uses cookies to track and improve site usage patterns. For
        more information, see the
        <a class="underline" href="https://www.research-hub.de/datenschutz">
          Privacy Policy </a
        >. You can revoke your consent at any time in the footer.</i
      >
    </p>
  </div>
  <div class="flex w-full justify-around mt-8">
    <button (click)="dialogRef.close('Accepted')" class="btn" type="button">
      accept all
    </button>
    <button (click)="dialogRef.close()" class="btn" type="button">
      deny cookies
    </button>
  </div>
</div>
