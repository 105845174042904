export function formatTVChartTicker(companyTicker) {
  /**
   * Entering a Ticker for TradingView is more of a search term and not an exact match.
   * The below "mapping" is only kept for legacy reasons until we have checked all companies.
   *
   *
   */

  const exchange = "XETR";
  const code = companyTicker.split(":")[0];
  // const symbolMap = {
  //   "2GI": "MUN",
  //   R1B: "MUN",
  //   CDZ0: "MUN",
  //   "991": "MUN",
  //   NSP: "MIL",
  //   XCX: "DUS",
  //   P7V: "SWB",
  //   ABA: "FWB",
  //   THAG: "SIX",
  //   EM6: "MUN",
  // };
  // /**Hardcode fix for single stocks */
  // if (code === "GSJ") {
  //   code = "SGF";
  // }
  //
  // if (symbolMap.hasOwnProperty(code)) {
  //   exchange = symbolMap[code];
  // }
  //return exchange + ":" + code;
  return code;
}
