import { JsonPipe } from "@angular/common";
import { Component } from "@angular/core";
import { RouterLink } from "@angular/router";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { BreadcrumbService } from "~shared/services/breadcrump.service";

@Component({
  selector: "app-breadcrump",
  standalone: true,
  imports: [RouterLink, JsonPipe, CompanySearchComponent],
  templateUrl: "./breadcrump.component.html",
  styleUrl: "./breadcrump.component.scss",
})
export class BreadcrumpComponent {
  disabledWords = ["admin", "settings", "string", "edit"];

  constructor(public breadcrumbService: BreadcrumbService) {}
}
