import { Clipboard } from "@angular/cdk/clipboard";
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from "@angular/cdk/menu";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { JsonPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  inject,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { RouterLink } from "@angular/router";

import { EventService } from "~features/events/event.service";

@Component({
  selector: "app-event-search",
  standalone: true,
  imports: [
    CdkMenuTrigger,
    CdkMenu,
    CdkMenuItem,
    RouterLink,
    ScrollingModule,
    JsonPipe,
  ],
  templateUrl: "./event-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: "./event-search.component.scss",
})
export class EventSearchComponent {
  @ViewChild(CdkMenuTrigger) menuTrigger!: CdkMenuTrigger;
  @ViewChild("inputElement") inputElement!: ElementRef;
  @Output() companySelected = new EventEmitter<string>();
  searchTerm = signal("");
  eventService = inject(EventService);
  companiesWithEventsWithVideos =
    this.eventService.getCompaniesWithEventsWithVideos();
  searchResults = computed(() => {
    const term = this.searchTerm().toLowerCase();

    if (term === "") {
      return this.companiesWithEventsWithVideos();
    }

    const filteredCompanies = this.companiesWithEventsWithVideos().filter(
      (company) => company.toLowerCase().includes(term)
    );

    // Create a new company object for the search term, this is quite hacky but it works
    const searchTermCompany = this.searchTerm();

    // Return the array with the search term company at the top
    return [searchTermCompany, ...filteredCompanies];

    return filteredCompanies;
  });
  private clipboard = inject(Clipboard);

  constructor() {}

  onSearchStringEntered(searchString: string) {
    this.companySelected.emit(searchString);
    this.menuTrigger.close();
    this.searchTerm.set("");
  }

  onSearchInput(event: Event) {
    console.log(this.inputElement);
    const term = (event.target as HTMLInputElement).value;
    this.searchTerm.set(term);
    if (term !== "" && this.searchResults().length > 0) {
      this.menuTrigger.open();
      this.inputElement.nativeElement.focus();
    } else {
      this.menuTrigger.close();
    }
  }

  focusInput() {
    // Use setTimeout to ensure the focus happens after the menu opens
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }
}
