import { JsonPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  Input,
  Signal,
  ViewChild,
} from "@angular/core";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";

interface ChartConfig {
  data?: any;
  layout?: any;
  config?: any;
  useResizeHandler?: boolean;
}
declare let Plotly: any;
@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JsonPipe],
})
export class ChartComponent {
  @ViewChild("plotlyChart", { static: true }) chartElement!: ElementRef;

  @Input({ required: true }) chart!: Signal<ChartConfig>;

  constructor() {
    effect(() => {
      this.updateChart();
    }, enableSignalWritesInEffectContext);
  }

  updateChart() {
    if (this.chartElement && this.chart()) {
      const { data, layout, config, useResizeHandler } = this.chart();

      Plotly.react(this.chartElement.nativeElement, data, layout, config);

      if (useResizeHandler) {
        Plotly.Plots.resize(this.chartElement.nativeElement);
      }
    } else {
      //console.log("no chartElement found");
    }
  }
}
