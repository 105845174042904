import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-impressum-page",
  templateUrl: "./impressum-page.component.html",
  styleUrls: ["./impressum-page.component.scss"],
  standalone: true,
})
export class ImpressumPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
