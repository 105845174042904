import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ICompany } from "~global-interfaces/ICompany";

import { AdminCompanyResearchEditorFormComponent } from "~features/company/admin/company-research/admin-company-research-editor-form/admin-company-research-editor-form.component";
import { AdminCompanyResearchPdfComponent } from "~features/company/admin/company-research/admin-company-research-pdf/admin-company-research-pdf.component";
import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { AdminCompanySwotCreatorComponent } from "~features/company/admin/company-swot/admin-company-swot-creator/admin-company-swot-creator.component";
import { CompanydataService } from "~features/company/companydata.service";
import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_COMPANY } from "~shared/initial-values";
import { AdminSidePanelService } from "~shared/services/admin-side-panel.service";

@Component({
  selector: "app-admin-company-creator",
  standalone: true,
  imports: [
    FileUploadComponent,
    AdminCompanyResearchEditorFormComponent,
    AdminCompanyResearchPdfComponent,
    EditEditorContentComponent,
    AdminCompanySettingsEditorFormComponent,
    BreadcrumpComponent,
    AdminCompanySwotCreatorComponent,
    CreationProcessMenuComponent,
    AdminSidePanelComponent,
    RouterLink,
  ],
  templateUrl: "./admin-company-creator.component.html",
  styleUrl: "./admin-company-creator.component.scss",
})
export class AdminCompanyCreatorComponent {
  companyDataService = inject(CompanydataService);
  company = signal<ICompany>(INITIAL_COMPANY);
  http = inject(HttpClient);
  creationProcessMenuService = inject(CreationProcessMenuService);
  sidePanelService = inject(AdminSidePanelService);

  onFormSubmit(updateCompanyData: any) {
    //console.log(updateCompanyData);
    this.companyDataService.createCompany(updateCompanyData);
    this.creationProcessMenuService.setStepCompleted("Create Company");
    this.creationProcessMenuService.setCurrentCompanyTicker(
      updateCompanyData.companyTicker
    );
    this.creationProcessMenuService.setCurrentCompanyName(
      updateCompanyData.name
    );
  }
}
