<cdk-accordion class="container py-0 my-0 customFont">
  <cdk-accordion-item
    #accordionItem="cdkAccordionItem"
    [attr.aria-controls]="'accordion-body-A'"
    [attr.aria-expanded]="accordionItem.expanded"
    [attr.id]="'accordion-header-A'"
    [expanded]="headline() === 'Trading Multiples' || !collapse"
    class="example-accordion-item"
    role="button"
    tabindex="0"
  >
    <div
      (click)="accordionItem.toggle()"
      class="flex items-center my-1 py-1 border-t-1 border-[#c7e7fd]"
    >
      <div class="hover:underline text-base font-medium leading-[1.4]">
        @if (accordionItem.expanded) {
          <i class="fa fa-chevron-up px-2 text-blue-500"></i>
        } @else {
          <i class="fa fa-chevron-down px-2 text-blue-500"></i>
        }
      </div>
      <div
        [ngClass]="{
          'hover:underline text-md font-medium leading-[1.4] text-white':
            customDesign,
          'hover:underline text-base font-medium leading-[1.4]': !customDesign
        }"
      >
        {{ headline() }}
      </div>
    </div>
    <div
      [attr.aria-labelledby]="'accordion-header-A'"
      [attr.id]="'accordion-body-A'"
      [style.display]="accordionItem.expanded ? '' : 'none'"
      class="example-accordion-item-body"
      role="region"
    >
      <div class="row-old py-0 my-0">
        <div class="mx-auto mt-2 overflow-x-auto">
          <div>
            <table class="table-auto w-full">
              @if (tableLayout() !== null && tableLayout().length > 0) {
                <tbody>
                  @for (row of tableLayout()[0].data; track row) {
                    <tr
                      class="hover:bg-[#00000013] font-normal text-base leading-[1.4]"
                    >
                      <th
                        class="text-left py-1 border-t border-[#dee2e6]"
                        [attr.colspan]="row.colspan"
                        [ngClass]="getRowClasses(row)"
                      >
                        {{ row.key }}
                      </th>
                      @for (datapoint of row.data; track datapoint) {
                        <td
                          class="text-right p-[.3rem] border-t border-[#dee2e6]"
                          [ngClass]="getRowClasses(row)"
                        >
                          {{ datapoint }}
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              }
            </table>
          </div>

          <p class="text-left text-sm my-2">Source: {{ dataSource.eng }}</p>
        </div>
      </div>
    </div>
  </cdk-accordion-item>
</cdk-accordion>
