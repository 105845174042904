<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-6 md:p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-sm rounded-lg p-6 mb-6">
      <h1 class="text-lg font-bold text-gray-800 mb-2">Event Details</h1>
      <p class="text-gray-600">View and manage event information</p>
    </div>

    @if (event()) {
      <div class="bg-white shadow-sm rounded-lg p-6 md:p-8">
        <dl class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8">
          <div class="col-span-1 md:col-span-2">
            <dt class="text-sm font-medium text-gray-500">Title</dt>
            <dd class="mt-1 text-lg font-semibold text-gray-900">
              {{ event().title }}
            </dd>
            <div class="flex justify-end">
              <a [routerLink]="['edit']">
                <button
                  class="px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Edit Event
                </button>
              </a>
            </div>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Date</dt>
            <dd class="mt-1 text-base text-gray-900">{{ event().date }}</dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Company</dt>
            <dd class="mt-1 text-base text-gray-900">
              {{ event().companyName || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Company Ticker</dt>
            <dd class="mt-1 text-base text-gray-900">
              {{ event().companyTicker || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Video URL</dt>
            <dd class="mt-1 text-base text-gray-900 break-all">
              {{ event().videoURL || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">SIB/Brevo List ID</dt>
            <dd class="mt-1 text-base text-gray-900">
              {{ event().sibListId || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Presentation URL</dt>
            <dd class="mt-1 text-base text-gray-900 break-all">
              {{ event().presentationURL || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Feedback URL</dt>
            <dd class="mt-1 text-base text-gray-900 break-all">
              {{ event().feedbackURL || "-" }}
            </dd>
          </div>
          <div class="col-span-1 md:col-span-2">
            <dt class="text-sm font-medium text-gray-500">Description</dt>
            <dd class="mt-1 text-base text-gray-900">
              {{ event().description || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Company Logo URL</dt>
            <dd class="mt-1 text-base text-gray-900 break-all">
              <app-logo [src]="event().companyLogoURL"></app-logo>
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Meeting Link</dt>
            <dd class="mt-1 text-base text-gray-900 break-all">
              {{ event().meetingLink || "-" }}
            </dd>
          </div>
          <div>
            <dt class="text-sm font-medium text-gray-500">Show Feedback</dt>
            <dd class="mt-1 text-base text-gray-900">
              {{ event().showFeedback ? "Yes" : "No" }}
            </dd>
          </div>
        </dl>

        @if (event().speakers && event().speakers.length > 0) {
          <div class="mt-12">
            <h2 class="text-xl font-bold text-gray-800 mb-6">Speakers</h2>
            <ul class="grid grid-cols-1 md:grid-cols-2 gap-6">
              @for (speaker of event().speakers; track speaker) {
                <li
                  class="flex items-center space-x-4 bg-gray-50 p-4 rounded-lg"
                >
                  <img
                    class="w-16 h-16 rounded-full object-cover border-2 border-gray-200"
                    src="{{ speaker.imageURL }}"
                    alt="{{ speaker.name }}"
                  />
                  <div>
                    <p class="text-lg font-semibold text-gray-900">
                      {{ speaker.name }}
                    </p>
                    <p class="text-sm text-gray-600">{{ speaker.position }}</p>
                  </div>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
  </main>
</div>
