@for (tilerow of tiles(); track tilerow; let rowIndex = $index) {
  <div class="grid grid-cols-12 gap-4">
    @for (tile of tilerow; track tile; let colIndex = $index) {
      <div class="col-span-{{ 12 / tilerow.length }}">
        <app-tile
          class="w-full max-h-60"
          [config]="tile"
          [baseyear]="baseyear"
          [companyData]="companyData"
          (tileEvent)="onTileEvent($event, rowIndex, colIndex)"
        ></app-tile>
      </div>
    }
  </div>
}
