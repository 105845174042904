<div class="flex bg-gray-50 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">SWOT Details for a company</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div class="bg-white shadow-md rounded-lg p-6 md:col-span-2">
        <h2 class="text-md font-secondary text-gray-900 mb-4">SWOT Data</h2>
        <dl class="grid grid-cols-1 gap-y-3 text-sm">
          <div>
            <dt class="font-secondary text-gray-700">ID</dt>
            <dd class="text-gray-900">{{ swotContent()._id }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Created Date</dt>
            <dd class="text-gray-900">{{ swotContent().created_date }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Company Ticker</dt>
            <dd class="text-gray-900">{{ swotContent().companyTicker }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Language</dt>
            <dd class="text-gray-900">{{ swotContent().language }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Is Public</dt>
            <dd class="text-gray-900">{{ swotContent().is_public }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Strengths</dt>
            <dd class="text-gray-900">{{ swotContent().strengths }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Weaknesses</dt>
            <dd class="text-gray-900">{{ swotContent().weaknesses }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Opportunities</dt>
            <dd class="text-gray-900">{{ swotContent().opportunities }}</dd>
          </div>
          <div>
            <dt class="font-secondary text-gray-700">Threats</dt>
            <dd class="text-gray-900">{{ swotContent().threats }}</dd>
          </div>
        </dl>
      </div>

      <div class="bg-white shadow-md rounded-lg p-6 md:col-span-1">
        <h2 class="text-md font-secondary text-gray-900 mb-4">Actions</h2>
        <div>
          <a [routerLink]="['edit']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit SWOT
            </button>
          </a>
        </div>
      </div>
    </div>
  </main>
</div>
