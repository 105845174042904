<div class="my-3 py-3 bg-white rounded-2xl">
  <div class="container mx-auto">
    <div class="flex flex-wrap">
      <div class="w-full">
        <ul class="hidden lg:flex justify-center">
          @for (char of alphabetArray; track char) {
            <li class="mx-1">
              @if (char !== "#") {
                <a
                  [attr.disabled]="!groupedCompanies[char]"
                  [ngClass]="
                    groupedCompanies[char]
                      ? 'text-blue-500 cursor-pointer'
                      : 'text-gray-500 cursor-not-allowed'
                  "
                  class="px-3 py-1 rounded-lg"
                  (click)="groupedCompanies[char] && scrollIntoView(char)"
                  >{{ char }}</a
                >
              } @else {
                <a
                  [attr.disabled]="!groupedCompanies['numbers']"
                  [ngClass]="
                    groupedCompanies['numbers']
                      ? 'text-blue-500 cursor-pointer'
                      : 'text-gray-500 cursor-not-allowed'
                  "
                  class="px-3 py-1 rounded-lg"
                  (click)="
                    groupedCompanies['numbers'] && scrollIntoView('numbers')
                  "
                  >#</a
                >
              }
            </li>
          }
        </ul>
      </div>
    </div>
    <div class="flex flex-wrap">
      <div class="w-full">
        @for (char of alphabetArray; track char) {
          @if (groupedCompanies[char === "#" ? "numbers" : char]) {
            <div>
              <h5 class="text-xl" [id]="char === '#' ? 'numbers' : char">
                {{ char === "#" ? "#" : char }}
              </h5>
              <hr class="my-2" />
              <ul class="space-y-2">
                @for (
                  company of groupedCompanies[char === "#" ? "numbers" : char];
                  track company.name
                ) {
                  <li>
                    <a [routerLink]="['/companies', company.slug]">
                      <button
                        class="text-left text-blue-500 uppercase focus:outline-none hover:underline"
                      >
                        {{ company.name }}
                      </button>
                    </a>
                  </li>
                }
              </ul>
            </div>
          }
        }
      </div>
    </div>
  </div>
</div>
