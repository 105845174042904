import { DatePipe, JsonPipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IEvent } from "~global-interfaces/IEvent";

import { CompanylistService } from "~features/company/companylist.service";
import { ExcelService } from "~features/company/excel.service";
import { AutoSliderLandingPageService } from "~features/custom-content-slider/auto-slider-landing-page.service";
import { DataServiceService } from "~features/data-service.service";
import { EventService } from "~features/events/event.service";
import { createEventRegistrationLink } from "~features/events/event.utils";
import { SubscribeService } from "~features/newsletter-subscription/subscribe.service";
import { DateComponent } from "~shared/components/ui/date/date.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { SliderComponent } from "~shared/components/ui/slider/slider.component";
import { SliderElementComponent } from "~shared/components/ui/slider-element/slider-element.component";

@Component({
  selector: "app-auto-slider-landing-page",
  templateUrl: "./auto-slider-landing-page.component.html",
  styleUrls: ["./auto-slider-landing-page.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    DatePipe,
    SliderComponent,
    SliderElementComponent,
    JsonPipe,
    LogoComponent,
    DateComponent,
  ],
})
export class AutoSliderLandingPageComponent {
  excelService = inject(ExcelService);
  autoSliderService = inject(AutoSliderLandingPageService);
  companyListService = inject(CompanylistService);
  dataService = inject(DataServiceService);
  eventService = inject(EventService);

  randomCompanyInfo = this.autoSliderService.getRandomCompanyInfo();
  randomCompany = this.autoSliderService.getRandomCompany();
  initiationCompanyInfo = this.autoSliderService.getInitiationCompanyInfo();
  initiationCompany = this.autoSliderService.getInitiationCompany();
  customSlideData = this.autoSliderService.getCustomSlideData();
  nextEvent = this.eventService.getNextEvent();
  subscribeService = inject(SubscribeService);

  constructor() {
    this.autoSliderService.fetchCompanyNumbers("RH:ALS", "customSlide");
    this.eventService.fetchNextEvent();
  }

  public customSlideButtonClick() {
    window.open(this.customSlideData()["slider_button_link"]);
  }

  public navigateToEventRegistrationPage(event: IEvent): void {
    const route = createEventRegistrationLink(event.companyTicker, event.date);
    window.open(route);
  }
}
