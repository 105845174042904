import { DatePipe } from "@angular/common";
import { Component, inject, Input, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";

import { VideoPlayerComponent } from "~features/video/video-player/video-player.component";
import { ClipboardService } from "~shared/services/clipboard.service";

@Component({
  selector: "app-event-video-highlight",
  standalone: true,
  imports: [DatePipe, VideoPlayerComponent, RouterLink],
  templateUrl: "./event-video-highlight.component.html",
  styleUrl: "./event-video-highlight.component.scss",
})
export class EventVideoHighlightComponent {
  @Input() videoEvent: WritableSignal<any>;
  @Input() hideDescription = false;
  @Input() isCoveredCompany: WritableSignal<boolean>;
  clipBoardService = inject(ClipboardService);
  protected readonly event = event;
  copyVideoURL(companyTicker, date) {
    this.clipBoardService.copyEventVideoLinkTioClipboard(companyTicker, date);
  }
}
