<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="grid grid-cols-12 gap-8">
      <!-- Guided Actions Section -->
      <section
        class="col-span-12 lg:col-span-7 bg-white rounded-lg shadow-md p-6"
      >
        <h2 class="text-md font-bold mb-6 text-gray-800">Guided Actions</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          @for (action of guidedActions; track action.title) {
            <div
              class="bg-blue-50 rounded-lg p-6 border-l-4 border-blue-500 hover:shadow-lg transition-shadow"
            >
              <p class="text-gray-600 mb-4">{{ action.description }}</p>
              @if (action.route) {
                <a
                  [routerLink]="action.route"
                  class="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors"
                >
                  {{ action.buttonText }}
                </a>
              }
            </div>
          }
        </div>
      </section>

      <!-- Quick Actions Section -->
      <section
        class="col-span-12 lg:col-span-5 bg-white rounded-lg shadow-md p-6"
      >
        <h2 class="text-md font-bold mb-6 text-gray-800">Quick Actions</h2>
        <div class="space-y-4">
          @for (action of quickActions; track action.title) {
            <div
              class="bg-green-50 rounded-lg p-4 hover:shadow-md transition-shadow"
            >
              <p class="text-gray-600 text-sm mb-3">{{ action.description }}</p>
              @if (action.route) {
                <a
                  [routerLink]="action.route"
                  class="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-sm transition-colors"
                >
                  {{ action.buttonText }}
                </a>
              } @else if (action.action) {
                <button
                  (click)="action.action()"
                  class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-sm transition-colors"
                >
                  {{ action.buttonText }}
                </button>
              }
            </div>
          }
        </div>
      </section>

      <!-- Company Highlights Section -->
      <section class="col-span-12 bg-white rounded-lg shadow-md p-6 mt-4">
        <h2 class="text-md font-bold mb-6 text-gray-800">Company Highlights</h2>
        <app-company-highlights-list></app-company-highlights-list>
      </section>
    </div>
  </main>
</div>
