import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IInitiationNote } from "~global-interfaces/IInitiationNote";

import { alsterAPI_URL } from "~api/api-urls";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanylistService } from "~features/company/companylist.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class InitiationNoteService {
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiToken = "cqt1bii3tq5fuzo0c5id";
  private http = inject(HttpClient);
  private companyTickerService = inject(CompanyTickerService);
  private initiationNoteResearch: WritableSignal<IInitiationNote | null> =
    signal(null);
  private initiationNoteContent: WritableSignal<IInitiationNote | null> =
    signal(null);
  private companyListService = inject(CompanylistService);
  private companyList = this.companyListService.getCompanyList();

  constructor() {
    effect(() => {
      const companyTicker = this.companyTickerService.getCompanyTicker();
      if (this.companyList() && companyTicker()) {
        this.fetchInitiationNote(companyTicker());
      }
    }, enableSignalWritesInEffectContext);
    effect(() => {
      if (this.initiationNoteContent()) {
        this.fetchInitiationResearch(this.initiationNoteContent().research);
      }
    }, enableSignalWritesInEffectContext);
  }

  getisLoading() {
    return this.isLoading;
  }

  /**
   *
   * Fetches the Investment Case Editor Content for one Company and stores it in the signal
   *
   */

  fetchInitiationNote(companyTicker) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IInitiationNote>>(
        alsterAPI_URL + "initiationnotes/" + companyTicker
      )
      .pipe(
        map((response) => response.data[0]),
        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch initiation note")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (initiationNoteContent) => {
          this.initiationNoteContent.set(initiationNoteContent);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch initiation note  success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchInitiationResearch(researchID) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IInitiationNote>>(
        alsterAPI_URL + "initiationnotes/research/" + researchID
      )
      .pipe(map((response) => response.data[0]))
      .subscribe((initiationNoteContent) => {
        this.initiationNoteResearch.set(initiationNoteContent);
        this.isLoading.set(false);
      });
  }

  updateInitiationNote(formData) {
    const body = { ...formData };
    console.log(formData);
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<IInitiationNote>>(
        alsterAPI_URL + "initiationnotes/edit/" + formData._id,
        body
      )
      .pipe(
        map((response) => response.data[0]),
        catchError((error) =>
          this.errorHandlingService.handleError(error, "update research")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (initiationNoteContent) => {
          this.initiationNoteContent.set(initiationNoteContent);
          this.adminRequestService.setLastRequestMessage("27", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  createInitiationNote(formData) {
    this.isLoading.set(true);
    const body = { ...formData };
    this.http
      .post<IApiResponse<IInitiationNote>>(
        alsterAPI_URL + "initiationnotes/create",
        body
      )
      .pipe(
        map((response) => response.data[0]),
        catchError((error) =>
          this.errorHandlingService.handleError(error, "update research")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (initiationNoteContent) => {
          this.initiationNoteContent.set(initiationNoteContent);
          this.adminRequestService.setLastRequestMessage("28", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  /**
   * Holds the current editor content for investment case
   */

  public getInitiationNote() {
    return this.initiationNoteContent;
  }

  public getInitiationNoteResearch() {
    return this.initiationNoteResearch;
  }
}
