import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  inject,
  Input,
  signal,
} from "@angular/core";
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { ICompany } from "~global-interfaces/ICompany";
import { IEvent } from "~global-interfaces/IEvent";

import { AuthService } from "~features/auth/auth.service";
import { CompanydataService } from "~features/company/companydata.service";
import { CompanylistService } from "~features/company/companylist.service";
import { EventService } from "~features/events/event.service";
import { timeZoneFormat } from "~features/events/event.utils";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { PosthogService } from "~shared/services/posthog.service";

@Component({
  selector: "app-event-registration",
  templateUrl: "./event-registration.component.html",
  styleUrls: ["./event-registration.component.scss"],
  standalone: true,
  imports: [RouterLink, ReactiveFormsModule, DatePipe, LogoComponent],
})
export class EventRegistrationComponent {
  timeZoneFormat = timeZoneFormat;
  @Input() dataFromURLParams: any = {
    date: null,
    companyTicker: null,
    beDate: null,
  };
  @Input() event: IEvent;

  emailForm: UntypedFormGroup;
  isRegistered = signal(false);
  isPastEvent = signal(false);
  isSent = signal(false);
  isAlreadyRegistered = signal(false);

  sibSignpLink = signal(null);
  posthogService = inject(PosthogService);
  companyDataService = inject(CompanydataService);
  authService = inject(AuthService);

  currentCompanyTicker = signal<ICompany | null>(null);
  logoURL = computed(() => {
    if (this.event && this.event.companyLogoURL) {
      return this.event.companyLogoURL;
    } else if (this.currentCompanyTicker()) {
      return this.companyDataService.getCurrentCompanySettings()()?.logoURL;
    }
    return null;
  });

  prefilledEmail = signal("");
  isAuthenticated = computed(() => this.authService.isAuthenticated());

  constructor(
    private cdr: ChangeDetectorRef,
    private companyListService: CompanylistService,
    private fb: UntypedFormBuilder,
    private eventService: EventService,
    private router: Router
  ) {
    this.emailForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });

    effect(() => {
      const companyList = this.companyListService.getCompanyList()();
      const companyTicker = this.dataFromURLParams.companyTicker;
      this.currentCompanyTicker.set(
        companyList.find((item) => item.companyTicker === companyTicker) || null
      );
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.currentCompanyTicker()) {
        this.companyDataService.fetchCompanySettings(
          this.currentCompanyTicker()!.companyTicker
        );
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.isAuthenticated()) {
        this.prefilledEmail.set(this.authService.getCurrentUserName());
        this.emailForm.controls["email"].setValue(this.prefilledEmail());
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.event) {
        const now = new Date();
        const eventDate = new Date(this.event.date);
        this.isPastEvent.set(now > eventDate);
        // Comment out the next line if you want to actually check for past events
        this.isPastEvent.set(false);
      }
    }, enableSignalWritesInEffectContext);
  }

  saveEventInfoForLaterSubscritionViaDashboard() {
    localStorage.setItem(
      "eventforuseaftersignup",
      JSON.stringify({
        title: this.event.title,
        bloombergTicker: this.event.companyTicker, // legacy
        companyTicker: this.event.companyTicker,
        date: this.event.date,
        description: this.event.description,
        sibListId: this.event.sibListId,
      })
    );
  }

  goToSignUp() {
    this.saveEventInfoForLaterSubscritionViaDashboard();
    this.router.navigate(["/signup"]);
  }

  submitForm() {
    this.posthogService.trackButtonClick("event_register_button_clicked");
    this.eventService
      .registerForEvent(this.emailForm.value.email, this.event.sibListId)
      .subscribe((res: any) => {
        if (res.data[0] === true) {
          this.isRegistered.set(true);
          this.isAlreadyRegistered.set(false);
        } else if (res.data[0] === false) {
          this.isRegistered.set(true);
          this.isAlreadyRegistered.set(true);
        } else if (typeof res.data[0] === "string") {
          this.sibSignpLink.set(res.data[0]);
          this.isRegistered.set(false);
        }
        this.isSent.set(true);
        this.cdr.detectChanges();
      });
  }
}
