import { JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, inject, OnInit, signal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { ICompany } from "~global-interfaces/ICompany";

import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_COMPANY } from "~shared/initial-values";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Component({
  selector: "app-admin-company-settings",
  standalone: true,
  imports: [
    RouterLink,
    FileUploadComponent,
    JsonPipe,
    BreadcrumpComponent,
    AdminSidePanelComponent,
    CompanySearchComponent,
    AdminCompanySettingsEditorFormComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./admin-company-settings.component.html",
  styleUrl: "./admin-company-settings.component.scss",
})
export class AdminCompanySettingsComponent implements OnInit {
  companyDataService = inject(CompanydataService);
  company = signal<ICompany>(INITIAL_COMPANY);
  companyTicker = signal<string>("");
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  excelService = inject(ExcelService);

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTicker.set(ticker);
    this.companyDataService.fetchCompanySettings(this.companyTicker());
    this.company = this.companyDataService.getCurrentCompanySettings();
  }

  onFormSubmit(updateCompanyData: any) {
    this.companyDataService.updateCompany(updateCompanyData);
  }

  publishExcelFigures() {
    this.excelService
      .publicPublishExcelFiguresForCompany(this.companyTicker())
      .subscribe({
        next: () => {
          this.adminRequestService.setLastRequestMessage("23", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}
