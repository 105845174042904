<div class="py-3 min-w-[56rem]">
  <div class="text-right">
    <span (click)="dialogRef.close()" class="cursor-pointer"
      ><strong>X</strong></span
    >
  </div>
  <div class="mt-4">
    <div class="text-center">
      <app-responsive-video
        [videoURL]="data.data.videoURL"
      ></app-responsive-video>
    </div>
  </div>
</div>
