<div class="container mx-auto mt-8">
  <div class="bg-white rounded-lg shadow-md">
    <div
      class="bg-gray-alster text-white rounded-t-lg px-6 py-4 flex items-center justify-between"
    >
      <h3 class="text-lg font-bold">Newsletter</h3>
    </div>
    <div class="p-6">
      @if (!loadingNewData) {
        <ul class="space-y-4">
          @for (list of newsletters; track list; let i = $index) {
            <li class="bg-gray-100 rounded-lg p-4">
              <div class="flex items-center justify-between">
                <span class="text-md font-medium">{{ list.name }}</span>
                <div class="flex items-center">
                  <input
                    class="form-checkbox h-5 w-5 text-blue-600 rounded-md focus:ring-blue-500"
                    id="{{ 'Id' + i }}"
                    type="checkbox"
                    [checked]="list.subscribed"
                    [disabled]="loading"
                    (change)="handleSubscribeToggle(list.id)"
                  />
                  <!--                  <label class="ml-2 text-sm text-gray-700" for="{{ 'Id' + i }}"-->
                  <!--                    >Subscribed</label>-->
                </div>
              </div>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>
