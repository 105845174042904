import { NgClass } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-delete-button",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./delete-button.component.html",
  styleUrl: "./delete-button.component.scss",
})
export class DeleteButtonComponent {
  @Input() deleteText = "Delete";
  @Input() confirmText = "Confirm";
  @Input() confirmationDelay = 10000; // 10 seconds
  @Output() deleteConfirmed = new EventEmitter<void>();
  @Output() deleteRequested = new EventEmitter<void>();
  @Output() deleteReset = new EventEmitter<void>();
  confirmationMode = false;
  private timeoutId: any;

  onButtonClick() {
    if (this.confirmationMode) {
      this.deleteConfirmed.emit();
      this.resetState();
    } else {
      this.confirmationMode = true;
      this.deleteRequested.emit();
      this.timeoutId = setTimeout(
        () => this.resetState(),
        this.confirmationDelay
      );
    }
  }

  private resetState() {
    this.confirmationMode = false;
    this.deleteReset.emit();
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
