import { DatePipe, JsonPipe, NgClass, SlicePipe } from "@angular/common";
import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { IEvent } from "~global-interfaces/IEvent";

import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanylistService } from "~features/company/companylist.service";
import { ArchivedEventsService } from "~features/events/archived-events.service";
import { EventGridArchivedTileComponent } from "~features/events/event-grid-tile-archived/event-grid-archived-tile.component";
import { EventGridUpcomingTileComponent } from "~features/events/event-grid-tile-upcoming/event-grid-upcoming-tile.component";
import { EventService } from "~features/events/event.service";
import {
  createEventRegistrationLink,
  timeZoneFormat,
} from "~features/events/event.utils";
import { ResponsiveVideoComponent } from "~features/video/responsive-video/responsive-video.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-event-grid-mixed",
  templateUrl: "./event-grid-mixed.component.html",
  styleUrls: ["./event-grid-mixed.component.scss"],
  standalone: true,
  imports: [
    ResponsiveVideoComponent,
    RouterLink,
    NgClass,
    SlicePipe,
    DatePipe,
    EventGridUpcomingTileComponent,
    EventGridArchivedTileComponent,
    JsonPipe,
  ],
})
export class EventGridMixedComponent {
  @Input() isEventPage: boolean = false;
  @Input() currentUserRegisteredEvents: any[] = [];
  @Output() reloadEvents = new EventEmitter<any>();
  defaultAmountOfShownEvents = 3;
  public listOfComingEvents = signal<IEvent[]>([]);
  public listOfArchiveEvents = signal<IEvent[]>([]);
  public showAllEvents = signal<boolean>(false);
  public timeZoneFormat = timeZoneFormat;
  public displayedComingEvents = computed(() =>
    this.showAllEvents()
      ? this.listOfComingEvents()
      : this.listOfComingEvents().slice(0, this.defaultAmountOfShownEvents)
  );

  public displayedArchiveEvents = computed(() => {
    const upcomingCount = this.listOfComingEvents().length;
    const archiveEvents = this.listOfArchiveEvents();
    if (this.showAllEvents()) {
      return archiveEvents.slice(0, upcomingCount);
    }
    return archiveEvents.slice(
      0,
      Math.min(upcomingCount, this.defaultAmountOfShownEvents)
    );
  });
  public showMoreButton = computed(() => {
    const upcomingCount = this.listOfComingEvents().length;

    return (
      !this.showAllEvents() && upcomingCount > this.defaultAmountOfShownEvents
    );
  });

  public showVideoLibraryButton = computed(
    () =>
      this.showAllEvents() ||
      this.listOfComingEvents().length <= this.defaultAmountOfShownEvents
  );
  public noEventsAvailable = computed(
    () =>
      this.listOfComingEvents().length === 0 &&
      this.listOfArchiveEvents().length === 0
  );
  private eventService = inject(EventService);
  private companyInfoService = inject(CompanyInfoService);
  private companyListService = inject(CompanylistService);
  private router = inject(Router);
  private eventArchiveService = inject(ArchivedEventsService);

  constructor() {
    effect(() => {
      this.initComingEvents();
      this.initArchiveEvents();
    }, enableSignalWritesInEffectContext);
  }

  public toggleShowAllEvents(): void {
    this.showAllEvents.update((value) => !value);
  }

  public navigateToEventRegistrationPage(event: IEvent): void {
    const route = createEventRegistrationLink(event.companyTicker, event.date);
    window.open(route);
  }

  private initComingEvents(): void {
    const allEvents = this.eventService.getEventsList()();
    const today = new Date().setHours(new Date().getHours() - 2);

    const comingEvents = allEvents
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .filter(
        (event) =>
          event.is_public === true && new Date(event.date).getTime() > today
      )
      .map((event) => ({
        ...event,
        isRegistered: this.currentUserRegisteredEvents.some(
          (myEvent) =>
            myEvent.date === event.date &&
            myEvent.companyTicker === event.companyTicker
        ),
      }));

    this.listOfComingEvents.set(comingEvents);
  }

  private initArchiveEvents(): void {
    const allEvents = this.eventArchiveService.getArchivedEventsList()();
    const companies = this.companyListService.getCompanyList()();
    const archiveEvents = allEvents

      .sort((b, a) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .filter((event) => event.is_public === true)
      .map((event) => ({
        ...event,
        isCovered: companies.some(
          (company) => company.companyTicker === event.companyTicker
        ),
      }));

    this.listOfArchiveEvents.set(archiveEvents);
  }
}
