import { HttpEventType, HttpResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { DragNDropFilesDirective } from "~directives/drag-n-drop-files.directive";
import { FilehandlerService } from "~features/file-manager/filehandler.service";

@Component({
  selector: "app-drive-uploader",
  templateUrl: "./drive-uploader.component.html",
  styleUrls: ["./drive-uploader.component.scss"],
  standalone: true,
  imports: [DragNDropFilesDirective],
})
export class DriveUploaderComponent implements OnInit, OnDestroy {
  files: any = [];
  uploadsComplete = false;
  statusMessage: any = "";
  constructor(private filehandlerService: FilehandlerService) {}

  ngOnInit(): void {}

  addFile(files) {
    this.uploadsComplete = false;
    this.statusMessage = "";
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      const newFile = {
        size: element.size,
        name: element.name,
        file: element,
        eLoaded: 0,
        eTotal: 0,
        eprogress: 0,
        uploadStartet: false,
        uploadComplete: false,
        type: element.type,
        statusMessage: "",
      };

      if (newFile.size > 31457280) {
        newFile.statusMessage = "File is too large (max 30MB)";
      }
      this.files.push(newFile);
    }
    //
  }
  startUpload() {
    this.statusMessage = "";
    const uploaderPromises = [];
    for (let i = 0; i < this.files.length; i++) {
      this.files[i].uploadStartet = true;
      uploaderPromises.push(this.sendFile(this.files[i]));
    }
    Promise.all(uploaderPromises).then((result) => {
      this.uploadsComplete = true;
    });
  }
  sendFile(file): Promise<any> {
    return new Promise((resolve, reject) => {
      this.filehandlerService.uploadToDrive(file.file, "UGH:AGH").subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            file.eLoaded = event.loaded;
            file.eTotal = event.total;
          } else if (event instanceof HttpResponse) {
            const response = event.body as any;
            file.statusMessage = response.data[0][0].status;
            file.uploadComplete = true;
            resolve(event);
          }
        },
        (err) => {
          this.statusMessage = err.error.msg;
          // console.log(err);
          resolve(err);
        },
        () => {}
      );
    });
  }
  deleteSelectedFile(index) {
    this.files.splice(index, 1);
    this.statusMessage = "";
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  ngOnDestroy() {
    this.files = [];
  }
}
