<div class="container mx-auto">
  <div class="grid grid-cols-1">
    <div class="col-span-1">
      <div class="bg-white shadow-md rounded-lg p-6">
        <div class="mb-4">
          <h5 class="text-md font-bold mb-2">Upload Files</h5>
        </div>
        <div class="mb-4">
          <div class="flex items-center mb-3">
            <div class="w-full">
              <input
                #fileInput
                (change)="onFileSelected($event)"
                class="hidden"
                id="fileInput"
                multiple
                type="file"
              />
              <label class="cursor-pointer" for="fileInput">
                <div
                  class="flex items-center justify-between bg-gray-200 rounded-lg px-4 py-2"
                >
                  <span class="text-gray-700">Choose file(s)</span>
                  <svg
                    class="w-6 h-6 text-gray-500 ml-2"
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                  </svg>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  @if (uploadMessage()) {
    <div class="mt-6">
      <div
        class="bg-blue-alster border-l-4 border-blue-500 text-blue-700 p-4"
        role="alert"
      >
        {{ uploadMessage() }}
      </div>
    </div>
  }
  @if (isLoading()) {
    <div class="text-center mt-6">
      <div class="inline-flex items-center">
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        <span>Loading...</span>
      </div>
    </div>
  }

  <div class="grid grid-cols-1 mt-8">
    <div class="col-span-1">
      @for (file of selectedFiles(); track file.name; let i = $index) {
        <div class="bg-white shadow-md rounded-lg p-6 mb-6">
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-1">
              @if (file.type.startsWith("image/")) {
                <div>
                  <img
                    [src]="file.url"
                    [alt]="file.name"
                    class="w-full h-auto rounded-lg"
                  />
                </div>
              }
              @if (!file.type.startsWith("image/")) {
                <div class="flex items-center justify-center h-16">
                  <svg
                    class="w-12 h-12 text-gray-400"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                  </svg>
                </div>
              }
            </div>
            <div class="col-span-5">
              <h5 class="text-sm font-bold mb-2">{{ file.name }}</h5>
              <div class="flex items-center mb-4">
                <input
                  type="text"
                  class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  [formControl]="file.control"
                  (blur)="onFileNameChange(i)"
                />
                <div class="ml-4">
                  @if (!file.uploaded) {
                    <button
                      class="bg-gray-200 hover:bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded"
                      (click)="onFileDeselect(i)"
                    >
                      Deselect
                    </button>
                  }
                </div>
              </div>
              <ul class="bg-gray-100 rounded-lg p-4">
                <li class="mb-2">
                  Size: {{ file.size / 1000000 | number: "1.0-0" }} MB
                  @if (file.size / 1000000 > 10) {
                    <span class="text-[#FF0000] underline font-bold">
                      File is very big!
                    </span>
                  }
                </li>
                <li class="mb-2">Type: {{ file.type }}</li>
                @if (file.uploaded) {
                  <li class="mb-2">{{ file.url }}</li>
                }
                @if (file.uploaded) {
                  <li>
                    <button
                      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                      (click)="copyTextToClipboard(file.url)"
                    >
                      Copy Link
                    </button>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      }
    </div>
    <button
      (click)="uploadFiles()"
      [disabled]="!hasSelectedFiles()"
      [ngClass]="{
        'bg-blue-500 hover:bg-blue-600 text-white': hasSelectedFiles(),
        'bg-gray-200 text-gray-muted': !hasSelectedFiles()
      }"
      class="font-bold py-2 px-4 rounded ml-4"
    >
      Upload
    </button>
  </div>
</div>
