<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <div>
        <p class="text-md font-semibold mb-2">Edit a user</p>
      </div>
      <div class="grid grid-cols-3 gap-1">
        <div class="col-span-1">
          <app-delete-button
            (deleteConfirmed)="deleteUser(user()?._id)"
            (deleteRequested)="toggleDeletionWarning(true)"
            (deleteReset)="toggleDeletionWarning(false)"
            confirmText="Click to confirm User Deletion for {{ user()?.email }}"
            deleteText="Delete User"
          ></app-delete-button>
        </div>
        @if (showDeletionWarning()) {
          <div class="col-span-2 border border-2 border-red-600 p-2">
            <p class="text-sm text-gray-muted">
              User data in Brevo is <b>NOT</b> deleted/changed.
            </p>
          </div>
        }
      </div>
    </div>
    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-4 rounded-lg p-6">
        <app-admin-user-editor-form
          (formSubmit)="onFormSubmit($event)"
          [user]="user"
        ></app-admin-user-editor-form>
      </div>
      <div class="col-span-2 rounded-lg p-6 border-2 bg-gray-50">
        <h3 class="text-lg font-semibold mb-4 flex items-center">
          Investor Profile (Brevo) <app-lock-icon class="ml-2"></app-lock-icon>
        </h3>
        <p class="text-xs text-gray-muted pb-3">
          This data was fetched right now for the Brevo-Contact
          {{ user()?.email }}.
        </p>
        @if (investorProfile()?.investorInfo) {
          <div class="space-y-2">
            @for (
              entry of investorProfile().investorInfo | keyvalue;
              track entry.key
            ) {
              <div
                class="grid grid-cols-2 gap-2 py-1 border-b border-gray-200 last:border-b-0"
              >
                <div class="text-sm font-medium text-gray-600">
                  {{ entry.key }}:
                </div>
                <div class="text-sm break-words">
                  @if (entry.value | json; as jsonValue) {
                    {{ jsonValue }}
                  } @else {
                    {{ entry.value }}
                  }
                </div>
              </div>
            }
          </div>
          @if (investorProfile().investorRegisteredLists?.length) {
            <div class="mt-6">
              <h4 class="text-md font-semibold mb-2">Registered Lists</h4>
              <div
                class="bg-white p-2 rounded-md border border-gray-200 max-h-40 overflow-y-auto"
              >
                @for (
                  list of investorProfile().investorRegisteredLists;
                  track list.listID
                ) {
                  <div
                    class="text-sm py-1 border-b border-gray-100 last:border-b-0"
                  >
                    {{ list.listID }}
                  </div>
                }
              </div>
            </div>
          }
        } @else {
          <p class="text-sm text-gray-500">No investor profile available.</p>
        }
      </div>
    </div>
  </main>
</div>
