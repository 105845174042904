import { JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";

import { AdminCompanyResearchEditorFormComponent } from "~features/company/admin/company-research/admin-company-research-editor-form/admin-company-research-editor-form.component";
import { AdminCompanyResearchPdfComponent } from "~features/company/admin/company-research/admin-company-research-pdf/admin-company-research-pdf.component";
import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { CreateResearchProcessService } from "~features/guided-research-creation-flow/create-research-process.service";
import { ResearchService } from "~features/research/research.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Component({
  selector: "app-admin-company-research-editor",
  standalone: true,
  imports: [
    DisplayEditorContentComponent,
    JsonPipe,
    EditEditorContentComponent,
    AdminCompanyResearchPdfComponent,
    AdminCompanyResearchEditorFormComponent,
    BreadcrumpComponent,
    FileUploadComponent,
    AdminSidePanelComponent,
    RouterLink,
  ],
  templateUrl: "./admin-company-research-editor.component.html",
  styleUrl: "./admin-company-research-editor.component.scss",
})
export class AdminCompanyResearchEditorComponent {
  route = inject(ActivatedRoute);
  http = inject(HttpClient);

  companyDataService = inject(CompanydataService);
  researchService = inject(ResearchService);
  companyResearchService = inject(CompanyResearchService);

  companyTickerService = inject(CompanyTickerService);

  companyTicker = this.companyTickerService.getCompanyTicker();
  researchTitle = signal<string>("");
  research = this.companyResearchService.getResearchArticle();
  adminRequestService = inject(AdminRequestService);
  excelService = inject(ExcelService);
  errorHandlingService = inject(ErrorHandlingService);
  createResearchProcessService = inject(CreateResearchProcessService);

  constructor() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker") ?? "";
    this.companyTickerService.setCompanyTicker(ticker);

    const researchTitle =
      this.route.snapshot.paramMap.get("researchTitle") ?? "";
    this.researchTitle.set(researchTitle);

    this.companyResearchService.fetchResearch(
      this.companyTicker(),
      this.researchTitle()
    );
  }

  onFormSubmit(formData: any) {
    this.researchService.updateResearch(formData);
  }
}
