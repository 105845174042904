<div
  class="text-center p-4 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer"
  (click)="fileDropRef.click()"
  appDragNDropFiles
  (fileDropped)="addFile($event)"
>
  <input
    hidden
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="addFile($event.target.files)"
    multiple
  />
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="64"
    viewBox="0 0 63 64"
    class="mx-auto mb-4"
  >
    <g fill="#3B454F" fill-rule="nonzero">
      <path
        d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z"
      />
      <path
        d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z"
      />
      <path
        d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z"
      />
    </g>
  </svg>
  <h3 class="text-xl font-bold mb-2">Drag and drop files here</h3>
  <h3 class="text-xl font-bold mb-2">or</h3>
  <label for="fileDropRef" class="text-blue-500 cursor-pointer"
    >click here to browse for files</label
  >
</div>

<div class="mt-4">
  <div>
    @for (file of files; track file; let i = $index) {
      <div
        class="flex items-center justify-between p-3 border-b border-gray-200"
      >
        <div class="w-1/4">{{ file.name }}</div>
        <div class="w-1/6">{{ formatBytes(file?.size, 2) }}</div>
        <div class="w-1/6 text-center">
          @if (file.uploadStartet && !file.uploadComplete) {
            <span>uploading...</span>
          }
          @if (file.uploadComplete && file.statusMessage === "ok") {
            <span>upload complete!</span>
          }
          @if (file.uploadComplete && file.statusMessage !== "ok") {
            <span>upload canceled</span>
          }
        </div>
        <div class="w-1/3 text-center">
          @if (file.statusMessage !== "ok") {
            <span class="text-red-500">{{ file.statusMessage }}</span>
          }
        </div>
        <div class="w-1/12 text-right">
          <button
            class="text-gray-500 hover:text-gray-700"
            (click)="deleteSelectedFile(i)"
          >
            <span class="fa mr-1"> X </span>
          </button>
        </div>
      </div>
    }
  </div>
</div>
<div class="mt-4">
  <div class="text-center">
    @if (files.length > 0 && !uploadsComplete) {
      <button
        (click)="startUpload()"
        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Upload {{ files.length }} files now
      </button>
    }
    @if (files.length > 0 && uploadsComplete) {
      <button
        class="px-4 py-2 bg-gray-400 text-white rounded cursor-not-allowed"
        disabled
      >
        All uploads complete
      </button>
    }
    @if (files.length < 1) {
      <button
        class="px-4 py-2 bg-gray-400 text-white rounded cursor-not-allowed"
        disabled
      >
        Upload files now
      </button>
    }
  </div>
</div>
<div class="mt-4">
  <div class="text-center">
    <p>{{ statusMessage }}</p>
  </div>
</div>
