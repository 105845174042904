<div class="bg-white shadow-md rounded-lg p-6 md:col-span-3">
  <form [formGroup]="leewayConnectionForm">
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700" for="companyTicker"
        >Company Ticker</label
      >
      <input
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        formControlName="companyTicker"
        id="companyTicker"
        type="text"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-medium text-gray-700" for="companyName"
        >Company Name</label
      >
      <input
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        formControlName="companyName"
        id="companyName"
        type="text"
      />
    </div>
    @if (!multiMode) {
      <app-admin-form-button
        (click)="saveForm()"
        [class]="'admin-form-button'"
        [disabled]="!leewayConnectionForm.valid"
        [overwriteText]="'Create Leeway Connection'"
      >
      </app-admin-form-button>
    }
  </form>
  @if (!multiMode) {
    <div class="mt-4">
      @if (isLoading()) {
        <p>
          Creating connection between: researchHub <-> Leeway <-> Excel/Word.
        </p>
      }
      <app-admin-form-request-message></app-admin-form-request-message>
    </div>
  }
</div>
