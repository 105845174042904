import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { ICompany } from "~global-interfaces/ICompany";

import { alsterAPI_URL } from "~api/api-urls";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Injectable({
  providedIn: "root",
})
export class CompanyHighlightService {
  companyHighlights: WritableSignal<ICompany[] | null> = signal(null);
  http = inject(HttpClient);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);

  constructor() {
    //console.log("IM AM THE COMPANY HIGHLIGHT LIST CONSTRUCTOR");
    this.fetchHighlightedCompanies();
  }

  getisLoading() {
    return this.isLoading;
  }
  fetchHighlightedCompanies() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ICompany[]>>(alsterAPI_URL + "companies/highlights")
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetching highlighted companies"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (companyList) => {
          this.companyHighlights.set(
            companyList.filter((company) => company.is_public === true)
          );
          this.adminRequestService.setLastRequestMessage("2", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
  public getCompanyHighlights() {
    return this.companyHighlights;
  }
}
