<app-slider>
  @if (customSlideData() && customSlideData()["title"] !== "#EMPTY") {
    <app-slider-element>
      <div class="bg-white p-6 shadow-lg h-[28rem] overflow-hidden">
        <div class="grid grid-cols-1 lg:grid-cols-5 gap-8 items-center h-full">
          <div class="lg:col-span-2 space-y-4">
            <h3 class="italic font-semibold leading-none text-md">
              {{ customSlideData()?.subtitle }}
            </h3>
            <h2 class="text-lg font-semibold leading-none">
              {{ customSlideData()?.title }}
            </h2>
            <p class="text-md line-clamp-6 leading-[1.4]">
              {{ customSlideData()?.description }}
            </p>
            <button
              class="btn btn-std uppercase text-smd ml-auto block"
              (click)="customSlideButtonClick()"
            >
              {{ customSlideData()?.slider_button }}
            </button>
          </div>
          <div class="hidden lg:block lg:col-span-3">
            <img
              class="w-full h-64 object-cover"
              loading="lazy"
              [src]="customSlideData()?.imageURL"
              alt="ResearchHub"
            />
          </div>
        </div>
      </div>
    </app-slider-element>
  }

  <app-slider-element>
    <div class="bg-white p-6 shadow-lg h-[28rem] overflow-hidden">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-8 items-center h-full">
        <div class="lg:col-span-2 space-y-4">
          <h3 class="italic font-semibold leading-none text-md">
            Check out...
          </h3>
          <h2 class="text-lg font-semibold leading-none">
            {{ randomCompanyInfo()?.name }}
            @if (randomCompany()?.general_information.cus_rating) {
              , {{ randomCompany()?.general_information.cus_rating }}
            }
          </h2>
          <p class="text-md line-clamp-6 leading-[1.4]">
            {{ randomCompany()?.general_information.description }}
          </p>
          <button
            [fragment]="'X'"
            [routerLink]="['/companies', randomCompanyInfo()?.name]"
            class="btn btn-std uppercase text-smd ml-auto block"
          >
            Explore
          </button>
        </div>
        <div class="hidden lg:block lg:col-span-3 space-y-2">
          <div class="text-right text-gray-500 text-xl font-semibold">
            Existing Coverage
          </div>
          <img
            [src]="randomCompanyInfo()?.imageURL"
            alt="ResearchHub"
            class="w-full h-64 object-cover"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </app-slider-element>

  <app-slider-element>
    <div class="bg-white p-6 shadow-lg h-[28rem] overflow-hidden">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-8 items-center h-full">
        <div class="lg:col-span-2 space-y-4">
          <h2 class="text-lg font-semibold leading-none">
            {{ nextEvent()?.title }}
          </h2>
          <h3 class="italic font-semibold leading-none text-md">
            <app-date
              [date]="nextEvent()?.date"
              [formatType]="'MMM dd, yyyy, HH:mm'"
            ></app-date>
          </h3>
          <p class="text-md line-clamp-6 leading-[1.4]">
            {{ nextEvent()?.description }}
          </p>
          <button
            (click)="navigateToEventRegistrationPage(nextEvent())"
            class="btn btn-std uppercase text-smd ml-auto block"
          >
            Register Now
          </button>
        </div>
        <div class="hidden lg:block lg:col-span-3 space-y-2">
          <div class="text-right text-gray-500 text-xl font-semibold">
            Next Event
          </div>

          <img
            [src]="'assets/event-slider-picture.webp'"
            alt="ResearchHub"
            class="w-full h-64 object-cover"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </app-slider-element>

  @if (initiationCompany()) {
    <app-slider-element>
      <div class="bg-white p-6 shadow-lg h-[28rem] overflow-hidden">
        <div class="grid grid-cols-1 lg:grid-cols-5 gap-8 items-center h-full">
          <div class="lg:col-span-2 space-y-4">
            <h3 class="italic font-semibold leading-none text-md">
              Latest initiation
            </h3>
            <h2 class="text-lg font-semibold leading-none">
              {{ initiationCompanyInfo()?.name }}
              @if (initiationCompany()?.general_information.cus_rating) {
                , {{ initiationCompany()?.general_information.cus_rating }}
              }
            </h2>
            <p class="text-md line-clamp-6 leading-[1.4]">
              {{ initiationCompany()?.general_information.description }}
            </p>
            <button
              class="btn btn-std uppercase text-smd ml-auto block"
              [routerLink]="['/companies', initiationCompanyInfo()?.name]"
              [fragment]="'X'"
            >
              Explore
            </button>
          </div>
          <div class="hidden lg:block lg:col-span-3 space-y-2">
            <div class="text-right text-gray-500 text-xl font-semibold">
              Welcome to our Coverage
            </div>
            <img
              class="w-full h-64 object-cover"
              loading="lazy"
              [src]="initiationCompanyInfo()?.imageURL"
              alt="ResearchHub"
            />
          </div>
        </div>
      </div>
    </app-slider-element>
  }

  <app-slider-element>
    <div class="bg-white p-6 shadow-lg h-[28rem] overflow-hidden">
      <div class="grid grid-cols-1 lg:grid-cols-5 gap-8 items-center h-full">
        <div class="lg:col-span-2 space-y-4">
          <h2 class="text-lg font-semibold leading-none">Subscribe here</h2>
          <h3 class="italic font-semibold leading-none text-md">
            On time and 100% free of charge
          </h3>
          <p class="text-md line-clamp-6 leading-[1.4]">
            Get MiFID II-compliant
            <strong>research and event</strong> invitations to your inbox.
          </p>
          <p class="hidden lg:flex text-md line-clamp-6 leading-[1.4]">
            Join <strong>1,700+ professional investors</strong> and do not miss
            out on hundreds of updates and events every year.
          </p>
          <div class="flex justify-between items-center">
            <div class="flex items-center space-x-4">
              <a href="https://de.linkedin.com/company/srh-alsterresearch-ag">
                <img
                  alt="logo linkedin"
                  class="h-8"
                  loading="lazy"
                  src="../../../../assets/linkedin.svg"
                />
              </a>
              <a href="https://twitter.com/mwbresearch">
                <img
                  alt="logo twitter"
                  class="h-6"
                  loading="lazy"
                  src="../../../../assets/twitter-logo.svg"
                />
              </a>
            </div>
            <div>
              <div class="text-gray-muted text-baseplus mb-2">
                Cancel anytime.
              </div>
              <a [routerLink]="'/signup'">
                <button class="btn btn-std uppercase text-smd">
                  Subscribe
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="hidden lg:block lg:col-span-3 space-y-2">
          <div class="text-right text-gray-500 text-xl font-semibold">
            Into your Inbox
          </div>
          <img
            alt="ResearchHub"
            class="w-full h-64 object-cover"
            loading="lazy"
            src="../../../../assets/subscribe-slide-image.webp"
          />
        </div>
      </div>
    </div>
  </app-slider-element>

  <app-slider-element></app-slider-element>
</app-slider>
