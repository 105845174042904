<div
  class="min-h-screen bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8"
>
  <div class="max-w-4xl w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
    <h2 class="text-lg font-extrabold text-gray-900 text-center">
      Cookie Consent Required
    </h2>
    <div class="mt-8 space-y-6">
      <p class="text-md text-gray-700 text-center">
        Dear visitor, we analyze website traffic to better understand the needs
        of our users and to tailor our product accordingly. Please understand
        that we cannot provide access to our website without your consent to our
        cookie policy.
      </p>
      <div class="flex justify-center">
        <a href="/">
          <button
            class="px-6 py-3 border border-transparent text-base font-medium uppercase tracking-widest text-white bg-blue-research hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition duration-150 ease-in-out"
          >
            Back to Cookie Consent
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
