import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";

import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { CompanyGridHighlightComponent } from "~features/company-grid/company-grid-highlight/company-grid-highlight.component";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";

@Component({
  selector: "app-more-content-cta",
  standalone: true,
  imports: [
    CompanyGridHighlightComponent,
    CompanyGridComponent,
    SubscribeBannerComponent,
    RouterLink,
  ],
  templateUrl: "./more-content-cta.component.html",
  styleUrl: "./more-content-cta.component.scss",
})
export class MoreContentCtaComponent {
  @Input() showUpdates = true;
  @Input() showAllCompanies = true;
  @Input() showSubscribeBanner = true;
}
