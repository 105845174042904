<div class="py-6 bg-gray-100 rounded-lg min-w-[62rem]">
  <div class="container mx-auto">
    <div class="flex flex-col items-end">
      <button
        (click)="dialogRef.close()"
        class="text-gray-600 hover:text-gray-800 focus:outline-none"
      >
        <span class="font-bold text-lg">×</span>
      </button>
      <div class="self-center mb-4">
        <h3 class="text-2xl font-bold text-gray-800">Update Your Profile</h3>
      </div>
    </div>
    <div class="flex justify-center">
      <div class="w-full md:w-2/3">
        <app-investor-profile-form
          (profileUpdated)="onProfileUpdated($event)"
          [currentProfile]="data.data"
        ></app-investor-profile-form>
      </div>
    </div>
  </div>
</div>
