<form
  [formGroup]="frontendConfigForm"
  class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-6">
    <h3 class="text-lg font-secondary text-gray-900 mb-4">
      Renamed Companies:
    </h3>
    <p>
      When a Company is renamed, the link to their details page will break. Add
      the mapping here.
    </p>
    <div formArrayName="renamedCompanies">
      @for (
        company of renamedCompanies.controls;
        track company;
        let i = $index
      ) {
        <div [formGroupName]="i" class="bg-gray-50 rounded-lg p-4 mb-4">
          <div class="grid grid-cols-2 gap-4">
            <div>
              <label
                class="block text-gray-700 text-sm font-secondary mb-2"
                [for]="'oldName_' + i"
              >
                Old Name
              </label>
              <input
                [id]="'oldName_' + i"
                type="text"
                formControlName="oldName"
                class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                [ngClass]="{ 'border-red-500': isInvalidAndTouched('oldName') }"
              />
              @if (isInvalidAndTouched("oldName")) {
                <p class="text-red-500 text-xs mt-1">
                  {{ getErrorMessage("oldName") }}
                </p>
              }
            </div>
            <div>
              <label
                class="block text-gray-700 text-sm font-secondary mb-2"
                [for]="'newName_' + i"
              >
                New Name
              </label>
              <input
                [id]="'newName_' + i"
                type="text"
                formControlName="newName"
                class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                [ngClass]="{ 'border-red-500': isInvalidAndTouched('newName') }"
              />
              @if (isInvalidAndTouched("newName")) {
                <p class="text-red-500 text-xs mt-1">
                  {{ getErrorMessage("newName") }}
                </p>
              }
            </div>
          </div>
          <button
            type="button"
            (click)="removeRenamedCompany(i)"
            class="mt-2 px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Remove
          </button>
        </div>
      }
    </div>
    <button
      (click)="addRenamedCompany()"
      class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      type="button"
    >
      Add Renamed Company
    </button>
  </div>

  <div class="mb-6">
    <h3 class="text-lg font-secondary text-gray-900 mb-4">
      Hidden Company Tickers:
    </h3>
    <p>
      Companies for the Landingpage Slider "Check out"-Slide are selected
      randomly, add tickers here to exclude them from the selection.
    </p>
    <div formArrayName="hiddenFromSliderCompanyTickers">
      @for (
        ticker of hiddenFromSliderCompanyTickers.controls;
        track ticker;
        let i = $index
      ) {
        <div class="bg-gray-50 rounded-lg p-4 mb-4">
          <div class="flex items-center space-x-4">
            <input
              [id]="'hiddenTicker_' + i"
              type="text"
              [formControlName]="i"
              class="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              [ngClass]="{
                'border-red-500': isInvalidAndTouched(
                  'hiddenFromSliderCompanyTickers.' + i
                )
              }"
            />
            <button
              type="button"
              (click)="removeHiddenCompanyTicker(i)"
              class="px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Remove
            </button>
          </div>
          @if (isInvalidAndTouched("hiddenFromSliderCompanyTickers." + i)) {
            <p class="text-red-500 text-xs mt-1">
              {{ getErrorMessage("hiddenFromSliderCompanyTickers." + i) }}
            </p>
          }
        </div>
      }
    </div>
    <button
      (click)="addHiddenCompanyTicker()"
      class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
      type="button"
    >
      Add Hidden Company Ticker
    </button>
  </div>

  <div class="mt-8">
    <app-admin-form-button
      (click)="saveForm()"
      [class]="'admin-form-button'"
      [disabled]="!frontendConfigForm.valid"
    >
    </app-admin-form-button>
  </div>
  <app-admin-form-request-message></app-admin-form-request-message>
</form>
