<div class="grid grid-col-2 lg:grid-cols-4 gap-8">
  @for (company of displayedCompanies(); track company) {
    <app-company-grid-tile [company]="company"></app-company-grid-tile>
  }
</div>
<div class="flex">
  <button
    (click)="toggleCompanyDisplay()"
    class="mx-auto mt-4 text-gray-muted tracking-widest uppercase text-base font-medium"
  >
    @if (!showAllCompanies()) {
      <span>show all ({{ remainingCompanies() }} more)</span>
      <i class="fas fa-arrow-down"></i>
    } @else {
      <span>show less</span>
      <i class="fas fa-arrow-up"></i>
    }
  </button>
</div>

<!--<button class="btn" (click)="showMore()" >more</button>-->
<!--<button class="btn" (click)="showLess()" >less</button>-->

<!--<div class="company-grid container-fluid">-->

<!--  @if (displayMode === "full" || displayMode === "welcome-page-page-top-half") {-->
<!--    <div class="row">-->
<!--      <div class="col-12 col-md-6">-->
<!--        <app-company-search></app-company-search>-->
<!--      </div>-->
<!--      <div class="col-12 col-md-6 float-md-right my-auto">-->
<!--        <a [routerLink]="['/company-screener']" ]>-->
<!--        <button-->
<!--          class="btn btn-link pr-0 float-md-right fingerFix"-->

<!--        >-->
<!--          try advanced search <i class="fas fa-arrow-right"></i>-->
<!--        </button>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  }-->

<!--  @if (displayMode !== "welcome-page-page-bottom-half") {-->
<!--    <div class="row mt-5">-->
<!--      <div class="col-12">-->
<!--        <p class="text-muted uppercase bigger">Recent Updates</p>-->
<!--      </div>-->
<!--      &lt;!&ndash; Das hier ist das Highlight Ding (wird in Adminpanel gesetzt) &ndash;&gt;-->
<!--      @for (company of shortenedHighlightList; track company) {-->
<!--        <div-->
<!--          class="companyTile my-2 my-md-3 top-bottom-spread col-md-12 fingerFix e2e-company-update-item"-->
<!--          [ngClass]="'col-lg-' + 12 / companyHighlightsPerRow"-->
<!--        >-->
<!--          &lt;!&ndash;   <div class=" pb-3  w-100 h-100 mx-auto"-->
<!--          [routerLink]="company.featuredStock === true && i >4?['/weeklyScreener-page/weeklyScreener-page/']:['/companies/snapshot/', company.name]">-->
<!--          &ndash;&gt;-->
<!--          <div-->
<!--            class="row w-100 h-100 mx-auto"-->
<!--            [routerLink]="['/companies', company.name]"-->
<!--            [fragment]="'X'"-->
<!--          >-->
<!--            <div class="col-12 col-md-6 pb-3">-->
<!--              <div class="row mx-auto pt-3 w-100">-->
<!--                <div class="col-12">-->
<!--                  <h4>-->
<!--                    {{ company.name }}-->
<!--                    <span-->
<!--                      class="float-right fi fi flagColorFilter"-->
<!--                      [ngClass]="selectCountryFlag(company.country)"-->
<!--                    ></span>-->
<!--                  </h4>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row mx-auto w-100 mt-2">-->
<!--                <div class="col-12 col-md-7 pr-0">-->
<!--                  @if (company.isFeatured !== "Featured Stock") {-->
<!--                    <span-->
<!--                      [ngClass]="getColourClass(company.isFeatured)"-->
<!--                      class="label d-inline-block small uppercase mw-100 mw-md-75"-->
<!--                    >-->
<!--                      {{ company.isFeatured }}-->
<!--                    </span>-->
<!--                  }-->
<!--                  &lt;!&ndash;  DAS HIER IST DER BTN LINK ZUM SCREENER&ndash;&gt;-->
<!--                  @if (company.isFeatured === "Featured Stock") {-->
<!--                    <span-->
<!--                      [routerLink]="[-->
<!--                        '/weeklyScreener-page/weeklyScreener-page/'-->
<!--                      ]"-->
<!--                      [fragment]="'X'"-->
<!--                      class="btn-link fingerFix d-inline-block small uppercase mw-100 mw-75"-->
<!--                    >-->
<!--                      Featured Stock <i class="fas fa-arrow-right"></i>-->
<!--                    </span>-->
<!--                  }-->
<!--                </div>-->
<!--                <div-->
<!--                  class="col-12 col-md-5 my-2 my-md-0 pl-md-0 text-right d-flex flex-row justify-content-between d-md-block"-->
<!--                >-->
<!--                  @if (!company.close) {-->
<!--                    <div>-->
<!--                      <app-loading-indicator></app-loading-indicator>-->
<!--                    </div>-->
<!--                  }-->
<!--                  <div class="">-->
<!--                    @if (company.close) {-->
<!--                      <span>{{ company.currency }} </span>-->
<!--                    }-->
<!--                    @if (company.close !== "NA") {-->
<!--                      <span>{{-->
<!--                        company.close | number: "1.2-2" || "n/a"-->
<!--                      }}</span>-->
<!--                    }-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class=""-->
<!--                    [ngClass]="-->
<!--                      company.change_p < 0 ? 'negativeRed' : 'positiveGreen'-->
<!--                    "-->
<!--                  >-->
<!--                    @if (company.close !== "NA") {-->
<!--                      <span>{{-->
<!--                        company.change_p | number: "1.1-1" || "n/a"-->
<!--                      }}</span>-->
<!--                    }-->
<!--                    @if (company.close) {-->
<!--                      <span>%</span>-->
<!--                    }-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="caps signalWord"-->
<!--                    [ngStyle]="{ color: recColors[company.rating] }"-->
<!--                  >-->
<!--                    {{ company.rating }}-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col-12 uppercase small lineHeight-1 mb-2">-->
<!--                  mwb research AG-->
<!--                </div>-->
<!--                <div-->
<!--                  class="col-12 text-muted uppercase small lineHeight-1 mb-2"-->
<!--                >-->
<!--                  {{ company.latestResearchTitle }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-6 d-none d-md-block pr-0">-->
<!--              <div class="w-100 h-100">-->
<!--                <img-->
<!--                  src="{{ company.imageURL }}"-->
<!--                  alt=""-->
<!--                  style="width: 100%; object-fit: cover; height: 100%"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      }-->
<!--      @if (displayMode !== "companyPage") {-->
<!--        <div class="col-12 show-all">-->
<!--          @if (highlightedList.length - shortenedHighlightList.length > 0) {-->
<!--            <button-->
<!--              (click)="showFullHighlights()"-->
<!--              class="btn text-muted smaller"-->
<!--            >-->
<!--              show all ({{-->
<!--                highlightedList.length - shortenedHighlightList.length-->
<!--              }}-->
<!--              more) <i class="fas fa-arrow-right"></i>-->
<!--            </button>-->
<!--          }-->
<!--          @if (highlightedList.length - shortenedHighlightList.length === 0) {-->
<!--            <button-->
<!--              (click)="shortenHighlights(highlightedList)"-->
<!--              class="btn text-muted smaller"-->
<!--            >-->
<!--              show less-->
<!--            </button>-->
<!--          }-->
<!--        </div>-->
<!--      }-->
<!--    </div>-->
<!--  }-->

<!--  @if (displayMode === "full") {-->
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <hr />-->
<!--      </div>-->
<!--    </div>-->
<!--  }-->

<!--  @if (-->
<!--    displayMode === "full" || displayMode === "welcome-page-page-bottom-half"-->
<!--  ) {-->
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <p class="text-muted uppercase bigger">All stocks</p>-->
<!--      </div>-->
<!--      &lt;!&ndash; Das hier ist das normale Company Grid, ohne jegliches Labelsgedoens &ndash;&gt;-->
<!--      @for (-->
<!--        company of listOfCompanies.slice(-->
<!--          0,-->
<!--          math.min(numRows * companiesPerRow, listOfCompanies.length)-->
<!--        );-->
<!--        track company-->
<!--      ) {-->
<!--        <div-->
<!--          class="companyTile my-3 top-bottom-spread col-md-12 fingerFix e2e-company-all-item"-->
<!--          [ngClass]="'col-lg-' + 12 / companiesPerRow"-->
<!--        >-->
<!--          <div-->
<!--            class="pb-3 w-100 h-100 mx-auto"-->
<!--            [routerLink]="['/companies', company.name]"-->
<!--            [fragment]="'X'"-->
<!--          >-->
<!--            <div class="row mx-auto pt-3 w-100">-->
<!--              <div class="col-12">-->
<!--                <h4>-->
<!--                  {{ company.name }}-->
<!--                  <span-->
<!--                    class="float-right fi flagColorFilter"-->
<!--                    [ngClass]="selectCountryFlag(company.country)"-->
<!--                  ></span>-->
<!--                </h4>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row mx-auto w-100 mt-2">-->
<!--              <div class="col-7 pr-0">-->
<!--                <div class="text-muted uppercase small lineHeight-1 mb-2">-->
<!--                  {{ company.sector }}-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-5 pl-0 text-right">-->
<!--                @if (!company.close) {-->
<!--                  <div>-->
<!--                    <app-loading-indicator></app-loading-indicator>-->
<!--                  </div>-->
<!--                }-->
<!--                <div class="">-->
<!--                  @if (company.close) {-->
<!--                    <span>{{ company.currency }} </span>-->
<!--                  }-->
<!--                  @if (company.close !== "NA") {-->
<!--                    <span>{{ company.close | number: "1.2-2" || "n/a" }}</span>-->
<!--                  }-->
<!--                </div>-->
<!--                <div-->
<!--                  class=""-->
<!--                  [ngClass]="-->
<!--                    company.change_p < 0 ? 'negativeRed' : 'positiveGreen'-->
<!--                  "-->
<!--                >-->
<!--                  @if (company.close !== "NA") {-->
<!--                    <span>{{-->
<!--                      company.change_p | number: "1.1-1" || "n/a"-->
<!--                    }}</span>-->
<!--                  }-->
<!--                  @if (company.close) {-->
<!--                    <span> %</span>-->
<!--                  }-->
<!--                </div>-->
<!--                <div-->
<!--                  class="caps signalWord"-->
<!--                  [ngStyle]="{ color: recColors[company.rating] }"-->
<!--                >-->
<!--                  {{ company.rating }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->
<!--      }-->
<!--    </div>-->
<!--  }-->

<!--  @if (!showAll) {-->
<!--    <div class="col-12 show-all">-->
<!--      @if (-->
<!--        listOfCompanies.length - numRows * companiesPerRow > 0 &&-->
<!--        displayMode === "full"-->
<!--      ) {-->
<!--        <button-->
<!--          (click)="-->
<!--            numRowsTMP = numRows;-->
<!--            numRows = math.ceil(listOfCompanies.length / companiesPerRow)-->
<!--          "-->
<!--          class="btn text-muted smaller"-->
<!--        >-->
<!--          show all ({{-->
<!--            1 + listOfCompanies.length - numRows * companiesPerRow-->
<!--          }}-->
<!--          more) <i class="fas fa-arrow-right"></i>-->
<!--        </button>-->
<!--      }-->
<!--      @if (-->
<!--        listOfCompanies.length - numRows * companiesPerRow <= 0 &&-->
<!--        displayMode === "full"-->
<!--      ) {-->
<!--        <button (click)="numRows = numRowsTMP" class="btn text-muted smaller">-->
<!--          show less-->
<!--        </button>-->
<!--      }-->
<!--      @if (-->
<!--        listOfCompanies.length - numRows * companiesPerRow > 0 &&-->
<!--        (displayMode === "full" ||-->
<!--          displayMode === "welcome-page-page-bottom-half")-->
<!--      ) {-->
<!--        <button-->
<!--          (click)="-->
<!--            numRowsTMP = numRows;-->
<!--            numRows = math.ceil(listOfCompanies.length / companiesPerRow)-->
<!--          "-->
<!--          class="btn text-muted smaller"-->
<!--        >-->
<!--          show all ({{-->
<!--            1 + listOfCompanies.length - numRows * companiesPerRow-->
<!--          }}-->
<!--          more) <i class="fas fa-arrow-right"></i>-->
<!--        </button>-->
<!--      }-->
<!--      @if (-->
<!--        listOfCompanies.length - numRows * companiesPerRow <= 0 &&-->
<!--        (displayMode === "full" ||-->
<!--          displayMode === "welcome-page-page-bottom-half")-->
<!--      ) {-->
<!--        <button (click)="numRows = numRowsTMP" class="btn text-muted smaller">-->
<!--          show less-->
<!--        </button>-->
<!--      }-->
<!--      @if (-->
<!--        listOfCompanies.length - numRows * companiesPerRow > 0 &&-->
<!--        displayMode === "companyPage"-->
<!--      ) {-->
<!--        <a [routerLink]="['/all-stocks']" ]>-->
<!--        <button  class="btn text-muted smaller">-->
<!--          show all ({{-->
<!--            1 + listOfCompanies.length - numRows * companiesPerRow-->
<!--          }}-->
<!--          more) <i class="fas fa-arrow-right"></i>-->
<!--        </button>-->
<!--        </a>-->
<!--      }-->
<!--    </div>-->
<!--  }-->
<!--</div>-->
