@if (podcast()) {
  <div class="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="flex flex-col items-center">
      <img
        [src]="podcast().companyDetails.imageURL"
        alt="{{ podcast().companyDetails.name }}"
        class="w-full h-64 object-cover"
      />
      <div class="p-6 w-full text-center">
        <h2 class="text-lg font-bold text-gray-800">
          {{ podcast().companyDetails.name }}
        </h2>
        <p class="text-gray-600 text-md mt-2">{{ podcast().title }}</p>
        <p class="text-gray-500 text-sm mt-1">
          {{ podcast().research_date | date: "dd/MM/yyyy" }}
        </p>
        <div class="mt-6 w-full">
          <div class="w-full">
            <audio
              [src]="podcast().fileStreamURL"
              class="w-full"
              controls
              (play)="onPlay()"
            ></audio>
            <div class="flex justify-between mt-4">
              <button
                (click)="copyShareLink(podcast())"
                class="text-blue-500 font-medium uppercase tracking-wide hover:underline text-start"
              >
                share podcast<i class="fas fa-arrow-right ml-1"></i>
              </button>
              <!--              <a-->
              <!--                [href]="podcast().downloadURL"-->
              <!--                class="text-blue-500 font-medium uppercase tracking-wide hover:underline"-->
              <!--                target="_blank"-->
              <!--              >-->
              <!--                Download Audio <i class="fas fa-arrow-right ml-1"></i>-->
              <!--              </a>-->
              <a
                [href]="podcast().researchDetails.pdfDetails.downloadURL"
                class="text-blue-500 font-medium uppercase tracking-wide hover:underline"
                target="_blank"
              >
                Download Research <i class="fas fa-arrow-right ml-1"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="flex flex-col items-center pt-6">
      <div
        class="h-64 w-full lg:h-32 lg:w-96 object-cover rounded-lg bg-blue-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="max-h-full mx-auto"
        >
          <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M319.4 372c48.5-31.3 80.6-85.9 80.6-148c0-97.2-78.8-176-176-176S48 126.8 48 224c0 62.1 32.1 116.6 80.6 148c1.2 17.3 4 38 7.2 57.1l.2 1C56 395.8 0 316.5 0 224C0 100.3 100.3 0 224 0S448 100.3 448 224c0 92.5-56 171.9-136 206.1l.2-1.1c3.1-19.2 6-39.8 7.2-57zm-2.3-38.1c-1.6-5.7-3.9-11.1-7-16.2c-5.8-9.7-13.5-17-21.9-22.4c19.5-17.6 31.8-43 31.8-71.3c0-53-43-96-96-96s-96 43-96 96c0 28.3 12.3 53.8 31.8 71.3c-8.4 5.4-16.1 12.7-21.9 22.4c-3.1 5.1-5.4 10.5-7 16.2C99.8 307.5 80 268 80 224c0-79.5 64.5-144 144-144s144 64.5 144 144c0 44-19.8 83.5-50.9 109.9zM224 312c32.9 0 64 8.6 64 43.8c0 33-12.9 104.1-20.6 132.9c-5.1 19-24.5 23.4-43.4 23.4s-38.2-4.4-43.4-23.4c-7.8-28.5-20.6-99.7-20.6-132.8c0-35.1 31.1-43.8 64-43.8zm0-144a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
          />
        </svg>
      </div>
      <div class="p-6 w-full text-center">
        <h2 class="text-lg font-bold text-gray-800"></h2>
        <p class="text-gray-600 mt-2"></p>
        <p class="text-gray-500 text-sm mt-1"></p>
        <div class="mt-6 w-full">
          <div class="w-full">
            <div class="flex justify-between mt-4">
              <h5>Loading podcast...</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
