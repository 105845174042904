import { AsyncPipe, JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";

import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-company-research-list",
  standalone: true,
  imports: [
    AsyncPipe,
    RouterLink,
    BreadcrumpComponent,
    PaginationComponent,
    JsonPipe,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-company-research-list.component.html",
  styleUrl: "./admin-company-research-list.component.scss",
})
export class AdminCompanyResearchListComponent {
  companyResearchService = inject(CompanyResearchService);
  companyTickerService = inject(CompanyTickerService);

  companyTicker = this.companyTickerService.getCompanyTicker();
  researchList: WritableSignal<any[]> = this.companyResearchService.items;
  totalCount = this.companyResearchService.totalCount;

  itemsPerPage = signal(20);
  currentPage = signal(1);

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    const ticker = this.route.snapshot.paramMap.get("companyTicker") ?? "";

    this.companyTickerService.setCompanyTicker(ticker);
    this.fetchResearchList();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalCount());
  }

  fetchResearchList() {
    const paginationParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    };
    this.companyResearchService.fetchResearchOverview(
      this.companyTicker(),
      paginationParams
    );
  }

  onPageChange(page: number) {
    this.currentPage.set(page);
    this.fetchResearchList();
  }
}
