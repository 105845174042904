<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-6 mb-8">
      <p class="text-md font-semibold mb-2">Create a transaction</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="grid grid-cols-6 gap-4 p-6">
      <div class="col-span-4">
        <app-admin-transaction-editor-form
          (formSubmit)="onFormSubmit($event)"
          [transaction]="transaction"
        ></app-admin-transaction-editor-form>
      </div>
      <div class="col-span-2">
        <app-file-upload></app-file-upload>
      </div>
    </div>
  </main>
</div>
