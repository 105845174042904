import { JsonPipe, KeyValuePipe } from "@angular/common";
import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  Signal,
} from "@angular/core";
import { RouterLink } from "@angular/router";

import { AuthService } from "~features/auth/auth.service";
import { InvestorProfileUpdateDialogComponent } from "~features/customer-dashboard/investor-profile-update-dialog/investor-profile-update-dialog.component";
import { InvestorprofileDialogComponent } from "~features/customer-dashboard/investorprofile-dialog/investorprofile-dialog.component";
import { UserService } from "~features/users/user.service";
import { ProfileAttributeTranslatorPipe } from "~pipes/profile-attribute-translator.pipe";
import { DialogService } from "~shared/services/dialog.service";

@Component({
  selector: "app-investor-profile-view",
  templateUrl: "./investor-profile-view.component.html",
  styleUrls: ["./investor-profile-view.component.scss"],
  standalone: true,
  imports: [
    KeyValuePipe,
    ProfileAttributeTranslatorPipe,
    InvestorprofileDialogComponent,
    JsonPipe,
    RouterLink,
  ],
})
export class InvestorProfileViewComponent {
  @Input({ required: true }) userInvestorProfile!: Signal<any>;
  @Output() profileUpdated = new EventEmitter<void>();
  profileData = computed(
    () => this.userInvestorProfile()?.investorInfo || null
  );
  private authService = inject(AuthService);
  userMail = computed(() => this.authService.getCurrentUserName());
  private dialogService = inject(DialogService);
  private baseInfoKeys = [
    "VORNAME",
    "NACHNAME",
    "PROFIL_INVESTORENTYP",
    "COMPANY_NAME",
    "JOB_TITLE",
    "CITY",
    "COUNTRY_NEU",
  ];
  baseInfo = computed(() =>
    this.profileData()
      ? this.baseInfoKeys
          .filter((key) => this.profileData()[key])
          .map((key) => ({ key, value: this.profileData()[key] }))
      : []
  );
  private profileInfoKeys = [
    "PROFIL_AUM",
    "PROFIL_GROESSE",
    "PROFIL_HALTEDAUER",
    "PROFIL_INVESTITIONSSTIL",
    "PROFIL_REGION",
    "PROFIL_TICKET_SIZE",
  ];
  profileInfo = computed(() =>
    this.profileData()
      ? this.profileInfoKeys
          .filter((key) => this.profileData()[key])
          .map((key) => ({ key, value: this.profileData()[key] }))
      : []
  );

  private userService = inject(UserService);
  openModal() {
    if (this.profileData()) {
      const dialogRef = this.dialogService.open(
        InvestorProfileUpdateDialogComponent,
        {
          data: this.profileData(),
        }
      );
      dialogRef.closed.subscribe((result) => {
        if (result) {
          this.userService.fetchInvestorProfile();
        }
      });
    }
  }
}
