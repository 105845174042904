import { Component, inject } from "@angular/core";
import { IFrontendConfig } from "~global-interfaces/IFrontendConfig";

//import { FrontendConfigEditorFormComponent } from "~features/frontend-config/admin/transaction-activation-form/transaction-activation-form.component";
import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-frontend-config-creator",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    // FrontendConfigEditorFormComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./frontend-config-creator.component.html",
  styleUrl: "./frontend-config-creator.component.scss",
})
export class FrontendConfigCreatorComponent {
  private frontEndConfigService = inject(FrontendConfigService);

  createFrontendConfig(newData?: IFrontendConfig) {
    //console.log(newData);
    this.frontEndConfigService.createFrontEndConfig();
  }
}
