<nav class="fixed w-full bg-white border-b border-gray-300" id="e2e-header">
  <div class="mx-auto px-4 w-11/12">
    <div class="flex justify-between items-center py-2">
      <div class="flex items-center">
        <a class="logo w-[155px]" href="/">
          <app-logo
            [alt]="'research hub'"
            [logoClass]="''"
            [src]="'assets/ResearchHubLogo.svg'"
            [wrapperClass]="''"
            class="hidden lg:block"
          >
          </app-logo>
          <span class="block lg:hidden text-md w-full text-center"
            >ResearchHub</span
          >
        </a>
      </div>

      <!-- Mobile menu button -->
      <button
        (click)="toggleMobileMenu()"
        class="lg:hidden text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
      >
        <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
          @if (!isMobileMenuOpen) {
            <path
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          }
          @if (isMobileMenuOpen) {
            <path
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
          }
        </svg>
      </button>

      <!-- Desktop menu -->

      <div class="hidden lg:flex space-x-2 items-center">
        <app-company-search></app-company-search>
        <a [routerLink]="['/']" class="block md:hidden">
          <button class="btn btn-transparent">Home</button>
        </a>
        @for (link of navLinks; track link.route) {
          <a [routerLink]="[link.route]">
            <button
              class="btn btn-transparent tracking-widest font-[500] text-sm uppercase text-[#212529] leading-normal relative"
            >
              {{ link.label }}
              @if (link.highlight) {
                <span
                  class="absolute -bottom-1.5 -right-2.5 text-blue-500 px-1.5 py-0.5 text-xs tracking-normal lowercase transform -rotate-12"
                  >new</span
                >
              }
            </button>
          </a>
        }

        @if (!isAuthenticated()) {
          <a [routerLink]="'/signup'">
            <button class="btn">Subscribe</button>
          </a>
          <button class="btn" (click)="openLogin()">Login</button>
        }

        @if (isAuthenticated()) {
          <div>
            <button
              (click)="isOpen = !isOpen"
              type="button"
              class="btn border-solid border-2 border-blue-400"
              [ngClass]="{
                '': isOpen,
                'btn-transparent border-solid text-blue-400 ': !isOpen
              }"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
            >
              @if (isOpen) {
                <span>
                  <svg
                    class="w-4 h-4 inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#fff"
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    />
                  </svg>
                </span>
                <i class="fa fa-chevron-up px-2"></i>
              } @else {
                <span>
                  <svg
                    class="w-4 h-4 inline-block"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="#3691D4"
                      d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    />
                  </svg>
                </span>
                <i class="fa fa-chevron-down px-2"></i>
              }
            </button>

            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="isOpen"
            >
              <ul class="bg-white p-2 border-solid border-2 shadow-md">
                <li>
                  @if (isCustomer()) {
                    <a [routerLink]="['/user/dashboard']">
                      <button class="btn w-full my-2">Your Dashboard</button>
                    </a>
                  }
                </li>
                <li>
                  @if (isCustomerEmittent()) {
                    <a [routerLink]="['/corporate/dashboard']">
                      <button class="btn w-full my-2">
                        Corporate-Dashboard
                      </button>
                    </a>
                  }
                </li>
                <li>
                  @if (isAuthenticated()) {
                    <button class="btn w-full my-2" (click)="logOff()">
                      Logout
                    </button>
                  }
                </li>
                <li>
                  @if (canUseAdminPanel()) {
                    <a [routerLink]="['/admin/dashboard']">
                      <button class="btn w-full">Admin Panel</button>
                    </a>
                  }
                </li>

                <!--                <li>-->
                <!--                  @if (canUseAdminPanel()) {}-->
                <!--                  <a class="float-right" href="/admin/dashboard/design">-->
                <!--                    <button class="btn w-full my-2">-->
                <!--                      see design document-->
                <!--                    </button></a-->
                <!--                  >-->
                <!--                </li>-->
              </ul>
            </ng-template>
          </div>
        }
      </div>
    </div>

    <!-- Mobile menu -->
    <div
      [ngClass]="{ hidden: !isMobileMenuOpen, block: isMobileMenuOpen }"
      class="lg:hidden"
    >
      <div class="px-2 pt-2 pb-3 space-y-1">
        <app-company-search></app-company-search>
        @for (link of mobileNavlinks; track link.route) {
          <a
            [routerLink]="[link.route]"
            class="block px-2 py-2 rounded-md text-sm font-semibold text-gray-700 hover:text-gray-900 hover:bg-gray-50 relative"
            >{{ link.label }}
            @if (link.highlight) {
              <span
                class="absolute left-6.5 text-blue-500 px-1.5 py-0.5 text-xs tracking-normal lowercase transform -rotate-12"
                >new</span
              >
            }
          </a>
        }

        @if (!isAuthenticated()) {
          <a [routerLink]="'/signup'">
            <button
              class="w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            >
              Subscribe
            </button>
          </a>
          <button
            class="w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            (click)="openLogin()"
          >
            Login
          </button>
        }

        @if (isAuthenticated()) {
          <!-- Mobile user menu items -->
          @if (isCustomer()) {
            <a
              [routerLink]="['/user/dashboard']"
              class="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >Your Dashboard</a
            >
          }
          @if (isCustomerEmittent()) {
            <a
              [routerLink]="['/corporate/dashboard']"
              class="block px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >Corporate-Dashboard</a
            >
          }
          <button
            class="w-full text-left px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
            (click)="logOff()"
          >
            Logout
          </button>
        }
      </div>
    </div>

    <!--    {{ frontendConfig() | json }}-->
    <!--    <div class="hidden lg:block">-->
    <!--      <div>-->
    <!--        @if (-->
    <!--          frontendConfig()?.activeTransaction && mobileHeader && !navbarOpen-->
    <!--        ) {-->
    <!--          <app-transactionbanner-->
    <!--            [mobileHeader]="mobileHeader"-->
    <!--            [frontendConfig]="frontendConfig"-->
    <!--          ></app-transactionbanner>-->
    <!--        }-->
    <!--      </div>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</nav>-->

    <!--@if (frontendConfig()?.activeTransaction && !mobileHeader) {-->
    <!--  <app-transactionbanner-->
    <!--    [frontendConfig]="frontendConfig"-->
    <!--  ></app-transactionbanner>-->
    <!--}-->
  </div>
</nav>
