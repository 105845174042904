import { CdkMenuTrigger } from "@angular/cdk/menu";
import { NgClass } from "@angular/common";
import {
  Component,
  inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import {
  AUM,
  Haltedauer,
  InvestorStil,
  PossibleCustomerTypes,
  PossibleInvestorTypes,
  Region,
  Size,
  TicketSize,
} from "~global-mappings/investorProfileMapping";

import { AuthService } from "~features/auth/auth.service";
import {
  InvestorProfileClass,
  NewContact,
  NewUser,
} from "~interfaces/user.model";
import { ProfileAttributeTranslatorPipe } from "~pipes/profile-attribute-translator.pipe";
import { PosthogService } from "~shared/services/posthog.service";

@Component({
  selector: "app-signup-page",
  templateUrl: "./signup-page.component.html",
  styleUrls: ["./signup-page.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    ProfileAttributeTranslatorPipe,
    RouterLink,
    CdkMenuTrigger,
  ],
})
export class SignupPageComponent implements OnInit {
  @ViewChildren(CdkMenuTrigger) menuTrigger!: QueryList<CdkMenuTrigger>;
  signupForm: FormGroup;
  editingProfile = false;

  loading = false;
  success = false;
  showAlert = false;
  errorMessage: string | null = null;
  customerTypes = Object.values(PossibleCustomerTypes);
  investorProfileFields = Object.keys(new InvestorProfileClass()).filter(
    (field) => field !== "PROFIL_INVESTORENTYP"
  );
  allNewsletters: any[] = [];
  newslettersForLaterAutoSubscription: string[] = [];
  profileOptions = {
    PROFIL_AUM: AUM,
    PROFIL_GROESSE: Size,
    PROFIL_HALTEDAUER: Haltedauer,
    PROFIL_INVESTITIONSSTIL: InvestorStil,
    PROFIL_INVESTORENTYP: Object.values(PossibleInvestorTypes),
    PROFIL_REGION: Region,
    PROFIL_TICKET_SIZE: TicketSize,
  };
  showCompanyNameAndJobTitleRequired = true;
  reorderedCountries = [
    "Germany",
    "Austria",
    "Switzerland",
    "France",
    "Luxembourg",
    "Belgium",
    "Netherlands",
    "Denmark",
    "Poland",
    "Czech_Republic",
    "Liechtenstein",
    "Italy",
    "Spain",
    "Portugal",
    "Sweden",
    "Norway",
    "Finland",
    "Ireland",
    "UK",
    "Romania",
    "Bulgaria",
    "Slovenia",
    "Estonia",
    "Canada",
    "USA",
    "Australia",
    "New Zealand",
    "India",
    "Singapore",
    "Thailand",
    "China",
    "Greece",
    "Kuwait",
  ];
  selectedCountry: string = "";
  selectedCustomerType: string = "";
  posthogService = inject(PosthogService);
  protected readonly Countries = this.reorderedCountries;
  private authService = inject(AuthService);
  private fb = inject(FormBuilder);

  closeAllMenus() {
    this.menuTrigger.forEach((trigger) => trigger.close());
  }

  ngOnInit() {
    this.initForm();
    this.getAllNewsletters();
  }

  initForm() {
    this.signupForm = this.fb.group(
      {
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", Validators.required],
        customerType: ["", Validators.required],
        companyName: [""],
        jobTitle: [""],
        city: [""],
        country: ["", Validators.required],
        profileData: this.fb.group({}),
      },
      { validator: this.passwordMatchValidator }
    );

    // Initialize profile fields
    const profileGroup = this.signupForm.get("profileData") as FormGroup;
    this.investorProfileFields.forEach((field) => {
      profileGroup.addControl(field, this.fb.control(""));
    });
  }

  onCustomerTypeChange() {
    this.updateProfileValidators();
    if (this.isCustomerInvestor()) {
      this.editingProfile = false;
    }

    const userType = this.signupForm.get("customerType").value;
    const companyControl = this.signupForm.get("companyName");
    const jobTitleControl = this.signupForm.get("jobTitle");

    if (userType !== "Private Investor") {
      this.showCompanyNameAndJobTitleRequired = true;
      companyControl.setValidators(Validators.required);
      jobTitleControl.setValidators(Validators.required);
    } else {
      this.showCompanyNameAndJobTitleRequired = false;
      companyControl.clearValidators();
      jobTitleControl.clearValidators();
    }

    companyControl.updateValueAndValidity();
    jobTitleControl.updateValueAndValidity();
  }

  updateProfileValidators() {
    const profileGroup = this.signupForm.get("profileData") as FormGroup;
    if (this.isCustomerInvestor()) {
      this.investorProfileFields.forEach((field) => {
        profileGroup.get(field).setValidators(Validators.required);
      });
    } else {
      this.investorProfileFields.forEach((field) => {
        profileGroup.get(field).clearValidators();
      });
    }
    profileGroup.updateValueAndValidity();
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get("password").value === g.get("confirmPassword").value
      ? null
      : { mismatch: true };
  }

  isCustomerInvestor(): boolean {
    return this.checkInvestorTypeValidity();
  }

  getHeaderTitle(): string {
    if (!this.editingProfile && !this.isCustomerInvestor())
      return "Create your Account";
    if (!this.editingProfile && this.isCustomerInvestor())
      return "Create your Account (1/2)";
    if (this.editingProfile && this.isCustomerInvestor())
      return "Complete your Profile (2/2)";
    return "";
  }

  isBasicInfoValid(): boolean {
    const basicFields = [
      "firstName",
      "lastName",
      "email",
      "password",
      "confirmPassword",
      "customerType",
      "country",
    ];
    return basicFields.every((field) => this.signupForm.get(field).valid);
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.signupForm.get(fieldName);
    return field.invalid && (field.dirty || field.touched);
  }

  isProfileFieldInvalid(fieldName: string): boolean {
    const field = this.signupForm.get("profileData").get(fieldName);
    return field.invalid && (field.dirty || field.touched);
  }

  getAllNewsletters() {
    this.authService.getNewsletterList().subscribe((result: any) => {
      this.allNewsletters = result.data[0];
    });
  }

  addToAutoSubscribeList(listId: string) {
    this.newslettersForLaterAutoSubscription.push(listId);
  }

  saveNewslettersForAutoSubscriptionAfterLogin() {
    localStorage.setItem(
      "newslettersforuseaftersignup",
      JSON.stringify(this.newslettersForLaterAutoSubscription)
    );
  }

  getEnumOptions(fieldName: string): string[] {
    return this.profileOptions[fieldName] || [];
  }

  toggleEditingProfile() {
    if (this.isCustomerInvestor()) {
      this.editingProfile = !this.editingProfile;
      this.scrollToTop();
    }
  }

  selectCountry(option: string) {
    this.selectedCountry = option;
    this.signupForm.get("country").setValue(option);
    this.closeAllMenus();
  }

  selectCustomerType(option: string) {
    this.selectedCustomerType = option;
    this.signupForm.get("customerType").setValue(option);
    this.closeAllMenus();
    this.onCustomerTypeChange();
  }

  onSubmit() {
    this.posthogService.trackButtonClick("signup_register_button_clicked");
    if (this.signupForm.invalid) return;

    if (this.isCustomerInvestor() && !this.editingProfile) {
      this.toggleEditingProfile();
      return;
    }

    this.loading = true;
    this.success = false;
    this.showAlert = false;
    this.errorMessage = null;

    const formValue = this.signupForm.value;
    const newUser: NewUser = {
      email: formValue.email,
      password: formValue.password,
      userRole: "customer",
    };

    const newContact: Partial<NewContact> = {
      VORNAME: formValue.firstName,
      NACHNAME: formValue.lastName,
      CITY: formValue.city,
      customerType: formValue.customerType,
      COMPANY_NAME: formValue.companyName,
      JOB_TITLE: formValue.jobTitle,
      COUNTRY_NEU: formValue.country,
      PROFIL_INVESTORENTYP: formValue.customerType,
      ...formValue.profileData,
    };

    this.saveNewslettersForAutoSubscriptionAfterLogin();
    this.authService.signup(newUser, newContact).then(
      (res) => {
        this.loading = false;
        if (res["success"] === true && res["emailStatus"] === 201) {
          this.success = true;
        } else if (res["success"] === true && !res["emailStatus"]) {
          this.showAlert = true;
        } else {
          this.errorMessage =
            "An unexpected error occurred. Please try again later.";
        }
      },
      (error) => {
        this.loading = false;
        this.errorMessage =
          "Failed to register. Please check your information and try again.";
        console.error("Signup error:", error);
      }
    );
  }

  checkInvestorTypeValidity(): boolean {
    const investorTypeValue = this.signupForm.get("customerType").value;

    if (
      investorTypeValue &&
      this.isValueInEnum(investorTypeValue, PossibleInvestorTypes)
    ) {
      return true; // The value is valid
    } else {
      return false; // The value is not valid
    }
  }

  isValueInEnum<T extends { [key: string]: string }>(
    value: string,
    enumObject: T
  ): value is T[keyof T] {
    return Object.values(enumObject).includes(value as T[keyof T]);
  }

  private scrollToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
