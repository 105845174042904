<p class="text-md text-red-500">
  Only create podcasts for english research! If your research is german, you
  dont need to do anything podcast related.
</p>
@if (creatorMode) {
  <form
    [formGroup]="podcastForm"
    class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
  >
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="language"
        >Language:</label
      >
      <input
        [ngClass]="{
          'border-red-500':
            podcastForm.get('language')?.invalid &&
            podcastForm.get('language')?.touched
        }"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        formControlName="language"
        id="language"
        required
        type="text"
      />
      @if (
        podcastForm.get("language")?.invalid &&
        podcastForm.get("language")?.touched
      ) {
        <p class="text-red-500 text-xs italic mt-1">
          {{ getErrorMessage("language") }}
        </p>
      }
    </div>

    <div class="mb-4">
      <label
        class="block text-gray-700 text-sm font-bold mb-2"
        for="research_date"
        >research_date:</label
      >
      <p class="text-red-500">
        Must be format: yyyy-mm-dd. Example: 2024-12-31
      </p>
      <input
        [ngClass]="{
          'border-red-500':
            podcastForm.get('research_date')?.invalid &&
            podcastForm.get('research_date')?.touched
        }"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        formControlName="research_date"
        id="research_date"
        required
        type="text"
      />
      @if (
        podcastForm.get("research_date")?.invalid &&
        podcastForm.get("research_date")?.touched
      ) {
        <p class="text-red-500 text-xs italic mt-1">
          {{ getErrorMessage("research_date") }}
        </p>
      }
    </div>

    <div class="mb-4">
      <label
        class="block text-gray-700 text-sm font-bold mb-2"
        for="companyTicker"
        >companyTicker:</label
      >
      <input
        [ngClass]="{
          'border-red-500':
            podcastForm.get('companyTicker')?.invalid &&
            podcastForm.get('companyTicker')?.touched
        }"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        formControlName="companyTicker"
        id="companyTicker"
        required
        type="text"
      />
      @if (
        podcastForm.get("companyTicker")?.invalid &&
        podcastForm.get("companyTicker")?.touched
      ) {
        <p class="text-red-500 text-xs italic mt-1">
          {{ getErrorMessage("companyTicker") }}
        </p>
      }
    </div>

    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
        >Research Title:
      </label>
      <input
        [ngClass]="{
          'border-red-500':
            podcastForm.get('title')?.invalid &&
            podcastForm.get('title')?.touched
        }"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        formControlName="title"
        id="title"
        required
        type="text"
      />
      @if (
        podcastForm.get("title")?.invalid && podcastForm.get("title")?.touched
      ) {
        <p class="text-red-500 text-xs italic mt-1">
          {{ getErrorMessage("title") }}
        </p>
      }
    </div>

    <div class="mb-4">
      <label
        class="block text-gray-700 text-sm font-bold mb-2"
        for="fileStreamURL"
        >The URL where the Audio-File is:</label
      >
      <input
        [ngClass]="{
          'border-red-500':
            podcastForm.get('fileStreamURL')?.invalid &&
            podcastForm.get('fileStreamURL')?.touched
        }"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        formControlName="fileStreamURL"
        id="fileStreamURL"
        required
        type="text"
      />
      @if (
        podcastForm.get("fileStreamURL")?.invalid &&
        podcastForm.get("fileStreamURL")?.touched
      ) {
        <p class="text-red-500 text-xs italic mt-1">
          {{ getErrorMessage("fileStreamURL") }}
        </p>
      }
    </div>

    @if (!multiMode) {
      <div class="flex items-center justify-between">
        <app-admin-form-button
          (click)="saveForm()"
          [class]="'admin-form-button'"
          [disabled]="!podcastForm.valid"
        >
        </app-admin-form-button>
      </div>
      <app-admin-form-request-message></app-admin-form-request-message>
    }
  </form>
} @else {
  <form
    [formGroup]="podcastForm"
    class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
  >
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
        >Language: {{ podcastForm.get("language").value }}</label
      >
    </div>
    <!--  <div class="mb-4">-->
    <!--    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"-->
    <!--      >published_date: {{ podcastForm.get("published_date").value }}</label-->
    <!--    >-->
    <!--  </div>-->
    <!--  <div class="mb-4">-->
    <!--    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"-->
    <!--      >upload_date: {{ podcastForm.get("upload_date").value }}</label-->
    <!--    >-->
    <!--  </div>-->
    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
        >research_date: {{ podcastForm.get("research_date").value }}</label
      >
      <p class="text-red-500">
        Must be format: yyyy-mm-dd. Example: 2024-12-31
      </p>
    </div>

    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
        >companyTicker: {{ podcastForm.get("companyTicker").value }}</label
      >
    </div>

    <div class="mb-4">
      <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
        >title: {{ podcastForm.get("title").value }}</label
      >
    </div>

    <div class="mb-4">
      <label
        class="block text-gray-700 text-sm font-bold mb-2"
        for="fileStreamURL"
        >The URL where the Audio-File is:</label
      >
      <input
        [ngClass]="{
          'border-red-500':
            podcastForm.get('fileStreamURL')?.invalid &&
            podcastForm.get('fileStreamURL')?.touched
        }"
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        formControlName="fileStreamURL"
        id="fileStreamURL"
        required
        type="text"
      />
      @if (
        podcastForm.get("fileStreamURL")?.invalid &&
        podcastForm.get("fileStreamURL")?.touched
      ) {
        <p class="text-red-500 text-xs italic mt-1">
          {{ getErrorMessage("fileStreamURL") }}
        </p>
      }
    </div>

    @if (!multiMode) {
      <div class="flex items-center justify-between">
        <app-admin-form-button
          (click)="saveForm()"
          [class]="'admin-form-button'"
          [disabled]="!podcastForm.valid"
        >
        </app-admin-form-button>
      </div>
      <app-admin-form-request-message></app-admin-form-request-message>
    }
  </form>
}
