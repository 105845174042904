import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-company-swot-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    JsonPipe,
    NgClass,
    AdminFormRequestMessageComponent,
    AdminFormButtonComponent,
  ],
  templateUrl: "./admin-company-swot-editor-form.component.html",
  styleUrl: "./admin-company-swot-editor-form.component.scss",
})
export class AdminCompanySwotEditorFormComponent implements OnInit {
  @Input() swot!: WritableSignal<any>;
  @Output() formSubmit = new EventEmitter<any>();
  swotForm: FormGroup;
  adminRequestService = inject(AdminRequestService);
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  constructor(private formBuilder: FormBuilder) {
    this.adminRequestService.emptyLastRequest();
    effect(() => {
      if (this.multiModeCompanyTicker()) {
        this.swotForm
          .get("companyTicker")
          .setValue(this.multiModeCompanyTicker());
      }
    });
    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  get strengths(): FormArray {
    return this.swotForm.get("strengths") as FormArray;
  }

  get weaknesses(): FormArray {
    return this.swotForm.get("weaknesses") as FormArray;
  }

  get opportunities(): FormArray {
    return this.swotForm.get("opportunities") as FormArray;
  }

  get threats(): FormArray {
    return this.swotForm.get("threats") as FormArray;
  }

  ngOnInit() {
    this.initializeForm();
    this.patchFormValues();
    this.formValidity.emit(this.swotForm.valid);
    this.formValueChange.emit(this.swotForm.value);
    // Subscribe to form changes
    this.swotForm.valueChanges.subscribe((values) => {
      this.formValueChange.emit(values);
    });

    // Subscribe to form validity changes
    this.swotForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  initializeForm() {
    this.swotForm = this.formBuilder.group({
      companyTicker: ["", [Validators.required]],
      language: ["de", [Validators.required]],
      is_public: [true],
      strengths: this.formBuilder.array([]),
      weaknesses: this.formBuilder.array([]),
      opportunities: this.formBuilder.array([]),
      threats: this.formBuilder.array([]),
    });
  }

  patchFormValues() {
    this.swotForm.patchValue({
      companyTicker: this.swot().companyTicker,
      language: this.swot().language,
      is_public: this.swot().is_public,
    });
    this.patchArrayValues(
      "strengths",
      this.swot().strengths ? this.swot().strengths : []
    );
    this.patchArrayValues(
      "weaknesses",
      this.swot().weaknesses ? this.swot().weaknesses : []
    );
    this.patchArrayValues(
      "opportunities",
      this.swot().opportunities ? this.swot().opportunities : []
    );
    this.patchArrayValues(
      "threats",
      this.swot().threats ? this.swot().threats : []
    );
  }

  patchArrayValues(formControlName: string, values: string[]) {
    const formArray = this.swotForm.get(formControlName) as FormArray;
    formArray.clear();
    values.forEach((value) => {
      formArray.push(
        new FormControl(value, [Validators.required, Validators.minLength(3)])
      );
    });
  }

  addStrength() {
    this.strengths.push(
      new FormControl("", [Validators.required, Validators.minLength(3)])
    );
  }

  removeStrength(index: number) {
    this.strengths.removeAt(index);
  }

  addWeakness() {
    this.weaknesses.push(
      new FormControl("", [Validators.required, Validators.minLength(3)])
    );
  }

  removeWeakness(index: number) {
    this.weaknesses.removeAt(index);
  }

  addOpportunity() {
    this.opportunities.push(
      new FormControl("", [Validators.required, Validators.minLength(3)])
    );
  }

  removeOpportunity(index: number) {
    this.opportunities.removeAt(index);
  }

  addThreat() {
    this.threats.push(
      new FormControl("", [Validators.required, Validators.minLength(3)])
    );
  }

  removeThreat(index: number) {
    this.threats.removeAt(index);
  }

  saveForm() {
    if (this.swotForm.valid) {
      const formValue = this.swotForm.value;

      const updatedSwot = {
        ...this.swot(),
        ...formValue,
      };
      this.formSubmit.emit(updatedSwot);
    } else {
      this.markFormGroupTouched(this.swotForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.swotForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
