import { Component } from "@angular/core";

@Component({
  selector: "app-company-valuation",
  standalone: true,
  imports: [],
  templateUrl: "./company-valuation.component.html",
  styleUrl: "./company-valuation.component.scss",
})
export class CompanyValuationComponent {}
