<div class="grid grid-cols-12 gap-0 w-full h-full min-h-[32rem]">
  @if (!noSliders) {
    <div
      class="d-none d-md-block col-span-1 flex items-center text-md"
      (click)="navigate(-1)"
    >
      <i class="fa fa-chevron-left pb-3 mx-auto text-[#0f6bb2]"></i>
    </div>
  }
  <div
    #chartWrapperTemplateCharts
    [ngClass]="{ 'col-span-10': !noSliders, 'col-span-12': noSliders }"
    class="p-0"
  >
    @if (chart()) {
      <app-chart [chart]="chart"></app-chart>
    }
  </div>

  @if (!noSliders) {
    <div
      class="d-none d-md-block col-span-1 flex items-center text-md"
      (click)="navigate(1)"
    >
      <i class="fa fa-chevron-right pb-3 mx-auto text-[#0f6bb2]"></i>
    </div>
  }
</div>
