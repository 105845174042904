@if (data()) {
  <div
    class="flex flex-col justify-end h-full text-center"
    [ngStyle]="{ 'background-color': bgColor(), color: color() }"
  >
    <div class="text-md font-medium text-gray-500 mb-2">
      {{ data().date[index()] }}
      @if (data()[index()]?.valueModifier) {
        <span class="ml-1">in {{ data()[index()]?.valueModifier }}</span>
      }
    </div>
    <div class="grid grid-cols-12 items-center">
      <div class="col-span-3 cursor-pointer text-lg" (click)="navigate(-1)">
        <i class="fa fa-chevron-left"></i>
      </div>
      <div class="col-span-6 text-lgx font-medium">
        {{ data()?.value[index()] }}
      </div>
      <div class="col-span-3 cursor-pointer text-lg" (click)="navigate(1)">
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
  </div>
}
