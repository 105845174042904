@if (chartData() && this.chart()) {
  <div class="h-full flex flex-col">
    <div class="flex justify-between h-full">
      <div
        (click)="navigate(-1)"
        class="cursor-pointer text-lg text-blue-research flex items-center"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
      <div class="flex-grow">
        <app-chart [chart]="chart" class="w-full h-full"></app-chart>
      </div>
      <div
        (click)="navigate(1)"
        class="cursor-pointer text-lg text-blue-research flex items-center"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
  </div>
}
