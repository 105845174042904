import { Component, effect, inject, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { EventNotFoundComponent } from "~features/events/event-registration/event-not-found/event-not-found.component";
import { EventRegistrationComponent } from "~features/events/event-registration/event-registration/event-registration.component";
import { EventService } from "~features/events/event.service";
import {
  formatBloombergTicker,
  formatDate,
} from "~features/events/event.utils";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-event-registration-page",
  templateUrl: "./event-registration-page.component.html",
  styleUrls: ["./event-registration-page.component.scss"],
  standalone: true,
  imports: [EventRegistrationComponent, EventNotFoundComponent],
})
export class EventRegistrationPageComponent {
  loadingData = signal(true);
  data = signal({
    date: null,
    companyTicker: null,
    beDate: null,
    beTicker: null,
  });
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private eventService = inject(EventService);
  registrationEvent = this.eventService.getRegistrationEvent();

  constructor() {
    effect(() => {
      this.activatedRoute.params.subscribe((params) => {
        const companyTicker = formatBloombergTicker(
          params.companyTicker,
          "toValid"
        );
        this.data.update((currentData) => ({
          ...currentData,
          beDate: params.date, // This is in the format "2024-09-18-11-00"
          beTicker: params.companyTicker,
          date: formatDate(params.date, "toValid"),
          companyTicker: companyTicker,
        }));
      });
    }, enableSignalWritesInEffectContext);

    effect(() => {
      const currentData = this.data();
      if (currentData.beDate && currentData.beTicker) {
        this.eventService.fetchRegistrationEvent(
          currentData.beDate,
          currentData.beTicker
        );
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.registrationEvent()) {
        this.loadingData.set(false);
      }
    }, enableSignalWritesInEffectContext);
  }
}
