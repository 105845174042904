import { NgClass } from "@angular/common";
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  AUM,
  Countries,
  Haltedauer,
  InvestorStil,
  PossibleCustomerTypes,
  PossibleInvestorTypes,
  Region,
  Size,
  TicketSize,
} from "~global-mappings/investorProfileMapping";

import { AuthService } from "~features/auth/auth.service";
import { UserNotificationService } from "~features/user-notifications/user-notification.service";
import { InvestorProfileClass } from "~interfaces/user.model";
import { ProfileAttributeTranslatorPipe } from "~pipes/profile-attribute-translator.pipe";
import { PosthogService } from "~shared/services/posthog.service";

@Component({
  selector: "app-investor-profile-form",
  templateUrl: "./investor-profile-form.component.html",
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, ProfileAttributeTranslatorPipe],
})
export class InvestorProfileFormComponent implements OnInit {
  @Input() currentProfile!: any;
  @Output() profileUpdated = new EventEmitter<boolean>();
  profileForm!: FormGroup;
  loading = false;
  success = false;
  submitted = false;
  customerTypes = Object.values(PossibleCustomerTypes);
  investorProfileFields = Object.keys(new InvestorProfileClass()).filter(
    (key) => key !== "PROFIL_INVESTORENTYP"
  );
  profileOptions = {
    PROFIL_AUM: AUM,
    PROFIL_GROESSE: Size,
    PROFIL_HALTEDAUER: Haltedauer,
    PROFIL_INVESTITIONSSTIL: InvestorStil,
    PROFIL_REGION: Region,
    PROFIL_TICKET_SIZE: TicketSize,
  };
  posthogService = inject(PosthogService);
  protected readonly Countries = Countries;
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private userNotificationService = inject(UserNotificationService);

  get isCustomerInvestor(): boolean {
    return this.checkInvestorTypeValidity();
  }

  get f() {
    return this.profileForm.controls;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    const formControls = {
      VORNAME: [this.currentProfile.VORNAME || "", Validators.required],
      NACHNAME: [this.currentProfile.NACHNAME || "", Validators.required],
      PROFIL_INVESTORENTYP: [
        this.currentProfile.PROFIL_INVESTORENTYP || "",
        Validators.required,
      ],
      CITY: [this.currentProfile.CITY || ""],
      COMPANY_NAME: [this.currentProfile.COMPANY_NAME || ""],
      JOB_TITLE: [this.currentProfile.JOB_TITLE || ""],
      COUNTRY_NEU: [this.currentProfile.COUNTRY_NEU || ""],
    };

    this.investorProfileFields.forEach((field) => {
      formControls[field] = [this.currentProfile[field] || ""];
    });

    this.profileForm = this.fb.group(formControls);
  }

  onSubmit(): void {
    this.posthogService.trackButtonClick("update_profile_button_clicked");
    this.submitted = true;
    if (this.profileForm.valid) {
      this.loading = true;
      this.authService
        .updateCurrentUserInvestorProfile(this.profileForm.value)
        .subscribe({
          next: () => {
            this.loading = false;
            this.success = true;
            this.profileUpdated.emit(true);
            this.userNotificationService.updateNotification(
              "Profile updated successfully"
            );
          },
          error: (error) => {
            this.loading = false;
            // Handle error
            this.userNotificationService.updateNotification(
              "Error: Try again later or contact mwb research directly."
            );
            console.error("Error updating profile:", error);
          },
        });
    }
  }

  getEnumOptions(fieldName: string): string[] {
    return this.profileOptions[fieldName] || [];
  }

  checkInvestorTypeValidity(): boolean {
    const investorTypeValue = this.profileForm.get(
      "PROFIL_INVESTORENTYP"
    )?.value;

    if (
      investorTypeValue &&
      this.isValueInEnum(investorTypeValue, PossibleInvestorTypes)
    ) {
      return true; // The value is valid
    } else {
      return false; // The value is not valid
    }
  }

  isValueInEnum<T extends { [key: string]: string }>(
    value: string,
    enumObject: T
  ): value is T[keyof T] {
    return Object.values(enumObject).includes(value as T[keyof T]);
  }
}
