import { Injectable, signal, WritableSignal } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { IGuidedMenuStep } from "~global-interfaces/IGuidedMenuStep";

@Injectable({
  providedIn: "root",
})
export class CreateResearchProcessService {
  steps: WritableSignal<IGuidedMenuStep[]> = signal([
    {
      name: "Upload Research & Publish Excel Figures",
      route: "/admin/dashboard/research/creator",
      isOptional: true,
      completed: false,
      status: "not-started",
    },

    {
      name: "Create RSS Entry",
      route: "/admin/dashboard/rss/create",
      isOptional: true,
      completed: false,
      status: "not-started",
    },
  ]);

  isGuidedMenuEnabled: WritableSignal<boolean> = signal(false);
  currentRoute: WritableSignal<string | null> = signal(null);
  currentCompanyTicker: WritableSignal<string | null> = signal(null);

  constructor(private router: Router) {
    this.subscribeToRouteChanges();
  }

  setCurrentCompanyTicker(ticker: string) {
    this.currentCompanyTicker.set(ticker);
  }

  public setStepCompleted(name: string) {
    const updatedSteps = this.steps().map((step) => {
      if (step.name === name) {
        return { ...step, status: "completed" };
      }
      return step;
    });
    this.steps.set(updatedSteps);
  }

  public getCurrentCompanyTicker(): WritableSignal<string | null> {
    return this.currentCompanyTicker;
  }

  getCurrentStepIndex(): number {
    let stepIndex = 0;
    stepIndex = this.steps().findIndex(
      (step) => this.buildRoute(step.route) === this.currentRoute()
    );
    return stepIndex >= 0 ? stepIndex : 0;
  }

  isCurrentStepCompleted(): boolean {
    const currentStep = this.steps().find(
      (step) => this.buildRoute(step.route) === this.currentRoute()
    );
    return currentStep ? currentStep.status === "completed" : false;
  }

  navigateToNextStep() {
    const currentIndex = this.getCurrentStepIndex();
    const nextIndex = currentIndex + 1;
    //console.log(currentIndex, nextIndex, this.steps().length);

    if (nextIndex < this.steps().length) {
      const nextStep = this.steps()[nextIndex];
      this.router.navigate([this.buildRoute(nextStep.route)]);
    } else if (nextIndex === this.steps().length) {
      // If we reach the last step, navigate to the final route or show a completion message
      //console.log("Guided menu completed!");
      // Add your desired behavior here
    }
  }

  buildRoute(rawRoute) {
    const companyTickerForRoute =
      this.currentCompanyTicker() !== null
        ? this.currentCompanyTicker()
        : "new ticker";
    if (companyTickerForRoute) {
      return rawRoute.replace(":companyTicker", companyTickerForRoute);
    }
  }

  toggleGuidedMenu() {
    this.resetMenuData();
    this.isGuidedMenuEnabled.set(!this.isGuidedMenuEnabled());
    //  console.log(this.isGuidedMenuEnabled());
  }

  resetMenuData() {
    this.currentCompanyTicker.set(null);
  }

  private subscribeToRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        //  console.log("event.urlAfterRedirects", event.urlAfterRedirects);
        this.currentRoute.set(event.urlAfterRedirects);
        this.updateStepStatus();
      });
  }

  private updateStepStatus() {
    const currentRoute = this.currentRoute();
    if (currentRoute) {
      const updatedSteps = this.steps().map((step) => {
        if (step.route === currentRoute) {
          return { ...step, status: "in-progress" };
        }
        return step;
      });
      this.steps.set(updatedSteps);
    }
  }
}
