import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { ITransaction } from "~global-interfaces/ITransaction";

import { alsterAPI_URL } from "~api/api-urls";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class TransactionService extends PaginationService<ITransaction> {
  currentTransaction: WritableSignal<ITransaction | null> = signal(null);
  currentlyEditingTransaction: WritableSignal<ITransaction | null> =
    signal(null);
  isLoading: WritableSignal<boolean> = signal(false);
  loadingIndicatorService = inject(LoadingIndicatorService);
  constructor(http: HttpClient) {
    super(http);
  }

  getisLoading() {
    return this.isLoading;
  }

  fetchTransactionsList(paginationParams: IQueryParams) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL + "transactions",
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      //this.currentTransaction.set(this.items()[0]);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }

  fetchTransaction(transactionid) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ITransaction>>(
        alsterAPI_URL + "transactions/" + transactionid
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch transaction")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (transaction) => {
          this.currentTransaction.set(transaction);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch transaction success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchEditingTransaction(transactionid) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ITransaction>>(
        alsterAPI_URL + "transactions/" + transactionid
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch transaction")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (transaction) => {
          this.currentlyEditingTransaction.set(transaction);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch editing transaction success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
  // TODO: check subscribe function to fill tap

  fetchUniqueTransaction(transactionid) {
    return this.http
      .get<IApiResponse<ITransaction>>(
        alsterAPI_URL + "transactions/" + transactionid
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch transaction special way"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }

  updateTransaction(newData) {
    this.isLoading.set(true);
    const body = { ...newData };
    this.http
      .put<IApiResponse<ITransaction>>(
        alsterAPI_URL + "transactions/edit/" + newData._id,
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "update transaction")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (transaction) => {
          this.currentlyEditingTransaction.set(transaction);
          this.adminRequestService.setLastRequestMessage("16", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
  createTransaction(newData = { link: "placeholder", text: "placeholder" }) {
    this.isLoading.set(true);
    const body = { ...newData };
    this.http
      .post<IApiResponse<ITransaction>>(
        alsterAPI_URL + "transactions/create",
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "createTransaction")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (transaction) => {
          this.currentTransaction.set(transaction);
          this.adminRequestService.setLastRequestMessage("17", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public getTransactionsList() {
    return this.items;
  }

  public getCurrentTransaction() {
    return this.currentTransaction;
  }
}
