import { Component, inject, OnInit } from "@angular/core";

import { AuthService } from "~features/auth/auth.service";
import { UserNotificationService } from "~features/user-notifications/user-notification.service";
import { LoadingIndicatorComponent } from "~shared/components/ui/loading-indicator/loading-indicator.component";

@Component({
  selector: "app-investor-newsletter",
  templateUrl: "./investor-newsletter.component.html",
  styleUrls: ["./investor-newsletter.component.scss"],
  standalone: true,
  imports: [LoadingIndicatorComponent],
})
export class InvestorNewsletterComponent implements OnInit {
  allNewsletters: any[] = [];
  subscribedNewsletters: any[] = [];
  newsletters: any[] = [];
  loadingNewData = false;
  loading = false;
  private userNotificationService = inject(UserNotificationService);

  constructor(private authService: AuthService) {
    this.loadingNewData = true;
    this.authService
      .getCurrentUsersNewsletterStatus()
      .subscribe((result: any) => {
        this.subscribedNewsletters =
          result.data[0].subscribedNewsletters.subscribedNewsletters;
        this.authService.getNewsletterList().subscribe((result: any) => {
          this.allNewsletters = result.data[0];
          this.mergeNewslettersWithUserSubscription();
        });
      });
  }

  ngOnInit(): void {}

  mergeNewslettersWithUserSubscription() {
    this.newsletters = this.allNewsletters.map((obj) => ({
      ...obj,
      subscribed: this.subscribedNewsletters.some(
        (subObj) => Number(subObj.id) === Number(obj.id)
      )
        ? true
        : false,
    }));
    this.loadingNewData = false;
  }

  handleSubscribeToggle(listId) {
    if (this.newsletters.find((x) => x.id == listId).subscribed) {
      this.removeFromNewsletter(listId);
    } else {
      this.addToNewsletter(listId);
    }
  }

  addToNewsletter(listId) {
    this.loading = true;
    this.authService.addCurrentUserToList(listId).subscribe((res: any) => {
      this.authService
        .getCurrentUsersNewsletterStatus()
        .subscribe((result: any) => {
          this.subscribedNewsletters =
            result.data[0].subscribedNewsletters.subscribedNewsletters;
          this.userNotificationService.updateNotification(
            "Subscribed to newsletter"
          );
          this.authService.getNewsletterList().subscribe((result: any) => {
            this.allNewsletters = result.data[0];
            this.mergeNewslettersWithUserSubscription();
            this.loading = false;
          });
        });
    });
  }

  removeFromNewsletter(listId) {
    this.loading = true;
    this.authService.removeCurrentUserFromList(listId).subscribe((res: any) => {
      this.authService
        .getCurrentUsersNewsletterStatus()
        .subscribe((result: any) => {
          this.subscribedNewsletters =
            result.data[0].subscribedNewsletters.subscribedNewsletters;
          this.userNotificationService.updateNotification(
            "Unsubscribed from newsletter"
          );
          this.authService.getNewsletterList().subscribe((result: any) => {
            this.allNewsletters = result.data[0];
            this.mergeNewslettersWithUserSubscription();
            this.loading = false;
          });
        });
    });
  }
}
