<form
  [formGroup]="transactionForm"
  class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-6">
    <label class="block text-sm font-secondary text-gray-700 mb-2" for="link">
      Link:
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          transactionForm.get('link')?.invalid &&
          transactionForm.get('link')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="link"
      id="link"
      required
      type="text"
    />
    @if (
      transactionForm.get("link")?.invalid &&
      transactionForm.get("link")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("link") }}
      </p>
    }
  </div>

  <div class="mb-6">
    <label class="block text-sm font-secondary text-gray-700 mb-2" for="text">
      Text:
    </label>
    <textarea
      [ngClass]="{
        'border-red-500':
          transactionForm.get('text')?.invalid &&
          transactionForm.get('text')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="text"
      id="text"
      required
      rows="4"
    ></textarea>
    @if (
      transactionForm.get("text")?.invalid &&
      transactionForm.get("text")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("text") }}
      </p>
    }
  </div>

  <div class="flex items-center justify-end">
    <app-admin-form-button
      (click)="saveForm()"
      [class]="'admin-form-button'"
      [disabled]="!transactionForm.valid"
    >
    </app-admin-form-button>
  </div>
  <app-admin-form-request-message></app-admin-form-request-message>
</form>
