import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LegacyCompanyURLRedirectGuard implements CanActivate {
  private oldPrefixes = new Set([
    "investment-case",
    "snapshot",
    "corporate",
    "financials",
    "esg",
    "swot",
    "research",
    "chart",
    "valuation",
    "quality",
    "growth",
  ]);

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const urlSegments = state.url
      .split("/")
      .filter((segment) => segment.length > 0);

    // Check if we have at least 3 segments: "companies", prefix, and companyName
    if (
      urlSegments.length >= 3 &&
      urlSegments[0].toLowerCase() === "companies"
    ) {
      const potentialPrefix = urlSegments[1].toLowerCase();

      if (this.oldPrefixes.has(potentialPrefix)) {
        // Old format detected, redirect to new format
        const companyName = urlSegments.slice(2).join("/"); // Join all remaining segments
        console.log(`Redirecting from old format: ${state.url}`);
        return this.router.parseUrl(`/companies/${companyName}`);
      }
    }

    // If no redirect is needed, allow the navigation to proceed
    console.log(`No redirect needed: ${state.url}`);
    return true;
  }
}
