import { Injectable, signal, WritableSignal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AdminRequestService {
  private lastRequestMessage: WritableSignal<string | null> = signal(null);
  private lastRequestSuccess = signal<boolean | null>(null);
  setLastRequestMessage(message: string, useMap = false) {
    if (useMap) {
      this.lastRequestMessage.set(ADMIN_REQUEST_MESSAGES[message]);
    } else {
      this.lastRequestMessage.set(message);
    }
  }
  getLastRequestMessage() {
    return this.lastRequestMessage;
  }

  getLastRequestSuccess() {
    return this.lastRequestSuccess;
  }
  setLastRequestSuccess(success: boolean) {
    this.lastRequestSuccess.set(success);
  }
  emptyLastRequest() {
    this.lastRequestMessage.set(null);
    this.lastRequestSuccess.set(null);
  }
}

const ADMIN_REQUEST_MESSAGES = {
  "1": "Research updated successfully",
  "2": "Highlighted company list updated successfully",
  "3": "Legal disclaimer updated successfully",
  "4": "Company updated successfully",
  "5": "Homepage/Transaction configuration updated successfully",
  "6": "New company created successfully",
  "7": "Exchange mapping updated successfully",
  "8": "Investment case updated successfully",
  "9": "New investment case created successfully",
  "10": "SWOT updated successfully",
  "11": "New SWOT  created successfully",
  "12": "Valuation updated successfully",
  "13": "New event created successfully",
  "14": "New front-end configuration created successfully",
  "15": "Legal disclaimer created successfully",
  "16": "Transaction updated successfully",
  "17": "New transaction created successfully",
  "18": "User updated successfully",
  "19": "Weekly screener updated successfully",
  "20": "New weekly screener created successfully",
  "21": "Event updated successfully",
  "22": "Deleted user successfully - Brevo Data is unaffected by this",
  "23": "Excel figures published successfully",
  "24": "Excel-Company created successfully",
  "25": "Deletion of company with context successful",
  "26": "New research uploaded successfully",
  "27": "Initiation note updated successfully",
  "28": "New initiation note created successfully",
  "29": "Event deleted successfully",
  "30": "Disclaimer deleted  successfully",
};
