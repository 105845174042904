import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";

import { InvestorProfileFormComponent } from "../investor-profile-form/investor-profile-form.component";

@Component({
  selector: "app-investor-profile-update-dialog",
  templateUrl: "./investor-profile-update-dialog.component.html",
  styleUrls: ["./investor-profile-update-dialog.component.scss"],
  standalone: true,
  imports: [InvestorProfileFormComponent],
})
export class InvestorProfileUpdateDialogComponent {
  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  onProfileUpdated(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
