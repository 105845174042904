import { JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, computed, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CompanyInitiationNoteFormComponent } from "~features/company/admin/company-initiation-note/company-initiation-note-form/company-initiation-note-form.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { InitiationNoteService } from "~features/company/initiation-note.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_INITIATIONNOTE } from "~shared/initial-values";

@Component({
  selector: "app-company-initiation-note-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    CreationProcessMenuComponent,
    CompanyInitiationNoteFormComponent,
    AdminSidePanelComponent,
    JsonPipe,
  ],
  templateUrl: "./company-initiation-note-editor.component.html",
  styleUrl: "./company-initiation-note-editor.component.scss",
})
export class CompanyInitiationNoteEditorComponent {
  route = inject(ActivatedRoute);
  http = inject(HttpClient);

  companyTickerService = inject(CompanyTickerService);
  initiationNoteService = inject(InitiationNoteService);

  companyTicker = this.companyTickerService.getCompanyTicker();

  initiationNoteContent = computed(() => {
    const initiationNoteValue =
      this.initiationNoteService.getInitiationNote()();

    return initiationNoteValue !== null
      ? initiationNoteValue
      : INITIAL_INITIATIONNOTE;
  });

  constructor() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTickerService.setCompanyTicker(ticker);
  }

  onFormSubmit(newData) {
    //console.log(newData);
    this.initiationNoteService.updateInitiationNote(newData);
  }
}
