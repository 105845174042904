<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">See all research for a company</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalCount() }}
        </p>
      </div>

      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-4 py-2 text-left">Title</th>
              <th class="px-4 py-2 text-left">Published at</th>
              <th class="px-4 py-2 text-left">Edit</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (research of researchList(); track research.title) {
              <tr>
                <td class="px-4 py-2">{{ research.title }}</td>
                <td class="px-4 py-2">{{ research.published_date }}</td>
                <td class="px-4 py-2">
                  <a [routerLink]="['edit', research.title]">
                    <button
                      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Edit
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalCount() }}
        </p>
      </div>
    </div>
  </main>
</div>
