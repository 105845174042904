import { Component, inject, signal, WritableSignal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IHubAccount } from "~global-interfaces/IHubAccount";

import { UserService } from "~features/users/user.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-user-list",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    RouterLink,
    PaginationComponent,
    AdminSidePanelComponent,
    FormsModule,
  ],
  templateUrl: "./admin-user-list.component.html",
  styleUrls: ["./admin-user-list.component.scss"],
})
export class AdminUserListComponent {
  userService = inject(UserService);
  usersList: WritableSignal<IHubAccount[]> = signal<IHubAccount[]>([]);
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);
  selectedCustomerType = signal("");
  mailSearchTerm = signal("");
  constructor() {
    this.fetchUsers();
  }

  fetchUsers(mailSearchTerm?: string) {
    this.userService.fetchUsersList({
      page: this.currentPage(),
      limit: this.itemsPerPage(),
      customerType: this.selectedCustomerType(),
      mailsearchterm: mailSearchTerm,
    });
    this.usersList = this.userService.getUsersList();
    this.totalItems = this.userService.getTotalCount();
  }

  fetchMoreUsers($event: number) {
    this.currentPage.set($event);
    this.fetchUsers();
  }

  onCustomerTypeChange(customerType: string) {
    this.selectedCustomerType.set(customerType);
    this.currentPage.set(1);
    this.fetchUsers();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalItems());
  }

  onSearchClick() {
    this.selectedCustomerType.set("");
    this.currentPage.set(1);
    this.fetchUsers(this.mailSearchTerm());
  }
}
