@if (transcationBannerContent() && frontendConfig().activeTransaction) {
  <div [style.height.px]="bannerHeight" class="w-full"></div>
  <div
    #bannerContainer
    id="bannerContainer"
    class="fixed left-0 right-0 z-40 bg-[#F87171] text-white top-10 lg:top-14"
  >
    <div class="relative">
      <a
        class="block py-2 px-4 hover:bg-opacity-90 transition-colors duration-300"
        [attr.href]="
          isExpanded || !isMobile ? transcationBannerContent().link : null
        "
        [attr.target]="isExpanded || !isMobile ? '_blank' : null"
        (click)="handleClick($event)"
      >
        <div class="container mx-auto">
          <div class="flex justify-between items-center pr-8">
            <!-- Added right padding for the X button -->
            <p
              class="text-sm md:text-base font-medium"
              [class.truncate]="!isExpanded && isMobile"
              [class.md:whitespace-normal]="!isExpanded && isMobile"
            >
              {{ transcationBannerContent().text }}
            </p>
          </div>
        </div>
      </a>
      @if (isExpanded && isMobile) {
        <button
          (click)="collapse()"
          class="absolute top-1/2 right-2 transform -translate-y-1/2 text-white hover:text-gray-200 transition-colors duration-300"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      }
    </div>
  </div>
}
