<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Create a RSS</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div class="md:col-span-4">
        <div class="mx-auto mt-5 border border-2 rounded bg-yellow-100 p-5">
          <app-company-search
            (companySelected)="onCompanySelected($event)"
            [emitterMode]="true"
            [isAdminDashboard]="true"
            class="w-full"
          ></app-company-search>
        </div>
        @if (companyName() === null) {
          <p class="bg-red-300 p-5 border border-2 border-red-800">
            No company selected yet!
          </p>
        }
        <div></div>
        <app-admin-rss-editor-form
          (formSubmit)="onFormSubmit($event, 'en')"
          [companyName]="companyName"
          [creatorMode]="true"
          [lang]="'en'"
          [rssEntry]="rssEntryEN"
        ></app-admin-rss-editor-form>
        @if (rssEntryENMessage()) {
          <p class="bg-green-300 p-5 border border-2 border-green-800">
            English RSS Entry created successfully.
          </p>
        }
        <hr />
        <app-admin-rss-editor-form
          (formSubmit)="onFormSubmit($event, 'de')"
          [companyName]="companyName"
          [creatorMode]="true"
          [lang]="'de'"
          [rssEntry]="rssEntryDE"
        ></app-admin-rss-editor-form>
        @if (rssEntryDEMessage()) {
          <p class="bg-green-300 p-5 border border-2 border-green-800">
            German RSS Entry created successfully.
          </p>
        }
      </div>
    </div>
  </main>
</div>
