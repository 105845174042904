import { ChangeDetectorRef, Component, Input } from "@angular/core";

@Component({
  selector: "app-tab",
  standalone: true,
  imports: [],
  templateUrl: "./tab.component.html",
  styleUrl: "./tab.component.scss",
})
export class TabComponent {
  @Input() title = "";

  constructor(private cdr: ChangeDetectorRef) {}

  private _active = false;

  get active(): boolean {
    return this._active;
  }

  @Input()
  set active(value: boolean) {
    this._active = value;
    this.cdr.detectChanges(); //TODO: refactor this to not use cdr
  }
}
