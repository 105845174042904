<div
  [ngClass]="{ 'cursor-pointer': openInDialog && videoURL }"
  class="relative w-full pb-[56.25%]"
>
  <app-responsive-video
    [videoURL]="videoURL"
    class="absolute top-0 left-0 w-full h-full"
  ></app-responsive-video>
  @if (openInDialog && videoURL) {
    <div
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-transparent"
      (click)="handleClick()"
    ></div>
  }

  @if (redirectToURL && videoURL) {
    <a
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-transparent"
      href="{{ videoURL }}"
      target="_blank"
    >
    </a>
  }
</div>
