import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IHubAccount } from "~global-interfaces/IHubAccount";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { LockIconComponent } from "~shared/components/ui/lock-icon/lock-icon.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-user-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormButtonComponent,
    LockIconComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./admin-user-editor-form.component.html",
  styleUrls: ["./admin-user-editor-form.component.scss"],
})
export class AdminUserEditorFormComponent implements OnInit {
  @Input() user!: Signal<IHubAccount>;
  @Output() formSubmit = new EventEmitter<IHubAccount>();
  adminRequestService = inject(AdminRequestService);

  formBuilder = inject(FormBuilder);
  userForm: FormGroup;
  showMismatchWarning = false;

  constructor() {
    this.adminRequestService.emptyLastRequest();

    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.initializeForm();
    this.patchFormValues();
  }

  initializeForm() {
    this.userForm = this.formBuilder.group({
      userName: ["", [Validators.required, Validators.minLength(3)]],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      customerType: ["", Validators.required],
      isCorporateUser: [false],
      isBrevoContact: [false],
      companyTicker: ["", []],
      companyName: [""],
    });
  }

  patchFormValues() {
    if (this.user()) {
      this.userForm.patchValue({
        userName: this.user().userName,
        firstName: this.user().firstName,
        lastName: this.user().lastName,
        email: this.user().email,
        customerType: this.user().customerType,
        isCorporateUser: this.user().isCorporateUser,
        isBrevoContact: this.user().isBrevoContact,
        companyTicker: this.user().companyTicker
          ? this.user().companyTicker
          : this.user().bloombergTicker,
        companyName: this.user().companyName,
      });
      this.userForm.get("firstName").disable();
      this.userForm.get("lastName").disable();
      //this.userForm.get("customerType").disable();
      this.checkUserNameEmailMismatch();
    }
  }

  saveForm() {
    if (this.userForm.valid) {
      const updatedUser: IHubAccount = {
        ...this.user(),
        ...this.userForm.value,
      };
      this.formSubmit.emit(updatedUser);
    } else {
      this.markFormGroupTouched(this.userForm);
    }
  }
  checkUserNameEmailMismatch() {
    const userName = this.userForm.get("userName").value;
    const email = this.userForm.get("email").value;
    this.showMismatchWarning =
      userName !== email && userName !== "" && email !== "";
  }
  getErrorMessage(controlName: string): string {
    const control = this.userForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters long`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
      if (control.errors["email"]) return `Invalid email format`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
