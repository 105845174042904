import { NgClass } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";

import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-frontend-config",
  standalone: true,
  imports: [BreadcrumpComponent, RouterLink, AdminSidePanelComponent, NgClass],
  templateUrl: "./frontend-config.component.html",
  styleUrl: "./frontend-config.component.scss",
})
export class FrontendConfigComponent {
  private frontEndConfigService = inject(FrontendConfigService);
  frontendConfig = this.frontEndConfigService.getFrontendConfig();
}
