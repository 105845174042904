<form
  [formGroup]="screenerArticleForm"
  class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-4">
    <label
      class="block text-gray-700 text-sm font-bold mb-2"
      for="published_date"
      >Published Date:</label
    >
    <input
      [ngClass]="{
        'border-red-500':
          screenerArticleForm.get('published_date')?.invalid &&
          screenerArticleForm.get('published_date')?.touched
      }"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      formControlName="published_date"
      type="date"
    />
    @if (
      screenerArticleForm.get("published_date")?.invalid &&
      screenerArticleForm.get("published_date")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("published_date") }}
      </p>
    }
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="upload_date"
      >Upload Date:</label
    >
    <input
      [ngClass]="{
        'border-red-500':
          screenerArticleForm.get('upload_date')?.invalid &&
          screenerArticleForm.get('upload_date')?.touched
      }"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      formControlName="upload_date"
      type="date"
    />
    @if (
      screenerArticleForm.get("upload_date")?.invalid &&
      screenerArticleForm.get("upload_date")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("upload_date") }}
      </p>
    }
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="title"
      >Title:</label
    >
    <input
      [ngClass]="{
        'border-red-500':
          screenerArticleForm.get('title')?.invalid &&
          screenerArticleForm.get('title')?.touched
      }"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      formControlName="title"
      type="text"
    />
    @if (
      screenerArticleForm.get("title")?.invalid &&
      screenerArticleForm.get("title")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("title") }}
      </p>
    }
  </div>

  <div class="mb-4">
    <label class="block text-gray-700 text-sm font-bold mb-2" for="teaser_text"
      >Teaser Text:</label
    >
    <textarea
      [ngClass]="{
        'border-red-500':
          screenerArticleForm.get('teaser_text')?.invalid &&
          screenerArticleForm.get('teaser_text')?.touched
      }"
      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      formControlName="teaser_text"
      id="teaser_text"
    ></textarea>
    @if (
      screenerArticleForm.get("teaser_text")?.invalid &&
      screenerArticleForm.get("teaser_text")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("teaser_text") }}
      </p>
    }
  </div>

  <!--  <div class="mb-4">-->
  <!--    <label-->
  <!--      class="block text-gray-700 text-sm font-bold mb-2"-->
  <!--      for="primary_language"-->
  <!--      >Primary Language:</label-->
  <!--    >-->
  <!--    <input-->
  <!--      [ngClass]="{-->
  <!--        'border-red-500':-->
  <!--          screenerArticleForm.get('primary_language')?.invalid &&-->
  <!--          screenerArticleForm.get('primary_language')?.touched-->
  <!--      }"-->
  <!--      class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"-->
  <!--      formControlName="primary_language"-->
  <!--      type="text"-->
  <!--    />-->
  <!--    @if (-->
  <!--      screenerArticleForm.get("primary_language")?.invalid &&-->
  <!--      screenerArticleForm.get("primary_language")?.touched-->
  <!--    ) {-->
  <!--      <p class="text-red-500 text-xs italic mt-1">-->
  <!--        {{ getErrorMessage("primary_language") }}-->
  <!--      </p>-->
  <!--    }-->
  <!--  </div>-->

  <div class="mb-4">
    <label class="inline-flex items-center">
      <input
        class="form-checkbox h-5 w-5 text-indigo-600"
        formControlName="is_public"
        type="checkbox"
      />
      <span class="ml-2 text-gray-700">Is Public</span>
    </label>
  </div>

  <div class="mb-4 p-4 bg-gray-100 rounded-md" formGroupName="content_DE">
    <!--    <div class="mb-2">-->
    <!--      <label-->
    <!--        class="block text-gray-700 text-sm font-bold mb-2"-->
    <!--        for="content_DE_language"-->
    <!--        >Language:</label-->
    <!--      >-->
    <!--      <input-->
    <!--        [ngClass]="{-->
    <!--          'border-red-500':-->
    <!--            screenerArticleForm.get('content_DE.language')?.invalid &&-->
    <!--            screenerArticleForm.get('content_DE.language')?.touched-->
    <!--        }"-->
    <!--        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"-->
    <!--        formControlName="language"-->
    <!--        type="text"-->
    <!--      />-->
    <!--      @if (-->
    <!--        screenerArticleForm.get("content_DE.language")?.invalid &&-->
    <!--        screenerArticleForm.get("content_DE.language")?.touched-->
    <!--      ) {-->
    <!--        <p class="text-red-500 text-xs italic mt-1">-->
    <!--          {{ getErrorMessage("language", "content_DE") }}-->
    <!--        </p>-->
    <!--      }-->
    <!--    </div>-->
    <!--    <div class="mb-2">-->
    <!--      <label-->
    <!--        class="block text-gray-700 text-sm font-bold mb-2"-->
    <!--        for="content_DE_created_date"-->
    <!--        >Created Date:</label-->
    <!--      >-->
    <!--      <input-->
    <!--        [ngClass]="{-->
    <!--          'border-red-500':-->
    <!--            screenerArticleForm.get('content_DE.created_date')?.invalid &&-->
    <!--            screenerArticleForm.get('content_DE.created_date')?.touched-->
    <!--        }"-->
    <!--        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"-->
    <!--        formControlName="created_date"-->
    <!--        type="date"-->
    <!--      />-->
    <!--      @if (-->
    <!--        screenerArticleForm.get("content_DE.created_date")?.invalid &&-->
    <!--        screenerArticleForm.get("content_DE.created_date")?.touched-->
    <!--      ) {-->
    <!--        <p class="text-red-500 text-xs italic mt-1">-->
    <!--          {{ getErrorMessage("created_date", "content_DE") }}-->
    <!--        </p>-->
    <!--      }-->
    <!--    </div>-->
    <div class="mb-2">
      <label
        class="block text-gray-700 text-sm font-bold mb-2"
        for="content_DE_type"
        >Type:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200"
        formControlName="type"
        type="text"
      />
    </div>
    <!--    <div>-->
    <!--      <label class="inline-flex items-center">-->
    <!--        <input-->
    <!--          class="form-checkbox h-5 w-5 text-indigo-600"-->
    <!--          formControlName="is_public"-->
    <!--          type="checkbox"-->
    <!--        />-->
    <!--        <span class="ml-2 text-gray-700">Is Public</span>-->
    <!--      </label>-->
    <!--    </div>-->
    <app-edit-editor-content
      (contentSave)="onEditorContentSave($event)"
      [content]="screenerArticleForm.get('content_DE.content')?.value || ''"
    ></app-edit-editor-content>
    @if (
      screenerArticleForm.get("content_DE.content")?.invalid &&
      screenerArticleForm.get("content_DE.content")?.touched
    ) {
      <p class="text-red-500 text-xs italic mt-1">
        {{ getErrorMessage("content", "content_DE") }}
      </p>
    }
  </div>

  <app-admin-form-button
    (click)="saveForm()"
    [class]="'admin-form-button'"
    [disabled]="!screenerArticleForm.valid"
  >
  </app-admin-form-button>

  <app-admin-form-request-message></app-admin-form-request-message>
</form>
