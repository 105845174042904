<form (ngSubmit)="onSubmit()" [formGroup]="profileForm">
  <div class="flex flex-wrap">
    <div [ngClass]="isCustomerInvestor ? 'w-1/2' : 'w-full'">
      <div class="mb-4">
        <label class="block mb-2" for="VORNAME">First Name</label>
        <input
          [ngClass]="{ 'border-red-500': submitted && f['VORNAME'].errors }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="VORNAME"
          id="VORNAME"
          type="text"
        />
      </div>
      <div class="mb-4">
        <label class="block mb-2" for="NACHNAME">Last Name</label>
        <input
          [ngClass]="{ 'border-red-500': submitted && f['NACHNAME'].errors }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="NACHNAME"
          id="NACHNAME"
          type="text"
        />
      </div>

      <div class="mb-4">
        <label class="block mb-2" for="PROFIL_INVESTORENTYP">Role</label>
        <select
          [ngClass]="{
            'border-red-500': submitted && f['PROFIL_INVESTORENTYP'].errors
          }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="PROFIL_INVESTORENTYP"
        >
          @for (option of customerTypes; track option) {
            <option [value]="option">{{ option }}</option>
          }
        </select>
        @if (submitted && f["PROFIL_INVESTORENTYP"].errors) {
          <div class="text-red-500 text-sm mt-1">
            @if (f["PROFIL_INVESTORENTYP"].errors["required"]) {
              <div>Role is required</div>
            }
          </div>
        }
      </div>

      <div class="mb-4">
        <label class="block mb-2" for="COMPANY_NAME">Company name</label>
        <input
          [ngClass]="{
            'border-red-500': submitted && f['COMPANY_NAME'].errors
          }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="COMPANY_NAME"
          id="COMPANY_NAME"
          type="text"
        />
      </div>
      <div class="mb-4">
        <label class="block mb-2" for="JOB_TITLE">Job title</label>
        <input
          [ngClass]="{
            'border-red-500': submitted && f['JOB_TITLE'].errors
          }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="JOB_TITLE"
          id="JOB_TITLE"
          type="text"
        />
      </div>
      <div class="mb-4">
        <label class="block mb-2" for="CITY">City</label>
        <input
          [ngClass]="{ 'border-red-500': submitted && f['CITY'].errors }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="CITY"
          id="CITY"
          type="text"
        />
      </div>
      <div class="mb-4">
        <label class="block mb-2" for="COUNTRY_NEU">Country</label>
        <select
          [ngClass]="{
            'border-red-500': submitted && f['COUNTRY_NEU'].errors
          }"
          class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          formControlName="COUNTRY_NEU"
        >
          @for (option of Countries; track option) {
            <option [value]="option">{{ option }}</option>
          }
        </select>
        @if (submitted && f["COUNTRY_NEU"].errors) {
          <div class="text-red-500 text-sm mt-1">
            @if (f["COUNTRY_NEU"].errors["required"]) {
              <div>Role is required</div>
            }
          </div>
        }
      </div>
    </div>
    @if (isCustomerInvestor) {
      <div class="w-1/2 pl-4 border-l">
        @for (field of investorProfileFields; track field) {
          <div class="mb-4">
            <label [for]="field" class="block mb-2"
              >{{ field | profileAttributeTranslator }}:</label
            >
            <select
              [formControlName]="field"
              [id]="field"
              class="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            >
              @for (option of getEnumOptions(field); track option) {
                <option [value]="option">{{ option }}</option>
              }
            </select>
          </div>
        }
      </div>
    }
  </div>

  <div class="my-6">
    <div class="w-1/2 mx-auto">
      <div class="text-center">
        @if (!loading && !success) {
          <button
            type="submit"
            class="px-4 py-2 bg-blue-500 text-white rounded uppercase"
          >
            Update
          </button>
        }
        @if (loading) {
          <button
            class="px-4 py-2 bg-blue-500 text-white rounded uppercase"
            disabled
          >
            Saving...
          </button>
          <span class="block mt-2 text-black"
            >Your request is being processed.</span
          >
        }
        @if (success) {
          <span class="block text-green-500">
            Thank you! Your profile has been updated.
          </span>
          <button
            type="button"
            (click)="profileUpdated.emit(true)"
            class="mt-4 px-4 py-2 bg-blue-500 text-white rounded uppercase"
          >
            Close
          </button>
        }
      </div>
    </div>
  </div>
</form>
