import { ICompany } from "~global-interfaces/ICompany";
import { ICorporateVideo } from "~global-interfaces/ICorporateVideo";
import { IEditorContent } from "~global-interfaces/IEditorContent";
import { IEvent } from "~global-interfaces/IEvent";
import { IInitiationNote } from "~global-interfaces/IInitiationNote";
import { ILegalDisclaimerCompany } from "~global-interfaces/ILegalDisclaimerCompany";
import { IResearch } from "~global-interfaces/IResearch";
import { IResearchPDF } from "~global-interfaces/IResearchPDF";
import { IScreenerArticle } from "~global-interfaces/IScreenerArticle";
import { ISWOT } from "~global-interfaces/ISWOT";
import { ITransaction } from "~global-interfaces/ITransaction";

export const INITIAL_COMPANY: ICompany = {
  name: "",
  companyTicker: "",
  slug: "",
  baseyear: 2023,
  charts: [
    {
      currentTemplate: "segment sales",
      position: "left",
    },
    {
      currentTemplate: "regional sales",
      position: "left",
    },
    {
      currentTemplate: "major shareholders",
      position: "left",
    },

    {
      currentTemplate: "sales and earnings momentum",
      position: "right",
    },
    {
      currentTemplate: "ROCEvsWACC",
      position: "right",
    },
    {
      currentTemplate: "free cash flow development",
      position: "right",
    },
  ],
  isFeatured: "",
  is_public: false,
  imageURL: "",
  is_covered: true,
  logoURL: "",
  presentationURL: "",
  companyVideoURL: "",
  is_hidden: false,
  calculation_settings: {
    numDecimals: 0,
  },
  valuation_settings: {
    show_trading_multiples: true,
    show_dcf_model: true,
    show_adj_fcf_yield: true,
    show_editorContent: false,
  },
  research_settings: {
    currentTemplate: "Half year table",
  },
  financials_settings: {
    currentTemplate: "financials-UKV",
  },
  mappedTVChartTicker: "",
};

export const INITIAL_CORPORATE_VIDEO: ICorporateVideo = {
  companyTicker: "",
  videoURL: "",
};
export const INITIAL_EDITORCONTENT: IEditorContent = {
  language: "de",
  content: "",
  created_date: "",
  type: "Research",
  is_public: true,
  companyTicker: "",
};

export const INITIAL_EDITORCONTENT_INVESTMENTCASE: IEditorContent = {
  language: "de",
  content: "",
  created_date: "",
  type: "InvestmentCase",
  is_public: true,
  companyTicker: "",
};

export const INITIAL_EDITORCONTENT_VALUATION: IEditorContent = {
  language: "de",
  content: "",
  created_date: "",
  type: "Valuation",
  is_public: true,
  companyTicker: "",
};

export const INITIAL_EDITORCONTENT_SCREENER: IEditorContent = {
  language: "de",
  content: "",
  created_date: "",
  type: "WeeklyScreener",
  is_public: true,
  companyTicker: "",
};

export const INITIAL_RESEARCH_PDF: IResearchPDF = {
  published_date: "",
  upload_date: "",
  title: "",
  research_date: "",
  companyTicker: "",
  language: "de",
  downloadURL: "",
  filename: "",
  shownName: "",
};

export const INITIAL_RESEARCH: IResearch = {
  published_date: "",
  upload_date: "",
  title: "",
  research_date: "",
  companyTicker: "",
  primary_language: "en",
  content_DE: INITIAL_EDITORCONTENT,
  pdf_DE: INITIAL_RESEARCH_PDF,
  is_public: true,
};

export const INITIAL_SCREENERARTICLE: IScreenerArticle = {
  published_date: "",
  upload_date: "",
  teaser_text: "",
  title_image: "",
  title: "",
  primary_language: "de",
  content_DE: INITIAL_EDITORCONTENT_SCREENER,
  is_public: false,
};

export const INITIAL_SWOT: ISWOT = {
  companyTicker: "",
  strengths: [],
  weaknesses: [],
  opportunities: [],
  threats: [],
  is_public: true,
  language: "de",
  created_date: "",
};

export const INITIAL_EVENT: IEvent = {
  title: "",
  date: "",
  language: "de",
  is_public: false,
  companyName: "",
  companyTicker: "",
  videoURL: "",
  subscribers: [],
  sibListId: "",
  speakers: [],
  presentationURL: "",
  feedbackURL: "",
  description: "",
  companyLogoURL: "",
  meetingLink: "",
  showFeedback: false,
};
export const INITIAL_INITIATIONNOTE: IInitiationNote = {
  companyTicker: "",
  research: "", // this is actually a Schema.Types.ObjectId that is populated with the research object, but for the form it just is a string now
};

export const INITIAL_LEGALDISCLAIMERCOMPANY: ILegalDisclaimerCompany = {
  bloomberg: "",
  disclosures: "",
  name: "",
};

export const INITIAL_TRANSACTION: ITransaction = {
  link: "",
  text: "",
};
