import { CdkAccordion, CdkAccordionItem } from "@angular/cdk/accordion";
import { JsonPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  Input,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";

import { ExcelService } from "~features/company/excel.service";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { TablesService } from "~features/tables/table.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

import { DataServiceService } from "../../data-service.service";
import { Tables } from "../tables.util";

@Component({
  selector: "app-table-notabs",
  templateUrl: "./table-notabs.component.html",
  styleUrls: ["./table-notabs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    CdkAccordion,
    CdkAccordionItem,
    DisplayEditorContentComponent,
    JsonPipe,
  ],
})
export class TableNotabsComponent {
  @Input() companyId: Signal<string>;
  @Input() companyData!: Signal<any>;
  @Input() decimals: number = 0;
  @Input() templateName = "Keyfacts PnL";
  @Input() collapse;
  @Input() customDesign: boolean = false;
  headline: WritableSignal<string> = signal("");

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: "Company data; mwb research",
    ger: "Firmenangaben; mwb research",
  };

  possibleJumpmarks = ["valuation-fcf-yield", "valuation-trading-multiples"];

  excelService = inject(ExcelService);
  tablesService = inject(TablesService);
  tableLayout: WritableSignal<any> = signal<any>(null);
  tableTemplate: WritableSignal<any>;

  constructor(
    private tableUtil: Tables,
    private dataService: DataServiceService,
    private cdr: ChangeDetectorRef
  ) {
    effect(() => {
      const companyNumbers = this.companyData();

      if (companyNumbers) {
        this.tablesService
          .fetchTableTemplate(this.templateName)
          .subscribe((tableTemplate) => {
            const tableData = this.tableUtil.createTableData(
              this.companyData(),
              tableTemplate.template,
              this.decimals
            );
            this.tableLayout.set(tableData);
            this.headline.set(
              tableTemplate.settings.title[tableData[0].settings.language]
            );
          });
      } else {
        this.tableLayout.set(null);
      }
    }, enableSignalWritesInEffectContext);
  }

  findAndAddJumpmark() {
    switch (this.headline()) {
      case "Adj. FCF yield": {
        return this.possibleJumpmarks[0];
      }
      case "Trading Multiples": {
        return this.possibleJumpmarks[1];
      }
    }
  }

  getRowClasses(row: any): string {
    if (row.headline === true) {
      return "font-bold bg-gray-table-highlight text-white";
    } else if (row.highlight) {
      return "bg-blue-alster font-bold";
    } else if (row.highlight2) {
      return "bg-gray-200";
    } else {
      return "font-normal";
    }
  }
}
