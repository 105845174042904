import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Input,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";

import { TablesService } from "~features/tables/table.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-keyfacts",
  templateUrl: "./keyfacts.component.html",
  styleUrls: ["./keyfacts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class KeyfactsComponent {
  tablesService = inject(TablesService);
  @Input() companyId: Signal<string>;
  @Input() companyData!: Signal<any>;
  @Input() decimals = 0;

  //yea this is crap. better to build a "no tabs table" component and insert it 3 times here
  // but this will do for now
  //for now also no point to input this
  @Input() templateNamePnL = { ger: "Keyfacts PnL", au: "Keyfacts PnL AU" };
  @Input() templateNameBS = { ger: "Keyfacts B/S", au: "Keyfacts B/S AU" };
  @Input() templateNameMult = {
    ger: "Keyfacts Multiples",
    au: "Keyfacts Multiples",
  };

  @Input() lang = "ger";

  //put this as tableTemplate in DB. Allow template selection in table creation
  //write UI to create templates
  // -> select  and set commonSize true/false
  // -> select keys
  // -> mark highlight and (NO)DATA/DAtes
  // -> validate everything marked DATA (default) is contained in mapping

  tableLayoutPnL: WritableSignal<any> = signal(this.tableUtil.defaultLayout);
  tableLayoutBS: WritableSignal<any> = signal(this.tableUtil.defaultLayout);
  tableLayoutMult: WritableSignal<any> = signal(this.tableUtil.defaultLayout);

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: "Company data; mwb research",
    ger: "Firmenangaben; mwb research",
  };

  constructor(private tableUtil: Tables) {
    effect(() => {
      const companyNumbers = this.companyData();

      if (companyNumbers) {
        this.tablesService
          .fetchTableTemplate(this.templateNamePnL[this.lang])
          .subscribe((tableTemplate) => {
            const tableData = this.tableUtil.createTableData(
              companyNumbers,
              tableTemplate.template,
              this.decimals
            );
            this.tableLayoutPnL.set(tableData);
          });

        this.tablesService
          .fetchTableTemplate(this.templateNameBS[this.lang])
          .subscribe((tableTemplate) => {
            const tableData = this.tableUtil.createTableData(
              companyNumbers,
              tableTemplate.template,
              this.decimals
            );
            this.tableLayoutBS.set(tableData);
          });

        this.tablesService
          .fetchTableTemplate(this.templateNameMult[this.lang])
          .subscribe((tableTemplate) => {
            const tableData = this.tableUtil.createTableData(
              companyNumbers,
              tableTemplate.template,
              this.decimals
            );
            this.tableLayoutMult.set(tableData);
          });
      } else {
        this.tableLayoutPnL.set(null);
        this.tableLayoutBS.set(null);
        this.tableLayoutMult.set(null);
      }
    }, enableSignalWritesInEffectContext);
  }

  getRowClasses(row: any): string {
    if (row.headline === true) {
      return "font-bold bg-gray-table-highlight text-white";
    } else if (row.highlight) {
      return "bg-blue-alster font-bold";
    } else if (row.highlight2) {
      return "bg-gray-200";
    } else {
      return "font-normal";
    }
  }
}
