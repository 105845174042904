import { NgClass } from "@angular/common";
import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";

import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-company-exchange-mapping-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./company-exchange-mapping-editor-form.component.html",
  styleUrl: "./company-exchange-mapping-editor-form.component.scss",
})
export class CompanyExchangeMappingEditorFormComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  creationProcessMenuService = inject(CreationProcessMenuService);
  @Input() currentCompanyTicker: string;
  @Input() exchangeMappingData: { [key: string]: string };
  exchangeMappingForm: FormGroup;
  @Output() formSubmit = new EventEmitter<any>();
  adminRequestService = inject(AdminRequestService);
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  constructor() {
    this.adminRequestService.emptyLastRequest();
  }

  get mappings(): FormArray {
    return this.exchangeMappingForm.get("mappings") as FormArray;
  }

  ngOnInit() {
    this.initializeForm();
    this.patchFormValues();
    this.formValidity.emit(this.exchangeMappingForm.valid);
    this.formValueChange.emit(this.exchangeMappingForm.value);
    // Subscribe to form changes
    this.exchangeMappingForm.valueChanges.subscribe((values) => {
      this.formValueChange.emit(values);
    });

    // Subscribe to form validity changes
    this.exchangeMappingForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  initializeForm() {
    this.exchangeMappingForm = this.formBuilder.group({
      mappings: this.formBuilder.array([]),
    });
  }

  patchFormValues() {
    this.exchangeMappingForm.patchValue({
      companyTicker: this.currentCompanyTicker,
    });

    if (this.exchangeMappingData) {
      Object.entries(this.exchangeMappingData).forEach(
        ([companyTicker, mappedTicker]) => {
          this.addMapping(this.formatKey(companyTicker), mappedTicker);
        }
      );
    }
  }

  addMapping(companyTicker = "", mappedTicker = "") {
    const companyTickerFromGuidedMenu =
      this.creationProcessMenuService.isGuidedMenuEnabled() &&
      companyTicker === "" &&
      this.currentCompanyTicker !== null
        ? this.currentCompanyTicker
        : companyTicker;
    const mappingForm = this.formBuilder.group({
      companyTicker: [
        this.formatKey(companyTickerFromGuidedMenu),
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
        ],
      ],
      mappedTicker: [
        mappedTicker,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
        ],
      ],
    });
    this.mappings.push(mappingForm);
  }

  removeMapping(index: number) {
    this.mappings.removeAt(index);
  }

  formatKey(key: string): string {
    return key.replace(/_/g, ":");
  }

  unformatKey(key: string): string {
    return key.replace(/:/g, "_");
  }

  saveForm() {
    if (this.exchangeMappingForm.valid) {
      const mappings = this.mappings.value.reduce(
        (acc, { companyTicker, mappedTicker }) => {
          acc[this.unformatKey(companyTicker)] = mappedTicker;
          return acc;
        },
        {}
      );
      this.formSubmit.emit(mappings);
    }
  }

  getErrorMessage(control: any): string {
    if (control.errors?.["required"]) {
      return "This field is required";
    }
    if (control.errors?.["minlength"]) {
      return `Minimum length is ${control.errors["minlength"].requiredLength} characters`;
    }
    if (control.errors?.["maxlength"]) {
      return `Maximum length is ${control.errors["maxlength"].requiredLength} characters`;
    }
    return "";
  }
}
