import { CdkAccordion, CdkAccordionItem } from "@angular/cdk/accordion";
import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  Input,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";

import { CompanyInfoService } from "~features/company/company-info.service";
import { ExcelService } from "~features/company/excel.service";
import { DataServiceService } from "~features/data-service.service";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { Tables } from "~features/tables/tables.util";
import { formatValue } from "~utils/numberFormatting.util";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-dcf-model",
  templateUrl: "./dcf-model.component.html",
  styleUrls: ["./dcf-model.component.scss"],
  standalone: true,
  imports: [
    DecimalPipe,
    CdkAccordion,
    CdkAccordionItem,
    DisplayEditorContentComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DcfModelComponent {
  @Input() companyData!: Signal<any>;
  @Input() dcfType = "Default";

  excelService = inject(ExcelService);

  rawDcfData = {
    HL_dcf: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_nopat: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_depr: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_chg_wc: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_chg_long_prov: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_capex: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_cash_flow: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_present_value: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    RW_dcf_wacc: {
      value: [],
      key: "",
      type: "TIMESERIES",
    },
    dcf_share_price: { key: "", value: "" },
    dcf_tpv: { key: "", value: "" },
    dcf_mid_yr_adj: { key: "", value: "" },
    dcf_net_debt: { key: "", value: "" },
    dcf_fin_asset: { key: "", value: "" },
    dcf_prov: { key: "", value: "" },
    dcf_eq_value: { key: "", value: "" },
    dcf_nosh: { key: "", value: "" },
    dcf_dcfps: { key: "", value: "" },
    dcf_upside: { key: "", value: "" },
    dcf_plan_hor: { key: "", value: "" },
    dcf_term_val_gro: { key: "", value: "" },
    dcr_roce: { key: "", value: "" },
    dcf_term_val_wacc: { key: "", value: "" },
    dcf_share_a: { key: "", value: "" },
    dcf_share_b: { key: "", value: "" },
    dcf_share_c: { key: "", value: "" },
    dcf_cost_borr: { key: "", value: "" },
    dcf_long_term_tax_rate: { key: "", value: "" },
    dcf_beta: { key: "", value: "" },
    dcf_unlevered_beta: { key: "", value: "" },
    dcf_target_debt_equity: { key: "", value: "" },
    dcf_relevered_beta: { key: "", value: "" },
    dcf_rfr: { key: "", value: "" },
    dcf_erp: { key: "", value: "" },
    dcf_cost_of_debt: { key: "", value: "" },
    sensi_a: { key: "", value: "" },
    sensi_b: { key: "", value: "" },
    sensi_c: { key: "", value: "" },
    sensi_d: { key: "", value: "" },
    sensi_e: { key: "", value: "" },
    sensi_f: { key: "", value: "" },
    sensi_g: { key: "", value: "" },
    sensi_h: { key: "", value: "" },
    sensi_i: { key: "", value: "" },
    sensi_j: { key: "", value: "" },
    sensi_k: { key: "", value: "" },
    sensi_l: { key: "", value: "" },
    sensi_m: { key: "", value: "" },
    sensi_n: { key: "", value: "" },
    sensi_o: { key: "", value: "" },
    sensi_p: { key: "", value: "" },
    sensi_q: { key: "", value: "" },
    sensi_r: { key: "", value: "" },
    sensi_s: { key: "", value: "" },
    sensi_t: { key: "", value: "" },
    sensi_u: { key: "", value: "" },
    sensi_v: { key: "", value: "" },
    sensi_w: { key: "", value: "" },
    sensi_x: { key: "", value: "" },
    sensi_y: { key: "", value: "" },
    sensi_z: { key: "", value: "" },
    sensi_aa: { key: "", value: "" },
    sensi_ab: { key: "", value: "" },
    sensi_ac: { key: "", value: "" },
    sensi_ad: { key: "", value: "" },
    sensi_ae: { key: "", value: "" },
    sensi_af: { key: "", value: "" },
    sensi_ag: { key: "", value: "" },
    sensi_ah: { key: "", value: "" },
    sensi_ai: { key: "", value: "" },
  };
  dcfData: WritableSignal<any> = signal(this.rawDcfData);
  excelMapping = this.excelService.getExcelMapping();
  baseYear;
  notApplicable = "na";
  language = "eng";
  defaultDecimals = 1;

  start = 2020;
  mid = 2024;
  mid2 = 2025;
  end = 2028;

  constructor(
    private tableUtil: Tables,
    private dataService: DataServiceService,
    private companyInfoService: CompanyInfoService,
    private cdr: ChangeDetectorRef
  ) {
    effect(() => {
      this.prepareData();
    }, enableSignalWritesInEffectContext); // Warning: This is here because of uknown side effect TODO: check for side effect and fix
  }

  prepareData() {
    const companyNumbers = this.companyData();

    const mapping = this.excelMapping();
    const bY = "2022"; //companyNumbers.baseyear;

    if (typeof bY !== "undefined" && bY !== null) {
      this.baseYear = bY.toString();
    }
    if (
      companyNumbers !== null &&
      companyNumbers !== undefined &&
      companyNumbers.base &&
      companyNumbers.financial_figures &&
      this.baseYear !== undefined &&
      Object.keys(mapping).length > 0
    ) {
      const dcfDates = this.tableUtil.getData(
        mapping["HL_dcf"].path,
        companyNumbers
      );
      this.start = dcfDates[0].value;
      this.mid = dcfDates[3].value;
      this.mid2 = dcfDates[4].value;
      this.end = dcfDates[dcfDates.length - 2].value;

      for (const key in this.rawDcfData) {
        const entry = this.rawDcfData[key];

        if (entry.type === "TIMESERIES") {
          entry.value = this.tableUtil.getTimeseries(
            key,
            companyNumbers,
            mapping[key],
            -1,
            10,
            "value",
            "eng",
            1
          );
        } else {
          entry.value = this.tableUtil.getValue(
            companyNumbers,
            mapping[key],
            this.language,
            this.defaultDecimals
          );
        }

        if (key === "dcf_plan_hor") {
          entry.key =
            mapping[key][this.language] +
            " (" +
            this.start +
            " - " +
            this.end +
            ")";
        } else if (key === "dcf_term_val_gro") {
          entry.key =
            mapping[key][this.language] + " (" + this.end + " - infinity)";
        } else if (key === "dcf_dcfps") {
          entry.value = parseFloat(entry.value);
        } else {
          entry.key = mapping[key][this.language];
        }
      }
      this.dcfData.set(this.rawDcfData);
    }
  }

  getValue(
    companyData,
    mappingEntry,
    language: string,
    defaultDecimals
  ): Array<string> {
    const tableData = [];
    const originalData = this.tableUtil.getData(mappingEntry.path, companyData);

    if (typeof originalData !== "undefined") {
      tableData.push(
        formatValue(originalData, mappingEntry, language, defaultDecimals)
      );
    } else {
      tableData.push(this.notApplicable);
    }

    return tableData;
  }
}
