<div class="container mx-auto px-16">
  <div class="py-12">
    <div class="w-5/6">
      <h3 class="mb-4">Impressum</h3>
      <p class="mb-4">
        mwb research AG<br />
        Mittelweg 142<br />
        20148 Hamburg<br />
      </p>

      <p class="mb-4">
        Fon: +49 40 309 293-52<br />
        Fax: +49 40 556 330-54<br />
      </p>

      <p class="mb-4">E-Mail: info&#64;mwb-research.com<br /></p>

      <p class="mb-4">
        Vorstand: Holger Nass, Thomas Wissler, Dr. Oliver Wojahn <br />
        Vorsitzender des Aufsichtsrats: Detlef Lübbe, Kai Jordan
      </p>

      <p class="mb-4">
        Inhaltlich Verantwortliche gemäß § 55 Abs. 2 RStV: Thomas Wissler
      </p>
      <p class="mb-4">
        Registergericht: Amtsgericht Hamburg – HRB 98756<br />
        USt-IdNr.: DE249083224<br />
      </p>

      <h3 class="text-md font-bold mb-4">Zuständige Aufsichtsbehörde:</h3>

      <p class="mb-4">
        Bundesanstalt für Finanzdienstleistungsaufsicht<br />
        Marie-Curie-Str. 24-28<br />
        60439 Frankfurt<br />
      </p>

      <p class="mb-4">
        Postfach 50 01 54<br />
        60391 Frankfurt<br />
      </p>
      <p class="mb-4">
        Fon: +49 228 / 4108 – 0<br />
        Fax: +49 228 / 4108 – 123<br />
        E-Mail: poststelle&#64;bafin.de<br />
        De-Mail: poststelle&#64;bafin.de-mail.de<br />
      </p>

      <p class="mb-4">
        Haftungshinweis<br />
        Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
        für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten
        sind ausschließlich deren Betreiber verantwortlich.
      </p>

      <p>
        Bildnachweise<br />
        Urheber und Bildquelle der verwendeten Bilder: Nick Herbold,
        Jarrestrasse 82a, 22303 Hamburg, www.sehenswuerdigkeiten-in.hamburg
      </p>
    </div>
  </div>
</div>
