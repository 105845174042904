<div class="container mx-auto px-4 py-8">
  <div class="mx-auto">
    <form
      [formGroup]="investmentCaseForm"
      class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
    >
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!--        <div>-->
        <!--          <label-->
        <!--            class="block text-gray-700 text-sm font-secondary mb-2"-->
        <!--            for="language"-->
        <!--          >-->
        <!--            Language:-->
        <!--          </label>-->
        <!--          <input-->
        <!--            [ngClass]="{-->
        <!--              'border-red-500':-->
        <!--                investmentCaseForm.get('language')?.invalid &&-->
        <!--                investmentCaseForm.get('language')?.touched-->
        <!--            }"-->
        <!--            class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"-->
        <!--            formControlName="language"-->
        <!--            id="language"-->
        <!--            required-->
        <!--            type="text"-->
        <!--          />-->
        <!--          @if (-->
        <!--            investmentCaseForm.get("language")?.invalid &&-->
        <!--            investmentCaseForm.get("language")?.touched-->
        <!--          ) {-->
        <!--            <p class="mt-1 text-xs text-red-500">-->
        <!--              {{ getErrorMessage("language") }}-->
        <!--            </p>-->
        <!--          }-->
        <!--        </div>-->
        <div>
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="created_date"
          >
            Created Date:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                investmentCaseForm.get('created_date')?.invalid &&
                investmentCaseForm.get('created_date')?.touched
            }"
            class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
            formControlName="created_date"
            id="created_date"
            required
            type="date"
          />
          @if (
            investmentCaseForm.get("created_date")?.invalid &&
            investmentCaseForm.get("created_date")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("created_date") }}
            </p>
          }
        </div>
        <div>
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="type"
          >
            Type:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                investmentCaseForm.get('type')?.invalid &&
                investmentCaseForm.get('type')?.touched
            }"
            class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
            formControlName="type"
            id="type"
            required
            type="text"
          />
          @if (
            investmentCaseForm.get("type")?.invalid &&
            investmentCaseForm.get("type")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("type") }}
            </p>
          }
        </div>
        <div>
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="companyTicker"
          >
            Company Ticker:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                investmentCaseForm.get('companyTicker')?.invalid &&
                investmentCaseForm.get('companyTicker')?.touched
            }"
            class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
            formControlName="companyTicker"
            id="companyTicker"
            required
            type="text"
          />
          @if (
            investmentCaseForm.get("companyTicker")?.invalid &&
            investmentCaseForm.get("companyTicker")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("companyTicker") }}
            </p>
          }
        </div>
      </div>

      <!--      <div class="mt-6">-->
      <!--        <label class="flex items-center">-->
      <!--          <input-->
      <!--            class="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"-->
      <!--            formControlName="is_public"-->
      <!--            id="is_public"-->
      <!--            type="checkbox"-->
      <!--          />-->
      <!--          <span class="ml-2 text-gray-700 text-sm font-secondary"-->
      <!--            >Is Public</span-->
      <!--          >-->
      <!--        </label>-->
      <!--      </div>-->

      <div class="mt-6">
        <label
          class="block text-gray-700 text-sm font-secondary mb-2"
          for="content"
        >
          Content:
        </label>
        <app-edit-editor-content
          (contentSave)="onEditorContentSave($event)"
          [content]="investmentCaseForm.get('content')?.value || ''"
          class="mt-1"
        ></app-edit-editor-content>
        @if (
          investmentCaseForm.get("content")?.invalid &&
          investmentCaseForm.get("content")?.touched
        ) {
          <p class="mt-1 text-xs text-red-500">
            {{ getErrorMessage("content") }}
          </p>
        }
      </div>
      @if (!multiMode) {
        <div class="mt-8 flex justify-end">
          <app-admin-form-button
            (click)="saveForm()"
            [class]="'admin-form-button'"
            [disabled]="!investmentCaseForm.valid"
          >
          </app-admin-form-button>
        </div>
      }
      @if (!multiMode) {
        <app-admin-form-request-message></app-admin-form-request-message>
      }
    </form>
  </div>
</div>
