<div class="grid grid-cols-1">
  <div class="col-span-1">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-8">
      <div class="col-span-2 lg:col-span-1">
        <app-event-search
          (companySelected)="loadEventsBySearchTerm($event)"
        ></app-event-search>
      </div>
      @if (listOfArchiveEvents() && listOfArchiveEvents().length > 0) {
        <div class="col-span-2 lg:col-span-1">
          <app-pagination
            (pageChanged)="fetchMoreEvents($event)"
            [currentPage]="currentPage"
            [itemsPerPage]="itemsPerPage"
            [totalItems]="totalItems"
          ></app-pagination>
        </div>
        @for (archiveEvent of listOfArchiveEvents(); track archiveEvent) {
          <app-event-grid-tile-archived
            class="col-span-2 lg:col-span-1"
            [listMode]="true"
            [archiveEvent]="archiveEvent"
          ></app-event-grid-tile-archived>
        }
        <div class="col-span-2">
          <app-pagination
            (pageChanged)="fetchMoreEvents($event)"
            [currentPage]="currentPage"
            [itemsPerPage]="itemsPerPage"
            [totalItems]="totalItems"
          ></app-pagination>
        </div>
      } @else {
        <div class="col-span-1 lg:col-span-2">
          <div class="text-center text-lg font-semibold py-32">
            No archived events found
          </div>
        </div>
      }
    </div>
  </div>
</div>
