import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { AdminLegalDisclaimerCompanyEditorFormComponent } from "~features/legal/admin-legal-disclaimer-company-editor-form/admin-legal-disclaimer-company-editor-form.component";
import { LegalDisclaimerService } from "~features/legal/legal-disclaimer.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";

@Component({
  selector: "app-admin-legal-disclaimer-company-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminLegalDisclaimerCompanyEditorFormComponent,
    AdminSidePanelComponent,
    DeleteButtonComponent,
  ],
  templateUrl: "./admin-legal-disclaimer-company-editor.component.html",
  styleUrl: "./admin-legal-disclaimer-company-editor.component.scss",
})
export class AdminLegalDisclaimerCompanyEditorComponent {
  showDeletionWarning: WritableSignal<boolean> = signal(false);
  protected readonly event = event;
  private legalDisclaimerService = inject(LegalDisclaimerService);
  currentLegalDisclaimerCompany =
    this.legalDisclaimerService.getCurrentLegalDisclaimerCompany();
  private route = inject(ActivatedRoute);

  constructor() {
    const legalDisclaimerCompanyId =
      this.route.snapshot.paramMap.get("legal-disclaimer-company-id") ?? "";
    this.legalDisclaimerService.fetchLegalDisclaimerCompany(
      legalDisclaimerCompanyId
    );
  }

  onFormSubmit(newData) {
    this.legalDisclaimerService.updateLegalDisclaimerCompany(newData);
  }

  toggleDeletionWarning(showWarning: boolean) {
    this.showDeletionWarning.set(showWarning);
  }

  deleteLegalDisclaimer(_id) {
    this.legalDisclaimerService.deleteLegalDisclaimerCompany(_id);
  }
}
