import { DecimalPipe } from "@angular/common";
import { Component, effect, input, OnInit } from "@angular/core";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-shareholders-barchart",
  standalone: true,
  imports: [DecimalPipe],
  templateUrl: "./shareholders-barchart.component.html",
  styleUrl: "./shareholders-barchart.component.scss",
})
export class ShareholdersBarchartComponent implements OnInit {
  companyData = input.required<any>();
  freefloat: number;
  freefloatWidth = 0;

  shareholders: any;
  barValues = [];
  barMax = 100;
  barMin = 0;
  constructor() {
    effect(() => {
      this.freefloat = 100 * this.companyData()?.general_information.free_float;
      this.parseShareHolders();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.freefloat = 100 * this.companyData()?.general_information.free_float;
    this.parseShareHolders();
  }

  parseShareHolders() {
    this.shareholders = [];
    this.barValues = [];
    // SHAREHOLDERS
    if (this.companyData()?.general_information.shareh_name_A !== "#EMPTY") {
      this.shareholders.push({
        name: this.companyData()?.general_information.shareh_name_A,
        value: 100 * this.companyData()?.general_information.shareh_perc_A,
      });
      this.barValues.push(
        100 * this.companyData()?.general_information.shareh_perc_A
      );
    }
    if (this.companyData()?.general_information.shareh_name_B !== "#EMPTY") {
      this.shareholders.push({
        name: this.companyData()?.general_information.shareh_name_B,
        value: 100 * this.companyData()?.general_information.shareh_perc_B,
      });
      this.barValues.push(
        100 * this.companyData()?.general_information.shareh_perc_B
      );
    }
    if (this.companyData()?.general_information.shareh_name_C !== "#EMPTY") {
      this.shareholders.push({
        name: this.companyData()?.general_information.shareh_name_C,
        value: 100 * this.companyData()?.general_information.shareh_perc_C,
      });
      this.barValues.push(
        100 * this.companyData()?.general_information.shareh_perc_C
      );
    }
    if (this.companyData()?.general_information.shareh_name_D !== "#EMPTY") {
      this.shareholders.push({
        name: this.companyData()?.general_information.shareh_name_D,
        value: 100 * this.companyData()?.general_information.shareh_perc_D,
      });
      this.barValues.push(
        100 * this.companyData()?.general_information.shareh_perc_D
      );
    }
    if (this.companyData()?.general_information.shareh_name_E !== "#EMPTY") {
      this.shareholders.push({
        name: this.companyData()?.general_information.shareh_name_E,
        value: 100 * this.companyData()?.general_information.shareh_perc_E,
      });
      this.barValues.push(
        100 * this.companyData()?.general_information.shareh_perc_E
      );
    }
    if (this.companyData()?.general_information.shareh_name_F !== "#EMPTY") {
      this.shareholders.push({
        name: this.companyData()?.general_information.shareh_name_F,
        value: 100 * this.companyData()?.general_information.shareh_perc_F,
      });
      this.barValues.push(
        100 * this.companyData()?.general_information.shareh_perc_F
      );
    }
    this.shareholders = this.shareholders.splice(0, 2);
    this.barValues = this.barValues.splice(0, 2);
    // get freefloat
    this.freefloat = 100 * this.companyData()?.general_information.free_float; // in perc
    this.barValues.push(
      100 * this.companyData()?.general_information.free_float
    );
    // get greates value and set it to max width
    this.shareholders.map(
      (holder) =>
        (holder.width =
          ((holder.value - this.barMin) / (this.barMax - this.barMin)) * 100)
    );
    this.freefloatWidth =
      ((this.freefloat - this.barMin) / (this.barMax - this.barMin)) * 100;
  }
}
