<div class="lg:mt-8 pt-6 lg:pt-8 max-w-screen-xl mx-auto bg-blue-100">
  <div class="lg:mt-8 p-8">
    <div class="text-center mb-4">
      <h3 class="text-lg lg:text-xl">Corporate Dashboard</h3>
      <hr />
    </div>

    <div class="flex flex-wrap mx-3 lg:ml-6">
      <div class="grid grid-cols-1 lg:grid-cols-4 w-full gap-4 my-5">
        <div class="col-span-2">
          <div class="grid grid-cols-1 lg:grid-rows-3">
            <h2 class="row-span-1 text-lg">{{ currentUsersCompanyName() }}</h2>
            <div class="row-span-1"></div>
            <div class="row-span-1">
              <p>
                Feel free to invite your investors to each event below by
                sending them the link to the event registration or posting it on
                your Website.
              </p>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div class="bg-gray-alster rounded-lg shadow-md w-8/12 mx-auto">
            <div class="flex flex-col">
              <div class="text-md font-bold text-white px-4 py-2">
                mwb research Contact
              </div>
              <div class="flex-grow px-4 py-6">
                <div class="text-white mb-3">
                  <div class="mt-1">{{ alsterContact.name }}</div>
                  <div class="mt-1">{{ alsterContact.email }}</div>
                  <div class="mt-1">Tel: {{ alsterContact.phone }}</div>
                </div>
                <div class="mt-4">
                  <svg
                    class="h-10"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="2.3 2.3 212.6 39.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="M36.6 2.3v16.8H19.8V2.3h16.8z"
                        fill="#ffd746"
                      ></path>
                      <path
                        d="M32.3 33.1v8.3H24v-8.3h8.3z"
                        fill="#17548a"
                      ></path>
                      <path
                        d="M15 17.7v12.7H2.3V17.7H15z"
                        fill="#84abdb"
                      ></path>
                      <text
                        fill="white"
                        font-size="20"
                        font-weight="bold"
                        x="48"
                        y="30"
                      >
                        mwb research
                      </text>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full mt-5">
        <app-tabs
          [activeTabClass]="'bg-gray-alster text-white'"
          [contentClasses]="
            'bg-white border-2 border-gray-alster rounded-[20px] bg-blue-100'
          "
          [inactiveTabClass]="'bg-white'"
          [navClasses]="'flex w-full px-6'"
          [tabClasses]="
            'font-semibold rounded-[20px] lg:rounded-none lg:rounded-t-[20px] px-4 py-2 text-gray-700 border-2 lg:border-b-0 border-gray-alster hover:border-0 focus:outline-none cursor-pointer text-xs lg:text-md text-center'
          "
          [tabRowClasses]="'rounded-t-lg flex items-center'"
        >
          <app-tab title="Events">
            <div class="event-list-border p-4 rounded-[20px]">
              <div>
                @if (!loadingNewData() && nextEvent()) {
                  <h4>Next Event</h4>
                }
                @if (!loadingNewData() && !nextEvent()) {
                  <h4>No Upcoming Events</h4>
                }
              </div>

              @if (!loadingNewData() && nextEvent()) {
                <div>
                  <p>
                    There are no events planned yet. If you are interested in
                    planning an event with us, please contact Holger Nass (<a
                      href="mailto:h.nass@mwb-research.com"
                      >h.nass&#64;mwb-research.com</a
                    >, +49 160 - 9798 3302).
                  </p>
                </div>
              }
              <app-corporate-event-grid
                [events]="nextEvent"
                [listType]="'nextEvent'"
                class="w-full"
              ></app-corporate-event-grid>

              @if (upcomingEvents()?.length > 0) {
                <div class="w-full">
                  <hr />
                </div>
                <div class="event-list-border">
                  <div>
                    <h4 class="py-4">More Upcoming Events</h4>
                  </div>
                  <app-corporate-event-grid
                    class="w-full"
                    [listType]="'upcomingEvents'"
                    [events]="upcomingEvents"
                  ></app-corporate-event-grid>
                </div>
              }

              @if (pastEvents()?.length > 0) {
                <div class="w-full">
                  <hr />
                </div>
                <div class="event-list-border">
                  <div>
                    <h4 class="py-4">Past Events</h4>
                  </div>
                  <app-corporate-event-grid
                    class="w-full"
                    [listType]="'pastEvents'"
                    [events]="pastEvents"
                  ></app-corporate-event-grid>
                </div>
              }
            </div>
          </app-tab>

          <app-tab title="Research">
            <div class="event-list-border p-4 rounded-[20px]">
              <div>
                <h4>Research</h4>
                <hr />
              </div>

              @if (companyIsCovered() && researchOverview().length > 0) {
                <app-research-accordion
                  [researchOverviewList]="researchOverview"
                  [corporateMode]="true"
                  [corporateTicker]="this.currentUserCompanyticker()"
                ></app-research-accordion>
              }
              @if (!companyIsCovered()) {
                <div>
                  <p>
                    This is the place to present all publications by mwb
                    research on your company. If you are interested in being
                    covered by us, please contact Holger Nass (<a
                      href="mailto:h.nass@mwb-research.com"
                      >h.nass&#64;mwb-research.com</a
                    >, +49 160 - 9798 3302)
                  </p>
                </div>
              }
            </div>
          </app-tab>

          <app-tab title="Uploads">
            <div class="event-list-border p-4 rounded-[20px]">
              <div>
                <h4>Uploads</h4>
                <hr />
              </div>
              <div>
                @if (companyIsCovered() && currentCompanyPresentation) {
                  <h5 class="mt-3 text-center">
                    This is the
                    <a href="{{ currentCompanyPresentation }}" target="_blank"
                      >current company presentation</a
                    >
                    on the research hub.
                  </h5>
                }
                @if (companyIsCovered) {
                  <h5 class="text-center">
                    If you want to upload a more current version, please use
                    this upload function below.
                  </h5>
                }
                @if (!companyIsCovered) {
                  <h5 class="text-center">
                    If you want to upload a company presentation, please use
                    this upload function below.
                  </h5>
                }
                <hr />
                <app-drive-uploader></app-drive-uploader>
              </div>
            </div>
          </app-tab>
        </app-tabs>
      </div>
    </div>
  </div>
</div>
