import { Injectable, signal } from "@angular/core";

import { BreadcrumbItem } from "~global-interfaces/IBreadcrumbItem";

@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  breadcrumbs = signal<BreadcrumbItem[]>([]);

  setBreadcrumbs(items: BreadcrumbItem[]) {
    this.breadcrumbs.set(items);
  }
}
