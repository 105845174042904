import {
  computed,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { IInvestmentChart } from "~global-interfaces/ICompany";

import { CompanydataService } from "~features/company/companydata.service";

@Injectable({
  providedIn: "root",
})
export class TileSettingsService {
  public researchTileSettings: WritableSignal<any> = signal({
    height: 1,
    width: 1,
    tiles: [
      {
        headline: "Interim Figures",
        type: "table-change",
        content: [
          "sales_q",
          "ebitda_q",
          "ebitda_margin_q",
          "ebit_q",
          "ebit_margin_q",
          "eps_q",
        ],
        height: 2,
        width: 1,
        mobile: true,
      },
    ],
  });

  public keyfiguresTileSettings: WritableSignal<any> = signal({
    height: 2,
    width: 1,
    tiles: [
      {
        headline: "Key Figures",
        type: "figure-slider",
        content: [
          "sales",
          "growth_sales",
          "ebitda",
          "margin_ebitda",
          "ebit",
          "margin_ebit",
          "eps",
          "dps",
        ],
        height: 1,
        width: 1,
        link: "below",
        mobile: true,
      },
      {
        headline: "",
        type: "figure-graph",
        content: [],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  });
  public swotTileSettings: WritableSignal<any> = signal({
    height: 1,
    width: 1,
    tiles: [
      {
        headline: "ROCE",
        type: "figure",
        content: ["roce"],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  });
  public valuationTileSettings: WritableSignal<any> = signal({
    height: 2,
    width: 1,
    tiles: [
      {
        headline: "DCF",
        type: "figure-slider",
        content: [
          "dcf_dcfps",
          "dcf_cost_of_debt",
          "dcf_term_val_wacc",
          "dcf_share_c",
        ],
        height: 1,
        width: 1,
        mobile: true,
      },
      {
        headline: "Multiples",
        type: "vertical-bars",
        content: ["ev_ebitda", "ev_ebit", "pe"],
        height: 1,
        width: 1,
        mobile: true,
      },
    ],
  });

  DEFAULT_INVESTMENTCASE_CHARTS: IInvestmentChart[] = [
    {
      currentTemplate: "segment sales",
      position: "left",
    },
    {
      currentTemplate: "regional sales",
      position: "left",
    },
    {
      currentTemplate: "major shareholders",
      position: "left",
    },

    {
      currentTemplate: "sales and earnings momentum",
      position: "right",
    },
    {
      currentTemplate: "ROCEvsWACC",
      position: "right",
    },
    {
      currentTemplate: "free cash flow development",
      position: "right",
    },
  ];

  private companydataService = inject(CompanydataService);
  public investmentCaseChartSettings = computed(() => {
    const companySettings =
      this.companydataService.getCurrentCompanySettings()();
    const charts: IInvestmentChart[] =
      companySettings.charts || this.DEFAULT_INVESTMENTCASE_CHARTS;

    const leftCharts = charts
      .filter((chart) => chart.position === "left")
      .map((chart) => chart.currentTemplate);
    const rightCharts = charts
      .filter((chart) => chart.position === "right")
      .map((chart) => chart.currentTemplate);

    return {
      height: 1,
      width: 2,
      tiles: [
        {
          headline: "Investment Case in Charts",
          type: "template-chart",
          content: leftCharts,
          height: 2,
          width: 1,
          mobile: false,
        },
        {
          headline: "Investment Case in Charts",
          type: "template-chart",
          content: rightCharts,
          height: 2,
          width: 1,
          mobile: false,
        },
      ],
    };
  });

  constructor() {}
}
