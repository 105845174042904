@if (showReminder()) {
  <div
    class="fixed left-1/2 transform -translate-x-1/2 top-[85%] bg-blue-500 text-white rounded-lg shadow-lg p-6 max-w-md z-50"
  >
    <p class="mb-4">
      Keep your profile up to date to ensure you get the best experience
      tailored to your preferences!
    </p>
    <a
      [routerLink]="['/user/dashboard']"
      [queryParams]="{ tab: 5 }"
      class="bg-white text-blue-500 font-semibold py-2 px-4 text-xs rounded hover:bg-gray-200"
    >
      Update Profile
    </a>
    <button
      (click)="hideReminder()"
      class="absolute top-2 right-2 text-white font-bold"
    >
      X
    </button>
  </div>
}
