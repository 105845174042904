import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, inject } from "@angular/core";

import { SubscribeService } from "~features/newsletter-subscription/subscribe.service";
import { SafePipe } from "~pipes/safe.pipe";

@Component({
  selector: "app-newsletter-subscribe-dialog",
  standalone: true,
  imports: [SafePipe],
  templateUrl: "./newsletter-subscribe-dialog.component.html",
  styleUrl: "./newsletter-subscribe-dialog.component.scss",
})
export class NewsletterSubscribeDialogComponent {
  subscribeService = inject(SubscribeService);

  formURL = this.subscribeService.getFormURL();

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any,
  ) {}
}
