import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map, tap } from "rxjs/operators";

import { alsterAPI_URL, leewayAPI_URL } from "~api/api-urls";
import { UtilitiesService } from "~features/msoffice-connection/utilities.service";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Injectable({
  providedIn: "root",
})
export class DataServiceService {
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiToken = "cqt1bii3tq5fuzo0c5id";
  private mapping;
  private isLoading: WritableSignal<boolean> = signal(false);

  constructor(
    private httpClient: HttpClient,
    private utilitiesService: UtilitiesService
  ) {}

  public getHistoricalQuotes(symbolExchange: string) {
    const url =
      leewayAPI_URL +
      "public/historicalquotes/" +
      symbolExchange +
      "?apitoken=cqt1bii3tq5fuzo0c5id";

    return this.httpClient.get(url);
  }

  public getFinancialsNew(companyId: string) {
    const url =
      leewayAPI_URL +
      "addin/companydata/getcombinedexceldata?apitoken=" +
      this.apiToken +
      "&companyId=" +
      companyId +
      "&customerId=" +
      this.customerId +
      "&published=true";

    return this.httpClient.get(url);
  }

  public getLiveQuote(symbolexchange: string, multi: string) {
    let multiParam = "";
    if (multi) {
      multiParam = "&multi=" + multi;
    }

    symbolexchange = this.utilitiesService.getMappedExchange(symbolexchange);

    const url =
      leewayAPI_URL +
      "public/live/" +
      symbolexchange +
      "?apitoken=" +
      this.apiToken +
      multiParam;

    return this.httpClient.get(url);
  }

  public getTiledata(companyId: string, multi) {
    let multiParam = "";
    if (multi) {
      multiParam = "&multi=" + multi;
    }

    const url =
      leewayAPI_URL +
      "addin/companydata/tiledata?apitoken=" +
      this.apiToken +
      "&companyId=" +
      companyId +
      "&customerId=" +
      this.customerId +
      multiParam;

    return this.httpClient.get(url);
  }

  public getMapping() {
    if (!this.mapping) {
      const customerIdparam = "customerId=" + this.customerId;
      const apiTokenparam = "apitoken=" + this.apiToken;

      const url =
        leewayAPI_URL +
        "addin/mapping/mapping?" +
        apiTokenparam +
        "&" +
        customerIdparam;
      const $mapping = this.httpClient.get(url).toPromise();
      $mapping.then((mapping) => (this.mapping = mapping));
      return $mapping;
    } else {
      //   return this.mapping as Promise<Array<any>>;
      return new Promise((resolve, reject) => {
        resolve(this.mapping);
      });
    }
  }

  public addPDFCounter(pdfURL) {
    const body = { ref: pdfURL };
    this.httpClient
      .post(alsterAPI_URL + "pdfcounter/updatepdfcount", body)
      .toPromise()
      .then((res) => {});
  }

  public getTableTemplate(name: string) {
    const url = alsterAPI_URL + "tabletemplates/" + encodeURIComponent(name);
    // TODO: check subscribe function to fill tap

    return this.httpClient.get(url).pipe(
      map((res: any) => res.data[0]),

      catchError((error) =>
        this.errorHandlingService.handleError(error, "get table template")
      ),
      finalize(() => {
        this.isLoading.set(false);
      })
    );
  }

  public getChartingTemplate(name: string) {
    const url = alsterAPI_URL + "chartingtemplates/" + encodeURIComponent(name);
    // TODO: check subscribe function to fill tap
    return this.httpClient.get(url).pipe(
      map((res: any) => res.data[0]),

      catchError((error) =>
        this.errorHandlingService.handleError(error, "get charting template")
      ),
      finalize(() => {
        this.isLoading.set(false);
      })
    );
  }

  //@update flag to show this updates existing template
  //if template name exist and no update flag return error name exists
  public putTableTemplate(update: boolean, template) {
    const apiTokenparam = "apitoken=" + this.apiToken;

    const url =
      alsterAPI_URL + "tabletemplates/update" + "?" + "update=" + update;

    return this.httpClient.post(url, template);
  }

  public putChartingTemplate(update: boolean, template) {
    const apiTokenparam = "apitoken=" + this.apiToken;

    const url =
      alsterAPI_URL + "chartingtemplates/update" + "?" + "update=" + update;

    return this.httpClient.post(url, template);
  }

  public getScreen(screen) {
    const apiTokenparam = "apitoken=" + this.apiToken;

    const url =
      leewayAPI_URL +
      "addin/companydata/screener/" +
      this.customerId +
      "?" +
      apiTokenparam;

    return this.httpClient.post(url, screen);
  }

  public getCompanyCountries() {
    const apiTokenparam = "apitoken=" + this.apiToken;

    const url =
      leewayAPI_URL +
      "addin/companydata/countries/" +
      this.customerId +
      "?" +
      apiTokenparam;

    return this.httpClient.get(url);
  }

  public getCompanySectors() {
    const apiTokenparam = "apitoken=" + this.apiToken;

    const url =
      leewayAPI_URL +
      "addin/companydata/sectors/" +
      this.customerId +
      "?" +
      apiTokenparam;

    return this.httpClient.get(url);
  }

  public getLatestPDfs() {
    const apiTokenparam = "apitoken=" + this.apiToken;
    const url = alsterAPI_URL + "latestresarch/latestresearch";

    return this.httpClient.get(url);
  }
}
