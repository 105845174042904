import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";

import { AuthService } from "./auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add default auth for alster-api
    let headers = new HttpHeaders({
      authorization: "41bdbE1uTZADpagZWfK1",
    });

    // add authorization header with jwt token if available
    const authService = this.injector.get(AuthService);
    const currentToken = authService.currentToken();
    if (currentToken) {
      headers = headers.set("x-auth-token", currentToken);
    }

    // fake referer for requests containing "leeway" in the URL
    if (request.url.includes("leeway")) {
      // headers = headers.set("Referer", "localhost"); the browser refuses to set this
    }

    // clone the request with the updated headers
    request = request.clone({ headers });

    return next.handle(request);
  }
}
