<div class="container mx-auto">
  <div class="flex flex-wrap">
    <div class="w-full">
      <form
        [formGroup]="swotForm"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div class="mb-4">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="companyTicker"
          >
            Company Ticker:
          </label>
          <input
            [hasSideeffect]="true"
            [inputName]="'swot_companyTicker'"
            [ngClass]="{
              'border-red-500':
                swotForm.get('companyTicker')?.invalid &&
                swotForm.get('companyTicker')?.touched
            }"
            appAdminInfoHasSideeffect
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="companyTicker"
            required
            type="text"
          />
          @if (
            swotForm.get("companyTicker")?.invalid &&
            swotForm.get("companyTicker")?.touched
          ) {
            <p class="text-red-500 text-xs italic">
              {{ getErrorMessage("companyTicker") }}
            </p>
          }
        </div>
        <!--        <div class="mb-4">-->
        <!--          <label-->
        <!--            class="block text-gray-700 text-sm font-bold mb-2"-->
        <!--            for="language"-->
        <!--          >-->
        <!--            Language:-->
        <!--          </label>-->
        <!--          <input-->
        <!--            [ngClass]="{-->
        <!--              'border-red-500':-->
        <!--                swotForm.get('language')?.invalid &&-->
        <!--                swotForm.get('language')?.touched-->
        <!--            }"-->
        <!--            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"-->
        <!--            formControlName="language"-->
        <!--            required-->
        <!--            type="text"-->
        <!--          />-->
        <!--          @if (-->
        <!--            swotForm.get("language")?.invalid &&-->
        <!--            swotForm.get("language")?.touched-->
        <!--          ) {-->
        <!--            <p class="text-red-500 text-xs italic">-->
        <!--              {{ getErrorMessage("language") }}-->
        <!--            </p>-->
        <!--          }-->
        <!--        </div>-->
        <!--        <div class="mb-6">-->
        <!--          <label-->
        <!--            class="block text-gray-700 text-sm font-bold mb-2"-->
        <!--            for="is_public"-->
        <!--          >-->
        <!--            Is Public:-->
        <!--          </label>-->
        <!--          <input-->
        <!--            class="form-checkbox h-5 w-5 text-blue-600"-->
        <!--            formControlName="is_public"-->
        <!--            type="checkbox"-->
        <!--          />-->
        <!--        </div>-->
        <div class="mb-4" formArrayName="strengths">
          <label class="block text-gray-700 text-sm font-bold mb-2"
            >Strengths:</label
          >
          @for (
            strength of strengths.controls;
            track strength;
            let i = $index
          ) {
            <div class="mb-2">
              <div class="flex">
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  [ngClass]="{
                    'border-red-500': strength.invalid && strength.touched
                  }"
                  [formControlName]="i"
                  required
                />
                <button
                  type="button"
                  class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  (click)="removeStrength(i)"
                >
                  Remove
                </button>
              </div>
              @if (strength.invalid && strength.touched) {
                <p class="text-red-500 text-xs italic">
                  {{ getErrorMessage("strength") }}
                </p>
              }
            </div>
          }
          <button
            (click)="addStrength()"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            Add another Strength
          </button>
        </div>
        <div class="mb-4" formArrayName="weaknesses">
          <label class="block text-gray-700 text-sm font-bold mb-2"
            >Weaknesses:</label
          >
          @for (
            weakness of weaknesses.controls;
            track weakness;
            let i = $index
          ) {
            <div class="mb-2">
              <div class="flex">
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  [ngClass]="{
                    'border-red-500': weakness.invalid && weakness.touched
                  }"
                  [formControlName]="i"
                  required
                />
                <button
                  type="button"
                  class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  (click)="removeWeakness(i)"
                >
                  Remove
                </button>
              </div>
              @if (weakness.invalid && weakness.touched) {
                <p class="text-red-500 text-xs italic">
                  {{ getErrorMessage("weakness") }}
                </p>
              }
            </div>
          }
          <button
            (click)="addWeakness()"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            Add another Weakness
          </button>
        </div>
        <div class="mb-4" formArrayName="opportunities">
          <label class="block text-gray-700 text-sm font-bold mb-2"
            >Opportunities:</label
          >
          @for (
            opportunity of opportunities.controls;
            track opportunity;
            let i = $index
          ) {
            <div class="mb-2">
              <div class="flex">
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  [ngClass]="{
                    'border-red-500': opportunity.invalid && opportunity.touched
                  }"
                  [formControlName]="i"
                  required
                />
                <button
                  type="button"
                  class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  (click)="removeOpportunity(i)"
                >
                  Remove
                </button>
              </div>
              @if (opportunity.invalid && opportunity.touched) {
                <p class="text-red-500 text-xs italic">
                  {{ getErrorMessage("opportunity") }}
                </p>
              }
            </div>
          }
          <button
            (click)="addOpportunity()"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            Add another Opportunity
          </button>
        </div>
        <div class="mb-4" formArrayName="threats">
          <label class="block text-gray-700 text-sm font-bold mb-2"
            >Threats:</label
          >
          @for (threat of threats.controls; track threat; let i = $index) {
            <div class="mb-2">
              <div class="flex">
                <input
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  [ngClass]="{
                    'border-red-500': threat.invalid && threat.touched
                  }"
                  [formControlName]="i"
                  required
                />
                <button
                  type="button"
                  class="ml-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  (click)="removeThreat(i)"
                >
                  Remove
                </button>
              </div>
              @if (threat.invalid && threat.touched) {
                <p class="text-red-500 text-xs italic">
                  {{ getErrorMessage("threat") }}
                </p>
              }
            </div>
          }
          <button
            (click)="addThreat()"
            class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            type="button"
          >
            Add another Threat
          </button>
        </div>
        @if (!multiMode) {
          <div class="flex items-center justify-between">
            <app-admin-form-button
              (click)="saveForm()"
              [class]="'admin-form-button'"
              [disabled]="!swotForm.valid"
            >
            </app-admin-form-button>
          </div>
          <app-admin-form-request-message></app-admin-form-request-message>
        }
      </form>
    </div>
  </div>
</div>
