import { Injectable, signal, WritableSignal } from "@angular/core";

interface Notification {
  id: string;
  message: string;
}

@Injectable({
  providedIn: "root",
})
export class UserNotificationService {
  notifications: WritableSignal<Notification[]> = signal([]);

  updateNotification(message: string) {
    const id = Math.random().toString(36).substr(2, 9);
    const newNotification: Notification = { id, message };
    const currentNotifications = [...this.notifications()];
    currentNotifications.push(newNotification);
    if (currentNotifications.length > 5) {
      currentNotifications.shift();
    }
    this.notifications.set(currentNotifications);

    setTimeout(() => {
      this.removeNotification(id);
    }, 5000);
  }

  private removeNotification(id: string) {
    const currentNotifications = this.notifications().filter(
      (notification) => notification.id !== id
    );
    this.notifications.set(currentNotifications);
  }
}
