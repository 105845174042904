import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { IResearch } from "~global-interfaces/IResearch";
import { IResearchOverviewItem } from "~global-interfaces/IResearchOverviewItem";

import { alsterAPI_URL } from "~api/api-urls";
import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { INITIAL_RESEARCH } from "~shared/initial-values";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class ResearchService extends PaginationService<any> {
  isLoading: WritableSignal<boolean> = signal(false);

  private companyTickerService = inject(CompanyTickerService);
  private companyResearchService = inject(CompanyResearchService);
  private researchArticle: WritableSignal<IResearch> = signal(INITIAL_RESEARCH);
  private researchOverViewList: WritableSignal<IResearchOverviewItem[] | null> =
    signal(null);

  constructor(http: HttpClient) {
    super(http);
  }

  getisLoading() {
    return this.isLoading;
  }

  updateResearch(formData) {
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<IResearch>>(
        alsterAPI_URL + "research/update/" + formData._id,
        formData
      )
      .pipe(
        map((response) => response.data[0]),
        catchError((error) =>
          this.errorHandlingService.handleError(error, "update research")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (researchData) => {
          this.companyResearchService.setResearchArticler(researchData);
          this.adminRequestService.setLastRequestMessage("1", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  createResearch(formData) {
    // console.log("SENDING RESEARCH CREATION");
    this.isLoading.set(true);
    this.http
      .post<IApiResponse<IResearch>>(
        alsterAPI_URL + "research/create/",
        formData
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "create research")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (researchData) => {
          this.companyResearchService.setResearchArticler(researchData);
          this.adminRequestService.setLastRequestMessage("26", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public fetchAllResearchOverview(paginationParams: IQueryParams) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL + "research",
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }
}
