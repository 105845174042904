import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";
import { IEditorContent } from "~global-interfaces/IEditorContent";

import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { parseDateOrFillWithToday } from "~utils/form.utils";

@Component({
  selector: "app-company-investmentcase-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EditEditorContentComponent,
    JsonPipe,
    NgClass,
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./company-investmentcase-form.component.html",
  styleUrl: "./company-investmentcase-form.component.scss",
})
export class CompanyInvestmentcaseFormComponent implements OnInit {
  @Input() currentCompanyTicker: string;
  @Input() investmentCase: Signal<IEditorContent | null>;
  formBuilder = inject(FormBuilder);
  investmentCaseForm: FormGroup;
  @Output() formSubmit = new EventEmitter<any>();
  adminRequestService = inject(AdminRequestService);
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  @Output() formValidity = new EventEmitter<boolean>();
  constructor() {
    this.adminRequestService.emptyLastRequest();
    effect(() => {
      if (this.multiModeCompanyTicker()) {
        this.investmentCaseForm
          .get("companyTicker")
          .setValue(this.multiModeCompanyTicker());
      }
    });
    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit(): void {
    console.log(this.investmentCase());
    this.initializeForm();
    this.formValidity.emit(this.investmentCaseForm.valid);
    this.formValueChange.emit(this.investmentCaseForm.value);
    // Subscribe to form changes
    this.investmentCaseForm.valueChanges.subscribe((values) => {
      this.formValueChange.emit(values);
    });

    // Subscribe to form validity changes
    this.investmentCaseForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  initializeForm() {
    this.investmentCaseForm = this.formBuilder.group({
      language: ["de", [Validators.required, Validators.pattern(/^[a-z]{2}$/)]],
      content: ["", Validators.required],
      created_date: ["", Validators.required],
      type: ["InvestmentCase", Validators.required],
      companyTicker: ["", [Validators.required]],
      is_public: [true],
    });
  }

  patchFormValues() {
    if (this.investmentCase()) {
      this.investmentCaseForm.patchValue({
        language: this.investmentCase().language || "de",
        created_date: parseDateOrFillWithToday(
          this.investmentCase().created_date
        ),
        type: this.investmentCase().type || "InvestmentCase",
        companyTicker: this.investmentCase().companyTicker,
        is_public: this.investmentCase().is_public,
        content: this.investmentCase().content,
      });
    } else {
      this.investmentCaseForm.patchValue({
        created_date: parseDateOrFillWithToday(""),
        type: "InvestmentCase",
        language: "de",
        companyTicker: this.currentCompanyTicker,
        is_public: false,
        content: "",
      });
    }
    this.investmentCaseForm.get("type").disable();
  }

  onEditorContentSave(content: string) {
    this.investmentCaseForm.get("content").setValue(content);
  }

  saveForm() {
    if (this.investmentCaseForm.valid) {
      const updatedInvestmentCase: IEditorContent = {
        ...this.investmentCase(),
        ...this.investmentCaseForm.value,
      };
      this.formSubmit.emit(updatedInvestmentCase);
    } else {
      this.markFormGroupTouched(this.investmentCaseForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.investmentCaseForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
