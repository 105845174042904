@if (!listMode) {
  <div
    class="flex flex-wrap md:flex-row items-center justify-end h-full md:h-24"
  >
    <div
      class="flex flex-col items-center justify-center bg-[#fbe4d4] w-20 h-24 md:h-full"
    >
      <h5 class="uppercase m-0 font-medium text-baseplus leading-[1.2]">
        {{ archiveEvent.date | date: "MMM" }}
      </h5>
      <h3 class="m-0">
        {{ archiveEvent.date | date: "dd" }}
      </h3>
      <h5 class="m-0 font-medium text-baseplus leading-[1.2]">
        {{ archiveEvent.date | date: "yyyy" }}
      </h5>
    </div>
    <div class="flex flex-1 flex-col md:flex-row items-center ml-4">
      <div class="flex flex-1 items-center">
        <h4>
          {{ archiveEvent.title }}
        </h4>
        @if (archiveEvent.videoURL) {
          <a href="{{ archiveEvent.videoURL }}" target="_blank">
            <app-youtube-icon
              class="md:hidden"
              color="red"
              size="3rem"
            ></app-youtube-icon>
          </a>
        }
      </div>
      <div class="hidden md:flex flex-1 items-center h-24 w-24 md:p-8">
        <app-video-player
          class="max-w-40 w-full"
          [videoURL]="archiveEvent.videoURL ? archiveEvent.videoURL : null"
          [openInDialog]="true"
        >
        </app-video-player>
      </div>

      <div class="hidden md:flex flex-1 flex-col md:justify-center">
        @if (archiveEvent.isCovered) {
          <a
            class="text-blue-500 w-full uppercase tracking-widest font-medium text-link hover:underline"
            [routerLink]="['/companies/' + archiveEvent.companyName]"
            [fragment]="'X'"
          >
            read research <i class="fas fa-arrow-right ml-1"></i>
          </a>
        }
        @if (archiveEvent.presentationURL !== "") {
          <a
            class="text-blue-500 w-full uppercase tracking-widest text-link font-medium mt-2 hover:underline"
            target="_blank"
            [href]="archiveEvent.presentationURL"
          >
            download<br />
            presentation<i class="fas fa-arrow-right ml-1"></i>
          </a>
        }

        <button
          (click)="copyVideoURL(archiveEvent.companyTicker, archiveEvent.date)"
          class="text-blue-500 w-full uppercase tracking-widest text-link mt-2 hover:underline text-start"
        >
          share video<i class="fas fa-arrow-right ml-1"></i>
        </button>
      </div>
    </div>
    <div class="flex w-full space-x-8 mt-4 md:hidden">
      @if (archiveEvent.isCovered) {
        <div class="flex flex-1">
          <a
            class="flex flex-1 items-center text-blue-500 w-full uppercase tracking-widest font-medium text-link hover:underline"
            [routerLink]="['/companies/' + archiveEvent.companyName]"
            [fragment]="'X'"
          >
            read research
            <i class="fas fa-arrow-right ml-1"></i>
          </a>
        </div>
      }
      @if (archiveEvent.presentationURL !== "") {
        <div class="flex flex-1">
          <a
            class="flex flex-1 items-center text-blue-500 w-full uppercase tracking-widest text-link font-medium md:mt-2 hover:underline"
            target="_blank"
            [href]="archiveEvent.presentationURL"
          >
            download<br />
            presentation<i class="fas fa-arrow-right ml-1"></i>
          </a>
        </div>
      }
    </div>
  </div>
}
@if (listMode) {
  <div class="flex flex-wrap h-full lg:h-52 shadow-alster p-8">
    <div class="flex flex-col w-full lg:w-7/12">
      <div class="flex w-full">
        <app-date [date]="archiveEvent.date" [formatType]="'MMM dd, y, HH:mm'">
        </app-date>
      </div>
      <div class="flex w-full text-md font-bold pb-4">
        {{ archiveEvent.title }}
      </div>

      <div class="flex flex-col w-full">
        @if (archiveEvent.isCovered) {
          <a
            class="text-blue-500 w-full uppercase tracking-widest text-link hover:underline"
            [routerLink]="['/companies/' + archiveEvent.companyName]"
            [fragment]="'X'"
          >
            read research <i class="fas fa-arrow-right ml-1"></i>
          </a>
        }
        @if (archiveEvent.presentationURL !== "") {
          <a
            class="text-blue-500 w-full uppercase tracking-widest text-link mt-2 hover:underline"
            target="_blank"
            [href]="archiveEvent.presentationURL"
          >
            download presentation<i class="fas fa-arrow-right ml-1"></i>
          </a>
        }

        <button
          (click)="copyVideoURL(archiveEvent.companyTicker, archiveEvent.date)"
          class="text-blue-500 w-full uppercase tracking-widest text-link mt-2 hover:underline text-start"
        >
          share video<i class="fas fa-arrow-right ml-1"></i>
        </button>
      </div>
    </div>
    <div class="w-full md:w-5/12 h-full">
      <app-video-player
        class="lg:hidden"
        [videoURL]="archiveEvent.videoURL ? archiveEvent.videoURL : null"
        [openInDialog]="false"
        [redirectToURL]="true"
      >
      </app-video-player>
      <app-video-player
        class="hidden lg:flex"
        [videoURL]="archiveEvent.videoURL ? archiveEvent.videoURL : null"
        [openInDialog]="true"
        [redirectToURL]="false"
      >
      </app-video-player>
    </div>
    <div class="hidden lg:block w-full py-3 h-full">
      <hr />
    </div>
  </div>
}
