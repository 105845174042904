<div cla>
  <div class="flex justify-between items-center mb-4">
    <div class="text-lg uppercase tracking-widest text-white">
      Book a meeting
    </div>
    <div (click)="closeWindow()" class="text-right cursor-pointer">
      <div class="text-white">X</div>
    </div>
  </div>
  <div class="space-y-4">
    <div>
      <p class="mb-2 text-white">
        If you are interested in a meeting with
        {{ data.data.companySettings().name }} please complete the data.
      </p>

      <div class="mb-4">
        <input
          [(ngModel)]="firstname"
          class="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="First Name*"
          required
          type="text"
        />
        @if (requiredFieldMissingInput) {
          <label class="text-red-500">This field must be filled.</label>
        }
      </div>
      <div class="mb-4">
        <input
          [(ngModel)]="lastname"
          class="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Last Name*"
          required
          type="text"
        />
        @if (requiredFieldMissingInput) {
          <label class="text-red-500">This field must be filled.</label>
        }
      </div>
      <div class="mb-4">
        <input
          [(ngModel)]="email"
          class="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Email*"
          required
          type="text"
        />
        @if (requiredFieldMissingInput) {
          <label class="text-red-500">This field must be filled.</label>
        }
      </div>
      <div class="mb-4">
        <input
          [(ngModel)]="requesterscompany"
          class="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Your Company*"
          required
          type="text"
        />
        @if (requiredFieldMissingInput) {
          <label class="text-red-500">This field must be filled.</label>
        }
      </div>
      <div class="mb-4">
        <input
          [(ngModel)]="phonenumber"
          class="w-full px-3 py-2 border border-gray-300 rounded"
          placeholder="Phone"
          type="text"
        />
      </div>
      <div class="text-center">
        <button
          (click)="sendNotification()"
          [disabled]="requestPending"
          class="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Send a request
        </button>
      </div>
      @if (requestSent) {
        <div class="text-center mt-2">
          <label>
            Your request has been sent. We will get in touch with you as soon as
            possible.
          </label>
        </div>
      }
    </div>
  </div>
</div>
