import { Component, inject, WritableSignal } from "@angular/core";

import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Component({
  selector: "app-simple-loading-indicator",
  standalone: true,
  imports: [AdminFormRequestMessageComponent],
  templateUrl: "./simple-loading-indicator.component.html",
  styleUrl: "./simple-loading-indicator.component.scss",
})
export class SimpleLoadingIndicatorComponent {
  loadingIndicatorService = inject(LoadingIndicatorService);
  isLoading: WritableSignal<boolean> = this.loadingIndicatorService.isLoading;
  loadingURLs: WritableSignal<string[]> = this.loadingIndicatorService.requests;
}
