<form
  [formGroup]="eventForm"
  class="mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-6">
    <label class="block text-gray-700 text-sm font-secondary mb-2" for="title">
      Title
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('title')?.invalid && eventForm.get('title')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="title"
      type="text"
    />
    @if (eventForm.get("title")?.invalid && eventForm.get("title")?.touched) {
      <p class="text-red-500 text-xs mt-1">{{ getErrorMessage("title") }}</p>
    }
  </div>

  <div class="mb-6">
    <label class="block text-gray-700 text-sm font-secondary mb-2" for="date">
      Date and Time
    </label>
    <p class="text-sm text-gray-600 mb-2">
      {{ eventForm.get("date").value }}
    </p>
    <app-date-time-picker
      (selectedDateTimeChange)="onDateTimeChange($event)"
      [selectedDateTime]="eventForm.get('date').value"
    ></app-date-time-picker>
    @if (eventForm.get("date")?.invalid && eventForm.get("date")?.touched) {
      <p class="text-red-500 text-xs mt-1">{{ getErrorMessage("date") }}</p>
    }
  </div>

  <!--    <div class="mb-6">-->
  <!--      <label-->
  <!--        class="block text-gray-700 text-sm font-secondary mb-2"-->
  <!--        for="language"-->
  <!--      >-->
  <!--        Language-->
  <!--      </label>-->
  <!--      <input-->
  <!--        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 bg-gray-100 text-sm"-->
  <!--        [ngClass]="{-->
  <!--          'border-red-500':-->
  <!--            eventForm.get('language')?.invalid &&-->
  <!--            eventForm.get('language')?.touched-->
  <!--        }"-->
  <!--        formControlName="language"-->
  <!--        type="text"-->
  <!--      />-->
  <!--      @if (-->
  <!--        eventForm.get("language")?.invalid && eventForm.get("language")?.touched-->
  <!--      ) {-->
  <!--        <p class="text-red-500 text-xs mt-1">-->
  <!--          {{ getErrorMessage("language") }}-->
  <!--        </p>-->
  <!--      }-->
  <!--    </div>-->

  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
    <div>
      <label class="flex items-center">
        <input
          class="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"
          formControlName="is_public"
          type="checkbox"
        />
        <span class="ml-2 text-sm font-secondary text-gray-700">Is Public</span>
      </label>
    </div>
    <div>
      <label class="flex items-center">
        <input
          class="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"
          formControlName="showFeedback"
          type="checkbox"
        />
        <span class="ml-2 text-sm font-secondary text-gray-700"
          >Show Feedback</span
        >
      </label>
    </div>
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="companyName"
    >
      Company Name
    </label>
    <input
      [hasSideeffect]="true"
      [inputName]="'event_companyName'"
      [ngClass]="{
        'border-red-500':
          eventForm.get('companyName')?.invalid &&
          eventForm.get('companyName')?.touched
      }"
      appAdminInfoHasSideeffect
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="companyName"
      type="text"
    />
    @if (
      eventForm.get("companyName")?.invalid &&
      eventForm.get("companyName")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("companyName") }}
      </p>
    }
    <p class="text-xs text-gray-muted">
      If the company is not covered yet, make up a unique one. But it must match
      the Company Name given to the corporate user.
    </p>
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="companyTicker"
    >
      Company Ticker
    </label>
    <input
      [hasSideeffect]="true"
      [inputName]="'event_companyTicker'"
      [ngClass]="{
        'border-red-500':
          eventForm.get('companyTicker')?.invalid &&
          eventForm.get('companyTicker')?.touched
      }"
      appAdminInfoHasSideeffect
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="companyTicker"
      type="text"
    />
    @if (
      eventForm.get("companyTicker")?.invalid &&
      eventForm.get("companyTicker")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("companyTicker") }}
      </p>
    }
    <p class="text-xs text-gray-muted">
      If the company is not covered yet, make up a unique one. But it must match
      the Ticker given to the corporate user.
    </p>
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="videoURL"
    >
      Video URL
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('videoURL')?.invalid &&
          eventForm.get('videoURL')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="videoURL"
      type="url"
    />
    @if (
      eventForm.get("videoURL")?.invalid && eventForm.get("videoURL")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("videoURL") }}
      </p>
    }
  </div>
  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="companyLogoURL"
    >
      Company Logo URL
    </label>

    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('companyLogoURL')?.invalid &&
          eventForm.get('companyLogoURL')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="companyLogoURL"
      type="url"
    />
    @if (
      eventForm.get("companyLogoURL")?.invalid &&
      eventForm.get("companyLogoURL")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("companyLogoURL") }}
      </p>
    }
    <p class="text-xs text-gray-muted">
      This is only needed, if the company is not covered yet.
    </p>
  </div>
  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="sibListId"
    >
      SIB List ID
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('sibListId')?.invalid &&
          eventForm.get('sibListId')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="sibListId"
      type="text"
    />
    @if (
      eventForm.get("sibListId")?.invalid && eventForm.get("sibListId")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("sibListId") }}
      </p>
    }
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="presentationURL"
    >
      Presentation URL
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('presentationURL')?.invalid &&
          eventForm.get('presentationURL')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="presentationURL"
      type="url"
    />
    @if (
      eventForm.get("presentationURL")?.invalid &&
      eventForm.get("presentationURL")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("presentationURL") }}
      </p>
    }
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="feedbackURL"
    >
      Feedback URL
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('feedbackURL')?.invalid &&
          eventForm.get('feedbackURL')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="feedbackURL"
      type="url"
    />
    @if (
      eventForm.get("feedbackURL")?.invalid &&
      eventForm.get("feedbackURL")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("feedbackURL") }}
      </p>
    }
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="description"
    >
      Description
    </label>
    <textarea
      [ngClass]="{
        'border-red-500':
          eventForm.get('description')?.invalid &&
          eventForm.get('description')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="description"
      rows="4"
    ></textarea>
    @if (
      eventForm.get("description")?.invalid &&
      eventForm.get("description")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("description") }}
      </p>
    }
  </div>

  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="meetingLink"
    >
      Meeting Link
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          eventForm.get('meetingLink')?.invalid &&
          eventForm.get('meetingLink')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="meetingLink"
      type="url"
    />
    @if (
      eventForm.get("meetingLink")?.invalid &&
      eventForm.get("meetingLink")?.touched
    ) {
      <p class="text-red-500 text-xs mt-1">
        {{ getErrorMessage("meetingLink") }}
      </p>
    }
  </div>

  <div class="mb-6" formArrayName="speakers">
    <div class="border-t border-gray-200 pt-4">
      <h3 class="text-lg font-secondary text-gray-900 mb-4">Speakers:</h3>
      @for (speaker of speakers.controls; track speaker; let i = $index) {
        <div class="bg-gray-50 rounded-lg p-4 mb-4">
          <div class="flex items-center space-x-4 mb-4">
            <div class="flex-shrink-0">
              <img
                class="w-12 h-12 rounded-full object-cover"
                src="{{ speaker.get('imageURL').value }}"
                alt="{{ speaker.get('name').value }}"
              />
            </div>
            <div class="flex-1 min-w-0">
              <p class="text-sm font-medium text-gray-900 truncate">
                {{ speaker.get("name").value }}
              </p>
              <p class="text-sm text-gray-500 truncate">
                {{ speaker.get("position").value }}
              </p>
            </div>
            <div>
              <button
                type="button"
                class="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                (click)="removeSpeaker(i)"
              >
                Remove
              </button>
            </div>
          </div>

          <div [formGroupName]="i">
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-secondary mb-2"
                for="speakerName_{{ i }}"
                >Name:</label
              >
              <input
                class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                [ngClass]="{
                  'border-red-500':
                    speaker.get('name')?.invalid && speaker.get('name')?.touched
                }"
                id="speakerName_{{ i }}"
                type="text"
                formControlName="name"
                required
              />
              @if (
                speaker.get("name")?.invalid && speaker.get("name")?.touched
              ) {
                <p class="text-red-500 text-xs mt-1">
                  {{ getErrorMessage("name") }}
                </p>
              }
            </div>
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-secondary mb-2"
                for="speakerPosition_{{ i }}"
                >Position:</label
              >
              <input
                class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                [ngClass]="{
                  'border-red-500':
                    speaker.get('position')?.invalid &&
                    speaker.get('position')?.touched
                }"
                id="speakerPosition_{{ i }}"
                type="text"
                formControlName="position"
                required
              />
              @if (
                speaker.get("position")?.invalid &&
                speaker.get("position")?.touched
              ) {
                <p class="text-red-500 text-xs mt-1">
                  {{ getErrorMessage("position") }}
                </p>
              }
            </div>
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-secondary mb-2"
                for="speakerImageURL_{{ i }}"
                >Image URL:</label
              >
              <input
                class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                [ngClass]="{
                  'border-red-500':
                    speaker.get('imageURL')?.invalid &&
                    speaker.get('imageURL')?.touched
                }"
                id="speakerImageURL_{{ i }}"
                type="url"
                formControlName="imageURL"
                required
              />
              @if (
                speaker.get("imageURL")?.invalid &&
                speaker.get("imageURL")?.touched
              ) {
                <p class="text-red-500 text-xs mt-1">
                  {{ getErrorMessage("imageURL") }}
                </p>
              }
            </div>
          </div>
        </div>
      }
      <button
        (click)="addSpeaker()"
        class="mt-4 px-4 py-2 bg-green-500 hover:bg-green-600 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        type="button"
      >
        Add another Speaker
      </button>
    </div>
  </div>

  <div class="mt-8">
    <app-admin-form-button
      (click)="saveForm()"
      [class]="'admin-form-button'"
      [disabled]="!eventForm.valid"
    >
    </app-admin-form-button>
  </div>
  <app-admin-form-request-message></app-admin-form-request-message>
</form>
