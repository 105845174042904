import { Component, inject, OnInit } from "@angular/core";

import { AuthService } from "~features/auth/auth.service";
import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { CompanyGridHighlightComponent } from "~features/company-grid/company-grid-highlight/company-grid-highlight.component";
import { EventGridArchivedComponent } from "~features/events/event-grid-archived/event-grid-archived.component";
import { EventGridMixedComponent } from "~features/events/event-grid-mixed/event-grid-mixed.component";
import { EventGridUpcomingComponent } from "~features/events/event-grid-upcoming/event-grid-upcoming.component";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";

@Component({
  selector: "app-events-page",
  templateUrl: "./events-page.component.html",
  styleUrls: ["./events-page.component.scss"],
  standalone: true,
  imports: [
    EventGridMixedComponent,

    CompanyGridComponent,
    SubscribeBannerComponent,
    EventGridUpcomingComponent,
    EventGridArchivedComponent,
    CompanyGridHighlightComponent,
    MoreContentCtaComponent,
  ],
})
export class EventsPageComponent implements OnInit {
  adminMode = false;
  authService = inject(AuthService);

  ngOnInit(): void {
    this.adminMode = this.authService.isAdmin();
  }

  subscribeToEvents() {
    // const modalRef = this.modalService.open(NewsletterSubscribeComponent, {
    //   centered: true,
    //   size: "lg",
    // });
    // modalRef.componentInstance.emailMode = "subscribetoevent"; // delete this when new forms are used in production
    // modalRef.componentInstance.formMode = "events";
  }
}
