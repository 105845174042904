import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  Inject,
} from "@angular/core";

declare const TradingView: any;
@Component({
  selector: "app-shareprice-dialog",
  standalone: true,
  imports: [],
  templateUrl: "./shareprice-dialog.component.html",
  styleUrl: "./shareprice-dialog.component.scss",
})
export class SharepriceDialogComponent implements AfterViewInit {
  //@ViewChild("chartWidthMeasureElementMODAL") divToMeasureElement: ElementRef;
  chartSymbol = "";
  cdr = inject(ChangeDetectorRef);
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any
  ) {
    effect(() => {
      this.chartSymbol = this.data.data.mappedTVChartTicker;
      this.createChart();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.chartSymbol = this.data.data.mappedTVChartTicker;
      this.createChart();
    }, 500);
  }

  createChart() {
    //  const divToMeasureWidth =
    //   this.divToMeasureElement.nativeElement.offsetWidth;
    // const divToMeasureHeight =
    //   this.divToMeasureElement.nativeElement.offSetHeight;
    new TradingView.widget({
      //  width: divToMeasureWidth, /// 1.6,// / 0.8,
      //  height: divToMeasureHeight, // * 1.2, /// 1.5  / 1.60655737705, // this is the standart TV-Chart width/heith ratio
      //"autosize":true,
      symbol: this.chartSymbol,
      interval: "D",
      timezone: "Etc/UTC",
      theme: "light",
      style: "1",
      locale: "uk",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      save_image: false,
      container_id: "tradingview_bac65",
      allow_symbol_change: true,
    });
    this.cdr.detectChanges();
  }
  createExchangeAndCodeForTVChart(companyTIcker) {
    let exchange = "XETR";
    let code = companyTIcker.split(":")[0];
    const symbolMap = {
      "2GI": "MUN",
      R1B: "MUN",
      CDZ0: "MUN",
      "991": "MUN",
      NSP: "MIL",
      XCX: "DUS",
      P7V: "SWB",
      ABA: "FWB",
      THAG: "SIX",
      EM6: "MUN",
    };
    /**Hardcode fix for single stocks */
    if (code === "GSJ") {
      code = "SGF";
    }

    if (symbolMap.hasOwnProperty(code)) {
      exchange = symbolMap[code];
    } else {
      /*
      use default exchange value
      */
    }
    return exchange + ":" + code;
  }
}
