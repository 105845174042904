<div class="w-full flex justify-center">
  <div class="flex space-x-4 mx-auto">
    @for (jumpmark of navigationPoints; track jumpmark; let i = $index) {
      <div class="">
        <div
          [attr.id]="'jumpmarkID-' + jumpmark.jumpmark"
          class="uppercase text-xs cursor-pointer font-semibold leading-8 tracking-widest decoration-transparent underline-offset-[50px]"
          (click)="scrollIntoView(jumpmark.jumpmark)"
          [ngClass]="{
            'text-blue-500 border-b-2 border-blue-500': jumpmark.inView,
            'text-gray-muted  hover:border-b-2 hover:border-gray-300':
              !jumpmark.inView
          }"
        >
          {{ jumpmark.name }}
        </div>
      </div>
    }
  </div>
</div>
