import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";

import { ExcelService } from "~features/company/excel.service";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-company-leeway-connection-form",
  standalone: true,
  imports: [
    AdminFormRequestMessageComponent,
    AdminFormButtonComponent,
    ReactiveFormsModule,
  ],
  templateUrl: "./company-leeway-connection-form.component.html",
  styleUrl: "./company-leeway-connection-form.component.scss",
})
export class CompanyLeewayConnectionFormComponent implements OnInit {
  leewayConnectionForm: FormGroup<any>;
  formBuilder = inject(FormBuilder);
  excelService = inject(ExcelService);
  isLoading = this.excelService.getisLoading();
  @Output() formValidity = new EventEmitter<boolean>();

  @Input() currentCompanyTicker: string;
  @Output() formSubmit = new EventEmitter<any>();
  adminRequestService = inject(AdminRequestService);
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  @Input() multiModeCompanyName: Signal<string | null> = signal(null);
  constructor() {
    effect(() => {
      if (this.multiModeCompanyTicker()) {
        this.leewayConnectionForm
          .get("companyTicker")
          .setValue(this.multiModeCompanyTicker());
      }
    });
    effect(() => {
      if (this.multiModeCompanyName()) {
        this.leewayConnectionForm
          .get("companyName")
          .setValue(this.multiModeCompanyName());
      }
    });
  }

  initForm() {
    this.leewayConnectionForm = this.formBuilder.group({
      companyTicker: [this.multiModeCompanyTicker() || "", Validators.required],
      companyName: [this.multiModeCompanyName() || "", Validators.required],
    });
  }

  ngOnInit() {
    this.initForm();
    this.formValidity.emit(this.leewayConnectionForm.valid);
    this.formValueChange.emit(this.leewayConnectionForm.value);
    // Subscribe to form changes
    this.leewayConnectionForm.valueChanges.subscribe((values) => {
      this.formValueChange.emit(values);
    });

    // Subscribe to form validity changes
    this.leewayConnectionForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  saveForm() {
    if (this.leewayConnectionForm.valid) {
      const leewayConnectionFormData: any = {
        ...this.leewayConnectionForm.value,
      };
      this.formSubmit.emit(leewayConnectionFormData);
    } else {
      this.markFormGroupTouched(this.leewayConnectionForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.leewayConnectionForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
