import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ILegalDisclaimerCompany } from "~global-interfaces/ILegalDisclaimerCompany";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-legal-disclaimer-company-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormButtonComponent,
    JsonPipe,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./admin-legal-disclaimer-company-editor-form.component.html",
  styleUrl: "./admin-legal-disclaimer-company-editor-form.component.scss",
})
export class AdminLegalDisclaimerCompanyEditorFormComponent implements OnInit {
  @Input() legalDisclaimerCompany!: WritableSignal<ILegalDisclaimerCompany>;
  @Output() formSubmit = new EventEmitter<ILegalDisclaimerCompany>();
  adminRequestService = inject(AdminRequestService);

  formBuilder = inject(FormBuilder);
  legalDisclaimerCompanyForm: FormGroup;

  constructor() {
    this.adminRequestService.emptyLastRequest();

    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.legalDisclaimerCompanyForm = this.formBuilder.group({
      bloomberg: ["", Validators.required],
      disclosures: ["", Validators.required],
      name: ["", Validators.required],
    });
  }

  patchFormValues() {
    if (this.legalDisclaimerCompany()) {
      this.legalDisclaimerCompanyForm.patchValue(this.legalDisclaimerCompany());
    }
  }

  saveForm() {
    if (this.legalDisclaimerCompanyForm.valid) {
      const updatedLegalDisclaimerCompany = {
        ...this.legalDisclaimerCompany(),
        ...this.legalDisclaimerCompanyForm.value,
      };
      this.formSubmit.emit(updatedLegalDisclaimerCompany);
    } else {
      this.markFormGroupTouched(this.legalDisclaimerCompanyForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.legalDisclaimerCompanyForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
