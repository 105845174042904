import { DecimalPipe, JsonPipe, NgClass, NgStyle } from "@angular/common";
import { Component, computed, inject, Input, signal } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { CompanylistService } from "~features/company/companylist.service";
import { ExcelService } from "~features/company/excel.service";
import { ExchangeMappingService } from "~features/company/exchangeMapping.service";
import { CompanyGridHighlightTileComponent } from "~features/company-grid/company-grid-highlight-tile/company-grid-highlight-tile.component";
import { CompanyGridTileComponent } from "~features/company-grid/company-grid-tile/company-grid-tile.component";
import { LoadingIndicatorComponent } from "~shared/components/ui/loading-indicator/loading-indicator.component";

@Component({
  selector: "app-company-grid",
  templateUrl: "./company-grid.component.html",
  styleUrls: ["./company-grid.component.scss"],
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    NgClass,
    LoadingIndicatorComponent,
    NgStyle,
    DecimalPipe,
    CompanySearchComponent,
    CompanyGridTileComponent,
    CompanyGridHighlightTileComponent,
    JsonPipe,
  ],
})
export class CompanyGridComponent {
  @Input() numRows = 4;
  @Input() showAll = false;
  @Input() listOfCompanies = [];
  @Input() displayMode = "full"; // full || companyPage || ???

  recColors: object = {
    "Spec. BUY": "#10B981",
    BUY: "#10B981",
    HOLD: "#F59E0B",
    SELL: "#F87171",
  };
  companylistService = inject(CompanylistService);
  excelService = inject(ExcelService);
  exchangeMappingService = inject(ExchangeMappingService);
  companyList = this.companylistService.getCompanyList();

  showAllCompanies = signal(false);

  displayedCompanies = computed(() => {
    const companies = this.companyList() || [];
    return this.showAllCompanies() ? companies : companies.slice(0, 16);
  });

  remainingCompanies = computed(() => {
    const totalCompanies = (this.companyList() || []).length;
    return totalCompanies - 16 > 0 ? totalCompanies - 16 : 0;
  });

  toggleCompanyDisplay() {
    this.showAllCompanies.update((current) => !current);
  }

  getColourClass(badge: string) {
    if (badge === "Initiation") {
      return "green";
    } else if (badge === "Update") {
      return "yellow";
    } else if (badge === "Transaction") {
      return "red";
    }
  }
}
