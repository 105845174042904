import { computed, inject, Injectable } from "@angular/core";

import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { DataServiceService } from "~features/data-service.service";
import { formatValue } from "~utils/numberFormatting.util";

@Injectable({
  providedIn: "root",
})
export class Tables {
  headlinePattern = /^HL_/;
  rowPattern = /^RW_/;
  currencyPattern = /{{curr}}/;
  notApplicable = "na"; //set this in admin panel
  languages = ["eng", "ger"];
  datatypes = ["DATA", "ONEDATA", "NODATA", "DATES", "QDATES", "HDATES"];
  highlights = ["none", "highlight", "highlight2", "headline"];

  excelService = inject(ExcelService);

  defaultTemplate = {
    name: "",
    customerId: "5eed24ff4fe2bd337ccab5d3", //TODO pass this from login or something
    settings: {
      tabs: true,
      title: {
        eng: "",
        ger: "",
      },
    },
    template: [
      {
        settings: {
          commonSize: true,
          language: "eng",
          numYearsFront: 4,
          numYearsBack: 1,
        },
        name: {
          ger: "",
          eng: "Profit and loss",
        },
        data: [
          {
            key: "Profit and loss (EUR m)",
            highlight: false,
            highlight2: false,
            headline: true,
            type: "DATES",
          },
          {
            key: "sales",
            highlight: false,
            highlight2: false,
            headline: false,
            type: "DATA",
          },
        ],
      },
    ],
  } as any;

  defaultLayout = [
    {
      settings: {
        commonSize: false,
        language: "eng",
        numYearsFront: 3,
        numYearsBack: 2,
      },
      name: {
        ger: "",
        eng: "Profit and loss",
      },
      data: [
        {
          key: "General Information",
          highlight: false,
          highlight2: false,
          headline: true,
          type: "DATES",
          data: [" ", " ", " ", " "],
        },
      ],
    },
  ] as any;

  tableLayout = computed(() => {
    this.excelService.getExcelMapping();
  });
  companySettingsService = inject(CompanydataService);
  currentCompanySettings =
    this.companySettingsService.getCurrentCompanySettings();

  constructor(
    private dataService: DataServiceService,
    private companyInfoService: CompanyInfoService
  ) {}

  createTableData(companyData: any, tableLayout, decimals: number) {
    if (companyData.base && companyData.financial_figures) {
      const mapping = this.excelService.getExcelMapping()();
      //process each tabs
      tableLayout.forEach((entry) => {
        const commonSize: boolean = entry.settings.commonSize;
        const language: string = entry.settings.language;
        const backYears: number = parseInt(entry.settings.numYearsBack);
        const frontYears: number = parseInt(entry.settings.numYearsFront);

        //process each line in a tabs
        entry.data.forEach((dataEntry) => {
          if (typeof dataEntry["data"] === "undefined") {
            dataEntry.key = dataEntry.key.replace(
              this.currencyPattern,
              companyData.base.curr
            );

            if (dataEntry.type === "DATA" || dataEntry.type === "ONEDATA") {
              const key = dataEntry.key;
              const mappingEntry = mapping[key];
              dataEntry.key = mappingEntry[language];
              dataEntry.key = dataEntry.key.replace(
                this.currencyPattern,
                companyData.base.curr
              );
              if (mappingEntry.type === "TIMESERIES") {
                if (dataEntry.type === "ONEDATA") {
                  dataEntry["data"] = this.getOneFromTimeseries(
                    companyData,
                    mappingEntry,
                    0,
                    "value",
                    language,
                    decimals
                  );
                } else {
                  dataEntry["data"] = this.getTimeseries(
                    key,
                    companyData,
                    mappingEntry,
                    backYears,
                    frontYears,
                    "value",
                    language,
                    decimals
                  );
                }
              } else if (mappingEntry.type === "VALUE") {
                dataEntry["data"] = this.getValue(
                  companyData,
                  mappingEntry,
                  language,
                  decimals
                );
              } else if (mappingEntry.type === "LIST") {
                dataEntry["data"] = this.getLastListValue(
                  companyData,
                  mappingEntry,
                  backYears,
                  frontYears,
                  "value",
                  language,
                  decimals
                );
              }

              if (commonSize && typeof mapping[key + "_c"] !== "undefined") {
                dataEntry["commonSizeKey"] = dataEntry.key;
                if (mappingEntry.type === "TIMESERIES") {
                  if (dataEntry.type === "ONEDATA") {
                    dataEntry["commonSizeData"] = this.getOneFromTimeseries(
                      companyData,
                      mapping[key + "_c"],
                      0,
                      "value",
                      language,
                      decimals
                    );
                  } else {
                    dataEntry["commonSizeData"] = this.getTimeseries(
                      key,
                      companyData,
                      mapping[key + "_c"],
                      backYears,
                      frontYears,
                      "value",
                      language,
                      decimals
                    );
                  }
                } else if (mappingEntry.type === "VALUE") {
                  dataEntry["commonSizeData"] = this.getValue(
                    companyData,
                    mapping[key + "_c"],
                    language,
                    decimals
                  );
                } else if (mappingEntry.type === "LIST") {
                  dataEntry["commonSizeData"] = this.getLastListValue(
                    companyData,
                    mapping[key + "_c"],
                    backYears,
                    frontYears,
                    "value",
                    language,
                    decimals
                  );
                }

                if (typeof entry.settings.injectFront !== "undefined") {
                  if (entry.settings.injectFront !== "") {
                    dataEntry["commonSizeData"].unshift(
                      this.doEval(
                        entry.settings.injectFront,
                        mapping[key + "_c"],
                        companyData,
                        language,
                        decimals
                      )
                    );
                  }
                }

                if (typeof entry.settings.injectBack !== "undefined") {
                  if (entry.settings.injectBack !== "") {
                    dataEntry["commonSizeData"].push(
                      this.doEval(
                        entry.settings.injectBack,
                        mapping[key + "_c"],
                        companyData,
                        language,
                        decimals
                      )
                    );
                  }
                }
              }
            } else if (
              dataEntry.type === "DATES" ||
              dataEntry.type === "QDATES" ||
              dataEntry.type === "HDATES"
            ) {
              if (dataEntry.type === "QDATES") {
                dataEntry["data"] = this.getTimeseries(
                  dataEntry.key,
                  companyData,
                  mapping["dates_q"],
                  backYears,
                  frontYears,
                  "date",
                  language,
                  decimals
                );
              } else if (dataEntry.type === "HDATES") {
                dataEntry["data"] = this.getTimeseries(
                  dataEntry.key,
                  companyData,
                  mapping["dates_h"],
                  backYears,
                  frontYears,
                  "date",
                  language,
                  decimals
                );
              } else {
                if (
                  typeof mapping["dates"].path
                    .replace("companyData.", "")
                    .split(".")
                    .reduce((o, i) => o[i], companyData) !== "undefined"
                ) {
                  dataEntry["data"] = this.getTimeseries(
                    dataEntry.key,
                    companyData,
                    mapping["dates"],
                    backYears,
                    frontYears,
                    "date",
                    language,
                    decimals
                  );
                } else {
                  //for data before inclusion of dates field
                  dataEntry["data"] = this.getTimeseries(
                    dataEntry.key,
                    companyData,
                    {
                      path: "companyData.financial_figures.profit_and_loss.sales",
                    },
                    backYears,
                    frontYears,
                    "date",
                    language,
                    decimals
                  );
                }
              }

              if (typeof entry.settings.injectFront !== "undefined") {
                if (entry.settings.injectFront !== "") {
                  dataEntry["data"].unshift(entry.settings.injectFront.heading);
                }
              }

              if (typeof entry.settings.injectBack !== "undefined") {
                if (entry.settings.injectBack !== "") {
                  dataEntry["data"].push(entry.settings.injectBack.heading);
                }
              }

              if (commonSize) {
                dataEntry["commonSizeKey"] = dataEntry.key.replace(
                  "(.)",
                  "(common size)"
                );
                dataEntry["commonSizeData"] = this.getTimeseries(
                  dataEntry.key,
                  companyData,
                  {
                    path: "companyData.financial_figures.profit_and_loss.sales",
                  },
                  backYears,
                  frontYears,
                  "date",
                  language,
                  decimals
                );
                if (typeof entry.settings.injectFront !== "undefined") {
                  if (entry.settings.injectFront !== "") {
                    dataEntry["commonSizeData"].unshift(
                      entry.settings.injectFront.heading
                    );
                  }
                }
                if (typeof entry.settings.injectBack !== "undefined") {
                  if (entry.settings.injectBack !== "") {
                    dataEntry["commonSizeData"].unshift(
                      entry.settings.injectBack.heading
                    );
                  }
                }
              }
            } else if (dataEntry.type === "NODATA") {
              const rowSize =
                entry.settings.numYearsFront + entry.settings.numYearsBack + 1;
              dataEntry.colspan = rowSize + 1;
              // dataEntry['data'] = new Array(rowSize).fill(' ');
              // if (commonSize) {
              //   dataEntry['commonSizeData'] = new Array(rowSize).fill(' ');
              // }
            } else if (commonSize) {
              dataEntry["commonSizeKey"] = dataEntry.key;
            }
          }
        });
      });
    }

    return tableLayout;
  }

  doEval(
    evalSettings,
    mappingEntry,
    companyData,
    language: string,
    defaultDecimals: number
  ) {
    try {
      let evalExpr = evalSettings.formula;
      const evalVarPattern = /t_(-)?\d/g;
      const evalIndexPattern = /(-)?\d/;

      const path = mappingEntry.path.replace("companyData.", "");

      const originalData = path.split(".").reduce((o, i) => o[i], companyData);
      const baseYearIndex = this.findBaseYear(
        companyData.CompanyId,
        originalData
      );

      if (typeof baseYearIndex !== "undefined") {
        const vars = evalExpr.match(evalVarPattern);
        for (const v of vars) {
          const index = Number(v.match(evalIndexPattern)[0]);
          const value = originalData[baseYearIndex + index].value;
          evalExpr = evalExpr.replace(v, value);
        }
        // @ts-ignore
        const evalResult = eval(evalExpr);

        const settings = JSON.parse(JSON.stringify(evalSettings));

        if (evalSettings.unit === "ROW") {
          settings.unit = mappingEntry.unit;
        }

        return formatValue(evalResult, settings, language, defaultDecimals);
      } else {
        return this.notApplicable;
      }
    } catch (error) {
      return this.notApplicable;
    }
  }

  getLastListValue(
    companyData,
    mappingEntry,
    backYears: number,
    frontYears: number,
    dateOrValue: string,
    language: string,
    defaultDecimals: number
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    const tableData = [];

    const splitPath = path.split(".");
    let key = splitPath[splitPath.length - 1];
    splitPath.splice(splitPath.length - 1);
    const originalData = splitPath.reduce((o, i) => o[i], companyData);

    if (typeof originalData !== "undefined" && originalData.length > 0) {
      const data = originalData[originalData.length - 1];
      if (key === "event_date") {
        key = "date";
      }
      tableData.push(
        formatValue(data[key], mappingEntry, language, defaultDecimals)
      );
    } else {
      tableData.push(this.notApplicable);
    }

    return tableData;
  }

  getValue(
    companyData,
    mappingEntry,
    language: string,
    defaultDecimals
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    const tableData = [];
    // console.log("TABLE UTILS GET VALUE");
    // console.log("PATH", path);
    // console.log(companyData);
    try {
      const originalData = path.split(".").reduce((o, i) => o[i], companyData);

      if (typeof originalData !== "undefined") {
        tableData.push(
          formatValue(originalData, mappingEntry, language, defaultDecimals)
        );
      } else {
        tableData.push(this.notApplicable);
      }
    } catch (e) {}

    return tableData;
  }

  getTimeseries(
    key,
    companyData,
    mappingEntry,
    backYears: number,
    frontYears: number,
    dateOrValue: string,
    language: string,
    defaultDecimals: number
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    let tableData = [];
    // console.log("TABLE UTILS GET TIME SERIES");
    // console.log("PATH", path);
    // console.log(companyData);
    const originalData = path.split(".").reduce((o, i) => o[i], companyData);

    if (typeof originalData !== "undefined") {
      let baseYearIndex = this.findBaseYear(
        companyData.CompanyId,
        originalData
      );

      if (this.headlinePattern.test(key) || this.rowPattern.test(key)) {
        baseYearIndex = -1;
      }

      if (typeof baseYearIndex !== "undefined") {
        for (
          let i = baseYearIndex - backYears;
          i < baseYearIndex + frontYears + 1;
          i++
        ) {
          if (i > -1 && i < originalData.length) {
            let val;
            if (dateOrValue !== "date") {
              val = formatValue(
                originalData[i][dateOrValue],
                mappingEntry,
                language,
                defaultDecimals
              );
            } else {
              val = originalData[i][dateOrValue];
            }
            tableData.push(val);
          } else {
            tableData.push(this.notApplicable);
          }
        }
      } else {
        for (let i = 0; i < backYears + frontYears + 1; i++) {
          tableData.push(this.notApplicable);
        }
      }
    } else {
      tableData = new Array(frontYears + backYears + 1).fill(
        this.notApplicable
      );
    }

    return tableData;
  }

  getOneFromTimeseries(
    companyData,
    mappingEntry,
    baseyearShift: number,
    dateOrValue: string,
    language: string,
    defaultDecimals: number
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    const tableData = [];

    try {
      const originalData = path.split(".").reduce((o, i) => o[i], companyData);

      if (typeof originalData !== "undefined") {
        let baseYearIndex = this.findBaseYear(
          companyData.CompanyId,
          originalData
        );
        baseYearIndex += baseyearShift;

        if (
          typeof baseYearIndex !== "undefined" &&
          typeof originalData[baseYearIndex] !== "undefined"
        ) {
          let val;
          if (dateOrValue !== "date") {
            val = formatValue(
              originalData[baseYearIndex][dateOrValue],
              mappingEntry,
              language,
              defaultDecimals
            );
          } else {
            val = originalData[baseYearIndex][dateOrValue];
          }
          tableData.push(val);
        } else {
          tableData.push(this.notApplicable);
        }
      } else {
        tableData.push(this.notApplicable);
      }
    } catch (e) {}

    return tableData;
  }

  findBaseYear(
    companyId: string,
    data: Array<{ date: string; value: any }>
  ): number {
    const baseyear = String(this.currentCompanySettings().baseyear);

    if (typeof data !== "undefined") {
      return data.findIndex((entry) => {
        return (
          entry.date
            .replace("E", "")
            .replace("e", "")
            .replace("P", "")
            .replace("p", "") == baseyear
        );
      });
    } else {
      return 0;
    }
  }

  getData(path: string, companyData) {
    return path
      .replace("companyData.", "")
      .split(".")
      .reduce((o, i) => o[i], companyData);
  }
}
