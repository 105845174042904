/**
 *
 * Below are is a simple mapping object to replace the positional
 * values recieved from brevo enums with actual "short-form" values
 *
 * The same object is also used in the backend. But there is another hardcoded object in BE/hardcoded.
 * So they need to be kept in sync manually for now.
 *
 * ATTENTION: When mapping the positional Values AND/OR the long-form enums, the position is important.
 * Both, the object (in FE and BE) and the enums, need to resemble the order of the brevo data
 *
 */

export const INVESTOR_PROFILE_MAPPING = {
  aum: {
    title: "Assets under Management (EURm)",
    values: ["< 50", "50 - 200", "200 - 500", "> 500"],
  },
  size: {
    title: "Segment",
    values: ["Small & Mid Cap", "Large Cap", "All Cap"],
  },
  holdingPeriod: {
    title: "Holding Period (years)",
    values: ["< 1", "1 -3", "> 3"],
  },
  investmentStyle: {
    title: "Investment Style",
    values: ["Growth", "Value", "GARP", "ESG", "agnostic"],
  },
  investorType: {
    title: "Investor Type",
    values: [
      "Asset-/Portfolio Manager",
      "Independent Financial Adviser",
      "Family Office",
      "Presse / Börsenbrief",
      "Corporate",
      "Other",
      "Financial Analyst",
      "Bank / Broker / Financial Services",
      "Private Investor",
    ],
  },
  region: {
    title: "Region",
    values: ["Germany", "Europe", "Worldwide"],
  },
  ticketSize: {
    title: "Ticket Size (EURk)",
    values: ["< 50", "50 - 250", "> 250"],
  },
  description: {
    title: "Further Information",
    values: [],
  },
};

/**
 *
 * Below are proper enums and the "long-form" values shown in the signup process
 *
 *
 */
export enum PossibleCustomerTypes {
  Investor_AssetManager_Portfolio_Manager = "Asset Manager / Portfolio Manager",
  Investor_IndependentFinancialAdviser = "Independent Financial Adviser",
  Investor_FamilyOffice = "Family Office",
  FinancialPress = "Financial press",
  Corporate = "Corporate",
  Other = "Other",
  FinancialAnalyst = "Financial Analyst",
  BankBrokerFinancialServices = "Bank / Broker / Financial Services",
  PrivateInvestor = "Private Investor",
}

export enum PossibleUserRoles {
  Admin = "admin",
  Customer = "customer",
  Contributor = "contributor",
}

export enum PossibleInvestorTypes {
  Investor_AssetManager_Portfolio_Manager = "Asset Manager / Portfolio Manager",
  Investor_IndependentFinancialAdviser = "Independent Financial Adviser",
  Investor_FamilyOffice = "Family Office",
}

export const Countries = [
  "Germany",
  "France",
  "UK",
  "Austria",
  "Australia",
  "Switzerland",
  "Finland",
  "Denmark",
  "Netherlands",
  "Spain",
  "Italy",
  "USA",
  "India",
  "Luxembourg",
  "Belgium",
  "Sweden",
  "Norway",
  "Poland",
  "Czech_Republic",
  "Canada",
  "Bulgaria",
  "Slovenia",
  "Ireland",
  "Romania",
  "Singapore",
  "Thailand",
  "China",
  "Liechtenstein",
  "Greece",
  "Kuwait",
  "Portugal",
  "New Zealand",
  "Estonia",
];
export const AUM = [
  "up to EUR 50m",
  "over EUR 50m up to EUR 200m",
  "over EUR 200m up to EUR 500m",
  "over EUR 500m",
];
export const TicketSize = [
  "up to EUR 50k",
  "over EUR 50k up to EUR 250k",
  "over EUR 250k",
];
export const Size = [
  "Small & Mid Cap (up to EUR 2 bn)",
  "Large Cap (over EUR 2 bn)",
  "All Cap (no restriction)",
];
export const Region = ["Germany", "Europe", "Worldwide"];
export const InvestorStil = [
  "Growth",
  "Value",
  "GARP",
  "ESG",
  "agnostic / special sit / opportunistic",
];
export const Haltedauer = ["up to 1 year", "1 to 3 years", "over 3 years"];
