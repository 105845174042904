import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, inject, Input, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthService } from "~features/auth/auth.service";

@Component({
  selector: "app-signin-dialog",
  templateUrl: "./signin-dialog.component.html",
  styleUrls: ["./signin-dialog.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule],
})
export class SigninDialogComponent implements OnInit {
  @Input() status: false;
  @Input() reroute: false;
  @Input() rerouteURL: "/user/dashboard";
  showError = false;
  authSerice = inject(AuthService);
  router = inject(Router);

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  login() {
    this.showError = false;
    this.authSerice.login(this.data).then(
      () => {
        if (this.reroute) {
          this.authSerice.temporaryEmailInput = "";
          this.router.navigate([this.rerouteURL]);
          this.dialogRef.close();
        } else {
          this.authSerice.temporaryEmailInput = "";

          this.dialogRef.close();

          if (this.authSerice.isCustomerEmittent()) {
            this.router.navigate(["corporate/dashboard"]);
          } else {
            this.router.navigate(["user/dashboard"]);
          }
        }
      },
      (err) => {
        console.log("ERROR");
        this.showError = true;
        this.authSerice.temporaryEmailInput = this.data.userName;
      }
    );
  }

  logOff() {
    this.authSerice.logOff();
    this.router.navigate([""]);
  }

  goToSignup() {
    this.dialogRef.close();
    this.router.navigate(["signup"]);
  }

  ngOnInit(): void {}
}
