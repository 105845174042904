import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IFrontendConfig } from "~global-interfaces/IFrontendConfig";

import { alsterAPI_URL } from "~api/api-urls";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class FrontendConfigService {
  http = inject(HttpClient);
  frontendConfig: WritableSignal<IFrontendConfig | null> = signal(null);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);

  constructor() {
    //console.log("IM AM THE frontend Config CONSTRUCTOR");
    this.fetchFrontendConfig();
  }

  fetchFrontendConfig() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<any>>(alsterAPI_URL + "app-init")
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetching frontend config"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (frontendConfig) => {
          this.frontendConfig.set(frontendConfig);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch FrontendConfig successfully"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public getFrontendConfig() {
    return this.frontendConfig;
  }

  updateFrontEndConfig(newData) {
    const body = { ...newData };
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<any>>(alsterAPI_URL + "app-init/" + newData._id, body)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "updateFrontEndConfig")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (frontendConfig) => {
          this.frontendConfig.set(frontendConfig);
          this.adminRequestService.setLastRequestMessage("5", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  getisLoading() {
    return this.isLoading;
  }
  createFrontEndConfig(newData = initialFrontendConfig) {
    this.isLoading.set(true);
    const body = { ...newData };
    this.http
      .post<IApiResponse<any>>(alsterAPI_URL + "app-init", body)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "createFrontEndConfig company"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (frontendConfig) => {
          this.frontendConfig.set(frontendConfig);
          this.adminRequestService.setLastRequestMessage("14", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}

const initialFrontendConfig = {
  activeTransaction: false,
};
