<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Edit a company</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
      <!--      <div>-->
      <!--        <app-delete-button-->
      <!--          (deleteConfirmed)="deleteCompany(company()?._id)"-->
      <!--          confirmText="Confirm Company Deletion for {{ company()?.name }}"-->
      <!--          deleteText="Delete Company"-->
      <!--        ></app-delete-button>-->
      <!--      </div>-->
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 md:col-span-8">
        <app-admin-company-settings-editor-form
          (formSubmit)="onFormSubmit($event)"
          [company]="company"
        ></app-admin-company-settings-editor-form>
      </div>
      <div class="col-span-12 md:col-span-4">
        <app-file-upload></app-file-upload>
      </div>
    </div>
  </main>
</div>
