import { JsonPipe } from "@angular/common";
import {
  Component,
  computed,
  effect,
  inject,
  Input,
  signal,
  WritableSignal,
} from "@angular/core";
import { RouterLink } from "@angular/router";

import { CompanyHighlightService } from "~features/company/company-highlight.service";
import { CompanylistService } from "~features/company/companylist.service";
import { ExcelService } from "~features/company/excel.service";
import { ExchangeMappingService } from "~features/company/exchangeMapping.service";
import { CompanyGridHighlightTileComponent } from "~features/company-grid/company-grid-highlight-tile/company-grid-highlight-tile.component";
import { CompanyGridTileComponent } from "~features/company-grid/company-grid-tile/company-grid-tile.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-company-grid-highlight",
  standalone: true,
  imports: [
    CompanyGridTileComponent,
    CompanyGridHighlightTileComponent,
    JsonPipe,
    RouterLink,
  ],
  templateUrl: "./company-grid-highlight.component.html",
  styleUrl: "./company-grid-highlight.component.scss",
})
export class CompanyGridHighlightComponent {
  @Input() linkToAllStocks = false;
  companylistService = inject(CompanylistService);
  companyHighlightsService = inject(CompanyHighlightService);
  excelService = inject(ExcelService);
  exchangeMappingService = inject(ExchangeMappingService);

  shownTiles: WritableSignal<number> = signal(6);

  companyList = this.companyHighlightsService.getCompanyHighlights();

  filteredCompanyList = computed(() => {
    if (this.companyList()) {
      const companies = this.companyList().filter(
        (company) =>
          company.is_public === true &&
          ["Update", "Initiation", "Transaction"].includes(company.isFeatured)
      );
      // Sort the companies based on isFeatured
      // return companies.sort((a, b) => {
      //   const order = { Initiation: 0, Transaction: 1, Update: 2 };
      //   return order[a.isFeatured] - order[b.isFeatured];
      // });
      return companies;
    } else {
      return null;
    }
  });
  showAllCompanies = signal(false);

  displayedCompanies = computed(() => {
    const companies = this.filteredCompanyList() || [];
    return this.showAllCompanies() || companies.length <= 6
      ? companies
      : companies.slice(0, 6);
  });

  remainingCompanies = computed(() => {
    const totalCompanies = (this.filteredCompanyList() || []).length;
    return Math.max(totalCompanies - 6, 0);
  });

  shouldShowToggle = computed(() => {
    return (this.filteredCompanyList() || []).length > 6;
  });

  constructor() {
    effect(() => {
      if (this.companyList()) {
        const companyTickers = this.companyList().map(
          (company) => company.companyTicker
        );
        this.excelService.fetchLiveQuotesAndTileData(companyTickers);
      }
    }, enableSignalWritesInEffectContext);
  }

  toggleCompanyDisplay() {
    this.showAllCompanies.update((current) => !current);
  }
}
