@if (events()?.length > 0) {
  <div>
    @for (event of events(); track event) {
      <app-corporate-event-tile
        [event]="event"
        [listType]="listType"
      ></app-corporate-event-tile>
    }
  </div>
}
