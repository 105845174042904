import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { IESGScore } from "~global-interfaces/IESGScore";

import { alsterAPI_URL, leewayAPI_URL } from "~api/api-urls";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanylistService } from "~features/company/companylist.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Injectable({
  providedIn: "root",
})
export class CompanyInfoService {
  currentCompanyESGScore: WritableSignal<IESGScore | null> = signal(null);
  isLoading: WritableSignal<boolean> = signal(false);
  private companyTickerService = inject(CompanyTickerService);
  private companyListService = inject(CompanylistService);
  private companyList = this.companyListService.getCompanyList();
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";

  constructor(private httpClient: HttpClient) {
    effect(() => {
      const companyTicker = this.companyTickerService.getCompanyTicker();
      if (this.companyList() && companyTicker()) {
        this.fetchESGScore(companyTicker());
      }
    }, enableSignalWritesInEffectContext);
  }

  sendBookAMeetingRequestForNewResearch(
    companyName,
    lastname,
    firstname,
    email,
    requesterscompany,
    phone
  ) {
    const body = {
      companyName: companyName,
      lastname: lastname,
      firstname: firstname,
      email: email,
      requesterscompany: requesterscompany,
      phone: phone,
    };
    return this.httpClient
      .post(
        alsterAPI_URL + "meeting/bookameetingrequest/" + this.customerId,
        body
      )
      .toPromise();
  }

  public fetchESGScore(symbolExchange: string) {
    const newSymbolExchange = symbolExchange.replace(":", ".");
    const url =
      leewayAPI_URL +
      "companies/esg/score/" +
      newSymbolExchange +
      "?apitoken=cqt1bii3tq5fuzo0c5id";
    const body = {};
    this.httpClient.post<any>(url, body).subscribe((result: IESGScore) => {
      this.currentCompanyESGScore.set(result);
    });
  }

  getESGScore() {
    return this.currentCompanyESGScore;
  }
}
