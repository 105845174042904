import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay";
import { JsonPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { NavigationEnd, Router, RouterLink } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { AuthService } from "~features/auth/auth.service";
import { SigninDialogComponent } from "~features/auth/signin-dialog/signin-dialog.component";
import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { SubscribeService } from "~features/newsletter-subscription/subscribe.service";
import { TransactionbannerComponent } from "~features/transactions/transactionbanner/transactionbanner.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { SimpleLoadingIndicatorComponent } from "~shared/components/ui/simple-loading-indicator/simple-loading-indicator.component";
import { DialogService } from "~shared/services/dialog.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    RouterLink,

    TransactionbannerComponent,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    BreadcrumpComponent,
    CompanySearchComponent,
    SimpleLoadingIndicatorComponent,
    LogoComponent,
    JsonPipe,
    AdminFormRequestMessageComponent,
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // @ViewChild("instance", { static: true }) instance: NgbTypeahead;
  // @ViewChild("instanceMobile", { static: true }) instanceMobile: NgbTypeahead;

  dialogService = inject(DialogService);
  isOpen = false;
  frontendConfigService = inject(FrontendConfigService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  public model: any;
  listOfCompanies: any = [];
  navbarOpen = true;
  mobileHeader = false;

  currentUserName = "";
  subscribeService = inject(SubscribeService);
  frontendConfig = this.frontendConfigService.getFrontendConfig();
  isMobileMenuOpen = false;
  navLinks = [
    { route: "company-screener", label: "Screener", highlight: false },
    { route: "events", label: "Events", highlight: false },
    { route: "podcasts", label: "Podcasts", highlight: true },
    { route: "blog/weeklyScreener", label: "Featured Ideas", highlight: false },
    { route: "disclaimer", label: "Disclaimer", highlight: false },
  ];

  public mobileNavlinks = this.navLinks.filter(
    (link) => link.label !== "Screener" && link.label !== "Disclaimer"
  );
  isAuthenticated = this.authService.isAuthenticated;
  private routerSubscription: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
    private companyInfoService: CompanyInfoService
  ) {
    this.currentUserName = this.authService.getCurrentUserName();
  }

  ngOnInit() {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isOpen = false;
      });
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  canUseAdminPanel() {
    return this.authService.canUseAdminPanel();
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isCustomer() {
    return this.authService.isCustomer();
  }

  isCustomerEmittent() {
    return this.authService.isCustomerEmittent();
  }

  isCustomerInvestor() {
    return this.authService.isCustomerInvestor();
  }

  isContributor() {
    return this.authService.isContributor();
  }

  openLogin() {
    if (this.mobileHeader) {
      this.navbarOpen = true;
    }
    const modalRef = this.dialogService.open(
      SigninDialogComponent,
      {
        data: {
          centered: true,
          size: "lg",
        },
      },
      "dialog-panel-gray-alster"
    );
    //modalRef.componentInstance.status = this.isAuthenticated();
  }

  logOff() {
    this.authService.logOff();
    this.router.navigate([""]);
  }
  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
