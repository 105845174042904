import { CdkAccordion, CdkAccordionItem } from "@angular/cdk/accordion";
import { JsonPipe, NgSwitch } from "@angular/common";
import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  inject,
  OnInit,
  QueryList,
  Signal,
  signal,
  ViewChildren,
  WritableSignal,
} from "@angular/core";
import { ICompany } from "~global-interfaces/ICompany";
import { IEditorContent } from "~global-interfaces/IEditorContent";
import { IExchangeMapping } from "~global-interfaces/IExchangeMapping";
import { IResearch } from "~global-interfaces/IResearch";

import { CompanyExchangeMappingEditorFormComponent } from "~features/company/admin/company-exchange-mapping/company-exchange-mapping-editor-form/company-exchange-mapping-editor-form.component";
import { CompanyInitiationNoteFormComponent } from "~features/company/admin/company-initiation-note/company-initiation-note-form/company-initiation-note-form.component";
import { CompanyInvestmentcaseFormComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-form/company-investmentcase-form.component";
import { CompanyLeewayConnectionFormComponent } from "~features/company/admin/company-leeway/company-leeway-connection-form/company-leeway-connection-form.component";
import { AdminCompanyResearchEditorFormComponent } from "~features/company/admin/company-research/admin-company-research-editor-form/admin-company-research-editor-form.component";
import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { CompanyValuationEditorFormComponent } from "~features/company/admin/company-valuation/company-valuation-editor-form/company-valuation-editor-form.component";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { ExchangeMappingService } from "~features/company/exchangeMapping.service";
import { InitiationNoteService } from "~features/company/initiation-note.service";
import { InvestmentCaseService } from "~features/company/investment-case.service";
import { SwotService } from "~features/company/swot.service";
import { ValuationService } from "~features/company/valuation.service";
import { AdminEventEditorFormComponent } from "~features/events/admin/admin-event-editor-form/admin-event-editor-form.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { ResearchService } from "~features/research/research.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import {
  INITIAL_COMPANY,
  INITIAL_EDITORCONTENT_INVESTMENTCASE,
  INITIAL_EDITORCONTENT_VALUATION,
  INITIAL_INITIATIONNOTE,
  INITIAL_RESEARCH,
  INITIAL_SWOT,
} from "~shared/initial-values";

@Component({
  selector: "app-guided-company-creation",
  standalone: true,
  imports: [
    AdminCompanySettingsEditorFormComponent,
    AdminSidePanelComponent,
    BreadcrumpComponent,
    FileUploadComponent,
    AdminEventEditorFormComponent,
    JsonPipe,
    NgSwitch,
    AdminCompanyResearchEditorFormComponent,
    AdminCompanySwotEditorFormComponent,
    CompanyExchangeMappingEditorFormComponent,
    CompanyInitiationNoteFormComponent,
    CompanyInvestmentcaseFormComponent,
    CompanyValuationEditorFormComponent,
    CompanySearchComponent,
    CdkAccordionItem,
    CdkAccordion,
    AdminFormRequestMessageComponent,
    CompanyLeewayConnectionFormComponent,
  ],
  templateUrl: "./guided-company-creation.component.html",
  styleUrl: "./guided-company-creation.component.scss",
})
export class GuidedCompanyCreationComponent implements OnInit, AfterViewInit {
  @ViewChildren("stepElement") stepElements!: QueryList<ElementRef>;

  currentStep = signal(0);

  steps = [
    { id: "companySettings", name: "Company Settings", optional: false },
    { id: "exchangeMapping", name: "Exchange Mapping", optional: true },
    { id: "leewayConnection", name: "Leeway Connection", optional: false },
    // { id: "investmentCase", name: "Investment Case", optional: false },
    // { id: "swot", name: "SWOT", optional: false },
    // { id: "valuation", name: "Valuation", optional: true },
    // { id: "research", name: "Research", optional: false },
    // { id: "saveresearch", name: "Save Research", optional: false },
    // { id: "initiationNote", name: "Initiation Note", optional: false },
  ];
  companySettingsInitialData: WritableSignal<any> =
    signal<ICompany>(INITIAL_COMPANY);
  investmentCaseInitialData: WritableSignal<IEditorContent | null> = signal(
    INITIAL_EDITORCONTENT_INVESTMENTCASE
  );
  swotInitialData: WritableSignal<any> = signal(INITIAL_SWOT);
  valuationInitialData: WritableSignal<any> = signal(
    INITIAL_EDITORCONTENT_VALUATION
  );
  researchInitialData: WritableSignal<any> =
    signal<IResearch>(INITIAL_RESEARCH);
  initiationNoteInitialData: WritableSignal<any> = signal(
    INITIAL_INITIATIONNOTE
  );

  formValidityStatus = signal<{ [key: string]: boolean }>({});
  formFinishStatus = signal<{ [key: string]: boolean }>({});
  allFormsValid = computed(() =>
    this.steps.every(
      (step) => step.optional || this.formValidityStatus()[step.id]
    )
  );
  allFormsFinished = computed(() =>
    this.steps.every(
      (step) => step.optional || this.formFinishStatus()[step.id]
    )
  );

  companySettingsService = inject(CompanydataService);
  formValues = signal<{ [key: string]: any }>({ saveresearch: false });
  researchFormValue = signal<{ [key: string]: any }>({});
  exchangeMappingService = inject(ExchangeMappingService);
  exchangeMapping: Signal<IExchangeMapping> =
    this.exchangeMappingService.exchangeMapping;
  currentCompanyTicker: WritableSignal<string | null> = signal(null);
  currentCompanyName: WritableSignal<string | null> = signal(null);
  researchService = inject(ResearchService);
  swotService = inject(SwotService);
  initiationNoteService = inject(InitiationNoteService);
  investmentCaseService = inject(InvestmentCaseService);
  excelService = inject(ExcelService);
  valuationService = inject(ValuationService);

  constructor() {}

  ngAfterViewInit() {
    this.setupScrollObserver();
  }

  setupScrollObserver() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const stepIndex = parseInt(entry.target.id.split("-")[1]);
            this.currentStep.set(stepIndex);
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is visible
    );

    this.stepElements.forEach((stepElement) => {
      observer.observe(stepElement.nativeElement);
    });
  }

  ngOnInit() {
    // Initialize form validity status
    this.formValidityStatus.set(
      this.steps.reduce((acc, step) => ({ ...acc, [step.id]: false }), {})
    );
  }

  updateFormValidity(formName: string, isValid: boolean) {
    this.formValidityStatus.update((prev) => ({
      ...prev,
      [formName]: isValid,
    }));
  }

  updateFormFinishedStatus(formName: string, isFinished: boolean) {
    this.formFinishStatus.update((prev) => ({
      ...prev,
      [formName]: isFinished,
    }));
  }

  updateFormValues(formName: string, values: any) {
    switch (formName) {
      case "companySettings":
        this.currentCompanyTicker.set(values.companyTicker);
        this.currentCompanyName.set(values.name);
        this.formValues.update((prev) => ({ ...prev, [formName]: values }));
        break;
      case "research":
        this.researchFormValue.update((prev) => ({
          ...prev,
          [formName]: values,
        }));

        break;
      default:
        this.formValues.update((prev) => ({ ...prev, [formName]: values }));
        break;
    }
  }

  submitResearch() {
    const { research } = this.researchFormValue();
    if (research) {
      this.researchService.createResearch(research);
      this.updateFormFinishedStatus("research", true);
      this.updateFormFinishedStatus("saveresearch", true);
      this.updateFormValidity("saveresearch", true);
    }
  }

  submitAll() {
    // research itself is already created separatly
    // leewayConnection is already triggered separatly this.excelService.makeLeewayRequest(data);
    const {
      companySettings,
      swot,
      exchangeMapping,
      investmentCase,
      valuation,
      initiationNote,
      leewayConnection,
    } = this.formValues();

    if (companySettings) {
      this.companySettingsService.createCompany(companySettings);
      this.updateFormFinishedStatus("companySettings", true);
    }
    if (leewayConnection) {
      this.excelService.makeLeewayRequest(leewayConnection);
      setTimeout(() => {
        this.updateFormFinishedStatus("leewayConnection", true);
      }, 2000);
    }
    if (swot) {
      this.swotService.createSWOT(swot);
      this.updateFormFinishedStatus("swot", true);
    }
    if (investmentCase) {
      this.investmentCaseService.createInvestmentCase(investmentCase);
      this.updateFormFinishedStatus("investmentCase", true);
    }
    if (valuation) {
      this.valuationService.updateValuation(valuation);
      this.updateFormFinishedStatus("valuation", true);
    }
    if (initiationNote) {
      this.initiationNoteService.createInitiationNote(initiationNote);
      this.updateFormFinishedStatus("initiationNote", true);
    }
    if (exchangeMapping) {
      this.exchangeMappingService.updateExchangeMapping(exchangeMapping);
      this.updateFormFinishedStatus("exchangeMapping", true);
    }
  }
}
