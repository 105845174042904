import { inject, Injectable, signal, WritableSignal } from "@angular/core";

import { AuthService } from "~features/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class UserReminderService {
  showToast: WritableSignal<boolean> = signal(false);
  private readonly reminderKey = "lastReminderDate";

  private authService = inject(AuthService);
  isAuthentificated = this.authService.isAuthenticated;

  constructor() {
    this.checkReminder();
  }

  showReminder() {
    this.showToast.set(true);
  }

  hideReminder() {
    this.showToast.set(false);
    this.setLastReminderDate();
  }

  checkReminder() {
    const lastReminderDate = this.getLastReminderDate();
    const now = new Date();
    if (
      this.isAuthentificated() &&
      (!lastReminderDate ||
        now.getTime() - lastReminderDate.getTime() > 14 * 24 * 60 * 60 * 1000)
    ) {
      this.showReminder();
    }
  }

  private setLastReminderDate() {
    localStorage.setItem(this.reminderKey, new Date().toISOString());
  }

  private getLastReminderDate(): Date | null {
    const dateStr = localStorage.getItem(this.reminderKey);
    return dateStr ? new Date(dateStr) : null;
  }
}
