import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-event-not-found",
  templateUrl: "./event-not-found.component.html",
  styleUrls: ["./event-not-found.component.scss"],
  standalone: true,
  imports: [RouterLink],
})
export class EventNotFoundComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
