<div class="container mx-auto px-16">
  <div class="flex flex-col space-y-8 pt-12">
    <div class="w-full md:w-5/6">
      <h3 class="text-xl font-bold mb-4">MiFID II</h3>
      <h4 class="text-md font-semibold mb-4">Our status under MiFID II</h4>
      <p class="text-base mb-6">
        Our research is classified as minor non-monetary benefit under MiFID II.
        This applies to all forms of transmission, including email, the digital
        research hub (DRH) and financial platforms such as Bloomberg or
        Refinitiv. We prominently disclose on our research that we are
        commissioned to write the research we produce on our notes. Our research
        is read on a free-to-access basis by institutions across the globe. It
        has been accessed by more than 5,000 professional investment
        institutions since MiFID II was introduced and many other market
        participants including private investors, sell side, advisors and press.
        The MiFID II rules make provisions for certain content and services to
        be received by institutional investors without the requirement for
        payment under the inducement rules. The list includes:
      </p>

      <ol class="list-decimal list-inside mb-6">
        <li class="mb-2">
          Written research that is paid for by the issuer to promote a new issue
        </li>
        <li class="mb-2">
          Written research where there is a contractual engagement to produce
          this written content on an ongoing basis where the relationship
          between the issuer and the research provider is clearly disclosed and
          is made widely available to the general public.
        </li>
        <li class="mb-2">
          Brief, unsubstantiated summaries on company results and upcoming
          releases and events.
        </li>
        <li class="mb-2">
          Macroeconomic and fixed income research that is freely disseminated
          with information that is generic in nature.
        </li>
        <li class="mb-2">
          Investor roadshows that are organized by an investor relations
          officer, or where the issuer pays a third-party provider to organize a
          roadshow that is made widely available to institutional and other
          investors.
        </li>
      </ol>

      <p class="text-base font-semibold mb-6">
        We do not seek payment from the asset management community and do not
        have any execution function. Investors can continue to receive our
        research under the new MiFID II regime without the need for a contract
        for services to be put in place. This applies to all forms of
        transmission, including email, the digital research hub (DRH) and
        financial platforms such as Bloomberg and Refinitiv.
      </p>

      <p class="text-base mb-4">
        We highlight the following to recipients of our research and attendees
        of roadshows:
      </p>
      <ol class="list-decimal list-inside mb-6">
        <li class="mb-2">
          We do not conduct any execution business, hence we cannot induce our
          readers to trade with us.
        </li>
        <li class="mb-2">
          Our content is disseminated widely and made freely available to the
          public through a range of different channels.
        </li>
        <li class="mb-2">
          We do not seek any payment from our readers for our research.
        </li>
      </ol>

      <p class="text-base mb-6">
        Many have expressed concerns about the potential negative impact of the
        new rules on the research coverage of smaller companies and the
        liquidity of their shares on secondary markets.
      </p>

      <p class="text-base mb-4">
        To ease this potential impact, we took certain steps ahead of MiFID II
        by:
      </p>
      <ol class="list-decimal list-inside mb-6">
        <li class="mb-2">
          allowing free distribution of research that supports capital raising
          events;
        </li>
        <li class="mb-2">
          allowing issuer-sponsored research – an important source of coverage
          for smaller companies – to be freely circulated; and
        </li>
        <li class="mb-2">
          clarifying that research made publicly available cannot be an
          inducement.
        </li>
      </ol>
    </div>
  </div>
</div>
