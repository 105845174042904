import { Clipboard } from "@angular/cdk/clipboard";
import { JsonPipe } from "@angular/common";
import {
  Component,
  computed,
  effect,
  inject,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import { IEvent } from "~global-interfaces/IEvent";

import { AuthService } from "~features/auth/auth.service";
import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanylistService } from "~features/company/companylist.service";
import { CorporateEventGridComponent } from "~features/corporate-dashboard/corporate-event-grid/corporate-event-grid.component";
import { CorporateResearchService } from "~features/corporate-dashboard/corporate-research.service";
import { EventGridMixedComponent } from "~features/events/event-grid-mixed/event-grid-mixed.component";
import { EventService } from "~features/events/event.service";
import { DriveUploaderComponent } from "~features/file-manager/drive-uploader/drive-uploader.component";
import { ResearchAccordionComponent } from "~features/research/research-accordion/research-accordion.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { LoadingIndicatorComponent } from "~shared/components/ui/loading-indicator/loading-indicator.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { TabComponent } from "~shared/components/ui/tab/tab.component";
import { TabsComponent } from "~shared/components/ui/tabs/tabs.component";

@Component({
  selector: "app-corporate-dashboard-page",
  templateUrl: "./corporate-dashboard-page.component.html",
  styleUrls: ["./corporate-dashboard-page.component.scss"],
  standalone: true,
  imports: [
    LoadingIndicatorComponent,
    DriveUploaderComponent,
    TabsComponent,
    TabComponent,
    EventGridMixedComponent,
    CorporateEventGridComponent,
    ResearchAccordionComponent,
    JsonPipe,
    LogoComponent,
  ],
})
export class CorporateDashboardPageComponent {
  expanded = signal<boolean[]>([]);

  companyIsCovered: WritableSignal<boolean> = signal<boolean>(false);
  loadingNewData = signal<boolean>(true);
  currentCompanyPresentation = signal<any>(null);
  alsterContact = {
    name: "Holger Nass",
    email: "h.nass@mwb-research.com",
    phone: "+49 160 - 9798 3302",
  };
  private companyTickerService = inject(CompanyTickerService);
  currentCompanyTicker = this.companyTickerService.getCompanyTicker();
  private authService = inject(AuthService);
  private companyInfoService = inject(CompanyInfoService);
  private companyListService = inject(CompanylistService);
  companyListIsLoaded = this.companyListService.isLoaded;
  private companyResearchService = inject(CompanyResearchService);
  private corporateResearchService = inject(CorporateResearchService);
  researchOverview = this.corporateResearchService.getResearchOverview();
  currentUsersCompanyName =
    this.corporateResearchService.getCorporateCompanyName();
  currentUserCompanyticker =
    this.corporateResearchService.getCorporateCompanyticker();
  private eventService = inject(EventService);
  companyEvents = this.eventService.getCompanyEvents();
  private clipboard = inject(Clipboard);
  private today = new Date().setHours(new Date().getHours() - 2);

  nextEvent: Signal<IEvent[] | null> = computed(() => {
    if (this.companyEvents()?.length > 0) {
      return [
        this.companyEvents().find(
          (event) => new Date(event.date).getTime() > this.today
        ),
      ];
    } else {
      return null;
    }
  });

  pastEvents = computed(() => {
    if (this.companyEvents()?.length > 0) {
      return this.companyEvents().filter(
        (event) => new Date(event.date).getTime() < this.today
      );
    } else {
      return [];
    }
  });

  upcomingEvents = computed(() => {
    if (this.companyEvents()?.length > 0) {
      const filteredUpcomingEventsWithoutNextEvent =
        this.companyEvents().filter(
          (event) => new Date(event.date).getTime() > this.today
        );
      filteredUpcomingEventsWithoutNextEvent.shift();
      return filteredUpcomingEventsWithoutNextEvent;
    } else {
      return [];
    }
  });

  constructor() {
    effect(() => {
      // console.log(
      //   "CURRENT COMPANY NAME  ",
      //   this.authService.getcurrentUserCompanyName()
      // );
      this.corporateResearchService.setCorporateCompanyName(
        this.authService.getcurrentUserCompanyName()
      );

      this.corporateResearchService.setCorporateCompanyticker(
        this.authService.getcurrentUserCompanyticker()
      );

      if (this.companyListIsLoaded()) {
        this.companyIsCovered.set(
          this.companyListService.companyIsCovered(
            this.currentUsersCompanyName()
          )
        );
        if (this.currentUsersCompanyName() && this.currentCompanyTicker()) {
          this.eventService.fetchCompanyEvents(this.currentCompanyTicker());
        } else {
          this.eventService.fetchCompanyEvents(this.currentUserCompanyticker());
        }
      }
    }, enableSignalWritesInEffectContext);
  }

  public getLogoForCompany(): string {
    return "../../assets/blanklogo.svg"; //TODO. return company logo if covered, return event logo if not
  }
}
