<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        See all companies from the legal disclaimer
      </p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    @if (legalCompaniesList()) {
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <table class="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-4 py-2 text-left">Name</th>
              <th class="px-4 py-2 text-left">Bloomberg</th>
              <th class="px-4 py-2 text-left">Disclosures</th>
              <th class="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (company of legalCompaniesList(); track company.bloomberg) {
              <tr>
                <td class="px-4 py-2">{{ company.name }}</td>
                <td class="px-4 py-2">{{ company.bloomberg }}</td>
                <td class="px-4 py-2">{{ company.disclosures }}</td>
                <td class="px-4 py-2">
                  <a [routerLink]="[company._id, 'edit']">
                    <button
                      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Settings
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </main>
</div>
