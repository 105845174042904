import { NgClass } from "@angular/common";
import { Component, inject } from "@angular/core";

import { UserNotificationService } from "~features/user-notifications/user-notification.service";

@Component({
  selector: "app-user-notification-toast",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./user-notification-toast.component.html",
  styleUrl: "./user-notification-toast.component.scss",
})
export class UserNotificationToastComponent {
  userNotificationService = inject(UserNotificationService);
  notifications = this.userNotificationService.notifications;
}
