<div class="flex flex-col min-h-screen">
  <section class="">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <div class="w-full">
        <section class="py-8 lg:py-12 px-5">
          <div class="container mx-auto px-16 max-w-screen-2xl">
            <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 py-4">
              <div class="lg:col-span-3 text-xl leading-none font-semibold">
                Check out
                <div class="text-blue-500 font-semibold">
                  all stocks we cover
                </div>
              </div>
              <div class="lg:col-span-2">
                <p class="text-md mt-4 lg:mt-0">
                  Pick your favorites or browse our highlighted stocks.
                </p>
              </div>
            </div>
          </div>
          <div class="container mx-auto px-16 pt-6 max-w-screen-2xl">
            <hr class="mb-4" />
          </div>
        </section>

        <section>
          <div class="container mx-auto px-16 max-w-screen-2xl">
            <app-company-grid-highlight></app-company-grid-highlight>
          </div>
        </section>
        <section class="px-5 pb-4">
          <div class="container mx-auto px-16 pt-6 max-w-screen-2xl">
            <hr class="mb-4" />
          </div>
        </section>
        <section>
          <div class="container mx-auto px-16 max-w-screen-2xl">
            <app-company-grid></app-company-grid>
          </div>
        </section>
      </div>
    </div>
  </section>

  <div class="my-5"></div>

  <hr />
</div>

<app-more-content-cta
  [showAllCompanies]="false"
  [showUpdates]="false"
></app-more-content-cta>
