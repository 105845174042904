@if (videoEvent()) {
  <div class="max-w-5xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="flex flex-col items-center">
      <app-video-player
        class="w-full"
        [videoURL]="videoEvent().videoURL"
        [openInDialog]="false"
      >
      </app-video-player>
      <div class="p-6 w-full text-center">
        <h2 class="text-lg font-bold text-gray-800">
          {{ videoEvent().companyName }}
        </h2>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-2 px-6">
          <div class="col-span-1 mx-auto">
            @if (videoEvent().speakers && videoEvent().speakers.length > 0) {
              @for (speaker of videoEvent().speakers; track speaker) {
                <div class="bg-white p-4">
                  <div class="grid grid-cols-4 gap-4 items-center">
                    <div class="col-span-1">
                      <div class="w-16 h-16 lg:w-24 lg:h-24 mx-auto">
                        <img
                          [src]="speaker.imageURL"
                          class="w-full h-full object-cover rounded-full"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="col-span-3 pl-4 text-left">
                      <h4 class="text-md font-semibold mb-2">
                        {{ speaker.name }}
                      </h4>
                      <p>{{ speaker.position }}</p>
                    </div>
                  </div>
                </div>
              }
            }
          </div>
        </div>
        @if (!hideDescription) {
          <p class="text-gray-600 text-md mt-2">
            {{ videoEvent().description }}
          </p>
        }

        <div class="mt-6 w-full">
          <hr class="w-full" />
          <div class="w-full">
            <div class="flex justify-between mt-4">
              <button
                (click)="
                  copyVideoURL(videoEvent().companyTicker, videoEvent().date)
                "
                class="text-blue-500 font-medium uppercase tracking-wide hover:underline text-start"
              >
                share video<i class="fas fa-arrow-right ml-1"></i>
              </button>
              @if (videoEvent()?.presentationURL) {
                <a
                  [href]="videoEvent().presentationURL"
                  class="text-blue-500 font-medium uppercase tracking-wide hover:underline"
                  target="_blank"
                >
                  Download Presentation <i class="fas fa-arrow-right ml-1"></i>
                </a>
              }
              @if (isCoveredCompany()) {
                <!-- is covered ? -->
                <a
                  [routerLink]="['/companies', videoEvent().companyName]"
                  class="text-blue-500 font-medium uppercase tracking-wide hover:underline"
                  target="_blank"
                >
                  Read Research <i class="fas fa-arrow-right ml-1"></i>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  <div class="max-w-2xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
    <div class="flex flex-col items-center pt-6">
      <div
        class="h-64 w-full lg:h-32 lg:w-96 object-cover rounded-lg bg-blue-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="max-h-full mx-auto"
          viewBox="0 0 576 512"
        >
          <!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
          />
        </svg>
      </div>
      <div class="p-6 w-full text-center">
        <h2 class="text-lg font-bold text-gray-800"></h2>
        <p class="text-gray-600 mt-2"></p>
        <p class="text-gray-500 text-sm mt-1"></p>
        <div class="mt-6 w-full">
          <div class="w-full">
            <div class="flex justify-between mt-4">
              <h5>Loading Video...</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
