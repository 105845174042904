<editor
  (ngModelChange)="onEditorChange($event)"
  [id]="editorId"
  [init]="init"
  [ngModel]="content"
  id="{{ editorId }}"
>
  <!-- put this in editor-tag above to enable auto-save   (onChange)="handleEditorChangedEvent($event)"   -->
</editor>

<button (click)="onSave()" class="btn">Save Text</button>
