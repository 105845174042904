<div class="flex flex-col lg:flex-row">
  <div class="lg:w-1/12 md:w-0"></div>
  <div class="lg:w-10/12 md:w-full text-center p-5">
    <h2 class="p-4 hidden md:block">
      <span class="text-blue-500">mwb research specials <br /></span> Fresh
      ideas for your performance
    </h2>
    <h4 class="p-4 block md:hidden">
      <span class="text-blue-500">mwb research specials <br /></span> Fresh
      ideas for your performance
    </h4>
    <p class="text-lg px-4">
      Our sales desk and our analysts take turns in uncovering hidden jewels and
      finding new angles on old acquaintances.
    </p>
  </div>
  <div class="lg:w-1/12 md:w-0"></div>
</div>

<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 p-8">
  @for (teaser of weeklyScreenerTeasers(); track teaser; let i = $index) {
    <div class="">
      <div class="text-gray-500 mb-4 text-xs">
        <app-date
          [date]="teaser.published_date"
          [formatType]="'dd/MM/yyyy'"
        ></app-date>
      </div>
      <h3 class="mb-2">{{ teaser.title }}</h3>
      <p class="text-gray-600 mb-4 line-clamp-4">{{ teaser.teaser_text }}</p>
      <a
        [routerLink]="[
          '/blog/weeklyScreener',
          {
            published_date: teaser.published_date,
            title: teaser.title
          }
        ]"
        class="text-blue-500 hover:underline"
        >Read More</a
      >
    </div>
  }
</div>
