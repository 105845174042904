import { Component, inject } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";

import { EventService } from "~features/events/event.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";

@Component({
  selector: "app-admin-event",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    RouterLink,
    AdminSidePanelComponent,
    LogoComponent,
  ],
  templateUrl: "./admin-event.component.html",
  styleUrls: ["./admin-event.component.scss"],
})
export class AdminEventComponent {
  eventService = inject(EventService);
  event = this.eventService.getCurrentEvent();

  constructor(private route: ActivatedRoute) {
    const eventId = this.route.snapshot.paramMap.get("eventid") ?? "";
    this.eventService.fetchEvent(eventId);
  }
}
