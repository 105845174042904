<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-hidden">
    <div class="h-screen overflow-y-auto">
      <div class="p-8">
        <app-breadcrump class="mb-6"></app-breadcrump>

        <div class="flex gap-8">
          <!-- Main content area with steps -->
          <div class="w-3/4 space-y-8">
            <h2 class="text-md font-bold mb-6">Guided Research Upload</h2>

            <div class="w-full border p-8 shadow mb-8 bg-yellow-100">
              <h5>
                Before you start: Select the company you want to upload research
                for
              </h5>
              <app-company-search
                (companySelected)="onCompanySelected($event)"
                [emitterMode]="true"
                [isAdminDashboard]="true"
                class="w-full"
              ></app-company-search>
            </div>

            @for (step of steps; track step.id; let i = $index) {
              <div class="bg-white shadow-md rounded-lg p-6" [id]="'step-' + i">
                <h3 class="text-md font-semibold mb-4">
                  Step {{ i + 1 }}: {{ step.name }}
                </h3>
                @switch (step.id) {
                  @case ("research") {
                    <app-admin-company-research-editor-form
                      (formValidity)="updateFormValidity('research', $event)"
                      (formValueChange)="updateFormValues('research', $event)"
                      [multiMode]="true"
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [research]="researchInitialData"
                    ></app-admin-company-research-editor-form>
                  }
                  @case ("publishfigures") {
                    <div class="mx-auto bg-white shadow-md rounded-lg p-8 my-4">
                      <button
                        (click)="publishExcelFigures()"
                        class="px-3 py-1 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Publish Excel Figures for {{ currentCompanyTicker() }}
                      </button>
                    </div>
                  }
                  @case ("rssgerman") {
                    <app-admin-rss-editor-form
                      (formValidity)="updateFormValidity('rssgerman', $event)"
                      (formValueChange)="updateFormValues('rssgerman', $event)"
                      [multiMode]="true"
                      [multiModeCompanyName]="currentCompanyName"
                      [lang]="'de'"
                      [rssEntry]="rssGermanInitialData"
                    ></app-admin-rss-editor-form>
                  }
                  @case ("rssenglish") {
                    <app-admin-rss-editor-form
                      (formValidity)="updateFormValidity('rssenglish', $event)"
                      (formValueChange)="updateFormValues('rssenglish', $event)"
                      [multiMode]="true"
                      [multiModeCompanyName]="currentCompanyName"
                      [lang]="'en'"
                      [rssEntry]="rssEnglishInitialData"
                    ></app-admin-rss-editor-form>
                  }
                  @case ("podcast") {
                    <app-admin-podcast-editor-form
                      (formValidity)="updateFormValidity('podcast', $event)"
                      (formValueChange)="updateFormValues('podcast', $event)"
                      [multiMode]="true"
                      [multiModeCompanyName]="currentCompanyName"
                      [research_language]="currentResearchPrimaryLanguage"
                      [research_date]="currentResearchDate"
                      [researchTitle]="currentResearchTitle"
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [podcast]="podcastInitialData"
                    ></app-admin-podcast-editor-form>
                  }

                  @default {
                    <p class="text-gray-600">
                      Form for {{ step.name.toLowerCase() }} goes here.
                    </p>
                  }
                }
                @if (step.optional) {
                  <p class="text-sm text-gray-500 mt-2">
                    This step is optional.
                  </p>
                }
              </div>
            }

            <!-- Submit Button -->
            <div class="mt-8">
              <button
                (click)="submitAll()"
                [disabled]="!allFormsValid()"
                class="w-full px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed transition duration-300 ease-in-out"
              >
                Submit All Steps
              </button>
            </div>
            <app-admin-form-request-message></app-admin-form-request-message>
          </div>
          <!-- Sticky sidebar for checklist -->
          <div class="w-1/4">
            <div
              class="sticky top-8 bg-white shadow-md rounded-lg p-4 max-h-[calc(100vh-4rem)] overflow-y-auto"
            >
              <h3 class="text-md font-semibold mb-4">Upload Progress</h3>
              <ul class="space-y-2">
                @for (step of steps; track step.id; let i = $index) {
                  <li class="flex items-center transition-all duration-300">
                    <span
                      class="w-6 h-6 flex items-center justify-center rounded-full mr-2 text-xs"
                      [class.bg-green-500]="formValidityStatus()[step.id]"
                      [class.bg-gray-300]="!formValidityStatus()[step.id]"
                      [class.text-white]="
                        currentStep() === i || formValidityStatus()[step.id]
                      "
                    >
                      {{ i + 1 }}
                    </span>
                    <span class="text-sm"
                      >{{ step.name }} {{ step.optional ? "(optional)" : "" }}:
                      {{
                        formFinishStatus()[step.id]
                          ? "✅"
                          : step.optional
                            ? "⚪"
                            : "❌"
                      }}</span
                    >
                  </li>
                }
              </ul>
              <div class="my-5">
                <app-file-upload></app-file-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
