import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { IEvent } from "~global-interfaces/IEvent";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { alsterAPI_URL } from "~api/api-urls";
import { CompanylistService } from "~features/company/companylist.service";
import { formatDateNow } from "~features/events/event.utils";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class ArchivedEventsService extends PaginationService<IEvent> {
  override http = inject(HttpClient);
  isLoading: WritableSignal<boolean> = signal(false);
  private companyListService = inject(CompanylistService);
  private companyList = this.companyListService.getCompanyList();
  constructor() {
    super(inject(HttpClient));

    effect(() => {
      if (this.companyList()) {
        this.fetchArvchivedEventsList(undefined, formatDateNow());
      }
    }, enableSignalWritesInEffectContext);
  }

  fetchArvchivedEventsList(
    paginationParams: IQueryParams = { page: 1, limit: 25 },
    beforedate: string = undefined,
    searchTerm: string = undefined
  ) {
    if (beforedate) {
      paginationParams = { ...paginationParams, beforedate };
    }
    if (searchTerm) {
      paginationParams = { ...paginationParams, searchterm: searchTerm };
    }
    paginationParams = { ...paginationParams, videomustexist: true };
    this.isLoading.set(true);
    this.fetchItems(alsterAPI_URL + "events", paginationParams).subscribe(
      ({ items, totalCount }) => {
        if (this.companyList()) {
          items = items.map((event) => ({
            ...event,
            isCovered: this.companyList().some(
              (company) => company.companyTicker === event.companyTicker
            ),
          }));
        }
        this.items.set(items);
        this.totalCount.set(totalCount || 0);
        this.isLoading.set(false);
      }
    );
  }

  getisLoading() {
    return this.isLoading;
  }
  public getArchivedEventsList() {
    return this.items;
  }
}
