<div class="flex bg-blue-100">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Create a company</p>
      <p class="text-red-500 font-semibold">
        You should use the
        <a
          [routerLink]="'/admin/dashboard/guided-company-creation'"
          class="underline text-blue-500"
          >guided process</a
        >
        when you creat a new company.
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <app-admin-company-settings-editor-form
        (formSubmit)="onFormSubmit($event)"
        [company]="company"
        class="col-span-3"
      ></app-admin-company-settings-editor-form>

      <app-file-upload class="col-span-1"></app-file-upload>
    </div>
  </main>
</div>
