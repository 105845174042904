import { JsonPipe, NgClass } from "@angular/common";
import { Component, inject, Signal } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IGuidedMenuStep } from "~global-interfaces/IGuidedMenuStep";

import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";

@Component({
  selector: "app-creation-process-menu",
  standalone: true,
  imports: [RouterLink, JsonPipe, ReactiveFormsModule, NgClass],
  templateUrl: "./creation-process-menu.component.html",
  styleUrls: ["./creation-process-menu.component.scss"],
})
export class CreationProcessMenuComponent {
  formBuilder = inject(FormBuilder);
  creationProcessMenuService = inject(CreationProcessMenuService);
  steps: Signal<IGuidedMenuStep[]> = this.creationProcessMenuService.steps;
  isGuidedMenuEnabled: Signal<boolean> =
    this.creationProcessMenuService.isGuidedMenuEnabled;
  tickerForm: FormGroup;
  currentTicker = this.creationProcessMenuService.getCurrentCompanyTicker();
  constructor() {
    this.tickerForm = this.formBuilder.group({
      ticker: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(15),
        ],
      ],
    });
  }

  getCurrentStepIndex(): number {
    return this.creationProcessMenuService.getCurrentStepIndex();
  }
  isCurrentStepCompleted(): boolean {
    return this.creationProcessMenuService.isCurrentStepCompleted();
  }

  navigateToNextStep() {
    this.creationProcessMenuService.navigateToNextStep();
  }
  saveForm() {
    if (this.tickerForm.valid) {
      const formData = this.tickerForm.value;
      this.creationProcessMenuService.setCurrentCompanyTicker(formData.ticker);
    }
  }
}
