import { Component, inject, Input } from "@angular/core";
import { IResearchPodcastPopulated } from "~global-interfaces/IResearchPodcast";

import { PodcastService } from "~features/podcasts/podcast.service";
import { PosthogService } from "~shared/services/posthog.service";

@Component({
  selector: "app-podcast-mini-player",
  standalone: true,
  imports: [],
  templateUrl: "./podcast-mini-player.component.html",
  styleUrl: "./podcast-mini-player.component.scss",
})
export class PodcastMiniPlayerComponent {
  @Input() downloadURL: string;
  @Input() fileURL: string;
  @Input() pdfURL: string;
  @Input() podcast: IResearchPodcastPopulated;

  podcastService = inject(PodcastService);
  posthogService = inject(PosthogService);

  copyShareLink(podcast) {
    this.podcastService.copyShareLinkToClipBoard(podcast);
  }

  onPlay() {
    this.posthogService.trackPodcastPlayed(
      this.podcast.companyTicker,
      this.podcast.research_date
    );
  }
}
