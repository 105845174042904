import { Component, HostBinding } from "@angular/core";

@Component({
  selector: "app-slider-element",
  standalone: true,
  imports: [],
  templateUrl: "./slider-element.component.html",
  styleUrl: "./slider-element.component.scss",
})
export class SliderElementComponent {
  @HostBinding("class") class = "slide";
}
