import { JsonPipe, NgIf, SlicePipe } from "@angular/common";
import {
  Component,
  effect,
  ElementRef,
  HostListener,
  inject,
  Input,
  signal,
  ViewChild,
} from "@angular/core";

import { TransactionService } from "~features/transactions/transaction.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-transactionbanner",
  templateUrl: "./transactionbanner.component.html",
  standalone: true,
  imports: [NgIf, SlicePipe, JsonPipe],
})
export class TransactionbannerComponent {
  @Input() mobileHeader = false;
  @Input() frontendConfig = signal(null);
  @ViewChild("bannerContainer") bannerContainer: ElementRef;

  bannerHeight = 50;
  isExpanded = false;
  isMobile = window.innerWidth < 768; // Adjust this breakpoint as needed

  transactionService = inject(TransactionService);
  transcationBannerContent = this.transactionService.getCurrentTransaction();

  constructor() {
    effect(() => {
      if (this.frontendConfig()) {
        this.transactionService.fetchTransaction(
          this.frontendConfig().transactionId
        );
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.transcationBannerContent()) {
        setTimeout(() => {
          this.adjustBannerHeight();
        }, 1);
      }
    }, enableSignalWritesInEffectContext);
  }

  @HostListener("window:resize")
  onResize() {
    this.isMobile = window.innerWidth < 768; // Adjust this breakpoint as needed
    this.adjustBannerHeight();
  }

  adjustBannerHeight() {
    if (this.bannerContainer) {
      this.bannerHeight = this.bannerContainer.nativeElement.offsetHeight;
    }
  }

  handleClick(event: MouseEvent) {
    if (this.isMobile && !this.isExpanded) {
      event.preventDefault();
      this.isExpanded = true;
      setTimeout(() => this.adjustBannerHeight(), 1);
    }
  }

  collapse() {
    this.isExpanded = false;
    setTimeout(() => this.adjustBannerHeight(), 1);
  }
}
