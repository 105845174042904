<div class="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
  <div class="mt-8 sm:mt-16 bg-blue-50 rounded-lg shadow-lg overflow-hidden">
    <div class="p-6 sm:p-10">
      <div class="text-center">
        <h2 class="text-lg sm:text-lg font-bold text-gray-900 mb-6">
          Welcome to the ResearchHub!
        </h2>
      </div>
      <div class="space-y-4 text-center text-gray-700">
        <p class="text-base sm:text-lg">
          We're thrilled to have you as a member of our community. Get ready to
          unlock a wealth of valuable information and insightful analyses and
          register to our regular events with interesting companies.
        </p>
        <p class="text-base sm:text-lg">Best regards,</p>
        <p class="text-base sm:text-lg font-semibold">The mwb research Team</p>
      </div>
      <div class="mt-8 text-center">
        <a [routerLink]="['/user/dashboard']" class="inline-block">
          <button
            class="bg-blue-research hover:bg-blue-700 text-white px-6 py-3 text-base sm:text-lg font-medium transition duration-150 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            To your Dashboard
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
