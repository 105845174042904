import { JsonPipe } from "@angular/common";
import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { main } from "@popperjs/core";

import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-research-deletion",
  standalone: true,
  imports: [
    AdminSidePanelComponent,
    BreadcrumpComponent,
    CompanySearchComponent,
    DeleteButtonComponent,
    JsonPipe,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./company-research-deletion.component.html",
  styleUrl: "./company-research-deletion.component.scss",
})
export class CompanyResearchDeletionComponent {
  companyResearchService = inject(CompanyResearchService);
  route = inject(ActivatedRoute);
  researchContextOverview =
    this.companyResearchService.getResearchContextOverview();
  researchId: WritableSignal<any | null> = signal(null);
  adminRequestService = inject(AdminRequestService);
  protected readonly main = main;

  constructor() {
    this.adminRequestService.emptyLastRequest();

    const id = this.route.snapshot.paramMap.get("id");
    this.researchId.set(id);
    this.companyResearchService.fetchContextOverview(id);
  }

  deleteResearch(id: any) {
    this.companyResearchService.deleteResearchWithContext(id);
  }
}
