<div class="container py-0 my-0 customFont">
  <div class="row-old py-0 my-0">
    <div class="mx-auto mt-2 overflow-x-auto">
      <div>
        <table class="table-auto w-full">
          <tbody>
            @if (tableLayoutPnL() !== null && tableLayoutPnL().length > 0) {
              @for (row of tableLayoutPnL()[0].data; track row) {
                <tr
                  class="hover:bg-[#00000013] font-normal text-base leading-[1.4]"
                >
                  <th
                    [attr.colspan]="row.colspan"
                    class="text-left py-1 border-t border-[#dee2e6]"
                    [ngClass]="getRowClasses(row)"
                  >
                    {{ row.key }}
                  </th>
                  @for (datapoint of row.data; track datapoint) {
                    <td
                      class="text-right py-1 border-t border-[#dee2e6]"
                      [ngClass]="getRowClasses(row)"
                    >
                      {{ datapoint }}
                    </td>
                  }
                </tr>
              }
            }
            <tr class="h-4">
              <th></th>
            </tr>
            @if (tableLayoutBS() !== null && tableLayoutBS().length > 0) {
              @for (row of tableLayoutBS()[0].data; track row) {
                <tr
                  class="hover:bg-[#00000013] font-normal text-base leading-[1.4]"
                >
                  <th
                    class="text-left py-1 border-t border-[#dee2e6]"
                    [attr.colspan]="row.colspan"
                    [ngClass]="getRowClasses(row)"
                  >
                    {{ row.key }}
                  </th>
                  @for (datapoint of row.data; track datapoint) {
                    <td
                      class="text-right py-1 border-t border-[#dee2e6]"
                      [ngClass]="getRowClasses(row)"
                    >
                      {{ datapoint }}
                    </td>
                  }
                </tr>
              }
            }
            <tr class="h-4">
              <th></th>
            </tr>
            @if (tableLayoutMult() !== null && tableLayoutMult().length > 0) {
              @for (row of tableLayoutMult()[0].data; track row) {
                <tr
                  class="hover:bg-[#00000013] font-normal text-base leading-[1.4]"
                >
                  <th
                    class="text-left py-1 border-t border-[#dee2e6]"
                    [attr.colspan]="row.colspan"
                    [ngClass]="getRowClasses(row)"
                  >
                    {{ row.key }}
                  </th>
                  @for (datapoint of row.data; track datapoint) {
                    <td
                      class="text-right py-1 border-t border-[#dee2e6]"
                      [ngClass]="getRowClasses(row)"
                    >
                      {{ datapoint }}
                    </td>
                  }
                </tr>
              }
            }
          </tbody>
        </table>
      </div>

      <p class="text-left">Source: {{ dataSource.eng }}</p>
    </div>
  </div>
</div>
