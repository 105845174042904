import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";
import { IResearchPodcast } from "~global-interfaces/IResearchPodcast";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-podcast-editor-form",
  standalone: true,
  imports: [
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: "./admin-podcast-editor-form.component.html",
  styleUrl: "./admin-podcast-editor-form.component.scss",
})
export class AdminPodcastEditorFormComponent implements OnInit {
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  @Input() multiModeCompanyName: Signal<string | null> = signal(null);
  @Input() podcast!: WritableSignal<IResearchPodcast>;
  @Input() creatorMode = false;
  @Output() formSubmit = new EventEmitter<IResearchPodcast>();
  @Input() companyTicker: WritableSignal<string | null> = signal(null);
  @Input() researchTitle: WritableSignal<string | null> = signal(null);
  @Input() research_date: WritableSignal<string | null> = signal(null);
  @Input() research_language: WritableSignal<string | null> = signal(null);
  podcastForm: FormGroup;
  isLoading = false;
  adminRequestService = inject(AdminRequestService);
  private formBuilder = inject(FormBuilder);

  constructor() {
    this.adminRequestService.emptyLastRequest();

    effect(() => {
      if (this.companyTicker()) {
        this.podcastForm.patchValue({
          companyTicker: this.companyTicker(),
          title: this.researchTitle(),
          research_date: this.research_date(),
          published_date: this.research_date(),
          upload_date: this.research_date(),
          language: this.research_language(),
        });
      }
    });
    effect(() => {
      if (this.multiModeCompanyTicker()) {
        this.podcastForm.patchValue({
          companyTicker: this.multiModeCompanyTicker(),
          title: this.researchTitle(),
          research_date: this.research_date(),
          published_date: this.research_date(),
          upload_date: this.research_date(),
          language: this.research_language(),
        });
      }
    });

    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }
  ngOnInit() {
    this.initForm();
    this.formValidity.emit(this.podcastForm.valid);
    this.formValueChange.emit(this.podcastForm.value);
    // Subscribe to form changes
    this.podcastForm.valueChanges.subscribe((values) => {
      const updatedPodcast: IResearchPodcast = {
        ...this.podcast(),
        ...values,
      };
      this.formValueChange.emit(updatedPodcast);
    });

    // Subscribe to form validity changes
    this.podcastForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  initForm() {
    this.podcastForm = this.formBuilder.group({
      published_date: ["", Validators.required],
      upload_date: ["", Validators.required],
      title: ["", [Validators.required, Validators.minLength(3)]],
      research_date: ["", Validators.required],
      companyTicker: ["", Validators.required],
      language: ["en", [Validators.required, Validators.pattern(/^(de|en)$/)]],
      downloadURL: [
        "",
        [Validators.required, Validators.pattern(/^(http|https):\/\/.+/)],
      ],
      fileStreamURL: [
        "",
        [Validators.required, Validators.pattern(/^(http|https):\/\/.+/)],
      ],
      filename: [""],
      shownName: [""],
    });

    if (this.creatorMode) {
      // Subscribe to changes on the research_date control
      this.podcastForm.get("research_date")?.valueChanges.subscribe((value) => {
        if (value) {
          this.podcastForm.get("upload_date")?.setValue(value);
          this.podcastForm.get("published_date")?.setValue(value);
        }
      });
      this.podcastForm.get("fileStreamURL")?.valueChanges.subscribe((value) => {
        if (value) {
          this.podcastForm.get("downloadURL")?.setValue(value);
        }
      });
    }
  }

  patchFormValues() {
    if (this.podcast()) {
      console.log("pathing podcast");
      this.podcastForm.patchValue(this.podcast());
    }
  }

  saveForm() {
    if (this.podcastForm.valid) {
      this.isLoading = true;
      const updatedPodcast = {
        ...this.podcast(),
        ...this.podcastForm.value,
      };
      this.formSubmit.emit(updatedPodcast);
      // Simulate async operation
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    } else {
      this.markFormGroupTouched(this.podcastForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.podcastForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters long`;
      if (control.errors["pattern"]) {
        if (controlName === "lang") return "Language must be a 2-letter code";
        if (controlName === "link")
          return "Must be a valid URL starting with http:// or https://";
      }
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
