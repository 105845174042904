import { JsonPipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-company-deletion",
  standalone: true,
  imports: [
    AdminSidePanelComponent,
    BreadcrumpComponent,
    CompanySearchComponent,
    DeleteButtonComponent,
    JsonPipe,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./company-deletion.component.html",
  styleUrl: "./company-deletion.component.scss",
})
export class CompanyDeletionComponent {
  company = {
    name: "my name",
    companyTicker: "my ticker",
    _id: "123",
    researchArticles: ["asdf"],
    valuationArticles: ["asdf"],
    events: ["asdf"],
    investorCase: { ouiahsdio: "asdasd" },
  };
  companyDataService = inject(CompanydataService);
  companyTickerService = inject(CompanyTickerService);
  route = inject(ActivatedRoute);
  companyContextOverview = this.companyDataService.getCompanyContextOverview();
  currentCompanyTicker = this.companyTickerService.getCompanyTicker();
  adminRequestService = inject(AdminRequestService);
  constructor() {
    this.adminRequestService.emptyLastRequest();

    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTickerService.setCompanyTicker(ticker);
    this.companyDataService.fetchContextOverview(ticker);
  }

  deleteCompany(ticker) {
    this.companyDataService.deleteCompanyWithContext(ticker);
    console.log("Company deleted");
  }
}
