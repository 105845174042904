@if (listOfCompanies()) {
  <input
    type="text"
    placeholder="{{ placeholderText }}"
    (input)="onSearchInput($event)"
    [cdkMenuTriggerFor]="companyMenu"
    class="px-3 py-2 text-base border border-gray-300 cursor-text w-full"
    #inputElement
    (cdkMenuOpened)="focusInput()"
  />
}

<ng-template #companyMenu>
  <div
    cdkMenu
    class="flex flex-col bg-white border border-gray-300 rounded-md py-2 max-h-[50vh] h-full overflow-auto"
  >
    @if (!emitterMode && !adminCopyMode && !isAdminDashboard) {
      <a
        class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
        cdkMenuItem
        [routerLink]="['/all-stocks']"
      >
        All Stocks
      </a>
      <hr />
    }
    @for (company of searchResults(); track company) {
      @if (emitterMode) {
        @if (freeTextMode) {
          <a
            class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            cdkMenuItem
            (click)="onSearchStringEntered(company)"
          >
            {{ company.name }}
          </a>
        } @else {
          <a
            class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            cdkMenuItem
            (click)="onCompanySelect(company)"
          >
            {{ company.name }} ({{ company.companyTicker }})
          </a>
        }
      } @else {
        @if (adminCopyMode) {
          <div class="grid grid-col-3 border-4 p-4 gap-4">
            <div class="col-span-3 font-semibold">
              <a
                class="hover:underline"
                [routerLink]="[
                  '/admin/dashboard/company/settings',
                  company.companyTicker
                ]"
              >
                {{ company.name }} ({{ company.companyTicker }})
              </a>
            </div>
            <div class="col-span-1">
              <a
                cdkMenuItem
                [routerLink]="[
                  '/admin/dashboard/company/settings',
                  company.companyTicker
                ]"
              >
                <button class="text-sm hover:underline border p-1 rounded">
                  go to settings
                </button>
              </a>
            </div>
            <div class="col-span-1">
              <button
                class="text-sm hover:underline border p-1 rounded"
                (click)="copyTextToClipboard(company.name)"
              >
                copy name
              </button>
            </div>
            <div class="col-span-1">
              <button
                class="text-sm hover:underline border p-1 rounded"
                (click)="copyTextToClipboard(company.companyTicker)"
              >
                copy ticker
              </button>
            </div>
          </div>
        } @else {
          @if (isAdminDashboard) {
            <a
              class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              cdkMenuItem
              [routerLink]="[
                '/admin/dashboard/company/settings',
                company.companyTicker
              ]"
            >
              {{ company.name }}
            </a>
          } @else {
            <a
              class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              cdkMenuItem
              [routerLink]="['/companies', company.slug]"
            >
              {{ company.name }}
            </a>
          }
        }
      }
    }
  </div>
</ng-template>
