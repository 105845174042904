import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { Observable, throwError } from "rxjs";

import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

import { AdminRequestService } from "./admin-request.service";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlingService {
  adminRequestService = inject(AdminRequestService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  latestErrorMessage: WritableSignal<any | null> = signal(null);
  constructor() {}

  handleError(error: HttpErrorResponse, context: string): Observable<never> {
    let errorMessage = `An error occurred while ${context}.`;

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    this.latestErrorMessage.set(errorMessage);
    this.adminRequestService.setLastRequestMessage(
      "Error! See bottom of the page for details."
    );
    this.loadingIndicatorService.setLastRequestMessage("Error!");
    this.loadingIndicatorService.setLastRequestSuccess(false);
    this.adminRequestService.setLastRequestSuccess(false);

    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  handleRequestError(error: any): void {
    console.error("Request error:", error);
  }

  getLatestErrorMessage() {
    return this.latestErrorMessage;
  }
}
