@if (noEventsAvailable()) {
  <div class="text-center py-8">
    <p>We are currently planning new events. Please check back later.</p>
  </div>
} @else {
  <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-32">
    <div class="col-span-1">
      <h3 class="font-bold uppercase mb-4">coming</h3>
      <div class="grid grid-cols-1 gap-y-4">
        @for (comingEvent of displayedComingEvents(); track comingEvent) {
          <app-event-grid-tile-upcoming
            [comingEvent]="comingEvent"
            [navigateToEventRegistrationPage]="navigateToEventRegistrationPage"
          ></app-event-grid-tile-upcoming>
        }
      </div>
    </div>

    <div class="mt-6 lg:mt-0 col-span-1">
      <h3 class="font-bold uppercase mb-4">Video Library</h3>
      <div class="grid grid-cols-1 gap-y-12 md:gap-y-4">
        @for (archiveEvent of displayedArchiveEvents(); track archiveEvent) {
          <app-event-grid-tile-archived
            [archiveEvent]="archiveEvent"
          ></app-event-grid-tile-archived>
        }
      </div>
    </div>
  </div>
  <div class="flex justify-center mt-8">
    @if (showMoreButton()) {
      <button
        (click)="toggleShowAllEvents()"
        class="px-4 py-2 uppercase text-gray-muted tracking-widest hover:underline"
      >
        <i class="fas fa-angle-down"></i> show all
      </button>
    }
    @if (showVideoLibraryButton()) {
      <a [routerLink]="'/events'">
        <button class="btn">Video Library</button>
      </a>
    }
  </div>
}
