import { NgModule } from "@angular/core";
import { RouteReuseStrategy, RouterModule, Routes } from "@angular/router";

import { AdminWikiPageComponent } from "~features/admin-wiki/admin-wiki-page/admin-wiki-page.component";
import { AdminAuthGuardService } from "~features/auth/admin-auth-guard.service";
import { AuthGuardService } from "~features/auth/auth-guard.service";
import { AdminCompanyCreatorComponent } from "~features/company/admin/admin-company-creator/admin-company-creator.component";
import { AdminCompanyListComponent } from "~features/company/admin/admin-company-list/admin-company-list.component";
import { CompanyDeletionComponent } from "~features/company/admin/company-deletion/company-deletion.component";
import { CompanyExchangeMappingEditorComponent } from "~features/company/admin/company-exchange-mapping/company-exchange-mapping-editor/company-exchange-mapping-editor.component";
import { CompanyInitiationNoteComponent } from "~features/company/admin/company-initiation-note/company-initiation-note/company-initiation-note.component";
import { CompanyInitiationNoteCreatorComponent } from "~features/company/admin/company-initiation-note/company-initiation-note-creator/company-initiation-note-creator.component";
import { CompanyInitiationNoteEditorComponent } from "~features/company/admin/company-initiation-note/company-initiation-note-editor/company-initiation-note-editor.component";
import { CompanyInvestmentcaseComponent } from "~features/company/admin/company-investmentcase/company-investmentcase/company-investmentcase.component";
import { CompanyInvestmentcaseCreatorComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-creator/company-investmentcase-creator.component";
import { CompanyInvestmentcaseEditorComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-editor/company-investmentcase-editor.component";
import { CompanyLeewayCreatorComponent } from "~features/company/admin/company-leeway/company-leeway-creator/company-leeway-creator.component";
import { AdminCompanyResearchCreatorComponent } from "~features/company/admin/company-research/admin-company-research-creator/admin-company-research-creator.component";
import { AdminCompanyResearchEditorComponent } from "~features/company/admin/company-research/admin-company-research-editor/admin-company-research-editor.component";
import { AdminCompanyResearchListComponent } from "~features/company/admin/company-research/admin-company-research-list/admin-company-research-list.component";
import { AdminResearchListComponent } from "~features/company/admin/company-research/admin-research-list/admin-research-list.component";
import { CompanyResearchDeletionComponent } from "~features/company/admin/company-research/company-research-deletion/company-research-deletion.component";
import { AdminCompanySettingsComponent } from "~features/company/admin/company-settings/admin-company-settings/admin-company-settings.component";
import { AdminCompanySettingsEditorComponent } from "~features/company/admin/company-settings/admin-company-settings-editor/admin-company-settings-editor.component";
import { AdminCompanySwotComponent } from "~features/company/admin/company-swot/admin-company-swot/admin-company-swot.component";
import { AdminCompanySwotCreatorComponent } from "~features/company/admin/company-swot/admin-company-swot-creator/admin-company-swot-creator.component";
import { AdminCompanySwotEditorComponent } from "~features/company/admin/company-swot/admin-company-swot-editor/admin-company-swot-editor.component";
import { CompanyValuationComponent } from "~features/company/admin/company-valuation/company-valuation/company-valuation.component";
import { CompanyValuationCreatorComponent } from "~features/company/admin/company-valuation/company-valuation-creator/company-valuation-creator.component";
import { CompanyValuationEditorComponent } from "~features/company/admin/company-valuation/company-valuation-editor/company-valuation-editor.component";
import { AdminEventComponent } from "~features/events/admin/admin-event/admin-event.component";
import { AdminEventCreatorComponent } from "~features/events/admin/admin-event-creator/admin-event-creator.component";
import { AdminEventEditorComponent } from "~features/events/admin/admin-event-editor/admin-event-editor.component";
import { AdminEventListComponent } from "~features/events/admin/admin-event-list/admin-event-list.component";
import { AdminFileManagerComponent } from "~features/file-manager/admin-file-manager/admin-file-manager.component";
import { FrontendConfigComponent } from "~features/frontend-config/admin/frontend-config/frontend-config.component";
import { FrontendConfigCreatorComponent } from "~features/frontend-config/admin/frontend-config-creator/frontend-config-creator.component";
import { FrontendConfigEditorComponent } from "~features/frontend-config/admin/frontend-config-editor/frontend-config-editor.component";
import { GuidedCompanyCreationComponent } from "~features/guided-company-creation/guided-company-creation.component";
import { GuidedResearchUploadComponent } from "~features/guided-research-upload/guided-research-upload.component";
import { AdminLegalDisclaimerCompanyCreatorComponent } from "~features/legal/admin-legal-disclaimer-company-creator/admin-legal-disclaimer-company-creator.component";
import { AdminLegalDisclaimerCompanyEditorComponent } from "~features/legal/admin-legal-disclaimer-company-editor/admin-legal-disclaimer-company-editor.component";
import { AdminLegalDisclaimerCompanyListComponent } from "~features/legal/admin-legal-disclaimer-company-list/admin-legal-disclaimer-company-list.component";
import { PdfAnalyticsOverviewComponent } from "~features/pdfAnalytics/admin/pdf-analytics-overview/pdf-analytics-overview.component";
import { AdminPodcastCreatorComponent } from "~features/podcasts/admin/admin-podcast-creator/admin-podcast-creator.component";
import { AdminPodcastEditorComponent } from "~features/podcasts/admin/admin-podcast-editor/admin-podcast-editor.component";
import { AdminPodcastListComponent } from "~features/podcasts/admin/admin-podcast-list/admin-podcast-list.component";
import { AdminRssCreatorComponent } from "~features/rss/admin/admin-rss-creator/admin-rss-creator.component";
import { AdminRssEditorComponent } from "~features/rss/admin/admin-rss-editor/admin-rss-editor.component";
import { AdminRssListComponent } from "~features/rss/admin/admin-rss-list/admin-rss-list.component";
import { AdminTransactionCreatorComponent } from "~features/transactions/admin/admin-transaction-creator/admin-transaction-creator.component";
import { AdminTransactionEditorComponent } from "~features/transactions/admin/admin-transaction-editor/admin-transaction-editor.component";
import { AdminTransactionListComponent } from "~features/transactions/admin/admin-transaction-list/admin-transaction-list.component";
import { TransactionActivationComponent } from "~features/transactions/admin/transaction-activation/transaction-activation.component";
import { AdminUserComponent } from "~features/users/admin/admin-user/admin-user.component";
import { AdminUserEditorComponent } from "~features/users/admin/admin-user-editor/admin-user-editor.component";
import { AdminUserListComponent } from "~features/users/admin/admin-user-list/admin-user-list.component";
import { AdminScreenerCreatorComponent } from "~features/weekly-screener/admin/admin-screener-creator/admin-screener-creator.component";
import { AdminScreenerEditorComponent } from "~features/weekly-screener/admin/admin-screener-editor/admin-screener-editor.component";
import { AdminScreenerListComponent } from "~features/weekly-screener/admin/admin-screener-list/admin-screener-list.component";
import { AdminDashboardComponent } from "~pages/internal/admin-dashboard/admin-dashboard.component";
import { DesignElementsComponent } from "~pages/internal/design-elements/design-elements.component";

//import { AdminPageComponent } from "~pages/internal/admin-page/admin-page.component";
// import { ReportPageComponent } from "~pages/internal/report-page/report-page.component";
import { AllStocksPageComponent } from "~pages/public/all-stocks/all-stocks-page.component";
import { CompanyPageComponent } from "~pages/public/company-page/company-page.component";
import { CompanyScreenerPageComponent } from "~pages/public/company-screener-page/company-screener-page.component";
import { CookiesDeniedPageComponent } from "~pages/public/cookies-denied-page/cookies-denied-page.component";
import { CorporateDashboardPageComponent } from "~pages/public/corporate-dashboard-page/corporate-dashboard-page.component";
import { CustomerDashboardPageComponent } from "~pages/public/customer-dashboard-page/customer-dashboard-page.component";
import { DatenschutzPageComponent } from "~pages/public/datenschutz-page/datenschutz-page.component";
import { EventRegistrationPageComponent } from "~pages/public/event-registration-page/event-registration-page.component";
import { EventsPageComponent } from "~pages/public/events-page/events-page.component";
import { ImpressumPageComponent } from "~pages/public/impressum-page/impressum-page.component";
import { LegalDisclaimerPageComponent } from "~pages/public/legal-disclaimer-page/legal-disclaimer-page.component";
import { MifidDisclaimerPageComponent } from "~pages/public/mifid-disclaimer-page/mifid-disclaimer-page.component";
import { PodcastsPageComponent } from "~pages/public/podcasts-page/podcasts-page.component";
import { RequestpasswordPageComponent } from "~pages/public/requestpassword-page/requestpassword-page.component";
import { ResetpasswordPageComponent } from "~pages/public/resetpassword-page/resetpassword-page.component";
import { SignupCompleteComponent } from "~pages/public/signup-complete/signup-complete";
import { SignupPageComponent } from "~pages/public/signup-page/signup-page.component";
import { WeeklyScreenerPageComponent } from "~pages/public/weekly-screener-page/weekly-screener-page.component";
import { WelcomePageComponent } from "~pages/public/welcome-page/welcome-page.component";
import { LegacyCompanyURLRedirectGuard } from "~shared/guards/legacyCompanyURLRedirectGuard.service";
import { breadcrumbResolver } from "~shared/resolvers/breadcrump.resolver";
import { CustomRouteReuseStategy } from "~utils/customRouterReloadStrategy.util";

const spaceForAllHeaders = 113;

const reuseComponentConfig = { shouldReuse: true };

const routes: Routes = [
  { path: "", component: WelcomePageComponent },
  { path: "welcome-page", component: WelcomePageComponent },
  // unternehmensseiten
  { path: "signup", component: SignupPageComponent },
  { path: "requestnewpassword", component: RequestpasswordPageComponent },
  { path: "setnewpassword/:token", component: ResetpasswordPageComponent },

  // this route is only here to get the guid-link from rss feeds working, which are (for whatever reason) formed like this.
  {
    path: "companies/:companyName/:notUsedRSSID",
    component: CompanyPageComponent,
    // data: reuseComponentConfig,
    //canActivate: [CompanyListGuardService], // deactivated because it czurrently would not allow direct page startup on a company page
    canActivate: [LegacyCompanyURLRedirectGuard],
  },
  {
    path: "companies/:companyName",
    component: CompanyPageComponent,
    // data: reuseComponentConfig,
    //canActivate: [CompanyListGuardService], // deactivated because it czurrently would not allow direct page startup on a company page
    canActivate: [LegacyCompanyURLRedirectGuard],
  },
  {
    // keep this route for legacy redirections -> see the legacyCompanyURLRedirectGuard
    path: "companies/:prefix/:companyName",
    component: CompanyPageComponent,
    canActivate: [LegacyCompanyURLRedirectGuard],
  },
  {
    path: "blog/weeklyScreener",
    component: WeeklyScreenerPageComponent,
    data: reuseComponentConfig,
  },

  {
    path: "company-screener",
    component: CompanyScreenerPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "all-stocks",
    component: AllStocksPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "datenschutz",
    component: DatenschutzPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "impressum",
    component: ImpressumPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "mifid2",
    component: MifidDisclaimerPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "disclaimer",
    component: LegalDisclaimerPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "cookies-denied",
    component: CookiesDeniedPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "dd",
    component: LegalDisclaimerPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "welcome-page",
    component: WelcomePageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "signupcomplete",
    component: SignupCompleteComponent,
    data: reuseComponentConfig,
  },
  // {
  //   path: "admin",
  //   component: AdminPageComponent,
  //   canActivate: [AuthGuardService, AdminAuthGuardService],
  //   data: reuseComponentConfig,
  // },
  {
    path: "admin/dashboard",
    component: AdminDashboardComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/design",
    component: DesignElementsComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/wiki",
    component: AdminWikiPageComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company",
    component: AdminCompanyListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/users",
    component: AdminUserListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/users/:userid",
    component: AdminUserComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/users/:userid/edit",
    component: AdminUserEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/events",
    component: AdminEventListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/events/:eventid",
    component: AdminEventComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/events/:eventid/edit",
    component: AdminEventEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/events-creator",
    component: AdminEventCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/pdfanalytics",
    component: PdfAnalyticsOverviewComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/legal-disclaimer",
    component: AdminLegalDisclaimerCompanyListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/legal-disclaimer/:legal-disclaimer-company-id/edit",
    component: AdminLegalDisclaimerCompanyEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/legal-disclaimer/create",
    component: AdminLegalDisclaimerCompanyCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/rss",
    component: AdminRssListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/rss/:rssid/edit",
    component: AdminRssEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/rss/create",
    component: AdminRssCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/podcast",
    component: AdminPodcastListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/podcast/:podcastid/edit",
    component: AdminPodcastEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/podcast/create",
    component: AdminPodcastCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/transaction/:transactionid/edit",
    component: AdminTransactionEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/transaction",
    component: AdminTransactionListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/transaction/create",
    component: AdminTransactionCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/transaction/:transactionid/edit",
    component: AdminTransactionEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/transaction/activation",
    component: TransactionActivationComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/file-manager",
    component: AdminFileManagerComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/creator",
    component: AdminCompanyCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/guided-research-upload",
    component: GuidedResearchUploadComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/guided-company-creation",
    component: GuidedCompanyCreationComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/research",
    component: AdminResearchListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/research/creator",
    component: AdminCompanyResearchCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/research/delete/:id",
    component: CompanyResearchDeletionComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker",
    component: AdminCompanySettingsComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings",
    redirectTo: "admin/dashboard/company",
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/edit",
    component: AdminCompanySettingsEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/delete",
    component: CompanyDeletionComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker/company-swot",
    component: AdminCompanySwotComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-swot/edit",
    component: AdminCompanySwotEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-swot/creator",
    component: AdminCompanySwotCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker/company-investmentcase",
    component: CompanyInvestmentcaseComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-investmentcase/edit",
    component: CompanyInvestmentcaseEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-investmentcase/creator",
    component: CompanyInvestmentcaseCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker/company-valuation",
    component: CompanyValuationComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-valuation/edit",
    component: CompanyValuationEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-valuation/creator",
    component: CompanyValuationCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker/company-initiation-note",
    component: CompanyInitiationNoteComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-initiation-note/edit",
    component: CompanyInitiationNoteEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/company-initiation-note/creator",
    component: CompanyInitiationNoteCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker/company-office/creator",
    component: CompanyLeewayCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/frontend-config",
    component: FrontendConfigComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/frontend-config/edit",
    component: FrontendConfigEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/frontend-config/creator",
    component: FrontendConfigCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/exchangemapping",
    component: CompanyExchangeMappingEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  {
    path: "admin/dashboard/company/settings/:companyTicker/research/list",
    component: AdminCompanyResearchListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/research",
    redirectTo: "admin/dashboard/company/settings/:companyTicker",
  },
  {
    path: "admin/dashboard/company/settings/:companyTicker/research/list/edit/:researchTitle",
    component: AdminCompanyResearchEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/weeklyscreener",
    component: AdminScreenerListComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/weeklyscreener/creator",
    component: AdminScreenerCreatorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },
  {
    path: "admin/dashboard/weeklyscreener/edit/:screenerDate",
    component: AdminScreenerEditorComponent,
    data: { breadcrumb: "placeholder" },
    resolve: { breadcrumbs: breadcrumbResolver },
    canActivate: [AuthGuardService, AdminAuthGuardService],
  },

  // {
  //   path: "reportpanel",
  //   component: ReportPageComponent,
  //   canActivate: [AuthGuardService],
  //   data: reuseComponentConfig,
  // },
  {
    path: "corporate/dashboard",
    component: CorporateDashboardPageComponent,
    data: reuseComponentConfig,
    canActivate: [AuthGuardService],
  },
  {
    path: "user/dashboard",
    component: CustomerDashboardPageComponent,
    data: reuseComponentConfig,
    canActivate: [AuthGuardService],
  },

  {
    path: "events",
    component: EventsPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "podcasts",
    component: PodcastsPageComponent,
    data: reuseComponentConfig,
  },
  {
    path: "podcasts/:companyTicker/:research_date/:language",
    component: PodcastsPageComponent,
    data: reuseComponentConfig,
  },

  {
    path: "events/registration/:date/:companyTicker",
    component: EventRegistrationPageComponent,
    data: reuseComponentConfig,
  },

  // auffangen der unbekannten seiten 404 seiten
  { path: "**", redirectTo: "/", data: reuseComponentConfig },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      anchorScrolling: "enabled",
      scrollOffset: [0, -spaceForAllHeaders],
    }),
  ],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStategy },
  ],
})
export class AppRouting {}
