import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";

import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { CreateResearchProcessService } from "~features/guided-research-creation-flow/create-research-process.service";
import { AdminSidePanelService } from "~shared/services/admin-side-panel.service";

interface ContentType {
  title: string;
  description: string;
  listRoute: string[];
  listButtonText: string;
  createRoute?: string[];
  createButtonText?: string;
}
@Component({
  selector: "app-admin-side-panel",
  standalone: true,
  imports: [RouterLink],
  templateUrl: "./admin-side-panel.component.html",
  styleUrl: "./admin-side-panel.component.scss",
})
export class AdminSidePanelComponent {
  sidePanelService = inject(AdminSidePanelService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  researchCreationProcessService = inject(CreateResearchProcessService);
  contentTypes: ContentType[] = [
    {
      title: "Companies",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["company"],
      listButtonText: "List All Companies",
      createRoute: ["guided-company-creation"],
      createButtonText: "Create a new company",
    },
    {
      title: "Research",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["research"],
      listButtonText: "List All Research",
      createRoute: ["research/creator"],
      createButtonText: "Upload Research",
    },
    {
      title: "Weekly Screeners",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["weeklyscreener"],
      listButtonText: "List All article",
      createRoute: ["weeklyscreener/creator"],
      createButtonText: "Create a new article",
    },
    {
      title: "Events",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["events"],
      listButtonText: "List All Events",
      createRoute: ["events-creator"],
      createButtonText: "Create a new event",
    },
    {
      title: "Users",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["users"],
      listButtonText: "List All Users",
      //  createRoute: ["users"],
      // createButtonText: "List All Users", // Note: This is the same as listButtonText in the original HTML
    },

    {
      title: "Transactions",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["transaction"],
      listButtonText: "List All Transactions",
      createRoute: ["transaction/create"],
      createButtonText: "Create Transactions",
    },

    {
      title: "Analytics",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["pdfanalytics"],
      listButtonText: "PDF Download Analytics",
      createRoute: [],
      createButtonText: "",
    },
    {
      title: "Legal Disclaimer",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["legal-disclaimer"],
      listButtonText: "List all ",
      createRoute: ["legal-disclaimer/create"],
      createButtonText: "Create disclaimer",
    },
    {
      title: "Exchange Mapping",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["exchangemapping"],
      listButtonText: "Edit Exchange Mapping",
      createRoute: [],
      createButtonText: "",
    },

    {
      title: "How to / Wiki",
      description: "Looking for help? Click here.",
      listRoute: ["wiki"],
      listButtonText: "workflows",
    },
    {
      title: "RSS",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["rss"],
      listButtonText: "List All RSS",
      createRoute: ["rss/create"],
      createButtonText: "Create RSS",
    },
    {
      title: "Podcast",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card's content.",
      listRoute: ["podcast"],
      listButtonText: "List All Podcasts",
      createRoute: ["podcast/create"],
      createButtonText: "Create Podcast",
    },
  ];
}
