import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { format, isValid, parse } from "date-fns";
import { IScreenerArticle } from "~global-interfaces/IScreenerArticle";

import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-screener-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EditEditorContentComponent,
    JsonPipe,
    NgClass,
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./admin-screener-editor-form.component.html",
  styleUrl: "./admin-screener-editor-form.component.scss",
})
export class AdminScreenerEditorFormComponent implements OnInit {
  @Input() screenerArticle!: Signal<IScreenerArticle>;
  @Output() formSubmit = new EventEmitter<IScreenerArticle>();

  formBuilder = inject(FormBuilder);
  screenerArticleForm: FormGroup;
  adminRequestService = inject(AdminRequestService);

  constructor() {
    this.adminRequestService.emptyLastRequest();

    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.initializeForm();
  }

  parseDate = (dateString: string) => {
    if (dateString && dateString.trim() !== "") {
      return this.parseAndFormatDate(dateString);
    }
    return format(Date.now(), "yyyy-LL-dd"); // "2024-07-18" is the correct input format, eventhough it's shown as dd.LL.yyyy in the actual input
  };
  parseAndFormatDate(dateString: string): string {
    if (!dateString) return "";

    let parsedDate: Date | null = null;

    // Try parsing as dd.MM.yyyy
    parsedDate = parse(dateString, "dd.MM.yyyy", new Date());

    // If not valid, try parsing as yyyy-MM-dd
    if (!isValid(parsedDate)) {
      parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
    }

    // If still not valid, return empty string or handle error as needed
    if (!isValid(parsedDate)) {
      console.error("Invalid date format:", dateString);
      return "";
    }

    // Format the valid date to yyyy-MM-dd
    return format(parsedDate, "yyyy-MM-dd");
  }
  initializeForm() {
    this.screenerArticleForm = this.formBuilder.group({
      published_date: ["", Validators.required],
      upload_date: ["", Validators.required],
      title: ["", [Validators.required, Validators.minLength(3)]],
      primary_language: [
        "",
        [Validators.required, Validators.pattern(/^[a-z]{2}$/)],
      ],
      teaser_text: ["", [Validators.required]],
      is_public: [false],
      content_DE: this.formBuilder.group({
        _id: [""],
        language: ["", [Validators.required, Validators.pattern(/^[a-z]{2}$/)]],
        content: ["", Validators.required],
        created_date: ["", Validators.required],
        type: [{ value: "WeeklyScreener", disabled: true }],
        is_public: [true],
      }),
    });
  }

  patchFormValues() {
    if (this.screenerArticle()) {
      const {
        published_date,
        upload_date,
        title,
        primary_language,
        is_public,
        teaser_text,
        content_DE,
      } = this.screenerArticle();

      this.screenerArticleForm.patchValue({
        published_date: this.parseDate(published_date),
        upload_date: this.parseDate(upload_date),
        teaser_text,
        title,
        primary_language,
        is_public,
        content_DE: {
          _id: content_DE["_id"] || "",
          language: content_DE["language"] || "",
          content: content_DE["content"] || "",
          created_date: this.parseDate(content_DE["created_date"]),
          type: content_DE["type"] || "WeeklyScreener",
          is_public: content_DE["is_public"] || false,
        },
      });
      this.screenerArticleForm.get("content_DE.type")?.disable();
    }
  }

  onEditorContentSave(content: string) {
    this.screenerArticleForm.get("content_DE.content")?.setValue(content);
  }

  saveForm() {
    if (this.screenerArticleForm.valid) {
      const updatedScreenerArticle: IScreenerArticle = {
        ...this.screenerArticle(),
        ...this.screenerArticleForm.value,
      };
      this.formSubmit.emit(updatedScreenerArticle);
    } else {
      this.markFormGroupTouched(this.screenerArticleForm);
    }
  }

  getErrorMessage(controlName: string, groupName: string = ""): string {
    const control = groupName
      ? this.screenerArticleForm.get(`${groupName}.${controlName}`)
      : this.screenerArticleForm.get(controlName);

    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters long`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
