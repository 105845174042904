import { Component, Input, signal, Signal } from "@angular/core";

import { CorporateEventTileComponent } from "~features/corporate-dashboard/corporate-event-tile/corporate-event-tile.component";

import { IEvent } from "~global-interfaces/IEvent";

@Component({
  selector: "app-corporate-event-grid",
  standalone: true,
  imports: [CorporateEventTileComponent],
  templateUrl: "./corporate-event-grid.component.html",
  styleUrl: "./corporate-event-grid.component.scss",
})
export class CorporateEventGridComponent {
  @Input() events: Signal<IEvent[] | null> = signal(null);
  @Input() listType: string = "";
}
