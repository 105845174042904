import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { JsonPipe, KeyValue, KeyValuePipe, NgFor } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { INVESTOR_PROFILE_MAPPING } from "~global-mappings/investorProfileMapping";

@Component({
  selector: "app-investor-profile-dialog",
  standalone: true,
  imports: [NgFor, JsonPipe, KeyValuePipe],
  templateUrl: "./investor-profile-dialog.component.html",
})
export class InvestorProfileDialogComponent {
  investorProfileMapping = INVESTOR_PROFILE_MAPPING;

  person: any;
  investorProfile: any;

  constructor(
    public dialogRef: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public data: any
  ) {
    this.person = data.data;
    this.investorProfile = data.data.investorProfile;
  }

  profileHasDetails() {
    const profileDetails = [
      "aum",
      "size",
      "holdingPeriod",
      "investmentStyle",
      "region",
      "ticketSize",
    ];
    return Object.keys(this.person.investorProfile).some(
      (r) => profileDetails.indexOf(r) >= 0
    );
  }

  keyAscOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? 1 : b.key > a.key ? -1 : 0;
  };
}
