import { inject, Injectable } from "@angular/core";

import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { formatValue } from "~utils//numberFormatting.util";

import { CompanyInfoService } from "../company/company-info.service";
import { DataServiceService } from "../data-service.service";

@Injectable({
  providedIn: "root",
})
export class Charting {
  notApplicable = 0;
  baseYear = "2023"; //set this companyWide in admin panel
  languages = ["eng", "ger"];
  chartTypes = [
    { name: "Bar-/Line-Chart", key: "TIMESERIES" },
    { name: "Pie-Chart", key: "VALUE" },
  ];
  visualizationTypes = ["GROUPEDBAR", "STACKEDBAR", "LINE", "SCATTER"];
  axisNumbers = ["1", "2"];

  defaultTemplate = {
    TIMESERIES: {
      name: "",
      customerId: "5eed24ff4fe2bd337ccab5d3",
      settings: {
        type: "TIMESERIES",
      },
      template: {
        settings: {
          language: "eng",
          numYearsFront: 9,
          numYearsBack: 9,
        },
        name: {
          ger: "",
          eng: "Profit and loss",
        },
        data: [
          {
            key: "sales",
            type: "GROUPEDBAR",
          },
          {
            key: "ebitda",
            type: "GROUPEDBAR",
          },
        ],
      },
    },
    VALUE: {
      name: "",
      customerId: "5eed24ff4fe2bd337ccab5d3",
      settings: {
        type: "VALUE",
      },
      template: {
        settings: {
          language: "eng",
          numYearsFront: 9,
          numYearsBack: 9,
        },
        name: {
          ger: "",
          eng: "Profit and loss",
        },
        data: [
          {
            key: "mc",
            type: "PIE",
          },
        ],
      },
    },
  } as any;

  defaultLayout = {
    settings: {
      language: "eng",
      numYearsFront: 9,
      numYearsBack: 9,
    },
    name: {
      ger: "",
      eng: "Profit and loss",
    },
    xAxis: ["2018", "2019", "2020", "2021"],
    data: [
      {
        key: "sales",
        data: [1, 1, 1, 1],
        type: "BAR",
      },
    ],
  } as any;

  excelService = inject(ExcelService);
  excelMapping = this.excelService.getExcelMapping();
  companySettingsService = inject(CompanydataService);
  constructor(
    private dataService: DataServiceService,
    private companyInfoService: CompanyInfoService
  ) {}

  createChartingData(
    originalTemplate: any,
    companyData: any,
    layoutWithData,
    decimals: number,
    companyId: string,
    type: string
  ) {
    if (companyData.base) {
      if (this.companySettingsService.getCurrentCompanySettings()()) {
        this.baseYear = String(
          this.companySettingsService.getCurrentCompanySettings()()?.baseyear
        );
      }

      const mapping = this.excelMapping();
      if (mapping && companyData.financial_figures) {
        //process each tabs
        const language: string = layoutWithData.settings.language;
        const backYears: number = parseInt(
          layoutWithData.settings.numYearsBack
        );
        const frontYears: number = parseInt(
          layoutWithData.settings.numYearsFront
        );
        // console.log(language, backYears, frontYears, decimals);
        //process each line in a tabs
        layoutWithData.data.forEach((dataEntry) => {
          if (typeof dataEntry["data"] === "undefined") {
            const key = dataEntry.key;
            const labelKey = dataEntry.label;
            const mappingEntry = mapping[key];
            dataEntry.key = mappingEntry[language];

            if (typeof labelKey !== "undefined") {
              const labelMapping = mapping[labelKey];
              if (labelMapping) {
                dataEntry.label = this.getValue(
                  companyData,
                  labelMapping,
                  backYears,
                  frontYears,
                  "value",
                  language,
                  decimals
                );
              }
            }

            if (dataEntry.baseyear) {
              dataEntry["data"] = this.getValueFromTimeseries(
                companyData,
                mappingEntry,
                dataEntry.baseyear,
                "value",
                language,
                decimals
              );
            } else if (type == "VALUE") {
              dataEntry["data"] = this.getValue(
                companyData,
                mappingEntry,
                backYears,
                frontYears,
                "value",
                language,
                decimals
              );
            } else if (type === "TIMESERIES") {
              dataEntry["data"] = this.getTimeseries(
                companyData,
                mappingEntry,
                backYears,
                frontYears,
                "value",
                language,
                decimals
              );
              dataEntry["xAxis"] = this.getTimeseries(
                companyData,
                mappingEntry,
                backYears,
                frontYears,
                "date",
                language,
                decimals
              );
            }
          }
        });
      }
    }
    layoutWithData.settings.originalTemplate = originalTemplate.name;
    return layoutWithData;
  }

  getValue(
    companyData,
    mappingEntry,
    backYears: number,
    frontYears: number,
    dateOrValue: string,
    language: string,
    defaultDecimals
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    const tableData = [];

    try {
      const originalData = path.split(".").reduce((o, i) => o[i], companyData);

      if (typeof originalData !== "undefined") {
        tableData.push(originalData);
      } else {
        tableData.push(this.notApplicable);
      }
    } catch (e) {}

    return tableData;
  }

  getValueFromTimeseries(
    companyData,
    mappingEntry,
    baseyear,
    dateOrValue: string,
    language: string,
    defaultDecimals: number
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    let tableData = [];

    try {
      const originalData = path.split(".").reduce((o, i) => o[i], companyData);

      if (typeof originalData !== "undefined") {
        const baseYearIndex = this.findBaseYear(baseyear, originalData);

        if (typeof baseYearIndex !== "undefined") {
          let val;
          if (dateOrValue !== "date") {
            val = formatValue(
              originalData[baseYearIndex][dateOrValue],
              mappingEntry,
              language,
              defaultDecimals
            );
          } else {
            val = originalData[baseYearIndex][dateOrValue];
          }
          tableData.push(val);
        }
        //  else {
        //   for (let i = 0; i < backYears + frontYears + 1; i++) {
        //     tableData.push(this.notApplicable);
        //   }
        // }
      } else {
        tableData = new Array(1).fill(this.notApplicable);
      }
    } catch (e) {}

    return tableData;
  }

  getTimeseries(
    companyData,
    mappingEntry,
    backYears: number,
    frontYears: number,
    dateOrValue: string,
    language: string,
    defaultDecimals: number
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    let tableData = [];

    const originalData = path.split(".").reduce((o, i) => o[i], companyData);

    if (typeof originalData !== "undefined") {
      const baseYearIndex = this.findBaseYear(this.baseYear, originalData);

      if (typeof baseYearIndex !== "undefined") {
        for (
          let i = baseYearIndex - backYears;
          i < baseYearIndex + frontYears + 1;
          i++
        ) {
          if (i > -1 && i < originalData.length) {
            let val;
            if (dateOrValue !== "date") {
              val = formatValue(
                originalData[i][dateOrValue],
                mappingEntry,
                language,
                defaultDecimals
              );
            } else {
              val = originalData[i][dateOrValue];
            }
            tableData.push(val);
          }
        }
      }
      //  else {
      //   for (let i = 0; i < backYears + frontYears + 1; i++) {
      //     tableData.push(this.notApplicable);
      //   }
      // }
    } else {
      tableData = new Array(frontYears + backYears + 1).fill(
        this.notApplicable
      );
    }

    return tableData;
  }

  findBaseYear(baseYear, data: Array<{ date: string; value: any }>): number {
    if (typeof data !== "undefined") {
      return data.findIndex((entry) => {
        return (
          entry.date
            .replace("E", "")
            .replace("e", "")
            .replace("P", "")
            .replace("p", "") == baseYear
        );
      });
    } else {
      return 0;
    }
  }
}
