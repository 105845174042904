import { DatePipe, JsonPipe, NgClass } from "@angular/common";
import { Component, computed, inject } from "@angular/core";

import { AuthService } from "~features/auth/auth.service";
import { EventService } from "~features/events/event.service";
import { formatDateNow } from "~features/events/event.utils";
import { UserNotificationService } from "~features/user-notifications/user-notification.service";

@Component({
  selector: "app-event-grid-quickmode",
  templateUrl: "./event-grid-quickmode.component.html",
  styleUrls: ["./event-grid-quickmode.component.scss"],
  standalone: true,
  imports: [NgClass, DatePipe, JsonPipe],
})
export class EventGridQuickmodeComponent {
  currentUserMail: string = "";
  mergedEventList = computed(() =>
    this.allUpcomingEvents()
      ?.filter((event) => event.is_public === true)
      .sort((eventA, eventB) => eventA.date.localeCompare(eventB.date))
      .map((eventA) => ({
        ...eventA,
        isRegistered: this.userRegisteredEvents()?.some(
          (eventB) => eventB._id === eventA._id
        ),
      }))
  );
  private authService = inject(AuthService);
  private eventService = inject(EventService);
  allUpcomingEvents = this.eventService.getEventsList();
  userRegisteredEvents = this.eventService.getUserRegisteredEvents();
  private userNotificationService = inject(UserNotificationService);

  constructor() {
    this.currentUserMail = this.authService.getCurrentUserName();
    this.eventService.fetchEventsList(undefined, formatDateNow());
    this.eventService.fetchUserRegisteredEvents();
  }

  handleSubscribeToggle(sibListId, eventTItle) {
    if (
      this.mergedEventList().find((x) => x.sibListId == sibListId).isRegistered
    ) {
      this.removeFromEvent(sibListId, eventTItle);
    } else {
      this.addToEvent(sibListId, eventTItle);
    }
  }

  addToEvent(sibListId, eventTItle) {
    this.eventService
      .registerForEvent(this.currentUserMail, sibListId)
      .subscribe((res: any) => {
        this.eventService.fetchEventsList(undefined, formatDateNow());
        this.eventService.fetchUserRegisteredEvents();
        this.userNotificationService.updateNotification(
          "Subscribed to event: " + eventTItle
        );
      });
  }

  removeFromEvent(sibListId, eventTItle) {
    this.authService
      .removeCurrentUserFromList(sibListId)
      .subscribe((res: any) => {
        this.eventService.fetchEventsList(undefined, formatDateNow());
        this.eventService.fetchUserRegisteredEvents();
        this.userNotificationService.updateNotification(
          "Unsubscribed from event: " + eventTItle
        );
      });
  }
}
