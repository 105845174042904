import { Component } from "@angular/core";

import { ArrowIconComponent } from "~shared/components/ui/arrow-icon/arrow-icon.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { LoadingIndicatorComponent } from "~shared/components/ui/loading-indicator/loading-indicator.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";
import { SliderComponent } from "~shared/components/ui/slider/slider.component";
import { SliderElementComponent } from "~shared/components/ui/slider-element/slider-element.component";
import { TabComponent } from "~shared/components/ui/tab/tab.component";
import { TabsComponent } from "~shared/components/ui/tabs/tabs.component";
import { TestChartComponent } from "~shared/components/ui/test-chart/test-chart.component";

@Component({
  selector: "app-design-elements",
  standalone: true,
  imports: [
    SliderComponent,
    PaginationComponent,
    LoadingIndicatorComponent,
    SliderElementComponent,
    TabsComponent,
    ArrowIconComponent,
    TestChartComponent,
    TabComponent,
    BreadcrumpComponent,
  ],
  templateUrl: "./design-elements.component.html",
  styleUrl: "./design-elements.component.scss",
})
export class DesignElementsComponent {}
