import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { ICompany } from "~global-interfaces/ICompany";

import { alsterAPI_URL } from "~api/api-urls";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class CompanylistService {
  http = inject(HttpClient);
  companyList: WritableSignal<ICompany[] | null> = signal(null);
  public isLoaded = signal(false);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);

  constructor() {
    this.fetchCompanyList();
  }

  fetchCompanyList() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ICompany[]>>(
        alsterAPI_URL + "companies?page=1&limit=1000"
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch company list ")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (companyList) => {
          this.companyList.set(
            companyList.sort((a, b) => a.name.localeCompare(b.name))
          );
          this.isLoaded.set(true);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch company list success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public getCompanyList() {
    return this.companyList;
  }

  getisLoading() {
    return this.isLoading;
  }
  public companyIsCovered(companyName: string): boolean {
    if (this.companyList().length > 0) {
      return this.companyList().some((el) => el.name === companyName);
    } else {
      return false;
    }
  }

  public getCompanyTickerFromSlug(slug: string): string | null {
    if (this.companyList() && this.companyList().length > 0) {
      const company = this.companyList().find((el) => el.slug === slug);
      return company ? company.companyTicker : null;
    } else {
      return null;
    }
  }

  public getCompanyNameFromSlug(slug: string): string | null {
    if (this.companyList() && this.companyList().length > 0) {
      const company = this.companyList().find((el) => el.slug === slug);

      return company ? company.name : null;
    } else {
      return null;
    }
  }

  public isCovered(companyName: string) {
    return this.companyList().some((company) => company.name === companyName);
  }
}
