@if (companiesWithEventsWithVideos()) {
  <input
    type="text"
    placeholder="FIND EVENTS"
    (input)="onSearchInput($event)"
    [cdkMenuTriggerFor]="companyMenu"
    class="px-3 py-2 text-base border border-gray-300 cursor-text w-full"
    #inputElement
    (cdkMenuOpened)="focusInput()"
  />
}

<ng-template #companyMenu>
  <div
    cdkMenu
    class="flex flex-col bg-white border border-gray-300 rounded-md py-2 max-h-[50vh] h-full overflow-auto"
  >
    @for (company of searchResults(); track company) {
      <a
        class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
        cdkMenuItem
        (click)="onSearchStringEntered(company)"
      >
        {{ company }}
      </a>
    }
  </div>
</ng-template>
