import { JsonPipe } from "@angular/common";
import { Component, Input, signal, Signal } from "@angular/core";

import { CustomerEventTileComponent } from "~features/customer-dashboard/customer-event-tile/customer-event-tile.component";

import { IEvent } from "~global-interfaces/IEvent";

@Component({
  selector: "app-customer-event-grid",
  standalone: true,
  imports: [CustomerEventTileComponent, JsonPipe],
  templateUrl: "./customer-event-grid.component.html",
  styleUrl: "./customer-event-grid.component.scss",
})
export class CustomerEventGridComponent {
  @Input() events: Signal<IEvent[] | null> = signal(null);
  @Input() listType = "";

  onEventRemoved(event: any) {
    // Handle event removal if needed
    console.log("Event removed:", event);
  }
}
