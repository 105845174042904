import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import posthog from "posthog-js";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { DialogService } from "~shared/services/dialog.service";

type CookieLevel = "anonymous" | "full";
interface ICookieData {
  accepted: boolean;
  timestamp: string;
  level: CookieLevel;
}

@Injectable({
  providedIn: "root",
})
export class CookieService {
  dialogService = inject(DialogService);
  router = inject(Router);
  navigationEnd: Observable<NavigationEnd>;
  public showCookieToast: WritableSignal<boolean> = signal(true);
  public isCookiesAccepted: WritableSignal<boolean> = signal(false);
  constructor(private GAService: GoogleAnalyticsService) {
    this.navigationEnd = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
  }

  public initPrivacyPermissionsAndCookies() {
    const cookieData: ICookieData = this.readCookie();
    if (cookieData && cookieData.accepted) {
      this.useSavedCookiePermissions(cookieData.level);
    } else {
      this.askCookiePermission();
    }
  }

  public useSavedCookiePermissions(level: CookieLevel | null = null) {
    this.setCookie(level);
    this.isCookiesAccepted.set(true);
    this.showCookieToast.set(false);
    if (level === "full") {
      this.setGAFullPermissions();
      this.setPostHogFullPermission();
    } else {
      this.setGAAnonymizedPermissions();
      this.setPostHogAnonymizedPermissions();
    }
  }

  public askCookiePermission() {
    this.isCookiesAccepted.set(false);
    this.showCookieToast.set(true);
  }

  public setAnonymousCookiePermissions() {
    this.isCookiesAccepted.set(true);
    this.showCookieToast.set(false);
    this.setGAAnonymizedPermissions();
    this.setPostHogAnonymizedPermissions();
    this.setCookie("anonymous");
  }

  public removePermissionsAndRemoveCookie(): void {
    this.removeGAFullPermissions();
    this.removePostHogFullPermissions();
    this.removeCookie();
    this.isCookiesAccepted.set(false);
    this.showCookieToast.set(true);
  }

  public setFullCookiePermissions() {
    this.setGAFullPermissions();
    this.setPostHogFullPermission();
    this.setCookie("full");
    this.isCookiesAccepted.set(true);
    this.showCookieToast.set(false);
  }

  public readCookie(): ICookieData {
    const cookiePermissionToken: string =
      localStorage.getItem("cookieSettings");
    if (cookiePermissionToken) {
      const cookiePermission = JSON.parse(cookiePermissionToken);
      if (cookiePermission.accepted) {
        return cookiePermission;
      } else return null;
    } else return null;
  }

  public identifyUser() {
    posthog.identify();
  }

  private removePostHogFullPermissions() {
    posthog.opt_out_capturing();
  }

  private setGAFullPermissions(): void {
    this.GAService.gtag("consent", "update", {
      ad_storage: "granted",
      ad_user_data: "granted",
      ad_personalization: "granted",
      analytics_storage: "granted",
    });
  }

  private setGAAnonymizedPermissions() {
    this.GAService.gtag("consent", "update", {
      ad_storage: "granted",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "granted",
    });
  }

  private setPostHogAnonymizedPermissions() {
    // posthog.set_config({
    //   persistence: "localStorage+cookie",
    // });
    //posthog.set_config({ person_profiles: "never" }); this is already covered by "identified only" global settings
  }

  private setPostHogFullPermission(): void {
    // posthog.set_config({
    //   persistence: "localStorage+cookie",
    // });
    // posthog.set_config({ person_profiles: "always" });
  }

  private setCookie(level: CookieLevel | null = null) {
    localStorage.setItem(
      "cookieSettings",
      JSON.stringify({
        accepted: true,
        timestamp: Date.now().toString(),
        level: level ? level : "anonymous",
      })
    );
  }

  private removeCookie() {
    localStorage.removeItem("cookieSettings");
  }

  private removeGAFullPermissions() {
    this.GAService.gtag("consent", "update", {
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "denied",
    });
  }
}
