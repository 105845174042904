<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Events</p>
      <p class="text-gray-600">Here you can edit a single event.</p>

      <div class="grid grid-cols-3 gap-1">
        <div class="col-span-1">
          <app-delete-button
            (deleteConfirmed)="deleteEvent(event()?._id)"
            (deleteRequested)="toggleDeletionWarning(true)"
            (deleteReset)="toggleDeletionWarning(false)"
            confirmText="Click to confirm Event Deletion for {{
              event()?.title
            }}"
            deleteText="Delete Event"
          ></app-delete-button>
        </div>
        @if (showDeletionWarning()) {
          <div class="col-span-2 border border-2 border-red-600 p-2">
            <p class="text-sm text-gray-muted">
              Event data in Brevo is <b>NOT</b> deleted/changed.
            </p>
          </div>
        }
      </div>
    </div>

    <div class="grid grid-cols-6 gap-4">
      <div class="col-span-4">
        <app-admin-event-editor-form
          (formSubmit)="onFormSubmit($event)"
          [event]="event"
        ></app-admin-event-editor-form>
      </div>
      <div class="col-span-2">
        <app-file-upload></app-file-upload>
      </div>
    </div>
  </main>
</div>
