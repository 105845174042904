<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        Edit an existing research article
      </p>
      <a
        [routerLink]="['/admin/dashboard/research/delete/', research()._id]"
        class="block max-w-52"
      >
        <button
          class="w-full px-4 py-2 bg-red-500 hover:bg-red-200 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Delete this Research
        </button>
      </a>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="grid grid-cols-7 gap-4">
      <div class="col-span-5">
        <app-admin-company-research-editor-form
          (formSubmit)="onFormSubmit($event)"
          [research]="research"
        ></app-admin-company-research-editor-form>
      </div>
      <div class="col-span-2">
        <app-file-upload></app-file-upload>
      </div>
    </div>
  </main>
</div>
