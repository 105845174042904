import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay";
import { Component, inject } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";

import { AdminCompanyListComponent } from "~features/company/admin/admin-company-list/admin-company-list.component";
import { CompanyHighlightsListComponent } from "~features/company/admin/company-highlights/company-highlights-list/company-highlights-list.component";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { CreateResearchProcessService } from "~features/guided-research-creation-flow/create-research-process.service";
import { AdminScreenerListComponent } from "~features/weekly-screener/admin/admin-screener-list/admin-screener-list.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { SliderComponent } from "~shared/components/ui/slider/slider.component";
import { SliderElementComponent } from "~shared/components/ui/slider-element/slider-element.component";
import { AdminSidePanelService } from "~shared/services/admin-side-panel.service";

interface QuickAction {
  title: string;
  route?: string[];
  buttonText: string;
  description: string;
  action?: () => void;
}

@Component({
  selector: "app-admin-dashboard",
  standalone: true,
  imports: [
    AdminCompanyListComponent,
    AdminScreenerListComponent,
    RouterLink,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    SliderComponent,
    BreadcrumpComponent,
    SliderElementComponent,
    CreationProcessMenuComponent,
    AdminSidePanelComponent,
    FormsModule,
    CompanyHighlightsListComponent,
  ],
  templateUrl: "./admin-dashboard.component.html",
  styleUrl: "./admin-dashboard.component.scss",
})
export class AdminDashboardComponent {
  creationProcessMenuService = inject(CreationProcessMenuService);
  createResearchProcessService = inject(CreateResearchProcessService);
  sidePanelService = inject(AdminSidePanelService);

  guidedActions: QuickAction[] = [
    {
      title: "Create a company",
      // action: () => this.creationProcessMenuService.toggleGuidedMenu(),
      route: ["guided-company-creation"],
      buttonText: "Create a company",
      description: "Start the guided process for creating a new company.",
    },
    {
      title: "Upload research",
      //action: () => this.createResearchProcessService.toggleGuidedMenu(),
      route: ["guided-research-upload"],
      buttonText: "Upload research",
      description: "Start the guided process for uploading new research.",
    },
  ];

  quickActions: QuickAction[] = [
    {
      title: "Create an event",
      route: ["events-creator"],
      buttonText: "Create a new event",
      description: "Create an event.",
    },
    {
      title: "Create weekly screener",
      route: ["weeklyscreener", "creator"],
      buttonText: "Create weekly screener",
      description: "Create a new weekly screener",
    },

    {
      title: "Approve a Corporate User",
      route: ["users"],
      buttonText: "Approve  User",
      description: "Approve a corporate user",
    },
    {
      title: "Upload a File",
      route: ["file-manager"],
      buttonText: "Upload a File",
      description: "Upload a file or find the URL of an already uploaded file.",
    },
    {
      title: "How to?",
      route: ["wiki"],
      buttonText: "How to do this?",
      description: "Read How-To Guides.",
    },
  ];
}
