<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">User Details</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <dl class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
        <div>
          <dt class="text-sm font-secondary text-gray-700">Username</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ user().userName }}</dd>
        </div>
        <div>
          <dt class="text-sm font-secondary text-gray-700">Name</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ user().firstName }} {{ user().lastName }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-secondary text-gray-700">Email</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ user().email }}</dd>
        </div>
        <div>
          <dt class="text-sm font-secondary text-gray-700">Customer Type</dt>
          <dd class="mt-1 text-sm text-gray-900">{{ user().customerType }}</dd>
        </div>
        <div>
          <dt class="text-sm font-secondary text-gray-700">
            Corporate Dashboard Approved
          </dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ user().isCorporateUser ? "Yes" : "No" }}
          </dd>
        </div>
        <div>
          <dt class="text-sm font-secondary text-gray-700">Company</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{
              user().companyTicker
                ? user().companyTicker
                : user().bloombergTicker
            }}
            -
            {{ user().companyName || "-" }}
          </dd>
        </div>
      </dl>

      <div class="mt-8 flex justify-end">
        <a [routerLink]="['edit']">
          <button
            class="px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Edit
          </button>
        </a>
      </div>
    </div>
  </main>
</div>
