import { JsonPipe } from "@angular/common";
import { Component, inject } from "@angular/core";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { DownloadPdfAnalyticsService } from "~features/pdfAnalytics/download-pdf-analytics.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-pdf-analytics-overview",
  standalone: true,
  imports: [
    JsonPipe,
    AdminSidePanelComponent,
    BreadcrumpComponent,
    CompanySearchComponent,
  ],
  templateUrl: "./pdf-analytics-overview.component.html",
  styleUrl: "./pdf-analytics-overview.component.scss",
})
export class PdfAnalyticsOverviewComponent {
  pdfAnalyticsService = inject(DownloadPdfAnalyticsService);
  pdfAnalytics = this.pdfAnalyticsService.getAnalytics();
  downloadPdfAnalytics() {
    this.pdfAnalyticsService.downloadPDFAnalyticsAsCSV();
  }
}
