import { Injectable, signal, WritableSignal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AdminInfoSideeffectService {
  toastTitle: WritableSignal<string | null> = signal(null);
  toastInfotext: WritableSignal<string | null> = signal(null);
  showToast: WritableSignal<boolean> = signal(false);
  allTexts = {
    valuation_companyTicker: {
      title: "Company Ticker for Valuation",
      infotext:
        "Connects valuation content to a specific company. Consult IT before changing.",
    },
    swot_companyTicker: {
      title: "Company Ticker for SWOT",
      infotext:
        "Connects investment case content to a specific company. Consult IT before changing.",
    },
    initiationnote_companyTicker: {
      title: "Company Ticker for Initiation Note",
      infotext:
        "Connects initiation note content to a specific company. Consult IT before changing.",
    },
    investmentcase_companyTicker: {
      title: "Company Ticker for Investment Case",
      infotext:
        "Connects SWOT content to a specific company. Consult IT before changing.",
    },
    research_companyTicker: {
      title: "Company Ticker for Research",
      infotext:
        "Connects research content to a specific company. Consult IT before changing.",
    },
    research_title: {
      title: "Research Title",
      infotext:
        "Connects podcast to research. Change both if modifying either.",
    },
    research_research_date: {
      title: "Research Date",
      infotext:
        "Connects podcast to research and orders research by company. Change both if modifying either.",
    },
    research_language: {
      title: "Research Language",
      infotext:
        "Connects podcast to research and orders research by language (en before de on same date). Change both if modifying either.",
    },
    company_settings_name: {
      title: "Company Settings Name",
      infotext:
        "Connects German RSS entries to Investor Hub. Change both if modifying either.",
    },
    company_settings_companyTicker: {
      title: "Company Settings Ticker",
      infotext:
        "Connects various content to a specific company. Consult IT before changing.",
    },
    company_settings_slug: {
      title: "Company Settings Slug",
      infotext:
        "Company Name in URL friendly format. (autogenerated but editable). This connects the actual company name with the link to its research-hub page. If you change this without telling IT first, this will break ALL old URLS of that company.",
    },

    event_companyName: {
      title: "Event Company Name",
      infotext:
        "Connects event to a covered company or names uncovered companies. Must match corporate user's company name. Groups events by company.",
    },
    event_companyTicker: {
      title: "Event Company Ticker",
      infotext:
        "Connects event to a covered company or names uncovered companies. Must match corporate user's company name. Groups events by company.",
    },
    rss_title: {
      title: "RSS Title",
      infotext:
        "Connects RSS entry to Investor Hub and its companies. Part before ':' must match a company name.",
    },
    sideeffectinfo: {
      title: "Side Effect Info",
      infotext: "Hover over any blue input for more details.",
    },
  };

  constructor() {}

  showInfoBanner(inputName: string) {
    // @ts-ignore
    const { title, infotext } = { ...this.getInfoText(inputName) };
    console.log(title, infotext);
    this.toastTitle.set(title);
    this.toastInfotext.set(infotext);
    this.showToast.set(true);
  }

  hideInfoBanner() {
    this.toastTitle.set(null);
    this.toastInfotext.set(this.getInfoText(null));
    this.showToast.set(false);
  }

  getInfoText(inputName: string) {
    return this.allTexts[inputName]
      ? this.allTexts[inputName]
      : { title: "Unknown Sideeffect", infotext: "no info provided" };
  }
}
