import { HttpClient } from "@angular/common/http";
import { computed, inject, Injectable, signal } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import {
  PossibleCustomerTypes,
  PossibleInvestorTypes,
  PossibleUserRoles,
} from "~global-mappings/investorProfileMapping";

import { alsterAPI_URL } from "~api/api-urls";
import { CookieService } from "~features/cookies/cookie.service";
import { NewContact, NewUser } from "~interfaces/user.model";
import { checkValueInEnum } from "~utils/general.util";

interface CurrentUser {
  userName: string;
  email: string;
  userRole: PossibleUserRoles;
  customerType?: PossibleCustomerTypes;
  isCorporateUser?: boolean;
  bloombergTicker?: string;
  company;
  companyName?: string;
  companyTicker?: string;
  rights?: any;
  adminPanel?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public temporaryEmailInput = "";
  cookieService = inject(CookieService);
  private tokenSignal = signal<string | null>(null);
  currentToken = computed(() => this.tokenSignal());
  private currentUserSignal = signal<CurrentUser | null>(null);
  public isAuthenticated = computed(() => {
    const token = this.tokenSignal();
    const user = this.currentUserSignal();
    return !!token && !!user && this.checkTokenAndRole(user);
  });

  constructor(private httpClient: HttpClient) {
    this.loadToken();
  }

  login(data: { userName: string; password: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getToken(data).then(
        (token: string) => {
          if (token) {
            this.saveToken(token);
            this.tokenSignal.set(token);
            this.decodeAndSetUser(token);
            this.cookieService.identifyUser();
            resolve(token);
          } else {
            reject("No token received");
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  logOff(): void {
    window.localStorage.removeItem("rh");
    this.tokenSignal.set(null);
    this.currentUserSignal.set(null);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }

  getCurrentUser(): CurrentUser | null {
    return this.currentUserSignal();
  }

  getCurrentUserRole(): PossibleUserRoles | null {
    return this.currentUserSignal()?.userRole ?? null;
  }

  getCurrentUserName(): string {
    return this.currentUserSignal()?.userName ?? "";
  }

  getCurrentUserEmail(): string {
    return this.currentUserSignal()?.email ?? "";
  }

  isAdmin(): boolean {
    return this.getCurrentUserRole() === PossibleUserRoles.Admin;
  }

  isCustomer(): boolean {
    const role = this.getCurrentUserRole();
    return role === PossibleUserRoles.Customer || this.isAdmin();
  }

  isContributor(): boolean {
    return this.getCurrentUserRole() === PossibleUserRoles.Contributor;
  }

  getCurrentCustomerType(): PossibleCustomerTypes | undefined {
    return this.currentUserSignal()?.customerType;
  }

  isCustomerEmittent(): boolean {
    const user = this.currentUserSignal();
    return (
      user?.customerType === PossibleCustomerTypes.Corporate &&
      user?.isCorporateUser === true
    );
  }

  // You can keep the existing isCustomerInvestor method if needed, or replace it with this new one
  isCustomerInvestor(): boolean {
    return this.isInvestor();
  }

  // Specific investor type checks
  isAssetManager(): boolean {
    return (
      this.currentUserSignal()?.customerType ===
      PossibleCustomerTypes.Investor_AssetManager_Portfolio_Manager
    );
  }

  isIndependentFinancialAdviser(): boolean {
    return (
      this.currentUserSignal()?.customerType ===
      PossibleCustomerTypes.Investor_IndependentFinancialAdviser
    );
  }

  isFamilyOffice(): boolean {
    return (
      this.currentUserSignal()?.customerType ===
      PossibleCustomerTypes.Investor_FamilyOffice
    );
  }

  isInvestor(): boolean {
    const customerType = this.currentUserSignal()?.customerType;
    if (!customerType) return false;

    switch (customerType) {
      case PossibleCustomerTypes.Investor_AssetManager_Portfolio_Manager:
      case PossibleCustomerTypes.Investor_IndependentFinancialAdviser:
      case PossibleCustomerTypes.Investor_FamilyOffice:
        return true;
      default:
        return false;
    }
  }

  getInvestorType(): PossibleInvestorTypes | null {
    const customerType = this.currentUserSignal()?.customerType;
    if (!customerType) return null;

    switch (customerType) {
      case PossibleCustomerTypes.Investor_AssetManager_Portfolio_Manager:
        return PossibleInvestorTypes.Investor_AssetManager_Portfolio_Manager;
      case PossibleCustomerTypes.Investor_IndependentFinancialAdviser:
        return PossibleInvestorTypes.Investor_IndependentFinancialAdviser;
      case PossibleCustomerTypes.Investor_FamilyOffice:
        return PossibleInvestorTypes.Investor_FamilyOffice;
      default:
        return null;
    }
  }

  canUseAdminPanel(): boolean {
    return this.isAdmin() || this.currentUserSignal()?.adminPanel === true;
  }

  getcurrentUserCompanyticker(): string {
    return this.currentUserSignal()?.companyTicker ?? "";
  }

  getcurrentUserCompanyName(): string {
    return this.currentUserSignal()?.companyName ?? "";
  }
  // special case of http request
  getCurrentUserProfileStatus() {
    const body = { usermail: this.getCurrentUserEmail() };
    return this.httpClient.post(
      alsterAPI_URL + "hubuser/checkinvestorprofile",
      body
    );
  }
  // special case of http request
  getCurrentUserInvestorProfile() {
    return this.httpClient.get(
      alsterAPI_URL +
        "hubuser/getinvestorinfo?usermail=" +
        this.getCurrentUserEmail()
    );
  }
  // special case of http request
  updateCurrentUserInvestorProfile(investorProfile: any) {
    const body = {
      usermail: this.getCurrentUserEmail(),
      investorProfile,
    };
    return this.httpClient.post(
      alsterAPI_URL +
        "hubuser/updateinvestorprofile?usermail=" +
        this.getCurrentUserEmail(),
      body
    );
  }
  // special case of http request
  getCustomAttributesForInvestorProfile(filtered: boolean) {
    if (filtered) {
      return this.httpClient.get(
        alsterAPI_URL + "hubuser/getcustomattributes?filtered=true"
      );
    } else {
      return this.httpClient.get(alsterAPI_URL + "hubuser/getcustomattributes");
    }
  }
  // special case of http request
  getCurrentUsersNewsletterStatus() {
    return this.httpClient.get(
      alsterAPI_URL +
        "hubuser/subscribednewsletters?usermail=" +
        this.getCurrentUserEmail()
    );
  }

  removeCurrentUserFromList(listId: string) {
    const body = { listId, usermail: this.getCurrentUserEmail() };
    return this.httpClient.post(alsterAPI_URL + "sib/list/removecontact", body);
  }

  addCurrentUserToList(listId: number) {
    const body = { listId, usermail: this.getCurrentUserEmail() };
    return this.httpClient.post(alsterAPI_URL + "sib/list/addcontact", body);
  }

  getNewsletterList() {
    const usermail = this.getCurrentUserEmail();
    const url = usermail
      ? `${alsterAPI_URL}sib/newsletters?usermail=${usermail}`
      : `${alsterAPI_URL}sib/newsletters`;
    return this.httpClient.get(url);
  }

  verifyToken() {
    return new Promise((resolve, reject) => {
      const body = { username: this.getCurrentUserName() };
      this.httpClient
        .post(alsterAPI_URL + "hubuser/verify", body)
        .toPromise()
        .then((res: any) => {
          const valid = res.data[0]?.validToken;
          if (res.data[0]?.updatedToken) {
            const token = res.data[0].updatedToken;
            this.saveToken(token);
            this.tokenSignal.set(token);
            this.decodeAndSetUser(token);
          }
          resolve(valid);
        });
    });
  }

  saveDisclaimerModalToken() {
    localStorage.setItem("rhmodal", JSON.stringify({ seenDisclaimer: true }));
  }

  signup(newUser: NewUser, newContact: Partial<NewContact>) {
    return new Promise((resolve, reject) => {
      const body = { newUser, newContact };
      this.httpClient
        .post(alsterAPI_URL + "hubuser/register", body)
        .toPromise()
        .then(
          (res: any) => {
            const result = {
              success: res.success,
              msg: res.message,
              emailStatus: res.data[0].emailStatus,
            };
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  requestPasswordReset(usermail: string) {
    return new Promise((resolve, reject) => {
      const body = { usermail };
      this.httpClient
        .post(alsterAPI_URL + "hubuser/requestpasswordreset", body)
        .toPromise()
        .then(
          (res: any) => {
            const result = {
              success: res.success,
              msg: res.message,
              emailStatus: res.data[0].emailStatus,
            };
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  setNewPassword(
    usermail: string,
    newPassword: string,
    passwordResetToken: string
  ) {
    return new Promise((resolve, reject) => {
      const body = {
        usermail,
        newPassword,
        passwordResetToken,
      };
      this.httpClient
        .post(alsterAPI_URL + "hubuser/resetpassword", body)
        .toPromise()
        .then(
          (res: any) => {
            const result = {
              success: res.success,
              msg: res.message,
              emailStatus: res.data[0].emailStatus,
            };
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  private loadToken() {
    const token = window.localStorage.getItem("rh");
    if (token) {
      this.tokenSignal.set(token);
      this.decodeAndSetUser(token);
    }
  }

  private decodeAndSetUser(token: string) {
    const jwtHelper = new JwtHelperService();
    try {
      const decoded = jwtHelper.decodeToken(token);
      this.currentUserSignal.set(decoded.user);
    } catch (err) {
      console.error("Error decoding token:", err);
      this.currentUserSignal.set(null);
    }
  }

  private checkTokenAndRole(user: CurrentUser): boolean {
    return checkValueInEnum(user.userRole, PossibleUserRoles);
  }

  private saveToken(receivedToken: string) {
    localStorage.setItem("rh", receivedToken);
  }

  private getToken(data: { userName: string; password: string }) {
    return new Promise<string>((resolve, reject) => {
      const body = { username: data.userName, password: data.password };
      this.httpClient
        .post(alsterAPI_URL + "hubuser/login", body)
        .toPromise()
        .then(
          (res: any) => {
            const token = res.data[0].token;
            resolve(token);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
}
