import { JsonPipe, NgClass, NgTemplateOutlet } from "@angular/common";
import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from "@angular/core";

import { TabComponent } from "~shared/components/ui/tab/tab.component";

@Component({
  selector: "app-tabs",
  standalone: true,
  imports: [NgTemplateOutlet, JsonPipe, NgClass],
  templateUrl: "./tabs.component.html",
  styleUrl: "./tabs.component.scss",
})
export class TabsComponent implements AfterContentInit {
  @Input() tabRowClasses: string = "bg-white";
  @Input() tabClasses: string = "text-gray-500";
  @Input() contentClasses: string = "";
  @Input() navClasses: string = "flex lg:space-x-4 border-b border-gray-200";
  @Input() activeTabClass: string = "bg-gray-active";
  @Input() inactiveTabClass: string = "bg-white";
  @Input() initialOpenTab: number | null = null;
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  ngAfterContentInit() {
    // Wait for tabs to be available
    if (this.tabs.length === 0) {
      this.tabs.changes.subscribe(() => {
        this.selectFirstTab();
      });
    } else {
      if (this.initialOpenTab) {
        const inititalTab = this.tabs.find(
          (element, index) => index === this.initialOpenTab
        );
        this.selectTab(inititalTab);
      } else {
        this.selectFirstTab();
      }
    }
  }

  selectFirstTab() {
    if (this.tabs.first) {
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(selectedTab: TabComponent) {
    this.tabs.forEach((tab) => (tab.active = false));
    selectedTab.active = true;
  }
}
