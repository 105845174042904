<div class="grid gap-4 grid-cols-6">
  @if (event) {
    <div
      class="col-span-12 md:col-span-4 bg-white shadow-md mb-6 p-4 rounded-lg"
    >
      <div class="col-span-12 p-2 border-b">
        <div>
          {{ event.date | date: "MMM dd, yyyy, HH:mm" }}
        </div>
        <h4 class="mt-2 text-md font-bold">{{ event.title }}</h4>
      </div>
      @if (listType !== "pastEvents") {
        <div class="col-span-12 p-2 border-b">
          @if (inviteLink) {
            <a
              class="text-blue-600 hover:underline"
              [href]="inviteLink"
              target="_blank"
            >
              <span>Invite your friends and colleagues</span>
              <i class="ml-1 fas fa-arrow-right"></i>
            </a>
            <button
              class="ml-3 px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-sm"
              (click)="copyTextToClipboard(inviteLink!)"
            >
              <svg
                class="inline-block align-text-bottom mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                />
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                />
              </svg>
              <span>Copy link</span>
            </button>
          }
        </div>
      }
      @if (listType !== "pastEvents") {
        <div class="col-span-12 p-2 border-b">
          @if (listType === "nextEvent" || listType === "upcomingEvents") {
            @if (event.meetingLink) {
              <a
                target="_blank"
                [href]="event.meetingLink"
                class="text-blue-600 hover:underline"
              >
                Join event here
                <i class="ml-1 fas fa-arrow-right"></i>
              </a>
            } @else {
              <a target="_blank" class="text-blue-600 hover:underline">
                Join event here (later)
                <i class="ml-1 fas fa-arrow-right"></i>
              </a>
            }
          }
        </div>
      }
      @if (listType === "pastEvents") {
        <div class="col-span-12 p-2 border-b">
          @if (event.presentationURL) {
            <a
              class="text-blue-600 hover:underline"
              [href]="event.presentationURL"
              target="_blank"
            >
              Download presentation
              <i class="ml-1 fas fa-arrow-right"></i>
            </a>
          } @else {
            <span class="text-gray-600">
              If the company provided a presentation, it will be uploaded here
              soon.
            </span>
          }
        </div>
      }

      @if (listType !== "pastEvents") {
        <div class="col-span-12 p-2">
          @if (!loadingUnregisteringFromEvent()) {
            <button
              class="text-blue-600 hover:underline"
              (click)="removeFromEventList(event.sibListId)"
            >
              Unregister from event
              <i class="ml-1 fas fa-arrow-right"></i>
            </button>
          } @else {
            <span class="text-gray-600">Unregistering...</span>
          }
        </div>
      }
    </div>
    <div
      class="hidden md:block col-span-12 md:col-span-2 mx-auto bg-white shadow-md mb-6 p-4 rounded-lg max-h-64 overflow-hidden w-full"
    >
      @if (listType === "pastEvents" && event.videoURL) {
        <div class="w-full">
          <app-video-player
            class="block"
            [videoURL]="event.videoURL ? event.videoURL : null"
            [openInDialog]="true"
          >
          </app-video-player>
        </div>
      } @else {
        <div class="flex justify-center items-center h-full">
          <div class="text-center">
            <div class="mb-4">
              <img
                class="mx-auto w-1/2"
                [src]="getLogoForCompanyFromEvent(event.companyTicker)"
                #companyLogo
              />
            </div>
          </div>
        </div>
      }
    </div>
  }
</div>
