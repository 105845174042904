import { Dialog } from "@angular/cdk/dialog";
import { NgOptimizedImage } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { RouterLink } from "@angular/router";

import { AuthService } from "~features/auth/auth.service";
import { SigninDialogComponent } from "~features/auth/signin-dialog/signin-dialog.component";
import { CookieService } from "~features/cookies/cookie.service";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { DialogService } from "~shared/services/dialog.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgOptimizedImage, LogoComponent],
})
export class FooterComponent implements OnInit {
  @Input() isCookiesAccepted: boolean = false;
  dialog = inject(Dialog);
  justClickedStartEditor = false;
  currentCompany = "";
  currentPageType = "";
  currentUserName = "";
  dialogService = inject(DialogService);
  isAuthenticated = this.authService.isAuthenticated;

  constructor(
    private authService: AuthService,
    private cookieService: CookieService
  ) {
    this.currentUserName = this.authService.getCurrentUserName();
  }

  public ngOnInit(): void {
    this.isCookiesAccepted = this.cookieService.checkCookiePermission();
  }

  openLoginDialog(): void {
    const dialogRef = this.dialogService.open(SigninDialogComponent, {
      /* login data */
    });

    dialogRef.closed.subscribe((result) => {
      // console.log("Login dialog closed", result);
    });
  }

  public revokeCookieConsent(): void {
    this.cookieService.clearCookiePermission();
  }
}
