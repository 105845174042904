import { Component, Input } from "@angular/core";

@Component({
  selector: "app-youtube-icon",
  standalone: true,
  imports: [],
  templateUrl: "./youtube-icon.component.html",
  styleUrl: "./youtube-icon.component.scss",
})
export class YoutubeIconComponent {
  @Input() color: string = "red";
  @Input() size: string = "1em";
}
