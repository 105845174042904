import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class CanonicalLinkService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2, private router: Router) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  updateCanonicalUrl() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const canonicalUrl = this.getCanonicalUrl();
        console.log(canonicalUrl);
        this.setCanonicalUrl(canonicalUrl);
      });
  }

  private getCanonicalUrl(): string {
    return window.location.origin + this.router.url;
  }

  private setCanonicalUrl(url: string) {
    let link: HTMLLinkElement =
      document.querySelector('link[rel="canonical"]') || null;
    if (!link) {
      link = this.renderer.createElement("link");
      this.renderer.setAttribute(link, "rel", "canonical");
      this.renderer.appendChild(document.head, link);
    }
    this.renderer.setAttribute(link, "href", url);
  }
}
