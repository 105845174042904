import { Clipboard } from "@angular/cdk/clipboard";
import { inject, Injectable } from "@angular/core";

import { UserNotificationService } from "~features/user-notifications/user-notification.service";
import { PosthogService } from "~shared/services/posthog.service";

@Injectable({
  providedIn: "root",
})
export class ClipboardService {
  clipboard = inject(Clipboard);
  userNotificationService = inject(UserNotificationService);
  posthogService = inject(PosthogService);
  constructor() {}

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this.userNotificationService.updateNotification("Link copied to clipboard");
    this.posthogService.trackLinkCopied(text);
  }
}
