import { Clipboard } from "@angular/cdk/clipboard";
import { AsyncPipe } from "@angular/common";
import { Component, inject, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";

import { timeZoneFormat } from "~features/events/event.utils";
import { FileService } from "~features/file-manager/file.service";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";
import { ClipboardService } from "~shared/services/clipboard.service";

import { FileMetadataComponent } from "../file-metadata/file-metadata.component";

@Component({
  selector: "app-file-list",
  templateUrl: "./file-list.component.html",
  styleUrls: ["./file-list.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FileMetadataComponent,
    PaginationComponent,
    AsyncPipe,
  ],
})
export class FileListComponent implements OnInit {
  fileList$: Observable<any>;
  searchTerm$ = new BehaviorSubject<string>("");
  currentPage$ = new BehaviorSubject<number>(1);
  pageSize = 50;
  totalFiles = 0;
  isLoading$ = new BehaviorSubject<boolean>(false);
  isSyncing$ = new BehaviorSubject<boolean>(false);

  fileMetadata$ = new BehaviorSubject<{ [filename: string]: any }>({});

  searchOptions = {
    pdf: false,
    image: false,
  };
  clipboardService = inject(ClipboardService);
  protected readonly timeZoneFormat = timeZoneFormat;

  constructor(private fileService: FileService, private clipboard: Clipboard) {}

  ngOnInit() {
    this.getFileList();
  }

  getFileList() {
    this.fileList$ = this.searchTerm$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term: string) =>
        this.currentPage$.pipe(
          switchMap((page: number) => {
            this.isLoading$.next(true);
            return this.fileService
              .getFileList(page, this.pageSize, term, this.searchOptions)
              .pipe(
                tap(() => {
                  this.isLoading$.next(false);
                })
              );
          })
        )
      ),
      tap((response: any) => {
        this.totalFiles = response.totalFiles;
      })
    );
  }

  onPageChange(page: number) {
    this.currentPage$.next(page);
  }

  onSearchOptionsChange() {
    this.currentPage$.next(1);
    this.getFileList();
  }

  onPageSizeChange() {
    this.currentPage$.next(1);
    this.getFileList();
  }

  searchFiles(term: string) {
    this.currentPage$.next(1);
    this.searchTerm$.next(term);
  }

  syncFileList() {
    this.isSyncing$.next(true);
    this.fileService.syncFileList().subscribe(() => {
      this.searchTerm$.next("");
      this.currentPage$.next(1);
      this.isSyncing$.next(false);
    });
  }

  onFileClick(filename: string) {
    const currentMetadata = this.fileMetadata$.getValue();
    this.isLoading$.next(true);
    if (!currentMetadata[filename]) {
      this.fileService.getFileMetadata(filename).subscribe(
        (metadata: any) => {
          this.fileMetadata$.next({ ...currentMetadata, [filename]: metadata });
          this.isLoading$.next(false);
        },
        (error: any) => {
          console.error("Error fetching file metadata:", error);
        }
      );
    }
    this.isLoading$.next(false);
  }

  copyTextToClipboard(textToCopy: string) {
    this.clipboardService.copyToClipboard(textToCopy);
  }
}
