<div class="relative">
  <div class="flex items-center">
    <input
      (click)="togglePicker()"
      [value]="selectedDate + ' ' + selectedTime"
      class="w-full px-4 py-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer"
      placeholder="Select date and time"
      readonly
      type="text"
    />
    <svg
      class="absolute left-3 w-5 h-5 text-gray-400 pointer-events-none"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clip-rule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill-rule="evenodd"
      />
    </svg>
  </div>
  @if (showPicker) {
    <div
      class="absolute mt-1 bg-white border border-gray-300 rounded-md shadow-lg"
    >
      <div class="p-4">
        <div class="mb-4">
          <label class="block mb-1 font-medium text-gray-700" for="datePicker"
            >Date:</label
          >
          <input
            (input)="onDateChange($any($event.target).value)"
            [value]="selectedDate"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="datePicker"
            type="date"
          />
        </div>
        <div>
          <label class="block mb-1 font-medium text-gray-700" for="timePicker"
            >Time:</label
          >
          <input
            (input)="onTimeChange($any($event.target).value)"
            [value]="selectedTime"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            id="timePicker"
            type="time"
          />
        </div>
        <div class="mt-4 text-right">
          <button
            (click)="confirmSelection()"
            class="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  }
</div>
