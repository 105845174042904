import { DatePipe, JsonPipe } from "@angular/common";
import { Component, inject, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";

import { WeeklyScreenerService } from "~features/weekly-screener/weekly-screener.service";
import { DateComponent } from "~shared/components/ui/date/date.component";

@Component({
  selector: "app-weekly-screener-teaser-list",
  standalone: true,
  imports: [JsonPipe, RouterLink, DatePipe, DateComponent],
  templateUrl: "./weekly-screener-teaser-list.component.html",
  styleUrl: "./weekly-screener-teaser-list.component.scss",
})
export class WeeklyScreenerTeaserListComponent {
  weekylScreenerService = inject(WeeklyScreenerService);
  weeklyScreenerTeasers: WritableSignal<any> = this.weekylScreenerService.items;
  maxTeaserIndex = 4; // 4 teasers are shown 0 to 3

  constructor() {
    this.weekylScreenerService.fetchOverviewListAlltWeeklyScreeners({
      limit: this.maxTeaserIndex,
      page: 1,
    });
  }
}
