import { CdkAccordionModule } from "@angular/cdk/accordion";
import { NgClass } from "@angular/common";
import {
  Component,
  computed,
  inject,
  Input,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import { IResearch } from "~global-interfaces/IResearch";
import { IResearchOverviewItem } from "~global-interfaces/IResearchOverviewItem";

import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CorporateResearchService } from "~features/corporate-dashboard/corporate-research.service";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { ButtonDownloadPdfComponent } from "~features/pdfAnalytics/button-download-pdf/button-download-pdf.component";
import { DateComponent } from "~shared/components/ui/date/date.component";
import { INITIAL_RESEARCH } from "~shared/initial-values";

@Component({
  selector: "app-research-accordion",
  standalone: true,
  imports: [
    CdkAccordionModule,
    DisplayEditorContentComponent,
    DateComponent,
    NgClass,
    ButtonDownloadPdfComponent,
  ],
  templateUrl: "./research-accordion.component.html",
  styleUrl: "./research-accordion.component.scss",
})
export class ResearchAccordionComponent {
  @Input() researchOverviewList: Signal<IResearchOverviewItem[] | null> =
    signal(null);
  @Input() corporateMode = false;
  @Input() corporateTicker = null;
  currentResearchArticle: WritableSignal<IResearch> = signal(INITIAL_RESEARCH);
  expandedIndex = 0;
  companyResearchService = inject(CompanyResearchService);
  companyTickerService = inject(CompanyTickerService);
  corporateResearchService = inject(CorporateResearchService);
  showAllItems = signal(false);

  displayedItems = computed(() => {
    const items = this.researchOverviewList() || [];
    return this.showAllItems() ? items : items.slice(0, 6);
  });

  remainingItemsCount = computed(() => {
    const totalItems = (this.researchOverviewList() || []).length;
    return Math.max(totalItems - 6, 0);
  });

  loadResearchArticle(researchTitle: string) {
    this.companyResearchService.fetchResearchArticleForAccordion(
      this.companyTickerService.getCompanyTicker()(),
      researchTitle
    );
    this.currentResearchArticle =
      this.companyResearchService.getActiveAccordionResearchArticle();
  }

  handleCollapseClick(researchTitle: string) {
    if (this.corporateMode) {
      this.loadResearchArticleForCorporate(researchTitle);
    } else {
      this.loadResearchArticle(researchTitle);
    }
  }

  loadResearchArticleForCorporate(researchTitle: string) {
    this.corporateResearchService.fetchResearchArticleForAccordion(
      this.corporateTicker,
      researchTitle
    );
    this.currentResearchArticle =
      this.corporateResearchService.getActiveAccordionResearchArticle();
  }

  toggleShowAll() {
    this.showAllItems.update((current) => !current);
  }
}
