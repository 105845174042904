import { Component, Input, signal, Signal } from "@angular/core";
import { IResearchPodcastPopulated } from "~global-interfaces/IResearchPodcast";

import { PodcastListItemComponent } from "~features/podcasts/podcast-list-item/podcast-list-item.component";

@Component({
  selector: "app-podcast-list",
  standalone: true,
  imports: [PodcastListItemComponent],
  templateUrl: "./podcast-list.component.html",
  styleUrl: "./podcast-list.component.scss",
})
export class PodcastListComponent {
  @Input() podcastList: Signal<IResearchPodcastPopulated[] | null> =
    signal(null);
}
