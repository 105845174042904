import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  WritableSignal,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IFrontendConfig } from "~global-interfaces/IFrontendConfig";

import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-frontend-config-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./frontend-config-editor-form.component.html",
  styleUrl: "./frontend-config-editor-form.component.scss",
})
export class FrontendConfigEditorFormComponent implements OnInit {
  @Input() frontendConfig!: WritableSignal<IFrontendConfig>;
  @Output() formSubmit = new EventEmitter<IFrontendConfig>();

  frontendConfigForm: FormGroup;
  formBuilder = inject(FormBuilder);
  adminRequestService = inject(AdminRequestService);

  constructor() {
    this.adminRequestService.emptyLastRequest();
    effect(() => {
      this.patchFormValues();
    });
  }

  get renamedCompanies(): FormArray {
    return this.frontendConfigForm.get("renamedCompanies") as FormArray;
  }

  get hiddenFromSliderCompanyTickers(): FormArray {
    return this.frontendConfigForm.get(
      "hiddenFromSliderCompanyTickers"
    ) as FormArray;
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.frontendConfigForm = this.formBuilder.group({
      activeTransaction: [false],
      transactionId: [""],
      renamedCompanies: this.formBuilder.array([]),
      hiddenFromSliderCompanyTickers: this.formBuilder.array([]),
    });
    this.patchFormValues();
  }

  patchFormValues() {
    if (this.frontendConfig()) {
      this.frontendConfigForm.patchValue({
        activeTransaction: this.frontendConfig().activeTransaction,
        transactionId: this.frontendConfig().transactionId,
      });

      // Clear and repopulate renamedCompanies
      this.renamedCompanies.clear();
      this.frontendConfig().renamedCompanies?.forEach((company) => {
        this.renamedCompanies.push(
          this.formBuilder.group({
            oldName: [company.oldName, Validators.required],
            newName: [company.newName, Validators.required],
          })
        );
      });

      // Clear and repopulate hiddenFromSliderCompanyTickers
      this.hiddenFromSliderCompanyTickers.clear();
      this.frontendConfig().hiddenFromSliderCompanyTickers?.forEach(
        (ticker) => {
          this.hiddenFromSliderCompanyTickers.push(
            this.formBuilder.control(ticker, Validators.required)
          );
        }
      );
    }
  }

  addRenamedCompany() {
    this.renamedCompanies.push(
      this.formBuilder.group({
        oldName: ["", Validators.required],
        newName: ["", Validators.required],
      })
    );
  }

  removeRenamedCompany(index: number) {
    this.renamedCompanies.removeAt(index);
  }

  addHiddenCompanyTicker() {
    this.hiddenFromSliderCompanyTickers.push(
      this.formBuilder.control("", Validators.required)
    );
  }

  removeHiddenCompanyTicker(index: number) {
    this.hiddenFromSliderCompanyTickers.removeAt(index);
  }

  saveForm() {
    if (this.frontendConfigForm.valid) {
      const updatedFrontendConfig: IFrontendConfig = {
        ...this.frontendConfig(),
        ...this.frontendConfigForm.value,
      };
      this.formSubmit.emit(updatedFrontendConfig);
    } else {
      this.markFormGroupTouched(this.frontendConfigForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.frontendConfigForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
    }
    return "";
  }

  isInvalidAndTouched(controlName: string): boolean {
    const control = this.frontendConfigForm.get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
