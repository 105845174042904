<div class="min-w-[42rem]">
  <div class="w-full">
    <p class="text-lg uppercase tracking-widest text-white">
      Major Shareholders
    </p>
  </div>
  <div>
    <app-template-chart
      [baseyear]="data.data.baseyear"
      [companyData]="data.data.companyNumbers"
      [config]="config"
      [noSliders]="true"
      class="full-width tile-component text-center"
    ></app-template-chart>
  </div>
</div>
