<form
  [formGroup]="researchForm"
  class="mx-auto bg-white shadow-md rounded-lg p-8"
>
  @if (!multiMode) {
    <div class="w-full border p-8 shadow mb-8 bg-yellow-100">
      <h5>
        Before you start: Select the company you want to upload research for
      </h5>
      <app-company-search
        (companySelected)="onCompanySelected($event)"
        [emitterMode]="true"
        [isAdminDashboard]="true"
        class="w-full"
      ></app-company-search>
    </div>
  }

  <div class="grid grid-cols-1 mb-8">
    <div>
      <label
        class="block text-sm font-secondary text-gray-700 mb-2"
        for="companyTicker"
        >Company Ticker</label
      >

      <input
        [hasSideeffect]="true"
        [inputName]="'research_companyTicker'"
        [ngClass]="{
          'border-red-500':
            researchForm.get('companyTicker')?.invalid &&
            researchForm.get('companyTicker')?.touched
        }"
        appAdminInfoHasSideeffect
        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
        formControlName="companyTicker"
        id="companyTicker"
        type="text"
      />
    </div>
    @if (
      researchForm.get("companyTicker")?.invalid &&
      researchForm.get("companyTicker")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("companyTicker") }}
      </p>
    }
  </div>

  <div class="mb-8">
    <label class="block text-sm font-secondary text-gray-700 mb-2" for="title"
      >Title</label
    >
    <input
      [hasSideeffect]="true"
      [inputName]="'research_title'"
      [ngClass]="{
        'border-red-500':
          researchForm.get('title')?.invalid &&
          researchForm.get('title')?.touched
      }"
      appAdminInfoHasSideeffect
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="title"
      id="title"
      type="text"
    />
    @if (
      researchForm.get("title")?.invalid && researchForm.get("title")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">{{ getErrorMessage("title") }}</p>
    }
  </div>

  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
    <div>
      <label
        class="block text-sm font-secondary text-gray-700 mb-2"
        for="published_date"
        >Published Date</label
      >
      <input
        [ngClass]="{
          'border-red-500':
            researchForm.get('published_date')?.invalid &&
            researchForm.get('published_date')?.touched
        }"
        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
        formControlName="published_date"
        id="published_date"
        type="date"
      />
      @if (
        researchForm.get("published_date")?.invalid &&
        researchForm.get("published_date")?.touched
      ) {
        <p class="mt-1 text-xs text-red-500">
          {{ getErrorMessage("published_date") }}
        </p>
      }
    </div>

    <div>
      <label
        class="block text-sm font-secondary text-gray-700 mb-2"
        for="research_date"
        >Research Date</label
      >
      <input
        [hasSideeffect]="true"
        [inputName]="'research_research_date'"
        [ngClass]="{
          'border-red-500':
            researchForm.get('research_date')?.invalid &&
            researchForm.get('research_date')?.touched
        }"
        appAdminInfoHasSideeffect
        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
        formControlName="research_date"
        id="research_date"
        type="date"
      />
      @if (
        researchForm.get("research_date")?.invalid &&
        researchForm.get("research_date")?.touched
      ) {
        <p class="mt-1 text-xs text-red-500">
          {{ getErrorMessage("research_date") }}
        </p>
      }
    </div>
    <div class="mb-6">
      <label class="block text-sm font-medium">Language:</label>
      <select
        [hasSideeffect]="true"
        [inputName]="'research_language'"
        appAdminInfoHasSideeffect
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
        formControlName="primary_language"
      >
        @for (language of possiblePrimaryLanguage; track language) {
          <option [value]="language">{{ language }}</option>
        }
      </select>
    </div>
  </div>

  <div class="bg-gray-50 rounded-lg p-6 mb-8" formGroupName="pdf_DE">
    <h3 class="text-lg font-secondary text-gray-900 mb-4">Research PDF</h3>
    <div class="grid grid-cols-1">
      <div>
        <label
          [ngClass]="{
            'border-red-500':
              researchForm.get('pdf_DE.downloadURL')?.invalid &&
              researchForm.get('pdf_DE.downloadURL')?.touched
          }"
          class="block text-sm font-secondary text-gray-700 mb-2"
          for="pdf_DE.downloadURL"
          >Download URL</label
        >
        <input
          class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          formControlName="downloadURL"
          id="pdf_DE.downloadURL"
          type="text"
        />
        @if (
          researchForm.get("pdf_DE.downloadURL")?.invalid &&
          researchForm.get("pdf_DE.downloadURL")?.touched
        ) {
          <p class="mt-1 text-xs text-red-500">
            {{ getErrorMessage("pdf_DE.downloadURL") }}
          </p>
        }
      </div>
    </div>
  </div>

  <div class="bg-gray-50 rounded-lg p-6 mb-8" formGroupName="content_DE">
    <h3 class="text-lg font-secondary text-gray-900 mb-4">Editor Content</h3>
    <div>
      <app-edit-editor-content
        (contentSave)="onEditorContentSave($event)"
        [content]="researchForm.get('content_DE.content')?.value"
      ></app-edit-editor-content>
    </div>
  </div>

  @if (!multiMode) {
    <div class="mt-8">
      <app-admin-form-button
        (click)="saveForm()"
        [class]="'admin-form-button'"
        [disabled]="!researchForm.valid"
      >
      </app-admin-form-button>
    </div>
    <p class="text-gray-muted">
      You may need to reload the page after saving to see all changes.
    </p>

    <app-admin-form-request-message></app-admin-form-request-message>
  }
</form>
