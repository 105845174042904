import { Injectable, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AdminSidePanelService {
  private isOpenSignal = signal(true);

  isOpen() {
    return this.isOpenSignal.asReadonly();
  }

  toggle() {
    this.isOpenSignal.update((value) => !value);
  }
}
