@if (showToast()) {
  <div class="fixed top-1/2 flex flex-col items-center z-50">
    <div
      class="bg-yellow-200 border-4 border-yellow-400 rounded-lg p-4 mt-2 w-56 text-center"
    >
      <p class="font-semibold">{{ inputTitle() }}</p>
      <p>{{ infoText() }}</p>
    </div>
  </div>
}
