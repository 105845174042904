<div
  class="flex flex-col lg:flex-row items-start gap-4 p-4 bg-white shadow-md rounded-lg w-full"
>
  <div class="flex flex-col flex-1 gap-2">
    <div class="flex justify-between text-gray-700">
      <span class="text-lg font-semibold">{{
        podcast.companyDetails.name //.slice(0, 18)
      }}</span
      ><span class="text-xs">{{
        podcast.research_date | date: "dd/MM/yyyy"
      }}</span>
    </div>
    @if (podcast.companyDetails.imageURL) {
      <img
        [src]="podcast.companyDetails.imageURL"
        alt="{{ podcast.companyDetails.name }}"
        class="h-24 w-72 lg:h-32 lg:w-96 object-cover rounded-lg"
      />
    } @else {
      <div
        class="h-24 w-72 lg:h-32 lg:w-96 object-cover rounded-lg bg-blue-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
          class="max-h-full mx-auto"
        >
          <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
          <path
            d="M319.4 372c48.5-31.3 80.6-85.9 80.6-148c0-97.2-78.8-176-176-176S48 126.8 48 224c0 62.1 32.1 116.6 80.6 148c1.2 17.3 4 38 7.2 57.1l.2 1C56 395.8 0 316.5 0 224C0 100.3 100.3 0 224 0S448 100.3 448 224c0 92.5-56 171.9-136 206.1l.2-1.1c3.1-19.2 6-39.8 7.2-57zm-2.3-38.1c-1.6-5.7-3.9-11.1-7-16.2c-5.8-9.7-13.5-17-21.9-22.4c19.5-17.6 31.8-43 31.8-71.3c0-53-43-96-96-96s-96 43-96 96c0 28.3 12.3 53.8 31.8 71.3c-8.4 5.4-16.1 12.7-21.9 22.4c-3.1 5.1-5.4 10.5-7 16.2C99.8 307.5 80 268 80 224c0-79.5 64.5-144 144-144s144 64.5 144 144c0 44-19.8 83.5-50.9 109.9zM224 312c32.9 0 64 8.6 64 43.8c0 33-12.9 104.1-20.6 132.9c-5.1 19-24.5 23.4-43.4 23.4s-38.2-4.4-43.4-23.4c-7.8-28.5-20.6-99.7-20.6-132.8c0-35.1 31.1-43.8 64-43.8zm0-144a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
          />
        </svg>
      </div>
    }
    <p class="text-base font-medium text-gray-600">
      {{ podcast.title }}
    </p>
    <div class="flex justify-center mt-2 w-full">
      <app-podcast-mini-player
        [downloadURL]="podcast.downloadURL"
        [fileURL]="podcast.fileStreamURL"
        [pdfURL]="podcast.researchDetails.pdfDetails.downloadURL"
        [podcast]="podcast"
        class="w-full"
      ></app-podcast-mini-player>
    </div>
  </div>
</div>
