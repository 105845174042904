import { JsonPipe } from "@angular/common";
import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IResearchPodcast } from "~global-interfaces/IResearchPodcast";

import { AdminPodcastEditorFormComponent } from "~features/podcasts/admin/admin-podcast-editor-form/admin-podcast-editor-form.component";
import { PodcastService } from "~features/podcasts/podcast.service";
import { AdminRssEditorFormComponent } from "~features/rss/admin/admin-rss-editor-form/admin-rss-editor-form.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-podcast-editor",
  standalone: true,
  imports: [
    AdminRssEditorFormComponent,
    AdminSidePanelComponent,
    BreadcrumpComponent,
    JsonPipe,
    AdminPodcastEditorFormComponent,
  ],
  templateUrl: "./admin-podcast-editor.component.html",
  styleUrl: "./admin-podcast-editor.component.scss",
})
export class AdminPodcastEditorComponent {
  podcastService = inject(PodcastService);
  podcast: WritableSignal<IResearchPodcast | null> =
    signal<IResearchPodcast | null>(null);

  constructor(private route: ActivatedRoute) {
    const podcastId = this.route.snapshot.paramMap.get("podcastid") ?? "";
    this.podcastService
      .fetchPodcastById(podcastId)
      .subscribe((podcast: IResearchPodcast) => {
        this.podcast.set(podcast);
      });
  }

  onFormSubmit(newData: IResearchPodcast) {
    // console.log(newData);
    this.podcastService
      .updatePodcast(newData)
      .subscribe((updatedPodcast: IResearchPodcast) => {
        this.podcast.set(updatedPodcast);
      });
  }
}
