import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { IResearch } from "~global-interfaces/IResearch";
import { IResearchOverviewItem } from "~global-interfaces/IResearchOverviewItem";

import { alsterAPI_URL } from "~api/api-urls";
import { CompanylistService } from "~features/company/companylist.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { INITIAL_RESEARCH } from "~shared/initial-values";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class CorporateResearchService extends PaginationService<any> {
  companyListService = inject(CompanylistService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private corporateCompanyName: WritableSignal<string> = signal<string>("");
  private corporateCompanyticker: WritableSignal<string> = signal<string>("");
  private companyList = this.companyListService.getCompanyList();
  private isLoading = signal(false);
  private activeAccordionResearchArticle: WritableSignal<IResearch> =
    signal(INITIAL_RESEARCH);

  constructor(http: HttpClient) {
    super(http);

    effect(() => {
      const companyTicker = this.corporateCompanyticker();
      if (this.companyList() && companyTicker) {
        this.fetchResearchOverview(companyTicker);
      }
    }, enableSignalWritesInEffectContext);
  }

  public fetchResearchOverview(
    companyTicker: string,
    paginationParams: IQueryParams = { page: 1, limit: 200 } // limit is set to 200 to load all
  ) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL + "research/list/" + companyTicker,
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }

  public getResearchOverview() {
    return this.items as WritableSignal<IResearchOverviewItem[]>;
  }

  public getCorporateCompanyName() {
    return this.corporateCompanyName;
  }

  public getCorporateCompanyticker() {
    return this.corporateCompanyticker;
  }

  public setCorporateCompanyName(companyName: string) {
    this.corporateCompanyName.set(companyName);
  }

  public setCorporateCompanyticker(companyticker: string) {
    this.corporateCompanyticker.set(companyticker);
  }

  public getActiveAccordionResearchArticle() {
    return this.activeAccordionResearchArticle;
  }

  public fetchResearchArticleForAccordion(
    companyTicker: string,
    researchTitle: string
  ) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IResearch>>(
        alsterAPI_URL +
          "research/find/" +
          companyTicker +
          "/" +
          encodeURIComponent(researchTitle)
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch researcha rticle for accordion"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (researchData) => {
          this.activeAccordionResearchArticle.update(() => ({
            ...researchData,
          }));
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch research for accordeon success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}
