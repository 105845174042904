<div class="w-full h-full flex flex-col justify-end">
  <table class="w-full table-auto text-base leading-none">
    @if (!valueTable) {
      <thead>
        <tr class="xborder-b">
          <th class="text-left py-2 font-normal">
            in {{ companyData().base?.quote_curr || companyData().base?.curr }}m
          </th>
          @for (date of dates(); track date) {
            <th class="text-right py-2">
              <b>{{ date }}</b>
            </th>
          }
          <th class="text-right py-2">
            <b>yoy chg.</b>
          </th>
        </tr>
      </thead>
    }
    @if (dataReady()) {
      <tbody>
        @for (row of data(); track row; let i = $index) {
          <tr class="hover:text-white hover:bg-[#212529] hover:bg-opacity-10">
            <td class="text-left py-2 text-[#e2e8f0]">{{ row.title }}</td>
            @for (datapoint of row.value; track datapoint) {
              <td class="text-right py-2">
                <span [class.text-red-500]="datapoint.includes('-')">{{
                  datapoint
                }}</span>
                @if (row.valueModifier) {
                  <span class="ml-1">{{
                    row?.valueModifier | number: "1.1-1"
                  }}</span>
                }
              </td>
            }
          </tr>
        }
      </tbody>
    }
  </table>
</div>
