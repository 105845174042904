import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { Router } from "@angular/router";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { ICompany } from "~global-interfaces/ICompany";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { IResearch } from "~global-interfaces/IResearch";
import { IResearchOverviewItem } from "~global-interfaces/IResearchOverviewItem";

import { alsterAPI_URL } from "~api/api-urls";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanylistService } from "~features/company/companylist.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { INITIAL_RESEARCH } from "~shared/initial-values";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class CompanyResearchService extends PaginationService<any> {
  loadingIndicatorService = inject(LoadingIndicatorService);
  researchContextOverview: WritableSignal<any | null> = signal(null);
  router = inject(Router);
  private companyTickerService = inject(CompanyTickerService);
  private researchArticle: WritableSignal<IResearch> = signal(INITIAL_RESEARCH);
  private activeAccordionResearchArticle: WritableSignal<IResearch> =
    signal(INITIAL_RESEARCH);
  private companyResearchOverview: WritableSignal<IResearchOverviewItem[]> =
    signal([]);
  private isLoading = signal(false);
  private companyListService = inject(CompanylistService);
  private companyList = this.companyListService.getCompanyList();

  constructor(http: HttpClient) {
    super(http);
    effect(() => {
      const companyTicker = this.companyTickerService.getCompanyTicker();
      if (this.companyList() && companyTicker()) {
        this.fetchResearch(companyTicker());
        this.fetchResearchOverview(companyTicker());
        this.fetchCompanyResearchOverview(companyTicker());
      }
    }, enableSignalWritesInEffectContext);
  }

  getisLoading() {
    return this.isLoading;
  }

  public fetchCompanyResearchOverview(companyTicker: string) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IResearchOverviewItem[]>>(
        `${alsterAPI_URL}research/list/${companyTicker}`
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetching company research overview"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (overviewData) => {
          this.companyResearchOverview.set(overviewData);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch company research overview success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public getCompanyResearchOverview() {
    return this.companyResearchOverview;
  }

  getIsLoading() {
    return this.isLoading;
  }

  setIsLoading(value: boolean) {
    this.isLoading.set(value);
  }

  public getActiveAccordionResearchArticle() {
    return this.activeAccordionResearchArticle;
  }

  public getResearchOverview() {
    return this.items as WritableSignal<IResearchOverviewItem[]>;
  }

  public getResearchArticle() {
    return this.researchArticle as any; // The "as any" is a workaround for the "union" types in shared interfaces
  }

  public setResearchArticler(researchArticle: IResearch) {
    this.researchArticle.set(researchArticle);
  }

  public fetchResearch(
    companyTicker: string,
    researchTitle: string = "latest"
  ) {
    /*
      if no researchTitle is provided it should fetch the latest research,
     "latest" is mapped to the latest research in the backend
     */
    this.setIsLoading(true);
    this.http
      .get<IApiResponse<IResearch>>(
        alsterAPI_URL + "research/find/" + companyTicker + "/" + researchTitle
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetching research")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (researchData) => {
          this.researchArticle.update(() => ({
            ...researchData,
          }));
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch research success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public fetchResearchArticleForAccordion(
    companyTicker: string,
    researchTitle: string
  ) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IResearch>>(
        alsterAPI_URL +
          "research/find/" +
          companyTicker +
          "/" +
          encodeURIComponent(researchTitle)
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch researcha rticle for accordion"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (researchData) => {
          this.activeAccordionResearchArticle.update(() => ({
            ...researchData,
          }));
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch research for accordeon success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public fetchResearchOverview(
    companyTicker: string,
    paginationParams: IQueryParams = { page: 1, limit: 200 } // limit is set to 200 to load all
  ) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL + "research/list/" + companyTicker,
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }

  public fetchUniqueResearch(
    companyTicker: string,
    researchTitle: string = "latest"
  ): any {
    const url =
      alsterAPI_URL +
      "research/find/" +
      companyTicker +
      "/" +
      encodeURIComponent(researchTitle);
    // TODO: check subscribe function to fill tap
    return this.http.get(url).pipe(
      map((response: any) => response.data[0]),

      catchError((error) =>
        this.errorHandlingService.handleError(
          error,
          "fetch research special way"
        )
      ),
      finalize(() => {
        this.isLoading.set(false);
      })
    );
  }

  deleteResearchWithContext(researchId: string) {
    this.isLoading.set(true);
    this.http
      .delete<IApiResponse<any>>(
        alsterAPI_URL + "research/delete/" + researchId
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "deletion of research with context"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: () => {
          this.adminRequestService.setLastRequestMessage(
            "Deletion of Research with Context successfull"
          );
          this.adminRequestService.setLastRequestSuccess(true);
          this.router.navigate(["/admin/dashboard/research"]);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchContextOverview(researchId: string) {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<ICompany>>(
        alsterAPI_URL + "research/contextoverview/" + researchId
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch company context overview"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (companyContextOverview) => {
          this.researchContextOverview.set(companyContextOverview);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch research context success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  getResearchContextOverview() {
    return this.researchContextOverview;
  }
}
