<div class="container mx-auto">
  <div class="grid grid-cols-1">
    <div>
      <form
        [formGroup]="exchangeMappingForm"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div class="flex items-center w-full my-2">
          <button
            (click)="addMapping()"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
          >
            Add Mapping
          </button>
        </div>
        <div formArrayName="mappings">
          @for (mapping of mappings.controls; track mapping; let i = $index) {
            <div class="mb-4" [formGroupName]="i">
              <div class="flex items-center">
                <div class="flex-1 mr-2">
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    [ngClass]="{
                      'border-red-500':
                        mapping.get('companyTicker').invalid &&
                        mapping.get('companyTicker').touched
                    }"
                    type="text"
                    formControlName="companyTicker"
                    placeholder="Company Ticker"
                    required
                  />
                  @if (
                    mapping.get("companyTicker").invalid &&
                    mapping.get("companyTicker").touched
                  ) {
                    <p class="text-red-500 text-xs italic mt-1">
                      {{ getErrorMessage(mapping.get("companyTicker")) }}
                    </p>
                  }
                </div>
                <span class="mx-2">:</span>
                <div class="flex-1 ml-2">
                  <input
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    [ngClass]="{
                      'border-red-500':
                        mapping.get('mappedTicker').invalid &&
                        mapping.get('mappedTicker').touched
                    }"
                    type="text"
                    formControlName="mappedTicker"
                    placeholder="Mapped Ticker"
                    required
                  />
                  @if (
                    mapping.get("mappedTicker").invalid &&
                    mapping.get("mappedTicker").touched
                  ) {
                    <p class="text-red-500 text-xs italic mt-1">
                      {{ getErrorMessage(mapping.get("mappedTicker")) }}
                    </p>
                  }
                </div>
                <button
                  type="button"
                  class="ml-2 px-4 py-2 rounded bg-red-500 text-white"
                  (click)="removeMapping(i)"
                >
                  Remove
                </button>
              </div>
            </div>
          }
        </div>
        @if (!multiMode) {
          <div class="flex items-center w-full my-2">
            <app-admin-form-button
              (click)="saveForm()"
              [class]="'admin-form-button mx-auto'"
              [disabled]="!exchangeMappingForm.valid"
            >
            </app-admin-form-button>
          </div>
          <app-admin-form-request-message></app-admin-form-request-message>
        }
      </form>
    </div>
  </div>
</div>
