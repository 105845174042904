@if (isGuidedMenuEnabled()) {
  <div class="bg-yellow-100 border-b-2 border-blue-500 p-4 shadow-md">
    <div class="container mx-auto">
      <div class="flex items-center justify-between mb-2">
        <h2 class="text-lg font-bold text-blue-800">Company Creation Guide</h2>
        <button
          class="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors duration-300"
          (click)="creationProcessMenuService.toggleGuidedMenu()"
        >
          Hide Guide
        </button>
      </div>

      @if (steps()[getCurrentStepIndex()] === undefined) {
        <div class="flex items-center justify-between">
          <p class="text-sm text-gray-600">Ready to create a new company?</p>
          <button
            class="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors duration-300"
            [routerLink]="[steps()[0].route]"
          >
            Start Process
          </button>
        </div>
      } @else {
        <div class="flex items-center space-x-2 overflow-x-auto pb-2 text-xs">
          @for (step of steps(); track step; let index = $index) {
            <div
              class="flex-shrink-0 flex items-center p-2 rounded-md transition-colors duration-300"
              [class.bg-blue-200]="index === getCurrentStepIndex()"
              [class.text-green-600]="step.status === 'completed'"
              [class.text-gray-500]="step.isOptional"
            >
              <span
                class="w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center text-xs mr-2"
                >{{ index + 1 }}</span
              >
              @if (currentTicker() || step.isOptional) {
                <a
                  class="text-sm"
                  [routerLink]="
                    creationProcessMenuService.buildRoute(step.route)
                  "
                >
                  {{ step.name }}
                </a>
              } @else {
                <span class="text-sm">{{ step.name }}</span>
              }
              @if (step.status === "completed") {
                <span class="text-green-500 ml-1">✓</span>
              }
            </div>
          }
        </div>

        <div class="flex items-center justify-between mt-2">
          <div class="flex items-center space-x-2">
            @if (getCurrentStepIndex() !== steps().length - 1) {
              <button
                class="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                [disabled]="
                  (!steps()[getCurrentStepIndex()].isOptional &&
                    !isCurrentStepCompleted()) ||
                  !currentTicker()
                "
                (click)="navigateToNextStep()"
              >
                Next Step
              </button>
            } @else {
              <p class="text-sm text-green-600 font-semibold">Completed!</p>
            }
            @if (currentTicker()) {
              <p class="text-sm text-gray-600">Ticker: {{ currentTicker() }}</p>
            } @else {
              <p class="text-sm text-yellow-600">No ticker set</p>
              <p class="text-sm text-gray-muted">
                The ticker ist set automatically when you create a new company
                in Step 1.
              </p>
            }
          </div>
          <p class="text-xs text-gray-600">To reset, reload the page</p>
        </div>
      }
    </div>
  </div>
}
