<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-6 mb-8">
      <p class="text-md font-semibold mb-2">Transactions</p>
      <p class="text-gray-600">View and manage transactions</p>
    </div>

    <div
      [ngClass]="
        frontendConfig()?.activeTransaction ? 'bg-green-100' : 'bg-red-100'
      "
      class="rounded-lg shadow-md p-6 md:col-span-1 mb-8"
    >
      <p class="text-lg font-semibold mb-3">
        @if (frontendConfig()?.activeTransaction) {
          Transaction is active
        } @else {
          No transaction active
        }
      </p>

      <div class="text-gray-700 mb-4">
        <p class="mb-2">{{ transcationBannerContent()?.text }}</p>
        <a
          class="text-blue-600 hover:underline"
          href="{{ transcationBannerContent()?.link }}"
          target="_blank"
        >
          {{ transcationBannerContent()?.link }}
        </a>
      </div>

      <a
        [routerLink]="['/admin/dashboard/transaction/activation']"
        class="inline-block"
      >
        <button
          class="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105"
        >
          Change active transaction
        </button>
      </a>
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="fetchMoreTransactions($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalItems() }}
        </p>
      </div>

      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-4 py-2 text-left">Link</th>
              <th class="px-4 py-2 text-left">Text</th>
              <th class="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (
              transaction of transactionsList();
              track transaction;
              let index = $index
            ) {
              <tr>
                <td class="px-4 py-2">{{ transaction.link }}</td>
                <td class="px-4 py-2">{{ transaction.text }}</td>
                <td class="px-4 py-2">
                  <a [routerLink]="[transaction._id, 'edit']">
                    <button
                      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Edit
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="fetchMoreTransactions($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalItems() }}
        </p>
      </div>
    </div>
  </main>
</div>
