import { NgClass } from "@angular/common";
import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";
import { IRSSEntry } from "~global-interfaces/IRSSEntry";

import { AdminInfoHasSideeffectDirective } from "~features/admin-info-sideeffects/admin-info-has-sideeffect.directive";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-rss-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormRequestMessageComponent,
    AdminFormButtonComponent,
    CompanySearchComponent,
    AdminInfoHasSideeffectDirective,
  ],
  templateUrl: "./admin-rss-editor-form.component.html",
  styleUrl: "./admin-rss-editor-form.component.scss",
})
export class AdminRssEditorFormComponent implements OnInit {
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  @Input() multiModeCompanyName: Signal<string | null> = signal(null);
  @Input() rssEntry!: WritableSignal<IRSSEntry>;
  @Input() lang!: string;
  @Input() creatorMode = false;
  @Output() formSubmit = new EventEmitter<IRSSEntry>();
  @Input() companyName: WritableSignal<string | null> = signal(null);
  rssEntryForm: FormGroup;
  isLoading = false;
  adminRequestService = inject(AdminRequestService);

  rssLink = computed(() => {
    return (
      "https://research-hub.de/companies/" +
      encodeURIComponent(
        this.companyName()
          ? this.companyName()
          : this.multiModeCompanyName()
          ? this.multiModeCompanyName()
          : ""
      )
    );
  });
  private formBuilder = inject(FormBuilder);

  constructor() {
    this.adminRequestService.emptyLastRequest();

    effect(() => {
      if (this.companyName()) {
        this.rssEntryForm.patchValue({ link: this.rssLink() });
      }
    });
    effect(() => {
      if (this.multiModeCompanyName()) {
        this.rssEntryForm.patchValue({ link: this.rssLink() });
      }
    });

    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.initForm(this.lang);
    this.formValidity.emit(this.rssEntryForm.valid);
    this.formValueChange.emit(this.rssEntryForm.value);
    // Subscribe to form changes
    this.rssEntryForm.valueChanges.subscribe((values) => {
      const updatedRSSEntry: IRSSEntry = {
        ...this.rssEntry(),
        ...values,
      };
      this.formValueChange.emit(updatedRSSEntry);
    });

    // Subscribe to form validity changes
    this.rssEntryForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  initForm(lang) {
    /**
     *
     * This needs some auto generated info
     * news['link'] = 'https://research-hub.de/companies/research/' + encodeURIComponent(this.companyName);

     * type: 'research'
     * creator: 'RH'
     * pubDate looks like this: Mon, 29 Aug 2022 10:07:16 GMT
     *
     */

    this.rssEntryForm = this.formBuilder.group({
      creator: ["RH", [Validators.minLength(2)]],
      description: ["", [Validators.required]],
      expanded: [false],
      type: ["research", [Validators.required]],
      lang: [lang, [Validators.required]],
      link: [this.rssLink(), [Validators.pattern(/^(http|https):\/\/.+/)]],
      pubDate: [new Date().toUTCString(), Validators.required],
      title: ["", [Validators.required, Validators.minLength(3)]],
      companyTicker: [""],
    });
  }

  patchFormValues() {
    if (this.rssEntry()) {
      console.log("pathing rss");
      this.rssEntryForm.patchValue(this.rssEntry());
    }
  }

  saveForm() {
    if (this.rssEntryForm.valid) {
      this.isLoading = true;
      const updatedRSSEntry = {
        ...this.rssEntry(),
        ...this.rssEntryForm.value,
      };
      this.formSubmit.emit(updatedRSSEntry);
      // Simulate async operation
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    } else {
      this.markFormGroupTouched(this.rssEntryForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.rssEntryForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters long`;
      if (control.errors["pattern"]) {
        if (controlName === "lang") return "Language must be a 2-letter code";
        if (controlName === "link")
          return "Must be a valid URL starting with http:// or https://";
      }
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
