import { Component, inject } from "@angular/core";

import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Component({
  selector: "app-error-message",
  standalone: true,
  imports: [],
  templateUrl: "./error-message.component.html",
  styleUrl: "./error-message.component.scss",
})
export class ErrorMessageComponent {
  errorHandlingService = inject(ErrorHandlingService);
  latestErrorMessage = this.errorHandlingService.getLatestErrorMessage();
}
