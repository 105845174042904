import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";
import { hide } from "@popperjs/core";

import { UserReminderService } from "~features/user-reminder/user-reminder.service";

@Component({
  selector: "app-user-reminder-toast",
  standalone: true,
  imports: [RouterLink],
  templateUrl: "./user-reminder-toast.component.html",
  styleUrl: "./user-reminder-toast.component.scss",
})
export class UserReminderToastComponent {
  userReminderService = inject(UserReminderService);
  showReminder = this.userReminderService.showToast;
  protected readonly hide = hide;

  hideReminder() {
    this.userReminderService.hideReminder();
  }
}
