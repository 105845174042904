import { Component, OnInit } from "@angular/core";

import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { CompanyGridHighlightComponent } from "~features/company-grid/company-grid-highlight/company-grid-highlight.component";
import { CompanyScreenerComponent } from "~features/company-screener/company-screener.component";
import { EventGridMixedComponent } from "~features/events/event-grid-mixed/event-grid-mixed.component";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";

@Component({
  selector: "app-stock-company-screener",
  templateUrl: "./company-screener-page.component.html",
  styleUrls: ["./company-screener-page.component.scss"],
  standalone: true,
  imports: [
    CompanyScreenerComponent,
    CompanyGridComponent,
    SubscribeBannerComponent,
    EventGridMixedComponent,
    CompanyGridHighlightComponent,
  ],
})
export class CompanyScreenerPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
