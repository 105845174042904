import { DatePipe, JsonPipe } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { RouterLink } from "@angular/router";

import { ResponsiveVideoComponent } from "~features/video/responsive-video/responsive-video.component";
import { VideoDialogComponent } from "~features/video/video-dialog/video-dialog.component";
import { VideoPlayerComponent } from "~features/video/video-player/video-player.component";
import { VideoService } from "~features/video/video.service";
import { DateComponent } from "~shared/components/ui/date/date.component";
import { YoutubeIconComponent } from "~shared/components/ui/youtube-icon/youtube-icon.component";
import { ClipboardService } from "~shared/services/clipboard.service";
import { DialogService } from "~shared/services/dialog.service";

@Component({
  selector: "app-event-grid-tile-archived",
  standalone: true,
  imports: [
    DatePipe,
    ResponsiveVideoComponent,
    RouterLink,
    DateComponent,
    VideoPlayerComponent,
    YoutubeIconComponent,
    JsonPipe,
  ],
  templateUrl: "./event-grid-archived-tile.component.html",
  styleUrl: "./event-grid-archived-tile.component.scss",
})
export class EventGridArchivedTileComponent {
  @Input() archiveEvent: any;
  @Input() listMode = false;
  clipBoardService = inject(ClipboardService);
  private dialogService = inject(DialogService);
  private videoService = inject(VideoService);

  openVideo() {
    this.dialogService.open(VideoDialogComponent, {
      maxWidth: "80%",
      data: {
        animal: "panda",
      },
    });
  }

  copyVideoURL(companyTicker, date) {
    this.clipBoardService.copyEventVideoLinkTioClipboard(companyTicker, date);
  }
}
