<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        Connect a company with Leeway (Word/Excel-Addin).
      </p>
      <p class="text-gray-600">
        Fill in the form and click the button to make this company available for
        Word/Excel.
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <app-company-leeway-connection-form
        (formSubmit)="onFormSubmit($event)"
      ></app-company-leeway-connection-form>
    </div>
  </main>
</div>
