import { Component, Input, signal, Signal } from "@angular/core";
import { IResearchOverviewItem } from "~global-interfaces/IResearchOverviewItem";

import { ButtonDownloadPdfComponent } from "~features/pdfAnalytics/button-download-pdf/button-download-pdf.component";
import { DateComponent } from "~shared/components/ui/date/date.component";

@Component({
  selector: "app-research-list",
  standalone: true,
  imports: [DateComponent, ButtonDownloadPdfComponent],
  templateUrl: "./research-list.component.html",
  styleUrl: "./research-list.component.scss",
})
export class ResearchListComponent {
  @Input() researchOverviewList: Signal<IResearchOverviewItem[] | null> =
    signal(null);
}
