import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
} from "@angular/core";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class PaginationComponent {
  @Input() totalItems = signal(0);
  @Input() itemsPerPage = signal(0);
  @Input() currentPage = signal(0);
  @Output() pageChanged = new EventEmitter<number>();

  private readonly MAX_VISIBLE_PAGES = 10;

  totalPages = computed(() =>
    Math.ceil(this.totalItems() / this.itemsPerPage()),
  );

  displayedPages = computed(() => {
    const pages: (number | -1)[] = [];
    const totalPages = this.totalPages();
    const visiblePages = Math.min(this.MAX_VISIBLE_PAGES, totalPages);

    let startPage = Math.max(
      1,
      this.currentPage() - Math.floor(visiblePages / 2),
    );
    const endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(1, endPage - visiblePages + 1);
    }

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push(-1); // Represents ellipsis (...)
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(-1); // Represents ellipsis (...)
      }
      pages.push(totalPages);
    }

    return pages;
  });

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages()) {
      this.pageChanged.emit(page);
    }
  }

  previousPage() {
    if (this.currentPage() > 1) {
      this.pageChanged.emit(this.currentPage() - 1);
    }
  }

  nextPage() {
    if (this.currentPage() < this.totalPages()) {
      this.pageChanged.emit(this.currentPage() + 1);
    }
  }
}
