<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        Company Settings {{ companyTicker() }}
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div class="md:col-span-2">
        <div class="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 class="text-md font-secondary text-gray-900 mb-4">
            Quick Actions
          </h2>
          <div class="grid grid-cols-2 gap-4">
            <div
              class="bg-green-50 rounded-lg p-4 hover:shadow-md transition-shadow"
            >
              <p class="text-gray-600 text-sm mb-3">
                publish excel figures for {{ companyTicker() }}
              </p>

              <button
                (click)="publishExcelFigures()"
                class="inline-block bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-sm transition-colors"
              >
                publish excel figures
              </button>
              <app-admin-form-request-message></app-admin-form-request-message>
            </div>

            <div
              class="bg-green-50 rounded-lg p-4 hover:shadow-md transition-shadow"
            >
              <p class="text-gray-600 text-sm mb-3">
                Set highlight for this company
              </p>

              <app-admin-company-settings-editor-form
                (formSubmit)="onFormSubmit($event)"
                [company]="company"
                [showIsFeaturedOnly]="true"
              ></app-admin-company-settings-editor-form>
            </div>
          </div>
        </div>
        <div class="bg-white shadow-md rounded-lg p-6">
          <h2 class="text-md font-secondary text-gray-900 mb-4">
            Company Data
          </h2>
          <dl class="grid grid-cols-1 gap-y-3 text-sm">
            <div>
              <dt class="font-secondary text-gray-700">ID</dt>
              <dd class="text-gray-900">{{ company()._id }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">Name</dt>
              <dd class="text-gray-900">{{ company().name }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">Company Ticker</dt>
              <dd class="text-gray-900">{{ company().companyTicker }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">
                Ticker for Shareprice TradingView Chart
              </dt>
              <dd class="text-gray-900">{{ company().mappedTVChartTicker }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">Base Year</dt>
              <dd class="text-gray-900">{{ company().baseyear }}</dd>
            </div>
            <!--          <div>-->
            <!--            <dt class="font-secondary text-gray-700">Charts</dt>-->
            <!--            <dd class="text-gray-900">{{ company().charts.join(", ") }}</dd>-->
            <!--          </div>-->
            <div>
              <dt class="font-secondary text-gray-700">Is Featured</dt>
              <dd class="text-gray-900">{{ company().isFeatured }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">Is Public</dt>
              <dd class="text-gray-900">
                {{ company().is_public ? "Yes" : "No" }}
              </dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">Image URL</dt>
              <dd class="text-gray-900">{{ company().imageURL }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">Logo URL</dt>
              <dd class="text-gray-900">{{ company().logoURL }}</dd>
            </div>
            <div>
              <dt class="font-secondary text-gray-700">
                Company Presentation URL
              </dt>
              <dd class="text-gray-900">{{ company().presentationURL }}</dd>
            </div>
          </dl>
        </div>
      </div>

      <div class="bg-white shadow-md rounded-lg p-6 md:col-span-1">
        <h2 class="text-md font-secondary text-gray-900 mb-4">Info</h2>
        <div class="space-y-3 mb-3">
          <a [routerLink]="['research', 'list']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              List Research
            </button>
          </a>
        </div>
        <h2 class="text-md font-secondary text-gray-900 mb-4">Edit Settings</h2>
        <div class="space-y-3">
          <a [routerLink]="['edit']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit Company Settings
            </button>
          </a>
          <a [routerLink]="['company-swot', 'edit']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit SWOT
            </button>
          </a>
          <!--          <a [routerLink]="['company-office', 'creator']" class="block">-->
          <!--            <button-->
          <!--              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"-->
          <!--            >-->
          <!--              Connect to MS Office-->
          <!--            </button>-->
          <!--          </a>-->
          <a [routerLink]="['company-investmentcase', 'edit']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit Investment Case
            </button>
          </a>
          <a [routerLink]="['company-valuation', 'edit']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit Valuation
            </button>
          </a>

          <a [routerLink]="['company-initiation-note', 'edit']" class="block">
            <button
              class="w-full px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Edit Initiation Note
            </button>
          </a>
          <a [routerLink]="['delete']" class="block">
            <button
              class="w-full px-4 py-2 bg-red-500 hover:bg-red-200 text-white font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Delete this Company
            </button>
          </a>
        </div>
      </div>
    </div>
  </main>
</div>
