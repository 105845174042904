import { Injectable, signal, WritableSignal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CompanyRatingService {
  private companyRating: WritableSignal<any> = signal({});

  public getCurrentCompanyRating() {
    return this.companyRating;
  }

  public setCurrentCompanyRating(rating: any) {
    this.companyRating.set(rating);
  }
}
