import { DatePipe } from "@angular/common";
import {
  Component,
  effect,
  inject,
  Input,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";
import { IResearchPodcastPopulated } from "~global-interfaces/IResearchPodcast";

import { PodcastMiniPlayerComponent } from "~features/podcasts/podcast-mini-player/podcast-mini-player.component";
import { PodcastService } from "~features/podcasts/podcast.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { PosthogService } from "~shared/services/posthog.service";

@Component({
  selector: "app-podcast-highlight",
  standalone: true,
  imports: [PodcastMiniPlayerComponent, DatePipe],
  templateUrl: "./podcast-highlight.component.html",
  styleUrl: "./podcast-highlight.component.scss",
})
export class PodcastHighlightComponent {
  @Input() podcast: Signal<IResearchPodcastPopulated | null> = signal(null);
  podcastService = inject(PodcastService);
  loading: WritableSignal<boolean> = signal(true);
  posthogService = inject(PosthogService);
  constructor() {
    effect(() => {
      if (this.podcast()) {
        this.loading.set(false);
      }
    }, enableSignalWritesInEffectContext);
  }

  copyShareLink(podcast) {
    this.podcastService.copyShareLinkToClipBoard(podcast);
  }

  onPlay() {
    this.posthogService.trackPodcastPlayed(
      this.podcast().companyTicker,
      this.podcast().research_date
    );
  }
}
