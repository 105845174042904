import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import { IEditorContent } from "~global-interfaces/IEditorContent";

import { CompanyInvestmentcaseFormComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-form/company-investmentcase-form.component";
import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { InvestmentCaseService } from "~features/company/investment-case.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_EDITORCONTENT_INVESTMENTCASE } from "~shared/initial-values";

@Component({
  selector: "app-company-investmentcase-creator",
  standalone: true,
  imports: [
    CreationProcessMenuComponent,
    AdminCompanySwotEditorFormComponent,
    BreadcrumpComponent,
    CompanyInvestmentcaseFormComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./company-investmentcase-creator.component.html",
  styleUrl: "./company-investmentcase-creator.component.scss",
})
export class CompanyInvestmentcaseCreatorComponent {
  investmentCaseService = inject(InvestmentCaseService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  currentCompanyTicker = this.creationProcessMenuService.currentCompanyTicker;
  investmentCase: WritableSignal<IEditorContent | null> = signal(
    INITIAL_EDITORCONTENT_INVESTMENTCASE
  );

  investmentCaseContent: Signal<IEditorContent | null> = computed(() => {
    return {
      ...this.investmentCase(),
      companyTicker: this.currentCompanyTicker() || "",
    };
  });

  onFormSubmit(newData: any) {
    //  console.log(newData);
    this.investmentCaseService.createInvestmentCase(newData);
    this.creationProcessMenuService.setStepCompleted("Investment Case");
  }
}
