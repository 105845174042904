import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate([""]);
    //this.openLogin(state.url);
    return false;
  }
  openLogin(rerouteURL: string = "") {
    //TODO: implement this with new modals
    // const modalRef = this.modalService.open(SigninDialogComponent, {
    //   centered: true,
    //   size: "lg",
    // });
    // modalRef.componentInstance.status = this.authService.isAuthenticated();
    // modalRef.componentInstance.reroute = true;
    // modalRef.componentInstance.rerouteURL = rerouteURL;
    // modalRef.result.then(
    //   () => {},
    //   (reason) => {
    //     // if (
    //     //   reason === ModalDismissReasons.ESC ||
    //     //   reason === ModalDismissReasons.BACKDROP_CLICK ||
    //     //   reason === "Cross click" ||
    //     //   reason === "ok"
    //     // ) {
    //     //   this.router.navigate([""]);
    //     // }
    //   },
    // );
  }
}
