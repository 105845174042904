import { Dialog } from "@angular/cdk/dialog";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { Event, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { AdminInfoSideeffectsToastComponent } from "~features/admin-info-sideeffects/admin-info-sideeffects-toast/admin-info-sideeffects-toast.component";
import { AuthService } from "~features/auth/auth.service";
import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanylistService } from "~features/company/companylist.service";
import { ExchangeMappingService } from "~features/company/exchangeMapping.service";
import { ContactBannerComponent } from "~features/contact-banner/contact-banner.component";
import { CookieToastComponent } from "~features/cookies/cookie-toast/cookie-toast.component";
import { CookieService } from "~features/cookies/cookie.service";
import { EventService } from "~features/events/event.service";
import { formatDateNow } from "~features/events/event.utils";
import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreateResearchProcessComponent } from "~features/guided-research-creation-flow/create-research-process/create-research-process.component";
import { CanonicalLinkService } from "~features/seo/canonical-link.service";
import { TransactionbannerComponent } from "~features/transactions/transactionbanner/transactionbanner.component";
import { UserNotificationToastComponent } from "~features/user-notifications/user-notification-toast/user-notification-toast.component";
import { UserReminderToastComponent } from "~features/user-reminder/user-reminder-toast/user-reminder-toast.component";
import { UserReminderService } from "~features/user-reminder/user-reminder.service";
import { FooterComponent } from "~shared/components/contentful/footer/footer.component";
import { HeaderComponent } from "~shared/components/contentful/header/header.component"; // import { ToastsContainer } from "~shared/components/contentful/toast-container/toast-container.component";
import { ErrorMessageComponent } from "~shared/components/ui/error-message/error-message.component";
import { SimpleLoadingIndicatorComponent } from "~shared/components/ui/simple-loading-indicator/simple-loading-indicator.component";
import { DialogService } from "~shared/services/dialog.service";
import { PosthogService } from "~shared/services/posthog.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  standalone: true,
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    NgOptimizedImage,
    CreationProcessMenuComponent,
    SimpleLoadingIndicatorComponent,
    CreateResearchProcessComponent,
    TransactionbannerComponent,
    ErrorMessageComponent,
    ContactBannerComponent,
    UserNotificationToastComponent,
    UserReminderToastComponent,
    CookieToastComponent,
    NgClass,
    AdminInfoSideeffectsToastComponent,
  ],
})
export class AppComponent implements OnInit {
  title = "researchHub";
  currentURL = "";
  showContactButton = false;
  showContactSlider = false;
  public isCookiesAccepted: boolean = false;
  showHeaderAndFooter = true;
  companyListService = inject(CompanylistService);
  comanyListIsLoaded = this.companyListService.isLoaded;
  exchangeMappingService = inject(ExchangeMappingService);
  frontendConfigService = inject(FrontendConfigService);
  eventService = inject(EventService);
  dialog = inject(Dialog);
  dialogService = inject(DialogService);
  frontendConfig = this.frontendConfigService.getFrontendConfig();
  navigationEnd: Observable<NavigationEnd>;
  canonicalLinkService = inject(CanonicalLinkService);
  postHogService = inject(PosthogService);
  public showCookieToast = this.cookieService.showCookieToast;
  private HEADER_OFFSET = 500; // 1 em = 16px =>  without banner: 25em = 400px; with banner = 28.5em = 456px; new company slider adds 53.3 px
  private authService = inject(AuthService);
  private userReminderService = inject(UserReminderService);

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private companyInfoService: CompanyInfoService,
    private cdr: ChangeDetectorRef
  ) {
    this.eventService.fetchEventsList(undefined, formatDateNow());
    this.navigationEnd = router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;
    this.userReminderService.checkReminder();
  }

  ngOnInit() {
    this.canonicalLinkService.updateCanonicalUrl();

    // Initialize cookie permissions
    this.cookieService.initPrivacyPermissionsAndCookies();

    // Subscribe to navigation end events to track page views
    this.cookieService.navigationEnd.subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects;
      // Check if cookies are accepted before tracking page views
      if (this.cookieService.isCookiesAccepted()) {
        this.postHogService.trackPageView(url);
      }
    });

    if (this.authService.isAuthenticated()) {
      this.authService.verifyToken().then(() => {
        this.cdr.detectChanges();
      });
    }

    // Subscribe to router events to toggle header and footer visibility
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showHeaderAndFooter = !event.url.includes("events/registration/");
      }
    });
  }

  isAdmin() {
    return this.authService.isAdmin();
  }
}
