import { Dialog, DialogRef } from "@angular/cdk/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: Dialog) {}

  open<T, D = any>(
    component: ComponentType<T>,
    data?: D,
    customDialogClasses: string = "",
    disableClose = false
  ): DialogRef<T, any> {
    return this.dialog.open<T, D, any>(component, {
      disableClose: disableClose,
      data,
      panelClass: [
        "rounded-[20px]",
        customDialogClasses ? customDialogClasses : "dialog-panel-white",
      ],
      backdropClass: "dialog-backdrop",
    });
  }
}
