import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from "@angular/cdk/menu";
import { JsonPipe } from "@angular/common";
import {
  Component,
  computed,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { AuthService } from "~features/auth/auth.service";
import { EventGridArchivedComponent } from "~features/events/event-grid-archived/event-grid-archived.component";
import { EventGridUpcomingComponent } from "~features/events/event-grid-upcoming/event-grid-upcoming.component";
import { EventSearchComponent } from "~features/events/event-search/event-search.component";
import { PodcastHighlightComponent } from "~features/podcasts/podcast-highlight/podcast-highlight.component";
import { PodcastListComponent } from "~features/podcasts/podcast-list/podcast-list.component";
import { PodcastService } from "~features/podcasts/podcast.service";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-podcasts-page-page",
  standalone: true,
  imports: [
    EventGridArchivedComponent,
    EventGridUpcomingComponent,
    MoreContentCtaComponent,
    PodcastListComponent,
    JsonPipe,
    PodcastHighlightComponent,
    PaginationComponent,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    EventSearchComponent,
  ],
  templateUrl: "./podcasts-page.component.html",
  styleUrl: "./podcasts-page.component.scss",
})
export class PodcastsPageComponent implements OnInit {
  @ViewChild(CdkMenuTrigger) menuTrigger!: CdkMenuTrigger;
  @ViewChild("inputElement") inputElement!: ElementRef;
  adminMode = false;
  authService = inject(AuthService);
  podcastService = inject(PodcastService);

  latestPodcast = this.podcastService.getCurrentPodcast();
  podcastList = this.podcastService.getPodcastList();
  currentPage = signal(1);
  itemsPerPage = signal(12);
  totalItems = this.podcastService.getTotalCount();
  route = inject(ActivatedRoute);
  searchTerm: WritableSignal<string | null> = signal("all");
  podcastCompanies = this.podcastService.getPodcastCompanies();
  searchResults = computed(() => {
    const term = this.searchTerm().toLowerCase();

    if (term === "" || term === "all" || term === null) {
      return ["all", ...this.podcastCompanies()];
    }

    const filteredCompanies = this.podcastCompanies().filter((company) =>
      company.toLowerCase().includes(term)
    );
    // Create a new company object for the search term, this is quite hacky but it works
    const searchTermCompany = this.searchTerm();

    // Return the array with the search term company at the top
    return ["all", searchTermCompany, ...filteredCompanies];

    //return filteredCompanies;
  });

  constructor() {
    this.fetchPodcasts();

    const companyTicker = this.route.snapshot.paramMap.get("companyTicker");
    const research_date = this.route.snapshot.paramMap.get("research_date");
    const language = this.route.snapshot.paramMap.get("language");

    this.podcastService.fetchPodcast(companyTicker, research_date, language);
  }

  fetchPodcasts() {
    this.podcastService.fetchResearchPodcastList(
      {
        page: this.currentPage(),
        limit: this.itemsPerPage(),
      },
      this.searchTerm()
    );
  }

  ngOnInit(): void {
    this.adminMode = this.authService.isAdmin();
  }

  fetchMorePodcasts($event: number) {
    this.currentPage.set($event);
    this.fetchPodcasts();
  }

  onSearchStringEntered(searchString: string) {
    this.menuTrigger.close();
    this.searchTerm.set(searchString);
    this.fetchPodcasts();
    this.searchTerm.set("all");
  }

  onSearchInput(event: Event) {
    console.log(this.inputElement);
    const term = (event.target as HTMLInputElement).value;
    this.searchTerm.set(term);
    if (term !== "" && this.searchResults().length > 0) {
      this.menuTrigger.open();
      this.inputElement.nativeElement.focus();
    } else {
      this.menuTrigger.close();
    }
  }

  focusInput() {
    // Use setTimeout to ensure the focus happens after the menu opens
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
    });
  }
}
