import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { IFrontendConfig } from "~global-interfaces/IFrontendConfig";
import { ITransaction } from "~global-interfaces/ITransaction";

import { TransactionService } from "~features/transactions/transaction.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-transaction-activation-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormButtonComponent,
    JsonPipe,
    PaginationComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./transaction-activation-form.component.html",
  styleUrl: "./transaction-activation-form.component.scss",
})
export class TransactionActivationFormComponent implements OnInit {
  @Input() frontendConfig!: WritableSignal<IFrontendConfig>;
  @Output() formSubmit = new EventEmitter<IFrontendConfig>();
  transactionService = inject(TransactionService);
  transactionsList: WritableSignal<ITransaction[]> =
    this.transactionService.getTransactionsList();
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);
  formBuilder = inject(FormBuilder);
  frontendConfigForm: FormGroup;
  adminRequestService = inject(AdminRequestService);
  constructor() {
    this.adminRequestService.emptyLastRequest();

    this.fetchTransactions();
    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.frontendConfigForm = this.formBuilder.group({
      activeTransaction: [false, Validators.required],
      transactionId: [""],
      // Add other form controls here as needed
    });
  }

  patchFormValues() {
    if (this.frontendConfig()) {
      this.frontendConfigForm.patchValue({
        activeTransaction: this.frontendConfig().activeTransaction,
        // Patch other values here
      });
    }
  }

  selectTransaction(transactionId: string) {
    this.frontendConfigForm.patchValue({ transactionId: transactionId });
  }

  saveForm() {
    if (this.frontendConfigForm.valid) {
      const updatedFrontendConfig = {
        ...this.frontendConfig(),
        ...this.frontendConfigForm.value,
      };
      this.formSubmit.emit(updatedFrontendConfig);
    } else {
      this.markFormGroupTouched(this.frontendConfigForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.frontendConfigForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      // Add other error messages as needed
    }
    return "";
  }

  fetchTransactions() {
    this.transactionService.fetchTransactionsList({
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    });
    this.transactionsList = this.transactionService.getTransactionsList();
    this.totalItems = this.transactionService.getTotalCount();
  }

  fetchMoreTransactions($event: number) {
    this.currentPage.set($event);
    this.fetchTransactions();
  }

  isInvalidAndTouched(controlName: string): boolean {
    const control = this.frontendConfigForm.get(controlName);
    return control ? control.invalid && control.touched : false;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
