import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-date-time-picker",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./date-time-picker.component.html",
  styleUrl: "./date-time-picker.component.scss",
})
export class DateTimePickerComponent {
  @Output() selectedDateTimeChange = new EventEmitter<string>();
  selectedDate: string = "";
  selectedTime: string = "";
  showPicker: boolean = false;

  @Input() set selectedDateTime(value: string) {
    if (value) {
      const [date, time] = value.split(" ");
      this.selectedDate = date;
      this.selectedTime = time;
    }
  }

  togglePicker() {
    this.showPicker = !this.showPicker;
  }

  onDateChange(date: string) {
    this.selectedDate = date;
    this.emitDateTimeChange();
  }

  onTimeChange(time: string) {
    this.selectedTime = time;
    this.emitDateTimeChange();
  }

  emitDateTimeChange() {
    this.selectedDateTimeChange.emit(
      `${this.selectedDate} ${this.selectedTime}`
    );
  }

  confirmSelection() {
    this.showPicker = false;
  }
}
