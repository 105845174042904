<form
  [formGroup]="legalDisclaimerCompanyForm"
  class="max-w-xl mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-6">
    <label
      class="block text-sm font-secondary text-gray-700 mb-2"
      for="bloomberg"
    >
      Bloomberg:
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          legalDisclaimerCompanyForm.get('bloomberg')?.invalid &&
          legalDisclaimerCompanyForm.get('bloomberg')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="bloomberg"
      id="bloomberg"
      required
      type="text"
    />
    @if (
      legalDisclaimerCompanyForm.get("bloomberg")?.invalid &&
      legalDisclaimerCompanyForm.get("bloomberg")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("bloomberg") }}
      </p>
    }
  </div>

  <div class="mb-6">
    <label
      class="block text-sm font-secondary text-gray-700 mb-2"
      for="disclosures"
    >
      Disclosures:
    </label>
    <textarea
      [ngClass]="{
        'border-red-500':
          legalDisclaimerCompanyForm.get('disclosures')?.invalid &&
          legalDisclaimerCompanyForm.get('disclosures')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="disclosures"
      id="disclosures"
      required
      rows="4"
    ></textarea>
    @if (
      legalDisclaimerCompanyForm.get("disclosures")?.invalid &&
      legalDisclaimerCompanyForm.get("disclosures")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("disclosures") }}
      </p>
    }
  </div>

  <div class="mb-8">
    <label class="block text-sm font-secondary text-gray-700 mb-2" for="name">
      Name:
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          legalDisclaimerCompanyForm.get('name')?.invalid &&
          legalDisclaimerCompanyForm.get('name')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="name"
      id="name"
      required
      type="text"
    />
    @if (
      legalDisclaimerCompanyForm.get("name")?.invalid &&
      legalDisclaimerCompanyForm.get("name")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("name") }}
      </p>
    }
  </div>
  <hr />

  <div class="flex items-center justify-end">
    <app-admin-form-button
      (click)="saveForm()"
      [class]="'admin-form-button'"
      [disabled]="!legalDisclaimerCompanyForm.valid"
    >
    </app-admin-form-button>
  </div>
  <app-admin-form-request-message></app-admin-form-request-message>
</form>
