import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";

import { BreadcrumbService } from "~shared/services/breadcrump.service";

import { BreadcrumbItem } from "~global-interfaces/IBreadcrumbItem";

export const breadcrumbResolver: ResolveFn<BreadcrumbItem[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const breadcrumbService = inject(BreadcrumbService);
  const breadcrumbs = buildBreadcrumbs(route, state.url);
  breadcrumbService.setBreadcrumbs(breadcrumbs);
  return breadcrumbs;
};

function buildBreadcrumbs(
  route: ActivatedRouteSnapshot,
  url: string
): BreadcrumbItem[] {
  const breadcrumbs: BreadcrumbItem[] = [];
  const paths = url.split("/").filter((path) => path !== "");

  let currentUrl = "";

  for (const path of paths) {
    currentUrl += `/${path}`;

    let label = route.data["breadcrumb"];

    if (route.params && route.params.companyTicker) {
      label = route.params.companyTicker;
    }
    if (label) {
      breadcrumbs.push({ label: path, url: currentUrl });
    }
  }
  return breadcrumbs;
}
