import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { JsonPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";

import { TableNotabsComponent } from "~features/tables/table-notabs/table-notabs.component";

/**
 *
 * This is needed because the code for dialogs changed. And for now we dont have time to change the
 * dynamic-tiles/talbes components to work with the new dialog system. So instead this is called by the tile and then
 * this component here holds the component that was previously called by the tile.
 *
 *
 */
@Component({
  selector: "app-legacy-dialog-wrapper",
  standalone: true,
  imports: [JsonPipe, TableNotabsComponent],
  templateUrl: "./legacy-dialog-wrapper.component.html",
  styleUrl: "./legacy-dialog-wrapper.component.scss",
})
export class LegacyDialogWrapperComponent {
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any
  ) {}
}
