<section class="my-12 col-span-6">
  <div class="bg-white border rounded-lg shadow-lg">
    <h5 class="p-4 font-semibold bg-gray-50 border-b">Workflows</h5>
    <div class="p-8">
      <p>Coming soon...</p>
      <!--      <div class="mb-4 flex justify-center">-->
      <!--        <input-->
      <!--          (ngModelChange)="searchTerm = $event"-->
      <!--          [(ngModel)]="searchTerm"-->
      <!--          class="px-4 py-2 border rounded-lg"-->
      <!--          placeholder="Search workflows..."-->
      <!--          type="text"-->
      <!--        />-->
      <!--      </div>-->
      <!--      <ul-->
      <!--        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6"-->
      <!--      >-->
      <!--        @for (workflow of filteredWorkflows; track workflow) {-->
      <!--          <li-->
      <!--            class="col-span-2 divide-y divide-gray-200 rounded-lg bg-yellow-100 shadow"-->
      <!--          >-->
      <!--            <div class="px-4 py-3 sm:p-6">-->
      <!--              <div class="mt-2 flex justify-around font-semibold">-->
      <!--                {{ workflow.title }}-->
      <!--              </div>-->
      <!--              <div class="my-2 max-w-xl text-sm text-gray-500">-->
      <!--                {{ workflow.description }}-->
      <!--              </div>-->
      <!--              <ol class="list-decimal list-inside">-->
      <!--                @for (step of workflow.steps; track step) {-->
      <!--                  <li>{{ step.description }}</li>-->
      <!--                }-->
      <!--              </ol>-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        }-->
      <!--      </ul>-->
    </div>
  </div>
</section>
