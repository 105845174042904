<div class="w-full">
  <audio (play)="onPlay()" [src]="fileURL" class="w-full" controls></audio>
  <div class="flex justify-between">
    <!--    <a-->
    <!--      [href]="downloadURL"-->
    <!--      class="text-blue-500 w-full uppercase tracking-widest text-link mt-2 hover:underline"-->
    <!--      target="_blank"-->
    <!--    >-->
    <!--      download audio<i class="fas fa-arrow-right ml-1"></i>-->
    <!--    </a>-->
    <button
      (click)="copyShareLink(podcast)"
      class="text-blue-500 w-full uppercase tracking-widest text-link mt-2 hover:underline text-start"
    >
      share podcast<i class="fas fa-arrow-right ml-1"></i>
    </button>
    <a
      [href]="pdfURL"
      class="text-blue-500 w-full uppercase tracking-widest text-link mt-2 hover:underline"
      target="_blank"
    >
      download research<i class="fas fa-arrow-right ml-1"></i>
    </a>
  </div>
</div>
