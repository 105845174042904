<div class="grid grid-cols-1">
  <div class="col-span-1">
    <h3 class="font-bold uppercase mb-4">Coming</h3>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-52 gap-y-4">
      @for (comingEvent of listOfComingEvents(); track comingEvent) {
        <app-event-grid-tile-upcoming
          [comingEvent]="comingEvent"
          [timeZoneFormat]="timeZoneFormat"
          [navigateToEventRegistrationPage]="navigateToEventRegistrationPage"
        ></app-event-grid-tile-upcoming>
      }
    </div>
  </div>
</div>
