import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";

import { NewsletterSubscribeDialogComponent } from "~features/newsletter-subscription/newsletter-subscribe-dialog/newsletter-subscribe-dialog.component";
import { DialogService } from "~shared/services/dialog.service";

@Injectable({
  providedIn: "root",
})
export class SubscribeService {
  http = inject(HttpClient);
  dialogService = inject(DialogService);

  formURL: WritableSignal<any> = signal(null);

  constructor() {
    this.formURL.set(
      "https://10957616.sibforms.com/serve/MUIFAODa39fhZyAaviFuvnlm7iQ5QrZWKkH0kDfv5Vuv2_AgAPOuZQrkQgvIG1T9mQ7Wq3jSnoN22_m6zOO1qE_zeORw3cqEHqk1mQ9X2MgG5-XoCB5U3s9M98GsAM-oKAR3GgZNP5w9p2HK5hsmd1Vkp13-PO40ePNnDMV74vx8L_GrB_3-4wK8V_U5D1aFIfzPNeqZ59rd5nkh"
    );
  }

  openSubscriberModal() {
    this.dialogService.open(
      NewsletterSubscribeDialogComponent,
      {},
      "dialog-panel-brevo"
    );
  }

  getFormURL() {
    return this.formURL;
  }
}
