import { SlicePipe } from "@angular/common";
import { Component, inject, signal } from "@angular/core";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { ArchivedEventsService } from "~features/events/archived-events.service";
import { EventGridArchivedTileComponent } from "~features/events/event-grid-tile-archived/event-grid-archived-tile.component";
import { EventSearchComponent } from "~features/events/event-search/event-search.component";
import { formatDateNow, timeZoneFormat } from "~features/events/event.utils";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

import { EventGridUpcomingTileComponent } from "../event-grid-tile-upcoming/event-grid-upcoming-tile.component";

@Component({
  selector: "app-event-grid-archived",
  standalone: true,
  imports: [
    EventGridUpcomingTileComponent,
    EventGridArchivedTileComponent,
    SlicePipe,
    PaginationComponent,
    CompanySearchComponent,
    EventSearchComponent,
  ],
  templateUrl: "./event-grid-archived.component.html",
  styleUrl: "./event-grid-archived.component.scss",
})
export class EventGridArchivedComponent {
  public timeZoneFormat = timeZoneFormat;
  currentPage = signal(1);
  itemsPerPage = signal(10);
  private eventService = inject(ArchivedEventsService);
  public listOfArchiveEvents = this.eventService.getArchivedEventsList();
  totalItems = this.eventService.getTotalCount();

  constructor() {
    this.fetchEvents();
  }

  fetchEvents(searchTerm: string = undefined) {
    this.eventService.fetchArvchivedEventsList(
      {
        page: this.currentPage(),
        limit: this.itemsPerPage(),
      },
      formatDateNow(),
      searchTerm
    );
  }
  fetchMoreEvents($event: number) {
    this.currentPage.set($event);
    this.fetchEvents();
  }

  loadEventsBySearchTerm(event) {
    const searchTerm = event;
    this.fetchEvents(searchTerm);
  }
}
