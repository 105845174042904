<div
  class="grid grod-cols-3 lg:grid-cols-6 gap-2 bg-gray-alster max-w-2xl rounded p-6"
>
  <div class="col-span-6">
    <p>Cookie permissions</p>
  </div>

  <div class="col-span-3">
    <p>
      <i
        >mwb research uses cookies to track and improve site usage patterns. For
        more information, see the
        <a class="underline" href="https://www.research-hub.de/datenschutz">
          Privacy Policy </a
        >. You can revoke your consent at any time in the footer.</i
      >
    </p>
  </div>
  <div class="col-span-3">
    <div class="col-span-1 mx-auto">
      <button
        (click)="acceptCookies()"
        class="btn btn-std w-full"
        type="button"
      >
        accept <span style="white-space: pre-line">all</span>
      </button>
    </div>
    <div class="col-span-1 mx-auto my-2"></div>
    <div class="col-span-1 mx-auto">
      <button
        (click)="denyCookies()"
        class="btn btn-transparent text-white w-full"
        type="button"
      >
        Accept Necessary
      </button>
    </div>
  </div>
</div>
