import { NgClass } from "@angular/common";
import { Component, inject } from "@angular/core";
import { RouterLink } from "@angular/router";

import { CompanyHighlightService } from "~features/company/company-highlight.service";

@Component({
  selector: "app-company-highlights-list",
  standalone: true,
  imports: [NgClass, RouterLink],
  templateUrl: "./company-highlights-list.component.html",
  styleUrl: "./company-highlights-list.component.scss",
})
export class CompanyHighlightsListComponent {
  companyHighlightsService = inject(CompanyHighlightService);
  companyList = this.companyHighlightsService.getCompanyHighlights();
}
