import { Clipboard } from "@angular/cdk/clipboard";
import { DatePipe } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from "@angular/core";
import { IEvent } from "~global-interfaces/IEvent";

import { AuthService } from "~features/auth/auth.service";
import { EventService } from "~features/events/event.service";
import { createEventRegistrationLink } from "~features/events/event.utils";
import { UserNotificationService } from "~features/user-notifications/user-notification.service";
import { ResponsiveVideoComponent } from "~features/video/responsive-video/responsive-video.component";
import { VideoPlayerComponent } from "~features/video/video-player/video-player.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { ClipboardService } from "~shared/services/clipboard.service";

@Component({
  selector: "app-customer-event-tile",
  templateUrl: "./customer-event-tile.component.html",
  standalone: true,
  imports: [ResponsiveVideoComponent, DatePipe, VideoPlayerComponent],
})
export class CustomerEventTileComponent {
  @Input() event: IEvent;
  @Input() listType = "";
  @Output() eventRemoved = new EventEmitter<any>();

  inviteLink = "";
  loadingUnregisteringFromEvent = signal(false);
  clipboardService = inject(ClipboardService);
  eventService = inject(EventService);
  private clipboard = inject(Clipboard);
  private authService = inject(AuthService);
  private userNotificationService = inject(UserNotificationService);

  constructor() {
    effect(() => {
      this.inviteLink = createEventRegistrationLink(
        this.event?.companyTicker,
        this.event?.date
      );
    }, enableSignalWritesInEffectContext);
  }

  copyTextToClipboard(textToCopy: string) {
    this.clipboardService.copyToClipboard(textToCopy);
  }

  openVideoModal(videoURL: string) {
    // TODO: Implement video modal
  }

  removeFromEventList(listId: string) {
    this.loadingUnregisteringFromEvent.set(true);
    this.authService.removeCurrentUserFromList(listId).subscribe(() => {
      this.eventRemoved.emit();
      this.userNotificationService.updateNotification(
        "Unsubscribed from event."
      );
      this.loadingUnregisteringFromEvent.set(false);
      this.eventService.fetchUserRegisteredEvents();
    });
  }

  getLogoForCompanyFromEvent(companyTicker: string): string {
    const logo = "../../assets/blanklogo.svg";
    return logo === "../../assets/blanklogo.svg"
      ? this.event.companyLogoURL
      : logo;
  }
}
