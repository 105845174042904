import { NgClass } from "@angular/common";
import { Component, inject } from "@angular/core";

import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-form-request-message",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./admin-form-request-message.component.html",
  styleUrl: "./admin-form-request-message.component.scss",
})
export class AdminFormRequestMessageComponent {
  adminRequestService = inject(AdminRequestService);
  lastRequestMessage = this.adminRequestService.getLastRequestMessage();
  lastRequestSuccess = this.adminRequestService.getLastRequestSuccess();

  dismiss() {
    this.adminRequestService.emptyLastRequest();
  }
}
