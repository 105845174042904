<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        Create an Investment Case Entry for a company
      </p>
      <p class="text-gray-600">
        This is only needed when you create a new company.
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div class="rounded-lg p-6 md:col-span-3">
        <div class="text-gray-600">
          <app-company-investmentcase-form
            (formSubmit)="onFormSubmit($event)"
            [investmentCase]="investmentCaseContent"
          ></app-company-investmentcase-form>
        </div>
      </div>
    </div>
  </main>
</div>
