<div
  [ngClass]="{
    'bg-green-400': lastRequestSuccess() === true,
    'bg-red-400': lastRequestSuccess() === false
  }"
  class="text-sm rounded p-2 flex justify-between items-center my-4"
>
  <span>{{ lastRequestMessage() }}</span>
  @if (lastRequestSuccess() === true) {
    <button (click)="dismiss()" class="ml-2 p-2 border-2 border-black rounded">
      <i class="fas fa-check"></i>
    </button>
  }
  @if (lastRequestSuccess() === false) {
    <button (click)="dismiss()" class="ml-2 p-2 border-2 border-black rounded">
      <i class="fas fa-times"></i>
    </button>
  }
</div>
