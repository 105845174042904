<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-8 max-w-3xl mx-auto">
      <h1 class="text-xl font-bold text-red-600 mb-6">Delete Research</h1>

      <div
        class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
        role="alert"
      >
        <p class="font-bold">Warning!</p>
        <p>
          You are about to delete a research entry. This action cannot be
          undone.
        </p>
      </div>

      <div class="mb-6">
        <h2 class="text-lg font-semibold mb-4">Research Information</h2>

        <p class="mb-2">
          <span class="font-semibold">Title:</span>
          {{ researchContextOverview()?.research[0].title }}
        </p>
        <p class="mb-2">
          <span class="font-semibold">Publish Date:</span>
          {{ researchContextOverview()?.research[0].published_date }}
        </p>
        <p class="mb-2">
          <span class="font-semibold">Company Ticker:</span>
          {{ researchContextOverview()?.research[0].companyTicker }}
        </p>
        <p class="mb-2">
          <span class="font-semibold">Language:</span>
          {{ researchContextOverview()?.research[0].primary_language }}
        </p>
        <p class="mb-2">
          <span class="font-semibold">Research Editor Text:</span>
          {{ researchContextOverview()?.research[0].content_DE ? "Yes" : "No" }}
        </p>
        <p class="mb-2">
          <span class="font-semibold">Research PDF Logic Connection:</span>
          {{ researchContextOverview()?.research[0].pdf_DE ? "Yes" : "No" }}
        </p>
      </div>

      <div
        class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6"
        role="alert"
      >
        <p class="font-bold">Deletion Consequences:</p>
        <ul class="list-disc list-inside">
          <li>
            The research entry on the homepage will be permanently deleted.
          </li>
          <li>Any Excel Numbers are NOT affected by this.</li>
          <li>
            Associated PDF Fils are NOT deleted, but logically disconnected from
            everything.
          </li>
          <li>
            The PDFs will stay online and the links to the files will work, but
            we can not "search" for certain PDFs anymore.
          </li>
          <li>RSS Entries are NOT deleted.</li>
          <li>This action is irreversible.</li>
        </ul>
      </div>

      <div class="flex justify-between items-center">
        <app-delete-button
          (deleteConfirmed)="deleteResearch(researchId())"
          [confirmText]="
            'Confirm Deletion of ' +
            researchContextOverview()?.research[0].title
          "
          [deleteText]="
            'Delete Research ' + researchContextOverview()?.research[0].title
          "
        >
        </app-delete-button>
      </div>
      <app-admin-form-request-message></app-admin-form-request-message>
    </div>
  </main>
</div>
