import { JsonPipe } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";

import { ExcelService } from "~features/company/excel.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

interface figureData {
  title: string;
  value: string | any[];
  valueModifier: string;
  date?: any[];
}

@Component({
  selector: "app-figure-slider",
  templateUrl: "./figure-slider.component.html",
  styleUrls: ["./figure-slider.component.scss"],
  standalone: true,
  imports: [JsonPipe],
})
export class FigureSliderComponent {
  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  @Input() tile: WritableSignal<any> = signal(null);
  @Input() decimals = 0;
  @Input() lang = "eng";

  tableUtils = inject(Tables);
  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  index: WritableSignal<number> = signal(0);
  excelService = inject(ExcelService);
  excelMapping = this.excelService.getExcelMapping();

  data: WritableSignal<figureData[] | null> = signal(null);
  counter = 0;

  constructor() {
    effect(() => {
      if (!this.data()) {
        this.prepareData(this.excelMapping(), this.companyData());
      }
    }, enableSignalWritesInEffectContext);
  }

  prepareData(mapping, companyData) {
    if (mapping && companyData && companyData.base) {
      //console.log("SLIDER: got all data");
      for (const key of this.tile().content) {
        // console.log("SLIDER:working on ", key);
        if (mapping[key]) {
          //  console.log("SLIDER: found in mapping ", key, mapping[key]);
          const mappingEntry = mapping[key];
          let entry: figureData;
          if (mappingEntry.type === "VALUE") {
            //   console.log("SLIDER:  ", key, " is VALUE");
            entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getValue(
                companyData,
                mappingEntry,
                this.lang,
                this.decimals
              )[0],
            } as figureData;
          } else if (mappingEntry.type === "TIMESERIES") {
            // console.log("SLIDER:  ", key, " is TIMESERIES");
            entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getOneFromTimeseries(
                companyData,
                mappingEntry,
                1,
                "value",
                this.lang,
                this.decimals
              )[0],
            } as figureData;
            if (Number(entry.value) < 100) {
              // console.log("SLIDER:  ", key, " is HACKED");
              //Hack to show small numbers with decimal
              entry.value = this.tableUtils.getOneFromTimeseries(
                companyData,
                mappingEntry,
                1,
                "value",
                this.lang,
                1
              )[0];
            }
          }

          if (entry) {
            if (!mappingEntry.unit) {
              entry.valueModifier = " in " + companyData?.base?.curr + "m";
            } else if (mappingEntry.unit === "FIX") {
              entry.valueModifier = " in " + companyData?.base?.curr;
            }
            // console.log("SLIDER:  TRYING TO UPDATE DATA", entry);
            //  console.log(this.data());
            this.counter = this.counter + 1;

            if (!this.data() || this.data().length === 0) {
              this.data.set([entry]);
            } else {
              const newData = [...this.data(), entry];
              this.data.set(newData);
            }
          }
        }
      }
    }
    // After preparing the data, initialize the title with valueModifier
    if (this.data() && this.data().length > 0) {
      setTimeout(() => {
        this.navigate(1); // Move forward
        this.navigate(-1); // Move back
      }, 1);
    }
  }

  navigate(step: number) {
    const length = this.data().length;
    const newIndex = (this.index() + step + length) % length;
    this.index.set(newIndex);

    let linkedTitle = this.data()[newIndex].title;
    if (this.data()[newIndex].valueModifier) {
      linkedTitle += this.data()[newIndex].valueModifier;
    }
    this.tileEvent.emit({
      type: "key-change",
      change: {
        content: [this.tile().content[newIndex]],
        title: linkedTitle,
      },
    });
  }
}
