import { Component, inject } from "@angular/core";

import { AdminInfoSideeffectService } from "~features/admin-info-sideeffects/admin-info-sideeffect.service";

@Component({
  selector: "app-admin-info-sideeffects-toast",
  standalone: true,
  imports: [],
  templateUrl: "./admin-info-sideeffects-toast.component.html",
  styleUrl: "./admin-info-sideeffects-toast.component.scss",
})
export class AdminInfoSideeffectsToastComponent {
  adminInfoSideeffectService = inject(AdminInfoSideeffectService);
  inputTitle = this.adminInfoSideeffectService.toastTitle;
  infoText = this.adminInfoSideeffectService.toastInfotext;
  showToast = this.adminInfoSideeffectService.showToast;
}
