import { JsonPipe, KeyValuePipe } from "@angular/common";
import {
  Component,
  effect,
  inject,
  signal,
  WritableSignal,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IHubAccount } from "~global-interfaces/IHubAccount";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { AdminUserEditorFormComponent } from "~features/users/admin/admin-user-editor-form/admin-user-editor-form.component";
import { UserService } from "~features/users/user.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";
import { LockIconComponent } from "~shared/components/ui/lock-icon/lock-icon.component";

@Component({
  selector: "app-admin-user-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminUserEditorFormComponent,
    AdminSidePanelComponent,
    CompanySearchComponent,
    JsonPipe,
    LockIconComponent,
    KeyValuePipe,
    DeleteButtonComponent,
  ],
  templateUrl: "./admin-user-editor.component.html",
  styleUrl: "./admin-user-editor.component.scss",
})
export class AdminUserEditorComponent {
  userService = inject(UserService);
  user: WritableSignal<IHubAccount> = signal<IHubAccount>({
    _id: "",
    addinIds: [],
    bloombergTicker: "",
    companyName: "",
    confirmationToken: "",
    confirmed: false,
    customerType: "",
    email: "",
    firstName: "",
    isBrevoContact: false,
    isCorporateUser: false,
    lastName: "",
    password: "",
    rights: undefined,
    type: "",
    userId: "",
    userName: "",
    userRole: "",
  });
  investorProfile: WritableSignal<any | null> = signal<any | null>(null);
  showDeletionWarning: WritableSignal<boolean> = signal(false);
  protected readonly JSON = JSON;

  constructor(private route: ActivatedRoute) {
    const userId = this.route.snapshot.paramMap.get("userid") ?? "";
    this.userService.fetchUser(userId);
    this.user = this.userService.getUser();

    effect(() => {
      if (this.user()) {
        this.userService.fetchInvestorProfileForEditor(this.user().email);
        this.investorProfile = this.userService.investorProfileForEditor;
      }
    }, enableSignalWritesInEffectContext);
  }

  deleteUser(_id) {
    this.userService.deleteUser(_id);
  }

  toggleDeletionWarning(showWarning: boolean) {
    this.showDeletionWarning.set(showWarning);
  }
  onFormSubmit(formData: IHubAccount) {
    this.userService.updateUser(formData);
  }
}
