import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { JsonPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";

import { ResearchListComponent } from "~features/research/research-list/research-list.component";

@Component({
  selector: "app-research-list-dialog",
  standalone: true,
  imports: [ResearchListComponent, JsonPipe],
  templateUrl: "./research-list-dialog.component.html",
  styleUrl: "./research-list-dialog.component.scss",
})
export class ResearchListDialogComponent {
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any
  ) {}
}
