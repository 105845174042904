import { Component } from "@angular/core";

import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { CompanyGridHighlightComponent } from "~features/company-grid/company-grid-highlight/company-grid-highlight.component";
import { AutoSliderLandingPageComponent } from "~features/custom-content-slider/auto-slider-landing-page/auto-slider-landing-page.component";
import { EventGridArchivedComponent } from "~features/events/event-grid-archived/event-grid-archived.component";
import { EventGridUpcomingComponent } from "~features/events/event-grid-upcoming/event-grid-upcoming.component";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";

@Component({
  selector: "app-all-stocks",
  templateUrl: "./all-stocks-page.component.html",
  styleUrls: ["./all-stocks-page.component.scss"],
  standalone: true,
  imports: [
    CompanyGridComponent,
    SubscribeBannerComponent,
    AutoSliderLandingPageComponent,
    EventGridArchivedComponent,
    EventGridUpcomingComponent,
    MoreContentCtaComponent,
    CompanyGridHighlightComponent,
  ],
})
export class AllStocksPageComponent {
  constructor() {}
}
