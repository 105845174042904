import { Component, inject, signal, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ILegalDisclaimerCompany } from "~global-interfaces/ILegalDisclaimerCompany";

import { LegalDisclaimerService } from "~features/legal/legal-disclaimer.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-legal-disclaimer-company-list",
  standalone: true,
  imports: [BreadcrumpComponent, RouterLink, AdminSidePanelComponent],
  templateUrl: "./admin-legal-disclaimer-company-list.component.html",
  styleUrl: "./admin-legal-disclaimer-company-list.component.scss",
})
export class AdminLegalDisclaimerCompanyListComponent {
  legalDisclaimerService = inject(LegalDisclaimerService);
  legalCompaniesList: WritableSignal<ILegalDisclaimerCompany[] | null> = signal<
    ILegalDisclaimerCompany[] | null
  >(null);

  constructor() {
    this.fetchLegalDisclaimerCompanies();
  }

  fetchLegalDisclaimerCompanies() {
    this.legalDisclaimerService.fetchLegalDisclaimerCompanies();
    this.legalCompaniesList =
      this.legalDisclaimerService.getLegalDisclaimerCompanies();
  }
}
