@if (color === "darkblue") {
  <svg
    fill="none"
    height="13"
    viewBox="0 0 15 15"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
      fill="#0F6BB2"
    ></path>
  </svg>
} @else {
  <svg
    fill="none"
    height="13"
    viewBox="0 0 15 15"
    width="13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
      fill="#FFFFFF"
    ></path>
  </svg>
}
