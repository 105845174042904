<div class="container mx-auto px-4">
  <div class="flex flex-wrap">
    <div class="w-full md:w-2/3 pr-4">
      <app-file-list></app-file-list>
    </div>
    <div class="w-full md:w-1/3">
      <app-file-upload></app-file-upload>
    </div>
  </div>
</div>
