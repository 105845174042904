import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mifid-disclaimer-page",
  templateUrl: "./mifid-disclaimer-page.component.html",
  styleUrls: ["./mifid-disclaimer-page.component.scss"],
  standalone: true,
})
export class MifidDisclaimerPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
