<div class="container">
  <div class="flex w-full px-16 py-16">
    <div class="mx-auto w-8/12">
      <h2 class="mt-5">Privacy Policy (English)</h2>

      <p>This website collects personal data from its users.</p>

      <h4>Provider and responsible</h4>

      <p>
        mwb research AG<br />Thomas Wissler<br />Mittelweg 142<br />20148
        Hamburg<br />Deutschland
      </p>

      <p>
        <strong>E-mail address of the provider:</strong
        >&nbsp;info&#64;mwb-research.com
      </p>

      <h4>Types of data collected</h4>

      <p>
        Personal data processed by this website independently or through third
        parties include: Cookie, Usage Data, Email, First Name, Last Name and
        Website.
      </p>

      <p>
        Full details of each type of personal data processed are provided in the
        designated sections of this Privacy Policy or selectively through
        explanatory texts displayed prior to data collection. Personal data may
        be provided voluntarily by the user or, in the case of usage data, may
        be collected automatically when this website is used. Unless otherwise
        specified, the provision of all data requested by this website is
        mandatory. If the user refuses to provide the data, this may result in
        this website being unable to provide the user with its services. In
        cases where this website expressly states that the provision of personal
        data is voluntary, users may choose not to provide such data without any
        consequences for the availability or functioning of the service. Users
        who are unclear about which personal data is mandatory may contact the
        provider. Any use of cookies &#8211; or other tracking tools &#8211; by
        this website or third-party service providers used by this website is
        for the purpose of providing the service requested by the user and any
        other purposes described in this document and, if any, in the Cookie
        Policy.
      </p>

      <p>
        Users are responsible for all third party personal data obtained,
        published or disclosed through this website and confirm that they have
        obtained consent for the transfer of personal data of any third parties
        to this website.
      </p>

      <p><strong>Type and place of data processing</strong></p>

      <p><strong>Processing methods</strong></p>

      <p>
        The Provider processes User data in a proper manner and takes
        appropriate security measures to prevent unauthorized access and
        unauthorized forwarding, modification or destruction of data. Data
        processing is carried out by means of computers or IT-based systems in
        accordance with organizational procedures and practices specifically
        aimed at the stated purposes. In addition to the data controller, other
        parties may operate this website internally (human resources, sales,
        marketing, legal department, system administrators) or externally
        &#8211; and in the case where necessary, designated by the data
        controller as processors (such as technical service providers, delivery
        companies, hosting providers, IT companies or communication agencies)
        &#8211; and therefore have access to the data. An up-to-date list of
        these parties can be requested from the provider at any time.
      </p>

      <p><strong>Legal bases of the processing</strong></p>

      <p>
        The provider may process personal data of users only if one of the
        following applies.
      </p>

      <p>
        Users have given their consent for one or more specific purposes. Note:
        In some jurisdictions, the provider may be permitted to process personal
        data until the user objects to such processing (&#8220;opt-out&#8221;)
        without relying on consent or any other of the following legal bases.
        However, this does not apply if the processing of personal data is
        subject to European data protection law.
      </p>

      <p>
        the processing is necessary for compliance with a legal obligation to
        which the provider is subject; or
      </p>

      <p>
        the processing is related to a task carried out in the public interest
        or in the exercise of official authority vested in the Provider;
      </p>

      <p>
        the processing is necessary to protect the legitimate interests of the
        Provider or a third party.
      </p>

      <p>
        In any case, the Provider will be happy to provide information about the
        specific legal basis on which the processing is based, in particular
        whether the provision of personal data is a legal or contractual
        obligation or a prerequisite for the conclusion of a contract.
      </p>

      <h4>Place</h4>

      <p>
        The data will be processed at the Provider&#8217;s office and at all
        other locations where the entities involved in the data processing are
        located.
      </p>

      <p>
        Depending on the location of the Users, data transfers may involve the
        transfer of the User&#8217;s data to a country other than the
        User&#8217;s own. To learn more about the location of the processing of
        the transferred data, users may consult the section detailing the
        processing of personal data.
      </p>

      <p>
        Users also have the right to be informed about the legal basis of the
        transfer of data to a country outside the European Union or to an
        international organization governed by international law or established
        by two or more countries, such as the UN, as well as to be informed
        about the security measures taken by the Provider to protect their data.
      </p>

      <p>
        If such transfer takes place, the User may learn more about it by
        reviewing the relevant sections of this document or by contacting the
        Provider through the information provided in the contact section.
      </p>

      <h4>Storage duration</h4>

      <p>
        Personal data is processed and stored for as long as required by the
        purpose for which it was collected.
      </p>

      <p>Therefore.</p>

      <p>
        Personal data collected for the purpose of fulfilling a contract
        concluded between the provider and the user will be stored until the
        complete fulfillment of this contract.
      </p>

      <p>
        Personal data collected for the purpose of safeguarding the
        Provider&#8217;s legitimate interests will be kept for as long as it is
        necessary for the fulfillment of these purposes. Users may obtain more
        detailed information about the Provider&#8217;s legitimate interests in
        the relevant sections of this document or by contacting the Provider.
      </p>

      <p>
        In addition, the provider is permitted to store personal data for a
        longer period of time if the user has consented to such processing, as
        long as the consent is not revoked. In addition, the provider may be
        obliged to store personal data for a longer period of time if this is
        necessary to fulfill a legal obligation or by order of an authority.
      </p>

      <p>
        After expiration of the retention period, personal data will be deleted.
        Therefore, the right to information, the right to deletion, the right to
        rectification and the right to data portability cannot be asserted after
        the retention period has expired.
      </p>

      <h4>Processing purposes</h4>

      <p>
        Personal data about the user is collected so that the provider can
        provide the services. In addition, data is collected for the following
        purposes: Interaction with external social networks and platforms, SPAM
        protection, contacting the user and commenting function.
      </p>

      <p>
        Users can find more detailed information about these processing purposes
        and the personal data used for each purpose in the relevant sections of
        this document.
      </p>

      <h4>Detailed information on the processing of personal data</h4>

      <p>
        Personal data is collected for the following purposes using the
        following services:
      </p>

      <h4>Analytics</h4>

      <p>
        The services listed in this section allow the Owner to monitor and
        analyze traffic and track user behavior.
      </p>

      <p><strong>Matomo</strong></p>

      <p>
        Matomo is an open source analytics software program that this website
        uses to analyze data directly without the help of third parties.
      </p>

      <p>
        Matomo uses &#8220;cookies.&#8221; Information generated with them about
        the use of this website is stored on the server. Before storage, your IP
        address is anonymized.
      </p>

      <p>
        Cookies from Matomo remain on your terminal device until you delete
        them.
      </p>

      <p>
        The setting of Matomo cookies is based on Art. 6 (1) lit. f DSGVO. As
        the operator of this website, we have a legitimate interest in the
        anonymized analysis of user behavior, both to optimize our website.
      </p>

      <p>
        There is no disclosure of the information stored in the Matomo cookie
        about the use of this website. The setting of cookies by your web
        browser can be prevented. However, some functions of our website could
        be restricted as a result.
      </p>

      <p>
        You can deactivate the storage and use of your data here. Your browser
        sets an opt-out cookie, which prevents the storage of Matomo usage data.
        If you delete your cookies, the Matomo opt-out cookie will also be
        removed. When you visit our website again, the opt-out cookie must be
        set again to prevent the storage and use of your data.
      </p>

      <p>Personal data collected: Cookie and usage data.</p>

      <p>
        Deactivation carried out! Your visits to this website will no longer be
        collected by web analytics. Please note that the Matomo deactivation
        cookie of this website will also be deleted if you remove the cookies
        stored in your browser. In addition, if you use a different computer or
        a different web browser, you will need to complete the deactivation
        procedure again.
      </p>

      <h4>Display content from external platforms</h4>

      <p>
        This type of service allows users to view and interact with content
        hosted on external platforms directly through this website. If such a
        service is installed, it may collect data from traffic for the pages on
        which it is installed even if users do not use it.
      </p>

      <p><strong>Google-Maps-Widget (Google Inc.)</strong></p>

      <p>
        Google Maps is a map visualization service provided by Google Inc. that
        allows this website to embed relevant content on its pages.
      </p>

      <p>Personal data collected: Cookie and usage data.</p>

      <p>
        Processing location: USA &#8211; Privacy Policy. Privacy Shield member.
      </p>

      <p><strong>Google Fonts (Google Inc.)</strong></p>

      <p>
        Google Fonts is a font visualization service provided by Google Inc.
        that allows this website to embed relevant content on its pages.
      </p>

      <p>
        Personal data collected: Usage data and various types of data as
        described in the privacy policy of the service.
      </p>

      <p>
        Processing Location: USA &#8211; Privacy Policy. Privacy Shield Member.
      </p>

      <h4>Interaction with external social networks and platforms</h4>

      <p>
        These types of services allow interaction with social networks or other
        external platforms directly through this website. The interaction, as
        well as the information collected through this website, is always
        subject to the privacy settings made by the users for the respective
        social network. If a service is installed for interaction with social
        networks, it may collect data from traffic for the pages on which it is
        installed even if users do not use the service.
      </p>

      <p><strong>AddToAny (AddToAny LLC)</strong></p>

      <p>
        AddToAny is a service provided by AddToAny LLC for displaying a widget
        that allows users to interact with social networks and external
        platforms, as well as share the content that this website contains.
        Depending on the settings, this service may display third-party widgets
        &#8211; for example, the leaders of social networks where interactions
        are shared. In this case, the third party providers of the widget will
        also be notified about the interactions and usage data of the pages
        where the service is installed.
      </p>

      <p>Personal data collected: Cookie and usage data.</p>

      <p>Processing location: United States -Privacy Policy.</p>

      <h4>Comment function</h4>

      <p>
        Comment services allow users to write and publish comments on the
        content that this website contains. Depending on the provider&#8217;s
        settings, users may also leave anonymous comments. If users have also
        provided their e-mail address with their personal data, this may be used
        to notify users of comments on the same content. Users are responsible
        for the content of their own comments. If a third-party comment service
        is installed, the third-party comment service may collect traffic data
        for the pages on which the comment service is installed even if the user
        does not use the comment service.
      </p>

      <p><strong>Own comment system</strong></p>

      <p>This website has its own internal comment function.</p>

      <p>Personal data collected: Email, last name, first name and website.</p>

      <h4>Contacting the user</h4>

      <p><strong>Contact form</strong></p>

      <p>
        By filling out the contact form with their information, users authorize
        this website to use their information to respond to requests for
        information, quotes, or other inquiries indicated in the form header.
      </p>

      <p>Personal data collected: Email, last name and first name.</p>

      <p><strong>Mailing list or newsletter</strong></p>

      <p>
        By subscribing to the mailing list or newsletter, the user&#8217;s email
        address is added to the contact list of people who may receive email
        messages with commercial or promotional information related to this
        website. In addition, your email address may be added to this list if
        you have signed up for this website.
      </p>

      <p>Personal Data Collected: Email, Last Name and First Name.</p>

      <h4>SPAM protection</h4>

      <p>
        These types of services analyze traffic that passes through this website
        and may contain personal data of users in order to filter out portions
        of traffic, messages and content that are considered SPAM.
      </p>

      <h4>Google reCAPTCHA (Google Inc.)</h4>

      <p>
        Google reCAPTCHA is a SPAM protection service provided by Google Inc.
        The use of reCAPTCHA is subject to Google&#8217;s Privacy Policy and
        Terms of Use. Personal data collected: Cookie and usage data.
      </p>

      <p>
        Processing location: United States &#8211; Privacy Policy. Privacy
        Shield Member.
      </p>

      <h4>The rights of users</h4>

      <p>
        Users may exercise certain rights in relation to their data processed by
        the Provider.
      </p>

      <p>In particular, users have the right to do the following.</p>

      <ul>
        <li>Revoke the consents at any time.</li>

        <li>
          If the user has previously consented to the processing of personal
          data, they may revoke their own consent at any time.
        </li>

        <li>Object to the processing of their data.</li>

        <li>
          The user has the right to object to the processing of their data if
          the processing is based on a legal basis other than consent. Further
          information on this is provided below.
        </li>

        <li>Receive information regarding their data.</li>

        <li>
          The user has the right to know whether the data is being processed by
          the provider, to receive information about individual aspects of the
          processing and to obtain a copy of the data.
        </li>

        <li>To have the data checked and corrected.</li>

        <li>
          The user has the right to check the accuracy of their data and request
          that it be updated or corrected.
        </li>

        <li>Request restriction of the processing of their data.</li>

        <li>
          Users have the right to restrict the processing of their data under
          certain circumstances. In this case, the Provider will not process the
          data for any purpose other than storage.
        </li>

        <li>Request deletion or other removal of personal data.</li>

        <li>
          Users have the right, under certain circumstances, to request that
          their data be erased by the Provider.
        </li>

        <li>
          Receive their data and have it transferred to another responsible
          party.
        </li>

        <li>
          Users have the right to receive their data in a structured, common and
          machine-readable format and, if technically possible, to have it
          transferred to another controller without hindrance. This provision is
          applicable if the data is processed by automated means and the
          processing is based on the user&#8217;s consent, on a contract to
          which the user is party, or on pre-contractual obligations.
        </li>

        <li>Filing a complaint.</li>

        <li>
          Users have the right to file a complaint with the competent
          supervisory authority. The following link provides a list of data
          protection officers and their contact details:
          https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
        </li>
      </ul>

      <h4>Details on the right to object to processing</h4>

      <p>
        If personal data are processed in the public interest, in the exercise
        of a sovereign power conferred on the Provider or to safeguard the
        Provider&#8217;s legitimate interests, the User may object to such
        processing by providing a justification relating to his or her
        particular situation.
      </p>

      <p>
        Users are informed that they may object to the processing of personal
        data for direct marketing at any time without giving reasons. To find
        out whether the Provider processes personal data for direct marketing
        purposes, Users may refer to the relevant sections of this document.
      </p>

      <h4>How to exercise the rights</h4>

      <p>
        All requests to exercise user rights can be directed to the provider via
        the contact details provided in this document. Requests can be exercised
        free of charge and will be processed by the Provider as soon as
        possible, at the latest within one month.
      </p>

      <h4>Further information on the collection and processing of data</h4>

      <p>Legal measures</p>

      <p>
        The User&#8217;s personal data may be processed by the Provider for the
        purposes of legal enforcement within or in preparation of legal
        proceedings resulting from the improper use of this Website or its
        associated services. The User declares that he/she is aware that the
        Provider may be obliged by the authorities to hand over personal data.
      </p>

      <p>Further information about the user&#8217;s personal data</p>

      <p>
        In addition to the information set forth in this Privacy Policy, this
        website may provide the User, upon request, with additional contextual
        information related to specific services or to the collection and
        processing of personal data.
      </p>

      <p>System Protocols and Maintenance</p>

      <p>
        This website and third party services may collect files that record
        interaction taking place through this website (system logs) for
        operational and maintenance purposes, or use other personal data (e.g.
        IP address) for this purpose.
      </p>

      <p>SSL or TLS encryption</p>

      <p>
        For security reasons and to protect the transmission of confidential
        content that you send to the site operator, this website uses SSL or TLS
        encryption. This means that data transmitted via this website cannot be
        read by third parties. You can recognize an encrypted connection by the
        &#8220;https://&#8221; address line of your browser and the lock symbol
        in the browser line.
      </p>

      <p>Information not contained in this data protection declaration</p>

      <p>
        Further information on the collection or processing of personal data can
        be requested from the provider at any time using the contact details
        listed.
      </p>

      <p>Changes to this privacy policy</p>

      <p>
        The Provider reserves the right to make changes to this Privacy Policy
        at any time by notifying its users accordingly on this page and, where
        applicable, via this website and/or &#8211; where technically and
        legally possible &#8211; by sending a message to users via one of the
        contact details available to the Provider. Users are therefore advised
        to visit this page regularly, checking the date of the last modification
        indicated at the bottom of the page.
      </p>

      <p>
        Insofar as changes affect a data use based on the consent of the user,
        the provider will &#8211; if necessary &#8211; obtain a new consent.<br />
      </p>

      <h4>Definitions and legal information</h4>

      <p>Personal data (or data)</p>

      <p>
        Any information by which the identity of a natural person is or can be
        determined, directly or in combination with other information.
      </p>

      <p>Usage data</p>

      <p>
        Information that this website (or third party services that this website
        uses) automatically collects, such as: the IP addresses or domain names
        of the computers of users who use this website, the URI (Uniform
        Resource Identifier) addresses, the time of the request, the method used
        to send the request to the server, the size of the response file
        received, the numerical code indicating the status of the server
        response (successful result, error, etc.), the country of origin, the
        features of the browser and operating system used by the user, and the
        type of data collected. ), the country of origin, the functions of the
        browser and operating system used by the user, the various time details
        per request (e.g. how much time was spent on each page of the website)
        and information about the path followed within a website, in particular
        the order of the pages visited, as well as other information about the
        operating system of the device and/or the IT environment of the user.
      </p>

      <p>User</p>

      <p>
        The person using this website, which, unless otherwise specified,
        coincides with the data subject.
      </p>

      <p>Data subject</p>

      <p>The natural person to whom the personal data relates.</p>

      <p>Processor (or data processor)</p>

      <p>
        Natural or legal person, authority, institution or other body that
        processes Personal Data on behalf of the Controller, as described in
        this Privacy Policy.
      </p>

      <p>Controller (or provider, sometimes also owner).</p>

      <p>
        The natural or legal person, public authority, agency or other body
        which alone or jointly with others determines the purposes and means of
        the processing of personal data and the means used for that purpose,
        including the security measures relating to the operation and use of
        this website. Unless otherwise specified, the controller is the natural
        or legal person through which this website is offered.
      </p>

      <p>This website</p>

      <p>
        The hardware or software tool used to collect and process the
        user&#8217;s personal data.
      </p>

      <p>Service</p>

      <p>
        The service offered through this website, as described in the relevant
        terms of use (if any) and on this page/website.
      </p>

      <p>European Union (or EU).</p>

      <p>
        Unless otherwise stated, all references in this document to the European
        Union refer to all current member states of the European Union and the
        European Economic Area (EEA).
      </p>

      <p>Cookie</p>

      <p>Small file that is stored on the user&#8217;s device.</p>

      <p>Legal notice</p>

      <p>
        This Privacy Policy has been written based on provisions of various
        legislations, including Article 13/14 of Regulation (EU) 2016/679
        (General Data Protection Regulation).
      </p>

      <p>
        This privacy policy applies exclusively to this website, unless
        otherwise stated in this document.<br />
      </p>

      <h2 class="mt-5">Datenschutzerklärung (Deutsch)</h2>
      <p>Diese Webseite erhebt personenbezogene Daten von ihren Nutzern.</p>

      <h4>Anbieter und Verantwortlicher</h4>
      <p>
        mwb research AG<br />
        Thomas Wissler<br />
        Mittelweg 142<br />
        20148 Hamburg<br />
        Deutschland
      </p>

      <p>
        <strong>E-Mail-Adresse des Anbieters:</strong> info&#64;mwb-research.com
      </p>
      <h4>Arten der erhobenen Daten</h4>
      <p>
        Zu den personenbezogenen Daten, die diese Webseite selbstständig oder
        durch Dritte verarbeitet, gehören: Cookie, Nutzungsdaten, E-Mail,
        Vorname, Nachname und Website.
      </p>
      <p>
        Vollständige Details zu jeder Art von verarbeiteten personenbezogenen
        Daten werden in den dafür vorgesehenen Abschnitten dieser
        Datenschutzerklärung oder punktuell durch Erklärungstexte
        bereitgestellt, die vor der Datenerhebung angezeigt werden.
        Personenbezogene Daten können vom Nutzer freiwillig angegeben oder, im
        Falle von Nutzungsdaten, automatisch erhoben werden, wenn diese Webseite
        genutzt wird. Sofern nicht anders angegeben, ist die Angabe aller durch
        diese Webseite angeforderten Daten obligatorisch. Weigert sich der
        Nutzer, die Daten anzugeben, kann dies dazu führen, dass diese Webseite
        dem Nutzer ihre Dienste nicht zur Verfügung stellen kann. In Fällen, in
        denen diese Webseite die Angabe personenbezogener Daten ausdrücklich als
        freiwillig bezeichnet, dürfen sich die Nutzer dafür entscheiden, diese
        Daten ohne jegliche Folgen für die Verfügbarkeit oder die
        Funktionsfähigkeit des Dienstes nicht anzugeben. Nutzer, die sich
        darüber im Unklaren sind, welche personenbezogenen Daten obligatorisch
        sind, können sich an den Anbieter wenden. Jegliche Verwendung von
        Cookies – oder anderer Tracking-Tools – durch diese Webseite oder
        Anbieter von Drittdiensten, die durch diese Webseite eingesetzt werden,
        dient dem Zweck, den vom Nutzer gewünschten Dienst zu erbringen, und
        allen anderen Zwecken, die im vorliegenden Dokument und, falls
        vorhanden, in der Cookie-Richtlinie beschrieben sind.
      </p>
      <p>
        Die Nutzer sind für alle personenbezogenen Daten Dritter verantwortlich,
        die durch diese Webseite beschafft, veröffentlicht oder weitergegeben
        werden, und bestätigen, dass sie die Zustimmung zur Übermittlung
        personenbezogener Daten etwaiger Dritter an diese Webseite eingeholt
        haben.
      </p>
      <h4>Art und Ort der Datenverarbeitung</h4>
      <p>
        <strong>Verarbeitungsmethoden</strong>
      </p>
      <p>
        Der Anbieter verarbeitet die Nutzerdaten auf ordnungsgemäße Weise und
        ergreift angemessene Sicherheitsmaßnahmen, um den unbefugten Zugriff und
        die unbefugte Weiterleitung, Veränderung oder Vernichtung von Daten zu
        vermeiden. Die Datenverarbeitung wird mittels Computern oder
        IT-basierten Systemen nach organisatorischen Verfahren und
        Verfahrensweisen durchgeführt, die gezielt auf die angegebenen Zwecke
        abstellen. Zusätzlich zum Verantwortlichen könnten auch andere Personen
        intern (Personalverwaltung, Vertrieb, Marketing, Rechtsabteilung,
        Systemadministratoren) oder extern – und in dem Fall soweit
        erforderlich, vom Verantwortlichen als Auftragsverarbeiter benannt (wie
        Anbieter technischer Dienstleistungen, Zustellunternehmen,
        Hosting-Anbieter, IT-Unternehmen oder Kommunikationsagenturen) – diese
        Webseite betreiben und damit Zugriff auf die Daten haben. Eine aktuelle
        Liste dieser Beteiligten kann jederzeit vom Anbieter verlangt werden.
      </p>
      <p>
        <strong> Rechtsgrundlagen der Verarbeitung </strong>
      </p>
      <p>
        Der Anbieter darf personenbezogene Daten von Nutzern nur dann
        verarbeiten, wenn einer der folgenden Punkte zutrifft:
      </p>
      ​
      <ul>
        <li>
          <p>
            Die Nutzer haben ihre Einwilligung für einen oder mehrere bestimmte
            Zwecke erteilt. Hinweis: In einigen Gesetzgebungen kann es dem
            Anbieter gestattet sein, personenbezogene Daten zu verarbeiten, bis
            der Nutzer einer solchen Verarbeitung widerspricht (“Opt-out”), ohne
            sich auf die Einwilligung oder eine andere der folgenden
            Rechtsgrundlagen verlassen zu müssen. Dies gilt jedoch nicht, wenn
            die Verarbeitung personenbezogener Daten dem europäischen
            Datenschutzrecht unterliegt;
          </p>
          ​
        </li>
        <p>
          die Datenerhebung ist für die Erfüllung eines Vertrages mit dem Nutzer
          und/oder für vorvertragliche Maßnahmen daraus erforderlich
        </p>
        <li>
          <p>
            die Verarbeitung ist für die Erfüllung einer rechtlichen
            Verpflichtung, der der Anbieter unterliegt, erforderlich
          </p>
        </li>
        <li>
          <p>
            die Verarbeitung steht im Zusammenhang mit einer Aufgabe, die im
            öffentlichen Interesse oder in Ausübung hoheitlicher Befugnisse, die
            dem Anbieter übertragen wurden, durchgeführt wird;
          </p>
        </li>
        <li>
          <p>
            die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Anbieters oder eines Dritten erforderlich.
          </p>
        </li>
        ​
      </ul>
      <p>
        In jedem Fall erteilt der Anbieter gerne Auskunft über die konkrete
        Rechtsgrundlage, auf der die Verarbeitung beruht, insbesondere darüber,
        ob die Angabe personenbezogener Daten eine gesetzliche oder vertragliche
        Verpflichtung oder eine Voraussetzung für den Abschluss eines Vertrages
        ist.
      </p>
      <h4>Ort</h4>
      <p>
        Die Daten werden in der Niederlassung des Anbieters und an allen anderen
        Orten, an denen sich die an der Datenverarbeitung beteiligten Stellen
        befinden, verarbeitet.
      </p>
      <p>
        Je nach Standort der Nutzer können Datenübertragungen die Übertragung
        der Daten des Nutzers in ein anderes Land als das eigene beinhalten. Um
        mehr über den Ort der Verarbeitung der übermittelten Daten zu erfahren,
        können die Nutzer den Abschnitt mit den ausführlichen Angaben zur
        Verarbeitung der personenbezogenen Daten konsultieren.
      </p>
      <p>
        Die Nutzer haben auch das Recht, sich über die Rechtsgrundlage der
        Datenübermittlung in ein Land außerhalb der Europäischen Union oder an
        eine internationale Organisation, die dem Völkerrecht unterliegt oder
        von zwei oder mehr Ländern gegründet wurde, wie beispielsweise die UNO,
        sowie sich über die vom Anbieter ergriffenen Sicherheitsmaßnahmen zum
        Schutz ihrer Daten aufklären zu lassen.
      </p>
      <p>
        Wenn eine solche Übermittlung stattfindet, kann der Nutzer mehr darüber
        erfahren, indem er die entsprechenden Abschnitte dieses Dokuments
        überprüft oder sich mit dem Anbieter über die im Kontaktteil angegebenen
        Informationen in Verbindung setzt.
      </p>

      <h4>Speicherdauer</h4>
      <p>
        Personenbezogene Daten werden so lange verarbeitet und gespeichert, wie
        es der Zweck erfordert, zu dem sie erhoben wurden.
      </p>
      <p>Daher gilt:</p>
      ​
      <ul>
        <li>
          <p>
            Personenbezogene Daten, die zu Zwecken der Erfüllung eines zwischen
            dem Anbieter und dem Nutzer geschlossenen Vertrages erhoben werden,
            werden bis zur vollständigen Erfüllung dieses Vertrages gespeichert.
          </p>
        </li>
        <li>
          <p>
            Personenbezogene Daten, die zur Wahrung der berechtigten Interessen
            des Anbieters erhoben werden, werden so lange aufbewahrt, wie es zur
            Erfüllung dieser Zwecke erforderlich ist. Nutzer können nähere
            Informationen über die berechtigten Interessen des Anbieters in den
            entsprechenden Abschnitten dieses Dokuments oder durch
            Kontaktaufnahme zum Anbieter erhalten.
          </p>
        </li>
        ​
      </ul>
      <p>
        Darüber hinaus ist es dem Anbieter gestattet, personenbezogene Daten für
        einen längeren Zeitraum zu speichern, wenn der Nutzer in eine solche
        Verarbeitung eingewilligt hat, solange die Einwilligung nicht widerrufen
        wird. Darüber hinaus kann der Anbieter verpflichtet sein,
        personenbezogene Daten für einen längeren Zeitraum aufzubewahren, wenn
        dies zur Erfüllung einer gesetzlichen Verpflichtung oder auf Anordnung
        einer Behörde erforderlich ist.
      </p>
      <p>
        Nach Ablauf der Aufbewahrungsfrist werden personenbezogene Daten
        gelöscht. Daher können das Auskunftsrecht, das Recht auf Löschung, das
        Recht auf Berichtigung und das Recht auf Datenübertragbarkeit nach
        Ablauf der Aufbewahrungsfrist nicht geltend gemacht werden.
      </p>

      <h4>Zwecke der Verarbeitung</h4>
      <p>
        Personenbezogene Daten über den Nutzer werden erhoben, damit der
        Anbieter die Dienstleistungen erbringen kann. Darüber hinaus werden
        Daten zu folgenden Zwecken erhoben: Interaktion mit externen sozialen
        Netzwerken und Plattformen, SPAM-Schutz, Kontaktieren des Nutzers und
        Kommentarfunktion.
      </p>
      <p>
        Nutzer können in den entsprechenden Abschnitten dieses Dokuments weitere
        detaillierte Informationen zu diesen Verarbeitungszwecken und zu den für
        den jeweiligen Zweck verwendeten personenbezogenen Daten vorfinden.
      </p>
      <h4>
        Ausführliche Angaben über die Verarbeitung personenbezogener Daten
      </h4>
      <p>
        Personenbezogene Daten werden zu folgenden Zwecken unter Inanspruchnahme
        folgender Dienstleistungen erhoben:
      </p>
      <h4>Analytik</h4>
      <p>
        Mit den in diesem Abschnitt aufgeführten Dienstleistungen kann der
        Eigentümer den Datenverkehr überwachen und analysieren sowie das
        Verhalten von Nutzern nachverfolgen.
      </p>
      <p>
        <strong>Matomo (diese Webseite / www.research-hub.de)</strong>
      </p>
      <p>
        Matomo ist ein Open Source Analyse-Softwareprogramm, welches diese
        Webseite nutzt, um Daten direkt ohne die Hilfe Dritter zu analysieren.
      </p>
      <p>
        Matomo verwendet “Cookies.” Damit erzeugte Informationen über die
        Benutzung dieser Website werden auf dem Server gespeichert. Vor der
        Speicherung erfolgt eine Anonymisierung Ihrer IP-Adresse.
      </p>
      <p>
        Cookies von Matomo verbleiben auf Ihrem Endgerät, bis Sie eine Löschung
        vornehmen.
      </p>
      <p>
        Das Setzen von Matomo-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes
        Interesse an der anonymisierten Analyse des Nutzerverhaltens, um sowohl
        unser Webangebot zu optimieren.
      </p>
      <p>
        Es erfolgt keine Weitergabe der im Matomo-Cookie gespeicherten
        Informationen über die Benutzung dieser Website. Das Setzen von Cookies
        durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer
        Website könnten dadurch jedoch eingeschränkt werden.
      </p>

      <p>
        Sie können hier die Speicherung und Nutzung Ihrer Daten deaktivieren.
        Ihr Browser setzt ein Opt-Out-Cookie, welches die Speicherung von Matomo
        Nutzungsdaten unterbindet. Wenn Sie Ihre Cookies löschen, wird auch das
        Matomo Opt-Out-Cookie entfernt. Bei einem erneuten Besuch unserer
        Website ist das Opt-Out-Cookie zur Unterbindung der Speicherung und
        Nutzung Ihrer Daten erneut zu setzen.
      </p>
      <p>Erhobene personenbezogene Daten: Cookie und Nutzungsdaten.</p>
      ​
      <p>
        Deaktivierung durchgeführt! Ihre Besuche auf dieser Webseite werden von
        der Webanalyse nicht mehr erfasst. Bitte beachten Sie, dass auch der
        Matomo-Deaktivierungs-Cookie dieser Webseite gelöscht wird, wenn Sie die
        in Ihrem Browser abgelegten Cookies entfernen. Außerdem müssen Sie, wenn
        Sie einen anderen Computer oder einen anderen Webbrowser verwenden, die
        Deaktivierungsprozedur nochmals absolvieren.
      </p>
      <h4>Anzeigen v>on Inhalten externer Plattformen</h4>
      <p>
        Mit dieser Art von Diensten können Nutzer sich Inhalte, die auf externen
        Plattformen gehostet werden, direkt über diese Webseite anzeigen lassen
        und mit ihnen interagieren. Falls ein solcher Dienst installiert ist, so
        kann er für die Seiten, auf denen er installiert ist, möglicherweise
        auch dann Daten aus dem Datenverkehr erheben, wenn Nutzer ihn nicht
        verwenden.
      </p>
      <p>
        <strong> Google-Maps-Widget (Google Inc.) </strong>
      </p>
      <p>
        Google Maps ist ein von Google Inc. bereitgestellter Service zur
        Visualisierung von Karten, mit dem diese Webseite entsprechende Inhalte
        auf ihren Seiten einbinden kann.
      </p>
      <p>Erhobene personenbezogene Daten: Cookie und Nutzungsdaten.</p>
      <p>
        Verarbeitungsort: USA – Datenschutzerklärung. Privacy Shield-Mitglied.
      </p>
      <p>
        <strong>Google Fonts (Google Inc.)</strong>
      </p>
      <p>
        Google Fonts ist ein von Google Inc. bereitgestellter Service zur
        Visualisierung von Schriftarten, mit dem diese Webseite entsprechende
        Inhalte auf ihren Seiten einbinden kann.
      </p>
      <p>
        Erhobene personenbezogene Daten: Nutzungsdaten und verschiedene
        Datenarten, wie in der Datenschutzerklärung des Dienstes beschrieben.
      </p>
      <p>
        Verarbeitungsort: USA – Datenschutzerklärung. Privacy Shield-Mitglied.
      </p>
      <h4>Interaktion mit externen sozialen Netzwerken und Plattformen</h4>
      <p>
        Diese Art von Diensten ermöglichen die Interaktion mit sozialen
        Netzwerken oder sonstigen externen Plattformen direkt über diese
        Webseite. Die Interaktion sowie die über diese Webseite erhobenen
        Informationen unterliegen stets den von den Nutzern für das jeweilige
        soziale Netzwerk vorgenommenen Privatsphäre-Einstellungen. Falls ein
        Dienst für die Interaktion mit sozialen Netzwerken installiert ist, so
        kann er für die Seiten, auf denen er installiert ist, auch dann Daten
        aus dem Datenverkehr erheben, wenn Nutzer den Dienst nicht verwenden.
      </p>
      <p>
        <strong> AddToAny (AddToAny LLC) </strong>
      </p>
      <p>
        AddToAny ist ein von AddToAny LLC bereitgestellter Dienst für die
        Anzeige eines Widgets, das Nutzern die Interaktion mit sozialen
        Netzwerken und externen Plattformen sowie das gemeinsame Nutzen der
        Inhalte, die diese Webseite enthält, ermöglicht. Je nach Einstellungen
        kann dieser Dienst Widgets Dritter – beispielsweise der Leiter von
        sozialen Netzwerken, in denen Interaktionen geteilt werden – anzeigen.
        In diesem Fall werden auch die Drittanbieter des Widgets über die
        Interaktionen und Nutzungsdaten der Seiten benachrichtigt, auf denen der
        Dienst installiert ist.
      </p>
      <p>Erhobene personenbezogene Daten: Cookie und Nutzungsdaten.</p>
      <p>Verarbeitungsort: Vereinigte Staaten –Datenschutzerklärung.</p>
      <h4>Kommentarfunktion</h4>
      <p>
        Kommentardienste ermöglichen es Nutzern, Kommentare zu den Inhalten, die
        diese Webseite enthält, zu verfassen und zu veröffentlichen. Je nach
        Einstellungen des Anbieters können Nutzer auch anonyme Kommentare
        hinterlassen. Falls Nutzer mit ihren personenbezogenen Daten auch ihre
        E-Mail-Adresse angegeben haben, kann diese verwendet werden, um Nutzer
        über Kommentare zu denselben Inhalten zu benachrichtigen. Nutzer sind
        für den Inhalt ihrer eigenen Kommentare verantwortlich. Falls der
        Kommentardienst eines Drittanbieters installiert ist, so kann dieser für
        die Seiten, auf denen der Kommentardienst installiert ist,
        möglicherweise auch dann Daten aus dem Datenverkehr erheben, wenn der
        Nutzer den Kommentardienst nicht verwendet.
      </p>
      <p>
        <strong>
          Eigenes Kommentarsystem (diese Webseite / www.research-hub.de)
        </strong>
      </p>
      <p>Diese Webseite verfügt über ihre eigene interne Kommentarfunktion.</p>
      <p>
        Erhobene personenbezogene Daten: E-Mail, Nachname, Vorname und Website.
      </p>
      <h4>Kontaktieren des Nutzers</h4>
      <p>
        <strong>
          Kontaktformular (diese Webseite / www.research-hub.de)
        </strong>
      </p>
      <p>
        Durch Ausfüllen des Kontaktformulars mit ihren Daten autorisieren Nutzer
        diese Webseite, ihre Angaben zu verwenden, um auf Bitten nach
        Informationen, Angebote oder sonstige Anfragen, die in der Kopfzeile des
        Formulars angegeben sind, zu reagieren.
      </p>
      <p>Erhobene personenbezogene Daten: E-Mail, Nachname und Vorname.</p>
      <p>
        <strong>
          Mailingliste oder Newsletter (diese Webseite / www.research-hub.de)
        </strong>
      </p>
      <p>
        Durch Anmelden zur Mailingliste oder zum Newsletter wird die
        E-Mail-Adresse des Nutzers der Kontaktliste der Personen hinzugefügt,
        die möglicherweise E-Mail-Nachrichten mit gewerblichen oder
        verkaufsfördernden Informationen in Bezug auf diese Webseite erhalten.
        Darüber hinaus kann Ihre E-Mail-Adresse dieser Liste zugefügt werden,
        wenn Sie sich für diese Webseite angemeldet haben.
      </p>
      <p>Erhobene personenbezogene Daten: E-Mail, Nachname und Vorname.</p>
      <h4>SPAM-Schutz</h4>
      <p>
        Diese Art von Diensten analysieren den Datenverkehr, der über diese
        Webseite stattfindet und möglicherweise personenbezogene Daten von
        Nutzern enthält, um Teile des Datenverkehrs, Nachrichten und Inhalte,
        die als SPAM gelten, herauszufiltern.
      </p>
      <h4>Google reCAPTCHA (Google Inc.)</h4>
      <p>
        Google reCAPTCHA ist ein SPAM-Schutz-Service von Google Inc. Die
        Verwendung von reCAPTCHA unterliegt der Datenschutzerklärung von Google
        und den Nutzungsbedingungen. Erhobene personenbezogene Daten: Cookie und
        Nutzungsdaten.
      </p>
      <p>
        Verarbeitungsort: Vereinigte Staaten – Datenschutzerklärung. Privacy
        Shield-Mitglied.
      </p>
      <h4>Die Rechte der Nutzer</h4>
      <p>
        Die Nutzer können bestimmte Rechte in Bezug auf ihre vom Anbieter
        verarbeiteten Daten ausüben.
      </p>
      <p>Nutzer haben insbesondere das Recht, Folgendes zu tun:</p>
      ​
      <ul>
        <li>
          <p>
            <strong> Die Einwilligungen jederzeit widerrufen.</strong>
          </p>
        </li>
        <li>
          <p>
            Hat der Nutzer zuvor in die Verarbeitung personenbezogener Daten
            eingewilligt, so kann er die eigene Einwilligung jederzeit
            widerrufen.
          </p>
        </li>
        <li>
          <p>
            <strong
              >Widerspruch gegen die Verarbeitung ihrer Daten einlegen.</strong
            >
          </p>
        </li>
        <li>
          <p>
            Der Nutzer hat das Recht, der Verarbeitung seiner Daten zu
            widersprechen, wenn die Verarbeitung auf einer anderen
            Rechtsgrundlage als der Einwilligung erfolgt. Weitere Informationen
            hierzu sind weiter unten aufgeführt.
          </p>
          ​
        </li>
        <li>
          <p>
            <strong> Auskunft bezüglich ihrer Daten erhalten. </strong>
          </p>
        </li>
        <li>
          <p>
            Der Nutzer hat das Recht zu erfahren, ob die Daten vom Anbieter
            verarbeitet werden, über einzelne Aspekte der Verarbeitung Auskunft
            zu erhalten und eine Kopie der Daten zu erhalten.
          </p>
        </li>
        <li>
          <p><strong> Überprüfen und berichtigen lassen. </strong></p>
        </li>
        <li>
          <p>
            Der Nutzer hat das Recht, die Richtigkeit seiner Daten zu überprüfen
            und deren Aktualisierung oder Berichtigung zu verlangen.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Einschränkung der Verarbeitung ihrer Daten verlangen.
            </strong>
          </p>
        </li>
        <li>
          <p>
            Die Nutzer haben das Recht, unter bestimmten Umständen die
            Verarbeitung ihrer Daten einzuschränken. In diesem Fall wird der
            Anbieter die Daten zu keinem anderen Zweck als der Speicherung
            verarbeiten.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Löschung oder anderweitiges Entfernen der personenbezogenen Daten
              verlangen.
            </strong>
          </p>
        </li>
        <li>
          <p>
            Die Nutzer haben unter bestimmten Umständen das Recht, die Löschung
            ihrer Daten vom Anbieter zu verlangen.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Ihre Daten erhalten und an einen anderen Verantwortlichen
              übertragen lassen.
            </strong>
          </p>
        </li>
        <li>
          <p>
            Der Nutzer hat das Recht, seine Daten in einem strukturierten,
            gängigen und maschinenlesbaren Format zu erhalten und, sofern
            technisch möglich, ungehindert an einen anderen Verantwortlichen
            übermitteln zu lassen. Diese Bestimmung ist anwendbar, sofern die
            Daten automatisiert verarbeitet werden und die Verarbeitung auf der
            Zustimmung des Nutzers, auf einem Vertrag, an dem der Nutzer
            beteiligt ist, oder auf vorvertraglichen Verpflichtungen beruht.
          </p>
          ​
        </li>
        <li>
          <p><strong> Beschwerde einreichen. </strong></p>
        </li>
        <li>
          <p>
            Die Nutzer haben das Recht, eine Beschwerde bei der zuständigen
            Aufsichtsbehörde einzureichen. Der folgende Link stellt eine Liste
            der Datenschutzbeauftragten sowie deren Kontaktdaten bereit:
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </p>
        </li>
        ​
      </ul>
      <h4>Details zum Widerspruchsrecht bezüglich der Verarbeitung</h4>
      <p>
        Werden personenbezogene Daten im öffentlichen Interesse, in Ausübung
        eines dem Anbieter übertragenen hoheitlichen Befugnisses oder zur
        Wahrung der berechtigten Interessen des Anbieters verarbeitet, kann der
        Nutzer dieser Verarbeitung widersprechen, indem er einen
        Rechtfertigungsgrund angibt, der sich auf seine besondere Situation
        bezieht.
      </p>
      <p>
        Nutzer werden darüber informiert, dass sie der Verarbeitung der
        personenbezogenen Daten für Direktwerbung jederzeit ohne Angabe von
        Gründen widersprechen können. Um zu erfahren, ob der Anbieter
        personenbezogene Daten für Direktwerbungszwecke verarbeitet, können die
        Nutzer den entsprechenden Abschnitten dieses Dokuments entnehmen.
      </p>
      <h4>Wie die Rechte ausgeübt werden können</h4>
      <p>
        Alle Anfragen zur Ausübung der Nutzerrechte können über die in diesem
        Dokument angegebenen Kontaktdaten an den Anbieter gerichtet werden.
        Anträge können kostenlos ausgeübt werden und werden vom Anbieter so früh
        wie möglich, spätestens innerhalb eines Monats bearbeitet.
      </p>
      <h4>
        Weitere Informationen über die Erhebung und Verarbeitung von Daten
      </h4>
      <p>
        <strong> Rechtliche Maßnahmen </strong>
      </p>
      <p>
        Die personenbezogenen Daten des Nutzers können vom Anbieter zu Zwecken
        der Rechtsdurchsetzung innerhalb oder in Vorbereitung gerichtlicher
        Verfahren verarbeitet werden, die sich daraus ergeben, dass diese
        Webseite oder die dazugehörigen Dienste nicht ordnungsgemäß genutzt
        wurden. Der Nutzer erklärt, sich dessen bewusst zu sein, dass der
        Anbieter von den Behörden zur Herausgabe von personenbezogenen Daten
        verpflichtet werden könnte.
      </p>
      <p>
        <strong>
          Weitere Informationen über die personenbezogenen Daten des Nutzers
        </strong>
      </p>
      <p>
        Zusätzlich zu den in dieser Datenschutzerklärung aufgeführten
        Informationen kann diese Webseite dem Nutzer auf Anfrage weitere
        kontextbezogene Informationen zur Verfügung stellen, die sich auf
        bestimmte Dienste oder auf die Erhebung und Verarbeitung
        personenbezogener Daten beziehen.
      </p>
      <p>
        <strong> Systemprotokolle und Wartung </strong>
      </p>
      <p>
        Diese Webseite und die Dienste von Drittanbietern können zu Betriebs-
        und Wartungszwecken Dateien sammeln, die die über diese Webseite
        stattfindende Interaktion aufzeichnen (Systemprotokolle), oder andere
        personenbezogene Daten (z. B. IP-Adresse) zu diesem Zweck verwenden.
      </p>
      <p><strong> SSL- bzw. TLS-Verschlüsselung</strong></p>
      <p>
        Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
        Inhalte, die Sie an den Seitenbetreiber senden, nutzt diese Website eine
        SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die über diese Website
        übermittelt werden, für Dritte nicht mitlesbar. Sie erkennen eine
        verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers
        und am Schloss-Symbol in der Browserzeile.
      </p>
      <p>
        <strong
          >Nicht in dieser Datenschutzerklärung enthaltene Informationen</strong
        >
      </p>
      <p>
        Weitere Informationen über die Erhebung oder Verarbeitung
        personenbezogener Daten können jederzeit vom Anbieter über die
        aufgeführten Kontaktangaben angefordert werden.
      </p>
      <p><strong> Änderungen dieser Datenschutzerklärung</strong></p>
      <p>
        Der Anbieter behält sich vor, jederzeit Änderungen an dieser
        Datenschutzerklärung vorzunehmen, indem sie ihre Nutzer auf dieser Seite
        und gegebenenfalls über diese Webseite und/oder – soweit technisch und
        rechtlich möglich – durch das Versenden einer Mitteilung an die Nutzer
        über eine der dem Anbieter zur Verfügung stehenden Kontaktdaten
        entsprechend informiert. Nutzern wird daher nahe gelegt, diese Seite
        regelmäßig aufzurufen und dabei das am Seitenende angegebene Datum der
        letzten Änderung zu prüfen.
      </p>
      <p>
        Soweit Änderungen eine auf der Einwilligung des Nutzers basierte
        Datennutzung betreffen, so wird der Anbieter – soweit erforderlich –
        eine neue Einwilligung einholen.
      </p>
      <h4>Begriffsbestimmungen und rechtliche Hinweise</h4>
      <p>
        <strong> Personenbezogene Daten (oder Daten) </strong>
      </p>
      <p>
        Alle Informationen, durch die direkt oder in Verbindung mit weiteren
        Informationen die Identität einer natürlichen Person bestimmt wird oder
        werden kann.
      </p>
      <p><strong> Nutzungsdaten </strong></p>
      <p>
        Informationen, die diese Webseite (oder Dienste Dritter, die diese
        Webseite in Anspruch nimmt), automatisch erhebt, z. B.: die IP-Adressen
        oder Domain-Namen der Computer von Nutzern, die diese Webseite
        verwenden, die URI-Adressen (Uniform Resource Identifier), den Zeitpunkt
        der Anfrage, die Methode, die für die Übersendung der Anfrage an den
        Server verwendet wurde, die Größe der empfangenen Antwort-Datei, der
        Zahlencode, der den Status der Server-Antwort anzeigt (erfolgreiches
        Ergebnis, Fehler etc.), das Herkunftsland, die Funktionen des vom Nutzer
        verwendeten Browsers und Betriebssystems, die diversen Zeitangaben pro
        Aufruf (z. B. wie viel Zeit auf jeder Seite der Webseite verbracht
        wurde) und Angaben über den Pfad, dem innerhalb einer Webseite gefolgt
        wurde, insbesondere die Reihenfolge der besuchten Seiten, sowie sonstige
        Informationen über das Betriebssystem des Geräts und/oder die
        IT-Umgebung des Nutzers.
      </p>
      <p>
        <strong> Nutzer </strong>
      </p>
      <p>
        Die diese Webseite verwendende Person, die, soweit nicht anders
        bestimmt, mit dem Betroffenen übereinstimmt.
      </p>
      <p>
        <strong> Betroffener </strong>
      </p>
      <p>
        Die natürliche Person, auf die sich die personenbezogenen Daten
        beziehen.
      </p>
      <p>
        <strong> Auftragsverarbeiter (oder Datenverarbeiter) </strong>
      </p>
      <p>
        Natürliche oder juristische Person, Behörde, Einrichtung oder andere
        Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen
        verarbeitet, wie in dieser Datenschutzerklärung beschrieben.
      </p>
      <p>
        <strong>
          Verantwortlicher (oder Anbieter, teilweise auch Eigentümer)
        </strong>
      </p>
      <p>
        Die natürliche oder juristische Person, Behörde, Einrichtung oder andere
        Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
        der Verarbeitung personenbezogener Daten sowie die hierfür verwendeten
        Mittel entscheidet, einschließlich der Sicherheitsmaßnahmen bezüglich
        des sich auf diese Webseite beziehenden Betriebs und der Nutzung. Soweit
        nichts anderes angegeben ist, ist der Verantwortliche die natürliche
        oder juristische Person, über welche diese Webseite angeboten wird.
      </p>
      <p>
        <strong> Diese Webseite </strong>
      </p>
      <p>
        Das Hardware- oder Software-Tool, mit dem die personenbezogenen Daten
        des Nutzers erhoben und verarbeitet werden.
      </p>
      <p>
        <strong> Dienst </strong>
      </p>
      <p>
        Der durch diese Webseite angebotene Dienst, wie in den entsprechenden
        Nutzungsbedingungen (falls vorhanden) und auf dieser Seite/Webseite
        beschrieben.
      </p>
      <p>
        <strong> Europäische Union (oder EU) </strong>
      </p>
      <p>
        Sofern nicht anders angegeben, beziehen sich alle Verweise in diesem
        Dokument auf die Europäische Union auf alle derzeitigen Mitgliedstaaten
        der Europäischen Union und den Europäischen Wirtschaftsraum (EWR).
      </p>
      <p>
        <strong> Cookie </strong>
      </p>
      <p>Kleine Datei, die auf das Gerät des Nutzers gespeichert wird.</p>
      <p>
        <strong> Rechtlicher Hinweis </strong>
      </p>
      <p>
        Diese Datenschutzerklärung wurde auf der Grundlage von Bestimmungen
        verschiedener Gesetzgebungen verfasst, einschließlich Art. 13/14 der
        Verordnung (EU) 2016/679 (Datenschutz-Grundverordnung).
      </p>
      <p>
        Diese Datenschutzerklärung bezieht sich ausschließlich auf diese
        Webseite, sofern in diesem Dokument nicht anders angegeben.
      </p>
    </div>
  </div>
</div>
