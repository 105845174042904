@if (isGuidedMenuEnabled()) {
  <div class="bg-yellow-100 border-b-2 border-blue-500 p-4 shadow-md">
    <!-- Research Guided Menu -->
    <div class="container mx-auto">
      <div class="flex items-center justify-between mb-2">
        <h2 class="text-lg font-bold text-blue-800">Research Upload Guide</h2>
        <button
          class="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors duration-300"
          (click)="creationProcessMenuService.toggleGuidedMenu()"
        >
          Hide Guide
        </button>
      </div>

      <div class="flex items-center space-x-4 overflow-x-auto pb-2">
        @for (step of steps(); track step; let index = $index) {
          <div
            class="flex-shrink-0 flex items-center p-2 rounded-md transition-colors duration-300"
            [class.bg-blue-200]="index === getCurrentStepIndex()"
            [class.text-green-600]="step.status === 'completed'"
            [class.text-gray-500]="step.isOptional"
          >
            <span
              class="w-6 h-6 bg-blue-500 text-white rounded-full flex items-center justify-center text-xs mr-2"
              >{{ index + 1 }}</span
            >
            <a
              class="text-sm"
              [routerLink]="creationProcessMenuService.buildRoute(step.route)"
            >
              {{ step.name }}
            </a>
            @if (step.status === "completed") {
              <span class="text-green-500 ml-1">✓</span>
            }
          </div>
        }
      </div>

      <div class="flex items-center justify-between mt-2">
        @if (getCurrentStepIndex() !== steps().length - 1) {
          <button
            class="text-sm bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 transition-colors duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
            [disabled]="
              !steps()[getCurrentStepIndex()].isOptional &&
              !isCurrentStepCompleted()
            "
            (click)="navigateToNextStep()"
          >
            Next Step
          </button>
        } @else {
          <p class="text-sm text-green-600 font-semibold">Completed!</p>
        }
        <p class="text-xs text-gray-600">To reset, reload the page</p>
      </div>
    </div>
  </div>
}
