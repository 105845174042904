import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { RouterLink } from "@angular/router";

import { AdminScreenerEditorFormComponent } from "~features/weekly-screener/admin/admin-screener-editor-form/admin-screener-editor-form.component";
import { WeeklyScreenerService } from "~features/weekly-screener/weekly-screener.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-screener-list",
  standalone: true,
  imports: [
    RouterLink,
    BreadcrumpComponent,
    PaginationComponent,
    AdminScreenerEditorFormComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-screener-list.component.html",
  styleUrl: "./admin-screener-list.component.scss",
})
export class AdminScreenerListComponent {
  weeklyScreenerService = inject(WeeklyScreenerService);
  screenerList = this.weeklyScreenerService.items;
  totalCount = this.weeklyScreenerService.totalCount;

  itemsPerPage = signal(25);
  currentPage = signal(1);

  constructor(private http: HttpClient) {
    this.fetchScreeners();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalCount());
  }

  fetchScreeners() {
    const paginationParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    };
    this.weeklyScreenerService.fetchOverviewListAlltWeeklyScreeners(
      paginationParams
    );
  }

  onPageChange(page: number) {
    this.currentPage.set(page);
    this.fetchScreeners();
  }
}
