import {
  Component,
  computed,
  inject,
  signal,
  WritableSignal,
} from "@angular/core";

import { CompanyInitiationNoteFormComponent } from "~features/company/admin/company-initiation-note/company-initiation-note-form/company-initiation-note-form.component";
import { AdminCompanyResearchListComponent } from "~features/company/admin/company-research/admin-company-research-list/admin-company-research-list.component";
import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { CompanydataService } from "~features/company/companydata.service";
import { InitiationNoteService } from "~features/company/initiation-note.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_INITIATIONNOTE } from "~shared/initial-values";

@Component({
  selector: "app-company-initiation-note-creator",
  standalone: true,
  imports: [
    CreationProcessMenuComponent,
    AdminCompanySwotEditorFormComponent,
    BreadcrumpComponent,
    CompanyInitiationNoteFormComponent,
    AdminCompanyResearchListComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./company-initiation-note-creator.component.html",
  styleUrl: "./company-initiation-note-creator.component.scss",
})
export class CompanyInitiationNoteCreatorComponent {
  companyDataService = inject(CompanydataService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  initiationNote: WritableSignal<any> = signal(INITIAL_INITIATIONNOTE);
  initiationNoteService = inject(InitiationNoteService);

  initiationNoteContent = computed(() => {
    return {
      ...this.initiationNote(),
      companyTicker: this.creationProcessMenuService.currentCompanyTicker(),
    };
  });

  onFormSubmit(newData: any) {
    this.initiationNoteService.createInitiationNote(newData);
    //console.log(newData);
    this.creationProcessMenuService.setStepCompleted("Initiation Note");
  }
}
