import { Component } from "@angular/core";

import { AdminWikiWorkflowsComponent } from "~features/admin-wiki/admin-wiki-workflows/admin-wiki-workflows.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-wiki-page",
  standalone: true,
  imports: [
    AdminWikiWorkflowsComponent,
    AdminSidePanelComponent,
    BreadcrumpComponent,
  ],
  templateUrl: "./admin-wiki-page.component.html",
  styleUrl: "./admin-wiki-page.component.scss",
})
export class AdminWikiPageComponent {}
