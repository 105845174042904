import { DatePipe, JsonPipe } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-event-grid-tile-upcoming",
  standalone: true,
  imports: [DatePipe, JsonPipe],
  templateUrl: "./event-grid-upcoming-tile.component.html",
  styleUrl: "./event-grid-upcoming-tile.component.scss",
})
export class EventGridUpcomingTileComponent {
  @Input() comingEvent: any;
  @Input() timeZoneFormat: (date: string) => string;
  @Input() navigateToEventRegistrationPage: (event: any) => void;
}
