<ul
  class="flex flex-wrap items-center justify-center space-x-1 space-y-1 lg:space-x-2 lg:space-y-0"
>
  <li>
    <button
      (click)="previousPage()"
      [disabled]="currentPage() === 1"
      class="px-2 py-1 lg:px-3 lg:py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 text-sm lg:text-base"
    >
      &laquo;
    </button>
  </li>
  @for (page of displayedPages(); track page) {
    @if (page !== -1) {
      <li>
        <button
          class="px-2 py-1 lg:px-3 lg:py-1 rounded-md text-sm lg:text-base"
          [class.bg-blue-500]="page === currentPage()"
          [class.text-white]="page === currentPage()"
          [class.bg-gray-200]="page !== currentPage()"
          [class.text-gray-700]="page !== currentPage()"
          (click)="goToPage(page)"
        >
          {{ page }}
        </button>
      </li>
    }
    @if (page === -1) {
      <li class="px-2 py-1 lg:px-3 lg:py-1 text-sm lg:text-base">...</li>
    }
  }
  <li>
    <button
      (click)="nextPage()"
      [disabled]="currentPage() === totalPages()"
      class="px-2 py-1 lg:px-3 lg:py-1 rounded-md bg-gray-200 text-gray-700 hover:bg-gray-300 disabled:opacity-50 text-sm lg:text-base"
    >
      &raquo;
    </button>
  </li>
</ul>
