import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";

import { alsterAPI_URL } from "~api/api-urls";
import { AuthService } from "~features/auth/auth.service";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class DownloadPdfAnalyticsService {
  http = inject(HttpClient);
  analytics: WritableSignal<any | null> = signal(null);
  authService = inject(AuthService);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);

  constructor() {
    if (this.authService.isAuthenticated() && this.authService.isAdmin()) {
      this.fetchAnalytics();
    }
    this.fetchAnalytics();
  }

  public addPDFCounter(pdfURL) {
    const body = { ref: pdfURL };
    this.isLoading.set(true);
    this.http
      .post<IApiResponse<any>>(alsterAPI_URL + "pdfanalytics/create", body)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "add pdf counter")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (res) => {
          this.analytics.set(res);
          this.loadingIndicatorService.setLastRequestMessage(
            "add pdf counter success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public fetchAnalytics() {
    this.isLoading.set(true);
    return this.http
      .get<IApiResponse<any>>(alsterAPI_URL + "pdfanalytics")
      .pipe(map((response) => response.data[0]))
      .subscribe((res) => {
        this.analytics.set(res);
        this.isLoading.set(false);
      });
  }

  public getAnalytics() {
    return this.analytics;
  }

  getisLoading() {
    return this.isLoading;
  }

  downloadPDFAnalyticsAsCSV(filename = "pdf-analytics-data") {
    const csvData = this.analyticsJSONToCSV(this.analytics());
    const blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    const dwldLink = document.createElement("a");
    const url = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  analyticsJSONToCSV(pdfs) {
    let csv = "";
    for (const pdf of pdfs) {
      let line = "";
      line = line + pdf._id + ";" + pdf.count;
      csv += line + "\r\n";
    }

    return csv;
  }
}
