<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Tansactions - shown or hidden?</p>
      <p class="text-gray-600">
        Here you see if the Transaction Banner is currently shown or hidden.
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div
        [ngClass]="{
          'bg-green-100': frontendConfig()?.activeTransaction,
          'bg-red-100': !frontendConfig()?.activeTransaction
        }"
        class="shadow-md rounded-lg p-6 md:col-span-1"
      >
        <p class="text-md font-semibold mb-2">Frontend Config Information</p>
        <div class="text-gray-600">
          <p>
            <strong>Frontend Config: </strong>
            {{
              frontendConfig()?.activeTransaction
                ? "Currently showing active Transaction"
                : "Currently not showing any Transaction"
            }}
          </p>
        </div>
        <a [routerLink]="['edit']">
          <button
            class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Edit
          </button>
        </a>
      </div>
    </div>
  </main>
</div>
