import { JsonPipe } from "@angular/common";
import { Component, computed, inject, Input, signal } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { IEditorContent } from "~global-interfaces/IEditorContent";

@Component({
  selector: "app-display-editor-content",
  standalone: true,
  imports: [JsonPipe],
  templateUrl: "./display-editor-content.component.html",
  styleUrls: ["./display-editor-content.component.scss"],
})
export class DisplayEditorContentComponent {
  domSanitizer = inject(DomSanitizer);

  private contentSignal = signal("");
  parsedContent = computed<SafeHtml>(() =>
    this.domSanitizer.bypassSecurityTrustHtml(this.contentSignal() || "")
  );

  @Input() set content(value: IEditorContent) {
    if (value) {
      this.contentSignal.set(value.content);
    }
  }
}
