import { Component, inject, signal, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IRSSEntry } from "~global-interfaces/IRSSEntry";

import { RssEntryService } from "~features/rss/rss-entry.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-rss-list",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    RouterLink,
    PaginationComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-rss-list.component.html",
  styleUrls: ["./admin-rss-list.component.scss"],
})
export class AdminRssListComponent {
  rssEntryService = inject(RssEntryService);
  rssEntriesList: WritableSignal<IRSSEntry[]> = signal<IRSSEntry[]>([]);
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);

  constructor() {
    this.fetchRssEntries();
  }

  fetchRssEntries() {
    this.rssEntryService.fetchRssEntriesList({
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    });
    this.rssEntriesList = this.rssEntryService.getRssEntriesList();
    this.totalItems = this.rssEntryService.getTotalCount();
  }

  fetchMoreRssEntries($event: number) {
    this.currentPage.set($event);
    this.fetchRssEntries();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalItems());
  }
}
