import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import { IEditorContent } from "~global-interfaces/IEditorContent";

import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { CompanydataService } from "~features/company/companydata.service";
import { SwotService } from "~features/company/swot.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_SWOT } from "~shared/initial-values";

@Component({
  selector: "app-admin-company-swot-creator",
  standalone: true,
  imports: [
    AdminCompanySwotEditorFormComponent,
    BreadcrumpComponent,
    CreationProcessMenuComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-company-swot-creator.component.html",
  styleUrl: "./admin-company-swot-creator.component.scss",
})
export class AdminCompanySwotCreatorComponent {
  companyDataService = inject(CompanydataService);
  swotService = inject(SwotService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  currentCompanyTicker = this.creationProcessMenuService.currentCompanyTicker;
  swot: WritableSignal<any> = signal(INITIAL_SWOT);

  swotContent: Signal<IEditorContent | null> = computed(() => {
    return {
      ...this.swot(),
      companyTicker: this.currentCompanyTicker() || "",
    };
  });

  onFormSubmit(updateSwotData: any) {
    this.swotService.createSWOT(updateSwotData);
    // console.log(updateSwotData);
    this.creationProcessMenuService.setStepCompleted("SWOT");
  }
}
