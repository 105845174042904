<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">See all companies</p>
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }} / {{ totalCount() }}
        </p>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                Ticker
              </th>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                public
              </th>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                Settings
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (company of companyAdminList(); track company.companyTicker) {
              <tr>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ company.name }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ company.companyTicker }}
                </td>
                <td
                  class="px-4 py-3 text-sm text-center"
                  [ngClass]="{
                    'text-green-500 bg-green-100 rounded-lg p-1':
                      company.is_public,
                    'text-gray-900': !company.is_public
                  }"
                >
                  {{ company.is_public ? "Yes" : "No" }}
                </td>
                <td class="px-4 py-3 text-sm">
                  <a [routerLink]="['settings', company.companyTicker]">
                    <button
                      class="px-3 py-1 bg-blue-500 hover:bg-blue-400 text-white text-xs font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Settings
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }} / {{ totalCount() }}
        </p>
      </div>
    </div>
  </main>
</div>
