@if (data()) {
  <div class="flex flex-col justify-end h-full text-center">
    <div class="mb-4">
      <div class="text-md font-medium text-[#e2e8f0]">
        {{ data()[index()]?.title }}
        @if (data()[index()]?.valueModifier) {
          <span class="ml-1">{{ data()[index()]?.valueModifier }}</span>
        }
      </div>
    </div>
    <div class="grid grid-cols-12 items-center">
      <div
        class="col-span-3 cursor-pointer text-lg text-blue-research"
        (click)="navigate(-1)"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
      <div class="col-span-6 text-lgx font-medium">
        {{ data()[index()]?.value }}
      </div>
      <div
        class="col-span-3 cursor-pointer text-lg text-blue-research"
        (click)="navigate(1)"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
    </div>
  </div>
}
