import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IResearchPodcast } from "~global-interfaces/IResearchPodcast";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminPodcastEditorFormComponent } from "~features/podcasts/admin/admin-podcast-editor-form/admin-podcast-editor-form.component";
import { PodcastService } from "~features/podcasts/podcast.service";
import { AdminRssEditorFormComponent } from "~features/rss/admin/admin-rss-editor-form/admin-rss-editor-form.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-podcast-creator",
  standalone: true,
  imports: [
    AdminRssEditorFormComponent,
    AdminSidePanelComponent,
    BreadcrumpComponent,
    CompanySearchComponent,
    AdminPodcastEditorFormComponent,
    FileUploadComponent,
  ],
  templateUrl: "./admin-podcast-creator.component.html",
  styleUrl: "./admin-podcast-creator.component.scss",
})
export class AdminPodcastCreatorComponent {
  podcastService = inject(PodcastService);
  podcast: WritableSignal<IResearchPodcast | null> =
    signal<IResearchPodcast | null>(null);

  rssEntryENMessage: WritableSignal<boolean> = signal<boolean>(false);
  companyName: WritableSignal<string | null> = signal(null);

  constructor(private route: ActivatedRoute) {}

  onFormSubmit(newData: IResearchPodcast) {
    this.podcastService
      .createPodcast(newData)
      .subscribe((updatedPodcast: IResearchPodcast) => {
        this.podcast.set(updatedPodcast);
      });
  }
}

/**
 *
 *   @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
 *   @Input() multiMode: boolean = false;
 *   @Output() formValidity = new EventEmitter<boolean>();
 *   @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
 *   @Input() multiModeCompanyName: Signal<string | null> = signal(null);
 *   @Input() podcast!: WritableSignal<IResearchPodcast>;
 *   @Input() creatorMode = false;
 *   @Output() formSubmit = new EventEmitter<IResearchPodcast>();
 *   @Input() companyTicker: WritableSignal<string | null> = signal(null);
 *   @Input() researchTitle: WritableSignal<string | null> = signal(null);
 *   @Input() research_date: WritableSignal<string | null> = signal(null);
 *   @Input() research_language: WritableSignal<string | null> = signal(null);
 *
 *
 *
 */
