import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { IHubAccount } from "~global-interfaces/IHubAccount";

import { UserService } from "~features/users/user.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-user",
  standalone: true,
  imports: [BreadcrumpComponent, RouterLink, AdminSidePanelComponent],
  templateUrl: "./admin-user.component.html",
  styleUrls: ["./admin-user.component.scss"],
})
export class AdminUserComponent {
  userService = inject(UserService);
  user: WritableSignal<IHubAccount> = signal<IHubAccount>({
    _id: "",
    addinIds: [],
    bloombergTicker: "",
    companyName: "",
    confirmationToken: "",
    confirmed: false,
    customerType: "",
    email: "",
    firstName: "",
    isBrevoContact: false,
    isCorporateUser: false,
    lastName: "",
    password: "",
    rights: undefined,
    type: "",
    userId: "",
    userName: "",
    userRole: "",
  });

  constructor(private route: ActivatedRoute) {
    const userId = this.route.snapshot.paramMap.get("userid") ?? "";
    this.userService.fetchUser(userId);
    this.user = this.userService.getUser();
  }
}
