import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";

@Component({
  selector: "app-admin-company-settings-editor",
  standalone: true,
  imports: [
    AdminCompanySettingsEditorFormComponent,
    FileUploadComponent,
    BreadcrumpComponent,
    AdminSidePanelComponent,
    DeleteButtonComponent,
  ],
  templateUrl: "./admin-company-settings-editor.component.html",
  styleUrl: "./admin-company-settings-editor.component.scss",
})
export class AdminCompanySettingsEditorComponent {
  route = inject(ActivatedRoute);
  http = inject(HttpClient);
  companyDataService = inject(CompanydataService);
  companyTickerService = inject(CompanyTickerService);
  company = this.companyDataService.getCurrentCompanySettings();

  companyTicker = signal<string>("");
  protected readonly event = event;

  constructor() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTickerService.setCompanyTicker(ticker);
  }

  onFormSubmit(updateCompanyData: any) {
    this.companyDataService.updateCompany(updateCompanyData);
  }

  deleteCompany(_id: string) {
    console.log(_id);
  }
}
