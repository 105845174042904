import { NgClass, ViewportScroller } from "@angular/common";
import { Component, effect, inject } from "@angular/core";
import { Router, RouterLink } from "@angular/router";

import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanylistService } from "~features/company/companylist.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-company-overview",
  templateUrl: "./company-overview.component.html",
  standalone: true,
  imports: [NgClass, RouterLink],
})
export class CompanyOverviewComponent {
  alphabet = "#ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  alphabetArray = this.alphabet.split("");
  groupedCompanies: { [key: string]: { name: string; slug: string }[] } = {};
  private companyInfoService = inject(CompanyInfoService);
  private companyListService = inject(CompanylistService);
  private router = inject(Router);
  private viewportScroller = inject(ViewportScroller);

  constructor() {
    this.viewportScroller.setOffset([250, 250]);

    effect(() => {
      if (this.companyListService.companyList()) {
        const companies = this.companyListService
          .companyList()
          .filter((item) => item.name !== "welcomePageStatic")
          .filter((company) => company.is_hidden !== true);

        this.groupCompanies(companies);
      }
    }, enableSignalWritesInEffectContext);
  }

  groupCompanies(companies: any[]) {
    this.groupedCompanies = {};
    companies.forEach((company) => {
      const firstChar = this.getFirstChar(company.name);
      if (!this.groupedCompanies[firstChar]) {
        this.groupedCompanies[firstChar] = [];
      }
      this.groupedCompanies[firstChar].push(company);
    });
  }

  getFirstChar(name: string): string {
    if (/^[0-9]/.test(name.charAt(0))) {
      return "numbers";
    }
    const char = name.charAt(0).toUpperCase();
    if (/[ÄÖÜA-Z]/.test(char)) {
      return char === "Ä"
        ? "A"
        : char === "Ö"
        ? "O"
        : char === "Ü"
        ? "U"
        : char;
    }
    for (let i = 1; i < name.length; i++) {
      const c = name.charAt(i).toUpperCase();
      if (/[ÄÖÜA-Z]/.test(c)) {
        return c === "Ä" ? "A" : c === "Ö" ? "O" : c === "Ü" ? "U" : c;
      }
    }
    return "#";
  }

  goToCompanyPage(company: any): void {
    this.router.navigate(["/companies/" + company.name]);
  }

  scrollIntoView(target: string) {
    this.viewportScroller.scrollToAnchor(target);
  }
}
