import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany, IInvestmentChart } from "~global-interfaces/ICompany";

import { AdminInfoHasSideeffectDirective } from "~features/admin-info-sideeffects/admin-info-has-sideeffect.directive";
import { SlugService } from "~features/url-slugs/slug.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-admin-company-settings-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    AdminFormRequestMessageComponent,
    AdminFormButtonComponent,
    AdminInfoHasSideeffectDirective,
  ],
  templateUrl: "./admin-company-settings-editor-form.component.html",
  styleUrls: ["./admin-company-settings-editor-form.component.scss"],
})
export class AdminCompanySettingsEditorFormComponent implements OnInit {
  @Input() company!: Signal<ICompany>;
  @Output() formSubmit = new EventEmitter<ICompany>();
  adminRequestService = inject(AdminRequestService);
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  companyForm: FormGroup;
  @Input() showIsFeaturedOnly = false;
  @Input() dynamicallyCreatedCompanySlug: Signal<string>;

  possibleResearchTableTemplates = [
    "Quarterly table",
    "Half year table",
    "none",
  ];
  possibleFinancialTableTemplates = [
    "financials-UKV",
    "financials-GKV-immo",
    "financials-GKV",
  ];
  possibleChartTemplates = [
    "major shareholders",
    "segment sales",
    "regional sales",
    "ROCEvsWACC",
    "sales and earnings momentum",
    "free cash flow development",
  ];
  defaultCharts: IInvestmentChart[] = [
    {
      currentTemplate: "segment sales",
      position: "left",
    },
    {
      currentTemplate: "regional sales",
      position: "left",
    },
    {
      currentTemplate: "major shareholders",
      position: "left",
    },

    {
      currentTemplate: "sales and earnings momentum",
      position: "right",
    },
    {
      currentTemplate: "ROCEvsWACC",
      position: "right",
    },
    {
      currentTemplate: "free cash flow development",
      position: "right",
    },
  ];
  slugService = inject(SlugService);

  constructor(private formBuilder: FormBuilder) {
    this.adminRequestService.emptyLastRequest();

    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  get chartsFormArray() {
    return this.companyForm.get("charts") as FormArray;
  }

  ngOnInit() {
    this.initializeForm();
    this.patchFormValues();
    this.formValidity.emit(this.companyForm.valid);
    this.formValueChange.emit(this.companyForm.value);
    // Subscribe to form changes
    this.companyForm.valueChanges.subscribe((values) => {
      this.formValueChange.emit(values);
    });

    // Subscribe to form validity changes
    this.companyForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });

    // Subscribe to name field changes to auto-generate slug
    this.companyForm.get("name")?.valueChanges.subscribe((nameValue) => {
      const formattedSlug =
        this.slugService.formatSlugFromCompanyName(nameValue);
      this.companyForm
        .get("slug")
        ?.setValue(formattedSlug, { emitEvent: false });
    });
  }

  initializeForm() {
    this.companyForm = this.formBuilder.group({
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(2),
          Validators.pattern(/^[^%]*$/),
        ],
      ], // no %
      companyTicker: ["", [Validators.required]],
      slug: ["", [Validators.required]],
      baseyear: [
        2023,
        [
          Validators.required,
          Validators.min(1900),
          Validators.max(new Date().getFullYear()),
        ],
      ],
      charts: this.formBuilder.array([]),
      imageURL: ["", [Validators.pattern(/^https?:\/\/.+/)]],
      logoURL: ["", [Validators.pattern(/^https?:\/\/.+/)]],
      presentationURL: ["", [Validators.pattern(/^https?:\/\/.+/)]],
      companyVideoURL: ["", [Validators.pattern(/^https?:\/\/.+/)]],
      isFeatured: [""],
      is_public: [false],
      is_hidden: [false],
      mappedTVChartTicker: [""],
      calculation_settings: this.formBuilder.group({
        numDecimals: [0],
      }),
      valuation_settings: this.formBuilder.group({
        show_trading_multiples: [true],
        show_dcf_model: [true],
        show_adj_fcf_yield: [true],
        show_editorContent: [false],
      }),
      research_settings: this.formBuilder.group({
        currentTemplate: ["Quarterly table"],
      }),
      financials_settings: this.formBuilder.group({
        currentTemplate: [""],
      }),
    });
  }

  patchFormValues() {
    const companyValue = this.company();

    // Set default value for research_settings.currentTemplate if it's null
    if (
      companyValue.research_settings &&
      companyValue.research_settings.currentTemplate === null
    ) {
      companyValue.research_settings.currentTemplate = "Quarterly table";
    }

    this.companyForm.patchValue(companyValue);

    // Clear existing charts
    (this.companyForm.get("charts") as FormArray).clear();

    // Add charts from company
    companyValue.charts.forEach((chart) => this.addChart(chart));
  }

  moveChart(index: number, direction: "up" | "down") {
    const newIndex = direction === "up" ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < this.chartsFormArray.length) {
      const currentChart = this.chartsFormArray.at(index);
      this.chartsFormArray.removeAt(index);
      this.chartsFormArray.insert(newIndex, currentChart);
    }
  }

  addChart(
    chart: IInvestmentChart = {
      currentTemplate: this.possibleChartTemplates[0],
      position: "left",
    }
  ) {
    const chartForm = this.formBuilder.group({
      currentTemplate: [chart.currentTemplate, Validators.required],
      position: [chart.position, Validators.required],
    });
    this.chartsFormArray.push(chartForm);
  }

  removeChart(index: number) {
    this.chartsFormArray.removeAt(index);
  }

  saveForm() {
    if (this.companyForm.valid) {
      const updatedCompany: ICompany = {
        ...this.company(),
        ...this.companyForm.value,
      };

      this.formSubmit.emit(updatedCompany);
    } else {
      this.markFormGroupTouched(this.companyForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.companyForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters`;
      if (control.errors["pattern"])
        return `Invalid ${controlName} format ${
          controlName === "name" ? "no % allowed" : ""
        }`;
      if (control.errors["min"])
        return `${controlName} must be at least ${control.errors["min"].min}`;
      if (control.errors["max"])
        return `${controlName} must not exceed ${control.errors["max"].max}`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
