import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  Input,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";

import { TablesService } from "~features/tables/table.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { TabComponent } from "~shared/components/ui/tab/tab.component";
import { TabsComponent } from "~shared/components/ui/tabs/tabs.component";

import { Tables } from "../tables.util";

@Component({
  selector: "app-financials-table",
  templateUrl: "./financials-table.component.html",
  styleUrls: ["./financials-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TabsComponent, TabComponent],
})
export class FinancialsTableComponent {
  @Input() templateName = "financials-GKV";
  @Input() decimals = 0;
  @Input() companyId: Signal<string>;
  @Input() companyData!: Signal<any>;
  @Input() collapse;
  headline: WritableSignal<string> = signal("");

  tablesService = inject(TablesService);
  tableLayout: WritableSignal<any | null> = signal(null);

  expanded = false;
  //put this as tableTemplate in DB. Allow template selection in table creation
  //write UI to create templates
  // -> select  and set commonSize true/false
  // -> select keys
  // -> mark highlight and (NO)DATA/DAtes
  // -> validate everything marked DATA (default) is contained in mapping

  //could also be uploaded and integrated but for now..
  dataSource = {
    eng: "Company data; mwb research",
    ger: "Firmenangaben; mwb research",
  };

  constructor(private tableUtil: Tables) {
    effect(() => {
      const companyNumbers = this.companyData();

      if (companyNumbers) {
        this.tablesService
          .fetchTableTemplate(this.templateName)
          .subscribe((tableTemplate) => {
            const tableData = this.tableUtil.createTableData(
              this.companyData(),
              tableTemplate?.template,
              this.decimals
            );
            this.tableLayout.set(tableData);
            this.headline.set(
              tableTemplate?.settings.title[tableData[0].settings.language]
            );
          });
      }
    }, enableSignalWritesInEffectContext);
  }

  getRowClasses(row: any): string {
    if (row.headline === true) {
      return "font-bold bg-gray-table-highlight text-white";
    } else if (row.highlight) {
      return "bg-blue-alster font-bold";
    } else if (row.highlight2) {
      return "bg-gray-200";
    } else {
      return "font-normal";
    }
  }
}
