import { Component } from "@angular/core";

import { FileManagerComponent } from "~features/file-manager/file-manager/file-manager.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-file-manager",
  standalone: true,
  imports: [FileManagerComponent, BreadcrumpComponent],
  templateUrl: "./admin-file-manager.component.html",
  styleUrl: "./admin-file-manager.component.scss",
})
export class AdminFileManagerComponent {}
