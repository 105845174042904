import { NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { ICompany } from "~global-interfaces/ICompany";
import { IInitiationNote } from "~global-interfaces/IInitiationNote";

import { CompanyResearchService } from "~features/company/company-research.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";
import { AdminRequestService } from "~shared/services/admin-request.service";

@Component({
  selector: "app-company-initiation-note-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PaginationComponent,
    RouterLink,
    NgClass,
    AdminFormButtonComponent,
    AdminFormRequestMessageComponent,
  ],
  templateUrl: "./company-initiation-note-form.component.html",
  styleUrl: "./company-initiation-note-form.component.scss",
})
export class CompanyInitiationNoteFormComponent implements OnInit {
  @Input() initiationNote: Signal<IInitiationNote | null>;
  initiationNoteForm: FormGroup;
  @Output() formSubmit = new EventEmitter<any>();
  adminRequestService = inject(AdminRequestService);
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  companyResearchService = inject(CompanyResearchService);
  companyTickerService = inject(CompanyTickerService);
  companyTicker = this.companyTickerService.getCompanyTicker();
  researchList: WritableSignal<any[]> = this.companyResearchService.items;
  totalCount = this.companyResearchService.totalCount;
  itemsPerPage = signal(20);
  currentPage = signal(1);
  private formBuilder = inject(FormBuilder);

  constructor() {
    this.adminRequestService.emptyLastRequest();
    effect(() => {
      if (this.multiModeCompanyTicker()) {
        this.initiationNoteForm
          .get("companyTicker")
          .setValue(this.multiModeCompanyTicker());
      }
    });
    effect(() => {
      this.initiationNoteForm.patchValue(this.initiationNote());
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.initiationNoteForm = this.formBuilder.group({
      companyTicker: ["", [Validators.required, Validators.minLength(1)]],
      research: ["", Validators.required],
    });
    this.fetchResearchList();
    this.formValidity.emit(this.initiationNoteForm.valid);
    this.formValueChange.emit(this.initiationNoteForm.value);
    // Subscribe to form changes
    this.initiationNoteForm.valueChanges.subscribe((values) => {
      this.formValueChange.emit(values);
    });

    // Subscribe to form validity changes
    this.initiationNoteForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  saveForm() {
    if (this.initiationNoteForm.valid) {
      const updatedInitiationNote: IInitiationNote = {
        ...this.initiationNote(),
        ...this.initiationNoteForm.value,
      };
      this.formSubmit.emit(updatedInitiationNote);
    } else {
      this.markFormGroupTouched(this.initiationNoteForm);
    }
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalCount());
  }

  fetchResearchList() {
    const paginationParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    };
    this.companyResearchService.fetchResearchOverview(
      this.initiationNoteForm.get("companyTicker").value,
      paginationParams
    );
  }

  onPageChange(page: number) {
    this.currentPage.set(page);
    this.fetchResearchList();
  }

  selectResearchForInitiation(researchId: string) {
    this.initiationNoteForm.get("research").setValue(researchId);
  }

  getErrorMessage(controlName: string): string {
    const control = this.initiationNoteForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
    }
    return "";
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
