<div class="container mx-auto min-h-screen px-4 lg:px-0">
  <div class="grid grid-cols-1 gap-8">
    <!-- Logo Row -->
    <div class="col-span-1">
      <app-logo
        #companyLogo
        [alt]="'logo image mwb research'"
        [logoClass]="'mix-blend-darken'"
        [src]="logoURL()"
        [wrapperClass]="'h-auto w-32 lg:h-auto lg:w-48'"
      >
      </app-logo>
    </div>

    <!-- Email Registration Section -->
    @if (event && !isPastEvent()) {
      <div class="col-span-1 w-full max-w-2xl mx-auto my-6">
        <h3 class="text-center mb-4 text-lg">
          Enter your email address to register for this event:
        </h3>
        <form [formGroup]="emailForm" (ngSubmit)="submitForm()" class="w-full">
          <div class="flex flex-col sm:flex-row">
            <input
              [disabled]="isPastEvent()"
              formControlName="email"
              type="email"
              class="form-input w-full sm:flex-grow mb-2 sm:mb-0 sm:mr-2"
              name="email"
              placeholder="email@example.com"
            />
            <button
              [disabled]="isPastEvent() || emailForm.invalid"
              type="submit"
              class="btn btn-std w-full sm:w-auto"
            >
              Submit
            </button>
          </div>
        </form>

        <!-- Error/Success Messages -->
        <div class="mt-4">
          @if (
            emailForm.get("email") &&
            (emailForm.get("email")!.dirty ||
              emailForm.get("email")!.touched) &&
            emailForm.get("email")!.errors
          ) {
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 w-full"
            >
              @if (emailForm.get("email")!.errors!["required"]) {
                <div>Email is required.</div>
              }
              @if (emailForm.get("email")!.errors!["email"]) {
                <div>Please enter a valid email address.</div>
              }
            </div>
          }
          @if (isSent() && isRegistered() && !isAlreadyRegistered()) {
            <div
              class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 text-center w-full"
              role="alert"
            >
              <h5 class="mb-2">
                You have successfully registered for this event and will shortly
                receive an email with login details and a calendar entry (please
                also check your spam folder).
              </h5>
              <h5 class="text-lg mb-2">
                If you have any problems, please contact
                feedback&#64;mwb-research.com.
              </h5>
              @if (isAuthenticated()) {
                <a routerLink="/user/dashboard">
                  <button class="btn btn-std">Go to your Dashboard</button>
                </a>
              }
            </div>
          }
          @if (isSent() && isRegistered() && isAlreadyRegistered()) {
            <div
              class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 text-center w-full"
              role="alert"
            >
              <h5 class="text-lg mb-2">
                You already are registered for this event.
              </h5>
              <h5 class="text-lg mb-2">
                You should have received an email with login details and a
                calendar entry (please also check your spam folder).
              </h5>
              <h5 class="text-lg mb-2">
                If you have any problems, please contact
                feedback&#64;mwb-research.com.
              </h5>
              @if (isAuthenticated()) {
                <a routerLink="/user/dashboard">
                  <button class="btn btn-std">Go to your Dashboard</button>
                </a>
              }
            </div>
          }
          @if (isSent() && !isRegistered()) {
            <div
              class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 text-center w-full"
              role="alert"
            >
              <h5 class="text-lg mb-2">
                You need to create an account before registering for an event.
              </h5>
              <h5 class="text-lg mb-2">
                After creating an account you can visit your dashboard and will
                be registered for this event automatically.
              </h5>
              <button class="btn btn-std" (click)="goToSignUp()">
                Create your account here
              </button>
            </div>
          }
        </div>
      </div>
    }
    <!-- Past Event Message -->
    @if (event && isPastEvent()) {
      <div class="col-span-1">
        <div
          class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 text-center mb-8 w-10/12 mx-auto"
          role="alert"
        >
          <h3 class="text-lg mb-4">This event has already taken place.</h3>
          <h5 class="text-lg">
            See all future and past events
            <a
              routerLink="/events"
              target="_blank"
              class="text-blue-600 hover:underline"
              >here</a
            >
          </h5>
        </div>
      </div>
    }

    <!-- Event Information -->
    @if (event) {
      <div class="col-span-1 grid grid-cols-1 lg:grid-cols-2 gap-8 gap-x-48">
        <!-- Event Details -->
        <div class="col-span-1">
          <h3 class="text-lg mb-4 text-left">Event</h3>
          <div
            class="bg-white p-4 text-left border-[1px] border-solid border-[#00000020]"
          >
            <h3 class="text-lg font-semibold mb-2">{{ event.title }}</h3>
            <div class="grid grid-cols-4 gap-4 my-4 text-smd">
              <div class="col-span-1 font-semibold space-y-3">
                <p>Date:</p>
                <p>Time:</p>
                <p>Location:</p>
              </div>
              <div class="col-span-3 space-y-3">
                <p>
                  {{
                    event.date
                      | date: "MMM dd, yyyy" : timeZoneFormat(event.date)
                  }}
                </p>
                <p>
                  {{ event.date | date: "HH:mm" : timeZoneFormat(event.date) }}
                  CE(S)T
                </p>
                <p>Online</p>
              </div>
            </div>
            <p class="text-smd">{{ event.description || "" }}</p>
          </div>
        </div>

        <!-- Speakers -->
        <div class="col-span-1">
          @if (event.speakers && event.speakers.length > 0) {
            <h3 class="text-lg mb-4 text-left">Speakers</h3>
            @for (speaker of event.speakers; track speaker) {
              <div
                class="bg-white mb-4 p-4 border-[1px] border-solid border-[#00000020]"
              >
                <div class="grid grid-cols-4 gap-4 items-center">
                  <div class="col-span-1">
                    <div class="w-16 h-16 lg:w-24 lg:h-24 mx-auto">
                      <img
                        [src]="speaker.imageURL"
                        class="w-full h-full object-cover rounded-full"
                        alt="{{ speaker.name }}"
                      />
                    </div>
                  </div>
                  <div class="col-span-3 pl-4 text-left">
                    <h4 class="text-md font-semibold mb-2">
                      {{ speaker.name }}
                    </h4>
                    <p>{{ speaker.position }}</p>
                  </div>
                </div>
              </div>
            }
          }
        </div>
      </div>
    }

    <!-- Back to Dashboard Link (if authenticated) -->
    @if (isAuthenticated()) {
      <div class="text-left">
        <a
          [routerLink]="['/user/dashboard']"
          class="text-gray-500 hover:underline inline-flex items-center"
        >
          <i class="fas fa-arrow-left mr-1"></i>
          <span>back to your dashboard</span>
        </a>
      </div>
    }

    <!-- Footer -->
    <div class="col-span-1 text-right text-gray-500 mt-8">
      Hosted by mwb research
    </div>
  </div>
</div>
