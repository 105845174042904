<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Create a frontend-config entry</p>
      <h2 class="text-red-600">
        If you dont know what this is, you dont need it. Talk to Elias before
        clicking here
      </h2>
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <button
        (click)="createFrontendConfig()"
        class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      >
        Create Frontend Config
      </button>
    </div>
  </main>
</div>
