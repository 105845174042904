import { NgClass } from "@angular/common";
import { Component, inject, Signal } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IGuidedMenuStep } from "~global-interfaces/IGuidedMenuStep";

import { CreateResearchProcessService } from "~features/guided-research-creation-flow/create-research-process.service";

@Component({
  selector: "app-create-research-process",
  standalone: true,
  imports: [RouterLink, ReactiveFormsModule, NgClass],
  templateUrl: "./create-research-process.component.html",
  styleUrl: "./create-research-process.component.scss",
})
export class CreateResearchProcessComponent {
  creationProcessMenuService = inject(CreateResearchProcessService);

  steps: Signal<IGuidedMenuStep[]> = this.creationProcessMenuService.steps;
  isGuidedMenuEnabled: Signal<boolean> =
    this.creationProcessMenuService.isGuidedMenuEnabled;
  formBuilder = inject(FormBuilder);

  tickerForm: FormGroup;
  currentTicker = this.creationProcessMenuService.getCurrentCompanyTicker();
  constructor() {
    this.tickerForm = this.formBuilder.group({
      ticker: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(15),
        ],
      ],
    });
  }
  onSubmit() {
    if (this.tickerForm.valid) {
      const formData = this.tickerForm.value;
      this.creationProcessMenuService.setCurrentCompanyTicker(formData.ticker);
      this.tickerForm.reset();
    }
  }

  getCurrentStepIndex(): number {
    return this.creationProcessMenuService.getCurrentStepIndex();
  }
  isCurrentStepCompleted(): boolean {
    return this.creationProcessMenuService.isCurrentStepCompleted();
  }

  navigateToNextStep() {
    this.creationProcessMenuService.navigateToNextStep();
  }
}
