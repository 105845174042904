@if (companyData()) {
  <cdk-accordion>
    <cdk-accordion-item
      #accordionItem="cdkAccordionItem"
      class="example-accordion-item"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-A'"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-header-A'"
    >
      <div
        class="flex items-center my-1 py-1 border-t-1 border-[#c7e7fd]"
        (click)="accordionItem.toggle()"
      >
        <div class="hover:underline text-base font-medium leading-[1.4]">
          @if (accordionItem.expanded) {
            <i class="fa fa-chevron-up px-2 text-blue-500"></i>
          } @else {
            <i class="fa fa-chevron-down px-2 text-blue-500"></i>
          }
        </div>
        <div class="hover:underline text-base font-medium leading-[1.4]">
          DCF Model
        </div>
      </div>
      <div
        class="w-full"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-'"
        [attr.aria-labelledby]="'accordion-header-A'"
      >
        <div class="container mx-auto py-0 my-0 font-sans">
          <div class="grid grid-cols-1 py-0 my-0">
            <div class="col-span-1 mx-0 px-0">
              <div class="overflow-x-auto mx-auto mt-2">
                <table class="w-full">
                  <tbody>
                    <tr class="">
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        DCF ({{ companyData().base?.curr }}m)<br />
                        (except per share data and beta)
                      </th>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        {{ dcfData().HL_dcf.value[1] }}
                      </th>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        {{ dcfData().HL_dcf.value[2] }}
                      </th>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        {{ dcfData().HL_dcf.value[3] }}
                      </th>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        {{ dcfData().HL_dcf.value[4] }}
                      </th>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        {{ dcfData().HL_dcf.value[5] }}
                      </th>
                      @if (dcfType !== "Italy") {
                        <th
                          class="px-4ö py-2ö bg-gray-alster text-white text-left"
                        >
                          {{ dcfData().HL_dcf.value[6] }}
                        </th>
                      }
                      @if (dcfType !== "Italy") {
                        <th
                          class="px-4ö py-2ö bg-gray-alster text-white text-left"
                        >
                          {{ dcfData().HL_dcf.value[7] }}
                        </th>
                      }
                      @if (dcfType !== "Italy") {
                        <th
                          class="px-4ö py-2ö bg-gray-alster text-white text-left"
                        >
                          {{ dcfData().HL_dcf.value[8] }}
                        </th>
                      }
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        Terminal
                        <br />value
                      </th>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_nopat.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_nopat.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_nopat.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left"></td>
                      }
                    </tr>
                    <!-- Rest of the table rows -->
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_depr.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_depr.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_depr.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left"></td>
                      }
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_wc.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_chg_wc.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_chg_wc.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left"></td>
                      }
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_chg_long_prov.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_chg_long_prov.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_chg_long_prov.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left"></td>
                      }
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_capex.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_capex.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_capex.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left"></td>
                      }
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_cash_flow.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_cash_flow.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_cash_flow.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_cash_flow.value[9] }}
                        </td>
                      }
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_present_value.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_present_value.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_present_value.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_present_value.value[9] }}
                        </td>
                      }
                    </tr>
                    <tr class="border-b border-gray-800">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.value[1] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.value[2] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.value[3] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.value[4] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.value[5] }}
                      </td>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().RW_dcf_wacc.value[6] }}
                      </td>
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_wacc.value[7] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_wacc.value[8] }}
                        </td>
                      }
                      @if (dcfType !== "Italy") {
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().RW_dcf_wacc.value[9] }}
                        </td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-5 px-0">
              <div class="overflow-x-auto mx-auto mt-2">
                <table class="w-full">
                  <tbody>
                    <tr>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        DCF per share derived from
                      </th>
                      <td class="px-4ö py-2ö bg-gray-alster text-white"></td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_tpv.key }}
                      </th>
                      <td class="px-4ö py-2ö text-right">
                        {{ dcfData().dcf_tpv.value }}
                      </td>
                    </tr>
                    @if (dcfType !== "Italy") {
                      <tr>
                        <th class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_mid_yr_adj.key }}
                        </th>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().dcf_mid_yr_adj.value }}
                        </td>
                      </tr>
                    }
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_net_debt.key }}
                      </th>
                      <td class="px-4ö py-2ö text-right">
                        {{ dcfData().dcf_net_debt.value }}
                      </td>
                    </tr>
                    @if (dcfType !== "Italy") {
                      <tr class="hover:bg-gray-500">
                        <th class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_fin_asset.key }}
                        </th>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().dcf_fin_asset.value }}
                        </td>
                      </tr>
                    }
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_prov.key }}
                      </th>
                      <td class="px-4ö py-2ö text-right">
                        {{ dcfData().dcf_prov.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_eq_value.key }}
                      </th>
                      <td class="px-4ö py-2ö text-right">
                        {{ dcfData().dcf_eq_value.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_nosh.key }}
                      </th>
                      <td class="px-4ö py-2ö text-right">
                        {{ dcfData().dcf_nosh.value }}
                      </td>
                    </tr>
                    <tr class="border-b border-gray-800">
                      <th class="px-4ö py-2ö text-left">&nbsp;</th>
                      <td class="px-4ö py-2ö text-right">&nbsp;</td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö bg-blue-alster text-left">
                        {{ dcfData().dcf_dcfps.key }}
                      </th>
                      <td class="px-4ö py-2ö bg-blue-alster text-right">
                        {{ dcfData().dcf_dcfps.value | number: "1.2-2" }}
                      </td>
                    </tr>
                    <tr class="border-b border-gray-800">
                      <th class="px-4ö py-2ö bg-blue-alster text-left">
                        {{ dcfData().dcf_upside.key }}
                      </th>
                      <td class="px-4ö py-2ö bg-blue-alster text-right">
                        {{ dcfData().dcf_upside.value }}
                      </td>
                    </tr>
                    <tr>
                      <th class="px-4ö py-2ö text-left">&nbsp;</th>
                      <td class="px-4ö py-2ö text-left">&nbsp;</td>
                    </tr>
                    <tr>
                      <th class="px-4ö py-2ö text-left">&nbsp;</th>
                      <td class="px-4ö py-2ö text-left">&nbsp;</td>
                    </tr>
                    <tr>
                      <th class="px-4ö py-2ö text-left">&nbsp;</th>
                      <td class="px-4ö py-2ö text-left">&nbsp;</td>
                    </tr>
                    <tr style="height: 21px !important"></tr>
                    <tr style="margin-top: 15px">
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        {{ dcfData().dcf_share_price.key }}
                      </th>
                      @if (companyData().quotes) {
                        <td
                          class="px-4ö py-2ö bg-gray-alster text-white text-left"
                        >
                          {{
                            companyData().quotes[
                              companyData().quotes?.length - 1
                            ].close_for_show | number: "1.2-2"
                          }}
                        </td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-span-1 px-0"></div>
            <div class="col-span-6 px-0">
              <div class="overflow-x-auto mx-auto mt-2">
                <table class="w-full">
                  <tbody>
                    <tr>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        DCF avg. growth and earnings assumptions
                      </th>
                      <td class="px-4ö py-2ö bg-gray-alster text-white"></td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_plan_hor.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_plan_hor.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_term_val_gro.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_term_val_gro.value }}
                      </td>
                    </tr>
                    <tr class="border-b border-gray-800 hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_term_val_wacc.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_term_val_wacc.value }}
                      </td>
                    </tr>
                    <tr>
                      <th class="px-4ö py-2ö text-left">&nbsp;</th>
                      <td class="px-4ö py-2ö text-left">&nbsp;</td>
                    </tr>
                    <tr>
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        Terminal WACC derived from
                      </th>
                      <td class="px-4ö py-2ö bg-gray-alster text-white"></td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_cost_borr.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_cost_borr.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_long_term_tax_rate.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_long_term_tax_rate.value }}
                      </td>
                    </tr>
                    @if (dcfType !== "Italy") {
                      <tr class="hover:bg-gray-500">
                        <th class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_beta.key }}
                        </th>
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_beta.value }}
                        </td>
                      </tr>
                    }
                    @if (dcfType !== "Italy") {
                      <tr class="hover:bg-gray-500">
                        <th class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_unlevered_beta.key }}
                        </th>
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_unlevered_beta.value }}
                        </td>
                      </tr>
                    }
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_target_debt_equity.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_target_debt_equity.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_relevered_beta.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_relevered_beta.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_rfr.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_rfr.value }}
                      </td>
                    </tr>
                    <tr class="hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_erp.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_erp.value }}
                      </td>
                    </tr>
                    <tr class="border-b border-gray-800 hover:bg-gray-500">
                      <th class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_cost_of_debt.key }}
                      </th>
                      <td class="px-4ö py-2ö text-left">
                        {{ dcfData().dcf_cost_of_debt.value }}
                      </td>
                    </tr>
                    <tr>
                      <th class="px-4ö py-2ö text-left">&nbsp;</th>
                      <td class="px-4ö py-2ö text-left">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1">
            <div class="col-span-1 px-0">
              <div class="overflow-x-auto mx-auto mt-2">
                <table class="w-full">
                  <tbody>
                    <tr class="border-b border-gray-800">
                      <th
                        class="px-4ö py-2ö bg-gray-alster text-white text-left"
                      >
                        Sensitivity analysis DCF
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-6 gap-4">
            <div
              class="col-span-1 transform -rotate-90 writing-mode-vertical-lr mx-auto px-0"
            >
              Change in WACC
            </div>
            <div class="col-span-3 px-0">
              <div class="text-center">
                Long term growth
                <div class="overflow-x-auto mx-auto mt-2">
                  <table class="w-full">
                    <tbody>
                      <tr>
                        <td class="px-4ö py-2ö text-center"></td>
                        <td class="px-4ö py-2ö text-center">
                          {{ dcfData().sensi_a.value }}
                        </td>
                        <td class="px-4ö py-2ö text-center">
                          {{ dcfData().sensi_b.value }}
                        </td>
                        <td class="px-4ö py-2ö text-center">
                          {{ dcfData().sensi_c.value }}
                        </td>
                        <td class="px-4ö py-2ö text-center">
                          {{ dcfData().sensi_d.value }}
                        </td>
                        <td class="px-4ö py-2ö text-center">
                          {{ dcfData().sensi_e.value }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().sensi_f.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_g.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_h.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_i.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_j.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_k.value }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().sensi_l.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_m.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_n.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_o.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_p.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_q.value }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().sensi_r.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_s.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_t.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-200">
                          {{ dcfData().sensi_u.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_v.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_w.value }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().sensi_x.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_y.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_z.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_aa.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-blue-alster">
                          {{ dcfData().sensi_ab.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_ac.value }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-4ö py-2ö text-right">
                          {{ dcfData().sensi_ad.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_ae.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_af.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_ag.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_ah.value }}
                        </td>
                        <td class="px-4ö py-2ö bg-yellow-200">
                          {{ dcfData().sensi_ai.value }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <div>
                Share of present value
                <div class="overflow-x-auto mx-auto mt-2">
                  <table class="w-full">
                    <tbody>
                      <tr>
                        <th class="px-4ö py-2ö text-left font-medium"> </th>
                        <td class="px-4ö py-2ö text-left"> </td>
                      </tr>
                      <tr>
                        <th class="px-4ö py-2ö text-left font-medium">
                          {{ start }} - {{ mid }}
                        </th>
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_share_a.value }}
                        </td>
                      </tr>
                      <tr>
                        <th class="px-4ö py-2ö text-left font-medium">
                          {{ mid2 }} - {{ end }}
                        </th>
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_share_b.value }}
                        </td>
                      </tr>
                      <tr>
                        <th class="px-4ö py-2ö text-left font-medium">
                          terminal value
                        </th>
                        <td class="px-4ö py-2ö text-left">
                          {{ dcfData().dcf_share_c.value }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          @if (dcfType !== "Italy") {
            <p class="text-left">Source: mwb research</p>
          }
          @if (dcfType === "Italy") {
            <p class="text-left">Source: Italian Research</p>
          }
        </div>
      </div>
    </cdk-accordion-item>
  </cdk-accordion>
}
