import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ILegalDisclaimerCompany } from "~global-interfaces/ILegalDisclaimerCompany";

import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { AdminCompanySwotCreatorComponent } from "~features/company/admin/company-swot/admin-company-swot-creator/admin-company-swot-creator.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminLegalDisclaimerCompanyEditorFormComponent } from "~features/legal/admin-legal-disclaimer-company-editor-form/admin-legal-disclaimer-company-editor-form.component";
import { LegalDisclaimerService } from "~features/legal/legal-disclaimer.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_LEGALDISCLAIMERCOMPANY } from "~shared/initial-values";

@Component({
  selector: "app-admin-legal-disclaimer-company-creator",
  standalone: true,
  imports: [
    AdminCompanySettingsEditorFormComponent,
    AdminCompanySwotCreatorComponent,
    BreadcrumpComponent,
    FileUploadComponent,
    AdminSidePanelComponent,
    AdminLegalDisclaimerCompanyEditorFormComponent,
  ],
  templateUrl: "./admin-legal-disclaimer-company-creator.component.html",
  styleUrl: "./admin-legal-disclaimer-company-creator.component.scss",
})
export class AdminLegalDisclaimerCompanyCreatorComponent {
  legalDisclaimerCompany: WritableSignal<ILegalDisclaimerCompany | null> =
    signal(INITIAL_LEGALDISCLAIMERCOMPANY);
  private legalDisclaimerService = inject(LegalDisclaimerService);

  constructor() {}

  onFormSubmit(newData) {
    //console.log(newData);
    this.legalDisclaimerService.createLegalDisclaimerCompany(newData);
  }
}
