import { Component, inject } from "@angular/core";

import { CookieService } from "~features/cookies/cookie.service";

@Component({
  selector: "app-cookie-toast",
  standalone: true,
  imports: [],
  templateUrl: "./cookie-toast.component.html",
  styleUrl: "./cookie-toast.component.scss",
})
export class CookieToastComponent {
  cookieService = inject(CookieService);

  acceptCookies() {
    console.log("accepted cookies");
    this.cookieService.setFullCookiePermissions();
  }

  denyCookies() {
    console.log("Denied cookies");
    this.cookieService.setAnonymousCookiePermissions();
  }
}
