<div class="flex bg-gray-50 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        See all research for all companies
      </p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <div>
        <h5>Filter</h5>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          <!-- Year Select -->
          <div>
            <label
              class="block text-sm font-medium text-gray-700 mb-1"
              for="yearSelect"
              >Year (of research date)</label
            >
            <select
              (ngModelChange)="yearSearchTerm.set($event)"
              [ngModel]="yearSearchTerm()"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              id="yearSelect"
            >
              <option [ngValue]="null">All Years</option>
              @for (year of yearOptions; track year) {
                <option [ngValue]="year">{{ year }}</option>
              }
            </select>
          </div>

          <!-- Quarter Select -->
          <div>
            <label
              class="block text-sm font-medium text-gray-700 mb-1"
              for="quarterSelect"
              >Quarter (of research date)</label
            >
            <select
              (ngModelChange)="quarterSearchTerm.set($event)"
              [ngModel]="quarterSearchTerm()"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              id="quarterSelect"
            >
              <option [ngValue]="null">All Quarters</option>
              @for (quarter of quarterOptions; track quarter.value) {
                <option [ngValue]="quarter.value">{{ quarter.label }}</option>
              }
            </select>
          </div>

          <!-- Company Ticker Select -->
          <div>
            <label
              class="block text-sm font-medium text-gray-700 mb-1"
              for="companyTickerSelect"
              >Company Ticker</label
            >
            <select
              (ngModelChange)="companyTickerSearchTerm.set($event)"
              [ngModel]="companyTickerSearchTerm()"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              id="companyTickerSelect"
            >
              <option [ngValue]="null">All Companies</option>
              @for (ticker of tickerOptions(); track ticker) {
                <option [ngValue]="ticker().companyTicker">
                  {{ ticker()?.name }} ({{ ticker()?.companyTicker }})
                </option>
              }
            </select>
          </div>

          <!-- Title Search Input -->
          <div>
            <label
              class="block text-sm font-medium text-gray-700 mb-1"
              for="titleSearch"
              >Search by Title</label
            >
            <input
              (keyup.enter)="fetchResearchList()"
              (ngModelChange)="titleSearchTerm.set($event)"
              [ngModel]="titleSearchTerm()"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              id="titleSearch"
              placeholder="Enter title..."
              type="text"
            />
          </div>
        </div>

        <!-- Search Button -->
        <div class="flex justify-end">
          <button
            (click)="fetchResearchList()"
            class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Apply Filter
          </button>
        </div>
      </div>

      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }} / {{ totalCount() }}
        </p>
      </div>

      <div class="overflow-x-auto">
        <table class="w-full">
          <thead class="bg-gray-50">
            <tr>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                Title
              </th>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                Published at
              </th>
              <th
                class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
              >
                Edit
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (research of researchList(); track research.title) {
              <tr>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ research.title }}
                </td>
                <td class="px-4 py-3 text-sm text-gray-900">
                  {{ research.published_date }}
                </td>
                <td class="px-4 py-3 text-sm">
                  <a
                    [routerLink]="[
                      '..',
                      'company',
                      'settings',
                      research.companyTicker,
                      'research',
                      'list',
                      'edit',
                      research.title
                    ]"
                  >
                    <button
                      class="px-3 py-1 bg-blue-500 hover:bg-blue-400 text-white text-xs font-sans rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Edit
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }} / {{ totalCount() }}
        </p>
      </div>
    </div>
  </main>
</div>
