<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">See all events</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <div>
        <h5>Filter</h5>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
          <!-- Event Company Name Select -->
          <div>
            <label
              class="block text-sm font-medium text-gray-700 mb-1"
              for="companyTickerSelect"
              >Company Name</label
            >
            <select
              (ngModelChange)="companyNameSearchTerm.set($event)"
              [ngModel]="companyNameSearchTerm()"
              class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              id="companyTickerSelect"
            >
              <option [ngValue]="null">All Companies</option>
              @for (name of companiesFromEvents(); track name) {
                <option [ngValue]="name">
                  {{ name }}
                </option>
              }
            </select>
          </div>

          <div class="self-end">
            <button
              (click)="fetchEvents()"
              class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Apply Filter
            </button>
          </div>
          <div class="self-end">
            <p class="text-sm text-gray-muted border-2 border-red p-2 rounded">
              To fix duplicate company names, make sure to set the same company
              name for each event.
            </p>
          </div>
          <div></div>
          <div></div>
        </div>

        <!-- Search Button -->
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="fetchMoreEvents($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalItems() }}
        </p>
      </div>

      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-4 py-2 text-left">Title</th>
              <th class="px-4 py-2 text-left">Date</th>
              <!--              <th class="px-4 py-2 text-left">Language</th>-->
              <th class="px-4 py-2 text-left">Company</th>
              <th class="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (event of eventsList(); track event; let index = $index) {
              <tr>
                <td class="px-4 py-2">{{ event.title }}</td>
                <td class="px-4 py-2">{{ event.date }}</td>
                <!--                <td class="px-4 py-2">{{ event.language }}</td>-->
                <td class="px-4 py-2">{{ event.companyName }}</td>
                <td class="px-4 py-2">
                  <a [routerLink]="[event._id, 'edit']">
                    <button
                      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Details
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="fetchMoreEvents($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalItems() }}
        </p>
      </div>
    </div>
  </main>
</div>
