<div class="container mx-auto px-4 py-8">
  <form
    [formGroup]="frontendConfigForm"
    class="bg-white shadow-lg rounded-lg p-6 space-y-6"
  >
    <!-- Active Transaction Checkbox -->
    <div class="bg-gray-100 p-4 rounded-md">
      <label class="flex items-center space-x-3 cursor-pointer">
        <input
          [ngClass]="{
            'border-red-500': isInvalidAndTouched('activeTransaction')
          }"
          class="form-checkbox h-6 w-6 text-green-600 rounded focus:ring-green-500 transition duration-150 ease-in-out"
          formControlName="activeTransaction"
          id="activeTransaction"
          type="checkbox"
        />
        <span class="text-lg font-medium text-gray-700"
          >Show transaction banner</span
        >
      </label>
      @if (isInvalidAndTouched("activeTransaction")) {
        <p class="mt-1 text-sm text-red-500">
          {{ getErrorMessage("activeTransaction") }}
        </p>
      }
    </div>

    <!--    &lt;!&ndash; Selected Transaction Display &ndash;&gt;-->
    <!--    @if (frontendConfigForm.get("transactionId")?.value) {-->
    <!--      <div class="bg-green-50 p-4 rounded-md">-->
    <!--        <h3 class="text-lg font-semibold text-green-800 mb-2">-->
    <!--          Selected Transaction-->
    <!--        </h3>-->
    <!--        <p class="text-sm text-green-600">-->
    <!--          ID: {{ frontendConfigForm.get("transactionId")?.value }}-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    }-->

    <!-- Transactions Table -->
    <div class="overflow-x-auto">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Actions
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              ID
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Text
            </th>
            <th
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Link
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          @for (transaction of transactionsList(); track transaction._id) {
            <tr>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                <button
                  (click)="selectTransaction(transaction._id)"
                  class="px-3 py-1 rounded-full text-sm font-semibold transition duration-150 ease-in-out"
                  [ngClass]="{
                    'bg-green-100 text-green-800 hover:bg-green-200':
                      frontendConfigForm.get('transactionId')?.value !==
                      transaction._id,
                    'bg-green-500 text-white':
                      frontendConfigForm.get('transactionId')?.value ===
                      transaction._id
                  }"
                >
                  {{
                    frontendConfigForm.get("transactionId")?.value ===
                    transaction._id
                      ? "Selected"
                      : "Select"
                  }}
                </button>
              </td>
              <td
                class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >
                {{ transaction._id }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ transaction.text }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <a
                  [href]="transaction.link"
                  target="_blank"
                  class="text-green-600 hover:underline truncate block max-w-xs"
                  title="{{ transaction.link }}"
                >
                  {{ transaction.link }}
                </a>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <app-pagination
      (pageChanged)="fetchMoreTransactions($event)"
      [currentPage]="currentPage"
      [itemsPerPage]="itemsPerPage"
      [totalItems]="totalItems"
    ></app-pagination>

    <!-- Submit Button -->
    <div class="flex justify-start">
      <app-admin-form-button
        (click)="saveForm()"
        [class]="'admin-form-button'"
        [disabled]="!frontendConfigForm.valid"
      >
        Submit
      </app-admin-form-button>
    </div>

    <app-admin-form-request-message></app-admin-form-request-message>
  </form>
</div>
