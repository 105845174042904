<div class="flex flex-col min-h-screen">
  <section class="bg-white py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <div class="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-16">
        <div class="lg:col-span-12 mt-8">
          <h2 class="text-md lg:text-lgx font-bold">
            {{ weeklyScreener().title }}
          </h2>
          <div class="text-sm text-gray-600 tracking-wider uppercase">
            <app-date
              [date]="weeklyScreener().published_date"
              [formatType]="'dd/MM/yyyy'"
            ></app-date
            >, FEATURED IDEAS
          </div>
        </div>

        <div class="lg:col-span-8">
          <app-display-editor-content
            [content]="weeklyScreener().content_DE"
            class="prose max-w-none"
          ></app-display-editor-content>
        </div>

        <div class="lg:col-span-4 mt-8 lg:mt-0">
          <h3 class="text-lgx font-semibold mb-4">Most recent</h3>
          <hr class="mb-6" />
          <ul class="space-y-6">
            @for (
              screener of overviewListAllWeeklyScreeners();
              track screener
            ) {
              <li>
                <div class="text-sm text-gray-500 mb-1">
                  {{ screener.published_date | date: "mediumDate" }}
                </div>
                <div class="text-md font-semibold mb-2">
                  {{ screener.title }}
                </div>
                <p class="text-gray-700 mb-2">
                  {{ screener.teaser_text }}
                </p>
                <a
                  class="text-blue-600 hover:text-blue-800 font-medium"
                  [routerLink]="[
                    '.',
                    {
                      published_date: screener.published_date,
                      title: screener.title
                    }
                  ]"
                  (click)="
                    getWeeklyScreener(screener.published_date, screener.title)
                  "
                  >Read more</a
                >
              </li>
              <hr class="my-6" />
            }
          </ul>
          <button
            (click)="showMore()"
            class="mt-4 px-4 py-2 bg-blue-research text-white uppercase tracking-widest hover:bg-blue-700 transition duration-300"
          >
            show more
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white py-8 lg:py-12">
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-more-content-cta></app-more-content-cta>
    </div>
  </section>
</div>
