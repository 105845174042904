import { Component, inject, signal, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";
import {
  IResearchPodcast,
  IResearchPodcastPopulated,
} from "~global-interfaces/IResearchPodcast";

import { PodcastAdminService } from "~features/podcasts/podcast-admin.service";
import { PodcastService } from "~features/podcasts/podcast.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-podcast-list",
  standalone: true,
  imports: [
    AdminSidePanelComponent,
    BreadcrumpComponent,
    PaginationComponent,
    RouterLink,
  ],
  templateUrl: "./admin-podcast-list.component.html",
  styleUrl: "./admin-podcast-list.component.scss",
})
export class AdminPodcastListComponent {
  podcastService = inject(PodcastService);
  podcastAdminService = inject(PodcastAdminService);
  podcastList: WritableSignal<IResearchPodcast[]> = signal<
    IResearchPodcastPopulated[]
  >([]);
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);

  constructor() {
    this.fetchPodcasts();
  }

  fetchPodcasts() {
    this.podcastAdminService.fetchResearchPodcastList({
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    });
    this.podcastList = this.podcastAdminService.getPodcastList();
    this.totalItems = this.podcastAdminService.getTotalCount();
  }

  fetchMorePodcasts($event: number) {
    this.currentPage.set($event);
    this.fetchPodcasts();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalItems());
  }
}
