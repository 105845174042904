import { Component, inject, OnInit, Signal } from "@angular/core";
import { IExchangeMapping } from "~global-interfaces/IExchangeMapping";

import { CompanyExchangeMappingEditorFormComponent } from "~features/company/admin/company-exchange-mapping/company-exchange-mapping-editor-form/company-exchange-mapping-editor-form.component";
import { CompanydataService } from "~features/company/companydata.service";
import { ExchangeMappingService } from "~features/company/exchangeMapping.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-company-exchange-mapping-editor",
  standalone: true,
  imports: [
    CreationProcessMenuComponent,
    BreadcrumpComponent,
    CompanyExchangeMappingEditorFormComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./company-exchange-mapping-editor.component.html",
  styleUrl: "./company-exchange-mapping-editor.component.scss",
})
export class CompanyExchangeMappingEditorComponent implements OnInit {
  creationProcessMenuService = inject(CreationProcessMenuService);
  exchangeMappingService = inject(ExchangeMappingService);
  currentCompanyTicker = this.creationProcessMenuService.currentCompanyTicker;
  companyDataService = inject(CompanydataService);
  exchangeMapping: Signal<IExchangeMapping> =
    this.exchangeMappingService.exchangeMapping;
  ngOnInit() {
    // console.log(this.currentCompanyTicker());
    //console.log(this.exchangeMapping());
  }

  onFormSubmit(newData: any) {
    this.exchangeMappingService.updateExchangeMapping(newData);
    // console.log(newData);
    this.creationProcessMenuService.setStepCompleted("Exchange Mapping");
  }
}
