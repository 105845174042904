import { Component, inject, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ITransaction } from "~global-interfaces/ITransaction";

import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminTransactionEditorFormComponent } from "~features/transactions/admin/admin-transaction-editor-form/admin-transaction-editor-form.component";
import { TransactionService } from "~features/transactions/transaction.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-transaction-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminTransactionEditorFormComponent,
    AdminSidePanelComponent,
    FileUploadComponent,
  ],
  templateUrl: "./admin-transaction-editor.component.html",
  styleUrls: ["./admin-transaction-editor.component.scss"],
})
export class AdminTransactionEditorComponent {
  transactionService = inject(TransactionService);
  transaction: WritableSignal<ITransaction | null> =
    this.transactionService.currentlyEditingTransaction;

  constructor(private route: ActivatedRoute) {
    const transactionId =
      this.route.snapshot.paramMap.get("transactionid") ?? "";
    this.transactionService.fetchEditingTransaction(transactionId);
  }

  onFormSubmit(newData: ITransaction) {
    // console.log(newData);
    this.transactionService.updateTransaction(newData);
  }
}
