import { AsyncPipe, NgOptimizedImage, SlicePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";

import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { CompanyGridHighlightComponent } from "~features/company-grid/company-grid-highlight/company-grid-highlight.component";
import { CompanyGridTileComponent } from "~features/company-grid/company-grid-tile/company-grid-tile.component";
import { CompanyScreenerComponent } from "~features/company-screener/company-screener.component";
import { AutoSliderLandingPageComponent } from "~features/custom-content-slider/auto-slider-landing-page/auto-slider-landing-page.component";
import { EventGridMixedComponent } from "~features/events/event-grid-mixed/event-grid-mixed.component";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";
import { SEOService } from "~features/seo/seo.service";
import { WeeklyScreenerTeaserListComponent } from "~features/weekly-screener/weekly-screener-teaser-list/weekly-screener-teaser-list.component";

@Component({
  selector: "app-welcome-page",
  templateUrl: "./welcome-page.component.html",
  styleUrls: ["./welcome-page.component.scss"],
  standalone: true,
  imports: [
    AutoSliderLandingPageComponent,
    EventGridMixedComponent,
    CompanyGridComponent,
    CompanyScreenerComponent,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    SubscribeBannerComponent,
    AsyncPipe,
    SlicePipe,
    CompanyGridTileComponent,
    CompanyGridHighlightComponent,
    WeeklyScreenerTeaserListComponent,
    CompanySearchComponent,
    NgOptimizedImage,
  ],
})
export class WelcomePageComponent implements OnInit {
  @ViewChild("videoBlock", { static: true }) videoBlock: ElementRef;
  math = Math;

  constructor(private seoService: SEOService) {}

  public ngOnInit(): void {
    this.seoService.setMetaTags(null, null, null, null);
  }
}
