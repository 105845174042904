import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map, tap } from "rxjs/operators";

import { alsterAPI_URL } from "~api/api-urls";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Injectable({
  providedIn: "root",
})
export class TablesService {
  http = inject(HttpClient);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);

  public fetchTableTemplate(name: string) {
    const url = alsterAPI_URL + "tabletemplates/" + encodeURIComponent(name);
    // TODO: check subscribe function to fill tap

    return this.http.get(url).pipe(
      map((res: any) => res.data[0]),

      catchError((error) =>
        this.errorHandlingService.handleError(error, "fetch table template")
      ),
      finalize(() => {
        this.isLoading.set(false);
      })
    );
  }

  getisLoading() {
    return this.isLoading;
  }
}
