<div class="flex items-center justify-end h-24">
  <div
    class="flex flex-col items-center justify-center bg-blue-alster w-20 h-full"
  >
    <h5 class="uppercase m-0 font-medium text-baseplus leading-[1.2]">
      {{ comingEvent.date | date: "MMM" }}
    </h5>
    <h3 class="m-0">{{ comingEvent.date | date: "dd" }}</h3>
    <h5 class="m-0 font-medium text-baseplus leading-[1.2]">
      {{ comingEvent.date | date: "yyyy" }}
    </h5>
  </div>
  <div class="flex-1 flex flex-col justify-center ml-4">
    <div>
      <h4 class="">{{ comingEvent.title }}</h4>
      <div class="flex items-center justify-between">
        <h5 class="m-0">
          {{ comingEvent.date | date: "HH:mm" }}
        </h5>
        <button
          (click)="navigateToEventRegistrationPage(comingEvent)"
          class="sm:hidden text-blue-500 uppercase tracking-widest"
        >
          register<i class="fas fa-arrow-right ml-1"></i>
        </button>
      </div>
    </div>
  </div>
  <button
    (click)="navigateToEventRegistrationPage(comingEvent)"
    class="hidden sm:block text-blue-500 uppercase font-medium tracking-widest ml-4"
  >
    register<i class="fas fa-arrow-right ml-1"></i>
  </button>
</div>
