<form
  [formGroup]="userForm"
  class="max-w-xl mx-auto bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-4"
>
  <div class="mb-6">
    <label
      class="block text-gray-700 text-sm font-secondary mb-2"
      for="userName"
    >
      Login Name
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          userForm.get('userName')?.invalid && userForm.get('userName')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="userName"
      type="text"
    />
    @if (
      userForm.get("userName")?.invalid && userForm.get("userName")?.touched
    ) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("userName") }}
      </p>
    }
    <p class="text-xs text-gray-muted">
      This mail is only used to login to the ResearchHub.
    </p>
  </div>

  <div class="mb-6">
    <label class="block text-gray-700 text-sm font-secondary mb-2" for="email">
      Email
    </label>
    <input
      [ngClass]="{
        'border-red-500':
          userForm.get('email')?.invalid && userForm.get('email')?.touched
      }"
      class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
      formControlName="email"
      type="email"
    />
    @if (userForm.get("email")?.invalid && userForm.get("email")?.touched) {
      <p class="mt-1 text-xs text-red-500">
        {{ getErrorMessage("email") }}
      </p>
    }
    <p class="text-xs text-gray-muted">
      This mail is used for the Brevo <-> ReasearchHub connection.
    </p>
  </div>
  @if (showMismatchWarning) {
    <div class="mb-6 p-3 bg-yellow-100 border border-yellow-400 rounded-md">
      <p class="text-sm text-red-600">
        Warning: Login Name and Email do not match. They MUST be identical for a
        fully set up account. A missing or different email indicates issues with
        the Brevo connection.
      </p>
    </div>
  }
  <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
    <!--    <div>-->
    <!--      <label-->
    <!--        class="block text-gray-700 text-sm font-secondary mb-2"-->
    <!--        for="firstName"-->
    <!--      >-->
    <!--        First Name <app-lock-icon></app-lock-icon>-->
    <!--      </label>-->
    <!--      <input-->
    <!--        [ngClass]="{-->
    <!--          'border-red-500':-->
    <!--            userForm.get('firstName')?.invalid &&-->
    <!--            userForm.get('firstName')?.touched-->
    <!--        }"-->
    <!--        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"-->
    <!--        formControlName="firstName"-->
    <!--        type="text"-->
    <!--      />-->
    <!--      @if (-->
    <!--        userForm.get("firstName")?.invalid && userForm.get("firstName")?.touched-->
    <!--      ) {-->
    <!--        <p class="mt-1 text-xs text-red-500">-->
    <!--          {{ getErrorMessage("firstName") }}-->
    <!--        </p>-->
    <!--      }-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <label-->
    <!--        class="block text-gray-700 text-sm font-secondary mb-2"-->
    <!--        for="lastName"-->
    <!--      >-->
    <!--        Last Name <app-lock-icon></app-lock-icon>-->
    <!--      </label>-->
    <!--      <input-->
    <!--        [ngClass]="{-->
    <!--          'border-red-500':-->
    <!--            userForm.get('lastName')?.invalid &&-->
    <!--            userForm.get('lastName')?.touched-->
    <!--        }"-->
    <!--        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"-->
    <!--        formControlName="lastName"-->
    <!--        type="text"-->
    <!--      />-->
    <!--      @if (-->
    <!--        userForm.get("lastName")?.invalid && userForm.get("lastName")?.touched-->
    <!--      ) {-->
    <!--        <p class="mt-1 text-xs text-red-500">-->
    <!--          {{ getErrorMessage("lastName") }}-->
    <!--        </p>-->
    <!--      }-->
    <!--    </div>-->
  </div>
  <div class="border border-amber-900 border-2 rounded p-2 m-2 bg-yellow-100">
    <div class="mb-8">
      <h5 class="font-semibold">Approve Corporate Dashboard</h5>
    </div>
    <div class="mb-6">
      <label
        class="block text-gray-700 text-sm font-secondary mb-2"
        for="customerType"
      >
        Customer Type

        <p class="text-xs text-gray-muted">
          <i class="fas fa-exclamation"></i>
          Customer Type must match the Brevo role.
          <i class="fas fa-exclamation"></i>
        </p>
      </label>
      <select
        [ngClass]="{
          'border-red-500':
            userForm.get('customerType')?.invalid &&
            userForm.get('customerType')?.touched
        }"
        class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
        formControlName="customerType"
      >
        <option value="">Select Customer Type</option>
        <option value="Corporate">Corporate</option>
        <option value="Other">Other</option>
        <option value="Family Office">Family Office</option>
        <option value="Asset Manager / Portfolio Manager">
          Asset Manager / Portfolio Manager
        </option>
        <option value="Independent Financial Adviser">
          Independent Financial Adviser
        </option>
        <option value="Financial press">Financial press</option>
      </select>
      @if (
        userForm.get("customerType")?.invalid &&
        userForm.get("customerType")?.touched
      ) {
        <p class="mt-1 text-xs text-red-500">
          {{ getErrorMessage("customerType") }}
        </p>
      }
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
      <div>
        <label
          class="block text-gray-700 text-sm font-secondary mb-2"
          for="companyTicker"
        >
          Company Ticker
        </label>
        <input
          [ngClass]="{
            'border-red-500':
              userForm.get('companyTicker')?.invalid &&
              userForm.get('companyTicker')?.touched
          }"
          class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          formControlName="companyTicker"
          type="text"
        />
        @if (
          userForm.get("companyTicker")?.invalid &&
          userForm.get("companyTicker")?.touched
        ) {
          <p class="mt-1 text-xs text-red-500">
            {{ getErrorMessage("companyTicker") }}
          </p>
        }
      </div>
      <div>
        <label
          class="block text-gray-700 text-sm font-secondary mb-2"
          for="companyName"
        >
          Company Name
        </label>
        <input
          class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          formControlName="companyName"
          type="text"
        />
      </div>
      <div class="col-span-1 md:col-span-2">
        <p class="w-full text-gray-muted text-xs">
          Company Name and Company Ticker must be a correct match and both must
          match the event info.
        </p>
      </div>
    </div>

    <div class="mb-8">
      <label class="flex items-center">
        <input
          class="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"
          formControlName="isCorporateUser"
          type="checkbox"
        />
        <span class="ml-2 text-sm font-secondary text-gray-700"
          >Corporate Dashboard is approved</span
        >
      </label>
    </div>
  </div>

  <div class="flex justify-end">
    <app-admin-form-button
      (click)="saveForm()"
      [class]="'admin-form-button'"
      [disabled]="!userForm.valid"
    >
    </app-admin-form-button>
  </div>
  <app-admin-form-request-message></app-admin-form-request-message>
</form>
