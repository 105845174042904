import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { IResearchPodcastPopulated } from "~global-interfaces/IResearchPodcast";

import { alsterAPI_URL } from "~api/api-urls";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class PodcastAdminService extends PaginationService<IResearchPodcastPopulated> {
  currentResearchPodcast: WritableSignal<IResearchPodcastPopulated | null> =
    signal(null);
  isLoading: WritableSignal<boolean> = signal(false);
  loadingIndicatorService = inject(LoadingIndicatorService);
  constructor(http: HttpClient) {
    super(http);
  }

  fetchResearchPodcastList(paginationParams: IQueryParams) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL + "research-podcast/",
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }

  getPodcastList() {
    return this.items;
  }
}
