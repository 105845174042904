import { JsonPipe } from "@angular/common";
import {
  Component,
  effect,
  inject,
  Input,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";

import { ExcelService } from "~features/company/excel.service";
import { TileComponent } from "~features/dynamic-tiles/tile/tile.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

interface ITileWrapperConfig {
  height: number;
  width: number;
  tiles: [
    {
      headline: string;
      type: string;
      content: string[] | string[][];
      height: number;
      width: number;
      mobile: boolean;
    }
  ];
}

export interface IDynamicTile {
  headline: string;
  type: string;
  content: any; //string[] | string[][];
  height: number;
  width: number;
  mobile: boolean;
}

@Component({
  selector: "app-tile-wrapper-grid",
  templateUrl: "./tile-wrapper-grid.component.html",
  styleUrls: ["./tile-wrapper-grid.component.scss"],
  standalone: true,
  imports: [TileComponent, JsonPipe],
})
export class TileWrapperGridComponent {
  @Input() companyData!: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  @Input() tileWrapperConfig: Signal<ITileWrapperConfig>;
  @Input() decimals = 0;
  tiles: WritableSignal<any> = signal([]);

  lang = "eng";

  excelService = inject(ExcelService);

  excelMapping = this.excelService.getExcelMapping();

  constructor() {
    effect(() => {
      this.prepareTiles();
    }, enableSignalWritesInEffectContext);
    // WARNING: this is a workaround for an actual sideeffect.
    // TODO: check what the side effect is and manage it. then remove this option again
  }

  prepareTiles() {
    const companyNumbers = this.companyData();
    const mapping = this.excelMapping();
    const config = this.tileWrapperConfig();
    const tiles: IDynamicTile[][] = [];
    // console.log("PREPARINT TILES IN TILE_WRAPPER");
    // console.log("companyNumbers", companyNumbers);
    // console.log("mapping", mapping);
    // console.log("config", config);

    if (
      config &&
      companyNumbers &&
      mapping &&
      Object.keys(companyNumbers).length > 0 &&
      Object.keys(mapping).length > 0
    ) {
      while (tiles.length < config.height) {
        tiles.push([]);
      }
      // console.log("All PREREQUISITES MET IN TILE WRAPPER");
      let rowIndex = 0;
      let currentWidth = 0;
      let link;
      for (const tile of config.tiles) {
        if (tile.mobile || window.innerWidth > 768) {
          if (currentWidth + tile.width > config.width) {
            rowIndex++;
            currentWidth = 0;
          }

          if (rowIndex < tiles.length) {
            tiles[rowIndex].push(tile);
            currentWidth += tile.width;
          }

          if (
            link &&
            link.row === rowIndex &&
            link.col === tiles[rowIndex].length - 1
          ) {
            tile.content = [link.tile.content[0]];
            if (mapping[link.tile.content[0]]) {
              tile.headline = mapping[link.tile.content[0]]["eng"];
            }
          }

          if (tile["link"]) {
            switch (tile["link"]) {
              case "right": {
                link = {
                  row: rowIndex,
                  col: tiles[rowIndex].length,
                  tile: tile,
                };
                tile["link"] = {
                  row: rowIndex,
                  col: tiles[rowIndex].length,
                };
                break;
              }
              case "below": {
                link = { row: rowIndex + 1, col: currentWidth - 1, tile: tile };
                tile["link"] = { row: rowIndex + 1, col: currentWidth - 1 };
                break;
              }
            }
          }
        }
      }
    }
    this.tiles.set(tiles);
  }

  onTileEvent($event, rowIndex, colIndex) {
    //  console.log("TILE WRAPPER EVENT", $event, rowIndex, colIndex);
    switch ($event.type) {
      case "key-change": {
        //   console.log("BEFORE");
        //   console.log(this.tiles());
        const link = this.tiles()[rowIndex][colIndex]["link"];
        if (link) {
          this.tiles.update((oldTiles) => {
            oldTiles[link.row][link.col]["content"] = $event.change.content;
            oldTiles[link.row][link.col]["headline"] = $event.change.title;
            oldTiles[link.row][link.col] = JSON.parse(
              JSON.stringify(this.tiles()[link.row][link.col])
            );
            //  console.log("AFTER");
            //   console.log({
            //     ...oldTiles,
            //   });
            return [...oldTiles];
          });
        }
        break;
      }
    }
  }
}
