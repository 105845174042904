import { Clipboard } from "@angular/cdk/clipboard";
import { AsyncPipe, DecimalPipe, NgClass } from "@angular/common";
import {
  Component,
  computed,
  ElementRef,
  inject,
  signal,
  ViewChild,
} from "@angular/core";
import { ReactiveFormsModule, UntypedFormControl } from "@angular/forms";

import { FileService } from "~features/file-manager/file.service";
import { ClipboardService } from "~shared/services/clipboard.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, AsyncPipe, DecimalPipe, NgClass],
})
export class FileUploadComponent {
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;
  isLoading = signal(false);
  selectedFiles = signal<
    {
      actualFile: any;
      name: string;
      url: string;
      type: string;
      size: number;
      hideFromGoogle: boolean;
      control: UntypedFormControl;
      uploaded: boolean;
    }[]
  >([]);
  uploadMessage = signal("");
  uploaded = signal(false);
  hasSelectedFiles = computed(() => this.selectedFiles().length > 0);
  clipboardService = inject(ClipboardService);

  constructor(private fileService: FileService, private clipboard: Clipboard) {}

  onFileSelected(event: any) {
    const files = event.target.files;
    const fileArray = Array.from(files).map((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise<{
        actualFile: any;
        name: string;
        url: string;
        type: string;
        size: number;
        hideFromGoogle: boolean;
        control: UntypedFormControl;
        uploaded: boolean;
      }>((resolve) => {
        reader.onload = () => {
          resolve({
            actualFile: file,
            name: file.name,
            url: reader.result as string,
            type: file.type,
            size: file.size,
            hideFromGoogle: false,
            control: new UntypedFormControl({
              value: file.name,
              disabled: false,
            }),
            uploaded: false,
          });
        };
      });
    });

    Promise.all(fileArray).then((files) => {
      this.selectedFiles.set([...this.selectedFiles(), ...files]);
      console.log("Files selected:", this.selectedFiles());
    });
  }

  onFileNameChange(index: number) {
    const files = this.selectedFiles();
    const updatedFiles = files.map((file, i) => {
      if (i === index) {
        return { ...file, name: file.control.value };
      }
      return file;
    });
    this.selectedFiles.set(updatedFiles);
  }

  onFileDeselect(index: number) {
    const files = this.selectedFiles();
    const updatedFiles = files.filter((_, i) => i !== index);
    this.selectedFiles.set(updatedFiles);
  }

  onHideFromGoogleChange(index: number) {
    const files = this.selectedFiles();
    const updatedFiles = files.map((file, i) => {
      if (i === index) {
        return { ...file, hideFromGoogle: !file.hideFromGoogle };
      }
      return file;
    });
    this.selectedFiles.set(updatedFiles);
  }

  uploadFiles() {
    const files = this.selectedFiles();
    const filesToUpload = files.filter((file) => !file.uploaded);

    if (filesToUpload.length === 0) {
      this.uploadMessage.set("No files selected for upload.");
      console.log("No files selected for upload.");
      return;
    }
    this.isLoading.set(true);
    const formData = new FormData();
    filesToUpload.forEach((file) => {
      formData.append("files", file.actualFile, file.name);
    });

    console.log("Uploading files:", filesToUpload);

    this.fileService.uploadFiles(formData).subscribe(
      (response) => {
        const uploadedFiles = files.map((file) => {
          const uploadedFile = response.files.find((uploadedFile: string) =>
            uploadedFile.includes(`${file.name.split(".")[0]}__`)
          );

          return {
            ...file,
            uploaded: true,
            url: uploadedFile || file.url, // Preserve existing URL if not uploaded
            control: new UntypedFormControl({
              value: file.name,
              disabled: true,
            }),
          };
        });
        this.selectedFiles.set(uploadedFiles);
        this.uploadMessage.set("Files uploaded successfully!");
        this.uploaded.set(true);
        this.isLoading.set(false);
        console.log("Files uploaded successfully:", uploadedFiles);
      },
      (error) => {
        console.error("Error uploading files:", error);
        this.uploadMessage.set("File upload failed. Please try again.");
        this.isLoading.set(false);
      }
    );
  }

  uploadMoreFiles() {
    this.fileInput.nativeElement.value = "";
  }

  copyTextToClipboard(textToCopy: string) {
    this.clipboardService.copyToClipboard(textToCopy);
  }
}
