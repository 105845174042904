import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { JsonPipe } from "@angular/common";
import { Component, Inject, signal } from "@angular/core";

import { TemplateChartComponent } from "~features/dynamic-tiles/tileparts/template-chart/template-chart.component";

@Component({
  selector: "app-shareholder-dialog",
  standalone: true,
  imports: [JsonPipe, TemplateChartComponent],
  templateUrl: "./shareholder-dialog.component.html",
  styleUrl: "./shareholder-dialog.component.scss",
})
export class ShareholderDialogComponent {
  config = signal({
    headline: "Major Shareholders",
    type: "template-chart",
    content: ["major shareholders"],
    height: 2,
    width: 1,
    mobile: false,
  });

  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any
  ) {}
}
