import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ITransaction } from "~global-interfaces/ITransaction";

import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminTransactionEditorFormComponent } from "~features/transactions/admin/admin-transaction-editor-form/admin-transaction-editor-form.component";
import { TransactionService } from "~features/transactions/transaction.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_TRANSACTION } from "~shared/initial-values";

@Component({
  selector: "app-admin-transaction-creator",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminSidePanelComponent,
    AdminTransactionEditorFormComponent,
    FileUploadComponent,
  ],
  templateUrl: "./admin-transaction-creator.component.html",
  styleUrl: "./admin-transaction-creator.component.scss",
})
export class AdminTransactionCreatorComponent {
  transaction: WritableSignal<ITransaction | null> =
    signal(INITIAL_TRANSACTION);
  private transactionService = inject(TransactionService);

  onFormSubmit(newData: ITransaction) {
    //console.log(newData);
    this.transactionService.createTransaction(newData);
  }
}
