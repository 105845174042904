<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-hidden">
    <div class="h-screen overflow-y-auto">
      <div class="p-8">
        <app-breadcrump class="mb-6"></app-breadcrump>

        <div class="flex gap-8">
          <!-- Main content area with steps -->
          <div class="w-3/4 space-y-8">
            <h2 class="text-md font-bold mb-6">Guided Company Creation</h2>

            @for (step of steps; track step.id; let i = $index) {
              <div class="bg-white shadow-md rounded-lg p-6" [id]="'step-' + i">
                <h3 class="text-md font-semibold mb-4">
                  Step {{ i + 1 }}: {{ step.name }}
                </h3>
                @switch (step.id) {
                  @case ("companySettings") {
                    <app-admin-company-settings-editor-form
                      (formValidity)="
                        updateFormValidity('companySettings', $event)
                      "
                      (formValueChange)="
                        updateFormValues('companySettings', $event)
                      "
                      [company]="companySettingsInitialData"
                      [multiMode]="true"
                    ></app-admin-company-settings-editor-form>
                  }
                  @case ("exchangeMapping") {
                    <!-- no currentTicker input here because this form works differnt then the others and this is ooptional -->
                    <cdk-accordion>
                      <cdk-accordion-item
                        #accordionItem="cdkAccordionItem"
                        role="button"
                        tabindex="0"
                        [attr.id]="'accordion-header-1'"
                        [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-1'"
                      >
                        <div (click)="accordionItem.toggle()">
                          <button class="btn">
                            show exchange mapping form
                          </button>
                        </div>
                        <div
                          role="region"
                          [style.display]="accordionItem.expanded ? '' : 'none'"
                          [attr.id]="'accordion-body-1'"
                          [attr.aria-labelledby]="'accordion-header-1'"
                        >
                          <app-company-exchange-mapping-editor-form
                            (formValidity)="
                              updateFormValidity('exchangeMapping', $event)
                            "
                            (formValueChange)="
                              updateFormValues('exchangeMapping', $event)
                            "
                            [currentCompanyTicker]="currentCompanyTicker()"
                            [exchangeMappingData]="exchangeMapping()"
                            [multiMode]="true"
                          ></app-company-exchange-mapping-editor-form>
                        </div>
                      </cdk-accordion-item>
                    </cdk-accordion>
                  }
                  @case ("leewayConnection") {
                    <app-company-leeway-connection-form
                      (formValidity)="
                        updateFormValidity('leewayConnection', $event)
                      "
                      (formValueChange)="
                        updateFormValues('leewayConnection', $event)
                      "
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [multiModeCompanyName]="currentCompanyName"
                      [multiMode]="true"
                    >
                    </app-company-leeway-connection-form>
                  }
                  @case ("investmentCase") {
                    <app-company-investmentcase-form
                      (formValidity)="
                        updateFormValidity('investmentCase', $event)
                      "
                      (formValueChange)="
                        updateFormValues('investmentCase', $event)
                      "
                      [investmentCase]="investmentCaseInitialData"
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [multiMode]="true"
                    ></app-company-investmentcase-form>
                  }
                  @case ("swot") {
                    <app-admin-company-swot-editor-form
                      (formValidity)="updateFormValidity('swot', $event)"
                      (formValueChange)="updateFormValues('swot', $event)"
                      [swot]="swotInitialData"
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [multiMode]="true"
                    >
                    </app-admin-company-swot-editor-form>
                  }
                  @case ("valuation") {
                    <!-- no currentTicker input here because this is optional -->
                    <cdk-accordion>
                      <cdk-accordion-item
                        #accordionItem="cdkAccordionItem"
                        role="button"
                        tabindex="0"
                        [attr.id]="'accordion-header-2'"
                        [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-2'"
                      >
                        <div (click)="accordionItem.toggle()">
                          <button class="btn">show valuation form</button>
                        </div>
                        <div
                          role="region"
                          [style.display]="accordionItem.expanded ? '' : 'none'"
                          [attr.id]="'accordion-body-2'"
                          [attr.aria-labelledby]="'accordion-header-2'"
                        >
                          <app-company-valuation-editor-form
                            (formValidity)="
                              updateFormValidity('valuation', $event)
                            "
                            (formValueChange)="
                              updateFormValues('valuation', $event)
                            "
                            [valuation]="valuationInitialData"
                            [multiMode]="true"
                          ></app-company-valuation-editor-form>
                        </div>
                      </cdk-accordion-item>
                    </cdk-accordion>
                  }
                  @case ("research") {
                    <app-admin-company-research-editor-form
                      (formValidity)="updateFormValidity('research', $event)"
                      (formValueChange)="updateFormValues('research', $event)"
                      [research]="researchInitialData"
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [multiMode]="true"
                    ></app-admin-company-research-editor-form>
                  }
                  @case ("saveresearch") {
                    <div
                      class="w-full border-2 border-red-600 p-4 flex flex-col items-center bg-red-300 rounded-lg"
                    >
                      <button class="btn mb-4" (click)="submitResearch()">
                        save research from step 7 before next step
                      </button>
                      <p class="text-xs text-muted-gray text-center max-w-xs">
                        You need to save the research now, to be able to set it
                        as your initiation note.
                      </p>
                    </div>
                    <app-admin-form-request-message></app-admin-form-request-message>
                  }
                  @case ("initiationNote") {
                    <app-company-initiation-note-form
                      (formValidity)="
                        updateFormValidity('initiationNote', $event)
                      "
                      (formValueChange)="
                        updateFormValues('initiationNote', $event)
                      "
                      [initiationNote]="initiationNoteInitialData"
                      [multiModeCompanyTicker]="currentCompanyTicker"
                      [multiMode]="true"
                    ></app-company-initiation-note-form>
                  }

                  @default {
                    <p class="text-gray-600">
                      Form for {{ step.name.toLowerCase() }} goes here.
                    </p>
                  }
                }
                @if (step.optional) {
                  <p class="text-sm text-gray-500 mt-2">
                    This step is optional.
                  </p>
                }
              </div>
            }

            <!-- Submit Button -->
            <div class="mt-8">
              <button
                (click)="submitAll()"
                [disabled]="!allFormsValid()"
                class="w-full px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed transition duration-300 ease-in-out"
              >
                Submit All Steps
              </button>
            </div>
            <app-admin-form-request-message></app-admin-form-request-message>
          </div>
          <!-- Sticky sidebar for checklist -->
          <div class="w-1/4">
            <div
              class="sticky top-8 bg-white shadow-md rounded-lg p-4 max-h-12rem overflow-y-auto"
            >
              <h3 class="text-md font-semibold mb-4">Creation Progress</h3>
              <ul class="space-y-2">
                @for (step of steps; track step.id; let i = $index) {
                  <li class="flex items-center transition-all duration-300">
                    <span
                      class="w-6 h-6 flex items-center justify-center rounded-full mr-2 text-xs"
                      [class.bg-green-500]="formValidityStatus()[step.id]"
                      [class.bg-gray-300]="!formValidityStatus()[step.id]"
                      [class.text-white]="
                        currentStep() === i || formValidityStatus()[step.id]
                      "
                    >
                      {{ i + 1 }}
                    </span>
                    <span class="text-sm"
                      >{{ step.name }} {{ step.optional ? "(optional)" : "" }}:
                      {{
                        formFinishStatus()[step.id]
                          ? "✅"
                          : step.optional
                            ? "⚪"
                            : "❌"
                      }}</span
                    >
                  </li>
                }
              </ul>
              <div class="my-5">
                <app-file-upload></app-file-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
