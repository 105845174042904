import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { IResearch } from "~global-interfaces/IResearch";

@Injectable({
  providedIn: "root",
})
export class StructuredDataService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  addStructuredData(
    companyName: string,
    researchArticle: IResearch,
    companyImage: string
  ): void {
    const mwbLogoUrl =
      "https://www.mwb-research.com/wp-content/uploads/mwb-research-logo-removebg-preview.png";

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Article",
      headline: companyName + ": " + researchArticle.title,
      author: {
        "@type": "Organization",
        name: "mwb research AG",
        url: "https://www.research-hub.de/",
      },
      datePublished: new Date(researchArticle.research_date),
      image: companyImage,
      publisher: {
        "@type": "Organization",
        name: "mwb research AG",
        logo: {
          "@type": "ImageObject",
          url: mwbLogoUrl,
        },
      },
      description: this.generateDescription(
        researchArticle.content_DE.content,
        researchArticle.title
      ),
    };

    const script = this.renderer.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(structuredData);
    this.renderer.appendChild(document.head, script);
  }

  removeStructuredData(): void {
    const scripts = document.querySelectorAll(
      'script[type="application/ld+json"]'
    );
    scripts.forEach((script) => script.remove());
  }

  private generateDescription(htmlContent: string, title: string): string {
    const div = this.renderer.createElement("div");
    div.innerHTML = htmlContent;
    let text = div.textContent || div.innerText || "";

    // Remove the title from the beginning if it exists
    if (text.startsWith(title)) {
      text = text.substring(title.length).trim();
    }

    return text.length > 320 ? text.substring(0, 320) + "..." : text;
  }
}
