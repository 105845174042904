import { Component, inject, signal, WritableSignal } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IEvent } from "~global-interfaces/IEvent";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { CompanylistService } from "~features/company/companylist.service";
import { EventService } from "~features/events/event.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-event-list",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    RouterLink,
    PaginationComponent,
    AdminSidePanelComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: "./admin-event-list.component.html",
  styleUrls: ["./admin-event-list.component.scss"],
})
export class AdminEventListComponent {
  eventService = inject(EventService);
  eventsList: WritableSignal<IEvent[]> = signal<IEvent[]>([]);
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);
  yearSearchTerm = signal(null);
  quarterSearchTerm = signal(null);
  companyNameSearchTerm = signal(null);
  titleSearchTerm = signal(null);
  quarterOptions = [
    { value: 1, label: "Q1" },
    { value: 2, label: "Q2" },
    { value: 3, label: "Q3" },
    { value: 4, label: "Q4" },
  ];
  yearOptions = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];
  companyListService = inject(CompanylistService);
  companiesFromEvents = this.eventService.getCompaniesFromEvents();

  constructor() {
    this.fetchEvents();
  }

  fetchEvents() {
    const paginationParams: IQueryParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),

      searchterm: this.companyNameSearchTerm(),
    };
    this.eventService.fetchEventsList(paginationParams);
    this.eventsList = this.eventService.getEventsList();
    this.totalItems = this.eventService.getTotalCount();
  }

  fetchMoreEvents($event: number) {
    this.currentPage.set($event);
    this.fetchEvents();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalItems());
  }
}
