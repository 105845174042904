<div class="container py-0 my-0 customFont">
  <div class="row-old py-0 my-0">
    <div class="mx-auto mt-2 overflow-x-auto">
      <app-tabs
        [inactiveTabClass]="'bg-transparent'"
        [tabClasses]="
          'font-semibold rounded-t-lg hover:bg-gray-100 px-4 py-2 text-gray-700  focus:outline-none cursor-pointer'
        "
        [tabRowClasses]="'bg-transparent'"
        tabGroupId="financials"
      >
        @for (tab of tableLayout(); track tab) {
          <app-tab
            title="{{ tab.name[tab.settings.language] }}"
            tabGroupId="financials"
          >
            <div>
              <table class="table-auto w-full">
                <tbody>
                  @for (row of tab.data; track row) {
                    <tr
                      class="hover:bg-[#00000013] font-normal text-base leading-[1.4]"
                    >
                      <th
                        class="text-left py-1 border-t border-[#dee2e6]"
                        [attr.colspan]="row.colspan"
                        [ngClass]="getRowClasses(row)"
                      >
                        {{ row.key }}
                      </th>
                      @for (datapoint of row.data; track datapoint) {
                        <td
                          class="text-right py-1 border-t border-[#dee2e6]"
                          [ngClass]="getRowClasses(row)"
                        >
                          {{ datapoint }}
                        </td>
                      }
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            @if (tab.settings.commonSize) {
              <div class="mb-4">
                <div
                  [id]="'#' + headline()"
                  class="fingerFix border-t border-light-blue py-2 w-full cursor-pointer"
                  (click)="expanded = !expanded"
                >
                  @if (expanded) {
                    <i class="fa fa-chevron-up px-2"></i>
                  }
                  @if (!expanded) {
                    <i class="fa fa-chevron-down px-2"></i>
                  }
                  <b>Common Size</b>
                </div>
                <table
                  class="table-auto w-full"
                  [ngClass]="{ hidden: !expanded }"
                >
                  <tbody>
                    @for (row of tab.data; track row) {
                      <tr
                        class="hover:bg-[#00000013] font-normal text-base leading-[1.4]"
                      >
                        <th
                          class="text-left py-1 border-t border-[#dee2e6]"
                          [ngClass]="getRowClasses(row)"
                        >
                          {{ row.commonSizeKey }}
                        </th>
                        @for (
                          datapoint of row.commonSizeData;
                          track datapoint
                        ) {
                          <td
                            class="text-right py-1 border-t border-[#dee2e6]"
                            [ngClass]="getRowClasses(row)"
                          >
                            {{ datapoint }}
                          </td>
                        }
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            }
          </app-tab>
        }
      </app-tabs>

      <p class="text-left">Source: {{ dataSource.eng }}</p>
    </div>
  </div>
</div>
