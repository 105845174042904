import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";

import { ChartComponent } from "~features/charts/chart/chart.component";
import { ExcelService } from "~features/company/excel.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

interface ChartData {
  x: string[];
  y: number[];
  text: string[];
  marker: { color: string[] };
}

@Component({
  selector: "app-vertical-bars",
  templateUrl: "./vertical-bars.component.html",
  styleUrls: ["./vertical-bars.component.scss"],
  standalone: true,
  imports: [ChartComponent],
})
export class VerticalBarsComponent {
  @ViewChild("chartWrapperVerticalBars") chartWrapper: ElementRef;

  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));

  @Input() config: Signal<any>;
  @Input() decimals = 0;
  @Input() lang = "eng";

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  index: WritableSignal<number> = signal(0);
  chart: WritableSignal<any> = signal(null);
  chartData: WritableSignal<ChartData | null> = signal(null);

  tableUtils = inject(Tables);
  excelService = inject(ExcelService);
  excelMapping = this.excelService.getExcelMapping();

  labelsize = 20;

  chartTemplate = {
    data: [
      {
        x: [],
        y: [],
        text: [],
        textangle: "0",
        textposition: "auto",
        insidetextanchor: "start",
        insidetextfont: {
          size: this.labelsize,
        },
        textfont: {
          size: this.labelsize,
        },
        hoverinfo: "none",
        marker: {
          color: [],
        },
        type: "bar",
      },
    ],
    layout: {
      annotations: [],
      showlegend: false,
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      xaxis: {
        tickangle: 0,
        showgrid: false,
        zeroline: false,
        tickfont: {
          size: 16,
          color: "#e2e8f0",
        },
        fixedrange: true,
      },
      yaxis: {
        showgrid: false,
        visible: false,
        fixedrange: true,
      },
      margin: {
        autoexpand: true,
        t: 0,
        b: 30,
        l: 0,
        r: 0,
      },
    },
    config: {
      displayModeBar: false,
    },
    useResizeHandler: true,
    style: { position: "absolute", height: "100%", width: "100%" },
  };

  constructor() {
    effect(() => {
      // console.log("PREPARING VERTICAL BARS");
      this.prepareData(this.excelMapping(), this.companyData(), this.config());
    }, enableSignalWritesInEffectContext);
  }

  prepareData(mapping, companyNumbers, config) {
    if (
      config &&
      companyNumbers &&
      companyNumbers.base &&
      companyNumbers.financial_figures
    ) {
      const chartData: ChartData = {
        x: [],
        y: [],
        text: [],
        marker: { color: [] },
      };

      for (const key of config.content) {
        // console.log("Looking for ", key);
        if (mapping[key]) {
          //  console.log("FOUND KEY", mapping[key]);
          const mappingEntry = mapping[key];
          chartData.x.push(mappingEntry[this.lang]);

          const val = this.getValue(companyNumbers, mappingEntry);
          chartData.text.push(val);
          chartData.y.push(this.parseValue(val));
          chartData.marker.color.push("#e2e8f0");
        }
      }
      // console.log("------------ DATA FOR BAR CHARTS CREATE");
      // console.log(chartData);
      this.chartData.set(chartData);
      this.updateChart();
    } else {
      // console.log("NO DATA");
    }
  }

  getValue(companyNumbers, mappingEntry) {
    if (mappingEntry.type === "VALUE") {
      return this.tableUtils.getValue(
        companyNumbers,
        mappingEntry,
        this.lang,
        this.decimals
      )[0];
    } else if (mappingEntry.type === "TIMESERIES") {
      return this.tableUtils.getOneFromTimeseries(
        companyNumbers,
        mappingEntry,
        1 + this.index(),
        "value",
        this.lang,
        this.decimals
      )[0];
    }
    return "n/a";
  }

  parseValue(val: string): number {
    if (val.includes("-") || val === "n/a") return 1;
    const matches = val.match(/\d+(.\d+)?/g);
    return matches ? parseFloat(matches[0]) : 1;
  }

  updateChart() {
    if (this.chartData()) {
      // console.log("Updating bar Chart");
      const updatedChart = JSON.parse(JSON.stringify(this.chartTemplate));
      updatedChart.data[0] = { ...updatedChart.data[0], ...this.chartData() };
      updatedChart.style.height = this.chartWrapper
        ? this.chartWrapper.nativeElement.offsetHeight + "px"
        : "100%";
      this.chart.set(updatedChart);
      // console.log("----- BAR CHART SHOULD BE SET NOW");
      //console.log(this.chart());
    }
  }

  navigate(step: number) {
    const minIndex = -2;
    const maxIndex = 2;
    const newIndex =
      ((this.index() + step - minIndex + (maxIndex - minIndex + 1)) %
        (maxIndex - minIndex + 1)) +
      minIndex;
    this.index.set(newIndex);
    this.prepareData(this.excelMapping(), this.companyData(), this.config());
    this.tileEvent.emit({
      type: "year-change",
      change: { year: this.baseyear() + this.index() },
    });
  }
}
