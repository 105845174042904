<div class="bg-blue-100">
  <div class="py-4 flex">
    <div class="hidden md:block bg-bluearea subscribe-banner pr-0 w-1/6"></div>
    <div class="bg-bluearea subscribe-banner px-0 w-full md:w-2/3">
      <div class="p-5">
        <h2 class="py-3 hidden md:block text-left">
          Join <span class="text-blue-500">1,700+</span> professional investors
        </h2>
        <h4 class="py-3 md:hidden text-left">
          Join <span class="text-blue-500">1,700+</span> professional investors
        </h4>
        <p class="text-md text-left">
          Subscribe now. Our research on German and selected European stocks is
          MIFID&nbsp;II compliant and delivered to your inbox free of charge.
        </p>
        <div class="flex justify-center w-full py-5 mt-5">
          <a [routerLink]="'/signup'">
            <button class="btn mx-auto text-md">subscribe now</button>
          </a>
        </div>
        <div class="flex justify-center w-full py-5mt-3">
          <span class="text-md text-gray-500 pb-5">Cancel anytime.</span>
        </div>
      </div>
    </div>
    <div class="hidden md:block bg-bluearea subscribe-banner pl-0 w-1/6"></div>
  </div>
</div>
