<div
  class="min-h-screen bg-gray-100 flex items-center justify-center px-4 sm:px-6 lg:px-8"
>
  <div class="max-w-md w-full space-y-8 bg-white p-10 rounded-xl shadow-lg">
    <h2 class="text-lg font-extrabold text-gray-900 text-center">
      Set New Password
    </h2>
    <form
      (ngSubmit)="onNewPasswordSubmit()"
      [formGroup]="newPasswordForm()"
      class="mt-8 space-y-6"
    >
      <div>
        <label class="sr-only" for="newPassword">New Password</label>
        <input
          [class.border-red-500]="
            submitted() && newPasswordForm().get('newPassword')?.errors
          "
          class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          formControlName="newPassword"
          id="newPassword"
          placeholder="New Password"
          required
          type="password"
        />
      </div>
      @if (submitted() && newPasswordForm().get("newPassword")?.errors) {
        <div class="text-red-500 text-sm mt-1">
          @if (newPasswordForm().get("newPassword")?.errors?.["required"]) {
            <p>New Password is required</p>
          }
          @if (newPasswordForm().get("newPassword")?.errors?.["minlength"]) {
            <p>Password must be at least 6 characters</p>
          }
        </div>
      }
      <div>
        <label class="sr-only" for="confirmNewPassword"
          >Confirm New Password</label
        >
        <input
          [class.border-red-500]="
            submitted() && newPasswordForm().get('confirmNewPassword')?.errors
          "
          class="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
          formControlName="confirmNewPassword"
          id="confirmNewPassword"
          placeholder="Confirm New Password"
          required
          type="password"
        />
      </div>
      @if (submitted() && newPasswordForm().get("confirmNewPassword")?.errors) {
        <div class="text-red-500 text-sm mt-1">
          @if (
            newPasswordForm().get("confirmNewPassword")?.errors?.["required"]
          ) {
            <p>Confirm New Password is required</p>
          }
          @if (
            newPasswordForm().get("confirmNewPassword")?.errors?.[
              "passwordMismatch"
            ]
          ) {
            <p>Passwords must match</p>
          }
        </div>
      }
      <div>
        <button
          [disabled]="loading()"
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
          type="submit"
        >
          @if (loading()) {
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Processing
          } @else {
            Set New Password
          }
        </button>
      </div>
    </form>
    @if (success()) {
      <div class="mt-3 text-sm text-center text-green-600">
        Your password has been successfully updated.
      </div>
    }
  </div>
</div>
