import { Component } from "@angular/core";

@Component({
  selector: "app-company-initiation-note",
  standalone: true,
  imports: [],
  templateUrl: "./company-initiation-note.component.html",
  styleUrl: "./company-initiation-note.component.scss",
})
export class CompanyInitiationNoteComponent {}
