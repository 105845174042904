<div class="min-w-[42rem]">
  <!-- TradingView Widget BEGIN -->
  <div #chartWidthMeasureElementMODAL id="chartWidthMeasureElementMODAL">
    <div class="tradingview-widget-container">
      <div id="tradingview_bac65"></div>
      <div class="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/symbols/{{ chartSymbol }}"
          rel="noopener"
          target="_blank"
          ><span class="blue-text">{{ chartSymbol }} Chart</span></a
        >
        by TradingView
      </div>
      <script
        src="https://s3.tradingview.com/tv.js"
        type="text/javascript"
      ></script>
      <script type="text/javascript"></script>
    </div>
  </div>
  <!-- TradingView Widget END -->
</div>
