import { inject, Injectable } from "@angular/core";
import posthog from "posthog-js";

import { AuthService } from "~features/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class PosthogService {
  /**
   *
   *
   * event subscription
   * user dashboard each tab
   * corporate dashboard each tab
   * corporate download presentation
   * corproate download CSV
   * video libreary read research link clicked
   * video library download presentation clicked
   *
   *
   */

  authService = inject(AuthService);

  constructor() {}

  public trackPageView(url) {
    const companyMatch = url.match(/\/companies\/(.+)/);

    if (companyMatch) {
      const company = decodeURIComponent(companyMatch[1]);
      posthog.capture("$pageview", {
        company: company,
        investor_mail: this.authService.getCurrentUserName()
          ? this.authService.getCurrentUserName()
          : undefined,
        investor_type: this.authService.getCurrentCustomerType()
          ? this.authService.getCurrentCustomerType()
          : undefined,
      });
    } else {
      posthog.capture("$pageview", {
        investor_mail: this.authService.getCurrentUserName()
          ? this.authService.getCurrentUserName()
          : undefined,
        investor_type: this.authService.getCurrentCustomerType()
          ? this.authService.getCurrentCustomerType()
          : undefined,
      });
    }
  }

  public trackPDFDownload() {
    posthog.capture("pdf_download_clicked");
  }

  public trackPresentationDownload() {
    posthog.capture("presentation_download_clicked");
  }

  public trackJumpNavigation(target) {
    posthog.capture("jump_navigation_clicked", { jumpmark: target });
  }

  public trackLinkCopied(link) {
    posthog.capture("link_copied", { link: link });
  }

  public trackButtonClick(buttonName) {
    posthog.capture("button_clicked", { buttonName: buttonName });
  }
  public trackVideoClicked(videoName) {
    posthog.capture("video_clicked", { videoName: videoName });
  }
  public trackPodcastPlayed(companyTicker, research_date) {
    posthog.capture("podcast_clicked", {
      companyTicker: companyTicker,
      research_date: research_date,
    });
  }
}
