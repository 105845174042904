import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CompanyInfoService } from "~features/company/company-info.service";

@Component({
  selector: "app-book-a-meeting",
  templateUrl: "./book-a-meeting.component.html",
  styleUrls: ["./book-a-meeting.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule],
})
export class BookAMeetingComponent implements OnInit {
  lastname: any;
  firstname: any;
  email: any;
  requesterscompany: any;
  phonenumber: any;
  requiredFieldMissingInput = false;
  requestPending = false;
  requestSent = false;
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any,
    private companyInfoService: CompanyInfoService
  ) {}

  ngOnInit(): void {}

  checkInput() {
    if (
      this.lastname === undefined ||
      this.firstname === undefined ||
      this.email === undefined ||
      this.requesterscompany === undefined
    ) {
      this.requiredFieldMissingInput = true;
    } else {
      this.requiredFieldMissingInput = false;
    }
  }

  sendNotification() {
    this.checkInput();
    if (!this.requiredFieldMissingInput) {
      this.sendPublishRequest();
    }
  }

  closeWindow() {
    this.dialogRef.close();
  }

  public sendPublishRequest() {
    this.requestPending = true;
    this.companyInfoService
      .sendBookAMeetingRequestForNewResearch(
        this.data.data.companySettings().name,
        this.lastname,
        this.firstname,
        this.email,
        this.requesterscompany,
        this.phonenumber
      )
      .then((res) => {
        this.requestPending = false;
        this.requestSent = true;
        setTimeout(() => {
          this.requestSent = false;
        }, 10000);
      });
  }
}
