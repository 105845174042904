import { Component, inject, signal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { IResearch } from "~global-interfaces/IResearch";

import { AdminCompanyResearchEditorFormComponent } from "~features/company/admin/company-research/admin-company-research-editor-form/admin-company-research-editor-form.component";
import { AdminCompanyResearchPdfComponent } from "~features/company/admin/company-research/admin-company-research-pdf/admin-company-research-pdf.component";
import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreateResearchProcessService } from "~features/guided-research-creation-flow/create-research-process.service";
import { ResearchService } from "~features/research/research.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_RESEARCH } from "~shared/initial-values";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Component({
  selector: "app-admin-company-research-creator",
  standalone: true,
  imports: [
    FileUploadComponent,
    AdminCompanyResearchEditorFormComponent,
    DisplayEditorContentComponent,
    EditEditorContentComponent,
    AdminCompanyResearchPdfComponent,
    BreadcrumpComponent,
    CreationProcessMenuComponent,
    AdminSidePanelComponent,
    RouterLink,
  ],
  templateUrl: "./admin-company-research-creator.component.html",
  styleUrl: "./admin-company-research-creator.component.scss",
})
export class AdminCompanyResearchCreatorComponent {
  companyDataService = inject(CompanydataService);
  researchService = inject(ResearchService);
  research = signal<IResearch>(INITIAL_RESEARCH);
  createResearchProcessService = inject(CreateResearchProcessService);
  adminRequestService = inject(AdminRequestService);
  excelService = inject(ExcelService);
  errorHandlingService = inject(ErrorHandlingService);

  constructor() {}

  onFormSubmit(formData: any) {
    //console.log(formData);
    this.researchService.createResearch(formData);
    this.createResearchProcessService.setStepCompleted("Upload Research");
    this.createResearchProcessService.setCurrentCompanyTicker(
      formData.companyTicker
    );
  }
}
