import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IExchangeMapping } from "~global-interfaces/IExchangeMapping";

import { alsterAPI_URL } from "~api/api-urls";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Injectable({
  providedIn: "root",
})
export class ExchangeMappingService {
  http = inject(HttpClient);
  exchangeMapping: WritableSignal<any> = signal(initialExchangeMapping);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  loadingIndicatorService = inject(LoadingIndicatorService);

  constructor() {
    // console.log("IM AM THE EXCHANGE MAPPING CONSTRUCTOR");
    this.fetchExchangeMapping();
  }

  getisLoading() {
    return this.isLoading;
  }

  /**
   * This is taken from legacy
   * @param companyTicker
   */
  public getMappedExchange(companyTicker) {
    const parsedCompanyTicker = companyTicker.replaceAll(":", "_");

    if (
      this.exchangeMapping() !== null &&
      this.exchangeMapping() !== undefined
    ) {
      let mappedTicker = this.exchangeMapping()[parsedCompanyTicker];

      if (mappedTicker === null || mappedTicker === undefined) {
        mappedTicker = companyTicker.split(":")[0] + "." + "XETRA";
      }

      return mappedTicker;
    } else {
      return companyTicker;
    }
  }

  fetchExchangeMapping() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IExchangeMapping>>(alsterAPI_URL + "exchangemappings")
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch exachange mapping"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (exchangeMapping) => {
          this.exchangeMapping.set(exchangeMapping.mapping);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch exchange mapping success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  updateExchangeMapping(formData: any) {
    // TODO: remove hardcoded document id. but this is quick and very dirty. there will be only one document anyways
    //TODO: there is a bug with this endpoint, it is not reachable from anywhere at the moment...
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<IExchangeMapping>>(
        alsterAPI_URL + "exchangemappings/62287c37c30a19368b8a1e2b",
        { mapping: formData }
      )
      .pipe(
        map((response) => response.data[0]),
        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "update exchange mapping"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (exchangeMapping) => {
          this.exchangeMapping.set(exchangeMapping.mapping);
          this.adminRequestService.setLastRequestMessage("7", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}

/**
 * This is MOCK data and does not need to updated
 */
const initialExchangeMapping = {
  R1B_GR: "R1B.MU",
  CDZ0_GR: "CDZ0.MU",
  "14D_GR": "14D.MU",
  "991_GR": "991.MU",
  XCX_GR: "XCX.DU",
  Z01_GR: "Z29.XETRA",
  NSP_GR: "NSP.MI",
  P7V_GR: "P7V.F",
  ZO1_GR: "ZO1.HM",
  TGYM_GR: "TGYM.MI",
  GSJ_GR: "GSJ.F",
  ABA_GR: "ABA.F",
  SDF_GR: "SDF.XETRA",
  "2GI_GR": "2GI.MU",
  THAG_SW: "THAG.SW",
  VUL_AU: "VUL.AU",
  M5S_GR: "M5S.F",
  TH51_GR: "TH51.MU",
  VUL_GR: "VUL.XETRA",
  CICN_SW: "CICN.SW",
  BAT_SS: "EBM.F",
  MMK_AV: "MMK.VI",
  PO1_GR: "PO1.XETRA",
  AR4_GR: "AR4.HM",
  SPC_GR: "SPC.DU",
  SEAP_SW: "WAG.DU",
  TRNR: "TRNR.US",
  Q11_GR: "Q11.DU",
  CHG_GR: "CHG.XETRA",
  Y00_GR: "Y00.F",
  H68_GR: "H68.F",
  "9MQ_GR": "9MQ.DU",
};
