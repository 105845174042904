import { HttpClient } from "@angular/common/http";
import {
  effect,
  inject,
  Injectable,
  signal,
  WritableSignal,
} from "@angular/core";
import { forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";

import { alsterAPI_URL, leewayAPI_URL } from "~api/api-urls";
import { CompanyRatingService } from "~features/company/company-rating.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { CompanylistService } from "~features/company/companylist.service";
import { ExchangeMappingService } from "~features/company/exchangeMapping.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";
import { formatValue } from "~utils/numberFormatting.util";

@Injectable({
  providedIn: "root",
})
export class ExcelService {
  defaultLanguage = "eng";
  public leewayResponse: WritableSignal<any | null> = signal(null);
  isLoading: WritableSignal<boolean> = signal(false);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiToken = "cqt1bii3tq5fuzo0c5id";
  private http = inject(HttpClient);
  private companyTickerService = inject(CompanyTickerService);
  private companyTicker = this.companyTickerService.getCompanyTicker();
  private companyDataService = inject(CompanydataService);
  companySettings = this.companyDataService.getCurrentCompanySettings();
  private companyRatingService = inject(CompanyRatingService);
  private exchangeMappingService = inject(ExchangeMappingService);
  private companyListService = inject(CompanylistService);
  private excelMapping: WritableSignal<any | null> = signal(null);
  private companyNumbers: WritableSignal<any | null> = signal(null);
  private companyLiveQuotes: WritableSignal<any | null> = signal(null);
  private companyHistoricalQUotes: WritableSignal<any | null> = signal(null);
  private liveQuotesCompanyGrid: WritableSignal<any[] | null> = signal(null);
  private tileDataCompanyGrid: WritableSignal<any[] | null> = signal(null);
  private liveQuotesHighlightCompanyGrid: WritableSignal<any[] | null> =
    signal(null);
  private tileDataHighlightCompanyGrid: WritableSignal<any[] | null> =
    signal(null);
  private companyList = this.companyListService.getCompanyList();
  private valuationTextNumbers: WritableSignal<any | null> = signal(null);

  constructor() {
    effect(() => {
      // console.log("COMPANY LIST IN EXCEL SERVICE", this.companyList());
      //console.log("COMPANY TICKER IN EXCEL SERVICE", this.companyTicker());
      if (this.companyList() && this.companyTicker()) {
        //  console.log("FETCHING DATA IN EXCELS ERVICE");
        this.fetchExcelMapping();
        // console.log(
        //   "COMPANY TICKER IN EXCEL SERVICE ",
        //   this.companyTicker(),
        //   "   <----",
        //   this.companyTicker,
        //   "   <<"
        // );
        this.fetchCompanyNumbers(this.companyTicker());
        this.fetchLiveQuotes(this.companyTicker());
        this.fetchHistoricalQuotes(this.companyTicker());
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (
        this.excelMapping() &&
        this.companyNumbers() &&
        this.companySettings()
      ) {
        this.valuationTextNumbers.set(null);
        this.valuationTextNumbers.set(
          this.parseValuesFromCompanyNumbersToValuationText()
        );
      }
    }, enableSignalWritesInEffectContext);
  }

  getisLoading() {
    return this.isLoading;
  }

  public getValuationTextNumbers() {
    return this.valuationTextNumbers;
  }

  public getCompanyNumbers() {
    return this.companyNumbers;
  }

  public getCompanyLiveQuotes() {
    return this.companyLiveQuotes;
  }

  public getCompanyHistoricalQuotes() {
    return this.companyHistoricalQUotes;
  }

  getExcelMapping() {
    return this.excelMapping;
  }

  getLiveQuotesCompanyGrid() {
    return this.liveQuotesCompanyGrid;
  }

  getTileDataCompanyGrid() {
    return this.tileDataCompanyGrid;
  }

  getLiveQuotesHighlightCompanyGrid() {
    return this.liveQuotesCompanyGrid;
  }

  getTileDataHighlightCompanyGrid() {
    return this.tileDataCompanyGrid;
  }

  /**
   *
   * Fetches Excel Mapping from Leeway API - this is the same Document for all Companies
   *
   */

  fetchLiveQuotes(companyTicker: string) {
    this.isLoading.set(true);
    const mappedTicker =
      this.exchangeMappingService.getMappedExchange(companyTicker);
    const url = `${leewayAPI_URL}public/live/${mappedTicker}?apitoken=${this.apiToken}`;
    this.http.get(url).subscribe((res) => {
      // console.log(res);
      this.companyLiveQuotes.set(res);
      this.isLoading.set(false);
    });
  }

  fetchHistoricalQuotes(companyTicker: string) {
    this.isLoading.set(true);
    const mappedTicker =
      this.exchangeMappingService.getMappedExchange(companyTicker);
    const url = `${leewayAPI_URL}public/historicalquotes/${mappedTicker}?apitoken=${this.apiToken}`;
    this.http.get(url).subscribe((res) => {
      // console.log(res);
      this.companyHistoricalQUotes.set(res);
      this.isLoading.set(false);
    });
  }

  /**
   *
   * This gets all data at once, it uses too much request tokens
   *
   *
   */
  fetchLiveQuotesAndTileData(companyTickers: string[], isHighlight = false) {
    const multiForTileData = companyTickers.join(",");
    const multiForLiveQuotes = companyTickers
      .map((companyTicker) =>
        this.exchangeMappingService.getMappedExchange(companyTicker)
      )
      .join(",");

    const liveQuoteRequest =
      this.fetchLiveQuotesCompanyGrid(multiForLiveQuotes);
    const tileDataRequest = this.fetchTileDataCompanyGrid(multiForTileData);

    // forkJoin([liveQuoteRequest, tileDataRequest]).subscribe(
    //   ([liveQuoteData, tileData]) => {
    //     if (isHighlight) {
    //       this.liveQuotesHighlightCompanyGrid.set(liveQuoteData as any[]);
    //       this.tileDataHighlightCompanyGrid.set(tileData as any[]);
    //     } else {
    //       this.liveQuotesCompanyGrid.set(liveQuoteData as any[]);
    //       this.tileDataCompanyGrid.set(tileData as any[]);
    //     }
    //   }
    // );
  }

  fetchUniqueLiveQuotesAndTileData(companyTicker: string) {
    // console.log("fetchUniqueLiveQuotesAndTileData", companyTicker);
    const mappedTicker =
      this.exchangeMappingService.getMappedExchange(companyTicker);
    if (mappedTicker === companyTicker) {
      mappedTicker.replace(":", ".").replace("GR", "XETRA");
    }
    const liveQuoteRequest =
      this.fetchUniqueLiveQuotesCompanyGrid(mappedTicker);
    const tileDataRequest = this.fetchUniqueTileDataCompanyGrid(companyTicker);
    return forkJoin([liveQuoteRequest, tileDataRequest]);
  }

  fetchExcelMapping() {
    const customerIdparam = "customerId=" + this.customerId;
    const apiTokenparam = "apitoken=" + this.apiToken;
    this.isLoading.set(true);
    const url =
      leewayAPI_URL +
      "addin/mapping/mapping?" +
      apiTokenparam +
      "&" +
      customerIdparam;
    this.http.get(url).subscribe((res) => {
      this.excelMapping.set(res);
      this.isLoading.set(false);
    });
  }

  /**
   *
   * Fetches Excel Numbers for Company from Leeway API
   *
   * Sets the Company Rating in the CompanyRatingService
   *
   */
  fetchCompanyNumbers(companyTicker) {
    this.isLoading.set(true);
    const url =
      leewayAPI_URL +
      "addin/companydata/getcombinedexceldata?apitoken=" +
      this.apiToken +
      "&companyId=" +
      companyTicker +
      "&customerId=" +
      this.customerId +
      "&published=true";

    this.http.get(url).subscribe((res: any) => {
      this.companyNumbers.set(res);
      this.isLoading.set(false);
      this.companyRatingService.setCurrentCompanyRating(
        res.general_information.cus_rating
      );
    });
  }

  /**
   *
   * Create Excel Base in Leeway API
   *
   */

  makeLeewayRequest(formData: any) {
    this.isLoading.set(true);
    const customerIdparam = "customerId=" + this.customerId;
    // console.log(formData);
    // console.log(customerIdparam);
    const companyId = formData.companyTicker;
    const name = formData.companyName;
    //  console.log(companyId, name);
    //   console.log(
    //     "makeLeewayRequest: not implemented yet. this did not call the API"
    //   );
    //createexceldocs

    this.http
      .post<IApiResponse<any>>(alsterAPI_URL + "companies/createexceldocs", {
        name,
        companyId,
      })
      .pipe(
        map((response) => response),
        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "Error Connecting researchHub - Leeway -  MS Office"
          )
        )
      )
      .subscribe({
        next: (response) => {
          this.leewayResponse.set(response);
          this.adminRequestService.setLastRequestMessage("24", true);
          this.adminRequestService.setLastRequestSuccess(true);
          this.isLoading.set(false);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  publicPublishExcelFiguresForCompany(companyTicker) {
    return this.http.post(
      leewayAPI_URL +
        "addin/companydata/publish/?customerId=" +
        this.customerId +
        "&companyId=" +
        companyTicker +
        "&apitoken=cqt1bii3tq5fuzo0c5id",
      {}
    );
  }

  parseValuesFromCompanyNumbersToValuationText() {
    const keys = [
      "curr",
      "dcf_dcfps",
      "name",
      "HL_dcf_2",
      "HL_dcf_9",
      "dcf_plan_hor",
      "dcf_term_val_gro",
      "dcf_cost_of_debt",
      "dcf_term_val_wacc",
      "HL_fcf_2",
      "RW_fcf_fvps_6",
      "HL_fcf_6",
    ];
    const valuationNumbers = {};
    for (const key of keys) {
      valuationNumbers[key] = this.getCompanyfiguresValue(key);
    }

    return valuationNumbers;
  }

  getValue(
    companyData,
    mappingEntry,
    language: string,
    defaultDecimals
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    const tableData = [];
    // console.log("TABLE UTILS GET VALUE");
    // console.log("PATH", path);
    // console.log(companyData);
    try {
      const originalData = path.split(".").reduce((o, i) => o[i], companyData);

      if (typeof originalData !== "undefined") {
        tableData.push(
          formatValue(originalData, mappingEntry, language, defaultDecimals)
        );
      } else {
        tableData.push("na");
      }
    } catch (e) {}

    return tableData;
  }

  findBaseYear(
    companyId: string,
    data: Array<{ date: string; value: any }>
  ): number {
    const baseyear = String(this.companySettings().baseyear);

    if (typeof data !== "undefined") {
      return data.findIndex((entry) => {
        return (
          entry.date
            .replace("E", "")
            .replace("e", "")
            .replace("P", "")
            .replace("p", "") == baseyear
        );
      });
    } else {
      return 0;
    }
  }

  getOneFromTimeseries(
    companyData,
    mappingEntry,
    baseyearShift: number,
    dateOrValue: string,
    language: string,
    defaultDecimals: number
  ): Array<string> {
    const path = mappingEntry.path.replace("companyData.", "");

    const tableData = [];

    try {
      const originalData = path.split(".").reduce((o, i) => o[i], companyData);

      if (typeof originalData !== "undefined") {
        let baseYearIndex = this.findBaseYear(
          companyData.CompanyId,
          originalData
        );
        baseYearIndex += baseyearShift;

        if (
          typeof baseYearIndex !== "undefined" &&
          typeof originalData[baseYearIndex] !== "undefined"
        ) {
          let val;
          if (dateOrValue !== "date") {
            val = formatValue(
              originalData[baseYearIndex][dateOrValue],
              mappingEntry,
              language,
              defaultDecimals
            );
          } else {
            val = originalData[baseYearIndex][dateOrValue];
          }
          tableData.push(val);
        } else {
          tableData.push("na");
        }
      } else {
        tableData.push("na");
      }
    } catch (e) {}

    return tableData;
  }

  private getCompanyfiguresValue(key: string) {
    let value = "na";
    let offset = 0;

    const offsetPattern = /_\d$/;
    if (offsetPattern.test(key)) {
      const split = key.split("_");
      key = split[0];
      for (let i = 1; i < split.length - 1; i++) {
        key += "_" + split[i];
      }
      offset = parseInt(split[split.length - 1]);
    }

    if (this.excelMapping()[key]) {
      const mappingEntry = this.excelMapping()[key];

      if (mappingEntry.type === "VALUE") {
        value = this.getValue(
          this.companyNumbers(),
          mappingEntry,
          this.defaultLanguage,
          this.companySettings().calculation_settings.numDecimals
        )[0];
      } else if (mappingEntry.type === "TIMESERIES") {
        value = this.getOneFromTimeseries(
          this.companyNumbers(),
          mappingEntry,
          offset,
          "value",
          this.defaultLanguage,
          this.companySettings().calculation_settings.numDecimals
        )[0];
      }
    }

    return value;
  }

  private fetchLiveQuotesCompanyGrid(multi: string) {
    const url = `${leewayAPI_URL}public/live/123F.XETRA?apitoken=${this.apiToken}&multi=${multi}`;
    return this.http.get(url);
  }

  private fetchTileDataCompanyGrid(multi: string) {
    const url = `${leewayAPI_URL}addin/companydata/tiledata?apitoken=${this.apiToken}&customerId=${this.customerId}&companyId=123F:GR&multi=${multi}`;
    return this.http.get(url);
  }

  private fetchUniqueLiveQuotesCompanyGrid(companyTicker) {
    const url = `${leewayAPI_URL}public/live/${companyTicker}?apitoken=${this.apiToken}`;
    return this.http.get(url);
  }

  private fetchUniqueTileDataCompanyGrid(companyTicker) {
    const url = `${leewayAPI_URL}addin/companydata/tiledata?apitoken=${this.apiToken}&customerId=${this.customerId}&companyId=${companyTicker}`;
    return this.http.get(url);
  }
  // receiveUniqueCompanyNumbers(companyTicker: string): Signal<any> {
  //   const url =
  //     leewayAPI_URL +
  //     "addin/companydata/getcombinedexceldata?apitoken=" +
  //     this.apiToken +
  //     "&companyId=" +
  //     companyTicker +
  //     "&customerId=" +
  //     this.customerId +
  //     "&published=true";
  //
  //   return toSignal(this.http.get(url), {
  //     initialValue: {
  //       base: {},
  //       general_information: {
  //         free_float: 0,
  //       },
  //     },
  //   });
  // }
}
