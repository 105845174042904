import { JsonPipe } from "@angular/common";
import {
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";

import { AdminCompanySwotEditorComponent } from "~features/company/admin/company-swot/admin-company-swot-editor/admin-company-swot-editor.component";
import { CompanydataService } from "~features/company/companydata.service";
import { SwotService } from "~features/company/swot.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-company-swot",
  standalone: true,
  imports: [
    JsonPipe,
    BreadcrumpComponent,
    RouterLink,
    AdminCompanySwotEditorComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-company-swot.component.html",
  styleUrl: "./admin-company-swot.component.scss",
})
export class AdminCompanySwotComponent implements OnInit {
  companyTicker = signal<string>("");
  swotContent: WritableSignal<any> = signal({});
  swotService = inject(SwotService);

  companyDataService = inject(CompanydataService);
  route = inject(ActivatedRoute);

  ngOnInit() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTicker.set(ticker);
    this.companyDataService.fetchCompanySettings(this.companyTicker());
    this.swotContent = this.swotService.getSwot();
  }
}
