<div [ngClass]="tabRowClasses">
  <nav [ngClass]="[navClasses, 'flex flex-col lg:flex-row']">
    @for (tab of tabs; track tab) {
      <div
        (click)="selectTab(tab)"
        [ngClass]="[
          tabClasses,
          'w-full lg:w-auto mb-0',
          tab.active ? activeTabClass : inactiveTabClass
        ]"
      >
        <span>{{ tab.title }}</span>
      </div>
    }
  </nav>
</div>
<div [ngClass]="contentClasses">
  <ng-content></ng-content>
</div>
