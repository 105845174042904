import { Component, inject } from "@angular/core";

import { AuthService } from "~features/auth/auth.service";

@Component({
  selector: "app-contact-banner",
  standalone: true,
  imports: [],
  templateUrl: "./contact-banner.component.html",
  styleUrl: "./contact-banner.component.scss",
})
export class ContactBannerComponent {
  authService = inject(AuthService);
  isAdmin = this.authService.isAdmin();
}
