import { HttpClient } from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { alsterAPI_URL, leewayAPI_URL } from "~api/api-urls";

@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  alsterExchangeMapping: any;
  mapping = {
    andorra: "ad",
    "united arab emirates": "ae",
    afghanistan: "af",
    "antigua and barbuda": "ag",
    anguilla: "ai",
    albania: "al",
    armenia: "am",
    angola: "ao",
    antarctica: "aq",
    argentina: "ar",
    "american samoa": "as",
    austria: "at",
    australia: "au",
    aruba: "aw",
    "åland islands": "ax",
    azerbaijan: "az",
    "bosnia and herzegovina": "ba",
    barbados: "bb",
    bangladesh: "bd",
    belgium: "be",
    "burkina faso": "bf",
    bulgaria: "bg",
    bahrain: "bh",
    burundi: "bi",
    benin: "bj",
    "saint barthélemy": "bl",
    bermuda: "bm",
    "brunei darussalam": "bn",
    "bolivia, plurinational state of": "bo",
    "bonaire, sint eustatius and saba": "bq",
    brazil: "br",
    bahamas: "bs",
    bhutan: "bt",
    "bouvet island": "bv",
    botswana: "bw",
    belarus: "by",
    belize: "bz",
    canada: "ca",
    "cocos (keeling) islands": "cc",
    "congo, democratic republic of the": "cd",
    "central african republic": "cf",
    congo: "cg",
    switzerland: "ch",
    "côte d'ivoire": "ci",
    "cook islands": "ck",
    chile: "cl",
    cameroon: "cm",
    china: "cn",
    colombia: "co",
    "costa rica": "cr",
    cuba: "cu",
    "cabo verde": "cv",
    curaçao: "cw",
    "christmas island": "cx",
    cyprus: "cy",
    czechia: "cz",
    germany: "de",
    djibouti: "dj",
    denmark: "dk",
    dominica: "dm",
    "dominican republic": "do",
    algeria: "dz",
    ecuador: "ec",
    estonia: "ee",
    egypt: "eg",
    "western sahara": "eh",
    eritrea: "er",
    spain: "es",
    ethiopia: "et",
    finland: "fi",
    fiji: "fj",
    "falkland islands (malvinas)": "fk",
    "micronesia, federated states of": "fm",
    "faroe islands": "fo",
    france: "fr",
    gabon: "ga",
    "united kingdom of great britain and northern ireland": "gb",
    grenada: "gd",
    georgia: "ge",
    "french guiana": "gf",
    guernsey: "gg",
    ghana: "gh",
    gibraltar: "gi",
    greenland: "gl",
    gambia: "gm",
    guinea: "gn",
    guadeloupe: "gp",
    "equatorial guinea": "gq",
    greece: "gr",
    "south georgia and the south sandwich islands": "gs",
    guatemala: "gt",
    guam: "gu",
    "guinea-bissau": "gw",
    guyana: "gy",
    "hong kong": "hk",
    "heard island and mcdonald islands": "hm",
    honduras: "hn",
    croatia: "hr",
    haiti: "ht",
    hungary: "hu",
    indonesia: "id",
    ireland: "ie",
    israel: "il",
    "isle of man": "im",
    india: "in",
    "british indian ocean territory": "io",
    iraq: "iq",
    "iran, islamic republic of": "ir",
    iceland: "is",
    italy: "it",
    jersey: "je",
    jamaica: "jm",
    jordan: "jo",
    japan: "jp",
    kenya: "ke",
    kyrgyzstan: "kg",
    cambodia: "kh",
    kiribati: "ki",
    comoros: "km",
    "saint kitts and nevis": "kn",
    "korea, democratic people's republic of": "kp",
    "korea, republic of": "kr",
    kuwait: "kw",
    "cayman islands": "ky",
    kazakhstan: "kz",
    "lao people's democratic republic": "la",
    lebanon: "lb",
    "saint lucia": "lc",
    liechtenstein: "li",
    "sri lanka": "lk",
    liberia: "lr",
    lesotho: "ls",
    lithuania: "lt",
    luxembourg: "lu",
    latvia: "lv",
    libya: "ly",
    morocco: "ma",
    monaco: "mc",
    "moldova, republic of": "md",
    montenegro: "me",
    "saint martin, (french part)": "mf",
    madagascar: "mg",
    "marshall islands": "mh",
    "north macedonia": "mk",
    mali: "ml",
    myanmar: "mm",
    mongolia: "mn",
    macao: "mo",
    "northern mariana islands": "mp",
    martinique: "mq",
    mauritania: "mr",
    montserrat: "ms",
    malta: "mt",
    mauritius: "mu",
    maldives: "mv",
    malawi: "mw",
    mexico: "mx",
    malaysia: "my",
    mozambique: "mz",
    namibia: "na",
    "new caledonia": "nc",
    niger: "ne",
    "norfolk island": "nf",
    nigeria: "ng",
    nicaragua: "ni",
    netherlands: "nl",
    norway: "no",
    nepal: "np",
    nauru: "nr",
    niue: "nu",
    "new zealand": "nz",
    oman: "om",
    panama: "pa",
    peru: "pe",
    "french polynesia": "pf",
    "papua new guinea": "pg",
    philippines: "ph",
    pakistan: "pk",
    poland: "pl",
    "saint pierre and miquelon": "pm",
    pitcairn: "pn",
    "puerto rico": "pr",
    "palestine, state of": "ps",
    portugal: "pt",
    palau: "pw",
    paraguay: "py",
    qatar: "qa",
    réunion: "re",
    romania: "ro",
    serbia: "rs",
    "russian federation": "ru",
    rwanda: "rw",
    "saudi arabia": "sa",
    "solomon islands": "sb",
    seychelles: "sc",
    sudan: "sd",
    sweden: "se",
    singapore: "sg",
    "saint helena, ascension and tristan da cunha": "sh",
    slovenia: "si",
    "svalbard and jan mayen": "sj",
    slovakia: "sk",
    "sierra leone": "sl",
    "san marino": "sm",
    senegal: "sn",
    somalia: "so",
    suriname: "sr",
    "south sudan": "ss",
    "sao tome and principe": "st",
    "el salvador": "sv",
    "sint maarten, (dutch part)": "sx",
    "syrian arab republic": "sy",
    eswatini: "sz",
    "turks and caicos islands": "tc",
    chad: "td",
    "french southern territories": "tf",
    togo: "tg",
    thailand: "th",
    tajikistan: "tj",
    tokelau: "tk",
    "timor-leste": "tl",
    turkmenistan: "tm",
    tunisia: "tn",
    tonga: "to",
    turkey: "tr",
    "trinidad and tobago": "tt",
    tuvalu: "tv",
    "taiwan, province of china": "tw",
    "tanzania, united republic of": "tz",
    ukraine: "ua",
    uganda: "ug",
    "united states minor outlying islands": "um",
    "united states of america": "us",
    uruguay: "uy",
    uzbekistan: "uz",
    "holy see": "va",
    "saint vincent and the grenadines": "vc",
    "venezuela, bolivarian republic of": "ve",
    "virgin islands, british": "vg",
    "virgin islands, u.s.": "vi",
    "viet nam": "vn",
    vanuatu: "vu",
    "wallis and futuna": "wf",
    samoa: "ws",
    yemen: "ye",
    mayotte: "yt",
    "south africa": "za",
    zambia: "zm",
    zimbabwe: "zw",
  };
  isLoading: WritableSignal<boolean> = signal(false);
  private currentRouterState = new Subject();
  private readonly customerId = "5eed24ff4fe2bd337ccab5d3";
  private readonly apiUrl = leewayAPI_URL;

  constructor(private httpClient: HttpClient) {}

  public setRouterstate(state: any) {
    this.currentRouterState.next(state);
  }

  public getRouterstate(): Observable<any> {
    return this.currentRouterState.asObservable();
  }

  loadExchangeMapping() {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get(
          alsterAPI_URL +
            "exchangemappings/all" +
            "?apitoken=cqt1bii3tq5fuzo0c5id" +
            "&customerId=" +
            this.customerId
        )
        .toPromise()
        .then((res: any) => {
          this.alsterExchangeMapping = res.result;
          resolve(res.result);
        });

      // this.alsterExchangeMapping = {
      //   mapping: {
      //     "mapping": {
      //       "2GI_GR": "2GI.MU",
      //       "R1B_GR": "R1B.MU",
      //       "CDZ0_GR": "CDZ0.MU",
      //       "14D_GR": "14D.MU",
      //       "991_GR": "991.MU",
      //       "XCX_GR": "XCX.DU",
      //       "Z01_GR": "Z01.HM",
      //       "NSP_GR": "NSP.MI",
      //       "P7V_GR": "P7V.F",
      //       "ZO1_GR": "ZO1.HM",
      //       "TGYM_GR": "TGYM.MI",
      //       "GSJ_GR": "GSJ.F",
      //       "ABA_GR": "ABA.F",
      //       "SDF_GR": "SDF.XETRA"
      //     }
      //   }
      // }
      // resolve(this.alsterExchangeMapping)
    });
  }

  public getExchangeMapping() {
    return new Promise((resolve, reject) => {
      if (this.alsterExchangeMapping && this.alsterExchangeMapping.mapping) {
        resolve(this.alsterExchangeMapping.mapping);
      } else {
        this.loadExchangeMapping().then((result: any) => {
          resolve(result.mapping);
        });
      }
    });
  }

  public getMappedExchange(blTicker) {
    const parsedBLTicker = blTicker.replaceAll(":", "_");

    if (
      this.alsterExchangeMapping.mapping !== null &&
      this.alsterExchangeMapping.mapping !== undefined
    ) {
      let mappedTicker = this.alsterExchangeMapping.mapping[parsedBLTicker];

      if (mappedTicker === null || mappedTicker === undefined) {
        mappedTicker = blTicker.split(":")[0] + "." + "XETRA";
      }

      return mappedTicker;
    } else {
      return blTicker;
    }
  }

  public updateExchangeMapping(data) {
    const body = { blTicker: data.blTicker, mappedTicker: data.mappedTicker };
    return this.httpClient
      .post(
        alsterAPI_URL +
          "exchangemappings/add" +
          "?apitoken=cqt1bii3tq5fuzo0c5id" +
          "&customerId=" +
          this.customerId,
        body
      )
      .toPromise();
  }

  public deleteExchangeMapping(blTicker) {
    const body = { blTicker: blTicker };
    return this.httpClient
      .post(
        alsterAPI_URL +
          "exchangemapping/delete" +
          "?apitoken=cqt1bii3tq5fuzo0c5id" +
          "&customerId=" +
          this.customerId,
        body
      )
      .toPromise();
  }

  public selectCountryFlag(countryName) {
    let flagClass = null;

    if (countryName) {
      countryName = countryName.toLowerCase();

      if (this.mapping[countryName]) {
        flagClass = "fi-" + this.mapping[countryName].toLowerCase();
      }
    }

    return flagClass ? flagClass : "fi-xx";
  }

  getisLoading() {
    return this.isLoading;
  }
}
