<div [ngClass]="wrapperClass">
  @if (src) {
    <img
      [ngClass]="logoClass + ' w-full h-full'"
      alt="{{ alt }}"
      loading="lazy"
      src="{{ src }}"
    />
  } @else {
    <div class="h-full w-full bg-yellow-900"></div>
  }
</div>
