<div class="container mx-auto px-4 py-8">
  <div class="max-w-md mx-auto">
    <form
      [formGroup]="initiationNoteForm"
      class="bg-white shadow-md rounded-lg px-8 pt-6 pb-8 mb-8"
    >
      <div class="mb-6">
        <label
          class="block text-gray-700 text-sm font-secondary mb-2"
          for="companyTicker"
        >
          Company Ticker:
        </label>
        <input
          [ngClass]="{
            'border-red-500':
              initiationNoteForm.get('companyTicker')?.invalid &&
              initiationNoteForm.get('companyTicker')?.touched
          }"
          class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
          formControlName="companyTicker"
          id="companyTicker"
          required
          type="text"
        />
        @if (
          initiationNoteForm.get("companyTicker")?.invalid &&
          initiationNoteForm.get("companyTicker")?.touched
        ) {
          <p class="mt-1 text-xs text-red-500">
            {{ getErrorMessage("companyTicker") }}
          </p>
        }
      </div>
      <div class="mb-6">
        <label
          class="block text-gray-700 text-sm font-secondary mb-2"
          for="research"
        >
          Research:
        </label>
        <input
          [ngClass]="{
            'border-red-500':
              initiationNoteForm.get('research')?.invalid &&
              initiationNoteForm.get('research')?.touched
          }"
          class="shadow-sm appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
          formControlName="research"
          id="research"
          required
          type="text"
        />
        @if (
          initiationNoteForm.get("research")?.invalid &&
          initiationNoteForm.get("research")?.touched
        ) {
          <p class="mt-1 text-xs text-red-500">
            {{ getErrorMessage("research") }}
          </p>
        }
      </div>
      @if (!multiMode) {
        <div class="flex items-center justify-end">
          <app-admin-form-button
            (click)="saveForm()"
            [class]="'admin-form-button'"
            [disabled]="!initiationNoteForm.valid"
          >
          </app-admin-form-button>
        </div>
        <app-admin-form-request-message></app-admin-form-request-message>
      }
    </form>
  </div>

  <div class="mt-12">
    <button
      (click)="fetchResearchList()"
      class="bg-blue-500 hover:bg-blue-400 text-white font-sans py-2 px-4 rounded-md mb-6"
    >
      Click here to show new research
    </button>

    <div class="bg-white shadow-md rounded-lg overflow-hidden">
      <div class="px-4 py-3 border-b">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600 mt-2">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalCount() }}
        </p>
      </div>

      <table class="w-full">
        <thead class="bg-gray-50">
          <tr>
            <th
              class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
            >
              Title
            </th>
            <th
              class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
            >
              Published at
            </th>
            <th
              class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
            >
              ID
            </th>
            <th
              class="px-4 py-2 text-left text-xs font-secondary text-gray-500 uppercase tracking-wider"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          @for (research of researchList(); track research.title) {
            <tr>
              <td class="px-4 py-3 text-sm">{{ research.title }}</td>
              <td class="px-4 py-3 text-sm">{{ research.published_date }}</td>
              <td class="px-4 py-3 text-sm">{{ research._id }}</td>
              <td class="px-4 py-3 text-sm">
                <button
                  (click)="selectResearchForInitiation(research._id)"
                  class="bg-blue-500 hover:bg-blue-400 text-white font-sans py-1 px-3 rounded-md text-xs"
                >
                  Set as Initiation Note
                </button>
              </td>
            </tr>
          } @empty {
            <tr>
              <td colspan="4" class="px-4 py-3 text-sm text-center">
                Click the "Look for reseach" Button to upate this list.
              </td>
            </tr>
          }
        </tbody>
      </table>
      <div class="px-4 py-3 border-b">
        <app-pagination
          (pageChanged)="onPageChange($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalCount"
        ></app-pagination>
        <p class="text-sm text-gray-600 mt-2">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalCount() }}
        </p>
      </div>
    </div>
  </div>
</div>
