<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-8 max-w-3xl mx-auto">
      <h1 class="text-xl font-bold text-red-600 mb-6">Delete Company</h1>

      <div
        class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-6"
        role="alert"
      >
        <p class="font-bold">Warning!</p>
        <p>You are about to delete a company. This action cannot be undone.</p>
      </div>

      <div class="mb-6">
        <h2 class="text-lg font-semibold mb-4">Company Information</h2>

        <p class="mb-2">
          <span class="font-semibold">Ticker:</span>
          {{ currentCompanyTicker() }}
        </p>
      </div>

      <div class="mb-6">
        <h2 class="text-lg font-semibold mb-4">Associated Data</h2>
        <p class="mb-2">
          <span class="font-semibold">Research Articles:</span>
          <span class="font-semibold text-red-600">
            {{ companyContextOverview()?.research.length }}</span
          >
          (will be deleted)
        </p>
        <p class="mb-2">
          <span class="font-semibold">Investment Case Articles:</span>
          <span class="font-semibold text-red-600">
            {{ companyContextOverview()?.investmentCase.length }}</span
          >
          (will be deleted)
        </p>
        <p class="mb-2">
          <span class="font-semibold">Valuation Articles:</span>
          <span class="font-semibold text-red-600">
            {{ companyContextOverview()?.valuation.length }}</span
          >
          (will be deleted)
        </p>
        <p class="mb-2">
          <span class="font-semibold">SWOT Analysis:</span>
          <span class="font-semibold text-red-600">
            {{ companyContextOverview()?.swot.length }}</span
          >
          (will be deleted)
        </p>
        <p class="mb-2">
          <span class="font-semibold">Initiation Note:</span>
          <span class="font-semibold text-red-600">
            {{ companyContextOverview()?.initiationNote.length }}
          </span>
          (will be deleted)
        </p>
        <hr />

        <p class="mb-2">
          <span class="font-semibold">PDF Files:</span>
          PDF files will <b>not</b> be deleted, but are not connected to
          anything anymore.
        </p>
        <p class="mb-2">
          <span class="font-semibold">Events:</span>
          Events will <b>not</b> be deleted.
        </p>
        <p class="mb-2">
          <span class="font-semibold">Excel/Word:</span>
          Figures and Data uploaded through Excel/Word Addin will <b>not</b> be
          deleted.
        </p>
      </div>

      <div
        class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6"
        role="alert"
      >
        <p class="font-bold">Deletion Consequences:</p>
        <ul class="list-disc list-inside">
          <li>All company data will be permanently deleted</li>
          <li>
            Associated research articles, investor case articles, valuation
            articles, SWOT analysis and the initiation note will be removed
          </li>
          <li>This action is irreversible</li>
        </ul>
      </div>

      <div class="flex justify-between items-center">
        <app-delete-button
          (deleteConfirmed)="deleteCompany(currentCompanyTicker())"
          [confirmText]="'Confirm Deletion of ' + currentCompanyTicker()"
          [deleteText]="'Delete Company ' + currentCompanyTicker()"
        >
        </app-delete-button>
      </div>
      <app-admin-form-request-message></app-admin-form-request-message>
    </div>
  </main>
</div>
