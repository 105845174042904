import {
  Directive,
  ElementRef,
  HostListener,
  inject,
  Input,
} from "@angular/core";

import { AdminInfoSideeffectService } from "~features/admin-info-sideeffects/admin-info-sideeffect.service";
import { AuthService } from "~features/auth/auth.service";

@Directive({
  selector: "[appAdminInfoHasSideeffect]",
  standalone: true,
})
export class AdminInfoHasSideeffectDirective {
  @Input() hasSideeffect = true;
  @Input() inputName = "Hallo";

  sideEffectService = inject(AdminInfoSideeffectService);
  authService = inject(AuthService);
  isAdmin = this.authService.isAdmin();

  constructor(private el: ElementRef) {
    if (this.hasSideeffect && this.isAdmin) {
      this.el.nativeElement.classList.add("bg-purple-100");
      this.el.nativeElement.classList.add("rounded-lg");
    }
  }
  @HostListener("mouseenter") onMouseEnter() {
    if (this.isAdmin) {
      this.sideEffectService.showInfoBanner(this.inputName);
    }
  }
  @HostListener("mouseleave") onMouseLeave() {
    if (this.isAdmin) {
      this.sideEffectService.hideInfoBanner();
    }
  }
}
