import { JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  computed,
  ContentChildren,
  effect,
  QueryList,
  signal,
} from "@angular/core";
import { interval, Subscription } from "rxjs";

import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { SliderElementComponent } from "~shared/components/ui/slider-element/slider-element.component";

@Component({
  selector: "app-slider",
  standalone: true,
  imports: [SliderElementComponent, JsonPipe, NgClass],
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent {
  @ContentChildren(SliderElementComponent)
  slides!: QueryList<SliderElementComponent>;

  currentIndex = signal(0);
  intervalSubscription: Subscription | undefined;

  readonly totalSlides = computed(() => this.slides.length - 1);

  visualDots = computed(() => {
    return Array.from({ length: this.totalSlides() }, (_, i) => i);
  });

  constructor() {
    effect(() => {
      //this.startAutoSlide();
      return () => {
        this.stopAutoSlide();
      };
    }, enableSignalWritesInEffectContext);
  }

  startAutoSlide() {
    this.intervalSubscription = interval(10000).subscribe(() => {
      this.nextSlide();
    });
  }

  stopAutoSlide() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  prevSlide() {
    this.stopAutoSlide();
    this.currentIndex.update(
      (index) => (index - 1 + this.totalSlides()) % this.totalSlides()
    );
    // this.startAutoSlide();
  }

  nextSlide() {
    this.currentIndex.update((index) => (index + 1) % this.totalSlides());
  }
}
