<a [routerLink]="['/companies', company.slug]" class="h-full">
  <div class="bg-gray-100 flex hover:bg-gray-200 hover:cursor-pointer h-full">
    <div class="w-full lg:w-1/2 px-8 pb-8 pt-4 grid grid-cols-2">
      <div class="col-span-2 flex justify-between">
        <div class="text-md font-bold truncate">{{ company.name }}</div>
        <app-country-flag
          [country]="company?.tileData?.base?.country"
        ></app-country-flag>
      </div>
      <div class="col-span-1 pt-2">
        <div class="inline-block">
          @if (company.isFeatured) {
            <div
              [ngClass]="{
                'bg-green-500': company.isFeatured === 'Initiation',
                'bg-yellow-500': company.isFeatured === 'Update',
                'bg-orange-500': company.isFeatured === 'Transaction'
              }"
              class="text-xs text-gray-900 uppercase font-normal tracking-widest py-[.15em] px-[.4em]"
            >
              {{ company.isFeatured }}
            </div>
          }
        </div>
      </div>
      <div class="col-span-1">
        <div class="text-right">
          @if (company.liveQuote && company.liveQuote.close !== "NA") {
            <div>
              {{ company?.tileData?.base?.curr }}
              {{ company?.liveQuote?.close | number: "1.2-2" }}
            </div>
          } @else {
            <div>-</div>
          }

          @if (company.liveQuote && company.liveQuote.change_p !== "NA") {
            <div class="{{ getNumberColor(company.liveQuote?.change_p) }}">
              {{ company.liveQuote.change_p | number: "1.1-1" }}%
            </div>
          } @else {
            <div>-</div>
          }

          <div
            class="font-semibold tracking-widest                    {{
              getTextColor(company?.tileData?.general_information?.cus_rating)
            }}"
          >
            {{ company?.tileData?.general_information?.cus_rating || "-" }}
          </div>
        </div>
      </div>

      <div class="col-span-2 space-y-2">
        <div
          class="text-xxs uppercase leading-none font-normal tracking-widest"
        >
          MWB RESEARCH AG
        </div>
        <div
          class="text-xxs text-gray-muted leading-none font-normal tracking-widest uppercase w-full line-clamp-2"
        >
          {{ latestResearchTitle() || "-" }}
        </div>
      </div>
    </div>
    <div class="hidden lg:flex w-1/2 border-t-1 border-b-1 border-l-1">
      <img
        alt="Company Image"
        class="h-full w-full object-cover"
        src="{{ company.imageURL }}"
      />
    </div>
  </div>
</a>
