import { JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IScreenerArticle } from "~global-interfaces/IScreenerArticle";

import { AdminCompanyResearchEditorFormComponent } from "~features/company/admin/company-research/admin-company-research-editor-form/admin-company-research-editor-form.component";
import { AdminCompanyResearchPdfComponent } from "~features/company/admin/company-research/admin-company-research-pdf/admin-company-research-pdf.component";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminScreenerEditorFormComponent } from "~features/weekly-screener/admin/admin-screener-editor-form/admin-screener-editor-form.component";
import { WeeklyScreenerService } from "~features/weekly-screener/weekly-screener.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { DeleteButtonComponent } from "~shared/components/ui/delete-button/delete-button.component";

@Component({
  selector: "app-admin-screener-editor",
  standalone: true,
  imports: [
    AdminCompanyResearchPdfComponent,
    DisplayEditorContentComponent,
    EditEditorContentComponent,
    AdminCompanyResearchEditorFormComponent,
    AdminScreenerEditorFormComponent,
    BreadcrumpComponent,
    JsonPipe,
    AdminSidePanelComponent,
    FileUploadComponent,
    DeleteButtonComponent,
  ],
  templateUrl: "./admin-screener-editor.component.html",
  styleUrl: "./admin-screener-editor.component.scss",
})
export class AdminScreenerEditorComponent {
  screenerService = inject(WeeklyScreenerService);

  screenerDate = signal<string>("");

  screenerArticle = signal<IScreenerArticle | null>({
    title: "",
    content_DE: { content: "" },
    published_date: "",
  } as any);
  protected readonly event = event;

  constructor(private route: ActivatedRoute, private http: HttpClient) {
    const screenerDate = this.route.snapshot.paramMap.get("screenerDate") ?? "";
    this.screenerDate.set(screenerDate);
    this.screenerService.fetchWeeklyScreener(this.screenerDate());
    this.screenerArticle = this.screenerService.getWeeklyScreener();
  }

  onFormSubmit(newData: any) {
    //console.log(newData);

    this.screenerService.updateScreenerArticle(newData);
  }

  deleteScreener(screenerId: string) {
    this.screenerService.deleteScreenerWithContext(screenerId);
  }
}
