<div class="flex bg-blue-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">PDF Download Analytics</p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      <div
        class="bg-white shadow-md rounded-lg p-6 md:col-span-2 overflow-x-auto"
      >
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th class="px-6 py-3" scope="col">#</th>
              <th class="px-6 py-3" scope="col">PDF</th>
              <th class="px-6 py-3" scope="col">Clicked total</th>
            </tr>
          </thead>
          <tbody>
            @for (pdf of pdfAnalytics(); track pdf; let i = $index) {
              <tr class="bg-white border-b hover:bg-gray-50">
                <th
                  scope="row"
                  class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                >
                  {{ i + 1 }}
                </th>
                <td class="px-6 py-2">{{ pdf._id }}</td>
                <td class="px-6 py-2">{{ pdf.count }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div>
        <button (click)="downloadPdfAnalytics()" class="btn">
          Download CSV
        </button>
      </div>
    </div>
  </main>
</div>
