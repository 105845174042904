import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

// import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: "app-responsive-video",
  templateUrl: "./responsive-video.component.html",
  styleUrls: ["./responsive-video.component.scss"],
  standalone: true,
  imports: [],
})
export class ResponsiveVideoComponent implements OnChanges {
  @Input() videoURL = null; //"https://vimeo.com/522221637";
  embedUrl: SafeResourceUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("VIDEO CHANGES");
    // console.log(changes);
    if (changes["videoURL"]) {
      this.updateVideo();
    }
  }

  private updateVideo(): void {
    const url = this.validateURL(this.videoURL);
    if (url !== null && url !== "") {
      this.embedUrl = this.createEmbedUrl(url);
    }
  }

  private validateURL(url: string): string {
    try {
      new URL(url);
      return url;
    } catch {
      console.warn(`Invalid URL provided: ${url}. Returned empty string.`);
      return ""; //"https://vimeo.com/522221637";
    }
  }

  private createEmbedUrl(url: string): SafeResourceUrl | null {
    const videoId = this.extractVideoId(url);
    if (!videoId) return null;

    const isYoutube = url.includes("youtube.com") || url.includes("youtu.be");
    const embedUrl = isYoutube
      ? `https://www.youtube.com/embed/${videoId}`
      : `https://player.vimeo.com/video/${videoId}`;

    return this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }

  private extractVideoId(url: string): string | null {
    try {
      const parsedUrl = new URL(url);

      if (parsedUrl.hostname === "youtu.be") {
        // Handle short YouTube URLs
        return parsedUrl.pathname.slice(1);
      } else if (parsedUrl.hostname === "www.youtube.com") {
        // Handle full YouTube URLs
        return new URLSearchParams(parsedUrl.search).get("v");
      } else if (parsedUrl.hostname === "vimeo.com") {
        // Handle Vimeo URLs
        return parsedUrl.pathname.split("/")[1].split("?")[0];
      }

      return null;
    } catch (error) {
      console.error("Error extracting video ID:", error);
      return null;
    }
  }
}
