<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        Add a new company to the legal disclaimer
      </p>
      <p class="text-gray-600">
        This companies are independend from the actual companies.
      </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div class="rounded-lg p-6 md:col-span-2">
        <app-admin-legal-disclaimer-company-editor-form
          (formSubmit)="onFormSubmit($event)"
          [legalDisclaimerCompany]="legalDisclaimerCompany"
        >
        </app-admin-legal-disclaimer-company-editor-form>
      </div>
    </div>
  </main>
</div>
