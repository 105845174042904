<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">
        Edit a company entry in the legal disclaimer
      </p>

      <div class="grid grid-cols-3 gap-1">
        <div class="col-span-1">
          <app-delete-button
            (deleteConfirmed)="
              deleteLegalDisclaimer(currentLegalDisclaimerCompany()?._id)
            "
            (deleteRequested)="toggleDeletionWarning(true)"
            (deleteReset)="toggleDeletionWarning(false)"
            confirmText="Click to confirm Disclaimer Deletion for {{
              currentLegalDisclaimerCompany()?.bloomberg
            }}"
            deleteText="Delete Disclaimer"
          ></app-delete-button>
        </div>
        <div class="col-span-2">
          @if (showDeletionWarning()) {
            <div class="col-span-2 border border-2 border-red-600 p-2">
              <p class="text-sm text-gray-muted">
                Only this single entry will be deleted. No company data is
                affected by this.
              </p>
            </div>
          }
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
      <div class="rounded-lg p-6 md:col-span-2">
        <app-admin-legal-disclaimer-company-editor-form
          (formSubmit)="onFormSubmit($event)"
          [legalDisclaimerCompany]="currentLegalDisclaimerCompany"
        >
        </app-admin-legal-disclaimer-company-editor-form>
      </div>
    </div>
  </main>
</div>
