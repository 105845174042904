import { DecimalPipe, JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  inject,
  Input,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";
import { ICompanyNumbers } from "~global-interfaces/ICompanyNumbers";

import { CompanyInfoService } from "~features/company/company-info.service";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { BrowserService } from "~features/tables/browser.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-companyinfo-slider",
  standalone: true,
  imports: [NgClass, JsonPipe, DecimalPipe],
  templateUrl: "./companyinfo-slider.component.html",
  styleUrl: "./companyinfo-slider.component.scss",
})
export class CompanyinfoSliderComponent {
  @Input() companyNumbers: Signal<ICompanyNumbers>;
  @Input() sliderIndex: Signal<number>;
  @Input() baseyear;
  @Input() lang = "eng";
  @Input() mapping: Signal<any>;
  allSlideArrays = [
    ["bl_ticker", "mc", "ev", "free_float", "volume", "num_shares"],
    ["isin", "wkn", "sector", "country", "index"],
    ["con_rating", "con_pt", "con_pt_range", "52w_high", "52w_low"],
    ["event", "event_date"],
    ["esg"],
  ];

  currentSlideData: WritableSignal<any[]> = signal([]);
  companyInfoService = inject(CompanyInfoService);
  companyTickerServie = inject(CompanyTickerService);
  currentCompanyTicker = this.companyTickerServie.getCompanyTicker();
  browserService = inject(BrowserService);
  esgScore = this.companyInfoService.getESGScore();
  private tableUtils = inject(Tables);

  constructor() {
    effect(() => {
      if (this.mapping() && this.companyNumbers()) {
        //  console.log("Preparing slide data for index:", this.sliderIndex());
        this.currentSlideData.set(this.prepareSlideData());
        // console.log("Slide data prepared:", this.currentSlideData());
      }
    }, enableSignalWritesInEffectContext);
  }

  prepareSlideData() {
    const slideData = [];

    for (let i = 0; i < this.allSlideArrays[this.sliderIndex()].length; i++) {
      const key = this.allSlideArrays[this.sliderIndex()][i];
      if (key === "esg") {
        slideData.push({
          label: "ESG Score",
          value: this.esgScore(),
        });
      } else {
        const mappingEntry = this.mapping()[key];
        if (mappingEntry) {
          if (mappingEntry.type === "VALUE") {
            const entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getValue(
                this.companyNumbers(),
                mappingEntry,
                this.lang,
                null
              ),
            };

            let value = entry.value[0];
            if (key === "mc" || key === "con_pt") {
              const currency =
                this.companyNumbers()?.base?.quote_curr ||
                this.companyNumbers()?.base?.curr;
              value = currency + " " + value;
            }
            if (key === "mc") {
              value += "m";
            }

            slideData.push({
              label: entry.title,
              value: value,
            });
          }
          if (mappingEntry.type === "TIMESERIES") {
            const entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getOneFromTimeseries(
                this.companyNumbers(),
                mappingEntry,
                1,
                "value",
                this.lang,
                null
              ),
            };

            let value = entry.value[0];
            if (key === "ev" || key === "volume") {
              const currency =
                this.companyNumbers()?.base?.quote_curr ||
                this.companyNumbers()?.base?.curr;
              value = currency + " " + value;
            }
            if (key === "ev" || key === "volume" || key === "num_shares") {
              value += "m";
            }

            slideData.push({
              label: entry.title,
              value: value,
            });
          }
        } else {
          //  console.log("no mapping for key: ", key);
        }
      }
    }
    return slideData;
  }
}

/**
 *
 *             ['bl_ticker', 'mc', 'ev', 'free_float', 'volume', 'num_shares'],
 *             base.bl_ticker
 *             financial_figures.ratios.mc
 *             general_information.free_float
 *             general_information.volume
 *
 *
 *             ['isin', 'wkn', 'sector', 'country', 'index'],
 *
 *
 *             ['con_rating', 'con_pt', 'con_pt_range', '52w_high', '52w_low'],
 *
 *
 *             ['event', 'event_date'],
 *
 *
 *             [{ type: 'custom', content: 'esgScores', input: this.symbolExchange }]
 *
 *
 *
 *
 * Slide 1
 * Bloomberg Ticker	CEC:GR
 * Market Cap	EUR 1,499m
 * Enterprise Value	EUR 3,164m
 * Free Float	36.3%
 * Trading Volume	505,701
 * Number of Shares	485.2m
 *
 * slide 2
 *
 * ISIN	DE0007257503
 * WKN	725750
 * Sector	Retail
 * Country	Germany
 * Index	SDAX
 *
 *
 *slide 3
 * Consensus Rating	SELL
 * Consensus PT	EUR 2.75
 * Up-/Downside Consensus PT	-11.0%
 *
 * slide 4
 *
 * Event	3Q2024
 * Event Date	14/08/2024
 *
 * slide 5
 *
 * ESG Score32 / 100
 * horizontal bar chart/line
 * Social Score	16
 * Governance Score	32
 * Environment Score	21
 *
 */
