import { AsyncPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, inject, signal } from "@angular/core";
import { RouterLink } from "@angular/router";

import { AdminCompanyListService } from "~features/company/admin-company-list.service";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { ExcelService } from "~features/company/excel.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { ErrorHandlingService } from "~shared/services/error-handling.service";

@Component({
  selector: "app-admin-company-list",
  standalone: true,
  imports: [
    AsyncPipe,
    JsonPipe,
    RouterLink,
    BreadcrumpComponent,
    PaginationComponent,
    AdminSidePanelComponent,
    CompanySearchComponent,
    NgClass,
  ],
  templateUrl: "./admin-company-list.component.html",
  styleUrl: "./admin-company-list.component.scss",
})
export class AdminCompanyListComponent {
  adminCompanyListService = inject(AdminCompanyListService);
  companyAdminList = this.adminCompanyListService.items;
  totalCount = this.adminCompanyListService.totalCount;
  excelService = inject(ExcelService);
  itemsPerPage = signal(25);
  currentPage = signal(1);
  adminRequestService = inject(AdminRequestService);
  errorHandlingService = inject(ErrorHandlingService);
  constructor() {
    this.fetchCompanies();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalCount());
  }

  fetchCompanies() {
    const paginationParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    };
    this.adminCompanyListService.fetchCompanyAdminList(paginationParams);
  }

  publishExcelFigures(companyTicker) {
    this.excelService
      .publicPublishExcelFiguresForCompany(companyTicker)
      .subscribe({
        next: () => {
          this.adminRequestService.setLastRequestMessage("23", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  onPageChange(page: number) {
    this.currentPage.set(page);
    this.fetchCompanies();
  }
}
