import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { IResearchPodcastPopulated } from "~global-interfaces/IResearchPodcast";

import { PodcastMiniPlayerComponent } from "~features/podcasts/podcast-mini-player/podcast-mini-player.component";

@Component({
  selector: "app-podcast-list-item",
  standalone: true,
  imports: [PodcastMiniPlayerComponent, DatePipe],
  templateUrl: "./podcast-list-item.component.html",
  styleUrl: "./podcast-list-item.component.scss",
})
export class PodcastListItemComponent {
  @Input() podcast: IResearchPodcastPopulated;
}
