import { JsonPipe } from "@angular/common";
import {
  Component,
  effect,
  ElementRef,
  inject,
  Input,
  signal,
  Signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";

import { ChartComponent } from "~features/charts/chart/chart.component";
import { ExcelService } from "~features/company/excel.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-figure-graph",
  templateUrl: "./figure-graph.component.html",
  styleUrls: ["./figure-graph.component.scss"],
  standalone: true,
  imports: [ChartComponent, JsonPipe],
})
export class FigureGraphComponent {
  @ViewChild("chartWrapperFigureGraph") chartWrapper: ElementRef;

  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  @Input() config: Signal<any>;

  @Input() decimals = 0;
  @Input() lang = "eng";

  excelService = inject(ExcelService);
  tableUtils = inject(Tables);

  chart: WritableSignal<any> = signal(null);
  excelMapping = this.excelService.getExcelMapping();

  constructor() {
    effect(() => {
      this.prepareData(this.excelMapping(), this.companyData(), this.config());
    }, enableSignalWritesInEffectContext);
    //WARNING: this is a proper side effect that MUST be checked before deploy to prod
    // TODO: check this unknown side effect
  }

  setChartDefaults(): any {
    return {
      data: [],
      layout: {
        autosize: true,
        showlegend: false,
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
          tickangle: 0,
          showgrid: false,
          zeroline: false,
          type: "category",
          dividercolor: "rgba(0,0,0,0)",
          tickfont: {
            size: 16,
            color: "#e2e8f0",
          },
          fixedrange: true,
        },
        yaxis: {
          showgrid: false,
          visible: true,
          zeroline: false,
          tickfont: {
            size: 16,
            color: "#e2e8f0",
          },
          fixedrange: true,
          // tickformat: 'd'
        },
        margin: {
          autoexpand: true,
          t: 15,
          b: 30,
          l: 45,
          r: 10,
        },
      },
      config: {
        displayModeBar: false,
        // responsive: true
      },
      useResizeHandler: true,
      style: { height: "100%", width: "100%" },
    };
  }

  prepareData(mapping, companyNumbers, config) {
    // console.log(companyNumbers, mapping, config);
    if (
      config &&
      companyNumbers &&
      companyNumbers.base &&
      companyNumbers.financial_figures
    ) {
      const chart = this.setChartDefaults();
      if (mapping && companyNumbers && companyNumbers.base) {
        for (const key of config.content) {
          if (mapping[key]) {
            const mappingEntry = mapping[key];
            config.headline = mappingEntry[this.lang];
            let entry;
            if (mappingEntry.type === "TIMESERIES") {
              entry = {
                x: this.tableUtils.getTimeseries(
                  key,
                  companyNumbers,
                  mappingEntry,
                  1,
                  3,
                  "date",
                  this.lang,
                  this.decimals
                ),
                y: this.tableUtils.getTimeseries(
                  key,
                  companyNumbers,
                  mappingEntry,
                  1,
                  3,
                  "value",
                  this.lang,
                  this.decimals
                ),
              };
            }

            if (entry) {
              // if (!mappingEntry.unit) {
              //   entry.valueModifier = this.companyData?.base?.curr + 'm';
              // } else {
              //   entry.valueModifier = '';
              // }

              if (mappingEntry.unit) {
                if (mappingEntry.unit === "PERCENT") {
                  chart.layout.yaxis.ticksuffix = "%";
                }
              }

              if (mappingEntry.format) {
                if (mappingEntry.format === "2-decimals") {
                  chart.layout.yaxis.tickformat = ".2f";
                }
              }

              const trace = {
                x: entry.x, //[entry.x, entry.y.map(y => y + ' ' + entry.valueModifier)],
                y: entry.y.map((y) => {
                  const matches = y.match(/-?\d+(.\d+)?%?/g);
                  if (matches) {
                    return matches[0];
                  } else {
                    return "n/a";
                  }
                }),
                type: "scatter",
              };

              chart.data = [trace];
            }
          }
        }
        chart.style.height = this.chartWrapper
          ? this.chartWrapper.nativeElement.offsetHeight + "px"
          : "100%";
        this.chart.set(chart);
      }
    }
  }
}
