import { CommonModule } from "@angular/common";
import { Component, inject } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";

import { CompanyInvestmentcaseFormComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-form/company-investmentcase-form.component";
import { CompanyLeewayConnectionFormComponent } from "~features/company/admin/company-leeway/company-leeway-connection-form/company-leeway-connection-form.component";
import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { ExcelService } from "~features/company/excel.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

export enum LoadingState {
  BeforeRequest = "BeforeRequest",
  Loading = "Loading",
  RequestError = "RequestError",
  RequestSuccess = "RequestSuccess",
}

/**
 * TODO
 * this needs feedback for the user from alster API and the alster API needs feedback from Leeway API
 * Current "request message" is not implemented for this
 *
 */
@Component({
  selector: "app-company-leeway-creator",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CreationProcessMenuComponent,
    BreadcrumpComponent,
    AdminCompanySwotEditorFormComponent,
    AdminSidePanelComponent,
    AdminFormRequestMessageComponent,
    CompanyLeewayConnectionFormComponent,
    CompanyInvestmentcaseFormComponent,
  ],
  templateUrl: "./company-leeway-creator.component.html",
  styleUrl: "./company-leeway-creator.component.scss",
})
export class CompanyLeewayCreatorComponent {
  companyDataService = inject(CompanydataService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  excelService = inject(ExcelService);
  companyTickerService = inject(CompanyTickerService);

  // this is a special case for the multi create flow

  leewayResponse = this.excelService.leewayResponse;

  constructor() {}

  onFormSubmit(formData) {
    console.log(formData);
    if (formData) {
      // Simulate a fake loading delay
      this.excelService.makeLeewayRequest(formData);
      setTimeout(() => {
        this.creationProcessMenuService.setStepCompleted("Leeway Connection");
      }, 2000);
    }
  }
}
