import { Component } from "@angular/core";

import { FileListComponent } from "../file-list/file-list.component";
import { FileUploadComponent } from "../file-upload/file-upload.component";

@Component({
  selector: "app-file-manager",
  templateUrl: "./file-manager.component.html",
  styleUrls: ["./file-manager.component.scss"],
  standalone: true,
  imports: [FileListComponent, FileUploadComponent],
})
export class FileManagerComponent {}
