import { JsonPipe } from "@angular/common";
import { Component, inject } from "@angular/core";

import { LegalDisclaimerService } from "~features/legal/legal-disclaimer.service";

@Component({
  selector: "app-legal-disclaimer-page",
  templateUrl: "./legal-disclaimer-page.component.html",
  styleUrls: ["./legal-disclaimer-page.component.scss"],
  standalone: true,
  imports: [JsonPipe],
})
export class LegalDisclaimerPageComponent {
  legalDisclaimerService = inject(LegalDisclaimerService);
  companies = this.legalDisclaimerService.getLegalDisclaimerCompanies();
}
