import { Injectable, Signal, signal } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CompanyTickerService {
  private companyTicker = signal("");

  setCompanyTicker(ticker: string) {
    this.companyTicker.set(ticker);
  }

  getCompanyTicker(): Signal<string> {
    return this.companyTicker;
  }
}
