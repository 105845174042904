import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-banner',
  standalone: true,
  imports: [],
  templateUrl: './contact-banner.component.html',
  styleUrl: './contact-banner.component.scss'
})
export class ContactBannerComponent {

}
