import {
  Component,
  computed,
  inject,
  Signal,
  signal,
  WritableSignal,
} from "@angular/core";
import { IEditorContent } from "~global-interfaces/IEditorContent";

import { CompanyValuationEditorFormComponent } from "~features/company/admin/company-valuation/company-valuation-editor-form/company-valuation-editor-form.component";
import { CompanydataService } from "~features/company/companydata.service";
import { ValuationService } from "~features/company/valuation.service";
import { CreationProcessMenuComponent } from "~features/guided-creation-flow/creation-process-menu/creation-process-menu.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_EDITORCONTENT_VALUATION } from "~shared/initial-values";

@Component({
  selector: "app-company-valuation-creator",
  standalone: true,
  imports: [
    CreationProcessMenuComponent,
    BreadcrumpComponent,
    CompanyValuationEditorFormComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./company-valuation-creator.component.html",
  styleUrl: "./company-valuation-creator.component.scss",
})
export class CompanyValuationCreatorComponent {
  companyDataService = inject(CompanydataService);
  creationProcessMenuService = inject(CreationProcessMenuService);
  currentCompanyTicker = this.creationProcessMenuService.currentCompanyTicker;
  valuationService = inject(ValuationService);

  valuation: WritableSignal<any> = signal(INITIAL_EDITORCONTENT_VALUATION);

  valuationContent: Signal<IEditorContent | null> = computed(() => {
    return {
      ...this.valuation(),
      companyTicker: this.currentCompanyTicker() || "",
    };
  });

  onFormSubmit(newData: any) {
    this.valuationService.updateValuation(newData);

    //console.log(newData);
    this.creationProcessMenuService.setStepCompleted("Valuation");
  }
}
