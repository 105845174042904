import { format, parse } from "date-fns"; //this is is not compatible with date-fns v3 yet, do we really need this? try without! old line: import { format, formatInTimeZone } from 'date-fns-tz'

import { researchHub_URL } from "~api/api-urls";

export function formatDate(date, mode) {
  if (mode === "toUrl") {
    return formatDateToString(date);
  }
  if (mode === "toValid" || mode === "toBERequest") {
    return parseDate(date);
  }
}

export function formatBloombergTicker(BLTicker, mode) {
  if (mode === "toUrl") {
    return BLTicker.replace(/:/g, "-");
  }
  if (mode === "toValid") {
    return BLTicker.replace(/-/g, ":");
  }
}

export function createEventRegistrationLink(blTicker, date) {
  const formattedDate = formatDate(date, "toUrl");
  const formattedTicker = formatBloombergTicker(blTicker, "toUrl");
  return (
    researchHub_URL +
    "/events/registration/" +
    formattedDate +
    "/" +
    formattedTicker
  );
}

function parseDate(str) {
  return parse(str, "yyyy-MM-dd-H", new Date());
}

function formatDateToString(date) {
  const tmpDate = new Date(date);
  const formattedDate = format(tmpDate, "yyyy-MM-dd-HH-mm"); // this formatString used to be "Europe/Berlin
  return formattedDate;
}

export function timeZoneFormat(datestring: string): string {
  if (!datestring) return null;
  const regex = /GMT([+-]\d{2}:?\d{2})/;
  const match = datestring.match(regex);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}

export function formatDateNow(): string {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hour = String(now.getHours()).padStart(2, "0");
  const minute = String(now.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day}-${hour}-${minute}`;
}
