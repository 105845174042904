import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IHubAccount } from "~global-interfaces/IHubAccount";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { alsterAPI_URL } from "~api/api-urls";
import { AuthService } from "~features/auth/auth.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class UserService extends PaginationService<IHubAccount> {
  authService = inject(AuthService);

  user: WritableSignal<IHubAccount> = signal<IHubAccount>({
    _id: "",
    addinIds: [],
    bloombergTicker: "",
    companyName: "",
    confirmationToken: "",
    confirmed: false,
    customerType: "",
    email: "",
    firstName: "",
    isBrevoContact: false,
    isCorporateUser: false,
    lastName: "",
    password: "",
    rights: undefined,
    type: "",
    userId: "",
    userName: "",
    userRole: "",
  });
  isLoading: WritableSignal<boolean> = signal(false);
  investorProfile: WritableSignal<any | null> = signal<any | null>(null);
  investorProfileForEditor: WritableSignal<any | null> = signal<any | null>(
    null
  );
  loadingIndicatorService = inject(LoadingIndicatorService);

  constructor(http: HttpClient) {
    super(http);
    this.fetchInvestorProfile();
  }

  getisLoading() {
    return this.isLoading;
  }

  fetchUsersList(paginationParams: IQueryParams) {
    this.isLoading.set(true);
    this.fetchItems(alsterAPI_URL + "users", paginationParams).subscribe(
      ({ items, totalCount }) => {
        this.items.set(items);
        this.totalCount.set(totalCount || 0);
        this.isLoading.set(false);
      }
    );
  }

  fetchUser(userid: string) {
    this.isLoading.set(true);
    this.user.set(null);
    this.investorProfileForEditor.set(null);
    this.http
      .get<IApiResponse<IHubAccount>>(alsterAPI_URL + "users/" + userid)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch user")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (user: IHubAccount) => {
          this.user.set(user);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch user success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  updateUser(formData) {
    this.isLoading.set(true);
    this.http
      .put<IApiResponse<IHubAccount>>(
        alsterAPI_URL + "users/edit/" + formData._id,
        formData
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "updateUser")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (user) => {
          this.user.set(user);
          this.adminRequestService.setLastRequestMessage("18", true);
          this.adminRequestService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchInvestorProfile() {
    this.isLoading.set(true);
    const userMail = this.authService.getCurrentUserEmail();
    this.http
      .get<IApiResponse<any>>(
        alsterAPI_URL + "users/investorprofile/" + userMail
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch Investor profile")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (investorProfile: any) => {
          this.investorProfile.set(investorProfile);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch investor profile success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchInvestorProfileForEditor(mail) {
    this.isLoading.set(true);
    const userMail = mail;
    this.http
      .get<IApiResponse<any>>(
        alsterAPI_URL + "users/investorprofile/" + userMail
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch Investor profile")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (investorProfile: any) => {
          this.investorProfileForEditor.set(investorProfile);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch investor profile success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  public getInvestorProfile() {
    return this.investorProfile;
  }

  public getUser() {
    return this.user;
  }
  public getUsersList() {
    return this.items;
  }
  deleteUser(dbId: string) {
    this.isLoading.set(true);
    this.http
      .delete<IApiResponse<any>>(alsterAPI_URL + "users/" + dbId)
      .subscribe({
        next: () => {
          this.adminRequestService.setLastRequestMessage("22", true);
          this.adminRequestService.setLastRequestSuccess(true);
          this.fetchUsersList(null);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }
}
