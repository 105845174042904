@if (sidePanelService.isOpen()()) {
  <aside
    class="h-full bg-white shadow-md transition-all duration-300 ease-in-out w-64 overflow-y-auto"
  >
    <div class="p-4 border-b hover:bg-gray-100">
      <h1 class="text-md font-bold text-gray-800 hover:underline">
        <a [routerLink]="'/admin/dashboard'">Admin Panel</a>
      </h1>
    </div>
    <nav class="mt-2">
      @for (content of contentTypes; track content.title) {
        <div class="px-4 py-2 hover:bg-gray-100">
          <details class="group">
            <summary
              class="flex justify-between items-center cursor-pointer list-none"
            >
              <span class="text-base text-gray-900">{{ content.title }}</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="currentColor"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>
              </span>
            </summary>
            <div class="mt-2 ml-4 space-y-2">
              <a
                [routerLink]="['/admin/dashboard/' + content.listRoute]"
                class="block text-sm text-blue-600 hover:text-blue-800 hover:underline"
              >
                {{ content.listButtonText }}
              </a>
              @if (content.createButtonText) {
                <a
                  [routerLink]="['/admin/dashboard/' + content.createRoute]"
                  class="block text-sm text-green-600 hover:text-green-800 hover:underline"
                >
                  {{ content.createButtonText }}
                </a>
              }
            </div>
          </details>
        </div>
      }
      <div class="w-full">
        <hr />
      </div>

      <div class="px-4 mt-4 py-2 hover:bg-gray-100">
        <button
          (click)="sidePanelService.toggle()"
          class="text-gray-muted w-full"
        >
          hide panel
        </button>
      </div>
      <div
        class="mt-4 mx-4 p-4 bg-purple-100 rounded-lg"
        appAdminInfoHasSideeffect
        [hasSideeffect]="true"
        [inputName]="'sideeffectinfo'"
      >
        <p>All Inputs with this color have more complex sideeffects!</p>
      </div>

      <app-admin-info-sideeffects-toast></app-admin-info-sideeffects-toast>

      <div class="w-full mt-16">
        <hr />
      </div>
      <div class="px-4 py-2 hover:bg-gray-100 text-gray-muted">
        <div>
          <details class="group">
            <summary
              class="flex justify-between items-center cursor-pointer list-none"
            >
              <span class="text-base">Dev Tools</span>
              <span class="transition group-open:rotate-180">
                <svg
                  fill="currentColor"
                  height="24"
                  width="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7 10l5 5 5-5z"></path>
                </svg>
              </span>
            </summary>
            <div>
              <ul>
                <li>
                  <a
                    [routerLink]="['/company/creator/']"
                    class="block text-sm text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    create company without dependencies
                  </a>
                </li>
                <li>
                  <a
                    [routerLink]="['/admin/dashboard/frontend-config/']"
                    class="block text-sm text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    list frontend config
                  </a>
                </li>
                <li>
                  <a
                    [routerLink]="['/admin/dashboard/frontend-config/edit']"
                    class="block text-sm text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    edit frontend config
                  </a>
                </li>
                <li>
                  <a
                    [routerLink]="['/admin/dashboard/frontend-config/creator']"
                    class="block text-sm text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    create frontend config
                  </a>
                </li>
                <li class="px-4 py-2 hover:bg-gray-100">
                  <button
                    (click)="creationProcessMenuService.toggleGuidedMenu()"
                    class="text-gray-muted w-full"
                  >
                    company creation guide
                  </button>
                </li>
                <li class="px-4 py-2 hover:bg-gray-100">
                  <button
                    (click)="researchCreationProcessService.toggleGuidedMenu()"
                    class="text-gray-muted w-full"
                  >
                    research upload guide
                  </button>
                </li>
              </ul>
            </div>
          </details>
        </div>
      </div>
    </nav>
  </aside>
} @else {
  <div class="fixed left-0 top-[8%] transform -translate-y-1/2 z-10">
    <button
      (click)="sidePanelService.toggle()"
      class="bg-white p-2 rounded-r-md shadow-md text-gray-500 hover:text-gray-700 focus:outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    </button>
  </div>
}
