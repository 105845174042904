import { NgClass } from "@angular/common";
import { Component, inject, Input } from "@angular/core";

import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";

@Component({
  selector: "app-admin-form-button",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./admin-form-button.component.html",
  styleUrl: "./admin-form-button.component.scss",
})
export class AdminFormButtonComponent {
  loadingIndicatorService = inject(LoadingIndicatorService);
  isLoading = this.loadingIndicatorService.isLoading;
  @Input() disabled = false;
  @Input() class = "submit";
  @Input() overwriteText: string | null = null;
}
