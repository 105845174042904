@if (events()) {
  <div class="grid gap-4">
    @for (event of events(); track event) {
      <app-customer-event-tile
        [event]="event"
        [listType]="listType"
        (eventRemoved)="onEventRemoved($event)"
      ></app-customer-event-tile>
    }
  </div>
}
