<div
  (click)="handleClick(tileHeadline())"
  [ngClass]="{
    'cursor-pointer': targets[tileHeadline()]
  }"
  [ngStyle]="{ 'background-color': bgColor }"
  class="tile relative w-full flex flex-col p-4 {{ getTileHeight() }}"
>
  <div class="w-full mb-2">
    <p
      [ngStyle]="{ color: color }"
      class="font-medium uppercase text-base tracking-special leading-[0.6em]"
    >
      {{ tileHeadline() }}
      @if (tile().type === "figure-slider" || tile().type === "vertical-bars") {
        <span class="ml-2">
          {{ year() }}{{ year() >= baseyear() ? "E" : "" }}
        </span>
      }
    </p>
  </div>
  @if (targets[tileHeadline()]) {
    <div class="absolute top-2 right-2">
      <svg
        class="w-4 h-4"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.75064 15L12.4745 4.28157L12.5989 4.39755V12.2068H15V0H2.79337V2.41624H10.6186L10.7334 2.53222L0 13.241L1.75064 15Z"
          fill="#0F6BB2"
        />
      </svg>
    </div>
  }
  <div class="flex-grow">
    @if (tile().type === "figure") {
      <app-figure
        class="w-full h-full"
        [companyData]="companyData"
        [baseyear]="baseyear"
        [tile]="tile"
        (tileEvent)="onTileEvent($event)"
      ></app-figure>
    }
    @if (tile().type === "figure-slider") {
      <app-figure-slider
        class="w-full h-full"
        [companyData]="companyData"
        [baseyear]="baseyear"
        [tile]="tile"
        (tileEvent)="onTileEvent($event)"
      ></app-figure-slider>
    }
    @if (tile().type === "figure-graph") {
      <app-figure-graph
        class="w-full h-full"
        [companyData]="companyData"
        [baseyear]="baseyear"
        [config]="tile"
      ></app-figure-graph>
    }
    @if (tile().type === "vertical-bars") {
      <app-vertical-bars
        class="w-full h-full"
        [companyData]="companyData"
        [baseyear]="baseyear"
        [config]="tile"
        (tileEvent)="onTileEvent($event)"
      ></app-vertical-bars>
    }
    @if (tile().type === "table-change") {
      <app-table-change
        class="w-full h-full"
        [companyData]="companyData"
        [baseyear]="baseyear"
        [tile]="tile"
        (tileEvent)="onTileEvent($event)"
      ></app-table-change>
    }
    @if (tile().type === "template-chart") {
      <app-template-chart
        class="w-full h-full"
        [companyData]="companyData"
        [baseyear]="baseyear"
        [config]="tile"
        (tileEvent)="onTileEvent($event)"
      ></app-template-chart>
    }
  </div>
</div>
