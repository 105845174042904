import { Component, inject } from "@angular/core";
import { IFrontendConfig } from "~global-interfaces/IFrontendConfig";

import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { TransactionActivationFormComponent } from "~features/transactions/admin/transaction-activation-form/transaction-activation-form.component";
import { TransactionService } from "~features/transactions/transaction.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-transaction-activation",
  standalone: true,
  imports: [
    AdminSidePanelComponent,
    BreadcrumpComponent,
    TransactionActivationFormComponent,
  ],
  templateUrl: "./transaction-activation.component.html",
  styleUrl: "./transaction-activation.component.scss",
})
export class TransactionActivationComponent {
  transactionService = inject(TransactionService);
  private frontEndConfigService = inject(FrontendConfigService);
  frontendConfig = this.frontEndConfigService.getFrontendConfig();

  onFormSubmit(newData: IFrontendConfig) {
    //console.log(newData);
    this.frontEndConfigService.updateFrontEndConfig(newData);
  }
}
