<div class="container mx-auto mt-8">
  <div class="bg-white rounded-lg shadow-md">
    <div class="px-6 py-4 bg-gray-alster text-white rounded-t-lg">
      <h3 class="text-lg font-bold">Profile</h3>
    </div>
    @if (profileData()) {
      <div class="p-6">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="md:col-span-1">
            <p class="mb-4">
              <strong class="font-semibold">E-Mail:</strong> {{ userMail() }}
            </p>
            @for (item of baseInfo(); track item) {
              <p class="mb-4">
                <strong class="font-semibold"
                  >{{ item.key | profileAttributeTranslator }}:</strong
                >
                {{ item.value }}
              </p>
            }
          </div>
          <div class="md:col-span-2 md:border-l md:border-gray-300 md:pl-8">
            @for (item of profileInfo(); track item) {
              <p class="mb-4">
                <strong class="font-semibold"
                  >{{ item.key | profileAttributeTranslator }}:</strong
                >
                {{ item.value }}
              </p>
            }
          </div>
        </div>
      </div>
    }
    <div
      class="px-6 py-4 bg-gray-100 rounded-b-lg flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0"
    >
      <button
        (click)="openModal()"
        class="px-4 py-2 bg-blue-research text-white uppercase tracking-widest hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Update Profile
      </button>
      <a [routerLink]="['/requestnewpassword']">
        <button
          class="px-4 py-2 bg-blue-research text-white uppercase tracking-widest hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Change your Password
        </button>
      </a>
    </div>
  </div>
</div>
