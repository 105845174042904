import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";

/**
 *
 * 20/03/2024
 * 2024-03-20
 * 08.05.2024
 * Jul 05, 2024
 * Jun 11, 2024, 14:00
 *
 */

type FormatType =
  | "dd/MM/yyyy"
  | "yyyy-MM-dd"
  | "dd.MM.yyyy"
  | "MMM dd, yyyy"
  | "MMM dd, y, HH:mm"
  | "MMM dd, yyyy, HH:mm";

@Component({
  selector: "app-date",
  standalone: true,
  imports: [DatePipe],
  templateUrl: "./date.component.html",
  styleUrl: "./date.component.scss",
})
export class DateComponent {
  @Input() date: Date | string;
  @Input() formatType: FormatType;
}
