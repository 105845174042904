<div class="bg-white border rounded-lg shadow-lg overflow-hidden">
  <h5 class="p-4 font-semibold bg-gray-50 border-b">
    Currently highlighted companies
  </h5>
  <p class="p-4 text-gray-muted">
    List of highlights is only refreshed on page reload.
  </p>
  <div class="overflow-x-auto">
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Company Name
          </th>
          <th
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Featured Status
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        @for (company of companyList(); track company) {
          <tr>
            <td class="px-6 py-4 whitespace-nowrap">{{ company.name }}</td>
            <td class="px-6 py-4 whitespace-nowrap">
              <span
                class="px-2 inline-flex text-xs font-semibold"
                [ngClass]="{
                  'bg-green-100 text-green-800':
                    company.isFeatured === 'Initiation',
                  'bg-yellow-100 text-yellow-800':
                    company.isFeatured === 'Update',
                  'bg-red-100 text-red-800':
                    company.isFeatured === 'Transaction'
                }"
              >
                {{ company.isFeatured }}
              </span>
              @if (
                company.isFeatured === "Initiation" ||
                company.isFeatured === "Transaction"
              ) {
                <span class="ml-2">
                  <a
                    [routerLink]="[
                      '/admin/dashboard/company/settings/',
                      company.companyTicker
                    ]"
                    ><button
                      class="text-xs text-gray-muted hover:underline border p-2 rounded"
                    >
                      edit
                    </button></a
                  >
                </span>
              }
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>
