<footer class="bg-[#edf2f7] py-8">
  <div class="container mx-auto px-4 lg:px-16">
    <div class="grid grid-cols-1 lg:grid-cols-12 gap-2">
      <!-- Left column: ResearchHub logo and Leeway info -->
      <div class="col-span-3 flex flex-col items-center lg:items-start">
        <a class="inline-block mb-4" routerLink="/">
          <app-logo
            [alt]="'logo image mwb research'"
            [logoClass]="'h-8'"
            [src]="'assets/ResearchHubLogo.svg'"
            [wrapperClass]="'w-44'"
          >
          </app-logo>
        </a>
        <div class="mt-6">
          <div class="text-xxxs text-center text-gray-500">
            Portal solution,<br />
            price and ESG data by
          </div>
          <a class="block mt-2 w-32 mx-auto lg:mx-0" href="https://leeway.tech">
            <app-logo
              [alt]="'logo leeway'"
              [logoClass]="''"
              [src]="
                'https://leeway.tech/assets/logo/Leeway_Logo_ohne_Icon_2020-04_RGB.svg'
              "
              [wrapperClass]="'w-32'"
            >
            </app-logo>
          </a>
        </div>
      </div>

      <!-- Middle column: Description and three logos -->
      <div class="col-span-6 flex flex-col items-center">
        <p class="text-sm mb-6">
          Research<span class="font-bold">Hub</span> is the place for
          professional investors to get regularly-updated high-quality research
          on German and selected European stocks.
        </p>
        <div class="grid grid-cols-1 sm:grid-cols-3 gap-1 w-full">
          <div class="text-center">
            <div class="text-xxxs text-gray-500">Stock chart by</div>
            <a
              class="block mt-2 w-16 mx-auto"
              href="https://www.tradingview.com"
            >
              <app-logo
                [alt]="'tv logo'"
                [logoClass]="''"
                [src]="
                  'https://avatars.githubusercontent.com/u/7644688?s=400&v=4'
                "
                [wrapperClass]="'w-16'"
              >
              </app-logo>
            </a>
          </div>
          <div class="text-center">
            <div class="text-xxxs text-gray-500">
              Deutsche Börse Capital Market Partner
            </div>
            <a
              class="block mt-2 w-24 mx-auto"
              href="https://www.deutsche-boerse-cash-market.com/dbcm-de/primary-market/capital-market-partner"
            >
              <app-logo
                [alt]="'Capital_Market_Partner'"
                [logoClass]="''"
                [src]="
                  'https://downloads.research-hub.de/Capital_Market_Partner_Signet_sm.jpg'
                "
                [wrapperClass]="'w-24'"
              >
              </app-logo>
            </a>
          </div>
          <div class="text-center">
            <div class="text-xxxs text-gray-500">Supported by</div>
            <a
              class="block mt-2 w-[6.5rem] mx-auto"
              href="https://www.ifbhh.de/foerderprogramm/hamburg-digital"
            >
              <app-logo
                [alt]="'hamburg_digital'"
                [logoClass]="'mx-auto'"
                [src]="'https://downloads.research-hub.de/hamburg_digital.png'"
                [wrapperClass]="'w-[6.5rem]'"
              >
              </app-logo>
            </a>
          </div>
        </div>
      </div>

      <!-- Right column: Links -->
      <div class="col-span-3 flex justify-center lg:justify-end">
        <ul class="text-xs text-center lg:text-right">
          <li>
            <a routerLink="/datenschutz">Data privacy</a>
          </li>
          @if (isCookiesAccepted()) {
            <li>
              <button (click)="revokeCookieConsent()">
                Revoke Cookie Consent
              </button>
            </li>
          }
          <li>
            <a routerLink="/impressum">Imprint</a>
          </li>
          <li>
            <a routerLink="/mifid2">MiFID II</a>
          </li>
          <li>
            <a href="https://www.mwb-research.com/">Contact</a>
          </li>
          <li>
            <a routerLink="/disclaimer">Disclaimer</a>
          </li>
          <li class="text-gray-500">&copy; mwb research</li>
          <li>
            <small>
              @if (isAuthenticated()) {
                <button
                  class="uppercase leading-none"
                  (click)="openLoginDialog()"
                >
                  log out {{ currentUserName || "" }}
                </button>
              }
            </small>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
