<div class="container mx-auto px-16 pt-12">
  <section class="px-5">
    <div class="max-w-screen-xl mx-auto">
      <div class="text-center p-5">
        <h2 class="p-4 hidden md:block">
          Find and compare<br /><span class="text-blue-500"
            >interesting companies</span
          >
        </h2>
        <h4 class="p-4 block md:hidden">
          Find and compare<br /><span class="text-blue-500"
            >interesting companies</span
          >
        </h4>
        <p class="text-lg px-4 font-bold">
          Select your own search criteria to find what you are looking for in
          our coverage.
        </p>
      </div>
    </div>
  </section>
  <section class="px-5">
    <div class="max-w-screen-xl mx-auto">
      <div class="p-5">
        <app-company-screener [noblocktitle]="true"></app-company-screener>
      </div>
    </div>
  </section>
  <div class="my-5"></div>

  <hr />
  <!-- subscibe banner-->

  <section class="bg-white py-8 lg:py-12">
    <h3 class="container mx-auto p-4 max-w-screen-2xl">
      Other interesting companies
    </h3>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-company-grid></app-company-grid>
    </div>
  </section>

  <section class="bg-white py-8 lg:py-12">
    <app-subscribe-banner class="w-full"></app-subscribe-banner>
  </section>
</div>
