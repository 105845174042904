<div class="container mx-auto">
  <div class="flex flex-wrap">
    <div class="w-full">
      <div
        class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 text-center mb-5"
        role="alert"
      >
        <h3 class="text-2xl font-bold mb-4">No event found.</h3>
        <h5 class="text-xl">
          See all future and past events
          <a
            routerLink="/events"
            target="_blank"
            class="text-blue-600 underline"
            >here</a
          >
        </h5>
      </div>
    </div>
  </div>
</div>
