import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-signup-page-complete",
  templateUrl: "./signup-complete.component.html",
  styleUrls: ["./signup-complete.component.scss"],
  standalone: true,
  imports: [RouterLink],
})
export class SignupCompleteComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
