<div class="flex flex-col min-h-screen">
  <section
    class="flex justify-center bg-blue-100 sticky z-20 top-[50px] lg:top-[56px] border-b border-gray-200 h-28 leading-none"
  >
    <div class="grid lg:grid-cols-12 grid-rows-1 w-11/12 gap-0">
      <div
        class="col-span-8 lg:col-span-6 flex items-center font-semibold text-lg lg:text-xl"
      >
        {{ companyName() }}
      </div>
      <div
        class="hidden col-span-4 lg:col-span-6 lg:flex lg:justify-end items-center"
      >
        <div
          class="  rounded-md tracking-widest leading-none text-lg lg:text-xl font-semibold {{
            getTextColor(companyNumbers()?.general_information.cus_rating)
          }}"
        >
          {{ companyNumbers()?.general_information.cus_rating }}
        </div>
      </div>
      <div
        class="col-span-12 lg:col-span-6 lg:flex lg:items-center text-gray-muted uppercase tracking-widest lg:pb-2 grid grid-cols-3 gap-x-12"
      >
        <div
          class="col-span-1 flex items-center text-gray-500 uppercase tracking-widest"
        >
          {{ companyNumbers()?.base?.sector }}
        </div>

        <div
          class="col-span-1 flex items-center text-gray-muted uppercase tracking-widest"
        >
          @if (companyLiveQuotes()?.close !== "NA") {
            Last:&nbsp;
            <span>
              {{
                companyNumbers()?.base?.quote_curr ||
                  companyNumbers()?.base?.curr
              }}
              {{ companyLiveQuotes()?.close | number: "1.2-2" }}
            </span>
          }
        </div>
        <div
          class="col-span-1 flex items-center  {{
            getNumberColor(companyLiveQuotes()?.change_p) || 'text-gray-muted'
          }}"
        >
          @if (companyLiveQuotes()?.change_p !== "NA") {
            @if (companyLiveQuotes()?.change_p < 0) {
              <i class="fas fa-arrow-down mr-1"></i>
            } @else {
              <i class="fas fa-arrow-up mr-1"></i>
            }

            <span>{{ companyLiveQuotes()?.change_p | number: "1.1-1" }}%</span>
          }
        </div>
      </div>

      <div class="col-span-12 lg:col-span-2"></div>
      <div
        class="col-span-12 lg:col-span-4 grid grid-cols-1 lg:grid-cols-2 gap-2 flex justify-end pb-2"
      >
        <div
          class="hidden col-span-1 lg:flex justify-end items-center text-gray-muted uppercase tracking-widest"
        >
          Price target:&nbsp;
          <span>
            {{
              companyNumbers()?.base?.quote_curr || companyNumbers()?.base?.curr
            }}
            {{
              companyNumbers()?.general_information?.cus_pt | number: "1.2-2"
            }}</span
          >
        </div>
        <div
          class="hidden col-span-1 lg:flex lg:justify-end lg:items-center uppercase tracking-widest"
        >
          <span class="text-gray-muted"> Up-/Downside:&nbsp;</span>
          <span
            class="{{
              getTextColor(companyNumbers()?.general_information.cus_rating) ||
                'text-gray-500'
            }} "
            >{{
              companyNumbers()?.financial_figures?.ratios?.cus_pt_range * 100
                | number: "1.1-1"
            }}
            %</span
          >
        </div>
      </div>
    </div>
  </section>
  <section class="bg-gray-200 sticky z-20 top-[160px] lg:top-[168px] py-2">
    <div class="flex justify-center mx-auto">
      <div class="grid grid-cols-3 lg:grid-cols-10 grid-rows-1 w-11/12">
        <div
          class="col-span-1 row-span-1 text-left text-blue-500 flex items-center justify-start"
        >
          <span
            class="hover:border-b-2 hover:border-blue-500 text-xs tracking-widest"
          >
            <a [routerLink]="['../', previousCompany().name]">
              <div class="flex items-center">
                <i class="fas fa-arrow-left mr-1"></i>
                <span class="truncate max-w-[100px] inline-block">{{
                  previousCompany().name
                }}</span>
              </div>
            </a>
          </span>
        </div>
        <div class="col-span-1 lg:col-span-8 row-span-1 text-center">
          <app-horizontal-nav-panel
            class="hidden lg:block"
          ></app-horizontal-nav-panel>
        </div>

        <div
          class="col-span-1 row-span-1 text-right text-blue-500 flex items-center justify-end"
        >
          <span
            class="hover:border-b-2 hover:border-blue-500 text-xs tracking-widest"
          >
            <a [routerLink]="['../', nextCompany().name]">
              <div class="flex items-center justify-end">
                <span class="truncate max-w-[100px] inline-block">{{
                  nextCompany().name
                }}</span>
                <i class="fas fa-arrow-right ml-1"></i>
              </div>
            </a>
          </span>
        </div>
      </div>
    </div>
  </section>
  <section #overview class="py-8 lg:mt-0" id="overview">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center">
          <div
            class="grid grid-cols-1 md:grid-cols-3 md:grid-rows-4 gap-4 w-11/12"
          >
            <!-- Tile 1: 2x2 on desktop, full width on mobile -->

            <div
              [style.background-image]="
                'url(' + encodeURI(companySettings().imageURL) + ')'
              "
              class="md:col-span-2 md:row-span-2 text-[#edf2f7] bg-cover bg-center max-h-56 lg:max-h-none relative shadow-[4px_4px_15px_rgba(0,0,0,0.1882352941)] rounded-[20px] overflow-hidden"
            >
              <div
                class="hidden lg:block absolute bottom-0 left-0 right-0 p-4 z-10"
              >
                <div
                  class="grid grid-cols-3 gap-4 mx-auto leading-tight font-black"
                >
                  <div class="col-span-1 flex justify-center">
                    <a
                      href="{{ currentInitiationNote()?.pdf_DE?.downloadURL }}"
                      target="_blank"
                    >
                      <button
                        class="bg-white text-gray-800 px-4 py-2 text-md rounded-[20px] mx-auto hover:underline"
                      >
                        Initiation Note (PDF)
                      </button>
                    </a>
                  </div>

                  <div class="col-span-1 flex justify-center">
                    @if (
                      companySettings().presentationURL &&
                      companySettings().presentationURL !== "placeholder"
                    ) {
                      <a
                        href="{{ companySettings().presentationURL }}"
                        target="_blank"
                      >
                        <button
                          class="bg-white text-gray-800 px-4 py-2 text-md rounded-[20px] mx-auto hover:underline"
                        >
                          Company Presentation (PDF)
                        </button>
                      </a>
                    }
                  </div>
                  <div class="col-span-1 flex justify-center">
                    @if (investorRelationsURL()) {
                      <a href="{{ investorRelationsURL() }}" target="_blank">
                        <button
                          class="bg-white text-gray-800 px-4 py-2 text-md rounded-[20px] mx-auto hover:underline"
                        >
                          Investor Relations
                        </button>
                      </a>
                    }
                  </div>
                </div>
              </div>

              <div
                class="relative lg:absolute inset-0 p-8 opacity-0 hover:opacity-100 transition-opacity duration-300 bg-gray-alster bg-opacity-90"
              >
                <div
                  class="text-xs lg:text-md font-medium lg:leading-8 text-white lg:p-16"
                >
                  {{ companyNumbers()?.general_information.description }}
                </div>
              </div>
            </div>

            <div class="lg:hidden">
              <div class="grid grid-cols-2 gap-4 mx-auto">
                <div class="col-span-1">
                  <a
                    class="w-full"
                    href="{{ currentInitiationNote()?.pdf_DE?.downloadURL }}"
                    target="_blank"
                  >
                    <button
                      class="bg-gray-alster text-white px-4 py-2 text-sm rounded-[20px] mx-auto hover:underline w-full"
                    >
                      Initiation Note
                    </button>
                  </a>
                </div>

                <div class="col-span-1">
                  @if (investorRelationsURL()) {
                    <a
                      href="{{ investorRelationsURL() }}"
                      target="_blank"
                      class="w-full"
                    >
                      <button
                        class="bg-gray-alster text-white px-4 py-2 text-sm rounded-[20px] mx-auto hover:underline w-full"
                      >
                        Investor Relations
                      </button>
                    </a>
                  }
                </div>
              </div>
            </div>

            <!-- Tile 2: 1x1 on desktop, full width on mobile -->
            <div
              (click)="openResearchList()"
              class="md:col-span-1 md:row-span-1 kachel kachel-bg-research kachel-aspect-wide kachel-interactive"
            >
              <div class="kachel-title">Research</div>
              <div class="kachel-icon">
                <app-arrow-icon></app-arrow-icon>
              </div>
              <div class="kachel-content">
                <div class="grid grid-cols-4">
                  <div class="hidden lg:block col-span-2">
                    <svg
                      fill="none"
                      height="51"
                      viewBox="0 0 49 51"
                      width="49"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M42.6552 28.3719C41.666 28.3719 40.8621 29.1973 40.8621 30.213V38.805C40.8621 39.8218 40.0593 40.6461 39.069 40.6461C38.0787 40.6461 37.2759 39.8218 37.2759 38.805V30.213C37.2759 27.1636 39.6853 24.6896 42.6552 24.6896C45.6251 24.6896 48.0346 27.1636 48.0346 30.213V41.2598C48.0346 46.3443 44.0209 50.4655 39.069 50.4655C34.1172 50.4655 30.1035 46.3443 30.1035 41.2598V33.8953C30.1035 32.8785 30.9063 32.0542 31.8966 32.0542C32.8869 32.0542 33.6897 32.8785 33.6897 33.8953V41.2598C33.6897 44.3107 36.0978 46.7832 39.069 46.7832C42.0403 46.7832 44.4483 44.3107 44.4483 41.2598V30.213C44.4483 29.1973 43.6445 28.3719 42.6552 28.3719Z"
                        fill="#FCFCFC"
                        fill-rule="evenodd"
                      />
                      <path
                        clip-rule="evenodd"
                        d="M9.93115 33.6552C9.93115 32.4173 10.7522 31.4138 11.765 31.4138H21.5456C22.5584 31.4138 23.3794 32.4173 23.3794 33.6552C23.3794 34.8931 22.5584 35.8966 21.5456 35.8966H11.765C10.7522 35.8966 9.93115 34.8931 9.93115 33.6552Z"
                        fill="#FCFCFC"
                        fill-rule="evenodd"
                      />
                      <path
                        clip-rule="evenodd"
                        d="M9.93115 24.1293C9.93115 23.2009 10.7723 22.4483 11.81 22.4483H29.3455C30.3831 22.4483 31.2243 23.2009 31.2243 24.1293C31.2243 25.0577 30.3831 25.8104 29.3455 25.8104H11.81C10.7723 25.8104 9.93115 25.0577 9.93115 24.1293Z"
                        fill="#FCFCFC"
                        fill-rule="evenodd"
                      />
                      <path
                        clip-rule="evenodd"
                        d="M9.93115 14.0431C9.93115 13.1147 10.7723 12.3621 11.81 12.3621H29.3455C30.3831 12.3621 31.2243 13.1147 31.2243 14.0431C31.2243 14.9715 30.3831 15.7241 29.3455 15.7241H11.81C10.7723 15.7241 9.93115 14.9715 9.93115 14.0431Z"
                        fill="#FCFCFC"
                        fill-rule="evenodd"
                      />
                      <path
                        clip-rule="evenodd"
                        d="M0.965576 6.83046C0.965576 3.07608 3.97502 0.0344849 7.68971 0.0344849H34.5863C38.301 0.0344849 41.3104 3.07608 41.3104 6.83046V16.7155C41.3104 17.7392 40.4894 18.569 39.4765 18.569C38.4637 18.569 37.6427 17.7392 37.6427 16.7155V6.83046C37.6427 5.12335 36.2753 3.74138 34.5863 3.74138H7.68971C6.00064 3.74138 4.63329 5.12335 4.63329 6.83046V41.4282C4.63329 43.1353 6.00064 44.5172 7.68971 44.5172H22.3606C23.3734 44.5172 24.1944 45.3471 24.1944 46.3707C24.1944 47.3943 23.3734 48.2241 22.3606 48.2241H7.68971C3.97502 48.2241 0.965576 45.1825 0.965576 41.4282V6.83046Z"
                        fill="#FCFCFC"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <div class="col-span-4 lg:col-span-2 text-left">
                    <div>
                      Last Update:
                      <app-date
                        [date]="researchArticle().research_date"
                        [formatType]="'dd/MM/yyyy'"
                      ></app-date>
                    </div>
                    <div>mwb research</div>
                    <div
                      class="text-gray-muted-light line-clamp-2 leading-none"
                    >
                      {{ researchArticle().title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Tile 3: 1x1 on desktop, full width on mobile -->

            <div
              [style.background-color]="
                companyNumbers()?.general_information.cus_rating === 'BUY'
                  ? '#ECFDF5'
                  : companyNumbers()?.general_information.cus_rating ===
                      'Spec. BUY'
                    ? '#ECFDF5'
                    : companyNumbers()?.general_information.cus_rating ===
                        'HOLD'
                      ? '#FFFBEB'
                      : companyNumbers()?.general_information.cus_rating ===
                          'SELL'
                        ? '#FEF2F2'
                        : '#F1F5F9'
              "
              class="md:col-span-1 md:row-span-1 kachel kachel-aspect-wide text-[#6c757d]"
            >
              <div class="kachel-title text-[#6c757d]">Rating</div>
              <div class="kachel-icon"></div>
              <div class="kachel-content">
                <div class="grid grid-cols-4">
                  <div
                    class="col-span-2 leading-none text-[3em] {{
                      getTextColor(
                        companyNumbers()?.general_information.cus_rating
                      )
                    }}"
                  >
                    {{ companyNumbers()?.general_information.cus_rating }}
                  </div>
                  <div
                    class="col-span-2 flex flex-col justify-end text-gray-muted leading-none pb-2"
                  >
                    <div>
                      Price Target:
                      {{
                        companyNumbers()?.base?.quote_curr ||
                          companyNumbers()?.base?.curr
                      }}
                      {{
                        companyNumbers()?.general_information?.cus_pt
                          | number: "1.2-2"
                      }}
                    </div>
                    <div>
                      Up-/Downside:
                      <span
                        class="{{
                          getNumberColor(
                            companyNumbers()?.financial_figures?.ratios
                              ?.cus_pt_range * 100
                          )
                        }}"
                      >
                        {{
                          companyNumbers()?.financial_figures?.ratios
                            ?.cus_pt_range * 100 | number: "1.1-1"
                        }}%</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Tile 4: 1x2 on desktop, full width on mobile -->
            <div
              (click)="openSharePrice()"
              class="md:col-span-1 md:row-span-2 kachel kachel-aspect-square kachel-interactive"
            >
              <div class="kachel-title">Share Price</div>
              <div class="kachel-icon">
                <app-arrow-icon [color]="'darkblue'"></app-arrow-icon>
              </div>

              <div class="kachel-content h-5/6">
                @if (companyTicker() && companyHistoricalQuotes()) {
                  <div class="grid grid-cols-3 gap-4 h-2/6">
                    <div class="col-span-2 leading-none">
                      <div class="text-md">
                        {{
                          companyNumbers()?.base?.quote_curr ||
                            companyNumbers()?.base?.curr
                        }}
                      </div>

                      <div class="text-xl">
                        {{
                          companyHistoricalQuotes()[
                            companyHistoricalQuotes()?.length - 1
                          ]?.adjusted_close | number: "1.2-2"
                        }}
                      </div>
                    </div>
                    <div class="col-span-1">
                      <div
                        class="grid col-span-1 row-span-3 text-right text-base font-medium leading-[22.23px]"
                      >
                        <div class="w-full flex justify-between">
                          <span>7D</span>
                          <span
                            class="font-medium {{
                              getNumberColor(weeklyUpDown(), true)
                            }}"
                            >{{ weeklyUpDown() | number: "1.1-1" }} %</span
                          >
                        </div>
                        <div class="w-full flex justify-between">
                          <span>30D</span>
                          <span
                            class="font-medium {{
                              getNumberColor(monthlyUpDown(), true)
                            }}"
                            >{{ monthlyUpDown() | number: "1.1-1" }} %</span
                          >
                        </div>
                        <div class="w-full flex justify-between">
                          <span>12M</span>
                          <span
                            class="font-medium {{
                              getNumberColor(yearlyUpDown(), true)
                            }}"
                            >{{ yearlyUpDown() | number: "1.1-1" }} %</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div
                  #tvShareChart
                  class="h-4/6 w-full"
                  id="tvShareChart-{{ chartID }}"
                ></div>
              </div>
            </div>

            <!-- Tile 5: 1x1 on desktop, full width on mobile -->
            <div class="md:col-span-1 md:row-span-1 kachel kachel-aspect-wide">
              <div class="flex flex-col h-full">
                <div class="flex justify-between items-center text-[#0F6BB2]">
                  <div class="kachel-title">Company Info</div>
                  <div class="flex">
                    <div
                      (click)="companyInfoSliderPrevSlide()"
                      class="mr-12 text-md"
                    >
                      <i class="fas fa-arrow-left"></i>
                    </div>
                    <div (click)="companyInfoSliderNextSlide()" class="text-md">
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </div>
                </div>
                <div class="flex-grow">
                  <app-companyinfo-slider
                    [baseyear]="baseyear"
                    [companyNumbers]="companyNumbers"
                    [mapping]="excelMapping"
                    [sliderIndex]="companyInfoSliderIndex"
                  ></app-companyinfo-slider>
                </div>
              </div>
            </div>
            <!-- Tile 6: 1x1 on desktop, full width on mobile -->
            <div
              (click)="openShareHolders()"
              class="md:col-span-1 md:row-span-1 kachel kachel-aspect-wide kachel-interactive"
            >
              <div class="kachel-title">Shareholders</div>
              <div class="kachel-icon">
                <app-arrow-icon [color]="'darkblue'"></app-arrow-icon>
              </div>
              <div class="kachel-content">
                <div class="min-h-20 min-w-80">
                  <app-shareholders-barchart
                    [companyData]="companyNumbers()"
                  ></app-shareholders-barchart>
                </div>
              </div>
            </div>

            <!-- Tile 7: 1x1 on desktop, full width on mobile -->
            <div
              class="md:col-span-1 md:row-span-1 kachel kachel-aspect-wide kachel-interactive"
            >
              <div class="kachel-title">Company Video</div>
              <div class="kachel-icon">
                <app-arrow-icon [color]="'darkblue'"></app-arrow-icon>
              </div>
              <div class="kachel-content h-4/6">
                <div class="grid grid-cols-4 h-full w-full">
                  @if (lastCompanyEvent()?.title) {
                    <div class="col-span-2 content-center">
                      {{ lastCompanyEvent()?.title }}
                      <br />
                      <app-date
                        [date]="lastCompanyEvent()?.date"
                        [formatType]="'MMM dd, yyyy, HH:mm'"
                      >
                      </app-date>
                    </div>
                  }
                  @if (!lastCompanyEvent()?.title) {
                    <div class="col-span-1"></div>
                  }
                  <div class="col-span-2 center-items">
                    <div
                      class="h-full w-full bg-yellow-full hover:cursor-pointer text-black"
                    >
                      <app-video-player
                        [openInDialog]="true"
                        [videoURL]="
                          lastCompanyEvent()?.videoURL ||
                          companySettings()?.companyVideoURL
                        "
                      >
                      </app-video-player>
                    </div>
                  </div>
                  @if (!lastCompanyEvent()?.title) {
                    <div class="col-span-1"></div>
                  }
                </div>
              </div>
            </div>

            <!-- Tile 8: 1x1 on desktop, full width on mobile -->
            <div
              (click)="openBookAMeetingComponent()"
              class="md:col-span-1 md:row-span-1 kachel kachel-aspect-wide kachel-interactive"
            >
              <div class="kachel-title">Book a meeting</div>
              <div class="kachel-icon">
                <app-arrow-icon [color]="'darkblue'"></app-arrow-icon>
              </div>
              <div class="kachel-content h-4/6">
                <div class="grid grid-cols-3 gap-4 h-full w-full">
                  <div
                    class="col-span-1 mix-blend-multiply object-contain object-left-top h-full w-full content-center"
                  >
                    <app-logo
                      [alt]="'companylogo'"
                      [logoClass]="'mx-auto mix-blend-darken'"
                      [src]="companySettings().logoURL"
                      [wrapperClass]="'mx-auto'"
                    >
                    </app-logo>
                  </div>
                  <div
                    class="col-span-2 content-center font-normal text-base leading-5"
                  >
                    Click here to request a meeting with a representative of
                    {{ companyName() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section #research class="bg-gray-one py-8" id="research">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center bg-gray-one">
          <div class="w-11/12" id="research">
            <h3 class="chapter-headline">Research</h3>
            <div class="grid grid-cols-6 gap-4">
              <div class="col-span-3">
                <app-date
                  [date]="researchArticle().research_date"
                  [formatType]="'dd/MM/yyyy'"
                  class="font-extrabold"
                ></app-date>
              </div>
              <div
                [ngClass]="{
                  'col-span-3':
                    companySettings()?.research_settings?.currentTemplate ===
                    'none',
                  'col-span-1':
                    companySettings()?.research_settings?.currentTemplate !==
                    'none'
                }"
                class="items-end"
              >
                <app-button-download-pdf
                  [buttonClasses]="'bg-blue-500 btn text-smd float-right'"
                  [pdf]="researchArticle()?.pdf_DE"
                ></app-button-download-pdf>
              </div>
              <div class="col-span-2"></div>
            </div>

            <div
              [ngClass]="{
                'md:grid-cols-1':
                  companySettings()?.research_settings?.currentTemplate ===
                  'none',
                'md:grid-cols-3':
                  companySettings()?.research_settings?.currentTemplate !==
                  'none'
              }"
              class="grid grid-cols-1 gap-4"
            >
              <div
                [ngClass]="{
                  'md:col-span-1':
                    companySettings()?.research_settings?.currentTemplate ===
                    'none',
                  'md:col-span-2':
                    companySettings()?.research_settings?.currentTemplate !==
                    'none'
                }"
                class="col-span-1"
              >
                <app-display-editor-content
                  [content]="researchArticle().content_DE"
                ></app-display-editor-content>
              </div>
              @if (
                companySettings()?.research_settings?.currentTemplate !== "none"
              ) {
                <div class="col-span-1 md:col-span-1">
                  @if (companyTicker()) {
                    <app-tile-wrapper-grid
                      [baseyear]="baseyear"
                      [companyData]="companyNumbers"
                      [tileWrapperConfig]="researchTileSettings"
                      [decimals]="
                        companySettings()?.calculation_settings?.numDecimals ||
                        1
                      "
                    ></app-tile-wrapper-grid>
                  }
                </div>
              }
            </div>
            <div class="w-full">
              <h3 class="text-lg my-4">Previous Updates</h3>
              <app-research-accordion
                [researchOverviewList]="researchOverview"
              ></app-research-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="trianglebelow trianglebelow-one w-full"></div>
  <section #keyfigures class="bg-gray-two py-12" id="keyfigures">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center">
          <div class="w-11/12">
            <h3 class="chapter-headline">Key Figures</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div class="col-span-1 md:col-span-2">
                <app-keyfacts
                  [companyData]="companyNumbers"
                  [companyId]="companyTicker"
                  [decimals]="
                    companySettings()?.calculation_settings?.numDecimals || 1
                  "
                  [lang]="'ger'"
                  class="w-full"
                ></app-keyfacts>
              </div>
              <div class="col-span-1 md:col-span-1">
                @if (companyTicker()) {
                  <app-tile-wrapper-grid
                    [baseyear]="baseyear"
                    [companyData]="companyNumbers"
                    [tileWrapperConfig]="keyfiguresTileSettings"
                    [decimals]="
                      companySettings()?.calculation_settings?.numDecimals || 1
                    "
                  ></app-tile-wrapper-grid>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="trianglebelow trianglebelow-two w-full"></div>
  <section #investmentcase class="bg-gray-one py-12" id="investmentcase">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center">
          <div class="w-11/12">
            <h3 class="chapter-headline">Investment Case</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div class="col-span-1 md:col-span-2">
                <app-display-editor-content
                  [content]="investmentCaseEditorContent()"
                ></app-display-editor-content>
              </div>
              <div class="col-span-1 md:col-span-1"></div>
            </div>
            <div class="w-full">
              <h4 class="chapter-subheadline">Investment case in Charts</h4>
              <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="col-span-1 md:col-span-2">
                  <app-tile-wrapper-grid
                    [baseyear]="baseyear"
                    [companyData]="companyNumbers"
                    [tileWrapperConfig]="investmentCaseChartSettings"
                  ></app-tile-wrapper-grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="trianglebelow trianglebelow-one w-full"></div>
  <section #swot class="bg-gray-two py-12" id="swot">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center">
          <div class="w-11/12">
            <h3 class="chapter-headline">SWOT-Analysis</h3>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div class="col-span-1 md:col-span-1">
                @if (companyTicker()) {
                  <app-tile-wrapper-grid
                    [baseyear]="baseyear"
                    [companyData]="companyNumbers"
                    [tileWrapperConfig]="swotTileSettings"
                    [decimals]="
                      companySettings()?.calculation_settings?.numDecimals || 1
                    "
                  >
                  </app-tile-wrapper-grid>
                }
              </div>
              <div class="col-span-1 md:col-span-2">
                <app-tabs
                  [contentClasses]="'p-4'"
                  [inactiveTabClass]="'bg-transparent'"
                  [tabClasses]="
                    'underline uppercase text-base leading-normal font-medium tracking-special p-1 text-gray-700  focus:outline-none cursor-pointer'
                  "
                  [tabRowClasses]="'bg-transparent'"
                  class="mx-auto block w-8/12 text-base"
                  tabGroupId="swot"
                >
                  <app-tab tabGroupId="swot" title="Strengths">
                    <ul class="list-disc">
                      @for (item of swotContent().strengths; track item) {
                        <li>{{ item }}</li>
                      }
                    </ul>
                  </app-tab>
                  <app-tab tabGroupId="swot" title="Weaknesses">
                    <ul class="list-disc">
                      @for (item of swotContent().weaknesses; track item) {
                        <li>{{ item }}</li>
                      }
                    </ul>
                  </app-tab>
                  <app-tab tabGroupId="swot" title="Opportunities">
                    <ul class="list-disc">
                      @for (item of swotContent().opportunities; track item) {
                        <li>{{ item }}</li>
                      }
                    </ul>
                  </app-tab>
                  <app-tab tabGroupId="swot" title="threats">
                    <ul class="list-disc">
                      @for (item of swotContent().threats; track item) {
                        <li>{{ item }}</li>
                      }
                    </ul>
                  </app-tab>
                </app-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="trianglebelow trianglebelow-two w-full"></div>
  <section class="bg-gray-one py-12">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center">
          <div class="w-11/12" id="valuation">
            <h3 class="chapter-headline">Valuation</h3>
            <div class="grid grid-cols-1 grid-rows-1 md:grid-cols-3 gap-4">
              <div class="col-span-1 row-span-1 md:col-span-2 md:row-span-2">
                @if (valuationTextNumbers()) {
                  <p>
                    At mwb research, we apply different valuation techniques.
                    The DCF model results in a fair value of
                    {{ valuationTextNumbers().curr }}
                    {{ valuationTextNumbers().dcf_dcfps }} per
                    {{ valuationTextNumbers().name }} share. This is based on
                    the following assumptions: for the top line, we expect a
                    CAGR {{ valuationTextNumbers().HL_dcf_2 }}-{{
                      valuationTextNumbers().HL_dcf_9
                    }}
                    in the range of
                    {{ valuationTextNumbers().dcf_plan_hor }} p.a. The long-term
                    growth rate is set at
                    {{ valuationTextNumbers().dcf_term_val_gro }}. Cost of
                    equity is calculated as
                    {{ valuationTextNumbers().dcf_cost_of_debt }}. This results
                    in a long-term WACC of
                    {{ valuationTextNumbers().dcf_term_val_wacc }}. For further
                    detail and a sensitivity analysis, see the DCF model below.
                    We also perform a free cash flow analysis. The adjusted Free
                    Cash Flow Yield results in a fair value between
                    {{ valuationTextNumbers().curr }}
                    {{ valuationTextNumbers().RW_fcf_fvps_2 }} per share based
                    on {{ valuationTextNumbers().HL_fcf_2 }} and
                    {{ valuationTextNumbers().RW_fcf_fvps_6 }}
                    {{ valuationTextNumbers().curr }}
                    per share on
                    {{ valuationTextNumbers().HL_fcf_6 }} estimates. Again, more
                    details can be found below.
                  </p>
                }
                @if (
                  companySettings()?.valuation_settings.show_trading_multiples
                ) {
                  <div class="w-full">
                    <app-table-notabs
                      [collapse]="false"
                      [companyData]="companyNumbers"
                      [companyId]="companyTicker"
                      [decimals]="
                        companySettings()?.calculation_settings?.numDecimals ||
                        0
                      "
                      [templateName]="valuationTradingMultiplesTableTemplate"
                    ></app-table-notabs>
                  </div>
                }
                @if (companySettings()?.valuation_settings.show_dcf_model) {
                  <div class="w-full">
                    @if (companyTicker()) {
                      <app-dcf-model
                        [companyData]="companyNumbers"
                        [dcfType]="valuationDCFTableTemplate"
                      ></app-dcf-model>
                    }
                  </div>
                }
                @if (companySettings()?.valuation_settings.show_adj_fcf_yield) {
                  <div class="w-full">
                    <app-table-notabs
                      [collapse]="true"
                      [companyData]="companyNumbers"
                      [companyId]="companyTicker"
                      [decimals]="
                        companySettings()?.calculation_settings?.numDecimals ||
                        0
                      "
                      [templateName]="valuationFCFYieldTableTemplate"
                    ></app-table-notabs>
                  </div>
                }
                @if (companySettings().valuation_settings?.show_editorContent) {
                  <app-display-editor-content
                    [content]="valuationEditorContent()"
                  ></app-display-editor-content>
                }
              </div>
              <div class="col-span-1 row-span-2 md:col-span-1 md:row-span-1">
                @if (companyTicker()) {
                  <app-tile-wrapper-grid
                    [baseyear]="baseyear"
                    [companyData]="companyNumbers"
                    [tileWrapperConfig]="valuationTileSettings"
                    [decimals]="
                      companySettings()?.calculation_settings?.numDecimals || 1
                    "
                  >
                  </app-tile-wrapper-grid>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="trianglebelow trianglebelow-one w-full"></div>
  <section class="bg-gray-two py-12">
    <div class="mx-auto">
      <div class="grid grid-cols-1 grid-rows-1">
        <div class="flex justify-center">
          <div class="w-11/12" id="financials">
            <h3 class="chapter-headline">Financials</h3>
            <div class="w-8/12">
              <app-financials-table
                [companyData]="companyNumbers"
                [companyId]="companyTicker"
                [decimals]="
                  companySettings()?.calculation_settings?.numDecimals || 0
                "
                [templateName]="
                  companySettings()?.financials_settings?.currentTemplate
                "
              ></app-financials-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="trianglebelow trianglebelow-two w-full"></div>
</div>

<app-more-content-cta></app-more-content-cta>
