import { HttpClient } from "@angular/common/http";
import { Component, inject, signal } from "@angular/core";
import { IScreenerArticle } from "~global-interfaces/IScreenerArticle";

import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminScreenerEditorFormComponent } from "~features/weekly-screener/admin/admin-screener-editor-form/admin-screener-editor-form.component";
import { WeeklyScreenerService } from "~features/weekly-screener/weekly-screener.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_SCREENERARTICLE } from "~shared/initial-values";

@Component({
  selector: "app-admin-screener-creator",
  standalone: true,
  imports: [
    FileUploadComponent,
    AdminScreenerEditorFormComponent,
    DisplayEditorContentComponent,
    EditEditorContentComponent,
    BreadcrumpComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-screener-creator.component.html",
  styleUrl: "./admin-screener-creator.component.scss",
})
export class AdminScreenerCreatorComponent {
  screenerService = inject(WeeklyScreenerService);
  screenerArticle = signal<IScreenerArticle>({ ...INITIAL_SCREENERARTICLE });

  constructor(private http: HttpClient) {}

  onFormSubmit(formData: any) {
    //console.log(formData);
    this.screenerService.createScreenerArticle(formData);
  }
}
