import { Injectable } from "@angular/core";

import { researchHub_URL } from "~api/api-urls";
import {
  formatBloombergTicker,
  formatDate,
} from "~features/events/event.utils";

@Injectable({
  providedIn: "root",
})
export class VideoService {
  public createEventVideoLink(companyTicker, date) {
    const formattedDate = formatDate(date, "toUrl");
    const formattedTicker = formatBloombergTicker(companyTicker, "toUrl");
    return (
      researchHub_URL + "/events/video/" + formattedDate + "/" + formattedTicker
    );
  }
}
