import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-logo",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./logo.component.html",
  styleUrl: "./logo.component.scss",
})
export class LogoComponent {
  @Input() src: string;
  @Input() alt: string;
  @Input() logoClass: string;
  @Input() wrapperClass: string = "h-24 w-24";
}
