import { Clipboard } from "@angular/cdk/clipboard";
import { DatePipe, JsonPipe, NgClass, SlicePipe } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from "@angular/core";
import { IEvent } from "~global-interfaces/IEvent";
import { IParticipant } from "~global-interfaces/IParticipant";

import { AuthService } from "~features/auth/auth.service";
import { InvestorProfileDialogComponent } from "~features/corporate-dashboard/investor-profile-dialog/investor-profile-dialog.component";
import { EventService } from "~features/events/event.service";
import {
  createEventRegistrationLink,
  timeZoneFormat,
} from "~features/events/event.utils";
import { ResponsiveVideoComponent } from "~features/video/responsive-video/responsive-video.component";
import { VideoPlayerComponent } from "~features/video/video-player/video-player.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { ClipboardService } from "~shared/services/clipboard.service";
import { DialogService } from "~shared/services/dialog.service";

@Component({
  selector: "app-corporate-event-tile",
  templateUrl: "./corporate-event-tile.component.html",
  standalone: true,
  imports: [
    ResponsiveVideoComponent,
    SlicePipe,
    NgClass,
    DatePipe,
    VideoPlayerComponent,
    JsonPipe,
  ],
})
export class CorporateEventTileComponent {
  @Input() event: IEvent;
  @Input() listType = "";
  @Output() eventRemoved = new EventEmitter<any>();
  timeZoneFormat = timeZoneFormat;
  expanded = signal([false, false]);
  inviteLink = "";
  dialogService = inject(DialogService);
  eventService = inject(EventService);
  clipboardService = inject(ClipboardService);
  private clipboard = inject(Clipboard);
  private authService = inject(AuthService);

  constructor() {
    effect(() => {
      if (this.event) {
        this.inviteLink = createEventRegistrationLink(
          this.event?.companyTicker,
          this.event?.date
        );
      }
      this.sortPeople("subscribers", "investorProfile");
    }, enableSignalWritesInEffectContext);
  }

  toggleExpand(index: number) {
    const current = this.expanded();
    current[index] = !current[index];
    this.expanded.set([...current]);
  }

  sortPeople(listName: string, target: string) {
    this.event[listName].sort((a, b) => {
      if (target === "investorProfile" && this.personHasProfile(a)) {
        return -1;
      } else if (
        a[target] === null ||
        a[target] === undefined ||
        (target === "investorProfile" && !this.personHasProfile(a))
      ) {
        return 1;
      } else {
        return a[target].localeCompare(b[target]);
      }
    });
  }

  downloadRegisteredInvestors(
    eventId: string,
    eventTitle: string,
    eventDate: any
  ) {
    this.eventService.fetchCSVOfEventSubscribers(
      eventId,
      eventTitle,
      eventDate
    );
  }

  personHasProfile(person: IParticipant) {
    if (!person.investorProfile) return false;
    const keys = Object.keys(person.investorProfile);
    const nonEmptyKeys = keys.filter(
      (key) => person.investorProfile[key] !== ""
    );
    return nonEmptyKeys.length >= 2;
  }

  openInvestorProfileDIalog(subscriber) {
    console.log(subscriber);
    const dialogRef = this.dialogService.open(
      InvestorProfileDialogComponent,
      {
        data: subscriber,
      },
      "dialog-panel-gray-alster"
    );
    dialogRef.closed.subscribe((result) => {
      // console.log("The dialog was closed");
      // console.log(result);
    });
  }

  copyTextToClipboard(textToCopy: string) {
    this.clipboardService.copyToClipboard(textToCopy);
  }

  openVideoModal(videoURL: string) {
    // TODO: Implement video modal
  }

  getLogoForCompanyFromEvent(companyTicker: string): string {
    const logo = "../../assets/blanklogo.svg";
    return logo === "../../assets/blanklogo.svg"
      ? this.event.companyLogoURL
      : logo;
  }
}
