@if (showUpdates) {
  <section class="bg-white py-8 lg:py-12">
    <h3
      class="container mx-auto p-4 max-w-screen-2xl uppercase font-normal tracking-widest text-[#6c757d]"
    >
      Recent updates
    </h3>
    <div class="container mx-auto px-16 max-w-screen-2xl">
      <app-company-grid-highlight
        [linkToAllStocks]="showAllCompanies"
      ></app-company-grid-highlight>
    </div>
  </section>
}
@if (showSubscribeBanner) {
  <section class="bg-white py-8 lg:py-12">
    <app-subscribe-banner class="w-full"></app-subscribe-banner>
  </section>
}
