import { Component } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-subscribe-banner",
  templateUrl: "./subscribe-banner.component.html",
  styleUrls: ["./subscribe-banner.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, RouterLink],
})
export class SubscribeBannerComponent {}
