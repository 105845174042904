import { JsonPipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, computed, inject } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, RouterLink } from "@angular/router";

import { AdminCompanySettingsEditorFormComponent } from "~features/company/admin/company-settings/admin-company-settings-editor-form/admin-company-settings-editor-form.component";
import { AdminCompanySwotEditorFormComponent } from "~features/company/admin/company-swot/admin-company-swot-editor-form/admin-company-swot-editor-form.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { CompanydataService } from "~features/company/companydata.service";
import { SwotService } from "~features/company/swot.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_SWOT } from "~shared/initial-values";

@Component({
  selector: "app-admin-company-swot-editor",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    BreadcrumpComponent,
    AdminCompanySwotEditorFormComponent,
    RouterLink,
    AdminCompanySettingsEditorFormComponent,
    JsonPipe,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-company-swot-editor.component.html",
  styleUrl: "./admin-company-swot-editor.component.scss",
})
export class AdminCompanySwotEditorComponent {
  companyTickerService = inject(CompanyTickerService);
  swotService = inject(SwotService);
  swotContent = computed(() => {
    const swotValue = this.swotService.getSwot()();
    console.log(swotValue);
    return swotValue !== null ? swotValue : INITIAL_SWOT;
  });
  companyDataService = inject(CompanydataService);
  route = inject(ActivatedRoute);
  http = inject(HttpClient);

  companyTicker = this.companyTickerService.getCompanyTicker();

  constructor() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");

    this.companyTickerService.setCompanyTicker(ticker);
  }

  onFormSubmit(updateSwotData: any) {
    this.swotService.updateSWOT(updateSwotData);
  }
}
