import { Component, inject, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IRSSEntry } from "~global-interfaces/IRSSEntry";

import { AdminRssEditorFormComponent } from "~features/rss/admin/admin-rss-editor-form/admin-rss-editor-form.component";
import { RssEntryService } from "~features/rss/rss-entry.service";
import { AdminTransactionEditorFormComponent } from "~features/transactions/admin/admin-transaction-editor-form/admin-transaction-editor-form.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-admin-rss-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminRssEditorFormComponent,
    AdminTransactionEditorFormComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-rss-editor.component.html",
  styleUrl: "./admin-rss-editor.component.scss",
})
export class AdminRssEditorComponent {
  rssEntryService = inject(RssEntryService);
  rssEntry: WritableSignal<IRSSEntry | null> = signal<IRSSEntry | null>(null);

  constructor(private route: ActivatedRoute) {
    const rssEntryId = this.route.snapshot.paramMap.get("rssid") ?? "";
    this.rssEntryService
      .fetchRssEntry(rssEntryId)
      .subscribe((rssEntry: IRSSEntry) => {
        this.rssEntry.set(rssEntry);
      });
  }

  onFormSubmit(newData: IRSSEntry) {
    // console.log(newData);
    this.rssEntryService
      .updateRssEntry(newData)
      .subscribe((updatedRssEntry: IRSSEntry) => {
        this.rssEntry.set(updatedRssEntry);
      });
  }
}
