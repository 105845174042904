<div>
  <div class="mx-auto">
    <form
      [formGroup]="companyForm"
      [ngClass]="showIsFeaturedOnly ? '' : 'pb-8 mb-4  pt-6'"
      class="bg-white shadow-md rounded-lg px-8"
    >
      @if (!showIsFeaturedOnly) {
        <!-- Basic Info -->
        <div class="mb-6">
          <h3 class="text-lg font-semibold mb-2">Basic Info</h3>
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="name"
          >
            Name:
          </label>
          <input
            appAdminInfoHasSideeffect
            [hasSideeffect]="true"
            [inputName]="'company_settings_name'"
            [ngClass]="{
              'border-red-500':
                companyForm.get('name')?.invalid &&
                companyForm.get('name')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="name"
            id="name"
            required
            type="text"
          />
          @if (
            companyForm.get("name")?.invalid && companyForm.get("name")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("name") }}
            </p>
          }
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="companyTicker"
          >
            Company Ticker:
          </label>
          <input
            appAdminInfoHasSideeffect
            [hasSideeffect]="true"
            [inputName]="'company_settings_companyTicker'"
            [ngClass]="{
              'border-red-500':
                companyForm.get('companyTicker')?.invalid &&
                companyForm.get('companyTicker')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="companyTicker"
            id="companyTicker"
            required
            type="text"
          />
          <p class="text-xs text-gray-muted">Format: XXX:GR</p>

          @if (
            companyForm.get("companyTicker")?.invalid &&
            companyForm.get("companyTicker")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("companyTicker") }}
            </p>
          }
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="slug"
          >
            Slug (autogenerated, you do not need to change this)
          </label>
          <input
            appAdminInfoHasSideeffect
            [hasSideeffect]="true"
            [inputName]="'company_settings_slug'"
            [ngClass]="{
              'border-red-500':
                companyForm.get('slug')?.invalid &&
                companyForm.get('slug')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="slug"
            id="slug"
            required
            type="text"
          />
          <p class="text-xs text-gray-muted">
            Example:
            <span class="font-semibold"
              >https://research-hub.de/companies/{{
                companyForm.get("slug")?.value
              }}</span
            >
          </p>
          <p class="text-xs text-gray-muted">
            Info: This should be autogenerated and can stay as is in 99.99% of
            cases.
          </p>

          @if (
            companyForm.get("slug")?.invalid && companyForm.get("slug")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("slug") }}
            </p>
          }
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="baseyear"
          >
            Base Year:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                companyForm.get('baseyear')?.invalid &&
                companyForm.get('baseyear')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="baseyear"
            id="baseyear"
            required
            type="number"
          />
          @if (
            companyForm.get("baseyear")?.invalid &&
            companyForm.get("baseyear")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("baseyear") }}
            </p>
          }
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="imageURL"
          >
            Image URL:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                companyForm.get('imageURL')?.invalid &&
                companyForm.get('imageURL')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="imageURL"
            id="imageURL"
            type="text"
          />
          @if (
            companyForm.get("imageURL")?.invalid &&
            companyForm.get("imageURL")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("imageURL") }}
            </p>
          }
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="logoURL"
          >
            Logo URL:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                companyForm.get('logoURL')?.invalid &&
                companyForm.get('logoURL')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="logoURL"
            id="logoURL"
            type="text"
          />
          @if (
            companyForm.get("logoURL")?.invalid &&
            companyForm.get("logoURL")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("logoURL") }}
            </p>
          }
        </div>

        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="presentationURL"
          >
            Presentation URL:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                companyForm.get('presentationURL')?.invalid &&
                companyForm.get('presentationURL')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="presentationURL"
            id="presentationURL"
            type="text"
          />
          @if (
            companyForm.get("presentationURL")?.invalid &&
            companyForm.get("presentationURL")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("presentationURL") }}
            </p>
          }
        </div>
        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="companyVideoURL"
          >
            Company Video URL:
          </label>
          <input
            [ngClass]="{
              'border-red-500':
                companyForm.get('companyVideoURL')?.invalid &&
                companyForm.get('companyVideoURL')?.touched
            }"
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="companyVideoURL"
            id="companyVideoURL"
            type="text"
          />
          @if (
            companyForm.get("companyVideoURL")?.invalid &&
            companyForm.get("companyVideoURL")?.touched
          ) {
            <p class="mt-1 text-xs text-red-500">
              {{ getErrorMessage("companyVideoURL") }}
            </p>
          }
        </div>
        <div class="mb-6" formGroupName="calculation_settings">
          <label class="block text-sm font-medium">Number of Decimals:</label>
          <input
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            formControlName="numDecimals"
            type="number"
          />
        </div>
        <div class="mb-6" formGroupName="research_settings">
          <label class="block text-sm font-medium">Interim Figures:</label>
          <select
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
            formControlName="currentTemplate"
          >
            @for (template of possibleResearchTableTemplates; track template) {
              <option [value]="template">{{ template }}</option>
            }
          </select>
        </div>
        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-secondary mb-2"
            for="mappedTVChartTicker"
          >
            Shareprice Chart (TradingView Popup):
          </label>
          <input
            class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            formControlName="mappedTVChartTicker"
            id="mappedTVChartTicker"
            type="text"
          />
          <p class="text-xs text-gray-muted">
            This is optional. This Ticker is for the TradingView Chart in the
            "Shareprice"-Popup. You only need to enter a value here if its not
            working.
          </p>
        </div>
        <!-- Charts -->
        <div class="mb-6">
          <h3 class="text-lg font-semibold mb-2">Investment Case in Charts</h3>
          <p class="text-xs text-gray-muted">
            There are six charts, that are the default for all companies.
            Depending on quality/calculations of data, some might need to be
            removed.
          </p>
          <p class="text-xs text-gray-muted">
            If no charts are set, it shows the 6 default charts.
          </p>
          <p class="text-xs text-gray-muted">
            The order of charts here is the order in which they are displayed
            on, still taking "left"/"right" into account.
          </p>

          <div formArrayName="charts">
            @for (
              chart of chartsFormArray.controls;
              track chart;
              let i = $index
            ) {
              <div [formGroupName]="i" class="mb-2 flex items-center">
                <select
                  formControlName="currentTemplate"
                  class="mr-2 w-1/2 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                >
                  @for (template of possibleChartTemplates; track template) {
                    <option [value]="template">{{ template }}</option>
                  }
                </select>
                <select
                  formControlName="position"
                  class="mr-2 w-1/4 border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
                >
                  <option value="left">Left</option>
                  <option value="right">Right</option>
                </select>
                <button
                  type="button"
                  (click)="moveChart(i, 'up')"
                  class="mr-1 px-2 py-1 bg-gray-200 text-gray-700 rounded"
                  [disabled]="i === 0"
                >
                  ↑
                </button>
                <button
                  type="button"
                  (click)="moveChart(i, 'down')"
                  class="mr-1 px-2 py-1 bg-gray-200 text-gray-700 rounded"
                  [disabled]="i === chartsFormArray.length - 1"
                >
                  ↓
                </button>
                <button
                  type="button"
                  (click)="removeChart(i)"
                  class="px-2 py-1 bg-red-500 text-white rounded"
                >
                  Remove
                </button>
              </div>
            }
          </div>
          <button
            (click)="addChart()"
            class="mt-2 px-4 py-2 bg-green-500 text-white rounded"
            type="button"
          >
            Add Chart
          </button>
        </div>

        <!-- Valuation Settings -->
        <div class="mb-6" formGroupName="valuation_settings">
          <h3 class="text-lg font-semibold mb-2">Valuation Settings</h3>
          <p class="text-xs text-gray-muted">
            For 99% of companies, the default settings are fine. (Default is
            showing all but the editor)
          </p>
          <div class="mb-2">
            <label class="inline-flex items-center">
              <input
                class="form-checkbox h-5 w-5 text-blue-500"
                formControlName="show_trading_multiples"
                type="checkbox"
              />
              <span class="ml-2">Show Trading Multiples</span>
            </label>
          </div>
          <div class="mb-2">
            <label class="inline-flex items-center">
              <input
                class="form-checkbox h-5 w-5 text-blue-500"
                formControlName="show_dcf_model"
                type="checkbox"
              />
              <span class="ml-2">Show DCF Model</span>
            </label>
          </div>
          <div class="mb-2">
            <label class="inline-flex items-center">
              <input
                class="form-checkbox h-5 w-5 text-blue-500"
                formControlName="show_adj_fcf_yield"
                type="checkbox"
              />
              <span class="ml-2">Show Adjusted FCF Yield</span>
            </label>
          </div>
          <div class="mb-2">
            <label class="inline-flex items-center">
              <input
                class="form-checkbox h-5 w-5 text-blue-500"
                formControlName="show_editorContent"
                type="checkbox"
              />
              <span class="ml-2">Show Editor Content</span>
            </label>
          </div>
        </div>

        <!-- Financials Settings -->
        <div class="mb-6" formGroupName="financials_settings">
          <h3 class="text-lg font-semibold mb-2">Financials Settings</h3>
          <div class="mb-2">
            <label class="block text-sm font-medium">Current Template:</label>
            <select
              class="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
              formControlName="currentTemplate"
            >
              @for (
                template of possibleFinancialTableTemplates;
                track template
              ) {
                <option [value]="template">{{ template }}</option>
              }
            </select>
          </div>
        </div>
      }
      <!-- Is Featured -->
      <div class="mb-6">
        <label
          class="block text-gray-700 text-sm font-secondary mb-2"
          for="isFeatured"
        >
          Is Featured:
        </label>
        <select
          class="w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          formControlName="isFeatured"
          id="isFeatured"
        >
          <option value="">None</option>
          <option value="Initiation">Initiation</option>
          <option value="Transaction">Transaction</option>
        </select>
      </div>
      @if (!showIsFeaturedOnly) {
        <!-- Is Public -->
        <div class="mb-8">
          <label class="inline-flex items-center">
            <input
              class="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"
              formControlName="is_public"
              id="is_public"
              type="checkbox"
            />
            <span class="ml-2 text-sm font-secondary text-gray-700"
              >Is visible to everyone - (is public)</span
            >
          </label>

          <ul class="text-sm text-gray-muted list-disc list-inside">
            <li>
              If checkbox is set, the company is visible everywhere for everyone
            </li>
            <li>
              If checkbox is NOT set, the company is only visible to admins.
            </li>
          </ul>
        </div>

        <!-- Is Hidden -->
        @if (!multiMode) {
          <div class="mb-8 border border-2 border-red-600 rounded p-2">
            <label class="inline-flex items-center">
              <input
                class="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"
                formControlName="is_hidden"
                id="is_hidden"
                type="checkbox"
              />
              <span class="ml-2 text-sm font-secondary text-gray-700"
                >Dont show this company to me anymore! - This is like a delete
                but keeps the data restorable
              </span>
            </label>
            <p class="text-sm text-gray-muted my-2">
              Example: Companies that are not covered anymore, but we want to
              keep the data for refence.
            </p>
            <p class="text-sm text-gray-muted">
              If you dont want to see this company anymore AT ALL, you can hide
              it here. It is not deleted, just hidden from everyones view. You
              have to contact a developer with database access to get it back.
            </p>
          </div>
        }
      }
      <!-- Submit Button -->
      @if (!multiMode) {
        <div class="flex justify-end">
          <app-admin-form-button
            (click)="saveForm()"
            [class]="'admin-form-button'"
            [disabled]="!companyForm.valid"
          >
          </app-admin-form-button>
        </div>
        <app-admin-form-request-message></app-admin-form-request-message>
      }
    </form>
  </div>
</div>
