export function niceDate(date: string) {
  const datePattern = /^\d\d\d\d-\d\d-\d\d$/;
  if (datePattern.test(date)) {
    const splitDate = date.split("-");
    date = splitDate[2] + "/" + splitDate[1] + "/" + splitDate[0];
  }

  return date;
}

export function formatValue(
  value,
  mapping,
  numberFormatScheme: string,
  defaultDecimals: number,
): string {
  const notApplicable = "na";
  const optionalDecimalNumberPattern = /^(-)?\d+(\.\d+)?(e(-)?\d+)?$/;

  if (value === "#EMPTY") {
    value = " ";
  } else if (typeof value === "undefined") {
    value = notApplicable;
  } else if (
    value === "undefined" ||
    value === null ||
    value == "Infinity" ||
    value == "-Infinity" ||
    value == "NaN" ||
    value == "#WERT!" ||
    value == "#DIV/0!"
  ) {
    value = notApplicable;
  } else {
    if (optionalDecimalNumberPattern.test(value)) {
      if (typeof mapping.maxVal !== "undefined") {
        if (Number(value) > mapping.maxVal) {
          value = notApplicable;
        }
      }

      if (typeof mapping.minVal !== "undefined") {
        if (Number(value) < mapping.minVal) {
          value = notApplicable;
        }
      }
    }

    value = niceDate(value);

    if (mapping.format !== "NOFORMAT" && value !== notApplicable) {
      if (typeof value != "undefined") {
        if (typeof value != "string") {
          value = value.toString();
        }

        if (
          mapping.unit === "PERCENT" &&
          optionalDecimalNumberPattern.test(value)
        ) {
          value = (Number(value) * 100).toString();
        }

        if (value.match(optionalDecimalNumberPattern)) {
          value = applyDecimalFormatting(
            value,
            mapping,
            numberFormatScheme,
            defaultDecimals,
          );
        }
      }

      if (mapping.unit === "PERCENT") {
        value = value + "%";
      } else if (mapping.unit === "MULTIPLE") {
        value = value + "x";
      }
    }
  }

  return value;
}

function applyDecimalFormatting(
  value: string,
  mapping,
  numberFormatScheme: string,
  defaultDecimals: number,
): string {
  const mappingDecimalFormat = /^\d-decimals/;
  if (mapping.format != "") {
    if (mappingDecimalFormat.test(mapping.format)) {
      const numberDecimalPlaces = mapping.format.split("-")[0];
      value = Number(value).toFixed(numberDecimalPlaces);
    }
  } else {
    value = Number(value).toFixed(defaultDecimals);
  }

  let decimalSeperator = ",";
  if (numberFormatScheme === "eng") {
    decimalSeperator = ".";
  }

  const splitVal = value.split(".");
  splitVal[0] = applyNumberSeperators(splitVal[0], numberFormatScheme);
  if (splitVal.length > 1) {
    value = splitVal[0] + decimalSeperator + splitVal[1];
  } else {
    value = splitVal[0];
  }

  return value;
}

function applyNumberSeperators(intNumber: string, numberFormatScheme): string {
  let negative: boolean = false;
  if (intNumber.startsWith("-")) {
    negative = true;
  }

  intNumber = intNumber.replace("-", "");

  let thousandSeperator = ".";
  if (numberFormatScheme === "eng") {
    thousandSeperator = ",";
  }

  let value = "";
  for (let i = intNumber.length - 3; i > -3; i = i - 3) {
    value = intNumber.substring(Math.max(i, 0), i + 3) + value;
    if (i > 0) {
      value = thousandSeperator + value;
    }
  }

  if (negative) {
    value = "-" + value;
  }

  return value;
}
