<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Edit a Podcast</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
      <p>Attention (Podcasts are still a "proof of concept"):</p>
      <p>Currently single podcasts are not hardwired to their research.</p>
      <p>
        They only have a soft connection via companyTicker, research-date,
        reserach_language.
      </p>
      <p>
        If you change that to something else that what is written in the
        reserach, this connection will break.
      </p>
    </div>

    <div class="p-6">
      <app-admin-podcast-editor-form
        (formSubmit)="onFormSubmit($event)"
        [podcast]="podcast"
      ></app-admin-podcast-editor-form>
    </div>
  </main>
</div>
