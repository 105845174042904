import { JsonPipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { EditorComponent, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";

@Component({
  selector: "app-edit-editor-content",
  standalone: true,
  imports: [EditorComponent, FormsModule, JsonPipe],
  templateUrl: "./edit-editor-content.component.html",
  styleUrl: "./edit-editor-content.component.scss",
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
  ],
})
export class EditEditorContentComponent implements OnInit {
  /**
   *  apiKey="mc27e24qav5ca95i2gb3lv6jh2skggnp8getjaklrloj61c6"
   *  The apikey is only needed if we use the "cloudhosted" option. Installing npm but not importing the script in angular.json uses the cloud hosted version by default.
   *
   */
  @Output() contentSave = new EventEmitter<string>();

  @Input() content = "";
  editorId: string;

  init: EditorComponent["init"];

  onEditorChange(content: string) {
    this.content = content;
  }

  onSave() {
    this.contentSave.emit(this.content);
  }
  ngOnInit() {
    this.editorId = this.generateRandomId();
    this.init = {
      height: 800,

      base_url: "/tinymce", // Root for resources
      suffix: ".min", // Suffix to use when loading resources ,  "./node_modules/tinymce/tinymce.min.js"
      menubar: " file edit view insert format tools table help ",
      plugins:
        "preview  autolink code save directionality visualblocks visualchars image link media  table charmap  pagebreak nonbreaking anchor  insertdatetime advlist lists wordcount   charmap quickbars",
      //toolbar_sticky: true,

      toolbar:
        "undo redo | bold italic underline strikethrough |  fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap |  preview save  | insertfile image media template link anchor | ltr rtl | code ",
    };
  }

  generateRandomId(): string {
    return "editor-" + Math.random().toString(36).substring(2, 15);
  }
}
