<div class="flex flex-col min-h-screen">
  <section class="bg-blue-100 py-8 lg:py-12">
    <div class="container mx-auto px-4 max-w-screen-2xl">
      <div class="flex flex-wrap">
        @if (!loadingData() && data() && registrationEvent()) {
          <app-event-registration
            class="w-full text-center"
            [dataFromURLParams]="data()"
            [event]="registrationEvent()"
          ></app-event-registration>
        }
        @if (!loadingData() && (!data() || !registrationEvent())) {
          <app-event-not-found
            class="w-full text-center"
            style="height: 100vh"
          ></app-event-not-found>
        }
      </div>
    </div>
  </section>
</div>
