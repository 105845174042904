import { CreateEffectOptions } from "@angular/core";

/**
 *
 * WARNING: This option has potential to cause BIG problems and should be used with caution.
 *
 * Using this option will allow you to write to signals in an effect context.
 * Currently this is needed because we do Http requests in effects and we want to toggle the loading indicators for that
 *
 * Using this option allows for potential infinite loops and strange side effects.
 * You !!MUST!! keep this in mind and double check every time you use this option.
 *
 *
 */
export const enableSignalWritesInEffectContext: CreateEffectOptions = {
  allowSignalWrites: true,
};
