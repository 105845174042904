<section
  [style.height.%]="80"
  class="bar-graph bar-graph-horizontal bar-graph-one"
>
  @for (shareholder of shareholders; track shareholder) {
    <div
      class="bar-one my-1"
      [style.min-height.em]="1.7"
      [style.height.%]="100"
      style="background-color: #e2e8f0"
    >
      <div
        class="bar"
        [style.min-height.em]="1.7"
        [style.height.%]="100"
        [style.width.%]="shareholder.width"
      ></div>
      <div
        class="barchart-text flex items-center"
        style="color: #6c757d !important; position: absolute; padding-left: 2%"
      >
        {{ shareholder?.value | number: "1.1-1" }} % {{ shareholder?.name }}
      </div>
    </div>
  }
  <div
    [style.height.%]="100"
    [style.min-height.em]="1.7"
    class="bar-one my-1"
    style="background-color: #e2e8f0"
  >
    <div
      [style.height.%]="100"
      [style.min-height.em]="1.7"
      [style.width.%]="freefloatWidth"
      class="bar"
      style="background-color: #c7e7fd"
    ></div>
    <div
      class="barchart-text flex items-center"
      style="color: #6c757d !important; position: absolute; padding-left: 2%"
    >
      {{ freefloat | number: "1.1-1" }} % Freefloat
    </div>
  </div>
</section>
