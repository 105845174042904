import { Component, Input } from "@angular/core";

import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { selectCountryFlag } from "~utils/country-flag.utils";

@Component({
  selector: "app-country-flag",
  standalone: true,
  imports: [LogoComponent],
  templateUrl: "./country-flag.component.html",
  styleUrl: "./country-flag.component.scss",
})
export class CountryFlagComponent {
  //@Input() country: string;
  public countryFlagURL: string;
  private _country: string;

  @Input()
  set country(value: string) {
    this._country = value;
    this.countryFlagURL = selectCountryFlag(this._country);
  }
}
