import { CdkMenu, CdkMenuItem } from "@angular/cdk/menu";
import { DatePipe, JsonPipe } from "@angular/common";
import {
  Component,
  effect,
  inject,
  signal,
  WritableSignal,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { CompanylistService } from "~features/company/companylist.service";
import { EventVideoHighlightComponent } from "~features/events/event-video-highlight/event-video-highlight.component";
import { EventService } from "~features/events/event.service";
import {
  formatBloombergTicker,
  formatDate,
} from "~features/events/event.utils";
import { PodcastHighlightComponent } from "~features/podcasts/podcast-highlight/podcast-highlight.component";
import { PodcastListComponent } from "~features/podcasts/podcast-list/podcast-list.component";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-event-video-page",
  standalone: true,
  imports: [
    JsonPipe,
    CdkMenu,
    CdkMenuItem,
    MoreContentCtaComponent,
    PaginationComponent,
    PodcastHighlightComponent,
    PodcastListComponent,
    EventVideoHighlightComponent,
    DatePipe,
  ],
  templateUrl: "./event-video-page.component.html",
  styleUrl: "./event-video-page.component.scss",
})
export class EventVideoPageComponent {
  loadingData = signal(true);
  data = signal({
    date: null,
    companyTicker: null,
    beDate: null,
    beTicker: null,
  });
  isCoveredCompany: WritableSignal<boolean> = signal(false);
  companyListService = inject(CompanylistService);
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private eventService = inject(EventService);
  videoEvent = this.eventService.getRegistrationEvent();

  constructor() {
    effect(() => {
      this.activatedRoute.params.subscribe((params) => {
        const companyTicker = formatBloombergTicker(
          params.companyTicker,
          "toValid"
        );
        this.data.update((currentData) => ({
          ...currentData,
          beDate: params.date, // This is in the format "2024-09-18-11-00"
          beTicker: params.companyTicker,
          date: formatDate(params.date, "toValid"),
          companyTicker: companyTicker,
        }));
      });
    }, enableSignalWritesInEffectContext);

    effect(() => {
      const currentData = this.data();
      if (currentData.beDate && currentData.beTicker) {
        this.eventService.fetchRegistrationEvent(
          currentData.beDate,
          currentData.beTicker
        );
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.videoEvent()) {
        this.isCoveredCompany.set(
          this.companyListService.isCovered(this.videoEvent().companyName)
        );
        this.loadingData.set(false);
      }
    }, enableSignalWritesInEffectContext);
  }
}
