import { Component } from '@angular/core';

@Component({
  selector: 'app-lock-icon',
  standalone: true,
  imports: [],
  templateUrl: './lock-icon.component.html',
  styleUrl: './lock-icon.component.scss'
})
export class LockIconComponent {

}
