<div class="container mx-auto p-8">
  <h1 class="text-3xl font-semibold mb-8">Design Elements Showcase</h1>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center w-full bg-blue-alster py-6 text-center"
    >
      Colors
    </h2>
    <hr class="mb-4" />
    <div class="flex flex-wrap">
      <div
        class="w-32 h-32 bg-gray-50 m-2 flex items-center justify-center text-gray-800"
      >
        Gray 50
      </div>
      <div
        class="w-32 h-32 bg-gray-100 m-2 flex items-center justify-center text-gray-800"
      >
        Gray 100
      </div>
      <div
        class="w-32 h-32 bg-gray-200 m-2 flex items-center justify-center text-gray-800"
      >
        Gray 200
      </div>
      <div
        class="w-32 h-32 bg-gray-300 m-2 flex items-center justify-center text-gray-800"
      >
        Gray 300
      </div>
      <div
        class="w-32 h-32 bg-gray-400 m-2 flex items-center justify-center text-white"
      >
        Gray 400
      </div>
      <div
        class="w-32 h-32 bg-gray-500 m-2 flex items-center justify-center text-white"
      >
        Gray 500
      </div>
      <div
        class="w-32 h-32 bg-gray-600 m-2 flex items-center justify-center text-white"
      >
        Gray 600
      </div>
      <div
        class="w-32 h-32 bg-gray-700 m-2 flex items-center justify-center text-white"
      >
        Gray 700
      </div>
      <div
        class="w-32 h-32 bg-gray-800 m-2 flex items-center justify-center text-white"
      >
        Gray 800
      </div>
      <div
        class="w-32 h-32 bg-gray-900 m-2 flex items-center justify-center text-white"
      >
        Gray 900
      </div>
      <div
        class="w-32 h-32 bg-gray-area m-2 flex items-center justify-center text-gray-800"
      >
        Gray Area
      </div>
      <div
        class="w-32 h-32 bg-gray-one m-2 flex items-center justify-center text-gray-800"
      >
        Gray One
      </div>
      <div
        class="w-32 h-32 bg-gray-two m-2 flex items-center justify-center text-gray-800"
      >
        Gray Two
      </div>
      <div
        class="w-32 h-32 bg-gray-old-default m-2 flex items-center justify-center text-gray-800"
      >
        Gray Old Default
      </div>
      <div
        class="w-32 h-32 bg-gray-alster m-2 flex items-center justify-center text-white"
      >
        Gray Alster
      </div>
      <div
        class="w-32 h-32 bg-gray-muted m-2 flex items-center justify-center text-white"
      >
        Gray Muted
      </div>
      <div
        class="w-32 h-32 bg-gray-muted-light m-2 flex items-center justify-center text-white"
      >
        Gray Muted Light
      </div>
      <div
        class="w-32 h-32 bg-gray-active m-2 flex items-center justify-center text-white"
      >
        Gray Active
      </div>
      <div
        class="w-32 h-32 bg-gray-table-highlight m-2 flex items-center justify-center text-white"
      >
        Gray Table Highlight
      </div>
      <div
        class="w-32 h-32 bg-blue-100 m-2 flex items-center justify-center text-gray-800"
      >
        Blue 100
      </div>
      <div
        class="w-32 h-32 bg-blue-300 m-2 flex items-center justify-center text-white"
      >
        Blue 300
      </div>
      <div
        class="w-32 h-32 bg-blue-400 m-2 flex items-center justify-center text-white"
      >
        Blue 400
      </div>
      <div
        class="w-32 h-32 bg-blue-500 m-2 flex items-center justify-center text-white"
      >
        Blue 500
      </div>
      <div
        class="w-32 h-32 bg-blue-700 m-2 flex items-center justify-center text-white"
      >
        Blue 700
      </div>
      <div
        class="w-32 h-32 bg-blue-900 m-2 flex items-center justify-center text-white"
      >
        Blue 900
      </div>
      <div
        class="w-32 h-32 bg-blue-research m-2 flex items-center justify-center text-white"
      >
        Blue Research
      </div>
      <div
        class="w-32 h-32 bg-blue-alster m-2 flex items-center justify-center text-gray-800"
      >
        Blue Alster
      </div>
      <div
        class="w-32 h-32 bg-yellow-100 m-2 flex items-center justify-center text-gray-800"
      >
        Yellow 100
      </div>
      <div
        class="w-32 h-32 bg-yellow-500 m-2 flex items-center justify-center text-gray-800"
      >
        Yellow 500
      </div>
      <div
        class="w-32 h-32 bg-yellow-700 m-2 flex items-center justify-center text-white"
      >
        Yellow 700
      </div>
      <div
        class="w-32 h-32 bg-yellow-800 m-2 flex items-center justify-center text-white"
      >
        Yellow 800
      </div>
      <div
        class="w-32 h-32 bg-yellow-hold-special m-2 flex items-center justify-center text-white"
      >
        Yellow Hold Special
      </div>
      <div
        class="w-32 h-32 bg-green-500 m-2 flex items-center justify-center text-white"
      >
        Green 500
      </div>
      <div
        class="w-32 h-32 bg-red-500 m-2 flex items-center justify-center text-white"
      >
        Red 500
      </div>
      <div
        class="w-32 h-32 bg-white m-2 flex items-center justify-center text-gray-800 border"
      >
        White
      </div>
      <div
        class="w-32 h-32 bg-transparent m-2 flex items-center justify-center text-gray-800 border"
      >
        Transparent
      </div>
      <div
        class="w-32 h-32 bg-light m-2 flex items-center justify-center text-gray-800"
      >
        Light
      </div>
    </div>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Typography
    </h2>
    <hr class="mb-4" />
    <h1 class="text-3xl font-bold mb-4">Heading 1</h1>
    <h2 class="text-2xl font-semibold mb-4">Heading 2</h2>
    <h3 class="text-xl font-medium mb-4">Heading 3</h3>
    <h4 class="text-lg font-normal mb-4">Heading 4</h4>
    <h5 class="text-base font-light mb-4">Heading 5</h5>
    <p class="text-sm text-gray-600 mb-4">Paragraph text goes here.</p>
    <a href="#" class="text-blue-500 hover:text-blue-600">Link</a>
    <p class="text-xs text-gray-500 uppercase tracking-widest mb-4">
      Small uppercase text
    </p>
    <hr class="my-6" />
    <!-- Font Weight Examples -->
    <!--    <p class="font-thin mb-2">Thin font weight</p>-->
    <!--    <p class="font-light mb-2">Light font weight</p>-->
    <p class="font-normal mb-2">Normal font weight</p>
    <p class="font-medium mb-2">Medium font weight</p>
    <p class="font-semibold mb-2">Semibold font weight</p>
    <p class="font-bold mb-2">Bold font weight</p>
    <hr class="my-6" />
    <!-- Line Height Examples -->
    <p class="leading-none mb-2">No line height</p>
    <p class="leading-tight mb-2">Tight line height</p>
    <!--    <p class="leading-snug mb-2">Snug line height</p>-->
    <p class="leading-normal mb-2">Normal line height</p>
    <p class="leading-relaxed mb-2">Relaxed line height</p>
    <!--    <p class="leading-loose mb-2">Loose line height</p>-->
    <hr class="my-6" />
    <!-- Letter Spacing Examples -->
    <!--    <p class="tracking-tighter mb-2">Tighter letter spacing</p>-->
    <!--    <p class="tracking-tight mb-2">Tight letter spacing</p>-->
    <!--    <p class="tracking-normal mb-2">Normal letter spacing</p>-->
    <p class="tracking-wide mb-2">Wide letter spacing</p>
    <!--    <p class="tracking-wider mb-2">Wider letter spacing</p>-->
    <p class="tracking-widest mb-2">Widest letter spacing</p>
    <hr class="my-6" />
    <!-- Line Clamp Examples -->
    <p class="line-clamp-1 mb-2">
      This is a long paragraph that will be truncated to a single line using the
      line-clamp utility class.
    </p>
    <p class="line-clamp-2 mb-2">
      This is a long paragraph that will be truncated to two lines using the
      line-clamp utility class. It will show a maximum of two lines and hide the
      rest of the content.
    </p>
    <hr class="my-6" />
    <!-- Truncate Examples -->
    <p class="truncate mb-2">
      This is a long paragraph that will be truncated with an ellipsis using the
      truncate utility class.
    </p>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Font Sizes
    </h2>
    <hr class="mb-4" />
    <p class="text-xs mb-2">Extra Small (xs): 0.88em</p>
    <p class="text-link mb-2">Link: 13.3333px</p>
    <p class="text-sm mb-2">Small (sm): 16px</p>
    <p class="text-base mb-2">Base: 18px</p>
    <p class="text-smd mb-2">Medium Small (smd): 1.125em</p>
    <p class="text-md mb-2">Medium (md): 1.333em</p>
    <p class="text-lg mb-2">Large (lg): 1.769em</p>
    <p class="text-lgx mb-2">Extra Large (lgx): 2.369em</p>
    <p class="text-xl mb-2">Extra Extra Large (xl): 3.129em</p>
    <p class="text-2xl mb-2">2 Extra Large (2xl): 4.162em</p>
    <p class="text-3xl mb-2">3 Extra Large (3xl): 5.535em</p>
    <!--    <p class="text-slider-headline mb-2">Slider Headline: 50.064px</p>-->
    <!--    <p class="text-kachel-rating-word mb-2">Kachel Rating Word: 3em</p>-->
  </section>
  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Buttons
    </h2>
    <hr class="mb-4" />
    <button class="btn mb-2">Primary Button</button>
    <button class="btn btn-transparent mb-2">Transparent Button</button>
    <button class="btn btn-transparent-blue mb-2">
      Transparent Blue Button
    </button>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Kachel
    </h2>
    <hr class="mb-4" />
    <div class="kachel kachel-interactive">
      <div class="kachel-title">Kachel Title</div>
      <div class="kachel-content">
        <p>Kachel content goes here.</p>
      </div>
      <div class="kachel-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Chapter Headline
    </h2>
    <hr class="mb-4" />
    <h2 class="chapter-headline">Chapter Headline Example</h2>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Slider
    </h2>
    <hr class="mb-4" />
    <app-slider>
      <app-slider-element>Slider Element 1</app-slider-element>
      <app-slider-element>Slider Element 2</app-slider-element>
      <app-slider-element>Slider Element 3</app-slider-element>
    </app-slider>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Tabs
    </h2>
    <hr class="mb-4" />
    <app-tabs>
      <app-tab title="Tab A">
        <p>Content for Tab A</p>
      </app-tab>
      <app-tab title="Tab B">
        <p>Content for Tab B</p>
      </app-tab>
    </app-tabs>

    <hr class="my-6" />
    <app-tabs
      [tabRowClasses]="'bg-transparent'"
      [tabClasses]="
        'underline uppercase text-base leading-normal font-medium tracking-special p-1 text-gray-700  focus:outline-none cursor-pointer'
      "
      [contentClasses]="'p-4 border bg-yellow-100'"
    >
      <app-tab title="Tab A">
        <p>Content for Tab A</p>
      </app-tab>
      <app-tab title="Tab B">
        <p>Content for Tab B</p>
      </app-tab>
    </app-tabs>
    <hr class="my-6" />
    <app-tabs
      [tabRowClasses]="'bg-yellow-100 rounded-t-lg  flex items-center'"
      [tabClasses]="
        'font-semibold rounded-t-lg border-t border-r border-l hover:bg-gray-100 px-4 py-2 text-gray-700  focus:outline-none cursor-pointer active:bg-blue-alster'
      "
      [contentClasses]="'p-4  bg-white'"
      [navClasses]="'flex space-x-4 w-full justify-between'"
      [activeTabClass]="'bg-white'"
    >
      <app-tab title="Tab A">
        <p>Content for Tab A</p>
      </app-tab>
      <app-tab title="Tab B">
        <p>Content for Tab B</p>
      </app-tab>
    </app-tabs>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Breadcrumb
    </h2>
    <hr class="mb-4" />
    <app-breadcrump></app-breadcrump>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Arrow Icon
    </h2>
    <hr class="mb-4" />
    <app-arrow-icon></app-arrow-icon>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Pagination
    </h2>
    <hr class="mb-4" />
    <app-pagination></app-pagination>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Test Chart
    </h2>
    <hr class="mb-4" />
    <app-test-chart></app-test-chart>
  </section>

  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Cards/Tiles
    </h2>
    <hr class="mb-4" />
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div class="bg-white shadow-md rounded p-4">
        <h3 class="text-lg font-semibold mb-2">Card Title</h3>
        <p class="text-gray-600">Card content goes here.</p>
      </div>
      <div class="bg-gray-100 shadow-md rounded p-4">
        <h3 class="text-lg font-semibold mb-2">Card Title</h3>
        <p class="text-gray-600">Card content goes here.</p>
      </div>
    </div>
  </section>
  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Layout
    </h2>
    <hr class="mb-4" />
    <div class="flex mb-4">
      <div class="w-1/3 bg-gray-200 p-4">Flex Item 1</div>
      <div class="w-1/3 bg-gray-300 p-4">Flex Item 2</div>
      <div class="w-1/3 bg-gray-400 p-4">Flex Item 3</div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="bg-gray-200 p-4">Grid Item 1</div>
      <div class="bg-gray-300 p-4">Grid Item 2</div>
      <div class="bg-gray-400 p-4">Grid Item 3</div>
    </div>
    <!-- Centered Content -->
    <div class="mb-8">
      <div class="max-w-2xl mx-auto bg-gray-100 p-6">
        <p class="text-center">This content is centered on the page.</p>
      </div>
    </div>
    <!-- Wide Content -->
    <div class="mb-8">
      <div class="max-w-7xl mx-auto bg-gray-100 p-6">
        <p>This content has much space on the left and right.</p>
      </div>
    </div>
    <!-- Slightly Narrower Content -->
    <div class="mb-8">
      <div class="max-w-5xl mx-auto bg-gray-100 p-6">
        <p>This content has slightly less space on the left and right.</p>
      </div>
    </div>
  </section>
  <section class="mb-12">
    <h2
      class="text-xl font-semibold mb-4 w-full bg-blue-alster py-6 text-center"
    >
      Custom Components
    </h2>
    <hr class="mb-4" />
    <!-- Event Grid Upcoming -->
    <div class="bg-yellow-100 p-4 mb-4">
      <h3 class="text-lg font-semibold mb-2">Event Grid Upcoming</h3>
      <!-- Add example markup for the event grid upcoming component -->
    </div>
    <!-- Event Grid Archived -->
    <div class="bg-blue-100 p-4 mb-4">
      <h3 class="text-lg font-semibold mb-2">Event Grid Archived</h3>
      <!-- Add example markup for the event grid archived component -->
    </div>
    <!-- Company Grid -->
    <div class="bg-gray-100 p-4 mb-4">
      <h3 class="text-lg font-semibold mb-2">Company Grid</h3>
      <!-- Add example markup for the company grid component -->
    </div>
    <!-- Subscribe Banner -->
    <div class="bg-gray-200 p-4">
      <h3 class="text-lg font-semibold mb-2">Subscribe Banner</h3>
      <!-- Add example markup for the subscribe banner component -->
    </div>
  </section>
</div>
