import { DecimalPipe, JsonPipe, NgClass } from "@angular/common";
import { Component, effect, inject, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ICompany } from "~global-interfaces/ICompany";

import { ExcelService } from "~features/company/excel.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { CountryFlagComponent } from "~shared/components/ui/country-flag/country-flag.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { getNumberColor, getTextColor } from "~utils/coloring.utils";

@Component({
  selector: "app-company-grid-tile",
  standalone: true,
  imports: [
    JsonPipe,
    DecimalPipe,
    LogoComponent,
    RouterLink,
    NgClass,
    CountryFlagComponent,
  ],
  templateUrl: "./company-grid-tile.component.html",
  styleUrl: "./company-grid-tile.component.scss",
})
export class CompanyGridTileComponent {
  @Input() company: ICompany;
  protected readonly getTextColor = getTextColor;
  protected readonly getNumberColor = getNumberColor;
  private excelService = inject(ExcelService);

  constructor() {
    effect(() => {
      this.excelService
        .fetchUniqueLiveQuotesAndTileData(this.company.companyTicker)
        .subscribe(([liveQuote, tileData]) => {
          this.company = {
            ...this.company,
            liveQuote: liveQuote,
            tileData: tileData[0],
          };
        });
    }, enableSignalWritesInEffectContext);
  }
}
