import { JsonPipe } from "@angular/common";
import { Component, computed, inject, signal } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";

import { CompanylistService } from "~features/company/companylist.service";
import { ResearchService } from "~features/research/research.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-research-list",
  standalone: true,
  imports: [
    RouterLink,
    BreadcrumpComponent,
    JsonPipe,
    PaginationComponent,
    AdminSidePanelComponent,
    FormsModule,
  ],
  templateUrl: "./admin-research-list.component.html",
  styleUrl: "./admin-research-list.component.scss",
})
export class AdminResearchListComponent {
  researchService = inject(ResearchService);
  researchList = this.researchService.items;
  totalCount = this.researchService.totalCount;

  itemsPerPage = signal(25);
  currentPage = signal(1);
  companyListService = inject(CompanylistService);
  quarterOptions = [
    { value: 1, label: "Q1" },
    { value: 2, label: "Q2" },
    { value: 3, label: "Q3" },
    { value: 4, label: "Q4" },
  ];
  yearOptions = [
    2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
  ];
  tickerOptions = computed(() => {
    return this.companyListService
      .getCompanyList()()
      .map((company) => () => {
        return { name: company.name, companyTicker: company.companyTicker };
      });
  });

  yearSearchTerm = signal(null);
  quarterSearchTerm = signal(null);
  companyTickerSearchTerm = signal(null);
  titleSearchTerm = signal(null);

  constructor() {
    this.fetchResearchList();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalCount());
  }

  fetchResearchList() {
    const paginationParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),
      year: this.yearSearchTerm(),
      quarter: this.quarterSearchTerm(),
      companyTicker: this.companyTickerSearchTerm(),
      titleSearchTerm: this.titleSearchTerm(),
    };
    this.researchService.fetchAllResearchOverview(paginationParams);
  }

  onPageChange(page: number) {
    this.currentPage.set(page);
    this.fetchResearchList();
  }
}
