import { HttpClient } from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import { IRSSEntry } from "~global-interfaces/IRSSEntry";

import { alsterAPI_URL } from "~api/api-urls";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class RssEntryService extends PaginationService<IRSSEntry> {
  isLoading: WritableSignal<boolean> = signal(false);

  constructor(http: HttpClient) {
    super(http);
  }

  fetchRssEntriesList(paginationParams: IQueryParams) {
    this.isLoading.set(true);
    return this.fetchItems(alsterAPI_URL + "rss", paginationParams).subscribe(
      ({ items, totalCount }) => {
        this.items.set(items);
        this.totalCount.set(totalCount || 0);
        this.isLoading.set(false);
      }
    );
  }

  // special case of http request
  // TODO: check subscribe function to fill tap
  fetchRssEntry(rssEntryid) {
    return this.http
      .get<IApiResponse<IRSSEntry>>(alsterAPI_URL + "rss/" + rssEntryid)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch rss entry")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }

  getisLoading() {
    return this.isLoading;
  }
  // special case of http request
  // TODO: check subscribe function to fill tap

  updateRssEntry(formData) {
    const body = { ...formData };
    return this.http
      .put<IApiResponse<IRSSEntry>>(
        alsterAPI_URL + "rss/edit/" + formData._id,
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "update rss entry")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }
  // special case of http request
  // TODO: check subscribe function to fill tap

  createRssEntry(formData) {
    const body = { ...formData };
    return this.http
      .post<IApiResponse<IRSSEntry>>(alsterAPI_URL + "rss/create", body)
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "create rss entry")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }

  public getRssEntriesList() {
    return this.items;
  }
}
