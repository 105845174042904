<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">See all users</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
      <div class="mb-4 flex">
        <input
          (ngModelChange)="mailSearchTerm.set($event)"
          [ngModel]="mailSearchTerm()"
          class="flex-grow mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md mr-2"
          placeholder="Search by email"
          type="text"
        />
        <button
          (click)="onSearchClick()"
          class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Search
        </button>
      </div>
    </div>

    <div class="bg-white shadow-md rounded-lg p-6">
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="fetchMoreUsers($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalItems() }}
        </p>
      </div>

      <div class="mb-4">
        <select
          (change)="onCustomerTypeChange($event.target.value)"
          class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          id="customerType"
        >
          <option value="">All</option>
          <option value="Corporate">Corporate</option>
          <option value="Other">Other</option>
          <option value="Family Office">Family Office</option>
          <option value="Asset Manager / Portfolio Manager">
            Asset Manager / Portfolio Manager
          </option>
          <option value="Independent Financial Adviser">
            Independent Financial Adviser
          </option>
          <option value="Financial press">Financial press</option>
        </select>
      </div>

      <div class="overflow-x-auto">
        <table class="table-auto w-full">
          <thead class="bg-gray-50">
            <tr>
              <th class="px-4 py-2 text-left">Login Name</th>
              <!--              <th class="px-4 py-2 text-left">Name</th>-->
              <th class="px-4 py-2 text-left">Email</th>
              <th class="px-4 py-2 text-left">Customer Type</th>
              <th class="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            @for (user of usersList(); track user; let index = $index) {
              <tr>
                <td class="px-4 py-2">{{ user.userName }}</td>
                <!--                <td class="px-4 py-2">-->
                <!--                  {{ user.firstName }} {{ user.lastName }}-->
                <!--                </td>-->
                <td class="px-4 py-2">{{ user.email }}</td>
                <td class="px-4 py-2">{{ user.customerType }}</td>
                <td class="px-4 py-2">
                  <a [routerLink]="[user._id, 'edit']">
                    <button
                      class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Edit
                    </button>
                  </a>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div class="flex justify-between items-center mb-4">
        <app-pagination
          (pageChanged)="fetchMoreUsers($event)"
          [currentPage]="currentPage"
          [itemsPerPage]="itemsPerPage"
          [totalItems]="totalItems"
        ></app-pagination>
        <p class="text-sm text-gray-600">
          {{ getStartIndex() }} - {{ getEndIndex() }}/{{ totalItems() }}
        </p>
      </div>
    </div>
  </main>
</div>
