import { DatePipe, JsonPipe } from "@angular/common";
import { Component, effect, inject, OnInit, signal } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { CompanyGridComponent } from "~features/company-grid/company-grid/company-grid.component";
import { CompanyGridHighlightComponent } from "~features/company-grid/company-grid-highlight/company-grid-highlight.component";
import { DisplayEditorContentComponent } from "~features/editor/display-editor-content/display-editor-content.component";
import { SubscribeBannerComponent } from "~features/newsletter-subscription/subscribe-banner/subscribe-banner.component";
import { SEOService } from "~features/seo/seo.service";
import { WeeklyScreenerService } from "~features/weekly-screener/weekly-screener.service";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";
import { DateComponent } from "~shared/components/ui/date/date.component";

@Component({
  selector: "app-weekly-screener-page",
  standalone: true,
  imports: [
    JsonPipe,
    DisplayEditorContentComponent,
    RouterLink,
    CompanyGridHighlightComponent,
    CompanyGridComponent,
    SubscribeBannerComponent,
    DateComponent,
    MoreContentCtaComponent,
    DatePipe,
  ],
  templateUrl: "./weekly-screener-page.component.html",
  styleUrl: "./weekly-screener-page.component.scss",
})
export class WeeklyScreenerPageComponent implements OnInit {
  weeklyScreenerService = inject(WeeklyScreenerService);
  route = inject(ActivatedRoute);
  weeklyScreener = this.weeklyScreenerService.getWeeklyScreener();
  overviewListAllWeeklyScreeners = this.weeklyScreenerService.items;
  isLoading = this.weeklyScreenerService.getisLoading();
  itemsPerPage = signal(15); // Adjust the number of latest articles to display
  currentPage = signal(1);

  seoService = inject(SEOService);

  constructor() {
    effect(() => {
      this.seoService.setMetaTags(
        this.weeklyScreener()?.title,
        null,
        null,
        null
      );
    });
  }

  ngOnInit() {
    const published_date = this.route.snapshot.paramMap.get("published_date");
    const title = this.route.snapshot.paramMap.get("title");

    if (published_date && title) {
      this.getWeeklyScreener(published_date, title);
    } else if (published_date) {
      this.getWeeklyScreener(published_date, title);
    } else {
      this.weeklyScreenerService.fetchLatestWeeklyScreener();
    }
    this.fetchLatestScreeners();
  }

  getWeeklyScreener(published_date: string, title: string) {
    this.weeklyScreenerService.fetchWeeklyScreener(published_date, title);
  }

  fetchLatestScreeners() {
    const paginationParams: IQueryParams = {
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    };
    this.weeklyScreenerService.fetchOverviewListAlltWeeklyScreeners(
      paginationParams
    );
  }

  showMore() {
    /**
     * TODO: Make sure your backend  can handle the increasing limit parameter when fetching more articles and also stops the useless increasing limit.
     */
    this.itemsPerPage.update((value) => value + 15);
    this.fetchLatestScreeners();
  }
}
