import { DatePipe, JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ICompany } from "~global-interfaces/ICompany";
import { IEditorContent } from "~global-interfaces/IEditorContent";
import { IResearch } from "~global-interfaces/IResearch";
import { IResearchPDF } from "~global-interfaces/IResearchPDF";

import { AdminInfoHasSideeffectDirective } from "~features/admin-info-sideeffects/admin-info-has-sideeffect.directive";
import { CompanySearchComponent } from "~features/company/company-search/company-search.component";
import { EditEditorContentComponent } from "~features/editor/edit-editor-content/edit-editor-content.component";
import { CreationProcessMenuService } from "~features/guided-creation-flow/creation-process-menu.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { AdminFormButtonComponent } from "~shared/components/ui/admin-form-button/admin-form-button.component";
import { AdminFormRequestMessageComponent } from "~shared/components/ui/admin-form-request-message/admin-form-request-message.component";
import { AdminRequestService } from "~shared/services/admin-request.service";
import { parseDateOrFillWithToday } from "~utils/form.utils";

@Component({
  selector: "app-admin-company-research-editor-form",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    EditEditorContentComponent,
    NgClass,
    AdminFormButtonComponent,
    DatePipe,
    JsonPipe,
    CompanySearchComponent,
    AdminFormRequestMessageComponent,
    AdminInfoHasSideeffectDirective,
  ],
  templateUrl: "./admin-company-research-editor-form.component.html",
  styleUrl: "./admin-company-research-editor-form.component.scss",
})
export class AdminCompanyResearchEditorFormComponent implements OnInit {
  @Input() research!: Signal<IResearch>;
  @Output() formSubmit = new EventEmitter<IResearch>();
  @Output() formValueChange = new EventEmitter<Partial<ICompany>>();
  @Input() multiMode: boolean = false;
  @Output() formValidity = new EventEmitter<boolean>();
  @Input() multiModeCompanyTicker: Signal<string | null> = signal(null);
  researchForm: FormGroup;
  adminRequestService = inject(AdminRequestService);
  createCompanyService = inject(CreationProcessMenuService);
  companyTickerFromCreateResearchService =
    this.createCompanyService.currentCompanyTicker();
  possiblePrimaryLanguage = ["en", "de"];

  constructor(private formBuilder: FormBuilder) {
    this.adminRequestService.emptyLastRequest();
    effect(() => {
      if (this.multiModeCompanyTicker()) {
        this.researchForm
          .get("companyTicker")
          .setValue(this.multiModeCompanyTicker());
      }
    });
    effect(() => {
      this.patchFormValues();
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.initializeForm();
    this.formValidity.emit(this.researchForm.valid);
    this.formValueChange.emit(this.researchForm.value);
    // Subscribe to form changes
    this.researchForm.valueChanges.subscribe((values) => {
      const updatedResearch: IResearch = {
        ...this.research(),
        ...values,
      };
      this.formValueChange.emit(updatedResearch);
    });

    // Subscribe to form validity changes
    this.researchForm.statusChanges.subscribe((status) => {
      this.formValidity.emit(status === "VALID");
    });
  }

  initializeForm() {
    this.researchForm = this.formBuilder.group({
      published_date: ["", Validators.required],
      title: ["", [Validators.required, Validators.minLength(3)]],
      research_date: ["", Validators.required],
      companyTicker: [
        this.companyTickerFromCreateResearchService || "",
        [Validators.required],
      ],
      primary_language: ["en"],
      is_public: [true],
      content_DE: this.formBuilder.group({
        _id: [""],
        language: ["en"],
        content: ["", Validators.required],
      }),
      pdf_DE: this.formBuilder.group({
        _id: [""],
        filename: [""],
        language: ["en"],
        shownName: [""],
        downloadURL: [
          "",
          [Validators.required, Validators.pattern(/^https?:\/\/.+/)],
        ],
      }),
    });
  }

  patchFormValues() {
    const {
      published_date,
      title,
      research_date,
      companyTicker,
      primary_language,
      is_public,
    } = this.research();

    const content_DE = this.research().content_DE as IEditorContent;
    const pdf_DE = this.research().pdf_DE as IResearchPDF;

    if (content_DE && pdf_DE) {
      this.researchForm.patchValue({
        published_date: parseDateOrFillWithToday(published_date),
        research_date: parseDateOrFillWithToday(research_date),
        title,
        companyTicker: companyTicker
          ? companyTicker
          : this.companyTickerFromCreateResearchService
          ? this.companyTickerFromCreateResearchService
          : "",
        primary_language,
        is_public,
        content_DE: {
          _id: content_DE._id,
          language: content_DE.language || "en",
          content: content_DE.content,
        },
        pdf_DE: {
          _id: pdf_DE._id,
          filename: pdf_DE.filename,
          language: pdf_DE.language,
          shownName: pdf_DE.shownName,
          downloadURL: pdf_DE.downloadURL,
        },
      });
    }
  }

  onEditorContentSave(content: string) {
    this.researchForm.get("content_DE.content").setValue(content);
  }

  saveForm() {
    if (this.researchForm.valid) {
      // const formattedPublishedDate = format(
      //   this.researchForm.get("published_date").value,
      //   "dd.LL.yyyy"
      // );
      // const formattedResearchDate = format(
      //   this.researchForm.get("published_date").value,
      //   "dd.LL.yyyy"
      // );
      //
      // this.researchForm.get("published_date").setValue(formattedPublishedDate);
      // this.researchForm.get("research_date").setValue(formattedResearchDate);
      const updatedResearch: IResearch = {
        ...this.research(),
        ...this.researchForm.value,
      };
      console.log("EMITTING RESEARCH");
      console.log(updatedResearch);
      this.formSubmit.emit(updatedResearch);
    } else {
      this.markFormGroupTouched(this.researchForm);
    }
  }

  getErrorMessage(controlName: string): string {
    const control = this.researchForm.get(controlName);
    if (control?.errors) {
      if (control.errors["required"]) return `${controlName} is required`;
      if (control.errors["minlength"])
        return `${controlName} must be at least ${control.errors["minlength"].requiredLength} characters`;
      if (control.errors["maxlength"])
        return `${controlName} must not exceed ${control.errors["maxlength"].requiredLength} characters`;
      if (control.errors["pattern"]) return `Invalid ${controlName} format`;
    }
    return "";
  }

  onCompanySelected(company: any) {
    this.researchForm.get("companyTicker").setValue(company.companyTicker);
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
