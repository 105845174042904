import { JsonPipe, NgClass } from "@angular/common";
import { Component, inject, signal, WritableSignal } from "@angular/core";
import { RouterLink } from "@angular/router";
import { ITransaction } from "~global-interfaces/ITransaction";

import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { TransactionService } from "~features/transactions/transaction.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { PaginationComponent } from "~shared/components/ui/pagination/pagination.component";

@Component({
  selector: "app-admin-transaction-list",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    RouterLink,
    PaginationComponent,
    AdminSidePanelComponent,
    NgClass,
    JsonPipe,
  ],
  templateUrl: "./admin-transaction-list.component.html",
  styleUrls: ["./admin-transaction-list.component.scss"],
})
export class AdminTransactionListComponent {
  transactionService = inject(TransactionService);
  transactionsList: WritableSignal<ITransaction[]> = signal<ITransaction[]>([]);
  totalItems = signal(0);
  currentPage = signal(1);
  itemsPerPage = signal(10);
  transcationBannerContent = this.transactionService.getCurrentTransaction();
  private frontEndConfigService = inject(FrontendConfigService);
  frontendConfig = this.frontEndConfigService.getFrontendConfig();

  constructor() {
    this.fetchTransactions();
  }

  fetchTransactions() {
    this.transactionService.fetchTransactionsList({
      page: this.currentPage(),
      limit: this.itemsPerPage(),
    });
    this.transactionsList = this.transactionService.getTransactionsList();
    this.totalItems = this.transactionService.getTotalCount();
  }

  fetchMoreTransactions($event: number) {
    this.currentPage.set($event);
    this.fetchTransactions();
  }

  getStartIndex(): number {
    return (this.currentPage() - 1) * this.itemsPerPage() + 1;
  }

  getEndIndex(): number {
    const endIndex = this.currentPage() * this.itemsPerPage();
    return Math.min(endIndex, this.totalItems());
  }
}
