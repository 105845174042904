import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { JsonPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";

import { ResponsiveVideoComponent } from "../responsive-video/responsive-video.component";

@Component({
  selector: "app-video-dialog",
  templateUrl: "./video-dialog.component.html",
  styleUrls: ["./video-dialog.component.scss"],
  standalone: true,
  imports: [ResponsiveVideoComponent, JsonPipe],
})
export class VideoDialogComponent {
  constructor(
    public dialogRef: DialogRef<void>,
    @Inject(DIALOG_DATA)
    public data: {
      data: {
        videoURL: string;
      };
    }
  ) {}
}
