import { DatePipe } from "@angular/common";
import {
  Component,
  effect,
  inject,
  signal,
  WritableSignal,
} from "@angular/core";

import { AuthService } from "~features/auth/auth.service";
import { CompanylistService } from "~features/company/companylist.service";
import { EventGridArchivedComponent } from "~features/events/event-grid-archived/event-grid-archived.component";
import { EventGridUpcomingComponent } from "~features/events/event-grid-upcoming/event-grid-upcoming.component";
import { EventVideoHighlightComponent } from "~features/events/event-video-highlight/event-video-highlight.component";
import { EventService } from "~features/events/event.service";
import { formatDate } from "~features/events/event.utils";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { MoreContentCtaComponent } from "~shared/components/contentful/more-content-cta/more-content-cta.component";

@Component({
  selector: "app-videos-page",
  standalone: true,
  imports: [
    EventGridArchivedComponent,
    EventGridUpcomingComponent,
    MoreContentCtaComponent,
    EventVideoHighlightComponent,
    DatePipe,
  ],
  templateUrl: "./videos-page.component.html",
  styleUrl: "./videos-page.component.scss",
})
export class VideosPageComponent {
  adminMode = false;
  authService = inject(AuthService);
  eventService = inject(EventService);
  videoEvent = this.eventService.getRegistrationEvent();
  isCoveredCompany: WritableSignal<boolean> = signal(false);
  companyListService = inject(CompanylistService);
  loadingData = signal(true);
  data = signal({
    date: null,
    companyTicker: null,
    beDate: null,
    beTicker: null,
  });
  constructor() {
    this.data.update((currentData) => ({
      ...currentData,
      beDate: "2024-11-28-17-00", // This is in the format "2024-09-18-11-00"
      beTicker: "INH-GR",
      date: formatDate("2024-11-28-17-00", "toValid"),
      companyTicker: "INH-GR",
    }));
    effect(() => {
      const currentData = this.data();
      if (currentData.beDate && currentData.beTicker) {
        this.eventService.fetchRegistrationEvent(undefined, undefined);
      }
    }, enableSignalWritesInEffectContext);

    effect(() => {
      if (this.videoEvent()) {
        this.isCoveredCompany.set(
          this.companyListService.isCovered(this.videoEvent().companyName)
        );
        this.loadingData.set(false);
      }
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit(): void {
    this.adminMode = this.authService.isAdmin();
  }
}
