<div class="grid grid-cols-1 lg:grid-cols-2 gap-8">
  @if (filteredCompanyList()) {
    @for (company of displayedCompanies(); track company) {
      <app-company-grid-highlight-tile
        class="h-full"
        [company]="company"
      ></app-company-grid-highlight-tile>
    }
  }
</div>
@if (linkToAllStocks) {
  <div class="container mx-auto px-16 max-w-screen-2xl flex">
    <a class="mx-auto" [routerLink]="['/all-stocks']">
      <button
        class="mx-auto mt-5 text-gray-muted tracking-widest uppercase text-base font-medium"
      >
        Show all companies <span><i class="fas fa-arrow-right"></i></span>
      </button>
    </a>
  </div>
} @else {
  @if (shouldShowToggle()) {
    <div class="flex">
      <button
        (click)="toggleCompanyDisplay()"
        class="mx-auto mt-4 text-gray-muted tracking-widest uppercase text-base font-medium"
      >
        @if (!showAllCompanies()) {
          <span>show all ({{ remainingCompanies() }} more)</span>
          <i class="fas fa-arrow-down"></i>
        } @else {
          <span>show less</span>
          <i class="fas fa-arrow-up"></i>
        }
      </button>
    </div>
  }
}
