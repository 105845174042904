<div class="relative">
  <div class="overflow-hidden">
    <div
      class="slider-container flex"
      [style.transform]="'translateX(-' + currentIndex() * 100 + '%)'"
    >
      <!-- transition-transform duration-300 removed for no slider animation-->
      <ng-content></ng-content>
    </div>
  </div>
  <button
    (click)="prevSlide()"
    class="absolute top-1/2 -left-8 transform -translate-y-1/2 p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-6 h-6 fill-current text-gray-400 hover:text-black transition duration-300"
      viewBox="0 0 8 8"
    >
      <path d="M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z" />
    </svg>
  </button>
  <button
    (click)="nextSlide()"
    class="absolute top-1/2 -right-8 transform -translate-y-1/2 p-2"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-6 h-6 fill-current text-gray-400 hover:text-black transition duration-300"
      viewBox="0 0 8 8"
    >
      <path d="M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z" />
    </svg>
  </button>
  <div
    class="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2"
  >
    @for (slide of visualDots(); track slide; let i = $index) {
      <div
        class="w-3 h-3 rounded-full"
        [ngClass]="{
          'bg-gray-400': i !== currentIndex(),
          'bg-black': i === currentIndex()
        }"
      ></div>
    }
  </div>
</div>
