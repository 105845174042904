import { AsyncPipe, DecimalPipe, JsonPipe, NgClass } from "@angular/common";
import {
  Component,
  effect,
  inject,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from "@angular/core";
import { RouterLink } from "@angular/router";
import { ICompany } from "~global-interfaces/ICompany";

import { CompanyResearchService } from "~features/company/company-research.service";
import { ExcelService } from "~features/company/excel.service";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";
import { CountryFlagComponent } from "~shared/components/ui/country-flag/country-flag.component";
import { LogoComponent } from "~shared/components/ui/logo/logo.component";
import { getNumberColor, getTextColor } from "~utils/coloring.utils";

@Component({
  selector: "app-company-grid-highlight-tile",
  standalone: true,
  imports: [
    JsonPipe,
    DecimalPipe,
    AsyncPipe,
    LogoComponent,
    RouterLink,
    NgClass,
    CountryFlagComponent,
  ],
  templateUrl: "./company-grid-highlight-tile.component.html",
  styleUrl: "./company-grid-highlight-tile.component.scss",
})
export class CompanyGridHighlightTileComponent implements OnInit {
  companyResearchService = inject(CompanyResearchService);
  excelService = inject(ExcelService);
  @Input() company: ICompany;
  latestResearchTitle: WritableSignal<string> = signal("");
  recColors: object = {
    "Spec. BUY": "#10B981",
    BUY: "#10B981",
    HOLD: "#F59E0B",
    SELL: "#F87171",
  };

  mergedTileData: WritableSignal<any | null> = signal(null);
  protected readonly getTextColor = getTextColor;
  protected readonly getNumberColor = getNumberColor;

  constructor() {
    effect(() => {
      this.excelService
        .fetchUniqueLiveQuotesAndTileData(this.company.companyTicker)
        .subscribe(([liveQuote, tileData]) => {
          this.company = {
            ...this.company,
            liveQuote: liveQuote,
            tileData: tileData[0],
          };
        });
    }, enableSignalWritesInEffectContext);
  }

  ngOnInit() {
    this.companyResearchService
      .fetchUniqueResearch(this.company.companyTicker)
      .subscribe((data) => {
        if (data) {
          this.latestResearchTitle.set(data.title);
        }
      });
    // console.log("in tile merged signal ", this.mergedTileData());
  }

  getColourClass(badge: string) {
    if (badge === "Initiation") {
      return "green";
    } else if (badge === "Update") {
      return "yellow";
    } else if (badge === "Transaction") {
      return "red";
    }
  }
}

// const liveQuoteData = Array.isArray(this.liveQuote())
//   ? this.liveQuote()
//   : [this.liveQuote()];
// const tileDataArray = this.tileData();
//
// if (this.companyList() && this.liveQuote() && this.tileData()) {
//   const companies = this.companyList()
//     .filter(
//       (company) =>
//         company.is_public === true &&
//         ["Update", "Initiation", "Transaction"].includes(company.isFeatured)
//     )
//     .map((company) => {
//       const liveQuote = liveQuoteData.find(
//         (item) =>
//           item.code ===
//           this.exchangeMappingService.getMappedExchange(
//             company.companyTicker
//           )
//       );
//       const tileData = tileDataArray.find(
//         (item) => item.CompanyId === company.companyTicker
//       );
//
//       return {
//         ...company,
//         liveQuote: liveQuote,
//         tileData: tileData,
//       };
//     })
//     .filter(
//       (company) =>
//         company.liveQuote &&
//         company.tileData &&
//         company.tileData.general_information
//     );
