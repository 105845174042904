<div class="video-container z-30">
  @if (videoURL) {
    <iframe
      class="z-30"
      [src]="embedUrl"
      width="100%"
      height="100%"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      loading="lazy"
    ></iframe>
  } @else {
    <div class="loading-placeholder">
      <span>No video available</span>
    </div>
  }
</div>
