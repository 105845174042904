import { HttpClient } from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { IQueryParams } from "~global-interfaces/IQueryParams";

import { alsterAPI_URL } from "~api/api-urls";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class AdminCompanyListService extends PaginationService<any> {
  isLoading: WritableSignal<boolean> = signal(false);

  constructor(http: HttpClient) {
    super(http);
  }

  getisLoading() {
    return this.isLoading;
  }
  public fetchCompanyAdminList(paginationParams: IQueryParams) {
    const queryParams = { ...paginationParams, hidden: true };
    this.isLoading.set(true);
    return this.fetchItems(alsterAPI_URL + "companies", queryParams).subscribe(
      ({ items, totalCount }) => {
        this.items.set(items.sort((a, b) => a.name.localeCompare(b.name)));
        this.totalCount.set(totalCount || 0);
        this.isLoading.set(false);
      }
    );
  }
}
