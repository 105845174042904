var e = "undefined" != typeof window ? window : void 0,
  t = "undefined" != typeof globalThis ? globalThis : e,
  i = Array.prototype,
  s = i.forEach,
  r = i.indexOf,
  n = null == t ? void 0 : t.navigator,
  o = null == t ? void 0 : t.document,
  a = null == t ? void 0 : t.location,
  l = null == t ? void 0 : t.fetch,
  u = null != t && t.XMLHttpRequest && "withCredentials" in new t.XMLHttpRequest() ? t.XMLHttpRequest : void 0,
  c = null == t ? void 0 : t.AbortController,
  d = null == n ? void 0 : n.userAgent,
  h = null != e ? e : {},
  _ = {
    DEBUG: !1,
    LIB_VERSION: "1.194.1"
  },
  p = {},
  v = function (e) {
    return e.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
  };
function g(e, t, i) {
  if (D(e)) if (s && e.forEach === s) e.forEach(t, i);else if ("length" in e && e.length === +e.length) for (var r = 0, n = e.length; r < n; r++) if (r in e && t.call(i, e[r], r) === p) return;
}
function f(e, t, i) {
  if (!j(e)) {
    if (D(e)) return g(e, t, i);
    if (Q(e)) {
      for (var s of e.entries()) if (t.call(i, s[1], s[0]) === p) return;
    } else for (var r in e) if (L.call(e, r) && t.call(i, e[r], r) === p) return;
  }
}
var m = function (e) {
  for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), s = 1; s < t; s++) i[s - 1] = arguments[s];
  return g(i, function (t) {
    for (var i in t) void 0 !== t[i] && (e[i] = t[i]);
  }), e;
};
function b(e, t) {
  return -1 !== e.indexOf(t);
}
function y(e) {
  for (var t = Object.keys(e), i = t.length, s = new Array(i); i--;) s[i] = [t[i], e[t[i]]];
  return s;
}
var w = function (e) {
    try {
      return e();
    } catch (e) {
      return;
    }
  },
  S = function (e) {
    return function () {
      try {
        for (var t = arguments.length, i = new Array(t), s = 0; s < t; s++) i[s] = arguments[s];
        return e.apply(this, i);
      } catch (e) {
        X.critical("Implementation error. Please turn on debug mode and open a ticket on https://app.posthog.com/home#panel=support%3Asupport%3A."), X.critical(e);
      }
    };
  },
  E = function (e) {
    var t = {};
    return f(e, function (e, i) {
      U(e) && e.length > 0 && (t[i] = e);
    }), t;
  },
  k = function (e) {
    return e.replace(/^\$/, "");
  };
function x(e, t) {
  return i = e, s = e => U(e) && !W(t) ? e.slice(0, t) : e, r = new Set(), function e(t, i) {
    return t !== Object(t) ? s ? s(t, i) : t : r.has(t) ? void 0 : (r.add(t), D(t) ? (n = [], g(t, t => {
      n.push(e(t));
    })) : (n = {}, f(t, (t, i) => {
      r.has(t) || (n[i] = e(t, i));
    })), n);
    var n;
  }(i);
  var i, s, r;
}
var I = function (e) {
    var t,
      i,
      s,
      r,
      n = "";
    for (t = i = 0, s = (e = (e + "").replace(/\r\n/g, "\n").replace(/\r/g, "\n")).length, r = 0; r < s; r++) {
      var o = e.charCodeAt(r),
        a = null;
      o < 128 ? i++ : a = o > 127 && o < 2048 ? String.fromCharCode(o >> 6 | 192, 63 & o | 128) : String.fromCharCode(o >> 12 | 224, o >> 6 & 63 | 128, 63 & o | 128), W(a) || (i > t && (n += e.substring(t, i)), n += a, t = i = r + 1);
    }
    return i > t && (n += e.substring(t, e.length)), n;
  },
  P = function () {
    function t(e) {
      return e && (e.preventDefault = t.preventDefault, e.stopPropagation = t.stopPropagation), e;
    }
    return t.preventDefault = function () {
      this.returnValue = !1;
    }, t.stopPropagation = function () {
      this.cancelBubble = !0;
    }, function (i, s, r, n, o) {
      if (i) {
        if (i.addEventListener && !n) i.addEventListener(s, r, !!o);else {
          var a = "on" + s,
            l = i[a];
          i[a] = function (i, s, r) {
            return function (n) {
              if (n = n || t(null == e ? void 0 : e.event)) {
                var o,
                  a = !0;
                N(r) && (o = r(n));
                var l = s.call(i, n);
                return !1 !== o && !1 !== l || (a = !1), a;
              }
            };
          }(i, r, l);
        }
      } else X.error("No valid element provided to register_event");
    };
  }();
function F(e, t) {
  for (var i = 0; i < e.length; i++) if (t(e[i])) return e[i];
}
var R,
  C = "$copy_autocapture",
  T = ["$snapshot", "$pageview", "$pageleave", "$set", "survey dismissed", "survey sent", "survey shown", "$identify", "$groupidentify", "$create_alias", "$$client_ingestion_warning", "$web_experiment_applied", "$feature_enrollment_update", "$feature_flag_called"];
!function (e) {
  e.GZipJS = "gzip-js", e.Base64 = "base64";
}(R || (R = {}));
var $ = ["fatal", "error", "warning", "log", "info", "debug"],
  M = Array.isArray,
  A = Object.prototype,
  L = A.hasOwnProperty,
  O = A.toString,
  D = M || function (e) {
    return "[object Array]" === O.call(e);
  },
  N = e => "function" == typeof e,
  q = e => e === Object(e) && !D(e),
  B = e => {
    if (q(e)) {
      for (var t in e) if (L.call(e, t)) return !1;
      return !0;
    }
    return !1;
  },
  H = e => void 0 === e,
  U = e => "[object String]" == O.call(e),
  z = e => U(e) && 0 === e.trim().length,
  W = e => null === e,
  j = e => H(e) || W(e),
  V = e => "[object Number]" == O.call(e),
  G = e => "[object Boolean]" === O.call(e),
  Q = e => e instanceof FormData,
  Y = e => b(T, e),
  J = "[PostHog.js]",
  X = {
    _log: function (t) {
      if (e && (_.DEBUG || h.POSTHOG_DEBUG) && !H(e.console) && e.console) {
        for (var i = ("__rrweb_original__" in e.console[t]) ? e.console[t].__rrweb_original__ : e.console[t], s = arguments.length, r = new Array(s > 1 ? s - 1 : 0), n = 1; n < s; n++) r[n - 1] = arguments[n];
        i(J, ...r);
      }
    },
    info: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      X._log("log", ...t);
    },
    warn: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      X._log("warn", ...t);
    },
    error: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      X._log("error", ...t);
    },
    critical: function () {
      for (var e = arguments.length, t = new Array(e), i = 0; i < e; i++) t[i] = arguments[i];
      console.error(J, ...t);
    },
    uninitializedWarning: e => {
      X.error("You must initialize PostHog before calling ".concat(e));
    }
  },
  K = (e, t, i) => {
    if (e.config.disable_external_dependency_loading) return X.warn("".concat(t, " was requested but loading of external scripts is disabled.")), i("Loading of external scripts is disabled");
    var s = () => {
      if (!o) return i("document not found");
      var e = o.createElement("script");
      e.type = "text/javascript", e.crossOrigin = "anonymous", e.src = t, e.onload = e => i(void 0, e), e.onerror = e => i(e);
      var s,
        r = o.querySelectorAll("body > script");
      r.length > 0 ? null === (s = r[0].parentNode) || void 0 === s || s.insertBefore(e, r[0]) : o.body.appendChild(e);
    };
    null != o && o.body ? s() : null == o || o.addEventListener("DOMContentLoaded", s);
  };
function Z(e, t) {
  var i = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var s = Object.getOwnPropertySymbols(e);
    t && (s = s.filter(function (t) {
      return Object.getOwnPropertyDescriptor(e, t).enumerable;
    })), i.push.apply(i, s);
  }
  return i;
}
function ee(e) {
  for (var t = 1; t < arguments.length; t++) {
    var i = null != arguments[t] ? arguments[t] : {};
    t % 2 ? Z(Object(i), !0).forEach(function (t) {
      te(e, t, i[t]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(i)) : Z(Object(i)).forEach(function (t) {
      Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(i, t));
    });
  }
  return e;
}
function te(e, t, i) {
  return t in e ? Object.defineProperty(e, t, {
    value: i,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = i, e;
}
function ie(e, t) {
  if (null == e) return {};
  var i,
    s,
    r = function (e, t) {
      if (null == e) return {};
      var i,
        s,
        r = {},
        n = Object.keys(e);
      for (s = 0; s < n.length; s++) i = n[s], t.indexOf(i) >= 0 || (r[i] = e[i]);
      return r;
    }(e, t);
  if (Object.getOwnPropertySymbols) {
    var n = Object.getOwnPropertySymbols(e);
    for (s = 0; s < n.length; s++) i = n[s], t.indexOf(i) >= 0 || Object.prototype.propertyIsEnumerable.call(e, i) && (r[i] = e[i]);
  }
  return r;
}
h.__PosthogExtensions__ = h.__PosthogExtensions__ || {}, h.__PosthogExtensions__.loadExternalDependency = (e, t, i) => {
  var s = "/static/".concat(t, ".js") + "?v=".concat(e.version);
  if ("toolbar" === t) {
    var r = 3e5,
      n = Math.floor(Date.now() / r) * r;
    s = "".concat(s, "&t=").concat(n);
  }
  var o = e.requestRouter.endpointFor("assets", s);
  K(e, o, i);
}, h.__PosthogExtensions__.loadSiteApp = (e, t, i) => {
  var s = e.requestRouter.endpointFor("api", t);
  K(e, s, i);
};
var se = "$people_distinct_id",
  re = "__alias",
  ne = "__timers",
  oe = "$autocapture_disabled_server_side",
  ae = "$heatmaps_enabled_server_side",
  le = "$exception_capture_enabled_server_side",
  ue = "$web_vitals_enabled_server_side",
  ce = "$dead_clicks_enabled_server_side",
  de = "$web_vitals_allowed_metrics",
  he = "$session_recording_enabled_server_side",
  _e = "$console_log_recording_enabled_server_side",
  pe = "$session_recording_network_payload_capture",
  ve = "$session_recording_canvas_recording",
  ge = "$replay_sample_rate",
  fe = "$replay_minimum_duration",
  me = "$replay_script_config",
  be = "$sesid",
  ye = "$session_is_sampled",
  we = "$session_recording_url_trigger_activated_session",
  Se = "$session_recording_event_trigger_activated_session",
  Ee = "$enabled_feature_flags",
  ke = "$early_access_features",
  xe = "$stored_person_properties",
  Ie = "$stored_group_properties",
  Pe = "$surveys",
  Fe = "$surveys_activated",
  Re = "$flag_call_reported",
  Ce = "$user_state",
  Te = "$client_session_props",
  $e = "$capture_rate_limit",
  Me = "$initial_campaign_params",
  Ae = "$initial_referrer_info",
  Le = "$initial_person_info",
  Oe = "$epp",
  De = "__POSTHOG_TOOLBAR__",
  Ne = [se, re, "__cmpns", ne, he, ae, be, Ee, Ce, ke, Ie, xe, Pe, Re, Te, $e, Me, Ae, Oe],
  qe = "$active_feature_flags",
  Be = "$override_feature_flags",
  He = "$feature_flag_payloads",
  Ue = e => {
    var t = {};
    for (var [i, s] of y(e || {})) s && (t[i] = s);
    return t;
  };
class ze {
  constructor(e) {
    this.instance = e, this._override_warning = !1, this.featureFlagEventHandlers = [], this.reloadFeatureFlagsQueued = !1, this.reloadFeatureFlagsInAction = !1;
  }
  getFlags() {
    return Object.keys(this.getFlagVariants());
  }
  getFlagVariants() {
    var e = this.instance.get_property(Ee),
      t = this.instance.get_property(Be);
    if (!t) return e || {};
    for (var i = m({}, e), s = Object.keys(t), r = 0; r < s.length; r++) i[s[r]] = t[s[r]];
    return this._override_warning || (X.warn(" Overriding feature flags!", {
      enabledFlags: e,
      overriddenFlags: t,
      finalFlags: i
    }), this._override_warning = !0), i;
  }
  getFlagPayloads() {
    return this.instance.get_property(He) || {};
  }
  reloadFeatureFlags() {
    this.reloadFeatureFlagsQueued || (this.reloadFeatureFlagsQueued = !0, this._startReloadTimer());
  }
  setAnonymousDistinctId(e) {
    this.$anon_distinct_id = e;
  }
  setReloadingPaused(e) {
    this.reloadFeatureFlagsInAction = e;
  }
  resetRequestQueue() {
    this.reloadFeatureFlagsQueued = !1;
  }
  _startReloadTimer() {
    this.reloadFeatureFlagsQueued && !this.reloadFeatureFlagsInAction && setTimeout(() => {
      !this.reloadFeatureFlagsInAction && this.reloadFeatureFlagsQueued && (this.reloadFeatureFlagsQueued = !1, this._reloadFeatureFlagsRequest());
    }, 5);
  }
  _reloadFeatureFlagsRequest() {
    if (!this.instance.config.advanced_disable_feature_flags) {
      this.setReloadingPaused(!0);
      var e = this.instance.config.token,
        t = this.instance.get_property(xe),
        i = this.instance.get_property(Ie),
        s = {
          token: e,
          distinct_id: this.instance.get_distinct_id(),
          groups: this.instance.getGroups(),
          $anon_distinct_id: this.$anon_distinct_id,
          person_properties: t,
          group_properties: i,
          disable_flags: this.instance.config.advanced_disable_feature_flags || void 0
        };
      this.instance._send_request({
        method: "POST",
        url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
        data: s,
        compression: this.instance.config.disable_compression ? void 0 : R.Base64,
        timeout: this.instance.config.feature_flag_request_timeout_ms,
        callback: e => {
          var t;
          this.setReloadingPaused(!1);
          var i = !0;
          200 === e.statusCode && (this.$anon_distinct_id = void 0, i = !1), this.receivedFeatureFlags(null !== (t = e.json) && void 0 !== t ? t : {}, i), this._startReloadTimer();
        }
      });
    }
  }
  getFeatureFlag(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) {
      var i,
        s = this.getFlagVariants()[e],
        r = "".concat(s),
        n = this.instance.get_property(Re) || {};
      if (t.send_event || !("send_event" in t)) if (!(e in n) || !n[e].includes(r)) D(n[e]) ? n[e].push(r) : n[e] = [r], null === (i = this.instance.persistence) || void 0 === i || i.register({
        [Re]: n
      }), this.instance.capture("$feature_flag_called", {
        $feature_flag: e,
        $feature_flag_response: s
      });
      return s;
    }
    X.warn('getFeatureFlag for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  getFeatureFlagPayload(e) {
    return this.getFlagPayloads()[e];
  }
  isFeatureEnabled(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    if (this.instance.decideEndpointWasHit || this.getFlags() && this.getFlags().length > 0) return !!this.getFeatureFlag(e, t);
    X.warn('isFeatureEnabled for key "' + e + "\" failed. Feature flags didn't load in time.");
  }
  addFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers.push(e);
  }
  removeFeatureFlagsHandler(e) {
    this.featureFlagEventHandlers = this.featureFlagEventHandlers.filter(t => t !== e);
  }
  receivedFeatureFlags(e, t) {
    if (this.instance.persistence) {
      this.instance.decideEndpointWasHit = !0;
      var i = this.getFlagVariants(),
        s = this.getFlagPayloads();
      !function (e, t) {
        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
          s = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {},
          r = e.featureFlags,
          n = e.featureFlagPayloads;
        if (r) if (D(r)) {
          var o = {};
          if (r) for (var a = 0; a < r.length; a++) o[r[a]] = !0;
          t && t.register({
            [qe]: r,
            [Ee]: o
          });
        } else {
          var l = r,
            u = n;
          e.errorsWhileComputingFlags && (l = ee(ee({}, i), l), u = ee(ee({}, s), u)), t && t.register({
            [qe]: Object.keys(Ue(l)),
            [Ee]: l || {},
            [He]: u || {}
          });
        }
      }(e, this.instance.persistence, i, s), this._fireFeatureFlagsCallbacks(t);
    }
  }
  override(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (!this.instance.__loaded || !this.instance.persistence) return X.uninitializedWarning("posthog.feature_flags.override");
    if (this._override_warning = t, !1 === e) this.instance.persistence.unregister(Be);else if (D(e)) {
      for (var i = {}, s = 0; s < e.length; s++) i[e[s]] = !0;
      this.instance.persistence.register({
        [Be]: i
      });
    } else this.instance.persistence.register({
      [Be]: e
    });
  }
  onFeatureFlags(e) {
    if (this.addFeatureFlagsHandler(e), this.instance.decideEndpointWasHit) {
      var {
        flags: t,
        flagVariants: i
      } = this._prepareFeatureFlagsForCallbacks();
      e(t, i);
    }
    return () => this.removeFeatureFlagsHandler(e);
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    var i,
      s = {
        ["$feature_enrollment/".concat(e)]: t
      };
    this.instance.capture("$feature_enrollment_update", {
      $feature_flag: e,
      $feature_enrollment: t,
      $set: s
    }), this.setPersonPropertiesForFlags(s, !1);
    var r = ee(ee({}, this.getFlagVariants()), {}, {
      [e]: t
    });
    null === (i = this.instance.persistence) || void 0 === i || i.register({
      [qe]: Object.keys(Ue(r)),
      [Ee]: r
    }), this._fireFeatureFlagsCallbacks();
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1],
      i = this.instance.get_property(ke);
    if (i && !t) return e(i);
    this.instance._send_request({
      transport: "XHR",
      url: this.instance.requestRouter.endpointFor("api", "/api/early_access_features/?token=".concat(this.instance.config.token)),
      method: "GET",
      callback: t => {
        var i;
        if (t.json) {
          var s = t.json.earlyAccessFeatures;
          return null === (i = this.instance.persistence) || void 0 === i || i.register({
            [ke]: s
          }), e(s);
        }
      }
    });
  }
  _prepareFeatureFlagsForCallbacks() {
    var e = this.getFlags(),
      t = this.getFlagVariants();
    return {
      flags: e.filter(e => t[e]),
      flagVariants: Object.keys(t).filter(e => t[e]).reduce((e, i) => (e[i] = t[i], e), {})
    };
  }
  _fireFeatureFlagsCallbacks(e) {
    var {
      flags: t,
      flagVariants: i
    } = this._prepareFeatureFlagsForCallbacks();
    this.featureFlagEventHandlers.forEach(s => s(t, i, {
      errorsLoading: e
    }));
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(xe) || {};
    this.instance.register({
      [xe]: ee(ee({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetPersonPropertiesForFlags() {
    this.instance.unregister(xe);
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
      i = this.instance.get_property(Ie) || {};
    0 !== Object.keys(i).length && Object.keys(i).forEach(t => {
      i[t] = ee(ee({}, i[t]), e[t]), delete e[t];
    }), this.instance.register({
      [Ie]: ee(ee({}, i), e)
    }), t && this.instance.reloadFeatureFlags();
  }
  resetGroupPropertiesForFlags(e) {
    if (e) {
      var t = this.instance.get_property(Ie) || {};
      this.instance.register({
        [Ie]: ee(ee({}, t), {}, {
          [e]: {}
        })
      });
    } else this.instance.unregister(Ie);
  }
}
Math.trunc || (Math.trunc = function (e) {
  return e < 0 ? Math.ceil(e) : Math.floor(e);
}), Number.isInteger || (Number.isInteger = function (e) {
  return V(e) && isFinite(e) && Math.floor(e) === e;
});
var We = "0123456789abcdef";
class je {
  constructor(e) {
    if (this.bytes = e, 16 !== e.length) throw new TypeError("not 128-bit length");
  }
  static fromFieldsV7(e, t, i, s) {
    if (!Number.isInteger(e) || !Number.isInteger(t) || !Number.isInteger(i) || !Number.isInteger(s) || e < 0 || t < 0 || i < 0 || s < 0 || e > 0xffffffffffff || t > 4095 || i > 1073741823 || s > 4294967295) throw new RangeError("invalid field value");
    var r = new Uint8Array(16);
    return r[0] = e / Math.pow(2, 40), r[1] = e / Math.pow(2, 32), r[2] = e / Math.pow(2, 24), r[3] = e / Math.pow(2, 16), r[4] = e / Math.pow(2, 8), r[5] = e, r[6] = 112 | t >>> 8, r[7] = t, r[8] = 128 | i >>> 24, r[9] = i >>> 16, r[10] = i >>> 8, r[11] = i, r[12] = s >>> 24, r[13] = s >>> 16, r[14] = s >>> 8, r[15] = s, new je(r);
  }
  toString() {
    for (var e = "", t = 0; t < this.bytes.length; t++) e = e + We.charAt(this.bytes[t] >>> 4) + We.charAt(15 & this.bytes[t]), 3 !== t && 5 !== t && 7 !== t && 9 !== t || (e += "-");
    if (36 !== e.length) throw new Error("Invalid UUIDv7 was generated");
    return e;
  }
  clone() {
    return new je(this.bytes.slice(0));
  }
  equals(e) {
    return 0 === this.compareTo(e);
  }
  compareTo(e) {
    for (var t = 0; t < 16; t++) {
      var i = this.bytes[t] - e.bytes[t];
      if (0 !== i) return Math.sign(i);
    }
    return 0;
  }
}
class Ve {
  constructor() {
    te(this, "timestamp", 0), te(this, "counter", 0), te(this, "random", new Ye());
  }
  generate() {
    var e = this.generateOrAbort();
    if (H(e)) {
      this.timestamp = 0;
      var t = this.generateOrAbort();
      if (H(t)) throw new Error("Could not generate UUID after timestamp reset");
      return t;
    }
    return e;
  }
  generateOrAbort() {
    var e = Date.now();
    if (e > this.timestamp) this.timestamp = e, this.resetCounter();else {
      if (!(e + 1e4 > this.timestamp)) return;
      this.counter++, this.counter > 4398046511103 && (this.timestamp++, this.resetCounter());
    }
    return je.fromFieldsV7(this.timestamp, Math.trunc(this.counter / Math.pow(2, 30)), this.counter & Math.pow(2, 30) - 1, this.random.nextUint32());
  }
  resetCounter() {
    this.counter = 1024 * this.random.nextUint32() + (1023 & this.random.nextUint32());
  }
}
var Ge,
  Qe = e => {
    if ("undefined" != typeof UUIDV7_DENY_WEAK_RNG && UUIDV7_DENY_WEAK_RNG) throw new Error("no cryptographically strong RNG available");
    for (var t = 0; t < e.length; t++) e[t] = 65536 * Math.trunc(65536 * Math.random()) + Math.trunc(65536 * Math.random());
    return e;
  };
e && !H(e.crypto) && crypto.getRandomValues && (Qe = e => crypto.getRandomValues(e));
class Ye {
  constructor() {
    te(this, "buffer", new Uint32Array(8)), te(this, "cursor", 1 / 0);
  }
  nextUint32() {
    return this.cursor >= this.buffer.length && (Qe(this.buffer), this.cursor = 0), this.buffer[this.cursor++];
  }
}
var Je = () => Xe().toString(),
  Xe = () => (Ge || (Ge = new Ve())).generate(),
  Ke = "Thu, 01 Jan 1970 00:00:00 GMT",
  Ze = "";
var et = /[a-z0-9][a-z0-9-]+\.[a-z]{2,}$/i;
function tt(e, t) {
  if (t) {
    var i = function (e) {
      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : o;
      if (Ze) return Ze;
      if (!t) return "";
      if (["localhost", "127.0.0.1"].includes(e)) return "";
      for (var i = e.split("."), s = Math.min(i.length, 8), r = "dmn_chk_" + Je(), n = new RegExp("(^|;)\\s*" + r + "=1"); !Ze && s--;) {
        var a = i.slice(s).join("."),
          l = r + "=1;domain=." + a;
        t.cookie = l, n.test(t.cookie) && (t.cookie = l + ";expires=" + Ke, Ze = a);
      }
      return Ze;
    }(e);
    if (!i) {
      var s = (e => {
        var t = e.match(et);
        return t ? t[0] : "";
      })(e);
      s !== i && X.info("Warning: cookie subdomain discovery mismatch", s, i), i = s;
    }
    return i ? "; domain=." + i : "";
  }
  return "";
}
var it = {
    is_supported: () => !!o,
    error: function (e) {
      X.error("cookieStore error: " + e);
    },
    get: function (e) {
      if (o) {
        try {
          for (var t = e + "=", i = o.cookie.split(";").filter(e => e.length), s = 0; s < i.length; s++) {
            for (var r = i[s]; " " == r.charAt(0);) r = r.substring(1, r.length);
            if (0 === r.indexOf(t)) return decodeURIComponent(r.substring(t.length, r.length));
          }
        } catch (e) {}
        return null;
      }
    },
    parse: function (e) {
      var t;
      try {
        t = JSON.parse(it.get(e)) || {};
      } catch (e) {}
      return t;
    },
    set: function (e, t, i, s, r) {
      if (o) try {
        var n = "",
          a = "",
          l = tt(o.location.hostname, s);
        if (i) {
          var u = new Date();
          u.setTime(u.getTime() + 24 * i * 60 * 60 * 1e3), n = "; expires=" + u.toUTCString();
        }
        r && (a = "; secure");
        var c = e + "=" + encodeURIComponent(JSON.stringify(t)) + n + "; SameSite=Lax; path=/" + l + a;
        return c.length > 3686.4 && X.warn("cookieStore warning: large cookie, len=" + c.length), o.cookie = c, c;
      } catch (e) {
        return;
      }
    },
    remove: function (e, t) {
      try {
        it.set(e, "", -1, t);
      } catch (e) {
        return;
      }
    }
  },
  st = null,
  rt = {
    is_supported: function () {
      if (!W(st)) return st;
      var t = !0;
      if (H(e)) t = !1;else try {
        var i = "__mplssupport__";
        rt.set(i, "xyz"), '"xyz"' !== rt.get(i) && (t = !1), rt.remove(i);
      } catch (e) {
        t = !1;
      }
      return t || X.error("localStorage unsupported; falling back to cookie store"), st = t, t;
    },
    error: function (e) {
      X.error("localStorage error: " + e);
    },
    get: function (t) {
      try {
        return null == e ? void 0 : e.localStorage.getItem(t);
      } catch (e) {
        rt.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(rt.get(e)) || {};
      } catch (e) {}
      return null;
    },
    set: function (t, i) {
      try {
        null == e || e.localStorage.setItem(t, JSON.stringify(i));
      } catch (e) {
        rt.error(e);
      }
    },
    remove: function (t) {
      try {
        null == e || e.localStorage.removeItem(t);
      } catch (e) {
        rt.error(e);
      }
    }
  },
  nt = ["distinct_id", be, ye, Oe, Le],
  ot = ee(ee({}, rt), {}, {
    parse: function (e) {
      try {
        var t = {};
        try {
          t = it.parse(e) || {};
        } catch (e) {}
        var i = m(t, JSON.parse(rt.get(e) || "{}"));
        return rt.set(e, i), i;
      } catch (e) {}
      return null;
    },
    set: function (e, t, i, s, r, n) {
      try {
        rt.set(e, t, void 0, void 0, n);
        var o = {};
        nt.forEach(e => {
          t[e] && (o[e] = t[e]);
        }), Object.keys(o).length && it.set(e, o, i, s, r, n);
      } catch (e) {
        rt.error(e);
      }
    },
    remove: function (t, i) {
      try {
        null == e || e.localStorage.removeItem(t), it.remove(t, i);
      } catch (e) {
        rt.error(e);
      }
    }
  }),
  at = {},
  lt = {
    is_supported: function () {
      return !0;
    },
    error: function (e) {
      X.error("memoryStorage error: " + e);
    },
    get: function (e) {
      return at[e] || null;
    },
    parse: function (e) {
      return at[e] || null;
    },
    set: function (e, t) {
      at[e] = t;
    },
    remove: function (e) {
      delete at[e];
    }
  },
  ut = null,
  ct = {
    is_supported: function () {
      if (!W(ut)) return ut;
      if (ut = !0, H(e)) ut = !1;else try {
        var t = "__support__";
        ct.set(t, "xyz"), '"xyz"' !== ct.get(t) && (ut = !1), ct.remove(t);
      } catch (e) {
        ut = !1;
      }
      return ut;
    },
    error: function (e) {
      X.error("sessionStorage error: ", e);
    },
    get: function (t) {
      try {
        return null == e ? void 0 : e.sessionStorage.getItem(t);
      } catch (e) {
        ct.error(e);
      }
      return null;
    },
    parse: function (e) {
      try {
        return JSON.parse(ct.get(e)) || null;
      } catch (e) {}
      return null;
    },
    set: function (t, i) {
      try {
        null == e || e.sessionStorage.setItem(t, JSON.stringify(i));
      } catch (e) {
        ct.error(e);
      }
    },
    remove: function (t) {
      try {
        null == e || e.sessionStorage.removeItem(t);
      } catch (e) {
        ct.error(e);
      }
    }
  },
  dt = ["localhost", "127.0.0.1"],
  ht = e => {
    var t = null == o ? void 0 : o.createElement("a");
    return H(t) ? null : (t.href = e, t);
  },
  _t = function (e, t) {
    return !!function (e) {
      try {
        new RegExp(e);
      } catch (e) {
        return !1;
      }
      return !0;
    }(t) && new RegExp(t).test(e);
  },
  pt = function (e) {
    var t,
      i,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "&",
      r = [];
    return f(e, function (e, s) {
      H(e) || H(s) || "undefined" === s || (t = encodeURIComponent((e => e instanceof File)(e) ? e.name : e.toString()), i = encodeURIComponent(s), r[r.length] = i + "=" + t);
    }), r.join(s);
  },
  vt = function (e, t) {
    for (var i, s = ((e.split("#")[0] || "").split("?")[1] || "").split("&"), r = 0; r < s.length; r++) {
      var n = s[r].split("=");
      if (n[0] === t) {
        i = n;
        break;
      }
    }
    if (!D(i) || i.length < 2) return "";
    var o = i[1];
    try {
      o = decodeURIComponent(o);
    } catch (e) {
      X.error("Skipping decoding for malformed query param: " + o);
    }
    return o.replace(/\+/g, " ");
  },
  gt = function (e, t) {
    var i = e.match(new RegExp(t + "=([^&]*)"));
    return i ? i[1] : null;
  },
  ft = "Mobile",
  mt = "iOS",
  bt = "Android",
  yt = "Tablet",
  wt = bt + " " + yt,
  St = "iPad",
  Et = "Apple",
  kt = Et + " Watch",
  xt = "Safari",
  It = "BlackBerry",
  Pt = "Samsung",
  Ft = Pt + "Browser",
  Rt = Pt + " Internet",
  Ct = "Chrome",
  Tt = Ct + " OS",
  $t = Ct + " " + mt,
  Mt = "Internet Explorer",
  At = Mt + " " + ft,
  Lt = "Opera",
  Ot = Lt + " Mini",
  Dt = "Edge",
  Nt = "Microsoft " + Dt,
  qt = "Firefox",
  Bt = qt + " " + mt,
  Ht = "Nintendo",
  Ut = "PlayStation",
  zt = "Xbox",
  Wt = bt + " " + ft,
  jt = ft + " " + xt,
  Vt = "Windows",
  Gt = Vt + " Phone",
  Qt = "Nokia",
  Yt = "Ouya",
  Jt = "Generic",
  Xt = Jt + " " + ft.toLowerCase(),
  Kt = Jt + " " + yt.toLowerCase(),
  Zt = "Konqueror",
  ei = "(\\d+(\\.\\d+)?)",
  ti = new RegExp("Version/" + ei),
  ii = new RegExp(zt, "i"),
  si = new RegExp(Ut + " \\w+", "i"),
  ri = new RegExp(Ht + " \\w+", "i"),
  ni = new RegExp(It + "|PlayBook|BB10", "i"),
  oi = {
    "NT3.51": "NT 3.11",
    "NT4.0": "NT 4.0",
    "5.0": "2000",
    5.1: "XP",
    5.2: "XP",
    "6.0": "Vista",
    6.1: "7",
    6.2: "8",
    6.3: "8.1",
    6.4: "10",
    "10.0": "10"
  };
var ai = (e, t) => t && b(t, Et) || function (e) {
    return b(e, xt) && !b(e, Ct) && !b(e, bt);
  }(e),
  li = function (e, t) {
    return t = t || "", b(e, " OPR/") && b(e, "Mini") ? Ot : b(e, " OPR/") ? Lt : ni.test(e) ? It : b(e, "IE" + ft) || b(e, "WPDesktop") ? At : b(e, Ft) ? Rt : b(e, Dt) || b(e, "Edg/") ? Nt : b(e, "FBIOS") ? "Facebook " + ft : b(e, "UCWEB") || b(e, "UCBrowser") ? "UC Browser" : b(e, "CriOS") ? $t : b(e, "CrMo") ? Ct : b(e, bt) && b(e, xt) ? Wt : b(e, Ct) ? Ct : b(e, "FxiOS") ? Bt : b(e.toLowerCase(), Zt.toLowerCase()) ? Zt : ai(e, t) ? b(e, ft) ? jt : xt : b(e, qt) ? qt : b(e, "MSIE") || b(e, "Trident/") ? Mt : b(e, "Gecko") ? qt : "";
  },
  ui = {
    [At]: [new RegExp("rv:" + ei)],
    [Nt]: [new RegExp(Dt + "?\\/" + ei)],
    [Ct]: [new RegExp("(" + Ct + "|CrMo)\\/" + ei)],
    [$t]: [new RegExp("CriOS\\/" + ei)],
    "UC Browser": [new RegExp("(UCBrowser|UCWEB)\\/" + ei)],
    [xt]: [ti],
    [jt]: [ti],
    [Lt]: [new RegExp("(Opera|OPR)\\/" + ei)],
    [qt]: [new RegExp(qt + "\\/" + ei)],
    [Bt]: [new RegExp("FxiOS\\/" + ei)],
    [Zt]: [new RegExp("Konqueror[:/]?" + ei, "i")],
    [It]: [new RegExp(It + " " + ei), ti],
    [Wt]: [new RegExp("android\\s" + ei, "i")],
    [Rt]: [new RegExp(Ft + "\\/" + ei)],
    [Mt]: [new RegExp("(rv:|MSIE )" + ei)],
    Mozilla: [new RegExp("rv:" + ei)]
  },
  ci = [[new RegExp(zt + "; " + zt + " (.*?)[);]", "i"), e => [zt, e && e[1] || ""]], [new RegExp(Ht, "i"), [Ht, ""]], [new RegExp(Ut, "i"), [Ut, ""]], [ni, [It, ""]], [new RegExp(Vt, "i"), (e, t) => {
    if (/Phone/.test(t) || /WPDesktop/.test(t)) return [Gt, ""];
    if (new RegExp(ft).test(t) && !/IEMobile\b/.test(t)) return [Vt + " " + ft, ""];
    var i = /Windows NT ([0-9.]+)/i.exec(t);
    if (i && i[1]) {
      var s = i[1],
        r = oi[s] || "";
      return /arm/i.test(t) && (r = "RT"), [Vt, r];
    }
    return [Vt, ""];
  }], [/((iPhone|iPad|iPod).*?OS (\d+)_(\d+)_?(\d+)?|iPhone)/, e => {
    if (e && e[3]) {
      var t = [e[3], e[4], e[5] || "0"];
      return [mt, t.join(".")];
    }
    return [mt, ""];
  }], [/(watch.*\/(\d+\.\d+\.\d+)|watch os,(\d+\.\d+),)/i, e => {
    var t = "";
    return e && e.length >= 3 && (t = H(e[2]) ? e[3] : e[2]), ["watchOS", t];
  }], [new RegExp("(" + bt + " (\\d+)\\.(\\d+)\\.?(\\d+)?|" + bt + ")", "i"), e => {
    if (e && e[2]) {
      var t = [e[2], e[3], e[4] || "0"];
      return [bt, t.join(".")];
    }
    return [bt, ""];
  }], [/Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i, e => {
    var t = ["Mac OS X", ""];
    if (e && e[1]) {
      var i = [e[1], e[2], e[3] || "0"];
      t[1] = i.join(".");
    }
    return t;
  }], [/Mac/i, ["Mac OS X", ""]], [/CrOS/, [Tt, ""]], [/Linux|debian/i, ["Linux", ""]]],
  di = function (e) {
    return ri.test(e) ? Ht : si.test(e) ? Ut : ii.test(e) ? zt : new RegExp(Yt, "i").test(e) ? Yt : new RegExp("(" + Gt + "|WPDesktop)", "i").test(e) ? Gt : /iPad/.test(e) ? St : /iPod/.test(e) ? "iPod Touch" : /iPhone/.test(e) ? "iPhone" : /(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(e) ? kt : ni.test(e) ? It : /(kobo)\s(ereader|touch)/i.test(e) ? "Kobo" : new RegExp(Qt, "i").test(e) ? Qt : /(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(e) || /(kf[a-z]+)( bui|\)).+silk\//i.test(e) ? "Kindle Fire" : /(Android|ZTE)/i.test(e) ? !new RegExp(ft).test(e) || /(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(e) ? /pixel[\daxl ]{1,6}/i.test(e) && !/pixel c/i.test(e) || /(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(e) || /lmy47v/i.test(e) && !/QTAQZ3/i.test(e) ? bt : wt : bt : new RegExp("(pda|" + ft + ")", "i").test(e) ? Xt : new RegExp(yt, "i").test(e) && !new RegExp(yt + " pc", "i").test(e) ? Kt : "";
  },
  hi = "https?://(.*)",
  _i = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term", "gclid", "gad_source", "gclsrc", "dclid", "gbraid", "wbraid", "fbclid", "msclkid", "twclid", "li_fat_id", "mc_cid", "igshid", "ttclid", "rdt_cid"],
  pi = {
    campaignParams: function (e) {
      return o ? this._campaignParamsFromUrl(o.URL, e) : {};
    },
    _campaignParamsFromUrl: function (e, t) {
      var i = _i.concat(t || []),
        s = {};
      return f(i, function (t) {
        var i = vt(e, t);
        s[t] = i || null;
      }), s;
    },
    _searchEngine: function (e) {
      return e ? 0 === e.search(hi + "google.([^/?]*)") ? "google" : 0 === e.search(hi + "bing.com") ? "bing" : 0 === e.search(hi + "yahoo.com") ? "yahoo" : 0 === e.search(hi + "duckduckgo.com") ? "duckduckgo" : null : null;
    },
    _searchInfoFromReferrer: function (e) {
      var t = pi._searchEngine(e),
        i = "yahoo" != t ? "q" : "p",
        s = {};
      if (!W(t)) {
        s.$search_engine = t;
        var r = o ? vt(o.referrer, i) : "";
        r.length && (s.ph_keyword = r);
      }
      return s;
    },
    searchInfo: function () {
      var e = null == o ? void 0 : o.referrer;
      return e ? this._searchInfoFromReferrer(e) : {};
    },
    browser: li,
    browserVersion: function (e, t) {
      var i = li(e, t),
        s = ui[i];
      if (H(s)) return null;
      for (var r = 0; r < s.length; r++) {
        var n = s[r],
          o = e.match(n);
        if (o) return parseFloat(o[o.length - 2]);
      }
      return null;
    },
    browserLanguage: function () {
      return navigator.language || navigator.userLanguage;
    },
    browserLanguagePrefix: function () {
      var e = this.browserLanguage();
      return "string" == typeof e ? e.split("-")[0] : void 0;
    },
    os: function (e) {
      for (var t = 0; t < ci.length; t++) {
        var [i, s] = ci[t],
          r = i.exec(e),
          n = r && (N(s) ? s(r, e) : s);
        if (n) return n;
      }
      return ["", ""];
    },
    device: di,
    deviceType: function (e) {
      var t = di(e);
      return t === St || t === wt || "Kobo" === t || "Kindle Fire" === t || t === Kt ? yt : t === Ht || t === zt || t === Ut || t === Yt ? "Console" : t === kt ? "Wearable" : t ? ft : "Desktop";
    },
    referrer: function () {
      return (null == o ? void 0 : o.referrer) || "$direct";
    },
    referringDomain: function () {
      var e;
      return null != o && o.referrer && (null === (e = ht(o.referrer)) || void 0 === e ? void 0 : e.host) || "$direct";
    },
    referrerInfo: function () {
      return {
        $referrer: this.referrer(),
        $referring_domain: this.referringDomain()
      };
    },
    initialPersonInfo: function () {
      return {
        r: this.referrer().substring(0, 1e3),
        u: null == a ? void 0 : a.href.substring(0, 1e3)
      };
    },
    initialPersonPropsFromInfo: function (e) {
      var t,
        {
          r: i,
          u: s
        } = e,
        r = {
          $initial_referrer: i,
          $initial_referring_domain: null == i ? void 0 : "$direct" == i ? "$direct" : null === (t = ht(i)) || void 0 === t ? void 0 : t.host
        };
      if (s) {
        r.$initial_current_url = s;
        var n = ht(s);
        r.$initial_host = null == n ? void 0 : n.host, r.$initial_pathname = null == n ? void 0 : n.pathname, f(this._campaignParamsFromUrl(s), function (e, t) {
          r["$initial_" + k(t)] = e;
        });
      }
      i && f(this._searchInfoFromReferrer(i), function (e, t) {
        r["$initial_" + k(t)] = e;
      });
      return r;
    },
    timezone: function () {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
        return;
      }
    },
    timezoneOffset: function () {
      try {
        return new Date().getTimezoneOffset();
      } catch (e) {
        return;
      }
    },
    properties: function () {
      if (!d) return {};
      var [t, i] = pi.os(d);
      return m(E({
        $os: t,
        $os_version: i,
        $browser: pi.browser(d, navigator.vendor),
        $device: pi.device(d),
        $device_type: pi.deviceType(d),
        $timezone: pi.timezone(),
        $timezone_offset: pi.timezoneOffset()
      }), {
        $current_url: null == a ? void 0 : a.href,
        $host: null == a ? void 0 : a.host,
        $pathname: null == a ? void 0 : a.pathname,
        $raw_user_agent: d.length > 1e3 ? d.substring(0, 997) + "..." : d,
        $browser_version: pi.browserVersion(d, navigator.vendor),
        $browser_language: pi.browserLanguage(),
        $browser_language_prefix: pi.browserLanguagePrefix(),
        $screen_height: null == e ? void 0 : e.screen.height,
        $screen_width: null == e ? void 0 : e.screen.width,
        $viewport_height: null == e ? void 0 : e.innerHeight,
        $viewport_width: null == e ? void 0 : e.innerWidth,
        $lib: "web",
        $lib_version: _.LIB_VERSION,
        $insert_id: Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10),
        $time: Date.now() / 1e3
      });
    },
    people_properties: function () {
      if (!d) return {};
      var [e, t] = pi.os(d);
      return m(E({
        $os: e,
        $os_version: t,
        $browser: pi.browser(d, navigator.vendor)
      }), {
        $browser_version: pi.browserVersion(d, navigator.vendor)
      });
    }
  },
  vi = ["cookie", "localstorage", "localstorage+cookie", "sessionstorage", "memory"];
class gi {
  constructor(e) {
    this.config = e, this.props = {}, this.campaign_params_saved = !1, this.name = (e => {
      var t = "";
      return e.token && (t = e.token.replace(/\+/g, "PL").replace(/\//g, "SL").replace(/=/g, "EQ")), e.persistence_name ? "ph_" + e.persistence_name : "ph_" + t + "_posthog";
    })(e), this.storage = this.buildStorage(e), this.load(), e.debug && X.info("Persistence loaded", e.persistence, ee({}, this.props)), this.update_config(e, e), this.save();
  }
  buildStorage(e) {
    -1 === vi.indexOf(e.persistence.toLowerCase()) && (X.critical("Unknown persistence type " + e.persistence + "; falling back to localStorage+cookie"), e.persistence = "localStorage+cookie");
    var t = e.persistence.toLowerCase();
    return "localstorage" === t && rt.is_supported() ? rt : "localstorage+cookie" === t && ot.is_supported() ? ot : "sessionstorage" === t && ct.is_supported() ? ct : "memory" === t ? lt : "cookie" === t ? it : ot.is_supported() ? ot : it;
  }
  properties() {
    var e = {};
    return f(this.props, function (t, i) {
      if (i === Ee && q(t)) for (var s = Object.keys(t), n = 0; n < s.length; n++) e["$feature/".concat(s[n])] = t[s[n]];else a = i, l = !1, (W(o = Ne) ? l : r && o.indexOf === r ? -1 != o.indexOf(a) : (f(o, function (e) {
        if (l || (l = e === a)) return p;
      }), l)) || (e[i] = t);
      var o, a, l;
    }), e;
  }
  load() {
    if (!this.disabled) {
      var e = this.storage.parse(this.name);
      e && (this.props = m({}, e));
    }
  }
  save() {
    this.disabled || this.storage.set(this.name, this.props, this.expire_days, this.cross_subdomain, this.secure, this.config.debug);
  }
  remove() {
    this.storage.remove(this.name, !1), this.storage.remove(this.name, !0);
  }
  clear() {
    this.remove(), this.props = {};
  }
  register_once(e, t, i) {
    if (q(e)) {
      H(t) && (t = "None"), this.expire_days = H(i) ? this.default_expiry : i;
      var s = !1;
      if (f(e, (e, i) => {
        this.props.hasOwnProperty(i) && this.props[i] !== t || (this.props[i] = e, s = !0);
      }), s) return this.save(), !0;
    }
    return !1;
  }
  register(e, t) {
    if (q(e)) {
      this.expire_days = H(t) ? this.default_expiry : t;
      var i = !1;
      if (f(e, (t, s) => {
        e.hasOwnProperty(s) && this.props[s] !== t && (this.props[s] = t, i = !0);
      }), i) return this.save(), !0;
    }
    return !1;
  }
  unregister(e) {
    e in this.props && (delete this.props[e], this.save());
  }
  update_campaign_params() {
    if (!this.campaign_params_saved) {
      var e = pi.campaignParams(this.config.custom_campaign_params);
      B(E(e)) || this.register(e), this.campaign_params_saved = !0;
    }
  }
  update_search_keyword() {
    this.register(pi.searchInfo());
  }
  update_referrer_info() {
    this.register_once(pi.referrerInfo(), void 0);
  }
  set_initial_person_info() {
    this.props[Me] || this.props[Ae] || this.register_once({
      [Le]: pi.initialPersonInfo()
    }, void 0);
  }
  get_referrer_info() {
    return E({
      $referrer: this.props.$referrer,
      $referring_domain: this.props.$referring_domain
    });
  }
  get_initial_props() {
    var e = {};
    f([Ae, Me], t => {
      var i = this.props[t];
      i && f(i, function (t, i) {
        e["$initial_" + k(i)] = t;
      });
    });
    var t = this.props[Le];
    if (t) {
      var i = pi.initialPersonPropsFromInfo(t);
      m(e, i);
    }
    return e;
  }
  safe_merge(e) {
    return f(this.props, function (t, i) {
      i in e || (e[i] = t);
    }), e;
  }
  update_config(e, t) {
    if (this.default_expiry = this.expire_days = e.cookie_expiration, this.set_disabled(e.disable_persistence), this.set_cross_subdomain(e.cross_subdomain_cookie), this.set_secure(e.secure_cookie), e.persistence !== t.persistence) {
      var i = this.buildStorage(e),
        s = this.props;
      this.clear(), this.storage = i, this.props = s, this.save();
    }
  }
  set_disabled(e) {
    this.disabled = e, this.disabled ? this.remove() : this.save();
  }
  set_cross_subdomain(e) {
    e !== this.cross_subdomain && (this.cross_subdomain = e, this.remove(), this.save());
  }
  get_cross_subdomain() {
    return !!this.cross_subdomain;
  }
  set_secure(e) {
    e !== this.secure && (this.secure = e, this.remove(), this.save());
  }
  set_event_timer(e, t) {
    var i = this.props[ne] || {};
    i[e] = t, this.props[ne] = i, this.save();
  }
  remove_event_timer(e) {
    var t = (this.props[ne] || {})[e];
    return H(t) || (delete this.props[ne][e], this.save()), t;
  }
  get_property(e) {
    return this.props[e];
  }
  set_property(e, t) {
    this.props[e] = t, this.save();
  }
}
function fi(e) {
  var t, i;
  return (null === (t = JSON.stringify(e, (i = [], function (e, t) {
    if (q(t)) {
      for (; i.length > 0 && i[i.length - 1] !== this;) i.pop();
      return i.includes(t) ? "[Circular]" : (i.push(t), t);
    }
    return t;
  }))) || void 0 === t ? void 0 : t.length) || 0;
}
function mi(e) {
  var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 6606028.8;
  if (e.size >= t && e.data.length > 1) {
    var i = Math.floor(e.data.length / 2),
      s = e.data.slice(0, i),
      r = e.data.slice(i);
    return [mi({
      size: fi(s),
      data: s,
      sessionId: e.sessionId,
      windowId: e.windowId
    }), mi({
      size: fi(r),
      data: r,
      sessionId: e.sessionId,
      windowId: e.windowId
    })].flatMap(e => e);
  }
  return [e];
}
var bi = (e => (e[e.DomContentLoaded = 0] = "DomContentLoaded", e[e.Load = 1] = "Load", e[e.FullSnapshot = 2] = "FullSnapshot", e[e.IncrementalSnapshot = 3] = "IncrementalSnapshot", e[e.Meta = 4] = "Meta", e[e.Custom = 5] = "Custom", e[e.Plugin = 6] = "Plugin", e))(bi || {}),
  yi = (e => (e[e.Mutation = 0] = "Mutation", e[e.MouseMove = 1] = "MouseMove", e[e.MouseInteraction = 2] = "MouseInteraction", e[e.Scroll = 3] = "Scroll", e[e.ViewportResize = 4] = "ViewportResize", e[e.Input = 5] = "Input", e[e.TouchMove = 6] = "TouchMove", e[e.MediaInteraction = 7] = "MediaInteraction", e[e.StyleSheetRule = 8] = "StyleSheetRule", e[e.CanvasMutation = 9] = "CanvasMutation", e[e.Font = 10] = "Font", e[e.Log = 11] = "Log", e[e.Drag = 12] = "Drag", e[e.StyleDeclaration = 13] = "StyleDeclaration", e[e.Selection = 14] = "Selection", e[e.AdoptedStyleSheet = 15] = "AdoptedStyleSheet", e[e.CustomElement = 16] = "CustomElement", e))(yi || {});
function wi(e) {
  var t;
  return e.id === De || !(null === (t = e.closest) || void 0 === t || !t.call(e, ".toolbar-global-fade-container"));
}
function Si(e) {
  return !!e && 1 === e.nodeType;
}
function Ei(e, t) {
  return !!e && !!e.tagName && e.tagName.toLowerCase() === t.toLowerCase();
}
function ki(e) {
  return !!e && 3 === e.nodeType;
}
function xi(e) {
  return !!e && 11 === e.nodeType;
}
function Ii(e) {
  return e ? v(e).split(/\s+/) : [];
}
function Pi(t) {
  var i = null == e ? void 0 : e.location.href;
  return !!(i && t && t.some(e => i.match(e)));
}
function Fi(e) {
  var t = "";
  switch (typeof e.className) {
    case "string":
      t = e.className;
      break;
    case "object":
      t = (e.className && "baseVal" in e.className ? e.className.baseVal : null) || e.getAttribute("class") || "";
      break;
    default:
      t = "";
  }
  return Ii(t);
}
function Ri(e) {
  return j(e) ? null : v(e).split(/(\s+)/).filter(e => zi(e)).join("").replace(/[\r\n]/g, " ").replace(/[ ]+/g, " ").substring(0, 255);
}
function Ci(e) {
  var t = "";
  return Li(e) && !Oi(e) && e.childNodes && e.childNodes.length && f(e.childNodes, function (e) {
    var i;
    ki(e) && e.textContent && (t += null !== (i = Ri(e.textContent)) && void 0 !== i ? i : "");
  }), v(t);
}
function Ti(e) {
  return H(e.target) ? e.srcElement || null : null !== (t = e.target) && void 0 !== t && t.shadowRoot ? e.composedPath()[0] || null : e.target || null;
  var t;
}
var $i = ["a", "button", "form", "input", "select", "textarea", "label"];
function Mi(e) {
  var t = e.parentNode;
  return !(!t || !Si(t)) && t;
}
function Ai(t, i) {
  var s = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0,
    r = arguments.length > 3 ? arguments[3] : void 0,
    n = arguments.length > 4 ? arguments[4] : void 0;
  if (!e || !t || Ei(t, "html") || !Si(t)) return !1;
  if (null != s && s.url_allowlist && !Pi(s.url_allowlist)) return !1;
  if (null != s && s.url_ignorelist && Pi(s.url_ignorelist)) return !1;
  if (null != s && s.dom_event_allowlist) {
    var o = s.dom_event_allowlist;
    if (o && !o.some(e => i.type === e)) return !1;
  }
  for (var a = !1, l = [t], u = !0, c = t; c.parentNode && !Ei(c, "body");) if (xi(c.parentNode)) l.push(c.parentNode.host), c = c.parentNode.host;else {
    if (!(u = Mi(c))) break;
    if (r || $i.indexOf(u.tagName.toLowerCase()) > -1) a = !0;else {
      var d = e.getComputedStyle(u);
      d && "pointer" === d.getPropertyValue("cursor") && (a = !0);
    }
    l.push(u), c = u;
  }
  if (!function (e, t) {
    var i = null == t ? void 0 : t.element_allowlist;
    if (H(i)) return !0;
    var s = function (e) {
      if (i.some(t => e.tagName.toLowerCase() === t)) return {
        v: !0
      };
    };
    for (var r of e) {
      var n = s(r);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, s)) return !1;
  if (!function (e, t) {
    var i = null == t ? void 0 : t.css_selector_allowlist;
    if (H(i)) return !0;
    var s = function (e) {
      if (i.some(t => e.matches(t))) return {
        v: !0
      };
    };
    for (var r of e) {
      var n = s(r);
      if ("object" == typeof n) return n.v;
    }
    return !1;
  }(l, s)) return !1;
  var h = e.getComputedStyle(t);
  if (h && "pointer" === h.getPropertyValue("cursor") && "click" === i.type) return !0;
  var _ = t.tagName.toLowerCase();
  switch (_) {
    case "html":
      return !1;
    case "form":
      return (n || ["submit"]).indexOf(i.type) >= 0;
    case "input":
    case "select":
    case "textarea":
      return (n || ["change", "click"]).indexOf(i.type) >= 0;
    default:
      return a ? (n || ["click"]).indexOf(i.type) >= 0 : (n || ["click"]).indexOf(i.type) >= 0 && ($i.indexOf(_) > -1 || "true" === t.getAttribute("contenteditable"));
  }
}
function Li(e) {
  for (var t = e; t.parentNode && !Ei(t, "body"); t = t.parentNode) {
    var i = Fi(t);
    if (b(i, "ph-sensitive") || b(i, "ph-no-capture")) return !1;
  }
  if (b(Fi(e), "ph-include")) return !0;
  var s = e.type || "";
  if (U(s)) switch (s.toLowerCase()) {
    case "hidden":
    case "password":
      return !1;
  }
  var r = e.name || e.id || "";
  if (U(r)) {
    if (/^cc|cardnum|ccnum|creditcard|csc|cvc|cvv|exp|pass|pwd|routing|seccode|securitycode|securitynum|socialsec|socsec|ssn/i.test(r.replace(/[^a-zA-Z0-9]/g, ""))) return !1;
  }
  return !0;
}
function Oi(e) {
  return !!(Ei(e, "input") && !["button", "checkbox", "submit", "reset"].includes(e.type) || Ei(e, "select") || Ei(e, "textarea") || "true" === e.getAttribute("contenteditable"));
}
var Di = "(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11})",
  Ni = new RegExp("^(?:".concat(Di, ")$")),
  qi = new RegExp(Di),
  Bi = "\\d{3}-?\\d{2}-?\\d{4}",
  Hi = new RegExp("^(".concat(Bi, ")$")),
  Ui = new RegExp("(".concat(Bi, ")"));
function zi(e) {
  var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
  if (j(e)) return !1;
  if (U(e)) {
    if (e = v(e), (t ? Ni : qi).test((e || "").replace(/[- ]/g, ""))) return !1;
    if ((t ? Hi : Ui).test(e)) return !1;
  }
  return !0;
}
function Wi(e) {
  var t = Ci(e);
  return zi(t = "".concat(t, " ").concat(ji(e)).trim()) ? t : "";
}
function ji(e) {
  var t = "";
  return e && e.childNodes && e.childNodes.length && f(e.childNodes, function (e) {
    var i;
    if (e && "span" === (null === (i = e.tagName) || void 0 === i ? void 0 : i.toLowerCase())) try {
      var s = Ci(e);
      t = "".concat(t, " ").concat(s).trim(), e.childNodes && e.childNodes.length && (t = "".concat(t, " ").concat(ji(e)).trim());
    } catch (e) {
      X.error(e);
    }
  }), t;
}
function Vi(e) {
  return function (e) {
    var t = e.map(e => {
      var t,
        i,
        s = "";
      if (e.tag_name && (s += e.tag_name), e.attr_class) for (var r of (e.attr_class.sort(), e.attr_class)) s += ".".concat(r.replace(/"/g, ""));
      var n = ee(ee(ee(ee({}, e.text ? {
          text: e.text
        } : {}), {}, {
          "nth-child": null !== (t = e.nth_child) && void 0 !== t ? t : 0,
          "nth-of-type": null !== (i = e.nth_of_type) && void 0 !== i ? i : 0
        }, e.href ? {
          href: e.href
        } : {}), e.attr_id ? {
          attr_id: e.attr_id
        } : {}), e.attributes),
        o = {};
      return y(n).sort((e, t) => {
        var [i] = e,
          [s] = t;
        return i.localeCompare(s);
      }).forEach(e => {
        var [t, i] = e;
        return o[Gi(t.toString())] = Gi(i.toString());
      }), s += ":", s += y(n).map(e => {
        var [t, i] = e;
        return "".concat(t, '="').concat(i, '"');
      }).join("");
    });
    return t.join(";");
  }(function (e) {
    return e.map(e => {
      var t,
        i,
        s = {
          text: null === (t = e.$el_text) || void 0 === t ? void 0 : t.slice(0, 400),
          tag_name: e.tag_name,
          href: null === (i = e.attr__href) || void 0 === i ? void 0 : i.slice(0, 2048),
          attr_class: Qi(e),
          attr_id: e.attr__id,
          nth_child: e.nth_child,
          nth_of_type: e.nth_of_type,
          attributes: {}
        };
      return y(e).filter(e => {
        var [t] = e;
        return 0 === t.indexOf("attr__");
      }).forEach(e => {
        var [t, i] = e;
        return s.attributes[t] = i;
      }), s;
    });
  }(e));
}
function Gi(e) {
  return e.replace(/"|\\"/g, '\\"');
}
function Qi(e) {
  var t = e.attr__class;
  return t ? D(t) ? t : Ii(t) : void 0;
}
var Yi = "[SessionRecording]",
  Ji = "redacted",
  Xi = {
    initiatorTypes: ["audio", "beacon", "body", "css", "early-hint", "embed", "fetch", "frame", "iframe", "icon", "image", "img", "input", "link", "navigation", "object", "ping", "script", "track", "video", "xmlhttprequest"],
    maskRequestFn: e => e,
    recordHeaders: !1,
    recordBody: !1,
    recordInitialRequests: !1,
    recordPerformance: !1,
    performanceEntryTypeToObserve: ["first-input", "navigation", "paint", "resource"],
    payloadSizeLimitBytes: 1e6,
    payloadHostDenyList: [".lr-ingest.io", ".ingest.sentry.io"]
  },
  Ki = ["authorization", "x-forwarded-for", "authorization", "cookie", "set-cookie", "x-api-key", "x-real-ip", "remote-addr", "forwarded", "proxy-authorization", "x-csrf-token", "x-csrftoken", "x-xsrf-token"],
  Zi = ["password", "secret", "passwd", "api_key", "apikey", "auth", "credentials", "mysql_pwd", "privatekey", "private_key", "token"],
  es = ["/s/", "/e/", "/i/"];
function ts(e, t, i, s) {
  if (j(e)) return e;
  var r = (null == t ? void 0 : t["content-length"]) || function (e) {
    return new Blob([e]).size;
  }(e);
  return U(r) && (r = parseInt(r)), r > i ? Yi + " ".concat(s, " body too large to record (").concat(r, " bytes)") : e;
}
function is(e, t) {
  if (j(e)) return e;
  var i = e;
  return zi(i, !1) || (i = Yi + " " + t + " body " + Ji), f(Zi, e => {
    var s, r;
    null !== (s = i) && void 0 !== s && s.length && -1 !== (null === (r = i) || void 0 === r ? void 0 : r.indexOf(e)) && (i = Yi + " " + t + " body " + Ji + " as might contain: " + e);
  }), i;
}
var ss = (e, t) => {
  var i,
    s,
    r,
    n = {
      payloadSizeLimitBytes: Xi.payloadSizeLimitBytes,
      performanceEntryTypeToObserve: [...Xi.performanceEntryTypeToObserve],
      payloadHostDenyList: [...(t.payloadHostDenyList || []), ...Xi.payloadHostDenyList]
    },
    o = !1 !== e.session_recording.recordHeaders && t.recordHeaders,
    a = !1 !== e.session_recording.recordBody && t.recordBody,
    l = !1 !== e.capture_performance && t.recordPerformance,
    u = (i = n, r = Math.min(1e6, null !== (s = i.payloadSizeLimitBytes) && void 0 !== s ? s : 1e6), e => (null != e && e.requestBody && (e.requestBody = ts(e.requestBody, e.requestHeaders, r, "Request")), null != e && e.responseBody && (e.responseBody = ts(e.responseBody, e.responseHeaders, r, "Response")), e)),
    c = t => {
      return u(((e, t) => {
        var i,
          s = ht(e.name),
          r = 0 === t.indexOf("http") ? null === (i = ht(t)) || void 0 === i ? void 0 : i.pathname : t;
        "/" === r && (r = "");
        var n = null == s ? void 0 : s.pathname.replace(r || "", "");
        if (!(s && n && es.some(e => 0 === n.indexOf(e)))) return e;
      })((s = (i = t).requestHeaders, j(s) || f(Object.keys(null != s ? s : {}), e => {
        Ki.includes(e.toLowerCase()) && (s[e] = Ji);
      }), i), e.api_host));
      var i, s;
    },
    d = N(e.session_recording.maskNetworkRequestFn);
  return d && N(e.session_recording.maskCapturedNetworkRequestFn) && X.warn("Both `maskNetworkRequestFn` and `maskCapturedNetworkRequestFn` are defined. `maskNetworkRequestFn` will be ignored."), d && (e.session_recording.maskCapturedNetworkRequestFn = t => {
    var i = e.session_recording.maskNetworkRequestFn({
      url: t.name
    });
    return ee(ee({}, t), {}, {
      name: null == i ? void 0 : i.url
    });
  }), n.maskRequestFn = N(e.session_recording.maskCapturedNetworkRequestFn) ? t => {
    var i,
      s,
      r,
      n = c(t);
    return n && null !== (i = null === (s = (r = e.session_recording).maskCapturedNetworkRequestFn) || void 0 === s ? void 0 : s.call(r, n)) && void 0 !== i ? i : void 0;
  } : e => function (e) {
    if (!H(e)) return e.requestBody = is(e.requestBody, "Request"), e.responseBody = is(e.responseBody, "Response"), e;
  }(c(e)), ee(ee(ee({}, Xi), n), {}, {
    recordHeaders: o,
    recordBody: a,
    recordPerformance: l,
    recordInitialRequests: l
  });
};
function rs(e, t, i, s, r) {
  return t > i && (X.warn("min cannot be greater than max."), t = i), V(e) ? e > i ? (s && X.warn(s + " cannot be  greater than max: " + i + ". Using max value instead."), i) : e < t ? (s && X.warn(s + " cannot be less than min: " + t + ". Using min value instead."), t) : e : (s && X.warn(s + " must be a number. using max or fallback. max: " + i + ", fallback: " + r), rs(r || i, t, i, s));
}
class ns {
  constructor(e) {
    var t,
      i,
      s = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
    te(this, "bucketSize", 100), te(this, "refillRate", 10), te(this, "mutationBuckets", {}), te(this, "loggedTracker", {}), te(this, "refillBuckets", () => {
      Object.keys(this.mutationBuckets).forEach(e => {
        this.mutationBuckets[e] = this.mutationBuckets[e] + this.refillRate, this.mutationBuckets[e] >= this.bucketSize && delete this.mutationBuckets[e];
      });
    }), te(this, "getNodeOrRelevantParent", e => {
      var t = this.rrweb.mirror.getNode(e);
      if ("svg" !== (null == t ? void 0 : t.nodeName) && t instanceof Element) {
        var i = t.closest("svg");
        if (i) return [this.rrweb.mirror.getId(i), i];
      }
      return [e, t];
    }), te(this, "numberOfChanges", e => {
      var t, i, s, r, n, o, a, l;
      return (null !== (t = null === (i = e.removes) || void 0 === i ? void 0 : i.length) && void 0 !== t ? t : 0) + (null !== (s = null === (r = e.attributes) || void 0 === r ? void 0 : r.length) && void 0 !== s ? s : 0) + (null !== (n = null === (o = e.texts) || void 0 === o ? void 0 : o.length) && void 0 !== n ? n : 0) + (null !== (a = null === (l = e.adds) || void 0 === l ? void 0 : l.length) && void 0 !== a ? a : 0);
    }), te(this, "throttleMutations", e => {
      if (3 !== e.type || 0 !== e.data.source) return e;
      var t = e.data,
        i = this.numberOfChanges(t);
      t.attributes && (t.attributes = t.attributes.filter(e => {
        var t,
          i,
          s,
          [r, n] = this.getNodeOrRelevantParent(e.id);
        if (0 === this.mutationBuckets[r]) return !1;
        (this.mutationBuckets[r] = null !== (t = this.mutationBuckets[r]) && void 0 !== t ? t : this.bucketSize, this.mutationBuckets[r] = Math.max(this.mutationBuckets[r] - 1, 0), 0 === this.mutationBuckets[r]) && (this.loggedTracker[r] || (this.loggedTracker[r] = !0, null === (i = (s = this.options).onBlockedNode) || void 0 === i || i.call(s, r, n)));
        return e;
      }));
      var s = this.numberOfChanges(t);
      return 0 !== s || i === s ? e : void 0;
    }), this.rrweb = e, this.options = s, this.refillRate = rs(null !== (t = this.options.refillRate) && void 0 !== t ? t : this.refillRate, 0, 100, "mutation throttling refill rate"), this.bucketSize = rs(null !== (i = this.options.bucketSize) && void 0 !== i ? i : this.bucketSize, 0, 100, "mutation throttling bucket size"), setInterval(() => {
      this.refillBuckets();
    }, 1e3);
  }
}
var os = Uint8Array,
  as = Uint16Array,
  ls = Uint32Array,
  us = new os([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0, 0]),
  cs = new os([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 0, 0]),
  ds = new os([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]),
  hs = function (e, t) {
    for (var i = new as(31), s = 0; s < 31; ++s) i[s] = t += 1 << e[s - 1];
    var r = new ls(i[30]);
    for (s = 1; s < 30; ++s) for (var n = i[s]; n < i[s + 1]; ++n) r[n] = n - i[s] << 5 | s;
    return [i, r];
  },
  _s = hs(us, 2),
  ps = _s[0],
  vs = _s[1];
ps[28] = 258, vs[258] = 28;
for (var gs = hs(cs, 0)[1], fs = new as(32768), ms = 0; ms < 32768; ++ms) {
  var bs = (43690 & ms) >>> 1 | (21845 & ms) << 1;
  bs = (61680 & (bs = (52428 & bs) >>> 2 | (13107 & bs) << 2)) >>> 4 | (3855 & bs) << 4, fs[ms] = ((65280 & bs) >>> 8 | (255 & bs) << 8) >>> 1;
}
var ys = function (e, t, i) {
    for (var s = e.length, r = 0, n = new as(t); r < s; ++r) ++n[e[r] - 1];
    var o,
      a = new as(t);
    for (r = 0; r < t; ++r) a[r] = a[r - 1] + n[r - 1] << 1;
    if (i) {
      o = new as(1 << t);
      var l = 15 - t;
      for (r = 0; r < s; ++r) if (e[r]) for (var u = r << 4 | e[r], c = t - e[r], d = a[e[r] - 1]++ << c, h = d | (1 << c) - 1; d <= h; ++d) o[fs[d] >>> l] = u;
    } else for (o = new as(s), r = 0; r < s; ++r) o[r] = fs[a[e[r] - 1]++] >>> 15 - e[r];
    return o;
  },
  ws = new os(288);
for (ms = 0; ms < 144; ++ms) ws[ms] = 8;
for (ms = 144; ms < 256; ++ms) ws[ms] = 9;
for (ms = 256; ms < 280; ++ms) ws[ms] = 7;
for (ms = 280; ms < 288; ++ms) ws[ms] = 8;
var Ss = new os(32);
for (ms = 0; ms < 32; ++ms) Ss[ms] = 5;
var Es = ys(ws, 9, 0),
  ks = ys(Ss, 5, 0),
  xs = function (e) {
    return (e / 8 >> 0) + (7 & e && 1);
  },
  Is = function (e, t, i) {
    (null == i || i > e.length) && (i = e.length);
    var s = new (e instanceof as ? as : e instanceof ls ? ls : os)(i - t);
    return s.set(e.subarray(t, i)), s;
  },
  Ps = function (e, t, i) {
    i <<= 7 & t;
    var s = t / 8 >> 0;
    e[s] |= i, e[s + 1] |= i >>> 8;
  },
  Fs = function (e, t, i) {
    i <<= 7 & t;
    var s = t / 8 >> 0;
    e[s] |= i, e[s + 1] |= i >>> 8, e[s + 2] |= i >>> 16;
  },
  Rs = function (e, t) {
    for (var i = [], s = 0; s < e.length; ++s) e[s] && i.push({
      s: s,
      f: e[s]
    });
    var r = i.length,
      n = i.slice();
    if (!r) return [new os(0), 0];
    if (1 == r) {
      var o = new os(i[0].s + 1);
      return o[i[0].s] = 1, [o, 1];
    }
    i.sort(function (e, t) {
      return e.f - t.f;
    }), i.push({
      s: -1,
      f: 25001
    });
    var a = i[0],
      l = i[1],
      u = 0,
      c = 1,
      d = 2;
    for (i[0] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    }; c != r - 1;) a = i[i[u].f < i[d].f ? u++ : d++], l = i[u != c && i[u].f < i[d].f ? u++ : d++], i[c++] = {
      s: -1,
      f: a.f + l.f,
      l: a,
      r: l
    };
    var h = n[0].s;
    for (s = 1; s < r; ++s) n[s].s > h && (h = n[s].s);
    var _ = new as(h + 1),
      p = Cs(i[c - 1], _, 0);
    if (p > t) {
      s = 0;
      var v = 0,
        g = p - t,
        f = 1 << g;
      for (n.sort(function (e, t) {
        return _[t.s] - _[e.s] || e.f - t.f;
      }); s < r; ++s) {
        var m = n[s].s;
        if (!(_[m] > t)) break;
        v += f - (1 << p - _[m]), _[m] = t;
      }
      for (v >>>= g; v > 0;) {
        var b = n[s].s;
        _[b] < t ? v -= 1 << t - _[b]++ - 1 : ++s;
      }
      for (; s >= 0 && v; --s) {
        var y = n[s].s;
        _[y] == t && (--_[y], ++v);
      }
      p = t;
    }
    return [new os(_), p];
  },
  Cs = function (e, t, i) {
    return -1 == e.s ? Math.max(Cs(e.l, t, i + 1), Cs(e.r, t, i + 1)) : t[e.s] = i;
  },
  Ts = function (e) {
    for (var t = e.length; t && !e[--t];);
    for (var i = new as(++t), s = 0, r = e[0], n = 1, o = function (e) {
        i[s++] = e;
      }, a = 1; a <= t; ++a) if (e[a] == r && a != t) ++n;else {
      if (!r && n > 2) {
        for (; n > 138; n -= 138) o(32754);
        n > 2 && (o(n > 10 ? n - 11 << 5 | 28690 : n - 3 << 5 | 12305), n = 0);
      } else if (n > 3) {
        for (o(r), --n; n > 6; n -= 6) o(8304);
        n > 2 && (o(n - 3 << 5 | 8208), n = 0);
      }
      for (; n--;) o(r);
      n = 1, r = e[a];
    }
    return [i.subarray(0, s), t];
  },
  $s = function (e, t) {
    for (var i = 0, s = 0; s < t.length; ++s) i += e[s] * t[s];
    return i;
  },
  Ms = function (e, t, i) {
    var s = i.length,
      r = xs(t + 2);
    e[r] = 255 & s, e[r + 1] = s >>> 8, e[r + 2] = 255 ^ e[r], e[r + 3] = 255 ^ e[r + 1];
    for (var n = 0; n < s; ++n) e[r + n + 4] = i[n];
    return 8 * (r + 4 + s);
  },
  As = function (e, t, i, s, r, n, o, a, l, u, c) {
    Ps(t, c++, i), ++r[256];
    for (var d = Rs(r, 15), h = d[0], _ = d[1], p = Rs(n, 15), v = p[0], g = p[1], f = Ts(h), m = f[0], b = f[1], y = Ts(v), w = y[0], S = y[1], E = new as(19), k = 0; k < m.length; ++k) E[31 & m[k]]++;
    for (k = 0; k < w.length; ++k) E[31 & w[k]]++;
    for (var x = Rs(E, 7), I = x[0], P = x[1], F = 19; F > 4 && !I[ds[F - 1]]; --F);
    var R,
      C,
      T,
      $,
      M = u + 5 << 3,
      A = $s(r, ws) + $s(n, Ss) + o,
      L = $s(r, h) + $s(n, v) + o + 14 + 3 * F + $s(E, I) + (2 * E[16] + 3 * E[17] + 7 * E[18]);
    if (M <= A && M <= L) return Ms(t, c, e.subarray(l, l + u));
    if (Ps(t, c, 1 + (L < A)), c += 2, L < A) {
      R = ys(h, _, 0), C = h, T = ys(v, g, 0), $ = v;
      var O = ys(I, P, 0);
      Ps(t, c, b - 257), Ps(t, c + 5, S - 1), Ps(t, c + 10, F - 4), c += 14;
      for (k = 0; k < F; ++k) Ps(t, c + 3 * k, I[ds[k]]);
      c += 3 * F;
      for (var D = [m, w], N = 0; N < 2; ++N) {
        var q = D[N];
        for (k = 0; k < q.length; ++k) {
          var B = 31 & q[k];
          Ps(t, c, O[B]), c += I[B], B > 15 && (Ps(t, c, q[k] >>> 5 & 127), c += q[k] >>> 12);
        }
      }
    } else R = Es, C = ws, T = ks, $ = Ss;
    for (k = 0; k < a; ++k) if (s[k] > 255) {
      B = s[k] >>> 18 & 31;
      Fs(t, c, R[B + 257]), c += C[B + 257], B > 7 && (Ps(t, c, s[k] >>> 23 & 31), c += us[B]);
      var H = 31 & s[k];
      Fs(t, c, T[H]), c += $[H], H > 3 && (Fs(t, c, s[k] >>> 5 & 8191), c += cs[H]);
    } else Fs(t, c, R[s[k]]), c += C[s[k]];
    return Fs(t, c, R[256]), c + C[256];
  },
  Ls = new ls([65540, 131080, 131088, 131104, 262176, 1048704, 1048832, 2114560, 2117632]),
  Os = function () {
    for (var e = new ls(256), t = 0; t < 256; ++t) {
      for (var i = t, s = 9; --s;) i = (1 & i && 3988292384) ^ i >>> 1;
      e[t] = i;
    }
    return e;
  }(),
  Ds = function () {
    var e = 4294967295;
    return {
      p: function (t) {
        for (var i = e, s = 0; s < t.length; ++s) i = Os[255 & i ^ t[s]] ^ i >>> 8;
        e = i;
      },
      d: function () {
        return 4294967295 ^ e;
      }
    };
  },
  Ns = function (e, t, i, s, r) {
    return function (e, t, i, s, r, n) {
      var o = e.length,
        a = new os(s + o + 5 * (1 + Math.floor(o / 7e3)) + r),
        l = a.subarray(s, a.length - r),
        u = 0;
      if (!t || o < 8) for (var c = 0; c <= o; c += 65535) {
        var d = c + 65535;
        d < o ? u = Ms(l, u, e.subarray(c, d)) : (l[c] = n, u = Ms(l, u, e.subarray(c, o)));
      } else {
        for (var h = Ls[t - 1], _ = h >>> 13, p = 8191 & h, v = (1 << i) - 1, g = new as(32768), f = new as(v + 1), m = Math.ceil(i / 3), b = 2 * m, y = function (t) {
            return (e[t] ^ e[t + 1] << m ^ e[t + 2] << b) & v;
          }, w = new ls(25e3), S = new as(288), E = new as(32), k = 0, x = 0, I = (c = 0, 0), P = 0, F = 0; c < o; ++c) {
          var R = y(c),
            C = 32767 & c,
            T = f[R];
          if (g[C] = T, f[R] = C, P <= c) {
            var $ = o - c;
            if ((k > 7e3 || I > 24576) && $ > 423) {
              u = As(e, l, 0, w, S, E, x, I, F, c - F, u), I = k = x = 0, F = c;
              for (var M = 0; M < 286; ++M) S[M] = 0;
              for (M = 0; M < 30; ++M) E[M] = 0;
            }
            var A = 2,
              L = 0,
              O = p,
              D = C - T & 32767;
            if ($ > 2 && R == y(c - D)) for (var N = Math.min(_, $) - 1, q = Math.min(32767, c), B = Math.min(258, $); D <= q && --O && C != T;) {
              if (e[c + A] == e[c + A - D]) {
                for (var H = 0; H < B && e[c + H] == e[c + H - D]; ++H);
                if (H > A) {
                  if (A = H, L = D, H > N) break;
                  var U = Math.min(D, H - 2),
                    z = 0;
                  for (M = 0; M < U; ++M) {
                    var W = c - D + M + 32768 & 32767,
                      j = W - g[W] + 32768 & 32767;
                    j > z && (z = j, T = W);
                  }
                }
              }
              D += (C = T) - (T = g[C]) + 32768 & 32767;
            }
            if (L) {
              w[I++] = 268435456 | vs[A] << 18 | gs[L];
              var V = 31 & vs[A],
                G = 31 & gs[L];
              x += us[V] + cs[G], ++S[257 + V], ++E[G], P = c + A, ++k;
            } else w[I++] = e[c], ++S[e[c]];
          }
        }
        u = As(e, l, n, w, S, E, x, I, F, c - F, u);
      }
      return Is(a, 0, s + xs(u) + r);
    }(e, null == t.level ? 6 : t.level, null == t.mem ? Math.ceil(1.5 * Math.max(8, Math.min(13, Math.log(e.length)))) : 12 + t.mem, i, s, !r);
  },
  qs = function (e, t, i) {
    for (; i; ++t) e[t] = i, i >>>= 8;
  },
  Bs = function (e, t) {
    var i = t.filename;
    if (e[0] = 31, e[1] = 139, e[2] = 8, e[8] = t.level < 2 ? 4 : 9 == t.level ? 2 : 0, e[9] = 3, 0 != t.mtime && qs(e, 4, Math.floor(new Date(t.mtime || Date.now()) / 1e3)), i) {
      e[3] = 8;
      for (var s = 0; s <= i.length; ++s) e[s + 10] = i.charCodeAt(s);
    }
  },
  Hs = function (e) {
    return 10 + (e.filename && e.filename.length + 1 || 0);
  };
function Us(e, t) {
  void 0 === t && (t = {});
  var i = Ds(),
    s = e.length;
  i.p(e);
  var r = Ns(e, t, Hs(t), 8),
    n = r.length;
  return Bs(r, t), qs(r, n - 8, i.d()), qs(r, n - 4, s), r;
}
function zs(e, t) {
  var i = e.length;
  if ("undefined" != typeof TextEncoder) return new TextEncoder().encode(e);
  for (var s = new os(e.length + (e.length >>> 1)), r = 0, n = function (e) {
      s[r++] = e;
    }, o = 0; o < i; ++o) {
    if (r + 5 > s.length) {
      var a = new os(r + 8 + (i - o << 1));
      a.set(s), s = a;
    }
    var l = e.charCodeAt(o);
    l < 128 || t ? n(l) : l < 2048 ? (n(192 | l >>> 6), n(128 | 63 & l)) : l > 55295 && l < 57344 ? (n(240 | (l = 65536 + (1047552 & l) | 1023 & e.charCodeAt(++o)) >>> 18), n(128 | l >>> 12 & 63), n(128 | l >>> 6 & 63), n(128 | 63 & l)) : (n(224 | l >>> 12), n(128 | l >>> 6 & 63), n(128 | 63 & l));
  }
  return Is(s, 0, r);
}
var Ws = 3e5,
  js = [yi.MouseMove, yi.MouseInteraction, yi.Scroll, yi.ViewportResize, yi.Input, yi.TouchMove, yi.MediaInteraction, yi.Drag],
  Vs = e => ({
    rrwebMethod: e,
    enqueuedAt: Date.now(),
    attempt: 1
  }),
  Gs = "[SessionRecording]";
function Qs(e) {
  return function (e, t) {
    for (var i = "", s = 0; s < e.length;) {
      var r = e[s++];
      r < 128 || t ? i += String.fromCharCode(r) : r < 224 ? i += String.fromCharCode((31 & r) << 6 | 63 & e[s++]) : r < 240 ? i += String.fromCharCode((15 & r) << 12 | (63 & e[s++]) << 6 | 63 & e[s++]) : (r = ((15 & r) << 18 | (63 & e[s++]) << 12 | (63 & e[s++]) << 6 | 63 & e[s++]) - 65536, i += String.fromCharCode(55296 | r >> 10, 56320 | 1023 & r));
    }
    return i;
  }(Us(zs(JSON.stringify(e))), !0);
}
function Ys(e) {
  return e.type === bi.Custom && "sessionIdle" === e.data.tag;
}
function Js(e, t) {
  return t.some(t => "regex" === t.matching && new RegExp(t.url).test(e));
}
class Xs {
  get sessionIdleThresholdMilliseconds() {
    return this.instance.config.session_recording.session_idle_threshold_ms || 3e5;
  }
  get rrwebRecord() {
    var e, t;
    return null == h || null === (e = h.__PosthogExtensions__) || void 0 === e || null === (t = e.rrweb) || void 0 === t ? void 0 : t.record;
  }
  get started() {
    return this._captureStarted;
  }
  get sessionManager() {
    if (!this.instance.sessionManager) throw new Error(Gs + " must be started with a valid sessionManager.");
    return this.instance.sessionManager;
  }
  get fullSnapshotIntervalMillis() {
    var e, t;
    return "trigger_pending" === this.triggerStatus ? 6e4 : null !== (e = null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.full_snapshot_interval_millis) && void 0 !== e ? e : Ws;
  }
  get isSampled() {
    var e = this.instance.get_property(ye);
    return G(e) ? e : null;
  }
  get sessionDuration() {
    var e,
      t,
      i = null === (e = this.buffer) || void 0 === e ? void 0 : e.data[(null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) - 1],
      {
        sessionStartTimestamp: s
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0);
    return i ? i.timestamp - s : null;
  }
  get isRecordingEnabled() {
    var t = !!this.instance.get_property(he),
      i = !this.instance.config.disable_session_recording;
    return e && t && i;
  }
  get isConsoleLogCaptureEnabled() {
    var e = !!this.instance.get_property(_e),
      t = this.instance.config.enable_recording_console_log;
    return null != t ? t : e;
  }
  get canvasRecording() {
    var e,
      t,
      i,
      s,
      r,
      n,
      o = this.instance.config.session_recording.captureCanvas,
      a = this.instance.get_property(ve),
      l = null !== (e = null !== (t = null == o ? void 0 : o.recordCanvas) && void 0 !== t ? t : null == a ? void 0 : a.enabled) && void 0 !== e && e,
      u = null !== (i = null !== (s = null == o ? void 0 : o.canvasFps) && void 0 !== s ? s : null == a ? void 0 : a.fps) && void 0 !== i ? i : 0,
      c = null !== (r = null !== (n = null == o ? void 0 : o.canvasQuality) && void 0 !== n ? n : null == a ? void 0 : a.quality) && void 0 !== r ? r : 0;
    return {
      enabled: l,
      fps: rs(u, 0, 12, "canvas recording fps"),
      quality: rs(c, 0, 1, "canvas recording quality")
    };
  }
  get networkPayloadCapture() {
    var e,
      t,
      i = this.instance.get_property(pe),
      s = {
        recordHeaders: null === (e = this.instance.config.session_recording) || void 0 === e ? void 0 : e.recordHeaders,
        recordBody: null === (t = this.instance.config.session_recording) || void 0 === t ? void 0 : t.recordBody
      },
      r = (null == s ? void 0 : s.recordHeaders) || (null == i ? void 0 : i.recordHeaders),
      n = (null == s ? void 0 : s.recordBody) || (null == i ? void 0 : i.recordBody),
      o = q(this.instance.config.capture_performance) ? this.instance.config.capture_performance.network_timing : this.instance.config.capture_performance,
      a = !!(G(o) ? o : null == i ? void 0 : i.capturePerformance);
    return r || n || a ? {
      recordHeaders: r,
      recordBody: n,
      recordPerformance: a
    } : void 0;
  }
  get sampleRate() {
    var e = this.instance.get_property(ge);
    return V(e) ? e : null;
  }
  get minimumDuration() {
    var e = this.instance.get_property(fe);
    return V(e) ? e : null;
  }
  get status() {
    return this.receivedDecide ? this.isRecordingEnabled ? this._urlBlocked ? "paused" : j(this._linkedFlag) || this._linkedFlagSeen ? "trigger_pending" === this.triggerStatus ? "buffering" : G(this.isSampled) ? this.isSampled ? "sampled" : "disabled" : "active" : "buffering" : "disabled" : "buffering";
  }
  get urlTriggerStatus() {
    var e;
    return 0 === this._urlTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(we)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get eventTriggerStatus() {
    var e;
    return 0 === this._eventTriggers.length ? "trigger_disabled" : (null === (e = this.instance) || void 0 === e ? void 0 : e.get_property(Se)) === this.sessionId ? "trigger_activated" : "trigger_pending";
  }
  get triggerStatus() {
    var e = "trigger_activated" === this.eventTriggerStatus || "trigger_activated" === this.urlTriggerStatus,
      t = "trigger_pending" === this.eventTriggerStatus || "trigger_pending" === this.urlTriggerStatus;
    return e ? "trigger_activated" : t ? "trigger_pending" : "trigger_disabled";
  }
  constructor(e) {
    if (te(this, "queuedRRWebEvents", []), te(this, "isIdle", !1), te(this, "_linkedFlagSeen", !1), te(this, "_lastActivityTimestamp", Date.now()), te(this, "_linkedFlag", null), te(this, "_removePageViewCaptureHook", void 0), te(this, "_onSessionIdListener", void 0), te(this, "_persistDecideOnSessionListener", void 0), te(this, "_samplingSessionListener", void 0), te(this, "_urlTriggers", []), te(this, "_urlBlocklist", []), te(this, "_urlBlocked", !1), te(this, "_eventTriggers", []), te(this, "_removeEventTriggerCaptureHook", void 0), te(this, "_forceAllowLocalhostNetworkCapture", !1), te(this, "_onBeforeUnload", () => {
      this._flushBuffer();
    }), te(this, "_onOffline", () => {
      this._tryAddCustomEvent("browser offline", {});
    }), te(this, "_onOnline", () => {
      this._tryAddCustomEvent("browser online", {});
    }), te(this, "_onVisibilityChange", () => {
      if (null != o && o.visibilityState) {
        var e = "window " + o.visibilityState;
        this._tryAddCustomEvent(e, {});
      }
    }), this.instance = e, this._captureStarted = !1, this._endpoint = "/s/", this.stopRrweb = void 0, this.receivedDecide = !1, !this.instance.sessionManager) throw X.error(Gs + " started without valid sessionManager"), new Error(Gs + " started without valid sessionManager. This is a bug.");
    var {
      sessionId: t,
      windowId: i
    } = this.sessionManager.checkAndGetSessionAndWindowId();
    this.sessionId = t, this.windowId = i, this.buffer = this.clearBuffer(), this.sessionIdleThresholdMilliseconds >= this.sessionManager.sessionTimeoutMs && X.warn(Gs + " session_idle_threshold_ms (".concat(this.sessionIdleThresholdMilliseconds, ") is greater than the session timeout (").concat(this.sessionManager.sessionTimeoutMs, "). Session will never be detected as idle"));
  }
  startIfEnabledOrStop(t) {
    this.isRecordingEnabled ? (this._startCapture(t), null == e || e.addEventListener("beforeunload", this._onBeforeUnload), null == e || e.addEventListener("offline", this._onOffline), null == e || e.addEventListener("online", this._onOnline), null == e || e.addEventListener("visibilitychange", this._onVisibilityChange), this._setupSampling(), this._addEventTriggerListener(), j(this._removePageViewCaptureHook) && (this._removePageViewCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        if ("$pageview" === e.event) {
          var t = null != e && e.properties.$current_url ? this._maskUrl(null == e ? void 0 : e.properties.$current_url) : "";
          if (!t) return;
          this._tryAddCustomEvent("$pageview", {
            href: t
          });
        }
      } catch (e) {
        X.error("Could not add $pageview to rrweb session", e);
      }
    })), this._onSessionIdListener || (this._onSessionIdListener = this.sessionManager.onSessionId((e, t, i) => {
      var s, r, n, o;
      i && (this._tryAddCustomEvent("$session_id_change", {
        sessionId: e,
        windowId: t,
        changeReason: i
      }), null === (s = this.instance) || void 0 === s || null === (r = s.persistence) || void 0 === r || r.unregister(Se), null === (n = this.instance) || void 0 === n || null === (o = n.persistence) || void 0 === o || o.unregister(we));
    }))) : this.stopRecording();
  }
  stopRecording() {
    var t, i, s, r;
    this._captureStarted && this.stopRrweb && (this.stopRrweb(), this.stopRrweb = void 0, this._captureStarted = !1, null == e || e.removeEventListener("beforeunload", this._onBeforeUnload), null == e || e.removeEventListener("offline", this._onOffline), null == e || e.removeEventListener("online", this._onOnline), null == e || e.removeEventListener("visibilitychange", this._onVisibilityChange), this.clearBuffer(), clearInterval(this._fullSnapshotTimer), null === (t = this._removePageViewCaptureHook) || void 0 === t || t.call(this), this._removePageViewCaptureHook = void 0, null === (i = this._removeEventTriggerCaptureHook) || void 0 === i || i.call(this), this._removeEventTriggerCaptureHook = void 0, null === (s = this._onSessionIdListener) || void 0 === s || s.call(this), this._onSessionIdListener = void 0, null === (r = this._samplingSessionListener) || void 0 === r || r.call(this), this._samplingSessionListener = void 0, X.info(Gs + " stopped"));
  }
  makeSamplingDecision(e) {
    var t,
      i = this.sessionId !== e,
      s = this.sampleRate;
    if (V(s)) {
      var r,
        n = this.isSampled,
        o = i || !G(n);
      if (o) r = Math.random() < s;else r = n;
      o && (r ? this._reportStarted("sampled") : X.warn(Gs + " Sample rate (".concat(s, ") has determined that this sessionId (").concat(e, ") will not be sent to the server.")), this._tryAddCustomEvent("samplingDecisionMade", {
        sampleRate: s,
        isSampled: r
      })), null === (t = this.instance.persistence) || void 0 === t || t.register({
        [ye]: r
      });
    } else {
      var a;
      null === (a = this.instance.persistence) || void 0 === a || a.register({
        [ye]: null
      });
    }
  }
  afterDecideResponse(e) {
    var t, i, s, r, n, o;
    (this._persistDecideResponse(e), this._linkedFlag = (null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.linkedFlag) || null, null !== (i = e.sessionRecording) && void 0 !== i && i.endpoint) && (this._endpoint = null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.endpoint);
    if (this._setupSampling(), !j(this._linkedFlag) && !this._linkedFlagSeen) {
      var a = U(this._linkedFlag) ? this._linkedFlag : this._linkedFlag.flag,
        l = U(this._linkedFlag) ? null : this._linkedFlag.variant;
      this.instance.onFeatureFlags((e, t) => {
        var i = q(t) && a in t,
          s = l ? t[a] === l : i;
        s && this._reportStarted("linked_flag_matched", {
          linkedFlag: a,
          linkedVariant: l
        }), this._linkedFlagSeen = s;
      });
    }
    null !== (s = e.sessionRecording) && void 0 !== s && s.urlTriggers && (this._urlTriggers = e.sessionRecording.urlTriggers), null !== (r = e.sessionRecording) && void 0 !== r && r.urlBlocklist && (this._urlBlocklist = e.sessionRecording.urlBlocklist), null !== (n = e.sessionRecording) && void 0 !== n && n.eventTriggers && (this._eventTriggers = e.sessionRecording.eventTriggers), this.receivedDecide = !0, this.startIfEnabledOrStop();
  }
  _setupSampling() {
    V(this.sampleRate) && j(this._samplingSessionListener) && (this._samplingSessionListener = this.sessionManager.onSessionId(e => {
      this.makeSamplingDecision(e);
    }));
  }
  _persistDecideResponse(e) {
    if (this.instance.persistence) {
      var t,
        i = this.instance.persistence,
        s = () => {
          var t,
            s,
            r,
            n,
            o,
            a,
            l,
            u,
            c = null === (t = e.sessionRecording) || void 0 === t ? void 0 : t.sampleRate,
            d = j(c) ? null : parseFloat(c),
            h = null === (s = e.sessionRecording) || void 0 === s ? void 0 : s.minimumDurationMilliseconds;
          i.register({
            [he]: !!e.sessionRecording,
            [_e]: null === (r = e.sessionRecording) || void 0 === r ? void 0 : r.consoleLogRecordingEnabled,
            [pe]: ee({
              capturePerformance: e.capturePerformance
            }, null === (n = e.sessionRecording) || void 0 === n ? void 0 : n.networkPayloadCapture),
            [ve]: {
              enabled: null === (o = e.sessionRecording) || void 0 === o ? void 0 : o.recordCanvas,
              fps: null === (a = e.sessionRecording) || void 0 === a ? void 0 : a.canvasFps,
              quality: null === (l = e.sessionRecording) || void 0 === l ? void 0 : l.canvasQuality
            },
            [ge]: d,
            [fe]: H(h) ? null : h,
            [me]: null === (u = e.sessionRecording) || void 0 === u ? void 0 : u.scriptConfig
          });
        };
      s(), null === (t = this._persistDecideOnSessionListener) || void 0 === t || t.call(this), this._persistDecideOnSessionListener = this.sessionManager.onSessionId(s);
    }
  }
  log(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
    null === (t = this.instance.sessionRecording) || void 0 === t || t.onRRwebEmit({
      type: 6,
      data: {
        plugin: "rrweb/console@1",
        payload: {
          level: i,
          trace: [],
          payload: [JSON.stringify(e)]
        }
      },
      timestamp: Date.now()
    });
  }
  _startCapture(e) {
    if (!H(Object.assign) && !H(Array.from) && !(this._captureStarted || this.instance.config.disable_session_recording || this.instance.consent.isOptedOut())) {
      var t, i;
      if (this._captureStarted = !0, this.sessionManager.checkAndGetSessionAndWindowId(), this.rrwebRecord) this._onScriptLoaded();else null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, this.scriptName, e => {
        if (e) return X.error(Gs + " could not load recorder", e);
        this._onScriptLoaded();
      });
      X.info(Gs + " starting"), "active" === this.status && this._reportStarted(e || "recording_initialized");
    }
  }
  get scriptName() {
    var e, t, i;
    return (null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t || null === (i = t.get_property(me)) || void 0 === i ? void 0 : i.script) || "recorder";
  }
  isInteractiveEvent(e) {
    var t;
    return 3 === e.type && -1 !== js.indexOf(null === (t = e.data) || void 0 === t ? void 0 : t.source);
  }
  _updateWindowAndSessionIds(e) {
    var t = this.isInteractiveEvent(e);
    t || this.isIdle || e.timestamp - this._lastActivityTimestamp > this.sessionIdleThresholdMilliseconds && (this.isIdle = !0, clearInterval(this._fullSnapshotTimer), this._tryAddCustomEvent("sessionIdle", {
      eventTimestamp: e.timestamp,
      lastActivityTimestamp: this._lastActivityTimestamp,
      threshold: this.sessionIdleThresholdMilliseconds,
      bufferLength: this.buffer.data.length,
      bufferSize: this.buffer.size
    }), this._flushBuffer());
    var i = !1;
    if (t && (this._lastActivityTimestamp = e.timestamp, this.isIdle && (this.isIdle = !1, this._tryAddCustomEvent("sessionNoLongerIdle", {
      reason: "user activity",
      type: e.type
    }), i = !0)), !this.isIdle) {
      var {
          windowId: s,
          sessionId: r
        } = this.sessionManager.checkAndGetSessionAndWindowId(!t, e.timestamp),
        n = this.sessionId !== r,
        o = this.windowId !== s;
      this.windowId = s, this.sessionId = r, n || o ? (this.stopRecording(), this.startIfEnabledOrStop("session_id_changed")) : i && this._scheduleFullSnapshot();
    }
  }
  _tryRRWebMethod(e) {
    try {
      return e.rrwebMethod(), !0;
    } catch (t) {
      return this.queuedRRWebEvents.length < 10 ? this.queuedRRWebEvents.push({
        enqueuedAt: e.enqueuedAt || Date.now(),
        attempt: e.attempt++,
        rrwebMethod: e.rrwebMethod
      }) : X.warn(Gs + " could not emit queued rrweb event.", t, e), !1;
    }
  }
  _tryAddCustomEvent(e, t) {
    return this._tryRRWebMethod(Vs(() => this.rrwebRecord.addCustomEvent(e, t)));
  }
  _tryTakeFullSnapshot() {
    return this._tryRRWebMethod(Vs(() => this.rrwebRecord.takeFullSnapshot()));
  }
  _onScriptLoaded() {
    var e,
      t = {
        blockClass: "ph-no-capture",
        blockSelector: void 0,
        ignoreClass: "ph-ignore-input",
        maskTextClass: "ph-mask",
        maskTextSelector: void 0,
        maskTextFn: void 0,
        maskAllInputs: !0,
        maskInputOptions: {
          password: !0
        },
        maskInputFn: void 0,
        slimDOMOptions: {},
        collectFonts: !1,
        inlineStylesheet: !0,
        recordCrossOriginIframes: !1
      },
      i = this.instance.config.session_recording;
    for (var [s, r] of Object.entries(i || {})) s in t && ("maskInputOptions" === s ? t.maskInputOptions = ee({
      password: !0
    }, r) : t[s] = r);
    if (this.canvasRecording && this.canvasRecording.enabled && (t.recordCanvas = !0, t.sampling = {
      canvas: this.canvasRecording.fps
    }, t.dataURLOptions = {
      type: "image/webp",
      quality: this.canvasRecording.quality
    }), this.rrwebRecord) {
      this.mutationRateLimiter = null !== (e = this.mutationRateLimiter) && void 0 !== e ? e : new ns(this.rrwebRecord, {
        refillRate: this.instance.config.session_recording.__mutationRateLimiterRefillRate,
        bucketSize: this.instance.config.session_recording.__mutationRateLimiterBucketSize,
        onBlockedNode: (e, t) => {
          var i = "Too many mutations on node '".concat(e, "'. Rate limiting. This could be due to SVG animations or something similar");
          X.info(i, {
            node: t
          }), this.log(Gs + " " + i, "warn");
        }
      });
      var n = this._gatherRRWebPlugins();
      this.stopRrweb = this.rrwebRecord(ee({
        emit: e => {
          this.onRRwebEmit(e);
        },
        plugins: n
      }, t)), this._lastActivityTimestamp = Date.now(), this.isIdle = !1, this._tryAddCustomEvent("$session_options", {
        sessionRecordingOptions: t,
        activePlugins: n.map(e => null == e ? void 0 : e.name)
      }), this._tryAddCustomEvent("$posthog_config", {
        config: this.instance.config
      });
    } else X.error(Gs + "onScriptLoaded was called but rrwebRecord is not available. This indicates something has gone wrong.");
  }
  _scheduleFullSnapshot() {
    if (this._fullSnapshotTimer && clearInterval(this._fullSnapshotTimer), !this.isIdle) {
      var e = this.fullSnapshotIntervalMillis;
      e && (this._fullSnapshotTimer = setInterval(() => {
        this._tryTakeFullSnapshot();
      }, e));
    }
  }
  _gatherRRWebPlugins() {
    var e,
      t,
      i,
      s,
      r = [],
      n = null === (e = h.__PosthogExtensions__) || void 0 === e || null === (t = e.rrwebPlugins) || void 0 === t ? void 0 : t.getRecordConsolePlugin;
    n && this.isConsoleLogCaptureEnabled && r.push(n());
    var o = null === (i = h.__PosthogExtensions__) || void 0 === i || null === (s = i.rrwebPlugins) || void 0 === s ? void 0 : s.getRecordNetworkPlugin;
    this.networkPayloadCapture && N(o) && (!dt.includes(location.hostname) || this._forceAllowLocalhostNetworkCapture ? r.push(o(ss(this.instance.config, this.networkPayloadCapture))) : X.info(Gs + " NetworkCapture not started because we are on localhost."));
    return r;
  }
  onRRwebEmit(e) {
    var t;
    if (this._processQueuedEvents(), e && q(e)) {
      if (e.type === bi.Meta) {
        var i = this._maskUrl(e.data.href);
        if (this._lastHref = i, !i) return;
        e.data.href = i;
      } else this._pageViewFallBack();
      if (this._checkUrlTriggerConditions(), "paused" !== this.status || function (e) {
        return e.type === bi.Custom && "recording paused" === e.data.tag;
      }(e)) {
        e.type === bi.FullSnapshot && this._scheduleFullSnapshot(), e.type === bi.FullSnapshot && "trigger_pending" === this.triggerStatus && this.clearBuffer();
        var s = this.mutationRateLimiter ? this.mutationRateLimiter.throttleMutations(e) : e;
        if (s) {
          var r = function (e) {
            var t = e;
            if (t && q(t) && 6 === t.type && q(t.data) && "rrweb/console@1" === t.data.plugin) {
              t.data.payload.payload.length > 10 && (t.data.payload.payload = t.data.payload.payload.slice(0, 10), t.data.payload.payload.push("...[truncated]"));
              for (var i = [], s = 0; s < t.data.payload.payload.length; s++) t.data.payload.payload[s] && t.data.payload.payload[s].length > 2e3 ? i.push(t.data.payload.payload[s].slice(0, 2e3) + "...[truncated]") : i.push(t.data.payload.payload[s]);
              return t.data.payload.payload = i, e;
            }
            return e;
          }(s);
          if (this._updateWindowAndSessionIds(r), !this.isIdle || Ys(r)) {
            if (Ys(r)) {
              var n = r.data.payload;
              if (n) {
                var o = n.lastActivityTimestamp,
                  a = n.threshold;
                r.timestamp = o + a;
              }
            }
            var l = null === (t = this.instance.config.session_recording.compress_events) || void 0 === t || t ? function (e) {
                if (fi(e) < 1024) return e;
                try {
                  if (e.type === bi.FullSnapshot) return ee(ee({}, e), {}, {
                    data: Qs(e.data),
                    cv: "2024-10"
                  });
                  if (e.type === bi.IncrementalSnapshot && e.data.source === yi.Mutation) return ee(ee({}, e), {}, {
                    cv: "2024-10",
                    data: ee(ee({}, e.data), {}, {
                      texts: Qs(e.data.texts),
                      attributes: Qs(e.data.attributes),
                      removes: Qs(e.data.removes),
                      adds: Qs(e.data.adds)
                    })
                  });
                  if (e.type === bi.IncrementalSnapshot && e.data.source === yi.StyleSheetRule) return ee(ee({}, e), {}, {
                    cv: "2024-10",
                    data: ee(ee({}, e.data), {}, {
                      adds: Qs(e.data.adds),
                      removes: Qs(e.data.removes)
                    })
                  });
                } catch (e) {
                  X.error(Gs + " could not compress event - will use uncompressed event", e);
                }
                return e;
              }(r) : r,
              u = {
                $snapshot_bytes: fi(l),
                $snapshot_data: l,
                $session_id: this.sessionId,
                $window_id: this.windowId
              };
            "disabled" !== this.status ? this._captureSnapshotBuffered(u) : this.clearBuffer();
          }
        }
      }
    }
  }
  _pageViewFallBack() {
    if (!this.instance.config.capture_pageview && e) {
      var t = this._maskUrl(e.location.href);
      this._lastHref !== t && (this._tryAddCustomEvent("$url_changed", {
        href: t
      }), this._lastHref = t);
    }
  }
  _processQueuedEvents() {
    if (this.queuedRRWebEvents.length) {
      var e = [...this.queuedRRWebEvents];
      this.queuedRRWebEvents = [], e.forEach(e => {
        Date.now() - e.enqueuedAt <= 2e3 && this._tryRRWebMethod(e);
      });
    }
  }
  _maskUrl(e) {
    var t = this.instance.config.session_recording;
    if (t.maskNetworkRequestFn) {
      var i,
        s = {
          url: e
        };
      return null === (i = s = t.maskNetworkRequestFn(s)) || void 0 === i ? void 0 : i.url;
    }
    return e;
  }
  clearBuffer() {
    return this.buffer = {
      size: 0,
      data: [],
      sessionId: this.sessionId,
      windowId: this.windowId
    }, this.buffer;
  }
  _flushBuffer() {
    this.flushBufferTimer && (clearTimeout(this.flushBufferTimer), this.flushBufferTimer = void 0);
    var e = this.minimumDuration,
      t = this.sessionDuration,
      i = V(t) && t >= 0,
      s = V(e) && i && t < e;
    if ("buffering" === this.status || s) return this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3), this.buffer;
    this.buffer.data.length > 0 && mi(this.buffer).forEach(e => {
      this._captureSnapshot({
        $snapshot_bytes: e.size,
        $snapshot_data: e.data,
        $session_id: e.sessionId,
        $window_id: e.windowId
      });
    });
    return this.clearBuffer();
  }
  _captureSnapshotBuffered(e) {
    var t,
      i = 2 + ((null === (t = this.buffer) || void 0 === t ? void 0 : t.data.length) || 0);
    !this.isIdle && (this.buffer.size + e.$snapshot_bytes + i > 943718.4 || this.buffer.sessionId !== this.sessionId) && (this.buffer = this._flushBuffer()), this.buffer.size += e.$snapshot_bytes, this.buffer.data.push(e.$snapshot_data), this.flushBufferTimer || this.isIdle || (this.flushBufferTimer = setTimeout(() => {
      this._flushBuffer();
    }, 2e3));
  }
  _captureSnapshot(e) {
    this.instance.capture("$snapshot", e, {
      _url: this.instance.requestRouter.endpointFor("api", this._endpoint),
      _noTruncate: !0,
      _batchKey: "recordings",
      skip_client_rate_limiting: !0
    });
  }
  _checkUrlTriggerConditions() {
    if (void 0 !== e && e.location.href) {
      var t = e.location.href,
        i = "paused" === this.status,
        s = Js(t, this._urlBlocklist);
      s && !i ? this._pauseRecording() : !s && i && this._resumeRecording(), Js(t, this._urlTriggers) && this._activateTrigger("url");
    }
  }
  _activateTrigger(e) {
    var t, i;
    "trigger_pending" === this.triggerStatus && (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      ["url" === e ? we : Se]: this.sessionId
    }), this._flushBuffer(), this._reportStarted(e + "_trigger_matched"));
  }
  _pauseRecording() {
    var e, t;
    "paused" !== this.status && (this._urlBlocked = !0, null == o || null === (e = o.body) || void 0 === e || null === (t = e.classList) || void 0 === t || t.add("ph-no-capture"), clearInterval(this._fullSnapshotTimer), setTimeout(() => {
      this._flushBuffer();
    }, 100), X.info(Gs + " recording paused due to URL blocker"), this._tryAddCustomEvent("recording paused", {
      reason: "url blocker"
    }));
  }
  _resumeRecording() {
    var e, t;
    "paused" === this.status && (this._urlBlocked = !1, null == o || null === (e = o.body) || void 0 === e || null === (t = e.classList) || void 0 === t || t.remove("ph-no-capture"), this._tryTakeFullSnapshot(), this._scheduleFullSnapshot(), this._tryAddCustomEvent("recording resumed", {
      reason: "left blocked url"
    }), X.info(Gs + " recording resumed"));
  }
  _addEventTriggerListener() {
    0 !== this._eventTriggers.length && j(this._removeEventTriggerCaptureHook) && (this._removeEventTriggerCaptureHook = this.instance.on("eventCaptured", e => {
      try {
        this._eventTriggers.includes(e.event) && this._activateTrigger("event");
      } catch (e) {
        X.error(Gs + "Could not activate event trigger", e);
      }
    }));
  }
  overrideLinkedFlag() {
    this._linkedFlagSeen = !0, this._reportStarted("linked_flag_overridden");
  }
  overrideSampling() {
    var e;
    null === (e = this.instance.persistence) || void 0 === e || e.register({
      [ye]: !0
    }), this._reportStarted("sampling_overridden");
  }
  overrideTrigger(e) {
    this._activateTrigger(e);
  }
  _reportStarted(e, t) {
    this.instance.register_for_session({
      $session_recording_start_reason: e
    }), X.info(Gs + " " + e.replace("_", " "), t), b(["recording_initialized", "session_id_changed"], e) || this._tryAddCustomEvent(e, t);
  }
}
class Ks {
  constructor(e) {
    this.instance = e, this.instance.decideEndpointWasHit = this.instance._hasBootstrappedFeatureFlags();
  }
  call() {
    var e = {
      token: this.instance.config.token,
      distinct_id: this.instance.get_distinct_id(),
      groups: this.instance.getGroups(),
      person_properties: this.instance.get_property(xe),
      group_properties: this.instance.get_property(Ie),
      disable_flags: this.instance.config.advanced_disable_feature_flags || this.instance.config.advanced_disable_feature_flags_on_first_load || void 0
    };
    this.instance._send_request({
      method: "POST",
      url: this.instance.requestRouter.endpointFor("api", "/decide/?v=3"),
      data: e,
      compression: this.instance.config.disable_compression ? void 0 : R.Base64,
      timeout: this.instance.config.feature_flag_request_timeout_ms,
      callback: e => this.parseDecideResponse(e.json)
    });
  }
  parseDecideResponse(e) {
    this.instance.featureFlags.setReloadingPaused(!1), this.instance.featureFlags._startReloadTimer();
    var t = !e;
    if (this.instance.config.advanced_disable_feature_flags_on_first_load || this.instance.config.advanced_disable_feature_flags || this.instance.featureFlags.receivedFeatureFlags(null != e ? e : {}, t), !t) return o && o.body ? void this.instance._afterDecideResponse(e) : (X.info("document not ready yet, trying again in 500 milliseconds..."), void setTimeout(() => {
      this.parseDecideResponse(e);
    }, 500));
    X.error("Failed to fetch feature flags from PostHog.");
  }
}
var Zs,
  er = null != e && e.location ? gt(e.location.hash, "__posthog") || gt(location.hash, "state") : null,
  tr = "_postHogToolbarParams";
!function (e) {
  e[e.UNINITIALIZED = 0] = "UNINITIALIZED", e[e.LOADING = 1] = "LOADING", e[e.LOADED = 2] = "LOADED";
}(Zs || (Zs = {}));
class ir {
  constructor(e) {
    this.instance = e;
  }
  setToolbarState(e) {
    h.ph_toolbar_state = e;
  }
  getToolbarState() {
    var e;
    return null !== (e = h.ph_toolbar_state) && void 0 !== e ? e : Zs.UNINITIALIZED;
  }
  maybeLoadToolbar() {
    var t,
      i,
      s = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    if (!e || !o) return !1;
    s = null !== (t = s) && void 0 !== t ? t : e.location, n = null !== (i = n) && void 0 !== i ? i : e.history;
    try {
      if (!r) {
        try {
          e.localStorage.setItem("test", "test"), e.localStorage.removeItem("test");
        } catch (e) {
          return !1;
        }
        r = null == e ? void 0 : e.localStorage;
      }
      var a,
        l = er || gt(s.hash, "__posthog") || gt(s.hash, "state"),
        u = l ? w(() => JSON.parse(atob(decodeURIComponent(l)))) || w(() => JSON.parse(decodeURIComponent(l))) : null;
      return u && "ph_authorize" === u.action ? ((a = u).source = "url", a && Object.keys(a).length > 0 && (u.desiredHash ? s.hash = u.desiredHash : n ? n.replaceState(n.state, "", s.pathname + s.search) : s.hash = "")) : ((a = JSON.parse(r.getItem(tr) || "{}")).source = "localstorage", delete a.userIntent), !(!a.token || this.instance.config.token !== a.token) && (this.loadToolbar(a), !0);
    } catch (e) {
      return !1;
    }
  }
  _callLoadToolbar(e) {
    (h.ph_load_toolbar || h.ph_load_editor)(e, this.instance);
  }
  loadToolbar(t) {
    var i = !(null == o || !o.getElementById(De));
    if (!e || i) return !1;
    var s = "custom" === this.instance.requestRouter.region && this.instance.config.advanced_disable_toolbar_metrics,
      r = ee(ee({
        token: this.instance.config.token
      }, t), {}, {
        apiURL: this.instance.requestRouter.endpointFor("ui")
      }, s ? {
        instrument: !1
      } : {});
    if (e.localStorage.setItem(tr, JSON.stringify(ee(ee({}, r), {}, {
      source: void 0
    }))), this.getToolbarState() === Zs.LOADED) this._callLoadToolbar(r);else if (this.getToolbarState() === Zs.UNINITIALIZED) {
      var n, a;
      this.setToolbarState(Zs.LOADING), null === (n = h.__PosthogExtensions__) || void 0 === n || null === (a = n.loadExternalDependency) || void 0 === a || a.call(n, this.instance, "toolbar", e => {
        if (e) return X.error("Failed to load toolbar", e), void this.setToolbarState(Zs.UNINITIALIZED);
        this.setToolbarState(Zs.LOADED), this._callLoadToolbar(r);
      }), P(e, "turbolinks:load", () => {
        this.setToolbarState(Zs.UNINITIALIZED), this.loadToolbar(r);
      });
    }
    return !0;
  }
  _loadEditor(e) {
    return this.loadToolbar(e);
  }
  maybeLoadEditor() {
    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : void 0,
      t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : void 0,
      i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : void 0;
    return this.maybeLoadToolbar(e, t, i);
  }
}
class sr {
  constructor(e) {
    te(this, "isPaused", !0), te(this, "queue", []), te(this, "flushTimeoutMs", 3e3), this.sendRequest = e;
  }
  enqueue(e) {
    this.queue.push(e), this.flushTimeout || this.setFlushTimeout();
  }
  unload() {
    this.clearFlushTimeout();
    var e = this.queue.length > 0 ? this.formatQueue() : {},
      t = Object.values(e),
      i = [...t.filter(e => 0 === e.url.indexOf("/e")), ...t.filter(e => 0 !== e.url.indexOf("/e"))];
    i.map(e => {
      this.sendRequest(ee(ee({}, e), {}, {
        transport: "sendBeacon"
      }));
    });
  }
  enable() {
    this.isPaused = !1, this.setFlushTimeout();
  }
  setFlushTimeout() {
    var e = this;
    this.isPaused || (this.flushTimeout = setTimeout(() => {
      if (this.clearFlushTimeout(), this.queue.length > 0) {
        var t = this.formatQueue(),
          i = function (i) {
            var s = t[i],
              r = new Date().getTime();
            s.data && D(s.data) && f(s.data, e => {
              e.offset = Math.abs(e.timestamp - r), delete e.timestamp;
            }), e.sendRequest(s);
          };
        for (var s in t) i(s);
      }
    }, this.flushTimeoutMs));
  }
  clearFlushTimeout() {
    clearTimeout(this.flushTimeout), this.flushTimeout = void 0;
  }
  formatQueue() {
    var e = {};
    return f(this.queue, t => {
      var i,
        s = t,
        r = (s ? s.batchKey : null) || s.url;
      H(e[r]) && (e[r] = ee(ee({}, s), {}, {
        data: []
      })), null === (i = e[r].data) || void 0 === i || i.push(s.data);
    }), this.queue = [], e;
  }
}
var rr = !!u || !!l,
  nr = "text/plain",
  or = (e, t) => {
    var [i, s] = e.split("?"),
      r = ee({}, t);
    null == s || s.split("&").forEach(e => {
      var [t] = e.split("=");
      delete r[t];
    });
    var n = pt(r);
    return n = n ? (s ? s + "&" : "") + n : s, "".concat(i, "?").concat(n);
  },
  ar = (e, t) => JSON.stringify(e, (e, t) => "bigint" == typeof t ? t.toString() : t, t),
  lr = e => {
    var {
      data: t,
      compression: i
    } = e;
    if (t) {
      if (i === R.GZipJS) {
        var s = Us(zs(ar(t)), {
            mtime: 0
          }),
          r = new Blob([s], {
            type: nr
          });
        return {
          contentType: nr,
          body: r,
          estimatedSize: r.size
        };
      }
      if (i === R.Base64) {
        var n = function (e) {
            var t,
              i,
              s,
              r,
              n,
              o = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
              a = 0,
              l = 0,
              u = "",
              c = [];
            if (!e) return e;
            e = I(e);
            do {
              t = (n = e.charCodeAt(a++) << 16 | e.charCodeAt(a++) << 8 | e.charCodeAt(a++)) >> 18 & 63, i = n >> 12 & 63, s = n >> 6 & 63, r = 63 & n, c[l++] = o.charAt(t) + o.charAt(i) + o.charAt(s) + o.charAt(r);
            } while (a < e.length);
            switch (u = c.join(""), e.length % 3) {
              case 1:
                u = u.slice(0, -2) + "==";
                break;
              case 2:
                u = u.slice(0, -1) + "=";
            }
            return u;
          }(ar(t)),
          o = (e => "data=" + encodeURIComponent("string" == typeof e ? e : ar(e)))(n);
        return {
          contentType: "application/x-www-form-urlencoded",
          body: o,
          estimatedSize: new Blob([o]).size
        };
      }
      var a = ar(t);
      return {
        contentType: "application/json",
        body: a,
        estimatedSize: new Blob([a]).size
      };
    }
  },
  ur = [];
u && ur.push({
  transport: "XHR",
  method: e => {
    var t,
      i = new u();
    i.open(e.method || "GET", e.url, !0);
    var {
      contentType: s,
      body: r
    } = null !== (t = lr(e)) && void 0 !== t ? t : {};
    f(e.headers, function (e, t) {
      i.setRequestHeader(t, e);
    }), s && i.setRequestHeader("Content-Type", s), e.timeout && (i.timeout = e.timeout), i.withCredentials = !0, i.onreadystatechange = () => {
      if (4 === i.readyState) {
        var t,
          s = {
            statusCode: i.status,
            text: i.responseText
          };
        if (200 === i.status) try {
          s.json = JSON.parse(i.responseText);
        } catch (e) {}
        null === (t = e.callback) || void 0 === t || t.call(e, s);
      }
    }, i.send(r);
  }
}), l && ur.push({
  transport: "fetch",
  method: e => {
    var t,
      i,
      {
        contentType: s,
        body: r,
        estimatedSize: n
      } = null !== (t = lr(e)) && void 0 !== t ? t : {},
      o = new Headers();
    f(e.headers, function (e, t) {
      o.append(t, e);
    }), s && o.append("Content-Type", s);
    var a = e.url,
      u = null;
    if (c) {
      var d = new c();
      u = {
        signal: d.signal,
        timeout: setTimeout(() => d.abort(), e.timeout)
      };
    }
    l(a, {
      method: (null == e ? void 0 : e.method) || "GET",
      headers: o,
      keepalive: "POST" === e.method && (n || 0) < 52428.8,
      body: r,
      signal: null === (i = u) || void 0 === i ? void 0 : i.signal
    }).then(t => t.text().then(i => {
      var s,
        r = {
          statusCode: t.status,
          text: i
        };
      if (200 === t.status) try {
        r.json = JSON.parse(i);
      } catch (e) {
        X.error(e);
      }
      null === (s = e.callback) || void 0 === s || s.call(e, r);
    })).catch(t => {
      var i;
      X.error(t), null === (i = e.callback) || void 0 === i || i.call(e, {
        statusCode: 0,
        text: t
      });
    }).finally(() => u ? clearTimeout(u.timeout) : null);
  }
}), null != n && n.sendBeacon && ur.push({
  transport: "sendBeacon",
  method: e => {
    var t = or(e.url, {
      beacon: "1"
    });
    try {
      var i,
        {
          contentType: s,
          body: r
        } = null !== (i = lr(e)) && void 0 !== i ? i : {},
        o = "string" == typeof r ? new Blob([r], {
          type: s
        }) : r;
      n.sendBeacon(t, o);
    } catch (e) {}
  }
});
var cr = ["retriesPerformedSoFar"];
class dr {
  constructor(t) {
    te(this, "isPolling", !1), te(this, "pollIntervalMs", 3e3), te(this, "queue", []), this.instance = t, this.queue = [], this.areWeOnline = !0, !H(e) && "onLine" in e.navigator && (this.areWeOnline = e.navigator.onLine, e.addEventListener("online", () => {
      this.areWeOnline = !0, this.flush();
    }), e.addEventListener("offline", () => {
      this.areWeOnline = !1;
    }));
  }
  retriableRequest(e) {
    var {
        retriesPerformedSoFar: t
      } = e,
      i = ie(e, cr);
    V(t) && t > 0 && (i.url = or(i.url, {
      retry_count: t
    })), this.instance._send_request(ee(ee({}, i), {}, {
      callback: e => {
        var s;
        200 !== e.statusCode && (e.statusCode < 400 || e.statusCode >= 500) && (null != t ? t : 0) < 10 ? this.enqueue(ee({
          retriesPerformedSoFar: t
        }, i)) : null === (s = i.callback) || void 0 === s || s.call(i, e);
      }
    }));
  }
  enqueue(e) {
    var t = e.retriesPerformedSoFar || 0;
    e.retriesPerformedSoFar = t + 1;
    var i = function (e) {
        var t = 3e3 * Math.pow(2, e),
          i = t / 2,
          s = Math.min(18e5, t),
          r = (Math.random() - .5) * (s - i);
        return Math.ceil(s + r);
      }(t),
      s = Date.now() + i;
    this.queue.push({
      retryAt: s,
      requestOptions: e
    });
    var r = "Enqueued failed request for retry in ".concat(i);
    navigator.onLine || (r += " (Browser is offline)"), X.warn(r), this.isPolling || (this.isPolling = !0, this.poll());
  }
  poll() {
    this.poller && clearTimeout(this.poller), this.poller = setTimeout(() => {
      this.areWeOnline && this.queue.length > 0 && this.flush(), this.poll();
    }, this.pollIntervalMs);
  }
  flush() {
    var e = Date.now(),
      t = [],
      i = this.queue.filter(i => i.retryAt < e || (t.push(i), !1));
    if (this.queue = t, i.length > 0) for (var {
      requestOptions: s
    } of i) this.retriableRequest(s);
  }
  unload() {
    for (var {
      requestOptions: e
    } of (this.poller && (clearTimeout(this.poller), this.poller = void 0), this.queue)) try {
      this.instance._send_request(ee(ee({}, e), {}, {
        transport: "sendBeacon"
      }));
    } catch (e) {
      X.error(e);
    }
    this.queue = [];
  }
}
var hr;
class _r {
  constructor(e, t, i) {
    var s;
    if (te(this, "_sessionIdChangedHandlers", []), !e.persistence) throw new Error("SessionIdManager requires a PostHogPersistence instance");
    this.config = e.config, this.persistence = e.persistence, this._windowId = void 0, this._sessionId = void 0, this._sessionStartTimestamp = null, this._sessionActivityTimestamp = null, this._sessionIdGenerator = t || Je, this._windowIdGenerator = i || Je;
    var r = this.config.persistence_name || this.config.token,
      n = this.config.session_idle_timeout_seconds || 1800;
    if (this._sessionTimeoutMs = 1e3 * rs(n, 60, 36e3, "session_idle_timeout_seconds", 1800), e.register({
      $configured_session_timeout_ms: this._sessionTimeoutMs
    }), this._window_id_storage_key = "ph_" + r + "_window_id", this._primary_window_exists_storage_key = "ph_" + r + "_primary_window_exists", this._canUseSessionStorage()) {
      var o = ct.parse(this._window_id_storage_key),
        a = ct.parse(this._primary_window_exists_storage_key);
      o && !a ? this._windowId = o : ct.remove(this._window_id_storage_key), ct.set(this._primary_window_exists_storage_key, !0);
    }
    if (null !== (s = this.config.bootstrap) && void 0 !== s && s.sessionID) try {
      var l = (e => {
        var t = e.replace(/-/g, "");
        if (32 !== t.length) throw new Error("Not a valid UUID");
        if ("7" !== t[12]) throw new Error("Not a UUIDv7");
        return parseInt(t.substring(0, 12), 16);
      })(this.config.bootstrap.sessionID);
      this._setSessionId(this.config.bootstrap.sessionID, new Date().getTime(), l);
    } catch (e) {
      X.error("Invalid sessionID in bootstrap", e);
    }
    this._listenToReloadWindow();
  }
  get sessionTimeoutMs() {
    return this._sessionTimeoutMs;
  }
  onSessionId(e) {
    return H(this._sessionIdChangedHandlers) && (this._sessionIdChangedHandlers = []), this._sessionIdChangedHandlers.push(e), this._sessionId && e(this._sessionId, this._windowId), () => {
      this._sessionIdChangedHandlers = this._sessionIdChangedHandlers.filter(t => t !== e);
    };
  }
  _canUseSessionStorage() {
    return "memory" !== this.config.persistence && !this.persistence.disabled && ct.is_supported();
  }
  _setWindowId(e) {
    e !== this._windowId && (this._windowId = e, this._canUseSessionStorage() && ct.set(this._window_id_storage_key, e));
  }
  _getWindowId() {
    return this._windowId ? this._windowId : this._canUseSessionStorage() ? ct.parse(this._window_id_storage_key) : null;
  }
  _setSessionId(e, t, i) {
    e === this._sessionId && t === this._sessionActivityTimestamp && i === this._sessionStartTimestamp || (this._sessionStartTimestamp = i, this._sessionActivityTimestamp = t, this._sessionId = e, this.persistence.register({
      [be]: [t, e, i]
    }));
  }
  _getSessionId() {
    if (this._sessionId && this._sessionActivityTimestamp && this._sessionStartTimestamp) return [this._sessionActivityTimestamp, this._sessionId, this._sessionStartTimestamp];
    var e = this.persistence.props[be];
    return D(e) && 2 === e.length && e.push(e[0]), e || [0, null, 0];
  }
  resetSessionId() {
    this._setSessionId(null, null, null);
  }
  _listenToReloadWindow() {
    null == e || e.addEventListener("beforeunload", () => {
      this._canUseSessionStorage() && ct.remove(this._primary_window_exists_storage_key);
    });
  }
  checkAndGetSessionAndWindowId() {
    var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      t = (arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null) || new Date().getTime(),
      [i, s, r] = this._getSessionId(),
      n = this._getWindowId(),
      o = V(r) && r > 0 && Math.abs(t - r) > 864e5,
      a = !1,
      l = !s,
      u = !e && Math.abs(t - i) > this.sessionTimeoutMs;
    l || u || o ? (s = this._sessionIdGenerator(), n = this._windowIdGenerator(), X.info("[SessionId] new session ID generated", {
      sessionId: s,
      windowId: n,
      changeReason: {
        noSessionId: l,
        activityTimeout: u,
        sessionPastMaximumLength: o
      }
    }), r = t, a = !0) : n || (n = this._windowIdGenerator(), a = !0);
    var c = 0 === i || !e || o ? t : i,
      d = 0 === r ? new Date().getTime() : r;
    return this._setWindowId(n), this._setSessionId(s, c, d), a && this._sessionIdChangedHandlers.forEach(e => e(s, n, a ? {
      noSessionId: l,
      activityTimeout: u,
      sessionPastMaximumLength: o
    } : void 0)), {
      sessionId: s,
      windowId: n,
      sessionStartTimestamp: d,
      changeReason: a ? {
        noSessionId: l,
        activityTimeout: u,
        sessionPastMaximumLength: o
      } : void 0,
      lastActivityTimestamp: i
    };
  }
}
!function (e) {
  e.US = "us", e.EU = "eu", e.CUSTOM = "custom";
}(hr || (hr = {}));
var pr = "i.posthog.com";
class vr {
  constructor(e) {
    te(this, "_regionCache", {}), this.instance = e;
  }
  get apiHost() {
    var e = this.instance.config.api_host.trim().replace(/\/$/, "");
    return "https://app.posthog.com" === e ? "https://us.i.posthog.com" : e;
  }
  get uiHost() {
    var e,
      t = null === (e = this.instance.config.ui_host) || void 0 === e ? void 0 : e.replace(/\/$/, "");
    return t || (t = this.apiHost.replace(".".concat(pr), ".posthog.com")), "https://app.posthog.com" === t ? "https://us.posthog.com" : t;
  }
  get region() {
    return this._regionCache[this.apiHost] || (/https:\/\/(app|us|us-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = hr.US : /https:\/\/(eu|eu-assets)(\.i)?\.posthog\.com/i.test(this.apiHost) ? this._regionCache[this.apiHost] = hr.EU : this._regionCache[this.apiHost] = hr.CUSTOM), this._regionCache[this.apiHost];
  }
  endpointFor(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "";
    if (t && (t = "/" === t[0] ? t : "/".concat(t)), "ui" === e) return this.uiHost + t;
    if (this.region === hr.CUSTOM) return this.apiHost + t;
    var i = pr + t;
    switch (e) {
      case "assets":
        return "https://".concat(this.region, "-assets.").concat(i);
      case "api":
        return "https://".concat(this.region, ".").concat(i);
    }
  }
}
var gr = "posthog-js";
function fr(e) {
  var {
    organization: t,
    projectId: i,
    prefix: s,
    severityAllowList: r = ["error"]
  } = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
  return n => {
    var o, a, l, u, c;
    if (!("*" === r || r.includes(n.level)) || !e.__loaded) return n;
    n.tags || (n.tags = {});
    var d = e.requestRouter.endpointFor("ui", "/project/".concat(e.config.token, "/person/").concat(e.get_distinct_id()));
    n.tags["PostHog Person URL"] = d, e.sessionRecordingStarted() && (n.tags["PostHog Recording URL"] = e.get_session_replay_url({
      withTimestamp: !0
    }));
    var h = (null === (o = n.exception) || void 0 === o ? void 0 : o.values) || [];
    h.map(e => {
      e.stacktrace && (e.stacktrace.type = "raw");
    });
    var _ = {
      $exception_message: (null === (a = h[0]) || void 0 === a ? void 0 : a.value) || n.message,
      $exception_type: null === (l = h[0]) || void 0 === l ? void 0 : l.type,
      $exception_personURL: d,
      $exception_level: n.level,
      $exception_list: h,
      $sentry_event_id: n.event_id,
      $sentry_exception: n.exception,
      $sentry_exception_message: (null === (u = h[0]) || void 0 === u ? void 0 : u.value) || n.message,
      $sentry_exception_type: null === (c = h[0]) || void 0 === c ? void 0 : c.type,
      $sentry_tags: n.tags
    };
    return t && i && (_.$sentry_url = (s || "https://sentry.io/organizations/") + t + "/issues/?project=" + i + "&query=" + n.event_id), e.exceptions.sendExceptionEvent(_), n;
  };
}
class mr {
  constructor(e, t, i, s, r) {
    this.name = gr, this.setupOnce = function (n) {
      n(fr(e, {
        organization: t,
        projectId: i,
        prefix: s,
        severityAllowList: r
      }));
    };
  }
}
var br, yr, wr;
function Sr(e, t) {
  var i = e.config.segment;
  if (!i) return t();
  !function (e, t) {
    var i = e.config.segment;
    if (!i) return t();
    var s = i => {
        var s = () => i.anonymousId() || Je();
        e.config.get_device_id = s, i.id() && (e.register({
          distinct_id: i.id(),
          $device_id: s()
        }), e.persistence.set_property(Ce, "identified")), t();
      },
      r = i.user();
    "then" in r && N(r.then) ? r.then(e => s(e)) : s(r);
  }(e, () => {
    i.register((e => {
      Promise && Promise.resolve || X.warn("This browser does not have Promise support, and can not use the segment integration");
      var t = (t, i) => {
        var s;
        if (!i) return t;
        t.event.userId || t.event.anonymousId === e.get_distinct_id() || (X.info("Segment integration does not have a userId set, resetting PostHog"), e.reset()), t.event.userId && t.event.userId !== e.get_distinct_id() && (X.info("Segment integration has a userId set, identifying with PostHog"), e.identify(t.event.userId));
        var r = e._calculate_event_properties(i, null !== (s = t.event.properties) && void 0 !== s ? s : {}, new Date());
        return t.event.properties = Object.assign({}, r, t.event.properties), t;
      };
      return {
        name: "PostHog JS",
        type: "enrichment",
        version: "1.0.0",
        isLoaded: () => !0,
        load: () => Promise.resolve(),
        track: e => t(e, e.event.event),
        page: e => t(e, "$pageview"),
        identify: e => t(e, "$identify"),
        screen: e => t(e, "$screen")
      };
    })(e)).then(() => {
      t();
    });
  });
}
class Er {
  constructor(e) {
    this._instance = e;
  }
  doPageView(t) {
    var i,
      s = this._previousPageViewProperties(t);
    return this._currentPath = null !== (i = null == e ? void 0 : e.location.pathname) && void 0 !== i ? i : "", this._instance.scrollManager.resetContext(), this._prevPageviewTimestamp = t, s;
  }
  doPageLeave(e) {
    return this._previousPageViewProperties(e);
  }
  _previousPageViewProperties(e) {
    var t = this._currentPath,
      i = this._prevPageviewTimestamp,
      s = this._instance.scrollManager.getContext();
    if (!i) return {};
    var r = {};
    if (s) {
      var {
        maxScrollHeight: n,
        lastScrollY: o,
        maxScrollY: a,
        maxContentHeight: l,
        lastContentY: u,
        maxContentY: c
      } = s;
      if (!(H(n) || H(o) || H(a) || H(l) || H(u) || H(c))) n = Math.ceil(n), o = Math.ceil(o), a = Math.ceil(a), l = Math.ceil(l), u = Math.ceil(u), c = Math.ceil(c), r = {
        $prev_pageview_last_scroll: o,
        $prev_pageview_last_scroll_percentage: n <= 1 ? 1 : rs(o / n, 0, 1),
        $prev_pageview_max_scroll: a,
        $prev_pageview_max_scroll_percentage: n <= 1 ? 1 : rs(a / n, 0, 1),
        $prev_pageview_last_content: u,
        $prev_pageview_last_content_percentage: l <= 1 ? 1 : rs(u / l, 0, 1),
        $prev_pageview_max_content: c,
        $prev_pageview_max_content_percentage: l <= 1 ? 1 : rs(c / l, 0, 1)
      };
    }
    return t && (r.$prev_pageview_pathname = t), i && (r.$prev_pageview_duration = (e.getTime() - i.getTime()) / 1e3), r;
  }
}
!function (e) {
  e.Popover = "popover", e.API = "api", e.Widget = "widget";
}(br || (br = {})), function (e) {
  e.Open = "open", e.MultipleChoice = "multiple_choice", e.SingleChoice = "single_choice", e.Rating = "rating", e.Link = "link";
}(yr || (yr = {})), function (e) {
  e.NextQuestion = "next_question", e.End = "end", e.ResponseBased = "response_based", e.SpecificQuestion = "specific_question";
}(wr || (wr = {}));
class kr {
  constructor() {
    te(this, "events", {}), this.events = {};
  }
  on(e, t) {
    return this.events[e] || (this.events[e] = []), this.events[e].push(t), () => {
      this.events[e] = this.events[e].filter(e => e !== t);
    };
  }
  emit(e, t) {
    for (var i of this.events[e] || []) i(t);
    for (var s of this.events["*"] || []) s(e, t);
  }
}
class xr {
  constructor(e) {
    te(this, "_debugEventEmitter", new kr()), te(this, "checkStep", (e, t) => this.checkStepEvent(e, t) && this.checkStepUrl(e, t) && this.checkStepElement(e, t)), te(this, "checkStepEvent", (e, t) => null == t || !t.event || (null == e ? void 0 : e.event) === (null == t ? void 0 : t.event)), this.instance = e, this.actionEvents = new Set(), this.actionRegistry = new Set();
  }
  init() {
    var e;
    if (!H(null === (e = this.instance) || void 0 === e ? void 0 : e._addCaptureHook)) {
      var t;
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.on(e, t);
      });
    }
  }
  register(e) {
    var t, i;
    if (!H(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) && (e.forEach(e => {
      var t, i;
      null === (t = this.actionRegistry) || void 0 === t || t.add(e), null === (i = e.steps) || void 0 === i || i.forEach(e => {
        var t;
        null === (t = this.actionEvents) || void 0 === t || t.add((null == e ? void 0 : e.event) || "");
      });
    }), null !== (i = this.instance) && void 0 !== i && i.autocapture)) {
      var s,
        r = new Set();
      e.forEach(e => {
        var t;
        null === (t = e.steps) || void 0 === t || t.forEach(e => {
          null != e && e.selector && r.add(null == e ? void 0 : e.selector);
        });
      }), null === (s = this.instance) || void 0 === s || s.autocapture.setElementSelectors(r);
    }
  }
  on(e, t) {
    var i;
    null != t && 0 != e.length && (this.actionEvents.has(e) || this.actionEvents.has(null == t ? void 0 : t.event)) && this.actionRegistry && (null === (i = this.actionRegistry) || void 0 === i ? void 0 : i.size) > 0 && this.actionRegistry.forEach(e => {
      this.checkAction(t, e) && this._debugEventEmitter.emit("actionCaptured", e.name);
    });
  }
  _addActionHook(e) {
    this.onAction("actionCaptured", t => e(t));
  }
  checkAction(e, t) {
    if (null == (null == t ? void 0 : t.steps)) return !1;
    for (var i of t.steps) if (this.checkStep(e, i)) return !0;
    return !1;
  }
  onAction(e, t) {
    return this._debugEventEmitter.on(e, t);
  }
  checkStepUrl(e, t) {
    if (null != t && t.url) {
      var i,
        s = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$current_url;
      if (!s || "string" != typeof s) return !1;
      if (!xr.matchString(s, null == t ? void 0 : t.url, (null == t ? void 0 : t.url_matching) || "contains")) return !1;
    }
    return !0;
  }
  static matchString(t, i, s) {
    switch (s) {
      case "regex":
        return !!e && _t(t, i);
      case "exact":
        return i === t;
      case "contains":
        var r = xr.escapeStringRegexp(i).replace(/_/g, ".").replace(/%/g, ".*");
        return _t(t, r);
      default:
        return !1;
    }
  }
  static escapeStringRegexp(e) {
    return e.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d");
  }
  checkStepElement(e, t) {
    if ((null != t && t.href || null != t && t.tag_name || null != t && t.text) && !this.getElementsList(e).some(e => !(null != t && t.href && !xr.matchString(e.href || "", null == t ? void 0 : t.href, (null == t ? void 0 : t.href_matching) || "exact")) && (null == t || !t.tag_name || e.tag_name === (null == t ? void 0 : t.tag_name)) && !(null != t && t.text && !xr.matchString(e.text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact") && !xr.matchString(e.$el_text || "", null == t ? void 0 : t.text, (null == t ? void 0 : t.text_matching) || "exact")))) return !1;
    if (null != t && t.selector) {
      var i,
        s = null == e || null === (i = e.properties) || void 0 === i ? void 0 : i.$element_selectors;
      if (!s) return !1;
      if (!s.includes(null == t ? void 0 : t.selector)) return !1;
    }
    return !0;
  }
  getElementsList(e) {
    return null == (null == e ? void 0 : e.properties.$elements) ? [] : null == e ? void 0 : e.properties.$elements;
  }
}
class Ir {
  constructor(e) {
    this.instance = e, this.eventToSurveys = new Map(), this.actionToSurveys = new Map();
  }
  register(e) {
    var t;
    H(null === (t = this.instance) || void 0 === t ? void 0 : t._addCaptureHook) || (this.setupEventBasedSurveys(e), this.setupActionBasedSurveys(e));
  }
  setupActionBasedSurveys(e) {
    var t = e.filter(e => {
      var t, i, s, r;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.actions) && (null === (i = e.conditions) || void 0 === i || null === (s = i.actions) || void 0 === s || null === (r = s.values) || void 0 === r ? void 0 : r.length) > 0;
    });
    if (0 !== t.length) {
      if (null == this.actionMatcher) {
        this.actionMatcher = new xr(this.instance), this.actionMatcher.init();
        this.actionMatcher._addActionHook(e => {
          this.onAction(e);
        });
      }
      t.forEach(e => {
        var t, i, s, r, n, o, a, l, u, c;
        e.conditions && null !== (t = e.conditions) && void 0 !== t && t.actions && null !== (i = e.conditions) && void 0 !== i && null !== (s = i.actions) && void 0 !== s && s.values && (null === (r = e.conditions) || void 0 === r || null === (n = r.actions) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 && (null === (a = this.actionMatcher) || void 0 === a || a.register(e.conditions.actions.values), null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u || null === (c = u.values) || void 0 === c || c.forEach(t => {
          if (t && t.name) {
            var i = this.actionToSurveys.get(t.name);
            i && i.push(e.id), this.actionToSurveys.set(t.name, i || [e.id]);
          }
        }));
      });
    }
  }
  setupEventBasedSurveys(e) {
    var t;
    if (0 !== e.filter(e => {
      var t, i, s, r;
      return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (s = i.events) || void 0 === s || null === (r = s.values) || void 0 === r ? void 0 : r.length) > 0;
    }).length) {
      null === (t = this.instance) || void 0 === t || t._addCaptureHook((e, t) => {
        this.onEvent(e, t);
      }), e.forEach(e => {
        var t, i, s;
        null === (t = e.conditions) || void 0 === t || null === (i = t.events) || void 0 === i || null === (s = i.values) || void 0 === s || s.forEach(t => {
          if (t && t.name) {
            var i = this.eventToSurveys.get(t.name);
            i && i.push(e.id), this.eventToSurveys.set(t.name, i || [e.id]);
          }
        });
      });
    }
  }
  onEvent(e, t) {
    var i,
      s,
      r = (null === (i = this.instance) || void 0 === i || null === (s = i.persistence) || void 0 === s ? void 0 : s.props[Fe]) || [];
    if (Ir.SURVEY_SHOWN_EVENT_NAME == e && t && r.length > 0) {
      var n,
        o = null == t || null === (n = t.properties) || void 0 === n ? void 0 : n.$survey_id;
      if (o) {
        var a = r.indexOf(o);
        a >= 0 && (r.splice(a, 1), this._updateActivatedSurveys(r));
      }
    } else this.eventToSurveys.has(e) && this._updateActivatedSurveys(r.concat(this.eventToSurveys.get(e) || []));
  }
  onAction(e) {
    var t,
      i,
      s = (null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i ? void 0 : i.props[Fe]) || [];
    this.actionToSurveys.has(e) && this._updateActivatedSurveys(s.concat(this.actionToSurveys.get(e) || []));
  }
  _updateActivatedSurveys(e) {
    var t, i;
    null === (t = this.instance) || void 0 === t || null === (i = t.persistence) || void 0 === i || i.register({
      [Fe]: [...new Set(e)]
    });
  }
  getSurveys() {
    var e,
      t,
      i = null === (e = this.instance) || void 0 === e || null === (t = e.persistence) || void 0 === t ? void 0 : t.props[Fe];
    return i || [];
  }
  getEventToSurveys() {
    return this.eventToSurveys;
  }
  _getActionMatcher() {
    return this.actionMatcher;
  }
}
te(Ir, "SURVEY_SHOWN_EVENT_NAME", "survey shown");
var Pr,
  Fr,
  Rr,
  Cr,
  Tr,
  $r,
  Mr,
  Ar,
  Lr = {},
  Or = [],
  Dr = /acit|ex(?:s|g|n|p|$)|rph|grid|ows|mnc|ntw|ine[ch]|zoo|^ord|itera/i,
  Nr = Array.isArray;
function qr(e, t) {
  for (var i in t) e[i] = t[i];
  return e;
}
function Br(e) {
  var t = e.parentNode;
  t && t.removeChild(e);
}
function Hr(e, t, i, s, r) {
  var n = {
    type: e,
    props: t,
    key: i,
    ref: s,
    __k: null,
    __: null,
    __b: 0,
    __e: null,
    __d: void 0,
    __c: null,
    constructor: void 0,
    __v: null == r ? ++Rr : r,
    __i: -1,
    __u: 0
  };
  return null == r && null != Fr.vnode && Fr.vnode(n), n;
}
function Ur(e) {
  return e.children;
}
function zr(e, t) {
  this.props = e, this.context = t;
}
function Wr(e, t) {
  if (null == t) return e.__ ? Wr(e.__, e.__i + 1) : null;
  for (var i; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) return i.__e;
  return "function" == typeof e.type ? Wr(e) : null;
}
function jr(e) {
  var t, i;
  if (null != (e = e.__) && null != e.__c) {
    for (e.__e = e.__c.base = null, t = 0; t < e.__k.length; t++) if (null != (i = e.__k[t]) && null != i.__e) {
      e.__e = e.__c.base = i.__e;
      break;
    }
    return jr(e);
  }
}
function Vr(e) {
  (!e.__d && (e.__d = !0) && Cr.push(e) && !Gr.__r++ || Tr !== Fr.debounceRendering) && ((Tr = Fr.debounceRendering) || $r)(Gr);
}
function Gr() {
  var e, t, i, s, r, n, o, a, l;
  for (Cr.sort(Mr); e = Cr.shift();) e.__d && (t = Cr.length, s = void 0, n = (r = (i = e).__v).__e, a = [], l = [], (o = i.__P) && ((s = qr({}, r)).__v = r.__v + 1, Fr.vnode && Fr.vnode(s), sn(o, s, r, i.__n, void 0 !== o.ownerSVGElement, 32 & r.__u ? [n] : null, a, null == n ? Wr(r) : n, !!(32 & r.__u), l), s.__.__k[s.__i] = s, rn(a, s, l), s.__e != n && jr(s)), Cr.length > t && Cr.sort(Mr));
  Gr.__r = 0;
}
function Qr(e, t, i, s, r, n, o, a, l, u, c) {
  var d,
    h,
    _,
    p,
    v,
    g = s && s.__k || Or,
    f = t.length;
  for (i.__d = l, Yr(i, t, g), l = i.__d, d = 0; d < f; d++) null != (_ = i.__k[d]) && "boolean" != typeof _ && "function" != typeof _ && (h = -1 === _.__i ? Lr : g[_.__i] || Lr, _.__i = d, sn(e, _, h, r, n, o, a, l, u, c), p = _.__e, _.ref && h.ref != _.ref && (h.ref && on(h.ref, null, _), c.push(_.ref, _.__c || p, _)), null == v && null != p && (v = p), 65536 & _.__u || h.__k === _.__k ? l = Jr(_, l, e) : "function" == typeof _.type && void 0 !== _.__d ? l = _.__d : p && (l = p.nextSibling), _.__d = void 0, _.__u &= -196609);
  i.__d = l, i.__e = v;
}
function Yr(e, t, i) {
  var s,
    r,
    n,
    o,
    a,
    l = t.length,
    u = i.length,
    c = u,
    d = 0;
  for (e.__k = [], s = 0; s < l; s++) null != (r = e.__k[s] = null == (r = t[s]) || "boolean" == typeof r || "function" == typeof r ? null : "string" == typeof r || "number" == typeof r || "bigint" == typeof r || r.constructor == String ? Hr(null, r, null, null, r) : Nr(r) ? Hr(Ur, {
    children: r
  }, null, null, null) : void 0 === r.constructor && r.__b > 0 ? Hr(r.type, r.props, r.key, r.ref ? r.ref : null, r.__v) : r) ? (r.__ = e, r.__b = e.__b + 1, a = Xr(r, i, o = s + d, c), r.__i = a, n = null, -1 !== a && (c--, (n = i[a]) && (n.__u |= 131072)), null == n || null === n.__v ? (-1 == a && d--, "function" != typeof r.type && (r.__u |= 65536)) : a !== o && (a === o + 1 ? d++ : a > o ? c > l - o ? d += a - o : d-- : d = a < o && a == o - 1 ? a - o : 0, a !== s + d && (r.__u |= 65536))) : (n = i[s]) && null == n.key && n.__e && (n.__e == e.__d && (e.__d = Wr(n)), an(n, n, !1), i[s] = null, c--);
  if (c) for (s = 0; s < u; s++) null != (n = i[s]) && 0 == (131072 & n.__u) && (n.__e == e.__d && (e.__d = Wr(n)), an(n, n));
}
function Jr(e, t, i) {
  var s, r;
  if ("function" == typeof e.type) {
    for (s = e.__k, r = 0; s && r < s.length; r++) s[r] && (s[r].__ = e, t = Jr(s[r], t, i));
    return t;
  }
  return e.__e != t && (i.insertBefore(e.__e, t || null), t = e.__e), t && t.nextSibling;
}
function Xr(e, t, i, s) {
  var r = e.key,
    n = e.type,
    o = i - 1,
    a = i + 1,
    l = t[i];
  if (null === l || l && r == l.key && n === l.type) return i;
  if (s > (null != l && 0 == (131072 & l.__u) ? 1 : 0)) for (; o >= 0 || a < t.length;) {
    if (o >= 0) {
      if ((l = t[o]) && 0 == (131072 & l.__u) && r == l.key && n === l.type) return o;
      o--;
    }
    if (a < t.length) {
      if ((l = t[a]) && 0 == (131072 & l.__u) && r == l.key && n === l.type) return a;
      a++;
    }
  }
  return -1;
}
function Kr(e, t, i) {
  "-" === t[0] ? e.setProperty(t, null == i ? "" : i) : e[t] = null == i ? "" : "number" != typeof i || Dr.test(t) ? i : i + "px";
}
function Zr(e, t, i, s, r) {
  var n;
  e: if ("style" === t) {
    if ("string" == typeof i) e.style.cssText = i;else {
      if ("string" == typeof s && (e.style.cssText = s = ""), s) for (t in s) i && t in i || Kr(e.style, t, "");
      if (i) for (t in i) s && i[t] === s[t] || Kr(e.style, t, i[t]);
    }
  } else if ("o" === t[0] && "n" === t[1]) n = t !== (t = t.replace(/(PointerCapture)$|Capture$/, "$1")), t = t.toLowerCase() in e ? t.toLowerCase().slice(2) : t.slice(2), e.l || (e.l = {}), e.l[t + n] = i, i ? s ? i.u = s.u : (i.u = Date.now(), e.addEventListener(t, n ? tn : en, n)) : e.removeEventListener(t, n ? tn : en, n);else {
    if (r) t = t.replace(/xlink(H|:h)/, "h").replace(/sName$/, "s");else if ("width" !== t && "height" !== t && "href" !== t && "list" !== t && "form" !== t && "tabIndex" !== t && "download" !== t && "rowSpan" !== t && "colSpan" !== t && "role" !== t && t in e) try {
      e[t] = null == i ? "" : i;
      break e;
    } catch (e) {}
    "function" == typeof i || (null == i || !1 === i && "-" !== t[4] ? e.removeAttribute(t) : e.setAttribute(t, i));
  }
}
function en(e) {
  var t = this.l[e.type + !1];
  if (e.t) {
    if (e.t <= t.u) return;
  } else e.t = Date.now();
  return t(Fr.event ? Fr.event(e) : e);
}
function tn(e) {
  return this.l[e.type + !0](Fr.event ? Fr.event(e) : e);
}
function sn(e, t, i, s, r, n, o, a, l, u) {
  var c,
    d,
    h,
    _,
    p,
    v,
    g,
    f,
    m,
    b,
    y,
    w,
    S,
    E,
    k,
    x = t.type;
  if (void 0 !== t.constructor) return null;
  128 & i.__u && (l = !!(32 & i.__u), n = [a = t.__e = i.__e]), (c = Fr.__b) && c(t);
  e: if ("function" == typeof x) try {
    if (f = t.props, m = (c = x.contextType) && s[c.__c], b = c ? m ? m.props.value : c.__ : s, i.__c ? g = (d = t.__c = i.__c).__ = d.__E : ("prototype" in x && x.prototype.render ? t.__c = d = new x(f, b) : (t.__c = d = new zr(f, b), d.constructor = x, d.render = ln), m && m.sub(d), d.props = f, d.state || (d.state = {}), d.context = b, d.__n = s, h = d.__d = !0, d.__h = [], d._sb = []), null == d.__s && (d.__s = d.state), null != x.getDerivedStateFromProps && (d.__s == d.state && (d.__s = qr({}, d.__s)), qr(d.__s, x.getDerivedStateFromProps(f, d.__s))), _ = d.props, p = d.state, d.__v = t, h) null == x.getDerivedStateFromProps && null != d.componentWillMount && d.componentWillMount(), null != d.componentDidMount && d.__h.push(d.componentDidMount);else {
      if (null == x.getDerivedStateFromProps && f !== _ && null != d.componentWillReceiveProps && d.componentWillReceiveProps(f, b), !d.__e && (null != d.shouldComponentUpdate && !1 === d.shouldComponentUpdate(f, d.__s, b) || t.__v === i.__v)) {
        for (t.__v !== i.__v && (d.props = f, d.state = d.__s, d.__d = !1), t.__e = i.__e, t.__k = i.__k, t.__k.forEach(function (e) {
          e && (e.__ = t);
        }), y = 0; y < d._sb.length; y++) d.__h.push(d._sb[y]);
        d._sb = [], d.__h.length && o.push(d);
        break e;
      }
      null != d.componentWillUpdate && d.componentWillUpdate(f, d.__s, b), null != d.componentDidUpdate && d.__h.push(function () {
        d.componentDidUpdate(_, p, v);
      });
    }
    if (d.context = b, d.props = f, d.__P = e, d.__e = !1, w = Fr.__r, S = 0, "prototype" in x && x.prototype.render) {
      for (d.state = d.__s, d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), E = 0; E < d._sb.length; E++) d.__h.push(d._sb[E]);
      d._sb = [];
    } else do {
      d.__d = !1, w && w(t), c = d.render(d.props, d.state, d.context), d.state = d.__s;
    } while (d.__d && ++S < 25);
    d.state = d.__s, null != d.getChildContext && (s = qr(qr({}, s), d.getChildContext())), h || null == d.getSnapshotBeforeUpdate || (v = d.getSnapshotBeforeUpdate(_, p)), Qr(e, Nr(k = null != c && c.type === Ur && null == c.key ? c.props.children : c) ? k : [k], t, i, s, r, n, o, a, l, u), d.base = t.__e, t.__u &= -161, d.__h.length && o.push(d), g && (d.__E = d.__ = null);
  } catch (e) {
    t.__v = null, l || null != n ? (t.__e = a, t.__u |= l ? 160 : 32, n[n.indexOf(a)] = null) : (t.__e = i.__e, t.__k = i.__k), Fr.__e(e, t, i);
  } else null == n && t.__v === i.__v ? (t.__k = i.__k, t.__e = i.__e) : t.__e = nn(i.__e, t, i, s, r, n, o, l, u);
  (c = Fr.diffed) && c(t);
}
function rn(e, t, i) {
  t.__d = void 0;
  for (var s = 0; s < i.length; s++) on(i[s], i[++s], i[++s]);
  Fr.__c && Fr.__c(t, e), e.some(function (t) {
    try {
      e = t.__h, t.__h = [], e.some(function (e) {
        e.call(t);
      });
    } catch (e) {
      Fr.__e(e, t.__v);
    }
  });
}
function nn(e, t, i, s, r, n, o, a, l) {
  var u,
    c,
    d,
    h,
    _,
    p,
    v,
    g = i.props,
    f = t.props,
    m = t.type;
  if ("svg" === m && (r = !0), null != n) for (u = 0; u < n.length; u++) if ((_ = n[u]) && "setAttribute" in _ == !!m && (m ? _.localName === m : 3 === _.nodeType)) {
    e = _, n[u] = null;
    break;
  }
  if (null == e) {
    if (null === m) return document.createTextNode(f);
    e = r ? document.createElementNS("http://www.w3.org/2000/svg", m) : document.createElement(m, f.is && f), n = null, a = !1;
  }
  if (null === m) g === f || a && e.data === f || (e.data = f);else {
    if (n = n && Pr.call(e.childNodes), g = i.props || Lr, !a && null != n) for (g = {}, u = 0; u < e.attributes.length; u++) g[(_ = e.attributes[u]).name] = _.value;
    for (u in g) _ = g[u], "children" == u || ("dangerouslySetInnerHTML" == u ? d = _ : "key" === u || u in f || Zr(e, u, null, _, r));
    for (u in f) _ = f[u], "children" == u ? h = _ : "dangerouslySetInnerHTML" == u ? c = _ : "value" == u ? p = _ : "checked" == u ? v = _ : "key" === u || a && "function" != typeof _ || g[u] === _ || Zr(e, u, _, g[u], r);
    if (c) a || d && (c.__html === d.__html || c.__html === e.innerHTML) || (e.innerHTML = c.__html), t.__k = [];else if (d && (e.innerHTML = ""), Qr(e, Nr(h) ? h : [h], t, i, s, r && "foreignObject" !== m, n, o, n ? n[0] : i.__k && Wr(i, 0), a, l), null != n) for (u = n.length; u--;) null != n[u] && Br(n[u]);
    a || (u = "value", void 0 !== p && (p !== e[u] || "progress" === m && !p || "option" === m && p !== g[u]) && Zr(e, u, p, g[u], !1), u = "checked", void 0 !== v && v !== e[u] && Zr(e, u, v, g[u], !1));
  }
  return e;
}
function on(e, t, i) {
  try {
    "function" == typeof e ? e(t) : e.current = t;
  } catch (e) {
    Fr.__e(e, i);
  }
}
function an(e, t, i) {
  var s, r;
  if (Fr.unmount && Fr.unmount(e), (s = e.ref) && (s.current && s.current !== e.__e || on(s, null, t)), null != (s = e.__c)) {
    if (s.componentWillUnmount) try {
      s.componentWillUnmount();
    } catch (e) {
      Fr.__e(e, t);
    }
    s.base = s.__P = null, e.__c = void 0;
  }
  if (s = e.__k) for (r = 0; r < s.length; r++) s[r] && an(s[r], t, i || "function" != typeof e.type);
  i || null == e.__e || Br(e.__e), e.__ = e.__e = e.__d = void 0;
}
function ln(e, t, i) {
  return this.constructor(e, i);
}
Pr = Or.slice, Fr = {
  __e: function (e, t, i, s) {
    for (var r, n, o; t = t.__;) if ((r = t.__c) && !r.__) try {
      if ((n = r.constructor) && null != n.getDerivedStateFromError && (r.setState(n.getDerivedStateFromError(e)), o = r.__d), null != r.componentDidCatch && (r.componentDidCatch(e, s || {}), o = r.__d), o) return r.__E = r;
    } catch (t) {
      e = t;
    }
    throw e;
  }
}, Rr = 0, zr.prototype.setState = function (e, t) {
  var i;
  i = null != this.__s && this.__s !== this.state ? this.__s : this.__s = qr({}, this.state), "function" == typeof e && (e = e(qr({}, i), this.props)), e && qr(i, e), null != e && this.__v && (t && this._sb.push(t), Vr(this));
}, zr.prototype.forceUpdate = function (e) {
  this.__v && (this.__e = !0, e && this.__h.push(e), Vr(this));
}, zr.prototype.render = Ur, Cr = [], $r = "function" == typeof Promise ? Promise.prototype.then.bind(Promise.resolve()) : setTimeout, Mr = function (e, t) {
  return e.__v.__b - t.__v.__b;
}, Gr.__r = 0, Ar = 0;
!function (e, t) {
  var i = {
    __c: t = "__cC" + Ar++,
    __: e,
    Consumer: function (e, t) {
      return e.children(t);
    },
    Provider: function (e) {
      var i, s;
      return this.getChildContext || (i = [], (s = {})[t] = this, this.getChildContext = function () {
        return s;
      }, this.shouldComponentUpdate = function (e) {
        this.props.value !== e.value && i.some(function (e) {
          e.__e = !0, Vr(e);
        });
      }, this.sub = function (e) {
        i.push(e);
        var t = e.componentWillUnmount;
        e.componentWillUnmount = function () {
          i.splice(i.indexOf(e), 1), t && t.call(e);
        };
      }), e.children;
    }
  };
  i.Provider.__ = i.Consumer.contextType = i;
}({
  isPreviewMode: !1,
  previewPageIndex: 0,
  handleCloseSurveyPopup: () => {},
  isPopup: !0
});
var un = "[Surveys]",
  cn = {
    icontains: t => !!e && e.location.href.toLowerCase().indexOf(t.toLowerCase()) > -1,
    not_icontains: t => !!e && -1 === e.location.href.toLowerCase().indexOf(t.toLowerCase()),
    regex: t => !!e && _t(e.location.href, t),
    not_regex: t => !!e && !_t(e.location.href, t),
    exact: t => (null == e ? void 0 : e.location.href) === t,
    is_not: t => (null == e ? void 0 : e.location.href) !== t
  };
class dn {
  constructor(e) {
    this.instance = e, this._surveyEventReceiver = null;
  }
  afterDecideResponse(e) {
    this._decideServerResponse = !!e.surveys, this.loadIfEnabled();
  }
  reset() {
    localStorage.removeItem("lastSeenSurveyDate");
    var e = (() => {
      for (var e = [], t = 0; t < localStorage.length; t++) {
        var i = localStorage.key(t);
        null != i && i.startsWith("seenSurvey_") && e.push(i);
      }
      return e;
    })();
    e.forEach(e => localStorage.removeItem(e));
  }
  loadIfEnabled() {
    var e,
      t,
      i,
      s = null == h || null === (e = h.__PosthogExtensions__) || void 0 === e ? void 0 : e.generateSurveys;
    this.instance.config.disable_surveys || !this._decideServerResponse || s || (null == this._surveyEventReceiver && (this._surveyEventReceiver = new Ir(this.instance)), null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "surveys", e => {
      var t, i;
      if (e) return X.error(un, "Could not load surveys script", e);
      this._surveyManager = null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.generateSurveys) || void 0 === i ? void 0 : i.call(t, this.instance);
    }));
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    if (this.instance.config.disable_surveys) return e([]);
    null == this._surveyEventReceiver && (this._surveyEventReceiver = new Ir(this.instance));
    var i = this.instance.get_property(Pe);
    if (i && !t) return e(i);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/surveys/?token=".concat(this.instance.config.token)),
      method: "GET",
      transport: "XHR",
      callback: t => {
        var i;
        if (200 !== t.statusCode || !t.json) return e([]);
        var s,
          r = t.json.surveys || [],
          n = r.filter(e => {
            var t, i, s, r, n, o, a, l, u, c, d, h;
            return (null === (t = e.conditions) || void 0 === t ? void 0 : t.events) && (null === (i = e.conditions) || void 0 === i || null === (s = i.events) || void 0 === s ? void 0 : s.values) && (null === (r = e.conditions) || void 0 === r || null === (n = r.events) || void 0 === n || null === (o = n.values) || void 0 === o ? void 0 : o.length) > 0 || (null === (a = e.conditions) || void 0 === a ? void 0 : a.actions) && (null === (l = e.conditions) || void 0 === l || null === (u = l.actions) || void 0 === u ? void 0 : u.values) && (null === (c = e.conditions) || void 0 === c || null === (d = c.actions) || void 0 === d || null === (h = d.values) || void 0 === h ? void 0 : h.length) > 0;
          });
        n.length > 0 && (null === (s = this._surveyEventReceiver) || void 0 === s || s.register(n));
        return null === (i = this.instance.persistence) || void 0 === i || i.register({
          [Pe]: r
        }), e(r);
      }
    });
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.getSurveys(t => {
      var i,
        s = t.filter(e => !(!e.start_date || e.end_date)).filter(e => {
          var t, i, s, r;
          if (!e.conditions) return !0;
          var n = null === (t = e.conditions) || void 0 === t || !t.url || cn[null !== (i = null === (s = e.conditions) || void 0 === s ? void 0 : s.urlMatchType) && void 0 !== i ? i : "icontains"](e.conditions.url),
            a = null === (r = e.conditions) || void 0 === r || !r.selector || (null == o ? void 0 : o.querySelector(e.conditions.selector));
          return n && a;
        }),
        r = null === (i = this._surveyEventReceiver) || void 0 === i ? void 0 : i.getSurveys(),
        n = s.filter(e => {
          var t, i, s, n, o, a, l, u, c, d, h;
          if (!(e.linked_flag_key || e.targeting_flag_key || e.internal_targeting_flag_key || null !== (t = e.feature_flag_keys) && void 0 !== t && t.length)) return !0;
          var _ = !e.linked_flag_key || this.instance.featureFlags.isFeatureEnabled(e.linked_flag_key),
            p = !e.targeting_flag_key || this.instance.featureFlags.isFeatureEnabled(e.targeting_flag_key),
            v = (null === (i = e.conditions) || void 0 === i ? void 0 : i.events) && (null === (s = e.conditions) || void 0 === s || null === (n = s.events) || void 0 === n ? void 0 : n.values) && (null === (o = e.conditions) || void 0 === o || null === (a = o.events) || void 0 === a ? void 0 : a.values.length) > 0,
            g = (null === (l = e.conditions) || void 0 === l ? void 0 : l.actions) && (null === (u = e.conditions) || void 0 === u || null === (c = u.actions) || void 0 === c ? void 0 : c.values) && (null === (d = e.conditions) || void 0 === d || null === (h = d.actions) || void 0 === h ? void 0 : h.values.length) > 0,
            f = !v && !g || (null == r ? void 0 : r.includes(e.id)),
            m = this._canActivateRepeatedly(e),
            b = !(e.internal_targeting_flag_key && !m) || this.instance.featureFlags.isFeatureEnabled(e.internal_targeting_flag_key),
            y = this.checkFlags(e);
          return _ && p && b && f && y;
        });
      return e(n);
    }, t);
  }
  checkFlags(e) {
    var t;
    return null === (t = e.feature_flag_keys) || void 0 === t || !t.length || e.feature_flag_keys.every(e => {
      var {
        key: t,
        value: i
      } = e;
      return !t || !i || this.instance.featureFlags.isFeatureEnabled(i);
    });
  }
  getNextSurveyStep(e, t, i) {
    var s,
      r = e.questions[t],
      n = t + 1;
    if (null === (s = r.branching) || void 0 === s || !s.type) return t === e.questions.length - 1 ? wr.End : n;
    if (r.branching.type === wr.End) return wr.End;
    if (r.branching.type === wr.SpecificQuestion) {
      if (Number.isInteger(r.branching.index)) return r.branching.index;
    } else if (r.branching.type === wr.ResponseBased) {
      if (r.type === yr.SingleChoice) {
        var o,
          a,
          l = r.choices.indexOf("".concat(i));
        if (null !== (o = r.branching) && void 0 !== o && null !== (a = o.responseValues) && void 0 !== a && a.hasOwnProperty(l)) {
          var u = r.branching.responseValues[l];
          return Number.isInteger(u) ? u : u === wr.End ? wr.End : n;
        }
      } else if (r.type === yr.Rating) {
        var c, d;
        if ("number" != typeof i || !Number.isInteger(i)) throw new Error("The response type must be an integer");
        var h = function (e, t) {
          if (3 === t) {
            if (e < 1 || e > 3) throw new Error("The response must be in range 1-3");
            return 1 === e ? "negative" : 2 === e ? "neutral" : "positive";
          }
          if (5 === t) {
            if (e < 1 || e > 5) throw new Error("The response must be in range 1-5");
            return e <= 2 ? "negative" : 3 === e ? "neutral" : "positive";
          }
          if (7 === t) {
            if (e < 1 || e > 7) throw new Error("The response must be in range 1-7");
            return e <= 3 ? "negative" : 4 === e ? "neutral" : "positive";
          }
          if (10 === t) {
            if (e < 0 || e > 10) throw new Error("The response must be in range 0-10");
            return e <= 6 ? "detractors" : e <= 8 ? "passives" : "promoters";
          }
          throw new Error("The scale must be one of: 3, 5, 7, 10");
        }(i, r.scale);
        if (null !== (c = r.branching) && void 0 !== c && null !== (d = c.responseValues) && void 0 !== d && d.hasOwnProperty(h)) {
          var _ = r.branching.responseValues[h];
          return Number.isInteger(_) ? _ : _ === wr.End ? wr.End : n;
        }
      }
      return n;
    }
    return X.warn(un, "Falling back to next question index due to unexpected branching type"), n;
  }
  _canActivateRepeatedly(e) {
    var t;
    return j(null === (t = h.__PosthogExtensions__) || void 0 === t ? void 0 : t.canActivateRepeatedly) ? (X.warn(un, "canActivateRepeatedly is not defined, must init before calling"), !1) : h.__PosthogExtensions__.canActivateRepeatedly(e);
  }
  canRenderSurvey(e) {
    j(this._surveyManager) ? X.warn(un, "canActivateRepeatedly is not defined, must init before calling") : this.getSurveys(t => {
      var i = t.filter(t => t.id === e)[0];
      this._surveyManager.canRenderSurvey(i);
    });
  }
  renderSurvey(e, t) {
    j(this._surveyManager) ? X.warn(un, "canActivateRepeatedly is not defined, must init before calling") : this.getSurveys(i => {
      var s = i.filter(t => t.id === e)[0];
      this._surveyManager.renderSurvey(s, null == o ? void 0 : o.querySelector(t));
    });
  }
}
class hn {
  constructor(e) {
    var t, i;
    te(this, "serverLimits", {}), te(this, "lastEventRateLimited", !1), te(this, "checkForLimiting", e => {
      var t = e.text;
      if (t && t.length) try {
        (JSON.parse(t).quota_limited || []).forEach(e => {
          X.info("[RateLimiter] ".concat(e || "events", " is quota limited.")), this.serverLimits[e] = new Date().getTime() + 6e4;
        });
      } catch (e) {
        return void X.warn('[RateLimiter] could not rate limit - continuing. Error: "'.concat(null == e ? void 0 : e.message, '"'), {
          text: t
        });
      }
    }), this.instance = e, this.captureEventsPerSecond = (null === (t = e.config.rate_limiting) || void 0 === t ? void 0 : t.events_per_second) || 10, this.captureEventsBurstLimit = Math.max((null === (i = e.config.rate_limiting) || void 0 === i ? void 0 : i.events_burst_limit) || 10 * this.captureEventsPerSecond, this.captureEventsPerSecond), this.lastEventRateLimited = this.clientRateLimitContext(!0).isRateLimited;
  }
  clientRateLimitContext() {
    var e,
      t,
      i,
      s = arguments.length > 0 && void 0 !== arguments[0] && arguments[0],
      r = new Date().getTime(),
      n = null !== (e = null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.get_property($e)) && void 0 !== e ? e : {
        tokens: this.captureEventsBurstLimit,
        last: r
      };
    n.tokens += (r - n.last) / 1e3 * this.captureEventsPerSecond, n.last = r, n.tokens > this.captureEventsBurstLimit && (n.tokens = this.captureEventsBurstLimit);
    var o = n.tokens < 1;
    return o || s || (n.tokens = Math.max(0, n.tokens - 1)), !o || this.lastEventRateLimited || s || this.instance.capture("$$client_ingestion_warning", {
      $$client_ingestion_warning_message: "posthog-js client rate limited. Config is set to ".concat(this.captureEventsPerSecond, " events per second and ").concat(this.captureEventsBurstLimit, " events burst limit.")
    }, {
      skip_client_rate_limiting: !0
    }), this.lastEventRateLimited = o, null === (i = this.instance.persistence) || void 0 === i || i.set_property($e, n), {
      isRateLimited: o,
      remainingTokens: n.tokens
    };
  }
  isServerRateLimited(e) {
    var t = this.serverLimits[e || "events"] || !1;
    return !1 !== t && new Date().getTime() < t;
  }
}
var _n = () => ee({
  initialPathName: (null == a ? void 0 : a.pathname) || "",
  referringDomain: pi.referringDomain()
}, pi.campaignParams());
class pn {
  constructor(e, t, i) {
    te(this, "_onSessionIdCallback", e => {
      var t = this._getStoredProps();
      if (!t || t.sessionId !== e) {
        var i = {
          sessionId: e,
          props: this._sessionSourceParamGenerator()
        };
        this._persistence.register({
          [Te]: i
        });
      }
    }), this._sessionIdManager = e, this._persistence = t, this._sessionSourceParamGenerator = i || _n, this._sessionIdManager.onSessionId(this._onSessionIdCallback);
  }
  _getStoredProps() {
    return this._persistence.props[Te];
  }
  getSessionProps() {
    var e,
      t = null === (e = this._getStoredProps()) || void 0 === e ? void 0 : e.props;
    return t ? {
      $client_session_initial_referring_host: t.referringDomain,
      $client_session_initial_pathname: t.initialPathName,
      $client_session_initial_utm_source: t.utm_source,
      $client_session_initial_utm_campaign: t.utm_campaign,
      $client_session_initial_utm_medium: t.utm_medium,
      $client_session_initial_utm_content: t.utm_content,
      $client_session_initial_utm_term: t.utm_term
    } : {};
  }
}
var vn = ["ahrefsbot", "ahrefssiteaudit", "applebot", "baiduspider", "bingbot", "bingpreview", "bot.htm", "bot.php", "crawler", "deepscan", "duckduckbot", "facebookexternal", "facebookcatalog", "gptbot", "http://yandex.com/bots", "hubspot", "ia_archiver", "linkedinbot", "mj12bot", "msnbot", "nessus", "petalbot", "pinterest", "prerender", "rogerbot", "screaming frog", "semrushbot", "sitebulb", "slurp", "turnitin", "twitterbot", "vercelbot", "yahoo! slurp", "yandexbot", "headlesschrome", "cypress", "Google-HotelAdsVerifier", "adsbot-google", "apis-google", "duplexweb-google", "feedfetcher-google", "google favicon", "google web preview", "google-read-aloud", "googlebot", "googleweblight", "mediapartners-google", "storebot-google", "Bytespider;"],
  gn = function (e, t) {
    if (!e) return !1;
    var i = e.toLowerCase();
    return vn.concat(t || []).some(e => {
      var t = e.toLowerCase();
      return -1 !== i.indexOf(t);
    });
  },
  fn = function (e, t) {
    if (!e) return !1;
    var i = e.userAgent;
    if (i && gn(i, t)) return !0;
    try {
      var s = null == e ? void 0 : e.userAgentData;
      if (null != s && s.brands && s.brands.some(e => gn(null == e ? void 0 : e.brand, t))) return !0;
    } catch (e) {}
    return !!e.webdriver;
  };
class mn {
  constructor() {
    this.clicks = [];
  }
  isRageClick(e, t, i) {
    var s = this.clicks[this.clicks.length - 1];
    if (s && Math.abs(e - s.x) + Math.abs(t - s.y) < 30 && i - s.timestamp < 1e3) {
      if (this.clicks.push({
        x: e,
        y: t,
        timestamp: i
      }), 3 === this.clicks.length) return !0;
    } else this.clicks = [{
      x: e,
      y: t,
      timestamp: i
    }];
    return !1;
  }
}
var bn = "[Dead Clicks]",
  yn = () => !0,
  wn = e => {
    var t,
      i = !(null === (t = e.instance.persistence) || void 0 === t || !t.get_property(ce)),
      s = e.instance.config.capture_dead_clicks;
    return G(s) ? s : i;
  };
class Sn {
  get lazyLoadedDeadClicksAutocapture() {
    return this._lazyLoadedDeadClicksAutocapture;
  }
  constructor(e, t, i) {
    this.instance = e, this.isEnabled = t, this.onCapture = i, this.startIfEnabled();
  }
  afterDecideResponse(e) {
    this.instance.persistence && this.instance.persistence.register({
      [ce]: null == e ? void 0 : e.captureDeadClicks
    }), this.startIfEnabled();
  }
  startIfEnabled() {
    this.isEnabled(this) && this.loadScript(() => {
      this.start();
    });
  }
  loadScript(e) {
    var t, i, s;
    null !== (t = h.__PosthogExtensions__) && void 0 !== t && t.initDeadClicksAutocapture && e(), null === (i = h.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "dead-clicks-autocapture", t => {
      t ? X.error(bn + " failed to load script", t) : e();
    });
  }
  start() {
    var e;
    if (o) {
      if (!this._lazyLoadedDeadClicksAutocapture && null !== (e = h.__PosthogExtensions__) && void 0 !== e && e.initDeadClicksAutocapture) {
        var t = q(this.instance.config.capture_dead_clicks) ? this.instance.config.capture_dead_clicks : {};
        t.__onCapture = this.onCapture, this._lazyLoadedDeadClicksAutocapture = h.__PosthogExtensions__.initDeadClicksAutocapture(this.instance, t), this._lazyLoadedDeadClicksAutocapture.start(o), X.info("".concat(bn, " starting..."));
      }
    } else X.error(bn + " `document` not found. Cannot start.");
  }
  stop() {
    this._lazyLoadedDeadClicksAutocapture && (this._lazyLoadedDeadClicksAutocapture.stop(), this._lazyLoadedDeadClicksAutocapture = void 0, X.info("".concat(bn, " stopping...")));
  }
}
class En {
  constructor(t) {
    var i;
    te(this, "rageclicks", new mn()), te(this, "_enabledServerSide", !1), te(this, "_initialized", !1), te(this, "_flushInterval", null), this.instance = t, this._enabledServerSide = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[ae]), null == e || e.addEventListener("beforeunload", () => {
      this.flush();
    });
  }
  get flushIntervalMilliseconds() {
    var e = 5e3;
    return q(this.instance.config.capture_heatmaps) && this.instance.config.capture_heatmaps.flush_interval_milliseconds && (e = this.instance.config.capture_heatmaps.flush_interval_milliseconds), e;
  }
  get isEnabled() {
    return H(this.instance.config.capture_heatmaps) ? H(this.instance.config.enable_heatmaps) ? this._enabledServerSide : this.instance.config.enable_heatmaps : !1 !== this.instance.config.capture_heatmaps;
  }
  startIfEnabled() {
    if (this.isEnabled) {
      if (this._initialized) return;
      X.info("[heatmaps] starting..."), this._setupListeners(), this._flushInterval = setInterval(this.flush.bind(this), this.flushIntervalMilliseconds);
    } else {
      var e, t;
      clearInterval(null !== (e = this._flushInterval) && void 0 !== e ? e : void 0), null === (t = this.deadClicksCapture) || void 0 === t || t.stop(), this.getAndClearBuffer();
    }
  }
  afterDecideResponse(e) {
    var t = !!e.heatmaps;
    this.instance.persistence && this.instance.persistence.register({
      [ae]: t
    }), this._enabledServerSide = t, this.startIfEnabled();
  }
  getAndClearBuffer() {
    var e = this.buffer;
    return this.buffer = void 0, e;
  }
  _onDeadClick(e) {
    this._onClick(e.originalEvent, "deadclick");
  }
  _setupListeners() {
    e && o && (P(o, "click", t => this._onClick(t || (null == e ? void 0 : e.event)), !1, !0), P(o, "mousemove", t => this._onMouseMove(t || (null == e ? void 0 : e.event)), !1, !0), this.deadClicksCapture = new Sn(this.instance, yn, this._onDeadClick.bind(this)), this.deadClicksCapture.startIfEnabled(), this._initialized = !0);
  }
  _getProperties(t, i) {
    var s = this.instance.scrollManager.scrollY(),
      r = this.instance.scrollManager.scrollX(),
      n = this.instance.scrollManager.scrollElement(),
      o = function (t, i, s) {
        for (var r = t; r && Si(r) && !Ei(r, "body");) {
          if (r === s) return !1;
          if (b(i, null == e ? void 0 : e.getComputedStyle(r).position)) return !0;
          r = Mi(r);
        }
        return !1;
      }(Ti(t), ["fixed", "sticky"], n);
    return {
      x: t.clientX + (o ? 0 : r),
      y: t.clientY + (o ? 0 : s),
      target_fixed: o,
      type: i
    };
  }
  _onClick(e) {
    var t,
      i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "click";
    if (!wi(e.target)) {
      var s = this._getProperties(e, i);
      null !== (t = this.rageclicks) && void 0 !== t && t.isRageClick(e.clientX, e.clientY, new Date().getTime()) && this._capture(ee(ee({}, s), {}, {
        type: "rageclick"
      })), this._capture(s);
    }
  }
  _onMouseMove(e) {
    wi(e.target) || (clearTimeout(this._mouseMoveTimeout), this._mouseMoveTimeout = setTimeout(() => {
      this._capture(this._getProperties(e, "mousemove"));
    }, 500));
  }
  _capture(t) {
    if (e) {
      var i = e.location.href;
      this.buffer = this.buffer || {}, this.buffer[i] || (this.buffer[i] = []), this.buffer[i].push(t);
    }
  }
  flush() {
    this.buffer && !B(this.buffer) && this.instance.capture("$$heatmap", {
      $heatmap_data: this.getAndClearBuffer()
    });
  }
}
class kn {
  constructor(e) {
    te(this, "_updateScrollData", () => {
      var e, t, i, s;
      this.context || (this.context = {});
      var r = this.scrollElement(),
        n = this.scrollY(),
        o = r ? Math.max(0, r.scrollHeight - r.clientHeight) : 0,
        a = n + ((null == r ? void 0 : r.clientHeight) || 0),
        l = (null == r ? void 0 : r.scrollHeight) || 0;
      this.context.lastScrollY = Math.ceil(n), this.context.maxScrollY = Math.max(n, null !== (e = this.context.maxScrollY) && void 0 !== e ? e : 0), this.context.maxScrollHeight = Math.max(o, null !== (t = this.context.maxScrollHeight) && void 0 !== t ? t : 0), this.context.lastContentY = a, this.context.maxContentY = Math.max(a, null !== (i = this.context.maxContentY) && void 0 !== i ? i : 0), this.context.maxContentHeight = Math.max(l, null !== (s = this.context.maxContentHeight) && void 0 !== s ? s : 0);
    }), this.instance = e;
  }
  getContext() {
    return this.context;
  }
  resetContext() {
    var e = this.context;
    return setTimeout(this._updateScrollData, 0), e;
  }
  startMeasuringScrollPosition() {
    null == e || e.addEventListener("scroll", this._updateScrollData, !0), null == e || e.addEventListener("scrollend", this._updateScrollData, !0), null == e || e.addEventListener("resize", this._updateScrollData);
  }
  scrollElement() {
    if (!this.instance.config.scroll_root_selector) return null == e ? void 0 : e.document.documentElement;
    var t = D(this.instance.config.scroll_root_selector) ? this.instance.config.scroll_root_selector : [this.instance.config.scroll_root_selector];
    for (var i of t) {
      var s = null == e ? void 0 : e.document.querySelector(i);
      if (s) return s;
    }
  }
  scrollY() {
    if (this.instance.config.scroll_root_selector) {
      var t = this.scrollElement();
      return t && t.scrollTop || 0;
    }
    return e && (e.scrollY || e.pageYOffset || e.document.documentElement.scrollTop) || 0;
  }
  scrollX() {
    if (this.instance.config.scroll_root_selector) {
      var t = this.scrollElement();
      return t && t.scrollLeft || 0;
    }
    return e && (e.scrollX || e.pageXOffset || e.document.documentElement.scrollLeft) || 0;
  }
}
function xn(e, t) {
  return t.length > e ? t.slice(0, e) + "..." : t;
}
function In(e) {
  if (e.previousElementSibling) return e.previousElementSibling;
  var t = e;
  do {
    t = t.previousSibling;
  } while (t && !Si(t));
  return t;
}
function Pn(e, t, i, s) {
  var r = e.tagName.toLowerCase(),
    n = {
      tag_name: r
    };
  $i.indexOf(r) > -1 && !i && ("a" === r.toLowerCase() || "button" === r.toLowerCase() ? n.$el_text = xn(1024, Wi(e)) : n.$el_text = xn(1024, Ci(e)));
  var o = Fi(e);
  o.length > 0 && (n.classes = o.filter(function (e) {
    return "" !== e;
  })), f(e.attributes, function (i) {
    var r;
    if ((!Oi(e) || -1 !== ["name", "id", "class", "aria-label"].indexOf(i.name)) && (null == s || !s.includes(i.name)) && !t && zi(i.value) && (r = i.name, !U(r) || "_ngcontent" !== r.substring(0, 10) && "_nghost" !== r.substring(0, 7))) {
      var o = i.value;
      "class" === i.name && (o = Ii(o).join(" ")), n["attr__" + i.name] = xn(1024, o);
    }
  });
  for (var a = 1, l = 1, u = e; u = In(u);) a++, u.tagName === e.tagName && l++;
  return n.nth_child = a, n.nth_of_type = l, n;
}
function Fn(t, i) {
  for (var s, r, {
      e: n,
      maskAllElementAttributes: o,
      maskAllText: a,
      elementAttributeIgnoreList: l,
      elementsChainAsString: u
    } = i, c = [t], d = t; d.parentNode && !Ei(d, "body");) xi(d.parentNode) ? (c.push(d.parentNode.host), d = d.parentNode.host) : (c.push(d.parentNode), d = d.parentNode);
  var h,
    _ = [],
    p = {},
    v = !1,
    g = !1;
  if (f(c, e => {
    var t = Li(e);
    "a" === e.tagName.toLowerCase() && (v = e.getAttribute("href"), v = t && v && zi(v) && v), b(Fi(e), "ph-no-capture") && (g = !0), _.push(Pn(e, o, a, l));
    var i = function (e) {
      if (!Li(e)) return {};
      var t = {};
      return f(e.attributes, function (e) {
        if (e.name && 0 === e.name.indexOf("data-ph-capture-attribute")) {
          var i = e.name.replace("data-ph-capture-attribute-", ""),
            s = e.value;
          i && s && zi(s) && (t[i] = s);
        }
      }), t;
    }(e);
    m(p, i);
  }), g) return {
    props: {},
    explicitNoCapture: g
  };
  if (a || ("a" === t.tagName.toLowerCase() || "button" === t.tagName.toLowerCase() ? _[0].$el_text = Wi(t) : _[0].$el_text = Ci(t)), v) {
    var y, w;
    _[0].attr__href = v;
    var S = null === (y = ht(v)) || void 0 === y ? void 0 : y.host,
      E = null == e || null === (w = e.location) || void 0 === w ? void 0 : w.host;
    S && E && S !== E && (h = v);
  }
  return {
    props: m({
      $event_type: n.type,
      $ce_version: 1
    }, u ? {} : {
      $elements: _
    }, {
      $elements_chain: Vi(_)
    }, null !== (s = _[0]) && void 0 !== s && s.$el_text ? {
      $el_text: null === (r = _[0]) || void 0 === r ? void 0 : r.$el_text
    } : {}, h && "click" === n.type ? {
      $external_click_url: h
    } : {}, p)
  };
}
class Rn {
  constructor(e) {
    te(this, "_initialized", !1), te(this, "_isDisabledServerSide", null), te(this, "rageclicks", new mn()), te(this, "_elementsChainAsString", !1), this.instance = e, this._elementSelectors = null;
  }
  get config() {
    var e,
      t,
      i = q(this.instance.config.autocapture) ? this.instance.config.autocapture : {};
    return i.url_allowlist = null === (e = i.url_allowlist) || void 0 === e ? void 0 : e.map(e => new RegExp(e)), i.url_ignorelist = null === (t = i.url_ignorelist) || void 0 === t ? void 0 : t.map(e => new RegExp(e)), i;
  }
  _addDomEventHandlers() {
    if (this.isBrowserSupported()) {
      if (e && o) {
        var t = t => {
            t = t || (null == e ? void 0 : e.event);
            try {
              this._captureEvent(t);
            } catch (e) {
              X.error("Failed to capture event", e);
            }
          },
          i = t => {
            t = t || (null == e ? void 0 : e.event), this._captureEvent(t, C);
          };
        P(o, "submit", t, !1, !0), P(o, "change", t, !1, !0), P(o, "click", t, !1, !0), this.config.capture_copied_text && (P(o, "copy", i, !1, !0), P(o, "cut", i, !1, !0));
      }
    } else X.info("Disabling Automatic Event Collection because this browser is not supported");
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (this._addDomEventHandlers(), this._initialized = !0);
  }
  afterDecideResponse(e) {
    e.elementsChainAsString && (this._elementsChainAsString = e.elementsChainAsString), this.instance.persistence && this.instance.persistence.register({
      [oe]: !!e.autocapture_opt_out
    }), this._isDisabledServerSide = !!e.autocapture_opt_out, this.startIfEnabled();
  }
  setElementSelectors(e) {
    this._elementSelectors = e;
  }
  getElementSelectors(e) {
    var t,
      i = [];
    return null === (t = this._elementSelectors) || void 0 === t || t.forEach(t => {
      var s = null == o ? void 0 : o.querySelectorAll(t);
      null == s || s.forEach(s => {
        e === s && i.push(t);
      });
    }), i;
  }
  get isEnabled() {
    var e,
      t,
      i = null === (e = this.instance.persistence) || void 0 === e ? void 0 : e.props[oe],
      s = this._isDisabledServerSide;
    if (W(s) && !G(i) && !this.instance.config.advanced_disable_decide) return !1;
    var r = null !== (t = this._isDisabledServerSide) && void 0 !== t ? t : !!i;
    return !!this.instance.config.autocapture && !r;
  }
  _captureEvent(t) {
    var i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "$autocapture";
    if (this.isEnabled) {
      var s,
        r = Ti(t);
      if (ki(r) && (r = r.parentNode || null), "$autocapture" === i && "click" === t.type && t instanceof MouseEvent) this.instance.config.rageclick && null !== (s = this.rageclicks) && void 0 !== s && s.isRageClick(t.clientX, t.clientY, new Date().getTime()) && this._captureEvent(t, "$rageclick");
      var n = i === C;
      if (r && Ai(r, t, this.config, n, n ? ["copy", "cut"] : void 0)) {
        var {
          props: o,
          explicitNoCapture: a
        } = Fn(r, {
          e: t,
          maskAllElementAttributes: this.instance.config.mask_all_element_attributes,
          maskAllText: this.instance.config.mask_all_text,
          elementAttributeIgnoreList: this.config.element_attribute_ignorelist,
          elementsChainAsString: this._elementsChainAsString
        });
        if (a) return !1;
        var l = this.getElementSelectors(r);
        if (l && l.length > 0 && (o.$element_selectors = l), i === C) {
          var u,
            c = Ri(null == e || null === (u = e.getSelection()) || void 0 === u ? void 0 : u.toString()),
            d = t.type || "clipboard";
          if (!c) return !1;
          o.$selected_content = c, o.$copy_type = d;
        }
        return this.instance.capture(i, o), !0;
      }
    }
  }
  isBrowserSupported() {
    return N(null == o ? void 0 : o.querySelectorAll);
  }
}
class Cn {
  constructor(e) {
    te(this, "_restoreXHRPatch", void 0), te(this, "_restoreFetchPatch", void 0), te(this, "_startCapturing", () => {
      var e, t, i, s;
      H(this._restoreXHRPatch) && (null === (e = h.__PosthogExtensions__) || void 0 === e || null === (t = e.tracingHeadersPatchFns) || void 0 === t || t._patchXHR(this.instance.sessionManager));
      H(this._restoreFetchPatch) && (null === (i = h.__PosthogExtensions__) || void 0 === i || null === (s = i.tracingHeadersPatchFns) || void 0 === s || s._patchFetch(this.instance.sessionManager));
    }), this.instance = e;
  }
  _loadScript(e) {
    var t, i, s;
    null !== (t = h.__PosthogExtensions__) && void 0 !== t && t.tracingHeadersPatchFns && e(), null === (i = h.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "tracing-headers", t => {
      if (t) return X.error("[TRACING-HEADERS] failed to load script", t);
      e();
    });
  }
  startIfEnabledOrStop() {
    var e, t;
    this.instance.config.__add_tracing_headers ? this._loadScript(this._startCapturing) : (null === (e = this._restoreXHRPatch) || void 0 === e || e.call(this), null === (t = this._restoreFetchPatch) || void 0 === t || t.call(this), this._restoreXHRPatch = void 0, this._restoreFetchPatch = void 0);
  }
}
var Tn;
!function (e) {
  e[e.PENDING = -1] = "PENDING", e[e.DENIED = 0] = "DENIED", e[e.GRANTED = 1] = "GRANTED";
}(Tn || (Tn = {}));
class $n {
  constructor(e) {
    this.instance = e;
  }
  get config() {
    return this.instance.config;
  }
  get consent() {
    return this.getDnt() ? Tn.DENIED : this.storedConsent;
  }
  isOptedOut() {
    return this.consent === Tn.DENIED || this.consent === Tn.PENDING && this.config.opt_out_capturing_by_default;
  }
  isOptedIn() {
    return !this.isOptedOut();
  }
  optInOut(e) {
    this.storage.set(this.storageKey, e ? 1 : 0, this.config.cookie_expiration, this.config.cross_subdomain_cookie, this.config.secure_cookie);
  }
  reset() {
    this.storage.remove(this.storageKey, this.config.cross_subdomain_cookie);
  }
  get storageKey() {
    var {
      token: e,
      opt_out_capturing_cookie_prefix: t
    } = this.instance.config;
    return (t || "__ph_opt_in_out_") + e;
  }
  get storedConsent() {
    var e = this.storage.get(this.storageKey);
    return "1" === e ? Tn.GRANTED : "0" === e ? Tn.DENIED : Tn.PENDING;
  }
  get storage() {
    if (!this._storage) {
      var e = this.config.opt_out_capturing_persistence_type;
      this._storage = "localStorage" === e ? rt : it;
      var t = "localStorage" === e ? it : rt;
      t.get(this.storageKey) && (this._storage.get(this.storageKey) || this.optInOut("1" === t.get(this.storageKey)), t.remove(this.storageKey, this.config.cross_subdomain_cookie));
    }
    return this._storage;
  }
  getDnt() {
    return !!this.config.respect_dnt && !!F([null == n ? void 0 : n.doNotTrack, null == n ? void 0 : n.msDoNotTrack, h.doNotTrack], e => b([!0, 1, "1", "yes"], e));
  }
}
var Mn = "[Exception Autocapture]";
class An {
  constructor(t) {
    var i;
    te(this, "originalOnUnhandledRejectionHandler", void 0), te(this, "startCapturing", () => {
      var t, i, s, r;
      if (e && this.isEnabled && !this.hasHandlers && !this.isCapturing) {
        var n = null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.errorWrappingFunctions) || void 0 === i ? void 0 : i.wrapOnError,
          o = null === (s = h.__PosthogExtensions__) || void 0 === s || null === (r = s.errorWrappingFunctions) || void 0 === r ? void 0 : r.wrapUnhandledRejection;
        if (n && o) try {
          this.unwrapOnError = n(this.captureException.bind(this)), this.unwrapUnhandledRejection = o(this.captureException.bind(this));
        } catch (e) {
          X.error(Mn + " failed to start", e), this.stopCapturing();
        } else X.error(Mn + " failed to load error wrapping functions - cannot start");
      }
    }), this.instance = t, this.remoteEnabled = !(null === (i = this.instance.persistence) || void 0 === i || !i.props[le]), this.startIfEnabled();
  }
  get isEnabled() {
    var e;
    return null !== (e = this.remoteEnabled) && void 0 !== e && e;
  }
  get isCapturing() {
    var t;
    return !(null == e || null === (t = e.onerror) || void 0 === t || !t.__POSTHOG_INSTRUMENTED__);
  }
  get hasHandlers() {
    return this.originalOnUnhandledRejectionHandler || this.unwrapOnError;
  }
  startIfEnabled() {
    this.isEnabled && !this.isCapturing && (X.info(Mn + " enabled, starting..."), this.loadScript(this.startCapturing));
  }
  loadScript(e) {
    var t, i;
    this.hasHandlers && e(), null === (t = h.__PosthogExtensions__) || void 0 === t || null === (i = t.loadExternalDependency) || void 0 === i || i.call(t, this.instance, "exception-autocapture", t => {
      if (t) return X.error(Mn + " failed to load script", t);
      e();
    });
  }
  stopCapturing() {
    var e, t;
    null === (e = this.unwrapOnError) || void 0 === e || e.call(this), null === (t = this.unwrapUnhandledRejection) || void 0 === t || t.call(this);
  }
  afterDecideResponse(e) {
    var t = e.autocaptureExceptions;
    this.remoteEnabled = !!t || !1, this.instance.persistence && this.instance.persistence.register({
      [le]: this.remoteEnabled
    }), this.startIfEnabled();
  }
  captureException(e) {
    var t = this.instance.requestRouter.endpointFor("ui");
    e.$exception_personURL = "".concat(t, "/project/").concat(this.instance.config.token, "/person/").concat(this.instance.get_distinct_id()), this.instance.exceptions.sendExceptionEvent(e);
  }
}
var Ln = 9e5,
  On = "[Web Vitals]";
class Dn {
  constructor(e) {
    var t;
    te(this, "_enabledServerSide", !1), te(this, "_initialized", !1), te(this, "buffer", {
      url: void 0,
      metrics: [],
      firstMetricTimestamp: void 0
    }), te(this, "_flushToCapture", () => {
      clearTimeout(this._delayedFlushTimer), 0 !== this.buffer.metrics.length && (this.instance.capture("$web_vitals", this.buffer.metrics.reduce((e, t) => ee(ee({}, e), {}, {
        ["$web_vitals_".concat(t.name, "_event")]: ee({}, t),
        ["$web_vitals_".concat(t.name, "_value")]: t.value
      }), {})), this.buffer = {
        url: void 0,
        metrics: [],
        firstMetricTimestamp: void 0
      });
    }), te(this, "_addToBuffer", e => {
      var t,
        i = null === (t = this.instance.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0);
      if (H(i)) X.error(On + "Could not read session ID. Dropping metrics!");else {
        this.buffer = this.buffer || {
          url: void 0,
          metrics: [],
          firstMetricTimestamp: void 0
        };
        var s = this._currentURL();
        if (!H(s)) if (j(null == e ? void 0 : e.name) || j(null == e ? void 0 : e.value)) X.error(On + "Invalid metric received", e);else if (this._maxAllowedValue && e.value >= this._maxAllowedValue) X.error(On + "Ignoring metric with value >= " + this._maxAllowedValue, e);else this.buffer.url !== s && (this._flushToCapture(), this._delayedFlushTimer = setTimeout(this._flushToCapture, this.flushToCaptureTimeoutMs)), H(this.buffer.url) && (this.buffer.url = s), this.buffer.firstMetricTimestamp = H(this.buffer.firstMetricTimestamp) ? Date.now() : this.buffer.firstMetricTimestamp, e.attribution && e.attribution.interactionTargetElement && (e.attribution.interactionTargetElement = void 0), this.buffer.metrics.push(ee(ee({}, e), {}, {
          $current_url: s,
          $session_id: i.sessionId,
          $window_id: i.windowId,
          timestamp: Date.now()
        })), this.buffer.metrics.length === this.allowedMetrics.length && this._flushToCapture();
      }
    }), te(this, "_startCapturing", () => {
      var e,
        t,
        i,
        s,
        r = h.__PosthogExtensions__;
      H(r) || H(r.postHogWebVitalsCallbacks) || ({
        onLCP: e,
        onCLS: t,
        onFCP: i,
        onINP: s
      } = r.postHogWebVitalsCallbacks), e && t && i && s ? (this.allowedMetrics.indexOf("LCP") > -1 && e(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("CLS") > -1 && t(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("FCP") > -1 && i(this._addToBuffer.bind(this)), this.allowedMetrics.indexOf("INP") > -1 && s(this._addToBuffer.bind(this)), this._initialized = !0) : X.error(On + "web vitals callbacks not loaded - not starting");
    }), this.instance = e, this._enabledServerSide = !(null === (t = this.instance.persistence) || void 0 === t || !t.props[ue]), this.startIfEnabled();
  }
  get allowedMetrics() {
    var e,
      t,
      i = q(this.instance.config.capture_performance) ? null === (e = this.instance.config.capture_performance) || void 0 === e ? void 0 : e.web_vitals_allowed_metrics : void 0;
    return H(i) ? (null === (t = this.instance.persistence) || void 0 === t ? void 0 : t.props[de]) || ["CLS", "FCP", "INP", "LCP"] : i;
  }
  get flushToCaptureTimeoutMs() {
    return (q(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals_delayed_flush_ms : void 0) || 5e3;
  }
  get _maxAllowedValue() {
    var e = q(this.instance.config.capture_performance) && V(this.instance.config.capture_performance.__web_vitals_max_value) ? this.instance.config.capture_performance.__web_vitals_max_value : Ln;
    return 0 < e && e <= 6e4 ? Ln : e;
  }
  get isEnabled() {
    var e = q(this.instance.config.capture_performance) ? this.instance.config.capture_performance.web_vitals : void 0;
    return G(e) ? e : this._enabledServerSide;
  }
  startIfEnabled() {
    this.isEnabled && !this._initialized && (X.info(On + " enabled, starting..."), this.loadScript(this._startCapturing));
  }
  afterDecideResponse(e) {
    var t = q(e.capturePerformance) && !!e.capturePerformance.web_vitals,
      i = q(e.capturePerformance) ? e.capturePerformance.web_vitals_allowed_metrics : void 0;
    this.instance.persistence && (this.instance.persistence.register({
      [ue]: t
    }), this.instance.persistence.register({
      [de]: i
    })), this._enabledServerSide = t, this.startIfEnabled();
  }
  loadScript(e) {
    var t, i, s;
    null !== (t = h.__PosthogExtensions__) && void 0 !== t && t.postHogWebVitalsCallbacks && e(), null === (i = h.__PosthogExtensions__) || void 0 === i || null === (s = i.loadExternalDependency) || void 0 === s || s.call(i, this.instance, "web-vitals", t => {
      t ? X.error(On + " failed to load script", t) : e();
    });
  }
  _currentURL() {
    var t = e ? e.location.href : void 0;
    return t || X.error(On + "Could not determine current URL"), t;
  }
}
var Nn = {
  icontains: (t, i) => !!e && i.href.toLowerCase().indexOf(t.toLowerCase()) > -1,
  not_icontains: (t, i) => !!e && -1 === i.href.toLowerCase().indexOf(t.toLowerCase()),
  regex: (t, i) => !!e && _t(i.href, t),
  not_regex: (t, i) => !!e && !_t(i.href, t),
  exact: (e, t) => t.href === e,
  is_not: (e, t) => t.href !== e
};
class qn {
  constructor(e) {
    var t = this;
    te(this, "getWebExperimentsAndEvaluateDisplayLogic", function () {
      var e = arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
      t.getWebExperiments(e => {
        qn.logInfo("retrieved web experiments from the server"), t._flagToExperiments = new Map(), e.forEach(e => {
          if (e.feature_flag_key && t._featureFlags && t._featureFlags[e.feature_flag_key]) {
            var i;
            if (t._flagToExperiments) qn.logInfo("setting flag key ", e.feature_flag_key, " to web experiment ", e), null === (i = t._flagToExperiments) || void 0 === i || i.set(e.feature_flag_key, e);
            var s = t._featureFlags[e.feature_flag_key];
            s && e.variants[s] && t.applyTransforms(e.name, s, e.variants[s].transforms);
          } else if (e.variants) for (var r in e.variants) {
            var n = e.variants[r];
            qn.matchesTestVariant(n) && t.applyTransforms(e.name, r, n.transforms);
          }
        });
      }, e);
    }), this.instance = e;
    this.instance.onFeatureFlags && this.instance.onFeatureFlags(e => {
      this.applyFeatureFlagChanges(e);
    }), this._flagToExperiments = new Map();
  }
  applyFeatureFlagChanges(e) {
    j(this._flagToExperiments) || this.instance.config.disable_web_experiments || (qn.logInfo("applying feature flags", e), e.forEach(e => {
      var t;
      if (this._flagToExperiments && null !== (t = this._flagToExperiments) && void 0 !== t && t.has(e)) {
        var i,
          s = this.instance.getFeatureFlag(e),
          r = null === (i = this._flagToExperiments) || void 0 === i ? void 0 : i.get(e);
        s && null != r && r.variants[s] && this.applyTransforms(r.name, s, r.variants[s].transforms);
      }
    }));
  }
  afterDecideResponse(e) {
    this._is_bot() ? qn.logInfo("Refusing to render web experiment since the viewer is a likely bot") : (this._featureFlags = e.featureFlags, this.loadIfEnabled(), this.previewWebExperiment());
  }
  previewWebExperiment() {
    var e = qn.getWindowLocation();
    if (null != e && e.search) {
      var t = vt(null == e ? void 0 : e.search, "__experiment_id"),
        i = vt(null == e ? void 0 : e.search, "__experiment_variant");
      t && i && (qn.logInfo("previewing web experiments ".concat(t, " && ").concat(i)), this.getWebExperiments(e => {
        this.showPreviewWebExperiment(parseInt(t), i, e);
      }, !1, !0));
    }
  }
  loadIfEnabled() {
    this.instance.config.disable_web_experiments || this.getWebExperimentsAndEvaluateDisplayLogic();
  }
  getWebExperiments(e, t, i) {
    if (this.instance.config.disable_web_experiments && !i) return e([]);
    var s = this.instance.get_property("$web_experiments");
    if (s && !t) return e(s);
    this.instance._send_request({
      url: this.instance.requestRouter.endpointFor("api", "/api/web_experiments/?token=".concat(this.instance.config.token)),
      method: "GET",
      transport: "XHR",
      callback: t => {
        if (200 !== t.statusCode || !t.json) return e([]);
        var i = t.json.experiments || [];
        return e(i);
      }
    });
  }
  showPreviewWebExperiment(e, t, i) {
    var s = i.filter(t => t.id === e);
    s && s.length > 0 && (qn.logInfo("Previewing web experiment [".concat(s[0].name, "] with variant [").concat(t, "]")), this.applyTransforms(s[0].name, t, s[0].variants[t].transforms, !0));
  }
  static matchesTestVariant(e) {
    return !j(e.conditions) && qn.matchUrlConditions(e) && qn.matchUTMConditions(e);
  }
  static matchUrlConditions(e) {
    var t;
    if (j(e.conditions) || j(null === (t = e.conditions) || void 0 === t ? void 0 : t.url)) return !0;
    var i,
      s,
      r,
      n = qn.getWindowLocation();
    return !!n && (null === (i = e.conditions) || void 0 === i || !i.url || Nn[null !== (s = null === (r = e.conditions) || void 0 === r ? void 0 : r.urlMatchType) && void 0 !== s ? s : "icontains"](e.conditions.url, n));
  }
  static getWindowLocation() {
    return null == e ? void 0 : e.location;
  }
  static matchUTMConditions(e) {
    var t;
    if (j(e.conditions) || j(null === (t = e.conditions) || void 0 === t ? void 0 : t.utm)) return !0;
    var i = pi.campaignParams();
    if (i.utm_source) {
      var s,
        r,
        n,
        o,
        a,
        l,
        u,
        c,
        d,
        h,
        _,
        p,
        v,
        g,
        f,
        m,
        b = null === (s = e.conditions) || void 0 === s || null === (r = s.utm) || void 0 === r || !r.utm_campaign || (null === (n = e.conditions) || void 0 === n || null === (o = n.utm) || void 0 === o ? void 0 : o.utm_campaign) == i.utm_campaign,
        y = null === (a = e.conditions) || void 0 === a || null === (l = a.utm) || void 0 === l || !l.utm_source || (null === (u = e.conditions) || void 0 === u || null === (c = u.utm) || void 0 === c ? void 0 : c.utm_source) == i.utm_source,
        w = null === (d = e.conditions) || void 0 === d || null === (h = d.utm) || void 0 === h || !h.utm_medium || (null === (_ = e.conditions) || void 0 === _ || null === (p = _.utm) || void 0 === p ? void 0 : p.utm_medium) == i.utm_medium,
        S = null === (v = e.conditions) || void 0 === v || null === (g = v.utm) || void 0 === g || !g.utm_term || (null === (f = e.conditions) || void 0 === f || null === (m = f.utm) || void 0 === m ? void 0 : m.utm_term) == i.utm_term;
      return b && w && S && y;
    }
    return !1;
  }
  static logInfo(e) {
    for (var t = arguments.length, i = new Array(t > 1 ? t - 1 : 0), s = 1; s < t; s++) i[s - 1] = arguments[s];
    X.info("[WebExperiments] ".concat(e), i);
  }
  applyTransforms(e, t, i, s) {
    var r;
    this._is_bot() ? qn.logInfo("Refusing to render web experiment since the viewer is a likely bot") : "control" !== t ? i.forEach(i => {
      if (i.selector) {
        var r;
        qn.logInfo("applying transform of variant ".concat(t, " for experiment ").concat(e, " "), i);
        var n,
          o = 0,
          a = null === (r = document) || void 0 === r ? void 0 : r.querySelectorAll(i.selector);
        if (null == a || a.forEach(e => {
          var t = e;
          o += 1, i.attributes && i.attributes.forEach(e => {
            switch (e.name) {
              case "text":
                t.innerText = e.value;
                break;
              case "html":
                t.innerHTML = e.value;
                break;
              case "cssClass":
                t.className = e.value;
                break;
              default:
                t.setAttribute(e.name, e.value);
            }
          }), i.text && (t.innerText = i.text), i.html && (t.parentElement ? t.parentElement.innerHTML = i.html : t.innerHTML = i.html), i.css && t.setAttribute("style", i.css);
        }), this.instance && this.instance.capture) this.instance.capture("$web_experiment_applied", {
          $web_experiment_name: e,
          $web_experiment_variant: t,
          $web_experiment_preview: s,
          $web_experiment_document_url: null === (n = qn.getWindowLocation()) || void 0 === n ? void 0 : n.href,
          $web_experiment_elements_modified: o
        });
      }
    }) : (qn.logInfo("Control variants leave the page unmodified."), this.instance && this.instance.capture && this.instance.capture("$web_experiment_applied", {
      $web_experiment_name: e,
      $web_experiment_preview: s,
      $web_experiment_variant: t,
      $web_experiment_document_url: null === (r = qn.getWindowLocation()) || void 0 === r ? void 0 : r.href,
      $web_experiment_elements_modified: 0
    }));
  }
  _is_bot() {
    return n && this.instance ? fn(n, this.instance.config.custom_blocked_useragents) : void 0;
  }
}
class Bn {
  constructor(e) {
    this.instance = e;
  }
  sendExceptionEvent(e) {
    this.instance.capture("$exception", e, {
      _noTruncate: !0,
      _batchKey: "exceptionEvent"
    });
  }
}
var Hn = ["$set_once", "$set"];
class Un {
  constructor(e) {
    this.instance = e, this.enabled = !!this.instance.config.opt_in_site_apps && !this.instance.config.advanced_disable_decide, this.missedInvocations = [], this.loaded = !1, this.appsLoading = new Set();
  }
  eventCollector(e, t) {
    if (this.enabled && !this.loaded && t) {
      var i = this.globalsForEvent(t);
      this.missedInvocations.push(i), this.missedInvocations.length > 1e3 && (this.missedInvocations = this.missedInvocations.slice(10));
    }
  }
  init() {
    var e;
    null === (e = this.instance) || void 0 === e || e._addCaptureHook(this.eventCollector.bind(this));
  }
  globalsForEvent(e) {
    var t, i, s, r, n, o, a;
    if (!e) throw new Error("Event payload is required");
    var l = {},
      u = this.instance.get_property("$groups") || [],
      c = this.instance.get_property("$stored_group_properties") || {};
    for (var [d, h] of Object.entries(c)) l[d] = {
      id: u[d],
      type: d,
      properties: h
    };
    var {
      $set_once: _,
      $set: p
    } = e;
    return {
      event: ee(ee({}, ie(e, Hn)), {}, {
        properties: ee(ee(ee({}, e.properties), p ? {
          $set: ee(ee({}, null !== (t = null === (i = e.properties) || void 0 === i ? void 0 : i.$set) && void 0 !== t ? t : {}), p)
        } : {}), _ ? {
          $set_once: ee(ee({}, null !== (s = null === (r = e.properties) || void 0 === r ? void 0 : r.$set_once) && void 0 !== s ? s : {}), _)
        } : {}),
        elements_chain: null !== (n = null === (o = e.properties) || void 0 === o ? void 0 : o.$elements_chain) && void 0 !== n ? n : "",
        distinct_id: null === (a = e.properties) || void 0 === a ? void 0 : a.distinct_id
      }),
      person: {
        properties: this.instance.get_property("$stored_person_properties")
      },
      groups: l
    };
  }
  afterDecideResponse(e) {
    var t = this;
    D(null == e ? void 0 : e.siteApps) && e.siteApps.length > 0 ? this.enabled && this.instance.config.opt_in_site_apps ? function () {
      var i = () => {
          0 === t.appsLoading.size && (t.loaded = !0, t.missedInvocations = []);
        },
        s = function (e, s) {
          var r, n;
          t.appsLoading.add(e), h["__$$ph_site_app_".concat(e, "_posthog")] = t.instance, h["__$$ph_site_app_".concat(e, "_missed_invocations")] = () => t.missedInvocations, h["__$$ph_site_app_".concat(e, "_callback")] = () => {
            t.appsLoading.delete(e), i();
          }, null === (r = h.__PosthogExtensions__) || void 0 === r || null === (n = r.loadSiteApp) || void 0 === n || n.call(r, t.instance, s, s => {
            if (s) return t.appsLoading.delete(e), i(), X.error("Error while initializing PostHog app with config id ".concat(e), s);
          });
        };
      for (var {
        id: r,
        url: n
      } of e.siteApps) s(r, n);
    }() : e.siteApps.length > 0 ? (X.error('PostHog site apps are disabled. Enable the "opt_in_site_apps" config to proceed.'), this.loaded = !0) : this.loaded = !0 : (this.loaded = !0, this.enabled = !1);
  }
}
var zn = {},
  Wn = () => {},
  jn = "posthog",
  Vn = !rr && -1 === (null == d ? void 0 : d.indexOf("MSIE")) && -1 === (null == d ? void 0 : d.indexOf("Mozilla")),
  Gn = () => {
    var t, i, s;
    return {
      api_host: "https://us.i.posthog.com",
      ui_host: null,
      token: "",
      autocapture: !0,
      rageclick: !0,
      cross_subdomain_cookie: (i = null == o ? void 0 : o.location, s = null == i ? void 0 : i.hostname, !!U(s) && "herokuapp.com" !== s.split(".").slice(-2).join(".")),
      persistence: "localStorage+cookie",
      persistence_name: "",
      loaded: Wn,
      store_google: !0,
      custom_campaign_params: [],
      custom_blocked_useragents: [],
      save_referrer: !0,
      capture_pageview: !0,
      capture_pageleave: "if_capture_pageview",
      debug: a && U(null == a ? void 0 : a.search) && -1 !== a.search.indexOf("__posthog_debug=true") || !1,
      verbose: !1,
      cookie_expiration: 365,
      upgrade: !1,
      disable_session_recording: !1,
      disable_persistence: !1,
      disable_web_experiments: !0,
      disable_surveys: !1,
      enable_recording_console_log: void 0,
      secure_cookie: "https:" === (null == e || null === (t = e.location) || void 0 === t ? void 0 : t.protocol),
      ip: !0,
      opt_out_capturing_by_default: !1,
      opt_out_persistence_by_default: !1,
      opt_out_useragent_filter: !1,
      opt_out_capturing_persistence_type: "localStorage",
      opt_out_capturing_cookie_prefix: null,
      opt_in_site_apps: !1,
      property_denylist: [],
      respect_dnt: !1,
      sanitize_properties: null,
      request_headers: {},
      inapp_protocol: "//",
      inapp_link_new_window: !1,
      request_batching: !0,
      properties_string_max_length: 65535,
      session_recording: {},
      mask_all_element_attributes: !1,
      mask_all_text: !1,
      advanced_disable_decide: !1,
      advanced_disable_feature_flags: !1,
      advanced_disable_feature_flags_on_first_load: !1,
      advanced_disable_toolbar_metrics: !1,
      feature_flag_request_timeout_ms: 3e3,
      on_request_error: e => {
        var t = "Bad HTTP status: " + e.statusCode + " " + e.text;
        X.error(t);
      },
      get_device_id: e => e,
      _onCapture: Wn,
      capture_performance: void 0,
      name: "posthog",
      bootstrap: {},
      disable_compression: !1,
      session_idle_timeout_seconds: 1800,
      person_profiles: "identified_only",
      __add_tracing_headers: !1,
      before_send: void 0
    };
  },
  Qn = e => {
    var t = {};
    H(e.process_person) || (t.person_profiles = e.process_person), H(e.xhr_headers) || (t.request_headers = e.xhr_headers), H(e.cookie_name) || (t.persistence_name = e.cookie_name), H(e.disable_cookie) || (t.disable_persistence = e.disable_cookie);
    var i = m({}, t, e);
    return D(e.property_blacklist) && (H(e.property_denylist) ? i.property_denylist = e.property_blacklist : D(e.property_denylist) ? i.property_denylist = [...e.property_blacklist, ...e.property_denylist] : X.error("Invalid value for property_denylist config: " + e.property_denylist)), i;
  };
class Yn {
  constructor() {
    te(this, "__forceAllowLocalhost", !1);
  }
  get _forceAllowLocalhost() {
    return this.__forceAllowLocalhost;
  }
  set _forceAllowLocalhost(e) {
    X.error("WebPerformanceObserver is deprecated and has no impact on network capture. Use `_forceAllowLocalhostNetworkCapture` on `posthog.sessionRecording`"), this.__forceAllowLocalhost = e;
  }
}
class Jn {
  constructor() {
    te(this, "webPerformance", new Yn()), te(this, "version", _.LIB_VERSION), te(this, "_internalEventEmitter", new kr()), this.config = Gn(), this.decideEndpointWasHit = !1, this.SentryIntegration = mr, this.sentryIntegration = e => function (e, t) {
      var i = fr(e, t);
      return {
        name: gr,
        processEvent: e => i(e)
      };
    }(this, e), this.__request_queue = [], this.__loaded = !1, this.analyticsDefaultEndpoint = "/e/", this._initialPageviewCaptured = !1, this._initialPersonProfilesConfig = null, this.featureFlags = new ze(this), this.toolbar = new ir(this), this.scrollManager = new kn(this), this.pageViewManager = new Er(this), this.surveys = new dn(this), this.experiments = new qn(this), this.exceptions = new Bn(this), this.rateLimiter = new hn(this), this.requestRouter = new vr(this), this.consent = new $n(this), this.people = {
      set: (e, t, i) => {
        var s = U(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(s), null == i || i({});
      },
      set_once: (e, t, i) => {
        var s = U(e) ? {
          [e]: t
        } : e;
        this.setPersonProperties(void 0, s), null == i || i({});
      }
    }, this.on("eventCaptured", e => X.info('send "'.concat(null == e ? void 0 : e.event, '"'), e));
  }
  init(e, t, i) {
    if (i && i !== jn) {
      var s,
        r = null !== (s = zn[i]) && void 0 !== s ? s : new Jn();
      return r._init(e, t, i), zn[i] = r, zn[jn][i] = r, r;
    }
    return this._init(e, t, i);
  }
  _init(t) {
    var i,
      s,
      r,
      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
      o = arguments.length > 2 ? arguments[2] : void 0;
    if (H(t) || z(t)) return X.critical("PostHog was initialized without a token. This likely indicates a misconfiguration. Please check the first argument passed to posthog.init()"), this;
    if (this.__loaded) return X.warn("You have already initialized PostHog! Re-initializing is a no-op"), this;
    this.__loaded = !0, this.config = {}, this._triggered_notifs = [], n.person_profiles && (this._initialPersonProfilesConfig = n.person_profiles), this.set_config(m({}, Gn(), Qn(n), {
      name: o,
      token: t
    })), this.config.on_xhr_error && X.error("[posthog] on_xhr_error is deprecated. Use on_request_error instead"), this.compression = n.disable_compression ? void 0 : R.GZipJS, this.persistence = new gi(this.config), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new gi(ee(ee({}, this.config), {}, {
      persistence: "sessionStorage"
    }));
    var a = ee({}, this.persistence.props),
      l = ee({}, this.sessionPersistence.props);
    if (this._requestQueue = new sr(e => this._send_retriable_request(e)), this._retryQueue = new dr(this), this.__request_queue = [], this.sessionManager = new _r(this), this.sessionPropsManager = new pn(this.sessionManager, this.persistence), new Cn(this).startIfEnabledOrStop(), this.siteApps = new Un(this), null === (i = this.siteApps) || void 0 === i || i.init(), this.sessionRecording = new Xs(this), this.sessionRecording.startIfEnabledOrStop(), this.config.disable_scroll_properties || this.scrollManager.startMeasuringScrollPosition(), this.autocapture = new Rn(this), this.autocapture.startIfEnabled(), this.surveys.loadIfEnabled(), this.heatmaps = new En(this), this.heatmaps.startIfEnabled(), this.webVitalsAutocapture = new Dn(this), this.exceptionObserver = new An(this), this.exceptionObserver.startIfEnabled(), this.deadClicksAutocapture = new Sn(this, wn), this.deadClicksAutocapture.startIfEnabled(), _.DEBUG = _.DEBUG || this.config.debug, _.DEBUG && X.info("Starting in debug mode", {
      this: this,
      config: n,
      thisC: ee({}, this.config),
      p: a,
      s: l
    }), this._sync_opt_out_with_persistence(), void 0 !== (null === (s = n.bootstrap) || void 0 === s ? void 0 : s.distinctID)) {
      var u,
        c,
        d = this.config.get_device_id(Je()),
        h = null !== (u = n.bootstrap) && void 0 !== u && u.isIdentifiedID ? d : n.bootstrap.distinctID;
      this.persistence.set_property(Ce, null !== (c = n.bootstrap) && void 0 !== c && c.isIdentifiedID ? "identified" : "anonymous"), this.register({
        distinct_id: n.bootstrap.distinctID,
        $device_id: h
      });
    }
    if (this._hasBootstrappedFeatureFlags()) {
      var p,
        v,
        g = Object.keys((null === (p = n.bootstrap) || void 0 === p ? void 0 : p.featureFlags) || {}).filter(e => {
          var t, i;
          return !(null === (t = n.bootstrap) || void 0 === t || null === (i = t.featureFlags) || void 0 === i || !i[e]);
        }).reduce((e, t) => {
          var i, s;
          return e[t] = (null === (i = n.bootstrap) || void 0 === i || null === (s = i.featureFlags) || void 0 === s ? void 0 : s[t]) || !1, e;
        }, {}),
        f = Object.keys((null === (v = n.bootstrap) || void 0 === v ? void 0 : v.featureFlagPayloads) || {}).filter(e => g[e]).reduce((e, t) => {
          var i, s, r, o;
          null !== (i = n.bootstrap) && void 0 !== i && null !== (s = i.featureFlagPayloads) && void 0 !== s && s[t] && (e[t] = null === (r = n.bootstrap) || void 0 === r || null === (o = r.featureFlagPayloads) || void 0 === o ? void 0 : o[t]);
          return e;
        }, {});
      this.featureFlags.receivedFeatureFlags({
        featureFlags: g,
        featureFlagPayloads: f
      });
    }
    if (!this.get_distinct_id()) {
      var b = this.config.get_device_id(Je());
      this.register_once({
        distinct_id: b,
        $device_id: b
      }, ""), this.persistence.set_property(Ce, "anonymous");
    }
    return null == e || null === (r = e.addEventListener) || void 0 === r || r.call(e, "onpagehide" in self ? "pagehide" : "unload", this._handle_unload.bind(this)), this.toolbar.maybeLoadToolbar(), n.segment ? Sr(this, () => this._loaded()) : this._loaded(), N(this.config._onCapture) && this.config._onCapture !== Wn && (X.warn("onCapture is deprecated. Please use `before_send` instead"), this.on("eventCaptured", e => this.config._onCapture(e.event, e))), this;
  }
  _afterDecideResponse(e) {
    var t, i, s, r, n, o, a, l, u, c;
    this.compression = void 0, e.supportedCompression && !this.config.disable_compression && (this.compression = b(e.supportedCompression, R.GZipJS) ? R.GZipJS : b(e.supportedCompression, R.Base64) ? R.Base64 : void 0), null !== (t = e.analytics) && void 0 !== t && t.endpoint && (this.analyticsDefaultEndpoint = e.analytics.endpoint), this.set_config({
      person_profiles: this._initialPersonProfilesConfig ? this._initialPersonProfilesConfig : e.defaultIdentifiedOnly ? "identified_only" : "always"
    }), null === (i = this.siteApps) || void 0 === i || i.afterDecideResponse(e), null === (s = this.sessionRecording) || void 0 === s || s.afterDecideResponse(e), null === (r = this.autocapture) || void 0 === r || r.afterDecideResponse(e), null === (n = this.heatmaps) || void 0 === n || n.afterDecideResponse(e), null === (o = this.experiments) || void 0 === o || o.afterDecideResponse(e), null === (a = this.surveys) || void 0 === a || a.afterDecideResponse(e), null === (l = this.webVitalsAutocapture) || void 0 === l || l.afterDecideResponse(e), null === (u = this.exceptionObserver) || void 0 === u || u.afterDecideResponse(e), null === (c = this.deadClicksAutocapture) || void 0 === c || c.afterDecideResponse(e);
  }
  _loaded() {
    var e = this.config.advanced_disable_decide;
    e || this.featureFlags.setReloadingPaused(!0);
    try {
      this.config.loaded(this);
    } catch (e) {
      X.critical("`loaded` function failed", e);
    }
    this._start_queue_if_opted_in(), this.config.capture_pageview && setTimeout(() => {
      this.consent.isOptedIn() && this._captureInitialPageview();
    }, 1), e || (new Ks(this).call(), this.featureFlags.resetRequestQueue());
  }
  _start_queue_if_opted_in() {
    var e;
    this.has_opted_out_capturing() || this.config.request_batching && (null === (e = this._requestQueue) || void 0 === e || e.enable());
  }
  _dom_loaded() {
    this.has_opted_out_capturing() || g(this.__request_queue, e => this._send_retriable_request(e)), this.__request_queue = [], this._start_queue_if_opted_in();
  }
  _handle_unload() {
    var e, t;
    this.config.request_batching ? (this._shouldCapturePageleave() && this.capture("$pageleave"), null === (e = this._requestQueue) || void 0 === e || e.unload(), null === (t = this._retryQueue) || void 0 === t || t.unload()) : this._shouldCapturePageleave() && this.capture("$pageleave", null, {
      transport: "sendBeacon"
    });
  }
  _send_request(e) {
    this.__loaded && (Vn ? this.__request_queue.push(e) : this.rateLimiter.isServerRateLimited(e.batchKey) || (e.transport = e.transport || this.config.api_transport, e.url = or(e.url, {
      ip: this.config.ip ? 1 : 0
    }), e.headers = ee({}, this.config.request_headers), e.compression = "best-available" === e.compression ? this.compression : e.compression, (e => {
      var t,
        i,
        s,
        r = ee({}, e);
      r.timeout = r.timeout || 6e4, r.url = or(r.url, {
        _: new Date().getTime().toString(),
        ver: _.LIB_VERSION,
        compression: r.compression
      });
      var n = null !== (t = r.transport) && void 0 !== t ? t : "XHR",
        o = null !== (i = null === (s = F(ur, e => e.transport === n)) || void 0 === s ? void 0 : s.method) && void 0 !== i ? i : ur[0].method;
      if (!o) throw new Error("No available transport method");
      o(r);
    })(ee(ee({}, e), {}, {
      callback: t => {
        var i, s, r;
        (this.rateLimiter.checkForLimiting(t), t.statusCode >= 400) && (null === (s = (r = this.config).on_request_error) || void 0 === s || s.call(r, t));
        null === (i = e.callback) || void 0 === i || i.call(e, t);
      }
    }))));
  }
  _send_retriable_request(e) {
    this._retryQueue ? this._retryQueue.retriableRequest(e) : this._send_request(e);
  }
  _execute_array(e) {
    var t,
      i = [],
      s = [],
      r = [];
    g(e, e => {
      e && (t = e[0], D(t) ? r.push(e) : N(e) ? e.call(this) : D(e) && "alias" === t ? i.push(e) : D(e) && -1 !== t.indexOf("capture") && N(this[t]) ? r.push(e) : s.push(e));
    });
    var n = function (e, t) {
      g(e, function (e) {
        if (D(e[0])) {
          var i = t;
          f(e, function (e) {
            i = i[e[0]].apply(i, e.slice(1));
          });
        } else this[e[0]].apply(this, e.slice(1));
      }, t);
    };
    n(i, this), n(s, this), n(r, this);
  }
  _hasBootstrappedFeatureFlags() {
    var e, t;
    return (null === (e = this.config.bootstrap) || void 0 === e ? void 0 : e.featureFlags) && Object.keys(null === (t = this.config.bootstrap) || void 0 === t ? void 0 : t.featureFlags).length > 0 || !1;
  }
  push(e) {
    this._execute_array([e]);
  }
  capture(e, t, i) {
    var s;
    if (this.__loaded && this.persistence && this.sessionPersistence && this._requestQueue) {
      if (!this.consent.isOptedOut()) if (!H(e) && U(e)) {
        if (this.config.opt_out_useragent_filter || !this._is_bot()) {
          var r = null != i && i.skip_client_rate_limiting ? void 0 : this.rateLimiter.clientRateLimitContext();
          if (null == r || !r.isRateLimited) {
            this.sessionPersistence.update_search_keyword(), this.config.store_google && this.sessionPersistence.update_campaign_params(), this.config.save_referrer && this.sessionPersistence.update_referrer_info(), (this.config.store_google || this.config.save_referrer) && this.persistence.set_initial_person_info();
            var n = new Date(),
              o = (null == i ? void 0 : i.timestamp) || n,
              a = {
                uuid: Je(),
                event: e,
                properties: this._calculate_event_properties(e, t || {}, o)
              };
            r && (a.properties.$lib_rate_limit_remaining_tokens = r.remainingTokens), (null == i ? void 0 : i.$set) && (a.$set = null == i ? void 0 : i.$set);
            var l = this._calculate_set_once_properties(null == i ? void 0 : i.$set_once);
            l && (a.$set_once = l), (a = x(a, null != i && i._noTruncate ? null : this.config.properties_string_max_length)).timestamp = o, H(null == i ? void 0 : i.timestamp) || (a.properties.$event_time_override_provided = !0, a.properties.$event_time_override_system_time = n);
            var u = ee(ee({}, a.properties.$set), a.$set);
            if (B(u) || this.setPersonPropertiesForFlags(u), !j(this.config.before_send)) {
              var c = this._runBeforeSend(a);
              if (!c) return;
              a = c;
            }
            this._internalEventEmitter.emit("eventCaptured", a);
            var d = {
              method: "POST",
              url: null !== (s = null == i ? void 0 : i._url) && void 0 !== s ? s : this.requestRouter.endpointFor("api", this.analyticsDefaultEndpoint),
              data: a,
              compression: "best-available",
              batchKey: null == i ? void 0 : i._batchKey
            };
            return !this.config.request_batching || i && (null == i || !i._batchKey) || null != i && i.send_instantly ? this._send_retriable_request(d) : this._requestQueue.enqueue(d), a;
          }
          X.critical("This capture call is ignored due to client rate limiting.");
        }
      } else X.error("No event name provided to posthog.capture");
    } else X.uninitializedWarning("posthog.capture");
  }
  _addCaptureHook(e) {
    return this.on("eventCaptured", t => e(t.event, t));
  }
  _calculate_event_properties(e, t, i) {
    if (i = i || new Date(), !this.persistence || !this.sessionPersistence) return t;
    var s = this.persistence.remove_event_timer(e),
      r = ee({}, t);
    if (r.token = this.config.token, "$snapshot" === e) {
      var n = ee(ee({}, this.persistence.properties()), this.sessionPersistence.properties());
      return r.distinct_id = n.distinct_id, (!U(r.distinct_id) && !V(r.distinct_id) || z(r.distinct_id)) && X.error("Invalid distinct_id for replay event. This indicates a bug in your implementation"), r;
    }
    var a = pi.properties();
    if (this.sessionManager) {
      var {
        sessionId: l,
        windowId: u
      } = this.sessionManager.checkAndGetSessionAndWindowId();
      r.$session_id = l, r.$window_id = u;
    }
    if (this.sessionRecording && (r.$recording_status = this.sessionRecording.status), this.requestRouter.region === hr.CUSTOM && (r.$lib_custom_api_host = this.config.api_host), this.sessionPropsManager && this.config.__preview_send_client_session_params && ("$pageview" === e || "$pageleave" === e || "$autocapture" === e)) {
      var c = this.sessionPropsManager.getSessionProps();
      r = m(r, c);
    }
    if (!this.config.disable_scroll_properties) {
      var h = {};
      "$pageview" === e ? h = this.pageViewManager.doPageView(i) : "$pageleave" === e && (h = this.pageViewManager.doPageLeave(i)), r = m(r, h);
    }
    if ("$pageview" === e && o && (r.title = o.title), !H(s)) {
      var _ = i.getTime() - s;
      r.$duration = parseFloat((_ / 1e3).toFixed(3));
    }
    d && this.config.opt_out_useragent_filter && (r.$browser_type = this._is_bot() ? "bot" : "browser"), (r = m({}, a, this.persistence.properties(), this.sessionPersistence.properties(), r)).$is_identified = this._isIdentified(), D(this.config.property_denylist) ? f(this.config.property_denylist, function (e) {
      delete r[e];
    }) : X.error("Invalid value for property_denylist config: " + this.config.property_denylist + " or property_blacklist config: " + this.config.property_blacklist);
    var p = this.config.sanitize_properties;
    p && (r = p(r, e));
    var v = this._hasPersonProcessing();
    return r.$process_person_profile = v, v && this._requirePersonProcessing("_calculate_event_properties"), r;
  }
  _calculate_set_once_properties(e) {
    if (!this.persistence || !this._hasPersonProcessing()) return e;
    var t = m({}, this.persistence.get_initial_props(), e || {}),
      i = this.config.sanitize_properties;
    return i && (t = i(t, "$set_once")), B(t) ? void 0 : t;
  }
  register(e, t) {
    var i;
    null === (i = this.persistence) || void 0 === i || i.register(e, t);
  }
  register_once(e, t, i) {
    var s;
    null === (s = this.persistence) || void 0 === s || s.register_once(e, t, i);
  }
  register_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.register(e);
  }
  unregister(e) {
    var t;
    null === (t = this.persistence) || void 0 === t || t.unregister(e);
  }
  unregister_for_session(e) {
    var t;
    null === (t = this.sessionPersistence) || void 0 === t || t.unregister(e);
  }
  _register_single(e, t) {
    this.register({
      [e]: t
    });
  }
  getFeatureFlag(e, t) {
    return this.featureFlags.getFeatureFlag(e, t);
  }
  getFeatureFlagPayload(e) {
    var t = this.featureFlags.getFeatureFlagPayload(e);
    try {
      return JSON.parse(t);
    } catch (e) {
      return t;
    }
  }
  isFeatureEnabled(e, t) {
    return this.featureFlags.isFeatureEnabled(e, t);
  }
  reloadFeatureFlags() {
    this.featureFlags.reloadFeatureFlags();
  }
  updateEarlyAccessFeatureEnrollment(e, t) {
    this.featureFlags.updateEarlyAccessFeatureEnrollment(e, t);
  }
  getEarlyAccessFeatures(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    return this.featureFlags.getEarlyAccessFeatures(e, t);
  }
  on(e, t) {
    return this._internalEventEmitter.on(e, t);
  }
  onFeatureFlags(e) {
    return this.featureFlags.onFeatureFlags(e);
  }
  onSessionId(e) {
    var t, i;
    return null !== (t = null === (i = this.sessionManager) || void 0 === i ? void 0 : i.onSessionId(e)) && void 0 !== t ? t : () => {};
  }
  getSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getSurveys(e, t);
  }
  getActiveMatchingSurveys(e) {
    var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
    this.surveys.getActiveMatchingSurveys(e, t);
  }
  renderSurvey(e, t) {
    this.surveys.renderSurvey(e, t);
  }
  canRenderSurvey(e) {
    this.surveys.canRenderSurvey(e);
  }
  getNextSurveyStep(e, t, i) {
    return this.surveys.getNextSurveyStep(e, t, i);
  }
  identify(e, t, i) {
    if (!this.__loaded || !this.persistence) return X.uninitializedWarning("posthog.identify");
    if (V(e) && (e = e.toString(), X.warn("The first argument to posthog.identify was a number, but it should be a string. It has been converted to a string.")), e) {
      if (["distinct_id", "distinctid"].includes(e.toLowerCase())) X.critical('The string "'.concat(e, '" was set in posthog.identify which indicates an error. This ID should be unique to the user and not a hardcoded string.'));else if (this._requirePersonProcessing("posthog.identify")) {
        var s = this.get_distinct_id();
        if (this.register({
          $user_id: e
        }), !this.get_property("$device_id")) {
          var r = s;
          this.register_once({
            $had_persisted_distinct_id: !0,
            $device_id: r
          }, "");
        }
        e !== s && e !== this.get_property(re) && (this.unregister(re), this.register({
          distinct_id: e
        }));
        var n = "anonymous" === (this.persistence.get_property(Ce) || "anonymous");
        e !== s && n ? (this.persistence.set_property(Ce, "identified"), this.setPersonPropertiesForFlags(t || {}, !1), this.capture("$identify", {
          distinct_id: e,
          $anon_distinct_id: s
        }, {
          $set: t || {},
          $set_once: i || {}
        }), this.featureFlags.setAnonymousDistinctId(s)) : (t || i) && this.setPersonProperties(t, i), e !== s && (this.reloadFeatureFlags(), this.unregister(Re));
      }
    } else X.error("Unique user id has not been set in posthog.identify");
  }
  setPersonProperties(e, t) {
    (e || t) && this._requirePersonProcessing("posthog.setPersonProperties") && (this.setPersonPropertiesForFlags(e || {}), this.capture("$set", {
      $set: e || {},
      $set_once: t || {}
    }));
  }
  group(e, t, i) {
    if (e && t) {
      if (this._requirePersonProcessing("posthog.group")) {
        var s = this.getGroups();
        s[e] !== t && this.resetGroupPropertiesForFlags(e), this.register({
          $groups: ee(ee({}, s), {}, {
            [e]: t
          })
        }), i && (this.capture("$groupidentify", {
          $group_type: e,
          $group_key: t,
          $group_set: i
        }), this.setGroupPropertiesForFlags({
          [e]: i
        })), s[e] === t || i || this.reloadFeatureFlags();
      }
    } else X.error("posthog.group requires a group type and group key");
  }
  resetGroups() {
    this.register({
      $groups: {}
    }), this.resetGroupPropertiesForFlags(), this.reloadFeatureFlags();
  }
  setPersonPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setPersonPropertiesForFlags") && this.featureFlags.setPersonPropertiesForFlags(e, t);
  }
  resetPersonPropertiesForFlags() {
    this.featureFlags.resetPersonPropertiesForFlags();
  }
  setGroupPropertiesForFlags(e) {
    var t = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
    this._requirePersonProcessing("posthog.setGroupPropertiesForFlags") && this.featureFlags.setGroupPropertiesForFlags(e, t);
  }
  resetGroupPropertiesForFlags(e) {
    this.featureFlags.resetGroupPropertiesForFlags(e);
  }
  reset(e) {
    var t, i, s, r, n;
    if (X.info("reset"), !this.__loaded) return X.uninitializedWarning("posthog.reset");
    var o = this.get_property("$device_id");
    this.consent.reset(), null === (t = this.persistence) || void 0 === t || t.clear(), null === (i = this.sessionPersistence) || void 0 === i || i.clear(), null === (s = this.surveys) || void 0 === s || s.reset(), null === (r = this.persistence) || void 0 === r || r.set_property(Ce, "anonymous"), null === (n = this.sessionManager) || void 0 === n || n.resetSessionId();
    var a = this.config.get_device_id(Je());
    this.register_once({
      distinct_id: a,
      $device_id: e ? a : o
    }, "");
  }
  get_distinct_id() {
    return this.get_property("distinct_id");
  }
  getGroups() {
    return this.get_property("$groups") || {};
  }
  get_session_id() {
    var e, t;
    return null !== (e = null === (t = this.sessionManager) || void 0 === t ? void 0 : t.checkAndGetSessionAndWindowId(!0).sessionId) && void 0 !== e ? e : "";
  }
  get_session_replay_url(e) {
    if (!this.sessionManager) return "";
    var {
        sessionId: t,
        sessionStartTimestamp: i
      } = this.sessionManager.checkAndGetSessionAndWindowId(!0),
      s = this.requestRouter.endpointFor("ui", "/project/".concat(this.config.token, "/replay/").concat(t));
    if (null != e && e.withTimestamp && i) {
      var r,
        n = null !== (r = e.timestampLookBack) && void 0 !== r ? r : 10;
      if (!i) return s;
      var o = Math.max(Math.floor((new Date().getTime() - i) / 1e3) - n, 0);
      s += "?t=".concat(o);
    }
    return s;
  }
  alias(e, t) {
    return e === this.get_property(se) ? (X.critical("Attempting to create alias for existing People user - aborting."), -2) : this._requirePersonProcessing("posthog.alias") ? (H(t) && (t = this.get_distinct_id()), e !== t ? (this._register_single(re, e), this.capture("$create_alias", {
      alias: e,
      distinct_id: t
    })) : (X.warn("alias matches current distinct_id - skipping api call."), this.identify(e), -1)) : void 0;
  }
  set_config(e) {
    var t,
      i,
      s,
      r,
      n = ee({}, this.config);
    q(e) && (m(this.config, Qn(e)), null === (t = this.persistence) || void 0 === t || t.update_config(this.config, n), this.sessionPersistence = "sessionStorage" === this.config.persistence || "memory" === this.config.persistence ? this.persistence : new gi(ee(ee({}, this.config), {}, {
      persistence: "sessionStorage"
    })), rt.is_supported() && "true" === rt.get("ph_debug") && (this.config.debug = !0), this.config.debug && (_.DEBUG = !0, X.info("set_config", {
      config: e,
      oldConfig: n,
      newConfig: ee({}, this.config)
    })), null === (i = this.sessionRecording) || void 0 === i || i.startIfEnabledOrStop(), null === (s = this.autocapture) || void 0 === s || s.startIfEnabled(), null === (r = this.heatmaps) || void 0 === r || r.startIfEnabled(), this.surveys.loadIfEnabled(), this._sync_opt_out_with_persistence());
  }
  startSessionRecording(e) {
    var t = !0 === e,
      i = {
        sampling: t || !(null == e || !e.sampling),
        linked_flag: t || !(null == e || !e.linked_flag),
        url_trigger: t || !(null == e || !e.url_trigger),
        event_trigger: t || !(null == e || !e.event_trigger)
      };
    if (Object.values(i).some(Boolean)) {
      var s, r, n, o, a;
      if (null === (s = this.sessionManager) || void 0 === s || s.checkAndGetSessionAndWindowId(), i.sampling) null === (r = this.sessionRecording) || void 0 === r || r.overrideSampling();
      if (i.linked_flag) null === (n = this.sessionRecording) || void 0 === n || n.overrideLinkedFlag();
      if (i.url_trigger) null === (o = this.sessionRecording) || void 0 === o || o.overrideTrigger("url");
      if (i.event_trigger) null === (a = this.sessionRecording) || void 0 === a || a.overrideTrigger("event");
    }
    this.set_config({
      disable_session_recording: !1
    });
  }
  stopSessionRecording() {
    this.set_config({
      disable_session_recording: !0
    });
  }
  sessionRecordingStarted() {
    var e;
    return !(null === (e = this.sessionRecording) || void 0 === e || !e.started);
  }
  captureException(e, t) {
    var i,
      s = new Error("PostHog syntheticException"),
      r = N(null === (i = h.__PosthogExtensions__) || void 0 === i ? void 0 : i.parseErrorAsProperties) ? h.__PosthogExtensions__.parseErrorAsProperties([e.message, void 0, void 0, void 0, e], {
        syntheticException: s
      }) : ee({
        $exception_level: "error",
        $exception_list: [{
          type: e.name,
          value: e.message,
          mechanism: {
            handled: !0,
            synthetic: !1
          }
        }]
      }, t);
    this.exceptions.sendExceptionEvent(r);
  }
  loadToolbar(e) {
    return this.toolbar.loadToolbar(e);
  }
  get_property(e) {
    var t;
    return null === (t = this.persistence) || void 0 === t ? void 0 : t.props[e];
  }
  getSessionProperty(e) {
    var t;
    return null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.props[e];
  }
  toString() {
    var e,
      t = null !== (e = this.config.name) && void 0 !== e ? e : jn;
    return t !== jn && (t = jn + "." + t), t;
  }
  _isIdentified() {
    var e, t;
    return "identified" === (null === (e = this.persistence) || void 0 === e ? void 0 : e.get_property(Ce)) || "identified" === (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.get_property(Ce));
  }
  _hasPersonProcessing() {
    var e, t, i, s;
    return !("never" === this.config.person_profiles || "identified_only" === this.config.person_profiles && !this._isIdentified() && B(this.getGroups()) && (null === (e = this.persistence) || void 0 === e || null === (t = e.props) || void 0 === t || !t[re]) && (null === (i = this.persistence) || void 0 === i || null === (s = i.props) || void 0 === s || !s[Oe]));
  }
  _shouldCapturePageleave() {
    return !0 === this.config.capture_pageleave || "if_capture_pageview" === this.config.capture_pageleave && this.config.capture_pageview;
  }
  createPersonProfile() {
    this._hasPersonProcessing() || this._requirePersonProcessing("posthog.createPersonProfile") && this.setPersonProperties({}, {});
  }
  _requirePersonProcessing(e) {
    return "never" === this.config.person_profiles ? (X.error(e + ' was called, but process_person is set to "never". This call will be ignored.'), !1) : (this._register_single(Oe, !0), !0);
  }
  _sync_opt_out_with_persistence() {
    var e,
      t,
      i,
      s,
      r = this.consent.isOptedOut(),
      n = this.config.opt_out_persistence_by_default,
      o = this.config.disable_persistence || r && !!n;
    (null === (e = this.persistence) || void 0 === e ? void 0 : e.disabled) !== o && (null === (i = this.persistence) || void 0 === i || i.set_disabled(o));
    (null === (t = this.sessionPersistence) || void 0 === t ? void 0 : t.disabled) !== o && (null === (s = this.sessionPersistence) || void 0 === s || s.set_disabled(o));
  }
  opt_in_capturing(e) {
    var t;
    (this.consent.optInOut(!0), this._sync_opt_out_with_persistence(), H(null == e ? void 0 : e.captureEventName) || null != e && e.captureEventName) && this.capture(null !== (t = null == e ? void 0 : e.captureEventName) && void 0 !== t ? t : "$opt_in", null == e ? void 0 : e.captureProperties, {
      send_instantly: !0
    });
    this.config.capture_pageview && this._captureInitialPageview();
  }
  opt_out_capturing() {
    this.consent.optInOut(!1), this._sync_opt_out_with_persistence();
  }
  has_opted_in_capturing() {
    return this.consent.isOptedIn();
  }
  has_opted_out_capturing() {
    return this.consent.isOptedOut();
  }
  clear_opt_in_out_capturing() {
    this.consent.reset(), this._sync_opt_out_with_persistence();
  }
  _is_bot() {
    return n ? fn(n, this.config.custom_blocked_useragents) : void 0;
  }
  _captureInitialPageview() {
    o && !this._initialPageviewCaptured && (this._initialPageviewCaptured = !0, this.capture("$pageview", {
      title: o.title
    }, {
      send_instantly: !0
    }));
  }
  debug(t) {
    !1 === t ? (null == e || e.console.log("You've disabled debug mode."), localStorage && localStorage.removeItem("ph_debug"), this.set_config({
      debug: !1
    })) : (null == e || e.console.log("You're now in debug mode. All calls to PostHog will be logged in your console.\nYou can disable this with `posthog.debug(false)`."), localStorage && localStorage.setItem("ph_debug", "true"), this.set_config({
      debug: !0
    }));
  }
  _runBeforeSend(e) {
    if (j(this.config.before_send)) return e;
    var t = D(this.config.before_send) ? this.config.before_send : [this.config.before_send],
      i = e;
    for (var s of t) {
      if (i = s(i), j(i)) {
        var r = "Event '".concat(e.event, "' was rejected in beforeSend function");
        return Y(e.event) ? X.warn("".concat(r, ". This can cause unexpected behavior.")) : X.info(r), null;
      }
      i.properties && !B(i.properties) || X.warn("Event '".concat(e.event, "' has no properties after beforeSend function, this is likely an error."));
    }
    return i;
  }
}
!function (e, t) {
  for (var i = 0; i < t.length; i++) e.prototype[t[i]] = S(e.prototype[t[i]]);
}(Jn, ["identify"]);
var Xn,
  Kn = (Xn = zn[jn] = new Jn(), function () {
    function t() {
      t.done || (t.done = !0, Vn = !1, f(zn, function (e) {
        e._dom_loaded();
      }));
    }
    null != o && o.addEventListener && ("complete" === o.readyState ? t() : o.addEventListener("DOMContentLoaded", t, !1)), e && P(e, "load", t, !0);
  }(), Xn);
export { C as COPY_AUTOCAPTURE_EVENT, R as Compression, Jn as PostHog, wr as SurveyQuestionBranchingType, yr as SurveyQuestionType, br as SurveyType, Kn as default, T as knownUnsafeEditableEvent, Kn as posthog, $ as severityLevels };
