import { format, isValid, parse } from "date-fns";

export function parseAndFormatDate(dateString: string): string {
  if (!dateString) return "";

  let parsedDate: Date | null = null;

  // Try parsing as dd.MM.yyyy
  parsedDate = parse(dateString, "dd.MM.yyyy", new Date());

  // If not valid, try parsing as yyyy-MM-dd
  if (!isValid(parsedDate)) {
    parsedDate = parse(dateString, "yyyy-MM-dd", new Date());
  }

  // If still not valid, return empty string or handle error as needed
  if (!isValid(parsedDate)) {
    console.error("Invalid date format:", dateString);
    return "";
  }

  // Format the valid date to yyyy-MM-dd
  return format(parsedDate, "yyyy-MM-dd");
}

export function parseDateOrFillWithToday(dateString: string) {
  if (dateString && dateString.trim() !== "") {
    return parseAndFormatDate(dateString);
  }
  return format(Date.now(), "yyyy-LL-dd"); // "2024-07-18" is the correct input format, eventhough it's shown as dd.LL.yyyy in the actual input
}
