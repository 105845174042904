import { NgClass } from "@angular/common";
import { Component, effect, signal } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { AuthService } from "~features/auth/auth.service";

@Component({
  selector: "app-resetpassword-page",
  templateUrl: "./resetpassword-page.component.html",
  styleUrls: ["./resetpassword-page.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
})
export class ResetpasswordPageComponent {
  newPasswordForm = signal<FormGroup>(
    this.formBuilder.group(
      {
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmNewPassword: ["", [Validators.required]],
      },
      { validators: this.passwordMatchValidator }
    )
  );

  submitted = signal(false);
  loading = signal(false);
  success = signal(false);
  usermail = signal("");
  token = signal("");

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    effect(() => {
      this.route.params.subscribe((params) => {
        this.token.set(params["token"]);
      });
      this.route.queryParams.subscribe((params) => {
        this.usermail.set(params["usermail"]);
      });
    });
  }

  onNewPasswordSubmit() {
    this.submitted.set(true);

    if (this.newPasswordForm().invalid) {
      return;
    }

    this.loading.set(true);
    this.authService
      .setNewPassword(
        this.usermail(),
        this.newPasswordForm().get("newPassword").value,
        this.token()
      )
      .then(() => {
        this.loading.set(false);
        this.success.set(true);
      })
      .catch(() => {
        this.loading.set(false);
        // Handle error
      });
  }

  private passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get("newPassword").value;
    const confirmPassword = formGroup.get("confirmNewPassword").value;

    if (password === confirmPassword) {
      formGroup.get("confirmNewPassword").setErrors(null);
    } else {
      formGroup.get("confirmNewPassword").setErrors({ passwordMismatch: true });
    }
  }
}
