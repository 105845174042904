import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

declare let Plotly: any;
@Component({
  selector: "app-test-chart",
  standalone: true,
  templateUrl: "./test-chart.component.html",
  styleUrl: "./test-chart.component.scss",
})
export class TestChartComponent implements OnInit {
  @ViewChild("chart", { static: true }) chartElement!: ElementRef;

  ngOnInit() {
    const data = [
      {
        x: [1, 2, 3],
        y: [2, 6, 3],
        type: "scatter",
        mode: "lines+markers",
        marker: { color: "red" },
      },
      { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
    ];

    const layout = { width: 800, height: 500, title: "Test Chart" };

    Plotly.newPlot(this.chartElement.nativeElement, data, layout);
  }
}
