<div class="grid gap-4 grid-cols-6">
  @if (event) {
    <div
      class="col-span-12 md:col-span-4 bg-white shadow-md mb-6 p-4 rounded-lg"
    >
      <div class="col-span-12 p-2 border-b">
        <div>
          {{
            event.date
              | date: "MMM dd, yyyy, HH:mm" : timeZoneFormat(event.date)
          }}
        </div>
        <h4 class="mt-2 text-md font-bold">{{ event.title }}</h4>
      </div>
      @if (listType !== "pastEvents") {
        <div class="col-span-12 p-2 border-b">
          @if (inviteLink) {
            <a
              class="text-blue-600 hover:underline"
              [href]="inviteLink"
              target="_blank"
            >
              <span>Invite your investors</span>
              <i class="ml-1 fas fa-arrow-right"></i>
            </a>
            <button
              class="ml-3 px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-sm hover:opacity-80"
              (click)="copyTextToClipboard(inviteLink!)"
            >
              <svg
                class="inline-block align-text-bottom mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                />
                <path
                  d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                />
              </svg>
              <span>Copy link</span>
            </button>
          }
        </div>
      }
      @if (listType !== "pastEvents") {
        <div class="col-span-12 p-2 border-b">
          @if (listType === "nextEvent" || listType === "upcomingEvents") {
            @if (event.meetingLink) {
              <a
                target="_blank"
                [href]="event.meetingLink"
                class="text-blue-600 hover:underline"
              >
                Join event here
                <i class="ml-1 fas fa-arrow-right"></i>
              </a>
            } @else {
              <a target="_blank" class="text-blue-600 hover:underline">
                Join event here (later)
                <i class="ml-1 fas fa-arrow-right"></i>
              </a>
            }
          }
        </div>
      }
      @if (listType === "pastEvents") {
        <div class="col-span-12 p-2 border-b">
          @if (event.presentationURL) {
            <a
              class="text-blue-600 hover:underline"
              [href]="event.presentationURL"
              target="_blank"
            >
              Download presentation
              <i class="ml-1 fas fa-arrow-right"></i>
            </a>
          } @else {
            <span class="text-gray-600">
              If you have provided a presentation, it will be uploaded here
              soon.
            </span>
          }
          @if (event.showFeedback && event.feedbackURL) {
            <br />
            <a
              class="text-blue-600 hover:underline"
              [href]="event.feedbackURL"
              target="_blank"
            >
              Read feedback
              <i class="ml-1 fas fa-arrow-right"></i>
            </a>
          }
        </div>
      }
      <div class="col-span-12 p-2"></div>
      <div class="col-span-12 p-2">
        <span (click)="toggleExpand(0)" class="cursor-pointer">
          <i
            class="fa"
            [ngClass]="expanded()[0] ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i>
          <span class="font-bold ml-2">
            {{ event.subscribers?.length || 0 }} Registered Investors
          </span>
        </span>
        @if (event.subscribers?.length > 0) {
          <button
            class="text-blue-600 hover:underline float-right"
            (click)="
              downloadRegisteredInvestors(event._id, event.title, event.date)
            "
          >
            Download investor details
            <i class="ml-1 fas fa-arrow-right"></i>
          </button>
        }
        <div
          class="overflow-x-auto pt-2"
          [ngClass]="{ hidden: !expanded()[0] }"
        >
          <table class="table-fixed w-full">
            <thead>
              <tr class="bg-gray-100 text-left">
                <th
                  class="px-4 py-2 cursor-pointer"
                  (click)="sortPeople('subscribers', 'firstName')"
                >
                  First Name <i class="fa fa-sort ml-1 text-gray-500"></i>
                </th>
                <th
                  class="px-4 py-2 cursor-pointer"
                  (click)="sortPeople('subscribers', 'lastName')"
                >
                  Last Name <i class="fa fa-sort ml-1 text-gray-500"></i>
                </th>
                <th
                  class="px-4 py-2 cursor-pointer"
                  (click)="sortPeople('subscribers', 'jobTitle')"
                >
                  Job Title <i class="fa fa-sort ml-1 text-gray-500"></i>
                </th>
                <th
                  class="px-4 py-2 cursor-pointer"
                  (click)="sortPeople('subscribers', 'company')"
                >
                  Company <i class="fa fa-sort ml-1 text-gray-500"></i>
                </th>
                <th
                  class="px-4 py-2 text-center cursor-pointer"
                  (click)="sortPeople('subscribers', 'investorProfile')"
                >
                  Profile <i class="fa fa-sort ml-1 text-gray-500"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              @for (subscriber of event.subscribers; track subscriber) {
                <tr class="border-b">
                  <td class="px-4 py-2">
                    {{ subscriber?.firstName || "Anonymous" }}
                  </td>
                  <td class="px-4 py-2">{{ subscriber?.lastName }}</td>
                  <td class="px-4 py-2">
                    {{ subscriber?.jobTitle | slice: 0 : 30 }}
                  </td>
                  <td class="px-4 py-2">
                    {{ subscriber?.company | slice: 0 : 30 }}
                  </td>
                  <td class="px-4 py-2 text-center">
                    @if (personHasProfile(subscriber)) {
                      <svg
                        (click)="openInvestorProfileDIalog(subscriber)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        class="bi bi-file-person inline-block cursor-pointer"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M12 1a1 1 0 0 1 1 1v10.755S12 11 8 11s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"
                        />
                        <path d="M8 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="hidden md:block col-span-12 md:col-span-2 mx-auto bg-white shadow-md mb-6 p-4 rounded-lg max-h-64 overflow-hidden w-full"
    >
      @if (listType === "pastEvents" && event.videoURL) {
        <div class="w-full">
          <app-video-player
            class="block"
            [videoURL]="event.videoURL ? event.videoURL : null"
            [openInDialog]="true"
          >
          </app-video-player>
        </div>
      } @else {
        <div class="flex justify-center items-center h-full">
          <span class="text-gray-600">
            If you have opted for a video, it will be uploaded here soon.
          </span>
        </div>
      }
    </div>
  }
</div>
