<div class="relative">
  <button
    (click)="onButtonClick()"
    [attr.aria-label]="confirmationMode ? 'Confirm delete' : 'Delete'"
    [ngClass]="{
      'bg-blue-500 hover:bg-blue-600': !confirmationMode,
      'bg-red-500 hover:bg-red-600': confirmationMode
    }"
    class="px-4 py-2 text-white rounded transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2"
  >
    {{ confirmationMode ? confirmText : deleteText }}
    @if (confirmationMode) {
      <div class="bottom-0 left-0 h-1 bg-yellow-300 animate-shrink"></div>
    }
  </button>
</div>
