import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";

@Component({
  selector: "app-admin-wiki-workflows",
  standalone: true,
  imports: [FormsModule],
  templateUrl: "./admin-wiki-workflows.component.html",
  styleUrl: "./admin-wiki-workflows.component.scss",
})
export class AdminWikiWorkflowsComponent {
  workflows: any[] = [
    {
      title: "Create a company",
      description: "Process for creating a new company profile",
      steps: [
        {
          description: "Create the company profile with basic information",
        },
        {
          description: "Create an exchange mapping if needed",
        },
        {
          description: "Connect the Word/Excel plugin for this company",
        },
        {
          description: "Create an investment case article",
        },
        {
          description: "Create SWOT points",
        },
        {
          description: "Set the URL to the corporate video",
        },
        {
          description: "Upload the first research with its article and PDF",
        },
        {
          description: "Select which research should be the initiation note",
        },
        {
          description:
            "If needed, you can set this company as a highlighted company",
        },
        {
          description: "Review and finalize the company profile",
        },
      ],
    },
    {
      title: "Upload research",
      description: "Process for uploading new research for an existing company",
      steps: [
        {
          description: "start the 'Upload research' process",
        },
        {
          description: "enter the text for your new research article ",
        },
        {
          description: "upload the PDF file and save the download URL",
        },
        {
          description: "Review and save the new research",
        },
        {
          description: "create the rss entry",
        },
      ],
    },
    {
      title: "Create an event",
      description: "Process for creating a new event",
      steps: [
        {
          description: "Enter event details (title, date, time, location)",
        },
        {
          description: "Add event description and all neccesarry info ",
        },
        {
          description:
            "Add the company name and company ticker - regardless if we cover this company already or not",
        },
        {
          description: "Review and save the event",
        },
      ],
    },
    {
      title: "Approve a corporate user",
      description: "Process for approving a corporate user",
      steps: [
        {
          description: "Check the user list for corporate users",
        },
        {
          description: "Review the user's information and credentials",
        },
        {
          description: "Go to edit mode for the selected user",
        },
        {
          description: "Set the 'approved dashboard' checkbox",
        },
        {
          description: "Set the company name and company ticker for that user!",
        },
        {
          description: "Save the changes ",
        },
      ],
    },
    {
      title: "Create a transaction",
      description: "Process for creating and activating a new transaction",
      steps: [
        {
          description: "create a transcations",
        },
        {
          description: "edit the frontend config to activate the transaction",
        },
      ],
    },
    {
      title: "Archive a past event",
      description: "Process for archiving a completed event",
      steps: [
        {
          description: "Locate the existing event in the system",
        },
        {
          description:
            "You do not have to set 'Archived' status anymore, this happens automatically",
        },
        {
          description: "Add feedback URL if available",
        },
        {
          description: "Add URLs for video recordings if applicable",
        },

        {
          description: "Save the updated event information",
        },
      ],
    },
    {
      title: "Create a weekly screener",
      description: "Process for creating a weekly screener",
      steps: [
        {
          description: "Prepare the weekly screener in Word",
        },
        {
          description:
            "Select the 'create weekly screener' option on the Dasbhoard",
        },
        {
          description: "Enter and design the text",
        },
        {
          description:
            "Upload images via the 'Upload Manager' and use the image-URLS in your text",
        },
        {
          description: "Review and save the weekly screener",
        },
      ],
    },
    {
      title: "Set highlighted companies: Initiation or Transaction",
      description: "Process for setting or updating highlighted companies",
      steps: [
        {
          description: "Go into the 'Edit Settings' Menu of the company",
        },
        {
          description:
            "select the highlight: 'Initiation' or Transaction' ('Update' is set automatically",
        },
        {
          description: "Save the new company settings and review the changes",
        },
      ],
    },
    {
      title: "Company visibility",
      description: "Who sees which companies?",
      steps: [
        {
          description:
            "Companies can be public or not public. Companies can also be hidden",
        },
        {
          description:
            "Admins see published and non-published companies on all pages",
        },
        {
          description: "All other users only see public companies",
        },
        {
          description:
            "Hidden companies are only shown in the Admin Company List and no where else on the page",
        },
      ],
    },
  ];
  /**
   *
   * QUick DUmp for additional infos
   *
   * - is_public is still present but hidden and always set to true, so we need an article for "what is public, what is only visible to admin ( and where), this needs to be done for company/research/event/screener
   *
   *
   *
   *
   */
  searchTerm: string = "";
  get filteredWorkflows() {
    return this.workflows.filter(
      (workflow) =>
        workflow.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        workflow.description
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
    );
  }
}
