import { Component, inject } from "@angular/core";
import { IFrontendConfig } from "~global-interfaces/IFrontendConfig";

//import { FrontendConfigEditorFormComponent } from "~features/frontend-config/admin/transaction-activation-form/transaction-activation-form.component";
import { FrontendConfigEditorFormComponent } from "~features/frontend-config/admin/frontend-config-editor-form/frontend-config-editor-form.component";
import { FrontendConfigService } from "~features/frontend-config/frontendConfig.service";
import { AdminRssEditorFormComponent } from "~features/rss/admin/admin-rss-editor-form/admin-rss-editor-form.component";
import { TransactionService } from "~features/transactions/transaction.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";

@Component({
  selector: "app-frontend-config-editor",
  standalone: true,
  imports: [
    AdminRssEditorFormComponent,
    BreadcrumpComponent,
    // FrontendConfigEditorFormComponent,
    AdminSidePanelComponent,
    FrontendConfigEditorFormComponent,
  ],
  templateUrl: "./frontend-config-editor.component.html",
  styleUrl: "./frontend-config-editor.component.scss",
})
export class FrontendConfigEditorComponent {
  transactionService = inject(TransactionService);
  private frontEndConfigService = inject(FrontendConfigService);
  frontendConfig = this.frontEndConfigService.getFrontendConfig();

  onFormSubmit(newData: IFrontendConfig) {
    //console.log(newData);
    this.frontEndConfigService.updateFrontEndConfig(newData);
  }
}
