import { DecimalPipe, JsonPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";

import { ExcelService } from "~features/company/excel.service";
import { Tables } from "~features/tables/tables.util";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

interface RowType {
  title: string;
  value: string[];
  valueModifier?: string;
}
@Component({
  selector: "app-table-change",
  templateUrl: "./table-change.component.html",
  styleUrls: ["./table-change.component.scss"],
  standalone: true,
  imports: [DecimalPipe, JsonPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableChangeComponent {
  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  @Input() tile: WritableSignal<any> = signal(null);
  @Input() decimals = 0;
  @Input() lang = "eng";

  @Input() valueTable = false;

  @Output() tileEvent: EventEmitter<any> = new EventEmitter();

  excelService = inject(ExcelService);
  excelMapping = this.excelService.getExcelMapping();

  data: WritableSignal<RowType[]> = signal<RowType[]>([]);
  dates: WritableSignal<string[]> = signal<string[]>([]);
  index: number = 0;
  dataReady = signal(false);

  constructor(private tableUtils: Tables) {
    effect(() => {
      // console.log("I AM IN EFFECT OF TABLE CHANGE");
      // console.log(this.tile());
      // console.log(this.companyData());
      //  console.log(this.baseyear());
      this.prepareData(this.excelMapping(), this.companyData());
    }, enableSignalWritesInEffectContext);
    // WARNING: this is a workaround for an actual sideeffect.
    // TODO: check what the side effect is and manage it. then remove this option again
  }

  prepareData(mapping, companyNumbers) {
    const numFigures = 8;

    // console.log("mapping");
    // console.log(mapping);
    // console.log("companyNumbers");
    // console.log(companyNumbers);
    if (
      mapping &&
      companyNumbers &&
      companyNumbers.base &&
      companyNumbers.financial_figures
    ) {
      //console.log("GOT ALL REQUIREMENTS IN TABLE CHANGE");
      for (let key of this.tile().content) {
        if (mapping[key]) {
          let mappingEntry = mapping[key];
          if (mappingEntry.type === "VALUE") {
            const entry = {
              title: mappingEntry[this.lang],
              value: this.tableUtils.getValue(
                companyNumbers,
                mappingEntry,
                this.lang,
                this.tile().decimals
              ),
            };

            if (this.data().length > 0) {
              this.data.update((oldData) => {
                oldData.push(entry);
                return oldData;
              });
            } else {
              this.data.set([entry]);
            }
          } else if (mappingEntry.type === "TIMESERIES") {
            const quarterlyPattern = /_q$/;
            let jump = 4; //number of timesteps between shown data
            //crap hack
            //uses halfyearly data if quarterly isnt there
            if (
              quarterlyPattern.test(key) &&
              (this.tableUtils.getTimeseries(
                key,
                companyNumbers,
                mappingEntry,
                -1,
                numFigures,
                "value",
                this.lang,
                this.tile().decimals
              )[0] === "na" ||
                this.tableUtils
                  .getTimeseries(
                    key,
                    companyNumbers,
                    mappingEntry,
                    -1,
                    numFigures,
                    "value",
                    this.lang,
                    this.tile().decimals
                  )[0]
                  .includes("WERT")) &&
              (this.tableUtils.getTimeseries(
                key,
                companyNumbers,
                mappingEntry,
                -1,
                numFigures,
                "value",
                this.lang,
                this.tile().decimals
              )[1] === "na" ||
                this.tableUtils
                  .getTimeseries(
                    key,
                    companyNumbers,
                    mappingEntry,
                    -1,
                    numFigures,
                    "value",
                    this.lang,
                    this.tile().decimals
                  )[0]
                  .includes("WERT"))
            ) {
              if (
                this.tableUtils.getTimeseries(
                  key.replace("_q", "_h"),
                  companyNumbers,
                  mapping[key.replace("_q", "_h")],
                  -1,
                  numFigures,
                  "value",
                  this.lang,
                  this.tile().decimals
                )[0] !== "na"
              ) {
                key = key.replace("_q", "_h");
                mappingEntry = mapping[key.replace("_q", "_h")];
                this.tileEvent.emit({ type: "half-year" });
                jump = 2;
              }
            }

            if (this.dates().length === 0) {
              this.dates.set(
                this.reduceAndCalc(
                  "dates",
                  this.tableUtils.getTimeseries(
                    key,
                    companyNumbers,
                    mappingEntry,
                    -1,
                    numFigures,
                    "date",
                    this.lang,
                    this.tile().decimals
                  ),
                  jump
                ).filter((item) => item.trim() !== "")
              );
            }

            const entry = {
              title: mappingEntry[this.lang],
              value: this.reduceAndCalc(
                key,
                this.tableUtils.getTimeseries(
                  key,
                  companyNumbers,
                  mappingEntry,
                  -1,
                  numFigures,
                  "value",
                  this.lang,
                  this.tile().decimals
                ),
                jump
              ),
            };

            if (this.data().length > 0) {
              this.data.update((oldData) => {
                if (!oldData.some((item) => item.title === entry.title)) {
                  oldData.push(entry);
                }
                return oldData;
              });
            } else {
              this.data.set([entry]);
            }
          }
        }
      }
      this.dataReady.set(true);
    } else {
      // console.log("DIDNT GET ALL REQUIREMENTS IN TABLE CHANGE");
    }
  }

  reduceAndCalc(key: string, data: Array<any>, jump: number): Array<any> {
    const res = [];

    res.push(data[data.length - jump - 1]);
    res.push(data[data.length - 1]);

    if (
      !(
        key.includes("date") ||
        key.includes("_margin") ||
        parseFloat(res[1].replace(",", "")) < 0 ||
        parseFloat(res[0].replace(",", "")) <= 0 ||
        data[data.length - jump - 1].includes("na") ||
        data[data.length - 1].includes("na")
      )
    ) {
      res.push(
        Math.round(
          ((parseFloat(res[1].replace(",", "")) -
            parseFloat(res[0].replace(",", ""))) /
            parseFloat(res[0].replace(",", ""))) *
            1000
        ) /
          10 +
          "%"
      );
    } else {
      res.push("   ");
    }

    return res;
  }
}
