<div class="flex bg-gray-100 min-h-screen">
  <app-admin-side-panel></app-admin-side-panel>

  <main class="flex-1 overflow-y-auto p-8">
    <app-breadcrump class="mb-6"></app-breadcrump>

    <div class="bg-white shadow-md rounded-lg p-4 mb-4">
      <p class="text-md font-semibold mb-2">Edit a RSS-Entry</p>
      <!-- <p class="text-gray-600">Upload logo, set featured, etc.</p>-->
    </div>

    <div class="p-6">
      <app-admin-rss-editor-form
        (formSubmit)="onFormSubmit($event)"
        [rssEntry]="rssEntry"
      ></app-admin-rss-editor-form>
    </div>
  </main>
</div>
