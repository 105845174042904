<div class="flex flex-col min-h-screen">
  <section class="bg-yellow-100 pb-4">
    <div class="container mx-auto px-4 lg:px-16 max-w-screen-2xl">
      <div class="w-full">
        <section class="py-8 lg:py-12 px-5 bg-yellow-100">
          <div class="container mx-auto max-w-screen-2xl">
            <div class="grid grid-cols-1 lg:grid-cols-5 gap-4 p-4">
              <div class="lg:col-span-3 leading-none font-semibold">
                <div class="text-lg lg:text-xl">{{ videoEvent()?.title }}</div>
                <div class="text-md mt-4">
                  {{ videoEvent()?.date | date: "fullDate" }}
                </div>
              </div>
              <div
                class="lg:col-span-2 text-lg lg:text-xl leading-none font-semibold"
              ></div>
            </div>
          </div>
          <div class="container mx-auto pt-6 max-w-screen-2xl">
            <hr class="my-b" />
          </div>
        </section>

        <section class="px-5 bg-yellow-100 pb-4" id="#eventgrid">
          <div class="content-wrapper limit-pagewidth">
            <app-event-video-highlight
              [hideDescription]="true"
              [isCoveredCompany]="isCoveredCompany"
              [videoEvent]="videoEvent"
            >
            </app-event-video-highlight>
          </div>
        </section>
      </div>
    </div>
  </section>
  <section class="bg-blue-100 pt-4" id="archive">
    <div class="container mx-auto px-4 lg:px-16 max-w-screen-2xl">
      <div class="w-full">
        <section class="pt-5 px-5 bg-blue-100">
          <div class="grid grid-cols-1 lg:grid-cols-5 grid-rows-1 gap-4 py-4">
            <div class="lg:col-span-2">
              <p class="text-md">
                You missed our recent roundtable with an exciting company? Get
                up to speed with a video recording and bonus material.
              </p>
            </div>
            <div class="col-span-1"></div>
            <div
              class="lg:col-span-2 text-lg lg:text-xl leading-none font-semibold text-right"
            >
              Catch up
              <div class="text-yellow-500 font-semibold">
                on our recent events
              </div>
            </div>
          </div>
        </section>
        <section class="px-5 bg-blue-100 pb-4">
          <div class="content-wrapper limit-pagewidth">
            <app-event-grid-archived></app-event-grid-archived>
          </div>
        </section>
      </div>
    </div>
  </section>

  <div class="my-5"></div>

  <hr />
  <!-- subscibe banner-->
</div>

<app-more-content-cta></app-more-content-cta>
