<div class="container mx-auto px-4">
  <div class="flex flex-wrap">
    <div class="w-full">
      <div class="bg-white shadow-md rounded-lg">
        <div class="px-6 py-4 border-b border-gray-200">
          <h4 class="text-lg font-bold">File List</h4>
        </div>
        <div class="p-6">
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-1/2">
              <div class="flex items-center">
                <input
                  type="text"
                  class="w-full px-3 py-2 text-gray-700 bg-gray-200 rounded-l-md focus:outline-none focus:bg-white"
                  (input)="searchFiles($event.target.value)"
                  placeholder="Search files"
                />
                <button
                  class="px-4 py-2 text-white bg-blue-500 rounded-r-md hover:bg-blue-600 focus:outline-none"
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="flex justify-end">
                <div class="mr-4">
                  <label class="inline-flex items-center">
                    <input
                      class="form-checkbox h-5 w-5 text-blue-600"
                      type="checkbox"
                      [(ngModel)]="searchOptions.pdf"
                      (change)="onSearchOptionsChange()"
                      id="pdfCheckbox"
                    />
                    <span class="ml-2 text-gray-700">Only PDF</span>
                  </label>
                </div>
                <div>
                  <label class="inline-flex items-center">
                    <input
                      class="form-checkbox h-5 w-5 text-blue-600"
                      type="checkbox"
                      [(ngModel)]="searchOptions.image"
                      (change)="onSearchOptionsChange()"
                      id="imageCheckbox"
                    />
                    <span class="ml-2 text-gray-700">Only Image</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <div class="w-full">
              @if (isLoading$ | async) {
                <div class="text-center">
                  <div
                    class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span
                      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Loading...</span
                    >
                  </div>
                  <span class="ml-2">Loading...</span>
                </div>
              }
              @if (isSyncing$ | async) {
                <div class="text-center">
                  <div
                    class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span
                      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Syncing...</span
                    >
                  </div>
                  <span class="ml-2">Syncing...</span>
                </div>
              }
            </div>
          </div>
          <div class="flex flex-wrap mb-4">
            <div class="w-full md:w-1/2">
              <div class="flex items-center">
                <label for="pageSizeSelect" class="mr-2">Page Size:</label>
                <select
                  id="pageSizeSelect"
                  class="px-2 py-1 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  [(ngModel)]="pageSize"
                  (change)="onPageSizeChange()"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="flex justify-end">
                <div>
                  <button
                    class="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none"
                    (click)="syncFileList()"
                  >
                    Synchronize Files
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
