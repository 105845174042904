import { JsonPipe, SlicePipe } from "@angular/common";
import { Component, effect, inject, signal } from "@angular/core";
import { IEvent } from "~global-interfaces/IEvent";

import { EventGridArchivedTileComponent } from "~features/events/event-grid-tile-archived/event-grid-archived-tile.component";
import { EventGridUpcomingTileComponent } from "~features/events/event-grid-tile-upcoming/event-grid-upcoming-tile.component";
import { EventService } from "~features/events/event.service";
import {
  createEventRegistrationLink,
  timeZoneFormat,
} from "~features/events/event.utils";
import { enableSignalWritesInEffectContext } from "~options/effectOptions";

@Component({
  selector: "app-event-grid-upcoming",
  standalone: true,
  imports: [
    EventGridArchivedTileComponent,
    EventGridUpcomingTileComponent,
    SlicePipe,
    JsonPipe,
  ],
  templateUrl: "./event-grid-upcoming.component.html",
  styleUrl: "./event-grid-upcoming.component.scss",
})
export class EventGridUpcomingComponent {
  public timeZoneFormat = timeZoneFormat;
  public listOfComingEvents = signal<IEvent[]>([]);
  private eventService = inject(EventService);

  constructor() {
    effect(() => {
      this.initComingEvents();
    }, enableSignalWritesInEffectContext);
  }

  public navigateToEventRegistrationPage(event: IEvent): void {
    const route = createEventRegistrationLink(event.companyTicker, event.date);
    window.open(route);
  }

  private initComingEvents(): void {
    const allEvents = this.eventService.getEventsList()();
    const today = new Date().setHours(new Date().getHours() - 2);

    const comingEvents = allEvents
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .filter(
        (event) =>
          event.is_public === true && new Date(event.date).getTime() > today
      );

    this.listOfComingEvents.set(comingEvents);
  }
}
