<nav aria-label="Breadcrumb" class="text-sm mb-4 border p-2 bg-yellow-100">
  <ol class="flex flex-wrap items-center">
    @for (
      item of breadcrumbService.breadcrumbs();
      track item;
      let first = $first;
      let last = $last
    ) {
      <li class="flex items-center">
        @if (!first) {
          <svg
            class="w-3 h-3 mx-2 text-gray-400 flex-shrink-0"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        }
        @if (item.url === "" || disabledWords.includes(item.label) || last) {
          <span
            class="text-gray-500 font-secondary"
            [class.font-medium]="last"
            >{{ item.label }}</span
          >
        } @else {
          <a
            [routerLink]="item.url"
            class="text-blue-500 hover:text-blue-600 font-secondary focus:outline-none focus:underline"
          >
            {{ item.label }}
          </a>
        }
      </li>
    }
  </ol>
  <div>
    <app-company-search
      [adminCopyMode]="true"
      [isAdminDashboard]="true"
    ></app-company-search>
  </div>
</nav>
