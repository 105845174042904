import { JsonPipe, NgClass, NgStyle, ViewportScroller } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
  Signal,
  WritableSignal,
} from "@angular/core";

import { LegacyDialogWrapperComponent } from "~features/dynamic-tiles/legacy-dialog-wrapper/legacy-dialog-wrapper.component";
import { IDynamicTile } from "~features/dynamic-tiles/tile-wrapper-grid/tile-wrapper-grid.component";
import { DialogService } from "~shared/services/dialog.service";

import { FigureComponent } from "../tileparts/figure/figure.component";
import { FigureGraphComponent } from "../tileparts/figure-graph/figure-graph.component";
import { FigureSliderComponent } from "../tileparts/figure-slider/figure-slider.component";
import { SidebarsComponent } from "../tileparts/sidebars/sidebars.component";
import { TableComponent } from "../tileparts/table/table.component";
import { TableChangeComponent } from "../tileparts/table-change/table-change.component";
import { TableSliderComponent } from "../tileparts/table-slider/table-slider.component";
import { TemplateChartComponent } from "../tileparts/template-chart/template-chart.component";
import { TextComponent } from "../tileparts/text/text.component";
import { VerticalBarsComponent } from "../tileparts/vertical-bars/vertical-bars.component";

@Component({
  selector: "app-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    NgClass,
    FigureComponent,
    FigureSliderComponent,
    FigureGraphComponent,
    SidebarsComponent,
    VerticalBarsComponent,
    TableComponent,
    TableChangeComponent,
    TableSliderComponent,
    TemplateChartComponent,
    TextComponent,
    JsonPipe,
  ],
})
export class TileComponent {
  @Input() companyData: Signal<any>;
  @Input() baseyear: Signal<number> = signal(Number(new Date().getFullYear()));
  year: WritableSignal<number> = signal(Number(new Date().getFullYear()));
  @Input() tile: WritableSignal<IDynamicTile> = signal(null);
  @Output() tileEvent: EventEmitter<any> = new EventEmitter();
  bgColor = null;
  color = null;
  targets = {
    "Interim Figures": "Quarterly table",
  };
  dialogService = inject(DialogService);

  tileHeadline = computed(() => {
    return this.tile().headline;
  });

  constructor(private viewportScroller: ViewportScroller) {}

  @Input() set config(config: any) {
    this.tile.set(config);
  }

  onTileEvent($event) {
    //  console.log("Tile event", $event);
    if ($event.type === "color-change") {
      this.bgColor = $event.change.backgroundColor;
      this.color = $event.change.color;
    } else if ($event.type === "half-year") {
      this.targets["Interim Figures"] = "Half year table";
    } else if ($event.type === "year-change") {
      this.year.set($event.change.year);
    } else if ($event.type === "headline-change") {
      this.tile.update((oldTileValue) => {
        oldTileValue.headline = $event.change.headline;
        return { ...oldTileValue };
      });
    }

    this.tileEvent.emit($event);
  }

  scrollIntoView(emitter) {
    this.viewportScroller.scrollToAnchor(this.targets[emitter]);
  }

  openModal(data) {
    this.dialogService.open(
      data.component,
      {
        companyData: this.companyData,
        templateName: data.templateName,
        collapse: false,
      },
      "dialog-panel-gray-alster"
    );
    // TODO build with new modal
    // const modalRef = this.modalService.open(data.component, {
    //   centered: true,
    //   size: "xl",
    //   windowClass: "tableModal",
    // });
    //
    // modalRef.componentInstance.companyData = this.companyData;
    // modalRef.componentInstance.templateName = data.templateName;
    // modalRef.componentInstance.collapse = false;
  }

  getTileHeight() {
    switch (this.tile().type) {
      case "table-change":
        return "h-full";
      case "template-chart":
        return "h-full";

      default:
        return "max-h-60";
    }
  }

  handleClick(headline) {
    if (headline === "Interim Figures") {
      const tableName = this.targets[headline];
      this.openModal({
        component: LegacyDialogWrapperComponent,
        templateName: tableName,
      });
    }
  }
}
