/// <reference types="@angular/localize" />

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import posthog from "posthog-js";

import { environment } from "~env/environment";
import { JwtInterceptor } from "~features/auth/jwt.interceptor";
import { LoadingInterceptor } from "~shared/interceptors/loading.interceptor";

import { AppRouting } from "./app/app-routing";
import { AppComponent } from "./app/app.component";

if (environment.production) {
  enableProdMode();
}

posthog.init("phc_MxLl1fFRxZwyuIDNTU6k9mHelt0oaPUSzLlepbVnodZ", {
  api_host: "https://eu.i.posthog.com",
  person_profiles: "identified_only", // "identified_only" for anonymous until user profile is set up or "always" for everyone, "never" for
  capture_pageview: false,
  capture_pageleave: true,
  //secure_cookie: true,
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRouting,
      FormsModule,
      ReactiveFormsModule,
      NgxGoogleAnalyticsModule.forRoot("G-CDCMZ9EJSM", [
        {
          command: "consent",
          values: [
            "default",
            {
              ad_storage: "denied",
              analytics_storage: "denied",
            },
          ],
        },
      ])
    ),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
}).catch((err) => console.error(err));
