import { Clipboard } from "@angular/cdk/clipboard";
import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { catchError, finalize, map } from "rxjs/operators";
import { IApiResponse } from "~global-interfaces/IApiResponse.";
import { IQueryParams } from "~global-interfaces/IQueryParams";
import {
  IResearchPodcast,
  IResearchPodcastPopulated,
} from "~global-interfaces/IResearchPodcast";

import { alsterAPI_URL, researchHub_URL } from "~api/api-urls";
import { UserNotificationService } from "~features/user-notifications/user-notification.service";
import { ClipboardService } from "~shared/services/clipboard.service";
import { LoadingIndicatorService } from "~shared/services/loading-indicator.service";
import { PaginationService } from "~shared/services/pagination.service";

@Injectable({
  providedIn: "root",
})
export class PodcastService extends PaginationService<IResearchPodcastPopulated> {
  currentResearchPodcast: WritableSignal<IResearchPodcastPopulated | null> =
    signal(null);
  isLoading: WritableSignal<boolean> = signal(false);
  loadingIndicatorService = inject(LoadingIndicatorService);
  userNotificationService = inject(UserNotificationService);
  clipboardService = inject(ClipboardService);
  podcastCompanies: WritableSignal<string[]> = signal([]);
  private clipboard = inject(Clipboard);

  constructor(http: HttpClient) {
    super(http);
    this.fetchPodcastCompanies();
  }

  fetchResearchPodcastList(
    paginationParams: IQueryParams,
    searchTerm: string | null
  ) {
    this.isLoading.set(true);
    return this.fetchItems(
      alsterAPI_URL +
        `research-podcast/withcontext${searchTerm ? `/${searchTerm}` : "all"}`,
      paginationParams
    ).subscribe(({ items, totalCount }) => {
      this.items.set(items);
      this.totalCount.set(totalCount || 0);
      this.isLoading.set(false);
    });
  }

  fetchPodcast(
    companyTicker: string,
    reserach_date: string,
    research_language: string
  ) {
    console.log(companyTicker, reserach_date, research_language);
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<IResearchPodcastPopulated>>(
        `${alsterAPI_URL}research-podcast/find/${companyTicker}/${reserach_date}/${research_language}`
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch research podcast")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (event: IResearchPodcastPopulated) => {
          this.currentResearchPodcast.set(event);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch research podcast success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchPodcastCompanies() {
    this.isLoading.set(true);
    this.http
      .get<IApiResponse<string[]>>(
        `${alsterAPI_URL}research-podcast/searchablelist`
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(
            error,
            "fetch research podcast companies"
          )
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      )
      .subscribe({
        next: (list: string[]) => {
          this.podcastCompanies.set(list);
          this.loadingIndicatorService.setLastRequestMessage(
            "fetch research podcast companies success"
          );
          this.loadingIndicatorService.setLastRequestSuccess(true);
        },
        error: (error) => this.errorHandlingService.handleRequestError(error),
      });
  }

  fetchPodcastById(podcastId) {
    return this.http
      .get<IApiResponse<IResearchPodcast>>(
        alsterAPI_URL + "research-podcast/" + podcastId
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "fetch podcast entry")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }

  updatePodcast(formData) {
    const body = { ...formData };
    return this.http
      .put<IApiResponse<IResearchPodcast>>(
        alsterAPI_URL + "research-podcast/edit/" + formData._id,
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "update podcast entry")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }

  createPodcast(formData) {
    const body = { ...formData };
    return this.http
      .post<IApiResponse<IResearchPodcast>>(
        alsterAPI_URL + "research-podcast/create",
        body
      )
      .pipe(
        map((response) => response.data[0]),

        catchError((error) =>
          this.errorHandlingService.handleError(error, "create podcast")
        ),
        finalize(() => {
          this.isLoading.set(false);
        })
      );
  }

  getCurrentPodcast() {
    return this.currentResearchPodcast;
  }

  getPodcastList() {
    return this.items;
  }

  getPodcastCompanies() {
    return this.podcastCompanies;
  }

  copyShareLinkToClipBoard(podcast) {
    const shareLink = `${researchHub_URL}/podcasts/${podcast.companyTicker}/${podcast.research_date}/${podcast.language}`;
    this.clipboardService.copyToClipboard(shareLink);
  }
}
