@if (loadingURLs().length > 0) {
  <div
    class="fixed right-1 top-[10%] text-gray-muted bg-white z-50 border shadow-lg text-md"
  >
    <div class="">
      <!--    <ul class="list-disc list-inside">-->
      <!--      @for (url of loadingURLs(); track url; let i = $index) {-->
      <!--        <li>-->
      <!--          <svg-->
      <!--            class="h-4 w-4"-->
      <!--            preserveAspectRatio="xMidYMid"-->
      <!--            viewBox="0 0 100 100"-->
      <!--            xmlns="http://www.w3.org/2000/svg"-->
      <!--          >-->
      <!--            <circle-->
      <!--              cx="50"-->
      <!--              cy="50"-->
      <!--              fill="none"-->
      <!--              r="35"-->
      <!--              stroke="#4c4c4c"-->
      <!--              stroke-dasharray="164.93361431346415 56.97787143782138"-->
      <!--              stroke-width="10"-->
      <!--            >-->
      <!--              <animateTransform-->
      <!--                attributeName="transform"-->
      <!--                dur="1s"-->
      <!--                keyTimes="0;1"-->
      <!--                repeatCount="indefinite"-->
      <!--                type="rotate"-->
      <!--                values="0 50 50;360 50 50"-->
      <!--              ></animateTransform>-->
      <!--            </circle>-->
      <!--          </svg>-->
      <!--          {{ i }} / {{ loadingURLs().length }}-->
      <!--        </li>-->
      <!--      }-->
      <!--    </ul>-->

      <div class="max-h-8 flex">
        <svg
          class="h-4 w-4 my-auto"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            r="35"
            stroke="#4c4c4c"
            stroke-dasharray="164.93361431346415 56.97787143782138"
            stroke-width="10"
          >
            <animateTransform
              attributeName="transform"
              dur="1s"
              keyTimes="0;1"
              repeatCount="indefinite"
              type="rotate"
              values="0 50 50;360 50 50"
            ></animateTransform>
          </circle>
        </svg>
        <div>Loading: {{ loadingURLs().length }}</div>
      </div>
    </div>
  </div>
}
