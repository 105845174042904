import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SlugService {
  constructor() {}

  public formatSlugFromCompanyName(companyName: string) {
    return this.slugify(companyName);
  }

  private slugify(text: string): string {
    const decodedText = decodeURIComponent(text);
    return decodedText
      .toString()
      .toLowerCase()
      .replace(/ä/g, "ae") // Replace German umlaute
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .replace(/ß/g, "ss")
      .normalize("NFD") // Normalize to decompose combined characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "") // Remove special characters
      .replace(/[\u2018\u2019]/g, "") // Remove special quotes
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
}
