export function getNumberColor(number, darkMode = false) {
  const parsedNumber = Number(number);
  if (parsedNumber > 0) {
    if (darkMode) {
      return "text-green-darkmode";
    }
    return "text-[#10B981]";
  } else if (parsedNumber < 0) {
    return "text-[#f43f5e]";
  } else {
    return "";
  }
}

export function getTextColor(text) {
  if (text === "Spec. BUY" || text === "BUY") {
    return "text-[#10B981]";
  } else if (text === "HOLD") {
    return "text-yellow-hold";
  } else if (text === "SELL") {
    return "text-[#F87171]";
  } else {
    return "";
  }
}
