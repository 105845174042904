import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { Component, Inject } from "@angular/core";

import { AuthService } from "~features/auth/auth.service";

@Component({
  selector: "app-cookie-banner-dialog",
  templateUrl: "./cookie-banner-dialog.component.html",
  styleUrls: ["./cookie-banner-dialog.component.scss"],
  standalone: true,
})
export class CookieBannerDialogComponent {
  constructor(
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any,
    public authService: AuthService
  ) {}
}
