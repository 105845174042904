<div
  [ngClass]="notifications().length <= 1 ? 'top-[90%]' : 'top-[80%]'"
  class="fixed left-1/2 transform -translate-x-1/2 flex flex-col items-center z-40"
>
  @for (
    notification of notifications().slice().reverse();
    track notification;
    let i = $index
  ) {
    <div
      class="bg-yellow-200 border-4 border-yellow-400 rounded-lg p-4 mt-2 w-72 text-center transition-opacity duration-500"
      [ngClass]="{ 'opacity-50 -translate-y-2': i > 0 }"
      [style.zIndex]="5 - i"
    >
      {{ notification.message }}
      <div class="h-1 bg-yellow-400 mt-2">
        <div class="h-full bg-yellow-600 animate-progress"></div>
      </div>
    </div>
  }
</div>
