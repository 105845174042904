import { NgClass } from "@angular/common";
import { Component, computed, effect, signal } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { AuthService } from "~features/auth/auth.service";

@Component({
  selector: "app-requestpassword-page",
  templateUrl: "./requestpassword-page.component.html",
  styleUrls: ["./requestpassword-page.component.scss"],
  standalone: true,
  imports: [ReactiveFormsModule, NgClass],
})
export class RequestpasswordPageComponent {
  resetForm = signal<FormGroup>(
    this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
    })
  );
  submitted = signal(false);
  loading = signal(false);
  success = signal(false);

  isAuthenticated = this.authService.isAuthenticated;
  currentUserEmail = computed(() => this.authService.getCurrentUserName());

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    effect(() => {
      if (this.isAuthenticated()) {
        this.resetForm().patchValue({ email: this.currentUserEmail() });
      }
    });
  }

  onResetSubmit() {
    this.submitted.set(true);

    if (this.resetForm().invalid) {
      return;
    }

    this.loading.set(true);
    this.authService
      .requestPasswordReset(this.resetForm().value.email)
      .then(() => {
        this.loading.set(false);
        this.success.set(true);
      })
      .catch(() => {
        this.loading.set(false);
        // Handle error
      });
  }
}
