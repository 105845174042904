<div class="relative h-full flex flex-col justify-end">
  <div class="h-full w-full relative overflow-hidden flex flex-col justify-end">
    @if (sliderIndex() !== null) {
      <div class="grid grid-cols-1 gap-0 w-full">
        @if (currentSlideData().length > 0) {
          @if (sliderIndex() === 4) {
            <td colspan="2" class="w-full">
              @if (esgScore()?.rating?.total !== undefined) {
                <div class="flex flex-col space-y-1">
                  <!-- Changed from space-y-2 to space-y-1 -->
                  <div class="flex justify-between items-center">
                    <span class="text-base">ESG Score</span>
                    <span class="text-white text-sm">
                      {{ esgScore()?.rating?.total | number: "1.0-2" }} / 100
                    </span>
                  </div>
                  <div class="relative w-full">
                    <div
                      class="w-full h-2 bg-gray-200 rounded-full overflow-hidden"
                    >
                      <div
                        class="h-full bg-[#0f6bb2]"
                        [style.width]="
                          (((esgScore()?.rating?.total ?? -120) + 120) / 240) *
                            100 +
                          '%'
                        "
                      ></div>
                    </div>
                  </div>
                  <div class="flex flex-col space-y-1 text-sm mt-1">
                    <!-- Added mt-1 for a bit more space after the progress bar -->
                    @if (esgScore()?.rating?.Social) {
                      <div class="flex justify-between">
                        <span class="font-medium">Social</span>
                        <span>{{
                          esgScore()?.rating?.Social | number: "1.0-2"
                        }}</span>
                      </div>
                    }
                    @if (esgScore()?.rating?.Governance) {
                      <div class="flex justify-between">
                        <span class="font-medium">Governance</span>
                        <span>{{
                          esgScore()?.rating?.Governance | number: "1.0-2"
                        }}</span>
                      </div>
                    }
                    @if (esgScore()?.rating?.Environment) {
                      <div class="flex justify-between">
                        <span class="font-medium">Environment</span>
                        <span>{{
                          esgScore()?.rating?.Environment | number: "1.0-2"
                        }}</span>
                      </div>
                    }
                  </div>
                </div>
              } @else {
                <p class="text-center text-gray-500">No ESG data available.</p>
              }
            </td>
          } @else {
            @for (item of currentSlideData(); track item.label) {
              <div
                class="flex justify-between w-full leading-none text-base font-medium pb-1"
              >
                <span class="text-base">{{ item.label }}</span>
                <span class="text-white text-right">{{ item.value }}</span>
              </div>
            }
          }
        } @else {
          <p class="col-span-2 text-center text-gray-500">
            No data available for this slide.
          </p>
        }
      </div>
    }
  </div>
</div>
