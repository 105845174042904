<div class="rounded-lg max-w-4xl mx-auto text-white">
  <div class="text-right mb-4">
    <span
      (click)="dialogRef.close()"
      class="cursor-pointer text-white hover:text-gray-800"
    >
      <strong>X</strong>
    </span>
  </div>

  <h2 class="text-xl font-bold mb-6">Your Dashboard</h2>

  <div class="flex flex-col md:flex-row">
    <div class="md:w-2/3 pr-6">
      @if (!status) {
        <div>
          <div class="mb-4">
            <label for="username" class="block text-sm font-medium mb-1"
              >E-Mail</label
            >
            <input
              class="w-full text-black px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              name="userName"
              id="username"
              [(ngModel)]="data.userName"
            />
          </div>
          <div class="mb-4">
            <label for="pw" class="block text-sm font-medium mb-1"
              >Password</label
            >
            <input
              class="w-full px-3 text-black py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="password"
              name="pw"
              id="pw"
              [(ngModel)]="data.password"
            />
          </div>
          <div class="flex justify-between items-center">
            <button
              class="bg-blue-research hover:bg-blue-700 py-2 px-4 tracking-widest"
              id="e2e-login"
              (click)="login()"
            >
              LOGIN
            </button>
            <a
              href="/requestnewpassword"
              class="text-blue-600 hover:text-blue-800 underline"
            >
              Forgot your password?
            </a>
          </div>
          @if (showError) {
            <div
              class="mt-4 text-center bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
            >
              <strong
                >You have not registered yet or your password is
                incorrect!</strong
              >
            </div>
          }
        </div>
      }
    </div>

    <div class="md:w-1/3 mt-6 md:mt-0 md:border-l md:pl-6">
      <p class="text-lg mb-4">
        Don't have an account yet? <strong>Register now</strong> and start
        managing your events.
      </p>
      <button
        (click)="goToSignup()"
        class="bg-blue-research hover:bg-blue-700 py-2 px-4 tracking-widest"
        id="e2e-register"
      >
        REGISTER
      </button>
    </div>
  </div>

  <div class="mt-8 w-full">
    <svg
      aria-hidden="true"
      aria-labelledby="svgcid-6anbqjl68z1g"
      class="max-h-10"
      data-bbox="2.3 2.3 212.6 39.1"
      data-type="color"
      preserveAspectRatio="xMidYMid meet"
      role="presentation"
      viewBox="2.3 2.3 212.6 39.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="svgcid-6anbqjl68z1g"></title>
      <g>
        <path
          d="M36.6 2.3v16.8H19.8V2.3h16.8z"
          data-color="1"
          fill="#ffd746"
        ></path>
        <path
          d="M32.3 33.1v8.3H24v-8.3h8.3z"
          data-color="2"
          fill="#17548a"
        ></path>
        <path
          d="M15 17.7v12.7H2.3V17.7H15z"
          data-color="3"
          fill="#84abdb"
        ></path>
        <text style="fill: white; font: normal 25px sans-serif" x="48" y="30">
          mwb research
        </text>
      </g>
    </svg>
  </div>
</div>
