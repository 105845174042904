import { Component, inject, signal, WritableSignal } from "@angular/core";
import { IEvent } from "~global-interfaces/IEvent";

import { AdminEventEditorFormComponent } from "~features/events/admin/admin-event-editor-form/admin-event-editor-form.component";
import { EventService } from "~features/events/event.service";
import { FileUploadComponent } from "~features/file-manager/file-upload/file-upload.component";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_EVENT } from "~shared/initial-values";

@Component({
  selector: "app-admin-event-creator",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    AdminEventEditorFormComponent,
    FileUploadComponent,
    AdminSidePanelComponent,
  ],
  templateUrl: "./admin-event-creator.component.html",
  styleUrl: "./admin-event-creator.component.scss",
})
export class AdminEventCreatorComponent {
  eventService = inject(EventService);
  event: WritableSignal<IEvent | null> = signal(INITIAL_EVENT);

  constructor() {}

  onFormSubmit(newData: IEvent) {
    // console.log(newData);
    this.eventService.createEvent(newData);
    this.event = this.eventService.getCurrentEvent();
  }
}
