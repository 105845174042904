import { HttpClient } from "@angular/common/http";
import { Component, computed, inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { CompanyInvestmentcaseFormComponent } from "~features/company/admin/company-investmentcase/company-investmentcase-form/company-investmentcase-form.component";
import { CompanyValuationEditorFormComponent } from "~features/company/admin/company-valuation/company-valuation-editor-form/company-valuation-editor-form.component";
import { CompanyTickerService } from "~features/company/company-ticker.service";
import { ValuationService } from "~features/company/valuation.service";
import { AdminSidePanelComponent } from "~shared/components/contentful/admin-side-panel/admin-side-panel.component";
import { BreadcrumpComponent } from "~shared/components/ui/breadcrump/breadcrump.component";
import { INITIAL_EDITORCONTENT_VALUATION } from "~shared/initial-values";

@Component({
  selector: "app-company-valuation-editor",
  standalone: true,
  imports: [
    BreadcrumpComponent,
    CompanyValuationEditorFormComponent,
    AdminSidePanelComponent,
    CompanyInvestmentcaseFormComponent,
  ],
  templateUrl: "./company-valuation-editor.component.html",
  styleUrl: "./company-valuation-editor.component.scss",
})
export class CompanyValuationEditorComponent {
  route = inject(ActivatedRoute);
  http = inject(HttpClient);

  companyTickerService = inject(CompanyTickerService);
  valuationService = inject(ValuationService);

  companyTicker = this.companyTickerService.getCompanyTicker();

  //valuationContent = this.valuationService.getValuation();

  valuationContent = computed(() => {
    const valuationValue = this.valuationService.getValuation()();

    return valuationValue !== null
      ? valuationValue
      : INITIAL_EDITORCONTENT_VALUATION;
  });

  constructor() {
    const ticker = this.route.snapshot.paramMap.get("companyTicker");
    this.companyTickerService.setCompanyTicker(ticker);
  }

  onFormSubmit(newData) {
    // console.log(newData);
    this.valuationService.updateValuation(newData);
  }
}
