<a [routerLink]="['/companies', company.name]">
  <div class="bg-gray-100 flex hover:bg-gray-200 hover:cursor-pointer h-32">
    <div class="w-full p-3 grid grid-cols-2 gap-0">
      <div class="col-span-2">
        <div class="flex justify-between">
          <div class="text-md font-bold truncate">{{ company.name }}</div>

          <app-country-flag
            [country]="company?.tileData?.base?.country"
          ></app-country-flag>
          <!--          <app-logo-->
          <!--            [alt]="'German Flag'"-->
          <!--            [logoClass]=""-->
          <!--            [src]="'https://research-hub.de/de.6435bf24d148ca7248e7.svg'"-->
          <!--            [wrapperClass]="'h-6 w-6 ml-2 flex-shrink-0'"-->
          <!--          >-->
          <!--          </app-logo>-->
        </div>
      </div>
      <div class="col-span-1">
        <div class="text-xs text-gray-muted tracking-widest uppercase">
          {{ company?.tileData?.base?.sector }}
        </div>
      </div>
      <div class="col-span-1">
        <div class="text-right">
          @if (company?.liveQuote && company?.liveQuote?.close !== "NA") {
            <div>
              {{ company?.tileData?.base?.curr }}
              {{ company?.liveQuote?.close | number: "1.2-2" }}
            </div>
          }

          @if (company?.liveQuote?.change_p !== "NA") {
            <div class="{{ getNumberColor(company.liveQuote?.change_p) }}">
              {{ company.liveQuote?.change_p | number: "1.1-1" }}%
            </div>
          }
          <div
            class="font-semibold tracking-widest   {{
              getTextColor(company?.tileData?.general_information?.cus_rating)
            }}"
          >
            {{ company?.tileData?.general_information?.cus_rating }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
